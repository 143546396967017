import { Component, OnInit } from '@angular/core';
import { MatDialog } from "@angular/material/dialog"; 
import { ActivatedRoute, Router } from '@angular/router';
import { TokenStorageService } from 'src/app/core/auth/token-storage.service';
import { ApiCallService } from 'src/app/core/services/api-call-service';
import { SnackbarCollection } from 'src/app/core/services/snackbar.service';
import { SendTestEmailDialogComponent, SendTestSmsDialogComponent } from 'src/app/shared/components/dialog-box';
import { TranslateDynamicText } from 'src/app/shared/pipes/dynamic-translation.pipe';

interface urlData {
  layout: string,
  type: string
}

@Component({
  selector: 'app-mobile-email-preview',
  templateUrl: './mobile-email-preview.component.html',
  styleUrls: ['./mobile-email-preview.component.css']
})
export class MobileEmailPreviewComponent implements OnInit {

  constructor(private route: ActivatedRoute, private router: Router, private dialog: MatDialog, private snack: SnackbarCollection
    ,private apicall: ApiCallService, private tokenStorage: TokenStorageService, private _i18nDynamicTranslate: TranslateDynamicText) { }
  urlData: urlData = {
    layout: null,
    type: null
  };
  
  selectedLayout;
  layoutName;
  smsChannelType;
  email;
  sendTestEmail
  senderNumber
  channel;
  shopName = "";
  header = "";
  headerData = "";
  footer = "";
  status = "";
  id;
  buttonText;

  ngOnInit() {
    this.shopName = this.tokenStorage.getShopName();
    this.route.params.subscribe((param) => {
      console.log(param)
      if(param.layout === undefined) {
        this.channel = 'SMS'
        this.selectedLayout = param.smsText;
        this.layoutName = param.smsName;
        this.smsChannelType = param.channelType
      } else {
        this.channel = "Email"
        console.log("tettt",param)
        if(param.type !== 'REWARD'){
          this.apicall.getEmailLayoutDetail(param.layout).subscribe(response => {
            this.email = JSON.parse(response['body']);
            this.layoutName = JSON.parse(response['body'])['name']
            this.selectedLayout = JSON.parse(response['body'])['layoutHtml']
          })
        }
        else{
          this.apicall.getRewardEmailDetail(param.layout).subscribe((response)=>{
            this.email = JSON.parse(response['body']);
            this.layoutName = JSON.parse(response['body'])['name']
            this.selectedLayout = JSON.parse(response['body'])['layoutHtml']
          })
        }
      }
      if(param.type === 'WHATSAPP'){
        this.channel = "WhatsApp";
        console.log("HELLOO_TEST")
        this.header = param.header;
        this.headerData = param.headerData;
        this.footer = param.footer;
        this.status = param.status;
        this.id = param.id;
        this.buttonText = param.buttonText
      }
      console.log(param.layout)
      
    });
  }

  back() {
    if(this.channel === 'Email')
    this.router.navigate(['app/email/choose-layout'], {
    });
    else if(this.channel ==='WhatsApp'){
      this.router.navigate(['/app/whatsapp/choose-layout'], {
      });
    }
    else {
      this.router.navigate(['/app/sms/choose-layout'], {
      });
    }
  }

  openSendTestEmailDialog() {
    const dialogRef = this.dialog.open(SendTestEmailDialogComponent, {
      panelClass: "full-width-dialog",
      height: "auto",
      disableClose: true,
      width: "80%"
    });

    dialogRef.afterClosed().subscribe(response => {
      if(response != false) {
      this.sendTestEmail = response;
      this.snack.openSnackBar(this._i18nDynamicTranslate.transform("Sending test email", ['STATIC_PAGE']), 2000);
      this.apicall.sendTestEngageEmailHTML('EMAIL_LAYOUT_HTML', 'EMAIL', this.email['id'], this.sendTestEmail, null, false, null, this.selectedLayout).subscribe(response => {
        this.snack.openSnackBar(this._i18nDynamicTranslate.transform("Test Email Sent", ['STATIC_PAGE']), 2000);
      }, err => {
        this.snack.openSnackBar(this._i18nDynamicTranslate.transform("Failed to send test email", ['STATIC_PAGE']), 2000);
      });
    }
    });
  }

  editTemplate(layout){
    this.router.navigate(["/app/whatsapp/layout-editor",{id:this.id}]);
  }

  openSendTestSMSDialog() {
    const dialogRef = this.dialog.open(SendTestSmsDialogComponent, {
      disableClose: true,
      panelClass: "custom-dialog-container",
      height: 'auto',
    });

    dialogRef.afterClosed().subscribe(response => {
      if(response) {
      this.senderNumber = response;
      this.snack.openSnackBar(this._i18nDynamicTranslate.transform("Sending test email", ['STATIC_PAGE']), 2000);
      this.apicall.sendTestSMS(this.layoutName,this.selectedLayout,'SMS',this.smsChannelType,this.senderNumber).subscribe(response => {
        this.snack.openSnackBar(this._i18nDynamicTranslate.transform("Test Email Sent", ['STATIC_PAGE']), 2000);
      }, err => {
        this.snack.openSnackBar(this._i18nDynamicTranslate.transform("Failed to send test email", ['STATIC_PAGE']), 2000);
      });
    }
    });
  }


}