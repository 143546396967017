<div *ngIf="modifyDivOpened" class="qb-offer-schedule-head qb-full-div" data-widget-name="OFFER-CHANNEL-SCHEDULE">
  <div class="qb-full">
    <div class="qb-reverse">
      <div class="qb-config-label">
        <div class="qb-config-label-first">
          <div>{{'OFFER_PAGE.SAVE_SCHEDULE' | i18nTranslate}}</div>
          <div>
            <mat-icon (click)="sendcancelMessage()" class="cursor-pointer"
              data-button-name="Cancel:OFFER-CHANNEL-SCHEDULE ">close</mat-icon>
          </div>
        </div>
        <div class="qb-seperator"></div>
        <div class="qb-main-div">
          <div class="qb-main">
            <div class="qb-channel-div">
              <div class="qb-channel">
                <span>{{'CAMPAIGN_PAGE.SELECT_CHANNEL' | i18nTranslate}}</span>
                <span class="qb-i-div-alignment" popoverClass="qb-my-custom-class" placement="bottom"
                  [ngbPopover]="popContentSelectChannel" triggers="mouseenter:mouseleave"
                  *ngIf="(validScreens | screenConfig: 18)">
                  <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9 1.5C6.65625 1.5 4.53125 2.75 3.34375 4.75C2.1875 6.78125 2.1875 9.25 3.34375 11.25C4.53125 13.2812 6.65625 14.5 9 14.5C11.3125 14.5 13.4375 13.2812 14.625 11.25C15.7812 9.25 15.7812 6.78125 14.625 4.75C13.4375 2.75 11.3125 1.5 9 1.5ZM9 16C6.125 16 3.5 14.5 2.0625 12C0.625 9.53125 0.625 6.5 2.0625 4C3.5 1.53125 6.125 0 9 0C11.8438 0 14.4688 1.53125 15.9062 4C17.3438 6.5 17.3438 9.53125 15.9062 12C14.4688 14.5 11.8438 16 9 16ZM7.75 10.5H8.5V8.5H7.75C7.3125 8.5 7 8.1875 7 7.75C7 7.34375 7.3125 7 7.75 7H9.25C9.65625 7 10 7.34375 10 7.75V10.5H10.25C10.6562 10.5 11 10.8438 11 11.25C11 11.6875 10.6562 12 10.25 12H7.75C7.3125 12 7 11.6875 7 11.25C7 10.8438 7.3125 10.5 7.75 10.5ZM9 6C8.4375 6 8 5.5625 8 5C8 4.46875 8.4375 4 9 4C9.53125 4 10 4.46875 10 5C10 5.5625 9.53125 6 9 6Z" fill="#4379EF"/>
                  </svg>                    
                </span>
              </div>
              <div class="qb-d-flex" style="flex-direction: column;">
                <!-- <div class="qb-border-channel cursor-pointer" style="width: 240px;" (click)="removeError()">
                  <mat-select placeholder="Select Channels" [(ngModel)]="selectedChannelList" multiple style="padding: 10px;" (selectionChange)="onSelectChange($event)">
                    <div *ngFor="let channel of channels">
                      <div *ngIf="(validScreens | screenConfig: getId(channel))">
                        <mat-option [checked]="isChannelChecked(channel)" [value]="channel">{{ channel }}</mat-option>
                      </div>
                    </div>
                  </mat-select>
                </div> -->
                <div class="channel-selector">
                  <div *ngFor="let channel of channels">
                    <div *ngIf="(validScreens | screenConfig: getId(channel))">
                      <mat-checkbox color="primary" [checked]="isChannelChecked2(channel)" *ngIf="checkChannelAvailability(channel)"
                                    (change)="checkChannel(channel)">{{channel === 'appOnly' ? 'App Only' : channel === 'pushNotification' ? 'Push Notification' : channel}}</mat-checkbox>
                    </div>
                  </div>
                </div>
                <div *ngIf="errorChannelSelect.length > 0" style="color: red; font-size: 11px;">{{errorChannelSelect}}
                </div>
              </div>
            </div>
            <div class="qb-channel-div" *ngIf="selectedChannelList.length > 0">
              <div class="qb-channel">
                <span>{{'OFFER_PAGE.CHANNEL' | i18nTranslate}}</span>
                <span class="qb-i-div-alignment" popoverClass="qb-my-custom-class" placement="bottom"
                  [ngbPopover]="popContentChannel" triggers="mouseenter:mouseleave"
                  *ngIf="(validScreens | screenConfig: 18)">
                  <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9 1.5C6.65625 1.5 4.53125 2.75 3.34375 4.75C2.1875 6.78125 2.1875 9.25 3.34375 11.25C4.53125 13.2812 6.65625 14.5 9 14.5C11.3125 14.5 13.4375 13.2812 14.625 11.25C15.7812 9.25 15.7812 6.78125 14.625 4.75C13.4375 2.75 11.3125 1.5 9 1.5ZM9 16C6.125 16 3.5 14.5 2.0625 12C0.625 9.53125 0.625 6.5 2.0625 4C3.5 1.53125 6.125 0 9 0C11.8438 0 14.4688 1.53125 15.9062 4C17.3438 6.5 17.3438 9.53125 15.9062 12C14.4688 14.5 11.8438 16 9 16ZM7.75 10.5H8.5V8.5H7.75C7.3125 8.5 7 8.1875 7 7.75C7 7.34375 7.3125 7 7.75 7H9.25C9.65625 7 10 7.34375 10 7.75V10.5H10.25C10.6562 10.5 11 10.8438 11 11.25C11 11.6875 10.6562 12 10.25 12H7.75C7.3125 12 7 11.6875 7 11.25C7 10.8438 7.3125 10.5 7.75 10.5ZM9 6C8.4375 6 8 5.5625 8 5C8 4.46875 8.4375 4 9 4C9.53125 4 10 4.46875 10 5C10 5.5625 9.53125 6 9 6Z" fill="#4379EF"/>
                  </svg>  
                </span>
              </div>
              <div style="width: fit-content;">
                <div class="qb-sms-div">
                  <span class="qb-selected-channel cursor-pointer" [ngClass]="{ active: selectedChannel?.toUpperCase()== 'SMS' }"
                    (click)="selectChannel('SMS')" matTooltip="SMS" *ngIf="
                     (validScreens | screenConfig: 18) && ((selectedChannelList.indexOf('SMS') != -1) || (selectedChannelList.indexOf('sms') != -1))
                    ">
                    <div style="display: flex; gap: 2px;">
                      <img src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/whatsapp/sms.svg"
                        width="20px" height="20px">
                      <span class="material-symbols-outlined" style="font-size: 20px; color: red;"
                        *ngIf="attachSmsError.length > 0 || (showSMSWarning != 1 && saveSurevyClicked && saveSurevyClicked && selectedChannelList.indexOf('sms') != -1 && templateDispatchForm['value']['dispatchType'] !== 'SCHEDULED')">
                        error
                      </span>
                    </div>
                  </span>
                  <!-- <span
                    class="qb-selected-channel qb-not-allowed"
                    [matTooltip]="'COMMON_PAGE.COMING_SOON' | i18nTranslate"
                    *ngIf="
                      !(
                        countryCode === 'IN' ||
                        countryCode === 'CA' ||
                        countryCode === 'US'
                      ) && (validScreens | screenConfig: 18) && (isChannelChecked2('SMS'))
                    "
                    >
                    <div style="display: flex; gap: 2px;">
                      <img src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/whatsapp/sms.svg" width="20px" height="20px">
                      <span class="material-symbols-outlined" style="font-size: 20px; color: red;" *ngIf="attachSmsError.length > 0 || (showSMSWarning != 1 && saveSurevyClicked && selectedChannelList.indexOf('SMS') != -1)">
                        error
                      </span>
                    </div>
                    </span
                  > -->
                  <span *ngIf="isChannelChecked2('email')" class="qb-selected-channel cursor-pointer"
                    [ngClass]="{ active: selectedChannel?.toUpperCase() == 'EMAIL' }" (click)="selectChannel('EMAIL')"
                    [matTooltip]="'COMMON_PAGE.EMAIL' | i18nTranslate">
                    <div style="display: flex; gap: 2px;">
                      <img src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/whatsapp/email.svg"
                        width="20px" height="20px">
                      <span class="material-symbols-outlined" style="font-size: 20px; color: red;"
                        *ngIf="attachEmailError.length > 0">
                        error
                      </span>
                    </div>
                  </span>
                  <span *ngIf="(validScreens | screenConfig: 41) && (isChannelChecked2('whatsapp'))"
                    class="qb-selected-channel cursor-pointer" [ngClass]="{ active: selectedChannel?.toUpperCase() == 'WHATSAPP' }"
                    (click)="selectChannel('WHATSAPP')" [matTooltip]="'COMMON_PAGE.WHATSAPP' | i18nTranslate">
                    <div style="display: flex; gap: 2px;">
                      <img src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/whatsapp/whatsapp.svg"
                        width="20px" height="20px">
                      <span class="material-symbols-outlined" style="font-size: 20px; color: red;"
                        *ngIf="attachWhatsappError.length > 0">
                        error
                      </span>
                    </div>
                  </span>

                  <!-- For mobile channel  -->
                  <span *ngIf="isChannelChecked2('appOnly')" class="qb-selected-channel cursor-pointer"
                    [ngClass]="{ active: selectedChannel?.toUpperCase() == 'APPONLY' }" (click)="selectChannel('APPONLY')"
                    [matTooltip]="'COMMON_PAGE.MOBILE' | i18nTranslate">
                    <div style="display: flex; gap: 2px;">
                      <img src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/icons/Mobile/phone_iphone_FILL0_wght400_GRAD0_opsz24.svg"
                        width="20px" height="20px">
                    </div>
                  </span>
                  <span *ngIf="isChannelChecked2('pos')" class="qb-selected-channel cursor-pointer"
                  [ngClass]="{ active: selectedChannel?.toUpperCase() == 'POS' }" (click)="selectChannel('POS')"
                  [matTooltip]="'COMMON_PAGE.POS' | i18nTranslate">
                  <div style="display: flex; gap: 2px;">
                    <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#5f6368"><path d="M240-120v-80h200v-120H160q-33 0-56.5-23.5T80-400v-360q0-33 23.5-56.5T160-840h640q33 0 56.5 23.5T880-760v360q0 33-23.5 56.5T800-320H520v120h200v80H240Zm-80-280h640v-360H160v360Zm0 0v-360 360Z"/></svg>
                  </div>
                </span>
                <span *ngIf="isChannelChecked2('pushNotification')" class="qb-selected-channel cursor-pointer"
                    [ngClass]="{ active: selectedChannel?.toUpperCase() == 'PUSHNOTIFICATION' }" (click)="selectChannel('PUSHNOTIFICATION')"
                    [matTooltip]="'COMMON_PAGE.PUSHNOTIFICATION' | i18nTranslate">
                    <div style="display: flex; gap: 2px;">
                      <!-- <span class="material-symbols-outlined"  width="20px" height="20px">
                        notifications_active
                      </span> -->
                      <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#5f6368"><path d="M160-200v-80h80v-280q0-83 50-147.5T420-792v-28q0-25 17.5-42.5T480-880q25 0 42.5 17.5T540-820v28q80 20 130 84.5T720-560v280h80v80H160Zm320-300Zm0 420q-33 0-56.5-23.5T400-160h160q0 33-23.5 56.5T480-80ZM320-280h320v-280q0-66-47-113t-113-47q-66 0-113 47t-47 113v280Z"/></svg>
                      <span class="material-symbols-outlined" style="font-size: 20px; color: red;"
                      *ngIf="offerPushNotificationErrorText?.length > 0">
                      error
                    </span>
                    
                    </div>
                  </span>

                </div>
              </div>
            </div>
            <div class="qb-channel-div">
              <div class="qb-channel">{{'OFFER_PAGE.OFFER_SUMMARY' | i18nTranslate}}</div>
              <div class="cursor-pointer qb-view-summary" (click)="openViewOfferSummary()">
                {{'OFFER_PAGE.VIEW_SUMMARY' | i18nTranslate}}
              </div>
            </div>
            <div class="qb-full-width" *ngIf="selectedChannel?.toUpperCase() == 'EMAIL'">
              <div class="qb-channel-div" *ngIf="rawJson['isNoneBarrierDetected'] == false">
                <div class="qb-channel">
                  <span>{{'OFFER_PAGE.CHALLENGE_TEMPLATES' | i18nTranslate}}</span>
                  <span class="qb-i-div-alignment" popoverClass="qb-my-custom-class" placement="bottom"
                    [ngbPopover]="popContentChallengeTemplate" triggers="mouseenter:mouseleave">
                    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9 1.5C6.65625 1.5 4.53125 2.75 3.34375 4.75C2.1875 6.78125 2.1875 9.25 3.34375 11.25C4.53125 13.2812 6.65625 14.5 9 14.5C11.3125 14.5 13.4375 13.2812 14.625 11.25C15.7812 9.25 15.7812 6.78125 14.625 4.75C13.4375 2.75 11.3125 1.5 9 1.5ZM9 16C6.125 16 3.5 14.5 2.0625 12C0.625 9.53125 0.625 6.5 2.0625 4C3.5 1.53125 6.125 0 9 0C11.8438 0 14.4688 1.53125 15.9062 4C17.3438 6.5 17.3438 9.53125 15.9062 12C14.4688 14.5 11.8438 16 9 16ZM7.75 10.5H8.5V8.5H7.75C7.3125 8.5 7 8.1875 7 7.75C7 7.34375 7.3125 7 7.75 7H9.25C9.65625 7 10 7.34375 10 7.75V10.5H10.25C10.6562 10.5 11 10.8438 11 11.25C11 11.6875 10.6562 12 10.25 12H7.75C7.3125 12 7 11.6875 7 11.25C7 10.8438 7.3125 10.5 7.75 10.5ZM9 6C8.4375 6 8 5.5625 8 5C8 4.46875 8.4375 4 9 4C9.53125 4 10 4.46875 10 5C10 5.5625 9.53125 6 9 6Z" fill="#4379EF"/>
                    </svg>   
                  </span>
                </div>
                <div class="qb-half-width" *ngIf="rawJson['isBuyXGetYDetected'] == false">
                  <div (click)="openchannelDiv()" class="qb-view-summary" *ngIf="preemailLayoutId == null">
                    {{'OFFER_PAGE.ATTACH_EMAIL' | i18nTranslate}}
                  </div>
                  <div (click)="openchannelDiv()" class="qb-view-summary" *ngIf="preemailLayoutId > 0">
                    {{'OFFER_PAGE.REPLACE_EMAIL' | i18nTranslate}}
                  </div>
                  <div class="qb-flex cursor-pointer" *ngIf="preemailLayoutId"
                    (click)="opensnapshotHtmlDialogBarrier(preemailLayoutId)">
                    <mat-icon class="mat-icon notranslate material-icons mat-icon-no-color qb-attach-icon" role="img"
                      aria-hidden="true" *ngIf="preemailLayoutId">attach_file</mat-icon><span
                      class="qb-challenge-template"> {{'OFFER_PAGE.CHALLENGE_TEMPLATES' | i18nTranslate}} </span>
                  </div>
                </div>
                <div class="qb-warning" *ngIf="rawJson['isBuyXGetYDetected'] == true">
                  <span>{{'OFFER_PAGE.NOT_REQ_REDEENED_SAME_TIME' | i18nTranslate}}</span>
                </div>
              </div>
          
              <div class="qb-channel-div" *ngIf="selectedChannelList.length > 0">
                <div class="qb-channel">
                  <span>{{'OFFER_PAGE.REWAED_TEMPLATE' | i18nTranslate}}</span><span class="qb-i-div-alignment"
                    popoverClass="qb-my-custom-class" placement="bottom" [ngbPopover]="popContentRewardTemplate"
                    triggers="mouseenter:mouseleave">
                    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9 1.5C6.65625 1.5 4.53125 2.75 3.34375 4.75C2.1875 6.78125 2.1875 9.25 3.34375 11.25C4.53125 13.2812 6.65625 14.5 9 14.5C11.3125 14.5 13.4375 13.2812 14.625 11.25C15.7812 9.25 15.7812 6.78125 14.625 4.75C13.4375 2.75 11.3125 1.5 9 1.5ZM9 16C6.125 16 3.5 14.5 2.0625 12C0.625 9.53125 0.625 6.5 2.0625 4C3.5 1.53125 6.125 0 9 0C11.8438 0 14.4688 1.53125 15.9062 4C17.3438 6.5 17.3438 9.53125 15.9062 12C14.4688 14.5 11.8438 16 9 16ZM7.75 10.5H8.5V8.5H7.75C7.3125 8.5 7 8.1875 7 7.75C7 7.34375 7.3125 7 7.75 7H9.25C9.65625 7 10 7.34375 10 7.75V10.5H10.25C10.6562 10.5 11 10.8438 11 11.25C11 11.6875 10.6562 12 10.25 12H7.75C7.3125 12 7 11.6875 7 11.25C7 10.8438 7.3125 10.5 7.75 10.5ZM9 6C8.4375 6 8 5.5625 8 5C8 4.46875 8.4375 4 9 4C9.53125 4 10 4.46875 10 5C10 5.5625 9.53125 6 9 6Z" fill="#4379EF"/>
                    </svg>   
                  </span>
                </div>
                <div class="qb-half-width">
                  <div (click)="openchannelEmailDiv()" class="qb-view-summary" *ngIf="preemailLayoutId == null">
                    {{'OFFER_PAGE.ATTACT_REWARD_EMAIL' | i18nTranslate}}
                  </div>
                  <div *ngIf="attachEmailError.length > 0" style="color: red; font-size: 11px;">{{attachEmailError}}
                  </div>
                  <div (click)="openchannelEmailDiv()" class="qb-view-summary" *ngIf="preemailLayoutId > 0">
                    {{'OFFER_PAGE.REPLACE_REWARD_EMAIL' | i18nTranslate}}
                  </div>

                  <div class="qb-flex cursor-pointer" style="margin-top: 5px;" *ngIf="preemailLayoutId"
                    (click)="opensnapshotHtmlDialog()">
                    <mat-icon class="mat-icon notranslate material-icons mat-icon-no-color" role="img"
                      class="qb-attach-file" aria-hidden="true" *ngIf="preemailLayoutId">attach_file</mat-icon><span
                      class="qb-challenge-template" style="margin-top: 3px;">{{
                      preemailLayoutName
                      }}</span>
                  </div>
                </div>
                <!-- <div class="qb-channel" (click)="opensnapshotHtmlDialog()">
                  <div class="qb-flex cursor-pointer">
                    <mat-icon
                      class="mat-icon notranslate material-icons mat-icon-no-color qb-attach-icon"
                      role="img"
                      aria-hidden="true"
                      >attach_file </mat-icon
                    ><span class="qb-challenge-template">
                      <div
                        *ngIf="
                          rawJson['barrierRewards'][0]['reward'][
                            'rewardType'
                          ] == 'BUY_X_GET_Y'
                        "
                        class="qb-font-exo2"
                        value="BUY_X_GET_Y_REWARD_EMAIL"
                      >
                        Buy X Get Y Reward Email
                      </div>
                      <div
                        *ngIf="
                          rawJson['barrierRewards'][0]['reward'][
                            'rewardType'
                          ] == 'PERCENTAGE_OFF'
                        "
                        class="qb-font-exo2"
                        value="PERCENTAGE_OFF_REWARD_EMAIL"
                      >
                        Percentage Off Reward Email
                      </div>
                      <div
                        *ngIf="
                          rawJson['barrierRewards'][0]['reward'][
                            'rewardType'
                          ] == 'FIXED_AMOUNT_OFF'
                        "
                        class="qb-font-exo2"
                        value="FIXED_AMOUNT_OFF_REWARD_EMAIL"
                      >
                        Fixed Amount Off Reward Email
                      </div>
                      <div
                        *ngIf="
                          rawJson['barrierRewards'][0]['reward'][
                            'rewardType'
                          ] == 'FREE_SHIPPING'
                        "
                        class="qb-font-exo2"
                        value="FREE_SHIPPING_REWARD_EMAIL"
                      >
                        Free Shipping Reward Email
                      </div>
                    </span>
                  </div>
                </div> -->
              </div>
            </div>
            <div class="qb-full-width" *ngIf="selectedChannel?.toUpperCase() == 'SMS'">
              <div class="qb-channel-div" *ngIf="
                  rawJson['isBuyXGetYDetected'] == false &&
                  rawJson['isNoneBarrierDetected'] == false &&
                  selectedChannelList.length > 0
                ">
                <div class="qb-channel">
                  <div>{{'OFFER_PAGE.CHALLENGE_SMS_TEMPLATE' | i18nTranslate}}</div>
                  <span class="qb-i-div-alignment" popoverClass="qb-my-custom-class" placement="bottom"
                    [ngbPopover]="popContentChallengeTemplate" triggers="mouseenter:mouseleave">
                    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9 1.5C6.65625 1.5 4.53125 2.75 3.34375 4.75C2.1875 6.78125 2.1875 9.25 3.34375 11.25C4.53125 13.2812 6.65625 14.5 9 14.5C11.3125 14.5 13.4375 13.2812 14.625 11.25C15.7812 9.25 15.7812 6.78125 14.625 4.75C13.4375 2.75 11.3125 1.5 9 1.5ZM9 16C6.125 16 3.5 14.5 2.0625 12C0.625 9.53125 0.625 6.5 2.0625 4C3.5 1.53125 6.125 0 9 0C11.8438 0 14.4688 1.53125 15.9062 4C17.3438 6.5 17.3438 9.53125 15.9062 12C14.4688 14.5 11.8438 16 9 16ZM7.75 10.5H8.5V8.5H7.75C7.3125 8.5 7 8.1875 7 7.75C7 7.34375 7.3125 7 7.75 7H9.25C9.65625 7 10 7.34375 10 7.75V10.5H10.25C10.6562 10.5 11 10.8438 11 11.25C11 11.6875 10.6562 12 10.25 12H7.75C7.3125 12 7 11.6875 7 11.25C7 10.8438 7.3125 10.5 7.75 10.5ZM9 6C8.4375 6 8 5.5625 8 5C8 4.46875 8.4375 4 9 4C9.53125 4 10 4.46875 10 5C10 5.5625 9.53125 6 9 6Z" fill="#4379EF"/>
                    </svg>   
                  </span>
                </div>
                <div class="qb-half-width" *ngIf="rawJson['isBuyXGetYDetected'] == false">
                  <div (click)="openchannelSMSDiv('barrier')" class="qb-view-summary" *ngIf="presmsLayoutId == null">
                    {{'OFFER_PAGE.ATTACTH_CHALLENGE_SMS' | i18nTranslate}}
                  </div>
                  <div (click)="openchannelSMSDiv('barrier')" class="qb-view-summary" *ngIf="presmsLayoutId > 0">
                    {{'OFFER_PAGE.REPLACE_CHALLENGE_SMS' | i18nTranslate}}
                  </div>
                  <div class="qb-flex cursor-pointer" *ngIf="presmsLayoutId"
                    (click)="opensmsPreviewDialog(presmsLayoutId)">
                    <mat-icon class="mat-icon notranslate material-icons mat-icon-no-color" role="img"
                      class="qb-attach-file" aria-hidden="true" *ngIf="presmsLayoutId">attach_file</mat-icon><span
                      class="qb-challenge-template">{{
                      presmsTemplateName
                      }}</span>
                  </div>
                </div>
              </div>
              <div class="qb-channel-div" *ngIf="selectedChannelList.length > 0">
                <div class="qb-channel">
                  {{'OFFER_PAGE.REWARD_SMS_TEMPLATE' | i18nTranslate}}<span class="qb-i-div-alignment"
                    popoverClass="qb-my-custom-class" placement="bottom" [ngbPopover]="popContentRewardTemplate"
                    triggers="mouseenter:mouseleave">
                    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9 1.5C6.65625 1.5 4.53125 2.75 3.34375 4.75C2.1875 6.78125 2.1875 9.25 3.34375 11.25C4.53125 13.2812 6.65625 14.5 9 14.5C11.3125 14.5 13.4375 13.2812 14.625 11.25C15.7812 9.25 15.7812 6.78125 14.625 4.75C13.4375 2.75 11.3125 1.5 9 1.5ZM9 16C6.125 16 3.5 14.5 2.0625 12C0.625 9.53125 0.625 6.5 2.0625 4C3.5 1.53125 6.125 0 9 0C11.8438 0 14.4688 1.53125 15.9062 4C17.3438 6.5 17.3438 9.53125 15.9062 12C14.4688 14.5 11.8438 16 9 16ZM7.75 10.5H8.5V8.5H7.75C7.3125 8.5 7 8.1875 7 7.75C7 7.34375 7.3125 7 7.75 7H9.25C9.65625 7 10 7.34375 10 7.75V10.5H10.25C10.6562 10.5 11 10.8438 11 11.25C11 11.6875 10.6562 12 10.25 12H7.75C7.3125 12 7 11.6875 7 11.25C7 10.8438 7.3125 10.5 7.75 10.5ZM9 6C8.4375 6 8 5.5625 8 5C8 4.46875 8.4375 4 9 4C9.53125 4 10 4.46875 10 5C10 5.5625 9.53125 6 9 6Z" fill="#4379EF"/>
                    </svg>   
                  </span>
                </div>
                <div class="qb-half-width">
                  <div (click)="openchannelSMSDiv('reward')" class="qb-view-summary" *ngIf="presmsrewardId == null">
                    {{'OFFER_PAGE.ATTACH_REWARD_SMS' | i18nTranslate}}
                  </div>
                  <div *ngIf="attachSmsError.length > 0" style="color: red; font-size: 11px;">{{attachSmsError}}</div>
                  <div (click)="openchannelSMSDiv('reward')" class="qb-view-summary" *ngIf="presmsrewardId > 0">
                    {{'OFFER_PAGE.REPLACE_REWARD_SMS' | i18nTranslate}}
                  </div>

                  <div class="qb-flex cursor-pointer" *ngIf="presmsrewardId"
                    (click)="opensmsPreviewDialog(presmsrewardId)">
                    <mat-icon class="mat-icon notranslate material-icons mat-icon-no-color" role="img"
                      class="qb-attach-file" aria-hidden="true" *ngIf="presmsrewardId">attach_file</mat-icon><span
                      class="qb-challenge-template">{{
                      presmsrewadLayoutName
                      }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="qb-full-width" *ngIf="selectedChannel?.toUpperCase() == 'WHATSAPP'">
              <div class="qb-channel-div" *ngIf="selectedChannelList.length > 0">
                <div class="qb-channel">
                  {{'OFFER_PAGE.REWARD_WHATSAPP_TEMPLATE' | i18nTranslate}}
                  <!-- <span
                    class="qb-i-div-alignment"
                    popoverClass="qb-my-custom-class"
                    placement="bottom"
                    [ngbPopover]="popContentRewardTemplate"
                    triggers="mouseenter:mouseleave"
                  >
                    i
                  </span> -->
                </div>
                <div class="qb-half-width">
                  <div style="width: 175px;" (click)="openchannelWhatsappDiv()" class="qb-view-summary"
                    *ngIf="prewhatsappLayoutId == null">
                    {{'OFFER_PAGE.ATTACH_REWARD_TEMPLATE' | i18nTranslate}}
                  </div>
                  <div *ngIf="attachWhatsappError.length > 0" style="color: red; font-size: 11px;">
                    {{attachWhatsappError}}</div>
                  <div style="width: 175px;" (click)="openchannelWhatsappDiv()" class="qb-view-summary"
                    *ngIf="prewhatsappLayoutId > 0">
                    {{'OFFER_PAGE.REPLACE_REWARD_TEMPLATE' | i18nTranslate}}
                  </div>

                  <div class="qb-flex cursor-pointer" *ngIf="prewhatsappLayoutId"
                    (click)="openWhatsAppPreview(prewhatsappLayoutData)">
                    <mat-icon class="mat-icon notranslate material-icons mat-icon-no-color" role="img"
                      class="qb-attach-file" aria-hidden="true" *ngIf="prewhatsappLayoutId">attach_file</mat-icon><span
                      class="qb-challenge-template">{{
                      prewhatsappLayoutName
                      }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="selectedChannelList.length > 0 && preemailLayoutId && selectedChannel?.toUpperCase() == 'EMAIL'" class="qb-full-width subject-container">
              <div class="qb-subheading-text padding-top-5">Offer Email Subject

              </div>
              <div class="position-relative flex-grow-1">
                <div class="pos-relative qb-full-width">
                    <input type="text" (keydown)="stringInputHandler($event, 150)"  [(ngModel)]="offerEmailSubject" maxlength="150" #inp2
                    class="input-style qb-full-width" placeholder="Enter Subject" [ngClass]="{'error-border':isSegmentNameInvalid}">
                    <div class="remaining-text-left-2">{{inp2.value.length}}/{{inp2.getAttribute('maxlength')}}</div>
                </div>
                <div *ngIf="offerEmailSubjectErrorText" class="qb-error-text">{{offerEmailSubjectErrorText}}</div>
              </div>
            </div>

            <div *ngIf="selectedChannelList.length > 0 && selectedChannel?.toUpperCase() == 'PUSHNOTIFICATION'" class="qb-full-width subject-container">
              <div class="qb-subheading-text padding-top-5">
                Push Notification Title
              </div>
              <div class="position-relative flex-grow-1">
                <div class="pos-relative qb-full-width">
                    <input type="text" (keydown)="stringInputHandler($event, 150)"  [(ngModel)]="offerPushNotificationSubject" maxlength="150" #inpTitle
                    class="input-style qb-full-width" placeholder="Enter Title" [ngClass]="{'error-border':isSegmentNameInvalid}">
                    <div class="remaining-text-left-2">{{inpTitle.value.length}}/{{inpTitle.getAttribute('maxlength')}}</div>
                </div>
                <div *ngIf="offerPushNotificationErrorText" class="qb-error-text">{{offerPushNotificationErrorText}}</div>
              </div>
            </div>
            <div *ngIf="selectedChannelList.length > 0 && selectedChannel?.toUpperCase() == 'PUSHNOTIFICATION'" class="qb-full-width subject-container">
              <div class="qb-subheading-text padding-top-5">
                Push Notification Alias Title
              </div>
              <div class="position-relative flex-grow-1">
                <div class="pos-relative qb-full-width">
                    <input type="text" (keydown)="stringInputHandler($event, 150)"  [(ngModel)]="offerPushTitleAlias" maxlength="150" #inpAlias
                    class="input-style qb-full-width" placeholder="Enter Title" [ngClass]="{'error-border':isSegmentNameInvalid}">
                    <div class="remaining-text-left-2">{{inpAlias.value.length}}/{{inpAlias.getAttribute('maxlength')}}</div>
                </div>
              </div>
            </div>
            <div *ngIf="selectedChannelList.length > 0 && selectedChannel?.toUpperCase() == 'PUSHNOTIFICATION'" class="qb-full-width subject-container">
              <div class="qb-subheading-text padding-top-5">
                Push Notification Body
              </div>
              <div class="position-relative flex-grow-1">
                <div class="pos-relative qb-full-width">
                    <input type="text" (keydown)="stringInputHandler($event, 150)"  [(ngModel)]="offerPushNotificationBody" maxlength="150" #inpBody
                    class="input-style qb-full-width" placeholder="Enter Body" [ngClass]="{'error-border':isSegmentNameInvalid}">
                    <div class="remaining-text-left-2">{{inpBody.value.length}}/{{inpBody.getAttribute('maxlength')}}</div>
                </div>
              </div>
            </div>

            <div *ngIf="isNonDiscountTypeEmailPicked && selectedChannel?.toUpperCase() == 'EMAIL' && !channelConfigureStatus && preemailLayoutId" class="warn-text">
              <span class="material-symbols-outlined">
                warning
                </span>You have selected custom template. Please ensure all the merge tags are relevant to the offer, Otherwise they won't be replaced in the final communication.
              If you are unsure, we suggest to remove the merge tags and replace it with your text.
            </div>
            <div *ngIf="isNonDiscountTypeSMSPicked  && selectedChannel?.toUpperCase() == 'SMS' && !channelConfigureStatus && presmsrewardId" class="warn-text">
              <span class="material-symbols-outlined">
                warning
                </span>You have selected custom template. Please ensure all the merge tags are relevant to the offer, Otherwise they won't be replaced in the final communication.
              If you are unsure, we suggest to remove the merge tags and replace it with your text.
            </div>
          </div>
          <div class="qb-seperator"></div>
          <div [formGroup]="templateDispatchForm" class="qb-padding" *ngIf="action == 'DISPATCH'">
            <div class="qb-customer-segments-div">
              <div *ngIf="!recAction" class="qb-customer-segments">
                <div class="qb-schedule-bold-text">
                  <span>{{'OFFER_PAGE.CUSTOMER_SEGEMNTS_TIER' | i18nTranslate}}</span><span
                    class="qb-i-div-alignment" popoverClass="qb-my-custom-class" placement="bottom"
                    [ngbPopover]="popContentCustomerSegments" triggers="mouseenter:mouseleave">
                    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9 1.5C6.65625 1.5 4.53125 2.75 3.34375 4.75C2.1875 6.78125 2.1875 9.25 3.34375 11.25C4.53125 13.2812 6.65625 14.5 9 14.5C11.3125 14.5 13.4375 13.2812 14.625 11.25C15.7812 9.25 15.7812 6.78125 14.625 4.75C13.4375 2.75 11.3125 1.5 9 1.5ZM9 16C6.125 16 3.5 14.5 2.0625 12C0.625 9.53125 0.625 6.5 2.0625 4C3.5 1.53125 6.125 0 9 0C11.8438 0 14.4688 1.53125 15.9062 4C17.3438 6.5 17.3438 9.53125 15.9062 12C14.4688 14.5 11.8438 16 9 16ZM7.75 10.5H8.5V8.5H7.75C7.3125 8.5 7 8.1875 7 7.75C7 7.34375 7.3125 7 7.75 7H9.25C9.65625 7 10 7.34375 10 7.75V10.5H10.25C10.6562 10.5 11 10.8438 11 11.25C11 11.6875 10.6562 12 10.25 12H7.75C7.3125 12 7 11.6875 7 11.25C7 10.8438 7.3125 10.5 7.75 10.5ZM9 6C8.4375 6 8 5.5625 8 5C8 4.46875 8.4375 4 9 4C9.53125 4 10 4.46875 10 5C10 5.5625 9.53125 6 9 6Z" fill="#4379EF"/>
                    </svg>   
                  </span>
                </div>
                <div class="qb-flex qb-full-width">
                  <div class="qb-form-div-schedule qb-full-width cursor-pointer" style="margin-right: 12px;"
                  (click)="selectopenclose('customerSegmentsSelect')">
                  <mat-select #customerSegmentsSelect (selectionChange)="changeSegmentSelection()" class="qb-font-exo2"
                    color="primary" placeholder="Select customer segment/tiers/lists"
                    formControlName="dispatchcustomerSegments" multiple disableOptionCentering
                    panelClass="myPanelClass">
                    <div class="qb-dropdown-wrapper">
                    <div class="qb-rectangle">
                      <div class="qb-rect">
                        <mat-icon class="qb-icon" matPrefix>search </mat-icon>
                        <input class="qb-no-border-input" type="text" formControlName="tempsegmentFilter"
                          placeholder=" Search by name " (keydown)="$event.stopPropagation()"/>
                      </div>
                    </div>
                    <div class="qb-dropdown-scroll-wrapper">
                      <div class="qb-scrollable-conatiner">
                        <div class="qb-mat-select-dropdowns">
                          <mat-option *ngFor="
                              let segment of allcustomerObj
                                | labelPipe
                                  : templateDispatchForm.controls.tempsegmentFilter
                                      .value
                                  : 'name'
                            " [value]="segment.id" [disabled]="disableAllCustomerSelection && segment.id === 'ALL_CUSTOMERS'" class="qb-font-exo2"
                            [value]="segment.id">
                            {{ segment.name }}
                          </mat-option>

                          <div class="category-text-container" *ngIf="customerSegmentList.length > 0">
                            <div class="category-line"></div>
                            <span class="category-text">{{'OFFER_PAGE.SEGMENTS' | i18nTranslate}}</span>
                            <div class="category-line"></div>
                          </div>
                         <!-- Store Created Segments Section -->
                        <div *ngIf="(storeCreatedSegments | labelPipe : templateDispatchForm.controls.tempsegmentFilter.value : 'name').length>0">
                          <div class="qb-admin-store-lists">
                            <span class="category-text">{{ isAdminPos ? 'Admin Created Segments' : 'Store Created Segments' }}</span>
                          </div>
                          <ng-container *ngFor="let segment of storeCreatedSegments | labelPipe : templateDispatchForm.controls.tempsegmentFilter.value : 'name'">
                            <mat-option class="qb-font-exo2"
                                        [value]="segment.id"
                                        [disabled]="disableCustomerSegmentSelection || !segment.isActive"
                                        [matTooltip]="segment.isActive ? '' : 'We are preparing your segment. It will be available for use shortly'">
                              {{ segment.name }}
                            </mat-option>
                          </ng-container>
                        </div>

                        <!-- Other Segments Section -->
                        <div *ngIf="(otherSegments | labelPipe : templateDispatchForm.controls.tempsegmentFilter.value : 'name').length>0">
                          <div class="qb-other-lists">
                            <span class="category-text">Other Segments</span>
                          </div>
                          <ng-container *ngFor="let segment of otherSegments | labelPipe : templateDispatchForm.controls.tempsegmentFilter.value : 'name'">
                            <mat-option class="qb-font-exo2"
                                        [value]="segment.id"
                                        [disabled]="disableCustomerSegmentSelection || !segment.isActive"
                                        [matTooltip]="segment.isActive ? '' : 'We are preparing your segment. It will be available for use shortly'">
                              {{ segment.name }}
                            </mat-option>
                          </ng-container>
                        </div>

                          <div class="category-text-container" *ngIf="customerTierList.length > 0">
                            <div class="category-line"></div>
                            <span class="category-text">{{'OFFER_PAGE.TIERS' | i18nTranslate}}</span>
                            <div class="category-line"></div>
                          </div>
                          <mat-option class="qb-font-exo2" *ngFor="
                              let segment of customerTierList
                                | labelPipe
                                  : templateDispatchForm.controls.tempsegmentFilter
                                      .value
                                  : 'name'
                            " [value]="segment.id" [disabled]="
                              disableCustomerSegmentSelection ||
                              !segment['isActive']
                            " [matTooltip]="
                              segment['isActive']
                                ? ''
                                : 'We are preparing your tiers. It will be available for use shortly'
                            ">
                            {{ segment.name }}
                          </mat-option>
                          <div class="category-text-container" *ngIf="customerList.length > 0">
                            <div class="category-line"></div>
                            <span class="category-text">{{'COMMON_PAGE.CUSTOMER_LIST' | i18nTranslate}}</span>
                            <div class="category-line"></div>
                          </div>
                          
                        <!-- Store Created Custom List Section -->
                        <div *ngIf="(storeCreatedCustomList | labelPipe : templateDispatchForm.controls.tempsegmentFilter.value : 'name').length>0">
                          <div class="qb-admin-store-lists">
                            <span class="category-text">
                              {{ isAdminPos ? 'Admin Created List' : 'Store Created List' }}
                            </span>
                          </div>
                          <ng-container *ngFor="let segment of storeCreatedCustomList | labelPipe : templateDispatchForm.controls.tempsegmentFilter.value : 'name' ">
                            <mat-option class="qb-font-exo2"
                                        [value]="segment.id"
                                        [disabled]="disableCustomerSegmentSelection || !segment.isActive"
                                        [matTooltip]="segment.isActive ? '' : 'We are preparing your custom list. It will be available for use shortly'">
                              {{ segment.name }}
                            </mat-option>
                          </ng-container>
                        </div>

                        <!-- Other Custom Lists Section -->
                        <div *ngIf="(otherCustomList| labelPipe : templateDispatchForm.controls.tempsegmentFilter.value : 'name').length>0">
                          <div class="qb-other-lists">
                            <span class="category-text">Other Lists</span>
                          </div>
                          <ng-container *ngFor="let segment of otherCustomList | labelPipe : templateDispatchForm.controls.tempsegmentFilter.value : 'name'">
                            <mat-option class="qb-font-exo2"
                                        [value]="segment.id"
                                        [disabled]="disableCustomerSegmentSelection || !segment.isActive"
                                        [matTooltip]="segment.isActive ? '' : 'We are preparing your custom list. It will be available for use shortly'">
                              {{ segment.name }}
                            </mat-option>
                          </ng-container>
                        </div>

                          
                          <!-- <mat-option class="qb-font-exo2" *ngFor="
                              let segment of customerList
                                | labelPipe
                                  : templateDispatchForm.controls.tempsegmentFilter
                                      .value
                                  : 'name'
                            " [value]="segment.id" [disabled]="
                              disableCustomerSegmentSelection ||
                              !segment['isActive']
                            " [matTooltip]="
                              segment['isActive']
                                ? ''
                                : 'We are preparing your custom list. It will be available for use shortly'
                            ">
                            {{ segment.name }}
                          </mat-option> -->
                          <div class="category-text-container" *ngIf="employeeList.length > 0">
                            <div class="category-line"></div>
                            <span class="category-text">{{'COMMON_PAGE.EMPLOYEE_LIST' | i18nTranslate}}</span>
                            <div class="category-line"></div>
                          </div>
                          <mat-option class="qb-font-exo2" *ngFor="
                              let segment of employeeList
                                | labelPipe
                                  : templateDispatchForm.controls.tempsegmentFilter
                                      .value
                                  : 'name'
                            " [value]="segment.id" [disabled]="
                              disableCustomerSegmentSelection ||
                              !segment['isActive']
                            " [matTooltip]="
                              segment['isActive']
                                ? ''
                                : 'We are preparing your employee list. It will be available for use shortly'
                            ">
                            {{ segment.name }}
                          </mat-option>
                        </div>
                      </div>
                    </div>
                  </div>
                  </mat-select>
                  <!-- <div
                    class="qb-keyboard-down-icon-container qb-rotate cursor-pointer"
                    id="keyboard-down-icon-container"  
              <div class="horizontal-flex">
                <div *ngIf="!recAction" class="qb-customer-segments">
                  <div class="qb-schedule-bold-text">
                    <span>{{'OFFER_PAGE.CUSTOMER_SEGEMNTS_TIER' | i18nTranslate}}</span
                    ><span
                      class="qb-i-div-alignment"
                      popoverClass="qb-my-custom-class"
                      placement="bottom"
                      [ngbPopover]="popContentCustomerSegments"
                      triggers="mouseenter:mouseleave"
                    >
                      i
                    </span>
                  </div>
                  <div
                    class="qb-form-div-schedule qb-full-width cursor-pointer
                    (click)="selectopenclose('customerSegmentsSelect')"
                  >
                    <mat-select
                      #customerSegmentsSelect
                      (selectionChange)="changeSegmentSelection()"
                      class="qb-font-exo2"
                      color="primary"
                      placeholder="Select customer segment/tiers/lists"
                      formControlName="dispatchcustomerSegments"
                      multiple
                      disableOptionCentering
                      panelClass="myPanelClass"
                    >
                      <div class="qb-rectangle">
                        <div class="qb-rect">
                          <mat-icon class="qb-icon" matPrefix>search </mat-icon>
                          <input
                            class="qb-no-border-input"
                            type="text"
                            formControlName="tempsegmentFilter"
                            placeholder=" Search by name "
                          />
                        </div>
                      </div>
                      <div class="qb-mat-select-dropdowns">
                        <mat-option
                          *ngFor="
                            let segment of allcustomerObj
                              | labelPipe
                                : templateDispatchForm.controls.tempsegmentFilter
                                    .value
                                : 'name'
                          "
                          [value]="segment.id"
                          [disabled]="disableAllCustomerSelection"
                          class="qb-font-exo2"
                          [value]="segment.id"
                        >
                          {{ segment.name }}
                        </mat-option>
                        <div class="category-text-container" *ngIf="(customerSegmentList
                        | labelPipe
                          : templateDispatchForm.controls.tempsegmentFilter
                              .value
                          : 'name').length>0">
                          <div class="category-line"></div>
                          <span class="category-text">{{'OFFER_PAGE.SEGMENTS' | i18nTranslate}}</span>
                          <div class="category-line"></div>
                        </div>
                        <mat-option
                          class="qb-font-exo2"
                          *ngFor="
                            let segment of customerSegmentList
                              | labelPipe
                                : templateDispatchForm.controls.tempsegmentFilter
                                    .value
                                : 'name'
                          "
                          [value]="segment.id"
                          [disabled]="
                            disableCustomerSegmentSelection ||
                            !segment['isActive']
                          "
                          [matTooltip]="
                            segment['isActive']
                              ? ''
                              : 'We are preparing your segment. It will be available for use shortly'
                          "
                        >
                          {{ segment.name }}
                        </mat-option>
                        <div class="category-text-container" *ngIf="(customerTierList
                        | labelPipe
                          : templateDispatchForm.controls.tempsegmentFilter
                              .value
                          : 'name').length>0">
                          <div class="category-line"></div>
                          <span class="category-text">{{'OFFER_PAGE.TIERS' | i18nTranslate}}</span>
                          <div class="category-line"></div>
                        </div>
                        <mat-option
                          class="qb-font-exo2"
                          *ngFor="
                            let segment of customerTierList
                              | labelPipe
                                : templateDispatchForm.controls.tempsegmentFilter
                                    .value
                                : 'name'
                          "
                          [value]="segment.id"
                          [disabled]="
                            disableCustomerSegmentSelection ||
                            !segment['isActive']
                          "
                          [matTooltip]="
                            segment['isActive']
                              ? ''
                              : 'We are preparing your segment. It will be available for use shortly'
                          "
                        >
                          {{ segment.name }}
                        </mat-option>
                        <div class="category-text-container" *ngIf="(customerList
                          | labelPipe: templateDispatchForm.controls.tempsegmentFilter.value
                          : 'name').length>0">
                          <div class="category-line"></div>
                          <span class="category-text">{{'COMMON_PAGE.CUSTOMER_LIST' | i18nTranslate}}</span>
                          <div class="category-line"></div>
                        </div>
                        <mat-option
                          class="qb-font-exo2"
                          *ngFor="
                            let segment of customerList
                              | labelPipe
                                : templateDispatchForm.controls.tempsegmentFilter
                                    .value
                                : 'name'
                          "
                          [value]="segment.id"
                          [disabled]="
                            disableCustomerSegmentSelection ||
                            !segment['isActive']
                          "
                          [matTooltip]="
                            segment['isActive']
                              ? ''
                              : 'We are preparing your segment. It will be available for use shortly'
                          "
                        >
                          {{ segment.name }}
                        </mat-option>
                        <div *ngIf="isNoDataCondition()" class="no-data">
                          {{'SHARED_PAGE.NO_DATA_AVAILABLE' | i18nTranslate}}
                        </div>
                      </div>
                    </mat-select>
                    <div
                      class="qb-keyboard-down-icon-container qb-rotate cursor-pointer"
                      id="keyboard-down-icon-container"
                      (click)="selectopenclose('customerSegmentsSelect')"
                    >
                      <mat-icon
                        class="qb-keyboard-down-icon mat-icon notranslate material-icons mat-icon-no-color"
                        role="img"
                        aria-hidden="true"
                        >keyboard_arrow_down</mat-icon
                      >
                    </div> -->
                  </div>
                  <div class="info-scl-container">
                    <app-segmentation-customer-list-redirect [miniVer]="true"></app-segmentation-customer-list-redirect>
                  </div>
                </div>
                
                  <mat-error
                    class="qb-error text-align-left"
                    *ngIf="
                      !submitted &&
                      templateDispatchForm.controls.dispatchcustomerSegments
                        .touched &&
                      templateDispatchForm.controls.dispatchcustomerSegments.hasError(
                        'required'
                      )
                    "
                  >
                    {{'OFFER_PAGE.SEGMENT_TIER_REQ' | i18nTranslate}}</mat-error
                  >
                  <mat-error
                    class="qb-error"
                    *ngIf="
                      submitted &&
                      templateDispatchForm.controls.dispatchcustomerSegments
                        .touched &&
                      templateDispatchForm.controls.dispatchcustomerSegments.hasError(
                        'required'
                      )
                    "
                  >
                    {{'OFFER_PAGE.SEGMENT_TIER_REQ' | i18nTranslate}}</mat-error
                  >
              </div>


              <!-- For exclusion of Segments/List/Tiers  -->

              <div class="qb-customer-segments">
                <div class="qb-schedule-bold-text">
                  <span>{{'OFFER_PAGE.EXCLUDE_CUSTOMER_SEGEMNTS_TIER' | i18nTranslate}}</span><span
                    class="qb-i-div-alignment" popoverClass="qb-my-custom-class" placement="bottom"
                    [ngbPopover]="popContentExcludeCustomerSegments" triggers="mouseenter:mouseleave">
                    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9 1.5C6.65625 1.5 4.53125 2.75 3.34375 4.75C2.1875 6.78125 2.1875 9.25 3.34375 11.25C4.53125 13.2812 6.65625 14.5 9 14.5C11.3125 14.5 13.4375 13.2812 14.625 11.25C15.7812 9.25 15.7812 6.78125 14.625 4.75C13.4375 2.75 11.3125 1.5 9 1.5ZM9 16C6.125 16 3.5 14.5 2.0625 12C0.625 9.53125 0.625 6.5 2.0625 4C3.5 1.53125 6.125 0 9 0C11.8438 0 14.4688 1.53125 15.9062 4C17.3438 6.5 17.3438 9.53125 15.9062 12C14.4688 14.5 11.8438 16 9 16ZM7.75 10.5H8.5V8.5H7.75C7.3125 8.5 7 8.1875 7 7.75C7 7.34375 7.3125 7 7.75 7H9.25C9.65625 7 10 7.34375 10 7.75V10.5H10.25C10.6562 10.5 11 10.8438 11 11.25C11 11.6875 10.6562 12 10.25 12H7.75C7.3125 12 7 11.6875 7 11.25C7 10.8438 7.3125 10.5 7.75 10.5ZM9 6C8.4375 6 8 5.5625 8 5C8 4.46875 8.4375 4 9 4C9.53125 4 10 4.46875 10 5C10 5.5625 9.53125 6 9 6Z" fill="#4379EF"/>
                    </svg>   
                  </span>
                </div>
                <div class="qb-flex qb-full-width">
                  <div class="qb-form-div-schedule qb-full-width cursor-pointer" style="margin-right: 12px;"
                  (click)="selectopenclose('customerSegmentsExclusion')">
                  <mat-select #customerSegmentsExclusion (selectionChange)="changeSegmentSelection()" class="qb-font-exo2"
                    color="primary" placeholder="Select customer segment/tiers/lists"
                    formControlName="dispatchexclusioncustomerSegments" multiple disableOptionCentering
                    panelClass="myPanelClass">
                    <div class="qb-dropdown-wrapper">
                    <div class="qb-rectangle">
                      <div class="qb-rect">
                        <mat-icon class="qb-icon" matPrefix>search </mat-icon>
                        <input class="qb-no-border-input" type="text" formControlName="tempexclusionsegmentFilter"
                          placeholder=" Search by name " (keydown)="$event.stopPropagation()"/>
                      </div>
                    </div>
                    <div class="qb-dropdown-scroll-wrapper">
                      <div class="qb-scrollable-conatiner">
                        <div class="qb-mat-select-dropdowns">

                          <div class="category-text-container" *ngIf="customerSegmentList.length > 0">
                            <div class="category-line"></div>
                            <span class="category-text">{{'OFFER_PAGE.SEGMENTS' | i18nTranslate}}</span>
                            <div class="category-line"></div>
                          </div>
                         <!-- Store Created Segments Section -->
                        <div *ngIf="(storeCreatedSegments | labelPipe : templateDispatchForm.controls.tempexclusionsegmentFilter.value : 'name').length>0">
                          <div class="qb-admin-store-lists">
                            <span class="category-text">{{ isAdminPos ? 'Admin Created Segments' : 'Store Created Segments' }}</span>
                          </div>
                          <ng-container *ngFor="let segment of storeCreatedSegments | labelPipe : templateDispatchForm.controls.tempexclusionsegmentFilter.value : 'name'">
                            <mat-option class="qb-font-exo2"
                                        [value]="segment.id"
                                        [disabled]="!segment.isActive"
                                        [matTooltip]="segment.isActive ? '' : 'We are preparing your segment. It will be available for use shortly'">
                              {{ segment.name }}
                            </mat-option>
                          </ng-container>
                        </div>

                        <!-- Other Segments Section -->
                        <div *ngIf="(otherSegments | labelPipe : templateDispatchForm.controls.tempexclusionsegmentFilter.value : 'name').length>0">
                          <div class="qb-other-lists">
                            <span class="category-text">Other Segments</span>
                          </div>
                          <ng-container *ngFor="let segment of otherSegments | labelPipe : templateDispatchForm.controls.tempexclusionsegmentFilter.value : 'name'">
                            <mat-option class="qb-font-exo2"
                                        [value]="segment.id"
                                        [disabled]="!segment.isActive"
                                        [matTooltip]="segment.isActive ? '' : 'We are preparing your segment. It will be available for use shortly'">
                              {{ segment.name }}
                            </mat-option>
                          </ng-container>
                        </div>

                          <div class="category-text-container" *ngIf="customerTierList.length > 0">
                            <div class="category-line"></div>
                            <span class="category-text">{{'OFFER_PAGE.TIERS' | i18nTranslate}}</span>
                            <div class="category-line"></div>
                          </div>
                          <mat-option class="qb-font-exo2" *ngFor="
                              let segment of customerTierList
                                | labelPipe
                                  : templateDispatchForm.controls.tempexclusionsegmentFilter
                                      .value
                                  : 'name'
                            " [value]="segment.id" [disabled]="
                              !segment['isActive']
                            " [matTooltip]="
                              segment['isActive']
                                ? ''
                                : 'We are preparing your tiers. It will be available for use shortly'
                            ">
                            {{ segment.name }}
                          </mat-option>
                          <div class="category-text-container" *ngIf="customerList.length > 0">
                            <div class="category-line"></div>
                            <span class="category-text">{{'COMMON_PAGE.CUSTOMER_LIST' | i18nTranslate}}</span>
                            <div class="category-line"></div>
                          </div>
                          
                        <!-- Store Created Custom List Section -->
                        <div *ngIf="(storeCreatedCustomList | labelPipe : templateDispatchForm.controls.tempexclusionsegmentFilter.value : 'name').length>0">
                          <div class="qb-admin-store-lists">
                            <span class="category-text">
                              {{ isAdminPos ? 'Admin Created List' : 'Store Created List' }}
                            </span>
                          </div>
                          <ng-container *ngFor="let segment of storeCreatedCustomList | labelPipe : templateDispatchForm.controls.tempexclusionsegmentFilter.value : 'name' ">
                            <mat-option class="qb-font-exo2"
                                        [value]="segment.id"
                                        [disabled]="!segment.isActive"
                                        [matTooltip]="segment.isActive ? '' : 'We are preparing your custom list. It will be available for use shortly'">
                              {{ segment.name }}
                            </mat-option>
                          </ng-container>
                        </div>

                        <!-- Other Custom Lists Section -->
                        <div *ngIf="(otherCustomList| labelPipe : templateDispatchForm.controls.tempexclusionsegmentFilter.value : 'name').length>0">
                          <div class="qb-other-lists">
                            <span class="category-text">Other Lists</span>
                          </div>
                          <ng-container *ngFor="let segment of otherCustomList | labelPipe : templateDispatchForm.controls.tempexclusionsegmentFilter.value : 'name'">
                            <mat-option class="qb-font-exo2"
                                        [value]="segment.id"
                                        [disabled]="!segment.isActive"
                                        [matTooltip]="segment.isActive ? '' : 'We are preparing your custom list. It will be available for use shortly'">
                              {{ segment.name }}
                            </mat-option>
                          </ng-container>
                        </div>

                          <div class="category-text-container" *ngIf="employeeList.length > 0">
                            <div class="category-line"></div>
                            <span class="category-text">{{'COMMON_PAGE.EMPLOYEE_LIST' | i18nTranslate}}</span>
                            <div class="category-line"></div>
                          </div>
                          <mat-option class="qb-font-exo2" *ngFor="
                              let segment of employeeList
                                | labelPipe
                                  : templateDispatchForm.controls.tempexclusionsegmentFilter
                                      .value
                                  : 'name'
                            " [value]="segment.id" [disabled]="
                              !segment['isActive']
                            " [matTooltip]="
                              segment['isActive']
                                ? ''
                                : 'We are preparing your employee list. It will be available for use shortly'
                            ">
                            {{ segment.name }}
                          </mat-option>
                        </div>
                      </div>
                    </div>
                  </div>
                  </mat-select>
                  </div>
                </div>
              </div>
                
              <div class="margin-bottom-7">
                <div class="each-store-select">
                  <div class="qb-schedule-bold-text">
                    <span>Customers of Stores that receive offer</span>
                    <span class="qb-i-div-alignment" popoverClass="qb-my-custom-class" placement="bottom"
                      [ngbPopover]="popContentCustomerStores" triggers="mouseenter:mouseleave">
                      <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9 1.5C6.65625 1.5 4.53125 2.75 3.34375 4.75C2.1875 6.78125 2.1875 9.25 3.34375 11.25C4.53125 13.2812 6.65625 14.5 9 14.5C11.3125 14.5 13.4375 13.2812 14.625 11.25C15.7812 9.25 15.7812 6.78125 14.625 4.75C13.4375 2.75 11.3125 1.5 9 1.5ZM9 16C6.125 16 3.5 14.5 2.0625 12C0.625 9.53125 0.625 6.5 2.0625 4C3.5 1.53125 6.125 0 9 0C11.8438 0 14.4688 1.53125 15.9062 4C17.3438 6.5 17.3438 9.53125 15.9062 12C14.4688 14.5 11.8438 16 9 16ZM7.75 10.5H8.5V8.5H7.75C7.3125 8.5 7 8.1875 7 7.75C7 7.34375 7.3125 7 7.75 7H9.25C9.65625 7 10 7.34375 10 7.75V10.5H10.25C10.6562 10.5 11 10.8438 11 11.25C11 11.6875 10.6562 12 10.25 12H7.75C7.3125 12 7 11.6875 7 11.25C7 10.8438 7.3125 10.5 7.75 10.5ZM9 6C8.4375 6 8 5.5625 8 5C8 4.46875 8.4375 4 9 4C9.53125 4 10 4.46875 10 5C10 5.5625 9.53125 6 9 6Z" fill="#4379EF"/>
                      </svg>   
                    </span>
                  </div>
                  <div class="qb-flex qb-full-width">
                    <div class="qb-form-div-schedule qb-full-width cursor-pointer" style="margin-right: 12px;"
                    (click)="selectopenclose('targetedStoresSelect')"  [ngClass]="{'error':templateDispatchForm?.controls.targetedStores?.value?.length == 0,'disabled':!isAdminPos}"
                    [matTooltip]="!isAdminPos?'Editable only for offers created by Admin':''">
                      <mat-select #targetedStoresSelect class="qb-font-exo2"
                        color="primary" placeholder="Select Stores"
                        formControlName="targetedStores" multiple disableOptionCentering
                        panelClass="myPanelClass" [disabled]="!isAdminPos">
                        <div class="qb-dropdown-wrapper">
                          <div class="qb-rectangle">
                            <div class="qb-rect">
                              <mat-icon class="qb-icon" matPrefix>search</mat-icon>
                              <input class="qb-no-border-input" type="text" formControlName="tempstoreFilter"
                                placeholder=" Search by name " (keydown)="$event.stopPropagation()"/>
                            </div>
                          </div>
                          <div class="qb-dropdown-scroll-wrapper">
                            <div class="qb-scrollable-conatiner">
                              <div class="qb-mat-select-dropdowns">
                                <mat-option 
                                  [value]="allStoresObj.qid" class="qb-font-exo2" (click)="selectStoreInDropdown(allStoresObj.qid);getCustomerCount()">
                                  {{ allStoresObj.storeName }}
                                </mat-option>
                                <mat-option *ngFor="let store of storeData | labelPipe
                                  : templateDispatchForm.controls.tempstoreFilter.value : 'storeName'" 
                                  [value]="store.qid" class="qb-font-exo2" (click)="selectStoreInDropdown(store.qid);getCustomerCount()">
                                  {{ store.storeName }}
                                </mat-option>
                              </div>
                            </div>
                          </div>
                        </div>
                      </mat-select>
                    </div>
                  </div>
                </div>
                <mat-error class="qb-error" *ngIf="templateDispatchForm?.controls.targetedStores?.value?.length == 0">Required</mat-error>
              </div>
  
              <div *ngIf="selectedChannel?.toUpperCase() == 'SMS' || selectedChannel?.toUpperCase() == 'WHATSAPP' || selectedChannel?.toUpperCase() == 'EMAIL'" class="qb-total-targeted">
                {{'OFFER_PAGE.TOTAL_NUMBER_CUSTOMER' | i18nTranslate}} -
                <span>{{ ValidCustomerCount }}</span>
                <div class="qb-i-div-alignment" >
                  <span placement="bottom auto" popoverClass="qb-my-custom-class"
                  triggers="mouseenter:mouseleave" [ngbPopover]="popContent58" ><svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9 1.5C6.65625 1.5 4.53125 2.75 3.34375 4.75C2.1875 6.78125 2.1875 9.25 3.34375 11.25C4.53125 13.2812 6.65625 14.5 9 14.5C11.3125 14.5 13.4375 13.2812 14.625 11.25C15.7812 9.25 15.7812 6.78125 14.625 4.75C13.4375 2.75 11.3125 1.5 9 1.5ZM9 16C6.125 16 3.5 14.5 2.0625 12C0.625 9.53125 0.625 6.5 2.0625 4C3.5 1.53125 6.125 0 9 0C11.8438 0 14.4688 1.53125 15.9062 4C17.3438 6.5 17.3438 9.53125 15.9062 12C14.4688 14.5 11.8438 16 9 16ZM7.75 10.5H8.5V8.5H7.75C7.3125 8.5 7 8.1875 7 7.75C7 7.34375 7.3125 7 7.75 7H9.25C9.65625 7 10 7.34375 10 7.75V10.5H10.25C10.6562 10.5 11 10.8438 11 11.25C11 11.6875 10.6562 12 10.25 12H7.75C7.3125 12 7 11.6875 7 11.25C7 10.8438 7.3125 10.5 7.75 10.5ZM9 6C8.4375 6 8 5.5625 8 5C8 4.46875 8.4375 4 9 4C9.53125 4 10 4.46875 10 5C10 5.5625 9.53125 6 9 6Z" fill="#4379EF"/>
                  </svg> </span>
                  <ng-template #popContent58>
                    <div class="qb-pop-content">
                      <div >
                        <div >
                          {{'OFFER_PAGE.TOTAL_CUSTOMER_SEGEMNT' | i18nTranslate}}
                        </div>
                        <div> : {{ totalCustomer }}</div>
                      </div>

                      <!-- <div >
                        <div>
                          {{'OFFER_PAGE.CUSTOMER_WITH_INCORRECT' | i18nTranslate}}
                        </div>
                        <div>: {{ InvalidCustomer }}</div>
                      </div> -->
                      <div >
                        <div >
                          {{'OFFER_PAGE.UNSUBSCRIBED_CUSTOMERS' | i18nTranslate}}
                        </div>
                        <div> : {{ UnsubscribedCount }}</div>
                      </div>
                    </div>
                  </ng-template>
                </div>
                <div class="qb-reminder-text" *ngIf="
                    segmentLength &&
                    apiloading &&
                    ConsentGivenCount != customerCount
                  ">
                  {{'OFFER_PAGE.CUSTOMER_NOT_YET_GIVEN_CONSENT' | i18nTranslate}}
                </div>
              </div>
              <div class="qb-error-text" *ngIf="smsNoOfCustomersZeroError.length > 0">{{smsNoOfCustomersZeroError}}
              </div>

              <div *ngIf="recAction" class="qb-customer-segments">
                <div class="qb-schedule-bold-text">{{'OFFER_PAGE.CUSTOMERS' | i18nTranslate}}</div>
                <div class="qb-form-div-schedule" class="qb-full-width">
                  <mat-select (selectionChange)="changeSegmentSelection()" class="qb-font-exo2" color="primary"
                    placeholder="Select customer segment" formControlName="dispatchcustomerSegments" multiple
                    disableOptionCentering panelClass="myPanelClass">
                    <mat-option [disabled]="disableAllCustomerSelection" class="qb-font-exo2" value="ALL_CUSTOMERS"
                      color="primary">{{'OFFER_PAGE.ALL_CUSTOMERS' | i18nTranslate}}
                    </mat-option>
                    <mat-option [disabled]="disableCustomerSegmentSelection" class="qb-font-exo2"
                      *ngFor="let segment of recomentedSegments" [value]="segment.id" color="primary">
                      {{ segment.name }}</mat-option>
                  </mat-select>
                </div>
                <mat-error class="qb-error" *ngIf="
                    !submitted &&
                    templateDispatchForm.controls.dispatchcustomerSegments
                      .touched &&
                    templateDispatchForm.controls.dispatchcustomerSegments.hasError(
                      'required'
                    )
                  ">
                  {{'OFFER_PAGE.SEGMENT_TIER_REQ' | i18nTranslate}}</mat-error>
                <mat-error class="qb-error" *ngIf="
                    submitted &&
                    templateDispatchForm.controls.dispatchcustomerSegments
                      .touched &&
                    templateDispatchForm.controls.dispatchcustomerSegments.hasError(
                      'required'
                    )
                  ">
                  {{'OFFER_PAGE.SEGMENT_TIER_REQ' | i18nTranslate}}</mat-error>
              </div>


              <div class="qb-customer-segments">
                <div class="qb-schedule-bold-text">
                  <span>{{'OFFER_PAGE.DISPATCH_TYPE' | i18nTranslate}}</span><span class="qb-i-div-alignment"
                    popoverClass="qb-my-custom-class" placement="bottom" [ngbPopover]="popContentDispatchType"
                    triggers="mouseenter:mouseleave">
                    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9 1.5C6.65625 1.5 4.53125 2.75 3.34375 4.75C2.1875 6.78125 2.1875 9.25 3.34375 11.25C4.53125 13.2812 6.65625 14.5 9 14.5C11.3125 14.5 13.4375 13.2812 14.625 11.25C15.7812 9.25 15.7812 6.78125 14.625 4.75C13.4375 2.75 11.3125 1.5 9 1.5ZM9 16C6.125 16 3.5 14.5 2.0625 12C0.625 9.53125 0.625 6.5 2.0625 4C3.5 1.53125 6.125 0 9 0C11.8438 0 14.4688 1.53125 15.9062 4C17.3438 6.5 17.3438 9.53125 15.9062 12C14.4688 14.5 11.8438 16 9 16ZM7.75 10.5H8.5V8.5H7.75C7.3125 8.5 7 8.1875 7 7.75C7 7.34375 7.3125 7 7.75 7H9.25C9.65625 7 10 7.34375 10 7.75V10.5H10.25C10.6562 10.5 11 10.8438 11 11.25C11 11.6875 10.6562 12 10.25 12H7.75C7.3125 12 7 11.6875 7 11.25C7 10.8438 7.3125 10.5 7.75 10.5ZM9 6C8.4375 6 8 5.5625 8 5C8 4.46875 8.4375 4 9 4C9.53125 4 10 4.46875 10 5C10 5.5625 9.53125 6 9 6Z" fill="#4379EF"/>
                    </svg>   
                  </span>
                </div>
                <div class="qb-form-div-schedule qb-full-width cursor-pointer"
                  (click)="selectopenclose('dispatchTypeSelect')">
                  <mat-select class="qb-font-exo2" color="primary" formControlName="dispatchType" disableOptionCentering
                    panelClass="myPanelClass" #dispatchTypeSelect>
                    <mat-option class="qb-font-exo2" value="INSTANT" data-widget-name="OFFER-CHANNEL-SCHEDULE"
                      data-button-name="Schedule Type:Instant">
                      {{'OFFER_PAGE.INSTANT' | i18nTranslate}}</mat-option>
                    <mat-option *ngIf="!isLImitedTime" class="qb-font-exo2" value="SCHEDULED" (click)="scheduledClick()"
                      data-widget-name="OFFER-CHANNEL-SCHEDULE" data-button-name="Schedule Type:Instant">
                      {{'OFFER_PAGE.SCHEDULE' | i18nTranslate}}</mat-option>
                  </mat-select>
                  <!-- <div
                    class="qb-keyboard-down-icon-container qb-rotate"
                    id="keyboard-down-icon-container"
                  >
                    <mat-icon
                      class="qb-keyboard-down-icon mat-icon notranslate material-icons mat-icon-no-color"
                      role="img"
                      aria-hidden="true"
                      >keyboard_arrow_down</mat-icon
                    >
                  </div> -->
                  <mat-error class="qb-error" *ngIf="
                      !submitted &&
                      templateDispatchForm.controls.dispatchType.touched &&
                      templateDispatchForm.controls.dispatchType.hasError(
                        'required'
                      )
                    ">
                    {{'OFFER_PAGE.DISPATCH_TYPE_REQ' | i18nTranslate}}</mat-error>
                  <mat-error class="qb-error" *ngIf="
                      submitted &&
                      templateDispatchForm.controls.dispatchType.touched &&
                      templateDispatchForm.controls.dispatchType.hasError(
                        'required'
                      )
                    ">
                    {{'OFFER_PAGE.DISPATCH_TYPE_REQ' | i18nTranslate}}</mat-error>
                </div>
              </div>




            </div>
            <div class="qb-template-form" *ngIf="
                templateDispatchForm.controls.dispatchType.value === 'SCHEDULED'
              ">
              <div class="qb-customer-segments">
                <div class="qb-schedule-bold-text">{{'OFFER_PAGE.DATE' | i18nTranslate}}</div>
                <div class="qb-form-div-schedule qb-full-width cursor-pointer" (click)="picker2.open()" [ngClass]="{
                    active1:
                      templateDispatchForm.controls.dispatchDate.value ==
                        null ||
                      templateDispatchForm.controls.dispatchDate.value.length ==
                        0
                  }">
                  <input class="qb-no-border-date" [matDatepicker]="picker2" [min]="startDateMinValue"
                    (dateChange)="startDateChange()" formControlName="dispatchDate" readonly
                    [ngStyle]="templateDispatchForm?.value.dispatchDate ? { opacity: 0 , width: '0px' } : null" />
                  <div class="qb-no-border-date">
                    {{templateDispatchForm?.value.dispatchDate | date: getDateFormat() }}
                  </div>
                  <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                  <mat-datepicker #picker2 color="primary"></mat-datepicker>
                </div>
                <mat-error class="qb-error" *ngIf="
                    !submitted &&
                    templateDispatchForm.controls.dispatchDate.touched &&
                    templateDispatchForm.controls.dispatchDate.hasError(
                      'required'
                    )
                  ">
                  {{'OFFER_PAGE.DATE_REQ' | i18nTranslate}}</mat-error>
                <mat-error class="qb-error" *ngIf="
                    submitted &&
                    templateDispatchForm.controls.dispatchDate.touched &&
                    templateDispatchForm.controls.dispatchDate.hasError(
                      'required'
                    )
                  ">
                  {{'OFFER_PAGE.DATE_REQ' |i18nTranslate}}</mat-error>
              </div>
              <div class="qb-customer-segments">
                <div class="qb-schedule-bold-text">{{'OFFER_PAGE.TIME' | i18nTranslate}}</div>
                <div class="qb-form-div-schedule qb-full-width cursor-pointer" (click)="pickerTo.open()" [ngClass]="{
                    active1:
                      templateDispatchForm.controls.dispatchTime.value ==
                        null ||
                      templateDispatchForm.controls.dispatchTime.value.length ==
                        0
                  }">
                  <input #dispatchTimeSelect class="qb-no-border-date cursor-pointer" formControlName="dispatchTime"
                    [format]="serviceCountry === 'US' ? 12 : 24" [ngxTimepicker]="pickerTo" [min]="currentTimeMin"
                    [max]="(selectedChannelList.indexOf('SMS') !== -1) ? '08:55 PM' : '23:59'" readonly />
                  <ngx-material-timepicker #pickerTo></ngx-material-timepicker>
                  <img [ngxTimepicker]="pickerTo" style="margin-right: 10px; cursor: pointer;"
                    src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/icons/negotiator/time.svg"
                    alt="" (closed)="startTimeChange()" />
                </div>
                <mat-error class="qb-error" *ngIf="
                    !submitted &&
                    templateDispatchForm.controls.dispatchTime.touched &&
                    templateDispatchForm.controls.dispatchTime.hasError(
                      'required'
                    )
                  ">
                  {{'OFFER_PAGE.TIME_REQ' | i18nTranslate}}</mat-error>
                <mat-error class="qb-error" *ngIf="
                    submitted &&
                    templateDispatchForm.controls.dispatchTime.touched &&
                    templateDispatchForm.controls.dispatchTime.hasError(
                      'required'
                    )
                  ">
                  {{'OFFER_PAGE.TIME_REQ' | i18nTranslate}}</mat-error>
              </div>
            </div>
            <div class="qb-show-warning" *ngIf="
                selectedChannel?.toUpperCase() == 'SMS' &&
                showSMSWarning != 1 &&
                templateDispatchForm.controls.dispatchType.value != 'SCHEDULED'
              ">
              <div class="qb-width7">
                <span popoverClass="qb-my-custom-class" placement="bottom">
                  <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9 1.5C6.65625 1.5 4.53125 2.75 3.34375 4.75C2.1875 6.78125 2.1875 9.25 3.34375 11.25C4.53125 13.2812 6.65625 14.5 9 14.5C11.3125 14.5 13.4375 13.2812 14.625 11.25C15.7812 9.25 15.7812 6.78125 14.625 4.75C13.4375 2.75 11.3125 1.5 9 1.5ZM9 16C6.125 16 3.5 14.5 2.0625 12C0.625 9.53125 0.625 6.5 2.0625 4C3.5 1.53125 6.125 0 9 0C11.8438 0 14.4688 1.53125 15.9062 4C17.3438 6.5 17.3438 9.53125 15.9062 12C14.4688 14.5 11.8438 16 9 16ZM7.75 10.5H8.5V8.5H7.75C7.3125 8.5 7 8.1875 7 7.75C7 7.34375 7.3125 7 7.75 7H9.25C9.65625 7 10 7.34375 10 7.75V10.5H10.25C10.6562 10.5 11 10.8438 11 11.25C11 11.6875 10.6562 12 10.25 12H7.75C7.3125 12 7 11.6875 7 11.25C7 10.8438 7.3125 10.5 7.75 10.5ZM9 6C8.4375 6 8 5.5625 8 5C8 4.46875 8.4375 4 9 4C9.53125 4 10 4.46875 10 5C10 5.5625 9.53125 6 9 6Z" fill="#f7941d"/>
                  </svg>   
                </span>
              </div>
              <div class="qb-width93">
                <div>
                  <span class="qb-sms-caution">{{'OFFER_PAGE.SMS_TIME_WARNING_2' | i18nTranslate}}
                  </span>
                </div>
                <div class="qb-warning-div">
                  <span class="qb-sms-caution qb-caution-div">
                    {{'OFFER_PAGE.SEND_SMS_IN_NEXT_SLOT' | i18nTranslate}}</span>
                  <span (click)="convertDispatchType('SCHEDULED')" class="qb-confirm-link-text">{{'OFFER_PAGE.YES' |
                    i18nTranslate}}</span>
                </div>
              </div>
            </div>

            <div class="qb-show-warning" *ngIf="
                selectedChannel?.toUpperCase() == 'SMS' &&
                showSMSWarning == 1 &&
                templateDispatchForm.controls.dispatchType.value != 'SCHEDULED'
              ">
              <div class="qb-width7">
                <span popoverClass="qb-my-custom-class" placement="bottom">
                  <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9 1.5C6.65625 1.5 4.53125 2.75 3.34375 4.75C2.1875 6.78125 2.1875 9.25 3.34375 11.25C4.53125 13.2812 6.65625 14.5 9 14.5C11.3125 14.5 13.4375 13.2812 14.625 11.25C15.7812 9.25 15.7812 6.78125 14.625 4.75C13.4375 2.75 11.3125 1.5 9 1.5ZM9 16C6.125 16 3.5 14.5 2.0625 12C0.625 9.53125 0.625 6.5 2.0625 4C3.5 1.53125 6.125 0 9 0C11.8438 0 14.4688 1.53125 15.9062 4C17.3438 6.5 17.3438 9.53125 15.9062 12C14.4688 14.5 11.8438 16 9 16ZM7.75 10.5H8.5V8.5H7.75C7.3125 8.5 7 8.1875 7 7.75C7 7.34375 7.3125 7 7.75 7H9.25C9.65625 7 10 7.34375 10 7.75V10.5H10.25C10.6562 10.5 11 10.8438 11 11.25C11 11.6875 10.6562 12 10.25 12H7.75C7.3125 12 7 11.6875 7 11.25C7 10.8438 7.3125 10.5 7.75 10.5ZM9 6C8.4375 6 8 5.5625 8 5C8 4.46875 8.4375 4 9 4C9.53125 4 10 4.46875 10 5C10 5.5625 9.53125 6 9 6Z" fill="#00b759"/>
                  </svg>   
                </span>
              </div>
              <div class="qb-width93">
                <div>
                  <span class="qb-sms-green">{{'OFFER_PAGE.SEND_SMS_INSTANTLY_WITHIN_VALID' | i18nTranslate}}
                  </span>
                </div>
              </div>
            </div>
            <div class="qb-show-warning" *ngIf="
                selectedChannel?.toUpperCase() == 'SMS' &&
                templateDispatchForm.controls.dispatchType.value === 'SCHEDULED'
              ">
              <div class="qb-width7">
                <span popoverClass="qb-my-custom-class" placement="bottom">
                  <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9 1.5C6.65625 1.5 4.53125 2.75 3.34375 4.75C2.1875 6.78125 2.1875 9.25 3.34375 11.25C4.53125 13.2812 6.65625 14.5 9 14.5C11.3125 14.5 13.4375 13.2812 14.625 11.25C15.7812 9.25 15.7812 6.78125 14.625 4.75C13.4375 2.75 11.3125 1.5 9 1.5ZM9 16C6.125 16 3.5 14.5 2.0625 12C0.625 9.53125 0.625 6.5 2.0625 4C3.5 1.53125 6.125 0 9 0C11.8438 0 14.4688 1.53125 15.9062 4C17.3438 6.5 17.3438 9.53125 15.9062 12C14.4688 14.5 11.8438 16 9 16ZM7.75 10.5H8.5V8.5H7.75C7.3125 8.5 7 8.1875 7 7.75C7 7.34375 7.3125 7 7.75 7H9.25C9.65625 7 10 7.34375 10 7.75V10.5H10.25C10.6562 10.5 11 10.8438 11 11.25C11 11.6875 10.6562 12 10.25 12H7.75C7.3125 12 7 11.6875 7 11.25C7 10.8438 7.3125 10.5 7.75 10.5ZM9 6C8.4375 6 8 5.5625 8 5C8 4.46875 8.4375 4 9 4C9.53125 4 10 4.46875 10 5C10 5.5625 9.53125 6 9 6Z" fill="#f7941d"/>
                  </svg>   
                </span>
              </div>
              <div class="qb-width93">
                <span class="qb-sms-caution">{{'OFFER_PAGE.TELECOME_WARNING_INFO' | i18nTranslate}}
                </span>
              </div>
            </div>
            <!-- <div class="qb-store-wrapper">
              <div class="qb-store-container">
                <div class="qb-add-condition-button-container-mid qb-d-flex"
                  *ngIf="pos !== 'shopify' && isAdminPos">
                  <span (click)="addStoreData()" class="secondary-button qb-primary-button" data-button-name=" Add stores">
                    {{'OFFER_PAGE.ADD_STORES' |
                    i18nTranslate}}
                  </span>
                </div>
                <div *ngIf="pos !== 'shopify' && isAdminPos" (click)="viewStore()"
                  style="margin-bottom: 15px; color: #0183fb; cursor: pointer;">View Selected Stores</div>
              </div>
            </div> -->
          </div>
        </div>
        <div class="qb-seperator qb-line"></div>
        <div class="qb-footer-labels qb-labels">
          <div class="qb-cancel-button" (click)="sendcancelMessage()" data-button-name="Cancel:OFFER-CHANNEL-SCHEDULE">
            {{'OFFER_PAGE.CANCEL' | i18nTranslate}}
          </div>
          <div class="qb-confirm-button" (click)="saveOfferDispatchEmail()" *ngIf="
              !(
                selectedChannel?.toUpperCase() == 'SMS' &&
                showSMSWarning != 1 &&
                templateDispatchForm.controls.dispatchType.value != 'SCHEDULED'
              ) && action == 'DISPATCH'
            " data-button-name="Dispatch:Schedule" [attr.data-item-name]="
              'Capture Type:' + templateDispatchForm.controls.dispatchType.value
            ">
            <span *ngIf="action == 'DISPATCH'">{{templateDispatchForm.controls.dispatchType.value=='INSTANT'?'Send':'Schedule'}}</span>
          </div>
          <div class="qb-confirm-button" (click)="saveOfferDispatchEmail()" *ngIf="
              templateDispatchForm.controls.dispatchType.value != 'SCHEDULED' &&
              action != 'DISPATCH'
            " data-button-name="Dispatch: Save and Exit" [attr.data-item-name]="
              'Capture Type:' + templateDispatchForm.controls.dispatchType.value
            ">
            <span *ngIf="action != 'DISPATCH'">{{'OFFER_PAGE.SAVE' | i18nTranslate}}</span>
          </div>
          <div class="qb-confirm-button" *ngIf="
              selectedChannel?.toUpperCase() == 'SMS' &&
              showSMSWarning != 1 &&
              templateDispatchForm.controls.dispatchType.value != 'SCHEDULED' &&
              action == 'DISPATCH'
            " style="cursor: not-allowed;" [matTooltip]="'COMMON_PAGE.CURRENTLY_UNAVAILABLE_SEND' | i18nTranslate">
            {{'OFFER_PAGE.SCHEDULE' | i18nTranslate}}
          </div>
        </div>
      </div>
      <div class="qb-config-status" *ngIf="channelConfigureStatus">
        <div class="qb-config-label-first configLabelSecond">
          <div>{{'OFFER_PAGE.CHOOSE_TEMPLATE' | i18nTranslate}}</div>
          <div>
            <mat-icon (click)="channelConfigureStatus = false" class="cursor-pointer">close</mat-icon>
          </div>
        </div>
        <div class="qb-seperator"></div>
        <div class="qb-select-channel">
          <div *ngIf="selectedChannel?.toUpperCase() == 'EMAIL'" class="qb-select-div">
            <!-- <div class="qb-first-nav-title fs08-rem qb-create"> -->
              <!-- <div>
                <span class="qb-fs08-rem">Create New Template</span>
              </div>
              <div>
                <mat-icon
                  class="qb-cursor-pointer qb-add-icon"
                  (click)="editTemplate('new', 'template')"
                >
                  add_circle
                </mat-icon>
              </div> -->
            <!-- </div> -->
            <mat-divider></mat-divider>
            <div>
              <div class="qb-form-div-schedule qb-choose">
                <input class="qb-form-input qb-fs08-rem" [(ngModel)]="filterEmail.label" type="text"
                  placeholder="Search by name" />
              </div>
              <div class="qb-choose-template-offerschedule" id="NewTemplateExt">
                <div class="category-text-container" *ngIf="(offerDiscountTypeEmailTemplateList
                | labelPipe: filterEmail.label:'name').length != 0">
                  <div class="category-line"></div>
                  <span class="category-text">Discount Type Emails <span class="reco-text">Recommended</span></span>
                  <div class="category-line"></div>
                </div>
                <div *ngFor="
                    let template of offerDiscountTypeEmailTemplateList
                      | labelPipe: filterEmail.label:'name';
                    let i = index
                  " class="qb-template-card-offer2" (click)="
                    selectTemplate(
                      'EMAIL',
                      template['id'],
                      template['name'],
                      template['tableType']
                    );
                  " [style.border]="
                    selectedEmailTemplateId == template['id']
                      ? '3px solid #5dadf9'
                      : '3px solid #FFF'
                  ">
                  <!-- <div class="qb-width95">
                    <img
                      *ngIf="template['snapshotUrl']"
                      class="qb-image-class qb-full"
                      [src]="template['snapshotUrl']"
                      alt="No Preview Available"
                    />
                    <div
                      *ngIf="!template['snapshotUrl'] && templateLoader"
                      class="qb-spinner"
                    >
                      <mat-spinner diameter="45"></mat-spinner>
                    </div>
                  </div> -->
                  <!-- <div class="qb-template-name-div">
                    <div class="qb-overflow">
                      <span
                        [matTooltip]="template['layoutName']"
                        matTooltipClass="ex-tooltip-red1"
                        class="qb-layout-name"
                        >{{ template["name"] | limitTo: 18 }}</span
                      >
                    </div>
                  </div> -->
                  <div *ngIf="template != null" class="qb-layout">
                    <div class="qb-fs08-rem name-ellipsis" [matTooltip]="template['name']">
                      {{ template.name }}
                    </div>
                    <div class="qb-fs08-rem qb-layout-name2">
                      <span class="qb-preview" (click)="opensnapshotHtmlDialog(template.id,template.tableType)">
                        {{"OFFER_PAGE.PREVIEW" | i18nTranslate}}</span>
                    </div>
                  </div>
                </div>
                <div class="category-text-container" *ngIf="(otherEmailTemplateList
                | labelPipe: filterEmail.label:'name').length != 0">
                  <div class="category-line"></div>
                  <span class="category-text">Other Custom Emails</span>
                  <div class="category-line"></div>
                </div>
                <div *ngFor="
                    let template of otherEmailTemplateList
                      | labelPipe: filterEmail.label:'name';
                    let i = index
                  " class="qb-template-card-offer2" (click)="
                    selectTemplate(
                      'EMAIL',
                      template['id'],
                      template['name'],
                      template['tableType'],
                      true
                    );
                  " [style.border]="
                    selectedEmailTemplateId == template['id']
                      ? '3px solid #5dadf9'
                      : '3px solid #FFF'
                  ">
                  <!-- <div class="qb-width95">
                    <img
                      *ngIf="template['snapshotUrl']"
                      class="qb-image-class qb-full"
                      [src]="template['snapshotUrl']"
                      alt="No Preview Available"
                    />
                    <div
                      *ngIf="!template['snapshotUrl'] && templateLoader"
                      class="qb-spinner"
                    >
                      <mat-spinner diameter="45"></mat-spinner>
                    </div>
                  </div> -->
                  <!-- <div class="qb-template-name-div">
                    <div class="qb-overflow">
                      <span
                        [matTooltip]="template['layoutName']"
                        matTooltipClass="ex-tooltip-red1"
                        class="qb-layout-name"
                        >{{ template["name"] | limitTo: 18 }}</span
                      >
                    </div>
                  </div> -->
                  <div *ngIf="template != null" class="qb-layout">
                    <div class="qb-fs08-rem name-ellipsis" [matTooltip]="template['name']">
                      {{ template.name }}
                    </div>
                    <div class="qb-fs08-rem qb-layout-name2">
                      <span class="qb-preview" (click)="opensnapshotHtmlDialog(template.id,template.tableType)">
                        {{"OFFER_PAGE.PREVIEW" | i18nTranslate}}</span>
                    </div>
                  </div>
                </div>
                <div *ngIf="(offerDiscountTypeEmailTemplateList
                  | labelPipe: filterEmail.label:'name').length == 0 && (otherEmailTemplateList
                  | labelPipe: filterEmail.label:'name').length == 0" class="no-data">
                  {{'SHARED_PAGE.NO_DATA_AVAILABLE' | i18nTranslate}}
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="selectedChannel?.toUpperCase() == 'SMS'" class="qb-selected-channel2">
            <div>
              <div class="qb-form-div-schedule qb-choose">
                <input class="qb-form-input fs08-rem" [(ngModel)]="filter.label" type="text"
                  placeholder="Search by name" />
              </div>
              <div class="qb-choose-template-offer-schedule" id="NewTemplateExt">
                <div class="category-text-container" *ngIf="(smsTemplateList
                | labelPipe: filter.label:'name').length != 0">
                  <div class="category-line"></div>
                  <span class="category-text">Discount Type SMS <span class="reco-text">Recommended</span></span>
                  <div class="category-line"></div>
                </div>
                <div *ngFor="
                    let layout of smsTemplateList
                      | labelPipe: filter.label:'name';
                    let i = index
                  " class="qb-template-card-offer2" (click)="selectSMStemplateOBJ(layout, layout['id'])"
                  [style.border]="
                    selectedSmsTemporaryId == layout['id']
                      ? '3px solid #5dadf9'
                      : '3px solid #FFF'
                  ">
                  <div *ngIf="layout != null" class="qb-layout">
                    <div class="qb-fs08-rem qb-layout-name2">
                      <span [matTooltip]="layout['name']" matTooltipClass="ex-tooltip-red1">{{ layout.name | limitTo: 18
                        }}</span>
                    </div>
                    <div class="qb-fs08-rem qb-layout-name2">
                      <span class="qb-preview" (click)="openSMSPreview(layout)">
                        {{'OFFER_PAGE.PREVIEW' | i18nTranslate}}</span>
                    </div>
                  </div>
                </div>
                <div class="category-text-container" *ngIf="(customSmsTemplateList
                | labelPipe: filter.label:'name').length != 0">
                  <div class="category-line"></div>
                  <span class="category-text">Custom SMS</span>
                  <div class="category-line"></div>
                </div>
                <div *ngFor="
                    let layout of customSmsTemplateList
                      | labelPipe: filter.label:'name';
                    let i = index
                  " class="qb-template-card-offer2" (click)="selectSMStemplateOBJ(layout, layout['id'],true)"
                  [style.border]="
                    selectedSmsTemporaryId == layout['id']
                      ? '3px solid #5dadf9'
                      : '3px solid #FFF'
                  ">
                  <div *ngIf="layout != null" class="qb-layout">
                    <div class="qb-fs08-rem qb-layout-name2">
                      <span [matTooltip]="layout['name']" matTooltipClass="ex-tooltip-red1">{{ layout.name | limitTo: 18
                        }}</span>
                    </div>
                    <div class="qb-fs08-rem qb-layout-name2">
                      <span class="qb-preview" (click)="openSMSPreview(layout)">
                        {{'OFFER_PAGE.PREVIEW' | i18nTranslate}}</span>
                    </div>
                  </div>
                </div>
                <div *ngIf="(smsTemplateList
                | labelPipe: filter.label:'name').length == 0 && (customSmsTemplateList
                | labelPipe: filter.label:'name').length == 0" class="no-data">
                  {{'SHARED_PAGE.NO_DATA_AVAILABLE' | i18nTranslate}}
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="selectedChannel?.toUpperCase() == 'WHATSAPP'" class="qb-selected-channel2">
            <div>
              <div class="qb-form-div-schedule qb-choose">
                <input class="qb-form-input fs08-rem" [(ngModel)]="filterWhatsApp.label" type="text"
                  placeholder="Search by name" />
              </div>
              <div class="qb-choose-template-offer-schedule" id="NewTemplateExt">
                <div *ngFor="
                    let layout of whatsAppTemplateList
                      | labelPipe: filterWhatsApp.label:'name';
                    let i = index
                  " class="qb-template-card-offer2" (click)="selectWhatsApptemplateOBJ(layout, layout['id'])"
                  [style.border]="
                  selectedWhatsAppTemporaryId == layout['id']
                      ? '3px solid #4abb5975'
                      : '3px solid #FFF'
                  ">
                  <div *ngIf="layout != null" class="qb-layout">
                    <div class="qb-fs08-rem qb-layout-name2">
                      <span [matTooltip]="layout['name']" matTooltipClass="ex-tooltip-red1">{{ layout.name | limitTo: 40
                        }}</span>
                    </div>
                    <div class="qb-fs08-rem qb-layout-name2">
                      <span class="qb-preview" (click)="openWhatsAppPreview(layout['layoutData'])">
                        {{'OFFER_PAGE.PREVIEW' | i18nTranslate}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="qb-seperator" *ngIf="selectedChannel?.toUpperCase() == 'SMS' || 'WHATSAPP'"></div>
        <div class="qb-footer-labels qb-labels2" *ngIf="selectedChannel?.toUpperCase() == 'SMS'">
          <div class="qb-cancel-button" (click)="cancelSMSSave()">{{'OFFER_PAGE.CANCEL' | i18nTranslate}}</div>
          <div class="qb-confirm-button" (click)="onSMSSave('schedule')">
            {{'OFFER_PAGE.SAVE' | i18nTranslate}}
          </div>
        </div>
        <div class="qb-footer-labels qb-labels2" *ngIf="selectedChannel?.toUpperCase() == 'WHATSAPP'">
          <div class="qb-cancel-button" (click)="cancelWhatsAppSave()">{{'OFFER_PAGE.CANCEL' | i18nTranslate}}</div>
          <div class="qb-confirm-button" (click)="onWhatsAppSave()">
            {{'OFFER_PAGE.SAVE' | i18nTranslate}}
          </div>
        </div>
        <div class="qb-footer-labels qb-labels2" *ngIf="selectedChannel?.toUpperCase() == 'EMAIL'">
          <div class="qb-cancel-button" (click)="cancelEmailSave()">{{'OFFER_PAGE.CANCEL' | i18nTranslate}}</div>
          <div class="qb-confirm-button" (click)="onEmailSave()">
            {{'OFFER_PAGE.SAVE' | i18nTranslate}}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #popContentChannel>
  <div class="qb-flex" *ngIf="(validScreens | screenConfig: 18)">
    <div class="qb-flex2">
      <mat-icon class="qb-temp"> lightbulb_outline </mat-icon>
    </div>
    <div class="qb-width90">
      <div class="qb-choose-channel">
        {{'OFFER_PAGE.CHANNEL_OF_COMMUNICATION' | i18nTranslate}}
      </div>
    </div>
  </div>
</ng-template>
<ng-template #popContentSelectChannel>
  <div class="qb-flex" *ngIf="(validScreens | screenConfig: 18)">
    <div class="qb-flex2">
      <mat-icon class="qb-temp"> lightbulb_outline </mat-icon>
    </div>
    <div class="qb-width90">
      <div class="qb-choose-channel">
        {{'OFFER_PAGE.SELECT_CHANNEL_OF_COMMUNICATION' | i18nTranslate}}
        <br>
        {{ 'OFFER_PAGE.APP_ONLY_MESSAGE' | i18nTranslate }}
      </div>
    </div>
  </div>
</ng-template>
<ng-template #popContentChallengeTemplate>
  <div class="qb-flex">
    <div class="qb-flex2">
      <mat-icon class="qb-bulb-icon"> lightbulb_outline </mat-icon>
    </div>
    <div class="qb-width90">
      <div class="qb-choose-channel">
        {{'OFFER_PAGE.CHOOSE_OR_CREATE_TEMPLATE' | i18nTranslate}}
      </div>
    </div>
  </div>
</ng-template>
<ng-template #popContentRewardTemplate>
  <div class="qb-flex">
    <div class="qb-flex2">
      <mat-icon class="qb-temp"> lightbulb_outline </mat-icon>
    </div>
    <div class="qb-width90" style="color: white;">
      <div class="qb-choose-channel" *ngIf="rawJson['isNoneBarrierDetected'] != true">
        {{'OFFER_PAGE.PREVIEW_EDIT_TEMPLATE_MESSAGE' | i18nTranslate}}
        <span *ngIf="(validScreens | screenConfig: 18)">{{'OFFER_PAGE.SMS_TEMPLATE_CANT_BE_EDITED_ONLY' |
          i18nTranslate}}</span>
      </div>
      <div class="qb-no-preview" *ngIf="rawJson['isNoneBarrierDetected'] == true">
        {{"OFFER_PAGE.PREVIEW_TEMPLATE_REQ" | i18nTranslate}}
        <span *ngIf="(validScreens | screenConfig: 18)">
          {{"OFFER_PAGE.SMS_TEMPLATE_CANT_BE_EDITED_ONLY" | i18nTranslate}}
        </span>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #popContentCustomerSegments>
  <div class="qb-flex">
    <div class="qb-flex2">
      <mat-icon class="qb-temp"> lightbulb_outline </mat-icon>
    </div>
    <div class="qb-width90">
      <div class="qb-choose-channel">
        {{'OFFER_PAGE.CHOOSE_TARGET_SEGMENT_TIER_OFFER_SENT' | i18nTranslate}}
      </div>
    </div>
  </div>
</ng-template>
<ng-template #popContentExcludeCustomerSegments>
  <div class="qb-flex">
    <div class="qb-flex2">
      <mat-icon class="qb-temp"> lightbulb_outline </mat-icon>
    </div>
    <div class="qb-width90">
      <div class="qb-choose-channel">
        {{'OFFER_PAGE.CHOOSE_EXCLUSION_SEGMENT_TIER_OFFER_SENT' | i18nTranslate}}
      </div>
    </div>
  </div>
</ng-template>
<ng-template #popContentCustomerStores>
  <div class="qb-flex">
    <div class="qb-flex2">
      <mat-icon class="qb-temp"> lightbulb_outline </mat-icon>
    </div>
    <div class="qb-width90">
      <div class="qb-choose-channel">
        Only customers from the selected store will receive the offer
      </div>
    </div>
  </div>
</ng-template>
<ng-template #popContentDispatchType>
  <div class="qb-flex">
    <div class="qb-flex2">
      <mat-icon class="qb-temp"> lightbulb_outline </mat-icon>
    </div>
    <div class="qb-width90">
      <div class="qb-choose-channel">
        {{'OFFER_PAGE.SELECT_OFFER_INSTANTY_DATE_TIME' | i18nTranslate}}
      </div>
    </div>
  </div>
</ng-template>