import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TokenStorageService } from 'src/app/core/auth/token-storage.service';
import { ApiCallService } from 'src/app/core/services/api-call-service';
import { SnackbarCollection } from 'src/app/core/services/snackbar.service';

@Component({
  selector: 'app-send-test-sms-dialog',
  templateUrl: './send-test-sms-dialog.component.html',
  styleUrls: ['./send-test-sms-dialog.component.css']
})
export class SendTestSmsDialogComponent implements OnInit {
  constructor(
    private tokenStorage: TokenStorageService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiCall: ApiCallService,
    private dialogRef: MatDialogRef<SendTestSmsDialogComponent>
  ) {
    window["screen-name"] = "SUPPRESSION LISTS";
  }
  cancel = true;
  error = "";
  smsno = "";
  country;

  ngOnInit() {
  this.country = sessionStorage.getItem("serviceCountry")

  }

  keypress() {
    this.error = "";
    }

  sendTestSMS() {
    if (this.smsno.length>0) {
      this.dialogRef.close(this.smsno);
      }
      else{
        this.error="Enter a valid mobile number"
      }
     }

  telInputObject(obj) {}
    
  getNumber(obj) {
    console.log(obj);
    this.smsno = obj;
    }

  onCountryChange(obj) {}
    
  keypresssms(event) {}

  }

