import { DatePipe } from "@angular/common";
import {
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ViewChild,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { TokenStorageService } from "src/app/core/auth/token-storage.service";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { create360dialogaccoountService } from "src/app/core/services/create-360-dialog-account-service";
import { SnackbarCollection } from "src/app/core/services/snackbar.service";
import { WidgetConfigService } from "src/app/core/services/widget-config.service";
import {
  ConfirmationDialogBoxComponent,
  ContactUsDialogComponent,
  HtmlDataDialogComponent,
  HtmlForEmailTemplatesDialogComponent,
  OfferSummaryDialogComponent,
  SmsPreviewDialogComponent,
  SmsPreviewIdDialogComponent,
  OfferSummaryDialogV2Component
} from "src/app/shared/components/dialog-box";
import { SelectStoreDialogBoxComponent } from "src/app/shared/components/dialog-box/select-store-dialog-box/select-store-dialog-box.component";
import { ViewStoreDialogBoxComponent } from "src/app/shared/components/dialog-box/view-store-dialog-box/view-store-dialog-box.component";
import { OfferAttachEmailDialogComponent } from "../../pages";
import { getdateformatService } from "src/app/core/services/get-date-format.service";
import { TranslateDynamicText } from "src/app/shared/pipes/dynamic-translation.pipe";
import { table } from "console";

@Component({
  selector: "app-offer-schedule-overlay",
  templateUrl: "./offer-schedule-overlay.component.html",
  styleUrls: ["./offer-schedule-overlay.component.css"],
})
export class OfferScheduleOverlayComponent implements OnInit, OnDestroy {
  message: string = "Hola Mundo!";
  @ViewChild("customerSegmentsSelect") customerSegmentsSelect: any;
  @ViewChild("dispatchTypeSelect") dispatchTypeSelect: any;
  @ViewChild("dispatchDateSelect") dispatchDateSelect: any;
  @ViewChild("dispatchTimeSelect") dispatchTimeSelect: any;
  @ViewChild("targetedStoresSelect") targetedStoresSelect: any;
  @ViewChild("customerSegmentsExclusion") customerSegmentsExclusion:any;
  @Output() messageEvent = new EventEmitter<any>();
  @Input() offerId: number;
  @Input() offerReqBodyObj: any;
  @Input() BarrierRewardFull: any;
  @Input() action: any;
  @Input() popUpMode: boolean = false;
  @Input() isLImitedTime: boolean;
  @Input() discountOn: string;
  sendcancelMessage() {
    this.messageEvent.emit(this.message);
  }
  barrierReward;
  selectedChannel;
  selectedChannelList = [];
  modifyDivOpened = false;
  channelConfigureStatus = false;
  additionallayoutSet = [];
  templateList = [];
  offerdataFetched;
  selectedbarrier;
  offerType;
  selectedEmailTemplateId;
  selectedEmailTemplateName;
  selectedTemplateTableType;
  isNonDiscountTypeEmailPicked = false;
  isNonDiscountTypeSMSPicked = false;
  channelSectionOpen = false;
  data = {
    process: "create",
    id: "null",
    tags: [],
    imageTagStatus: true,
    offerId: null,
    saveName: null,
    isReward: false,
    type: "EMAIL_LAYOUT_HTML",
    rewardType: null,
  };
  apicount = 5;
  filter = {
    label: "",
  };
  filterWhatsApp = {
    label: "",
  };
  filterEmail = {
    label: "",
  };
  allcustomerObj = [
    { id: "ALL_CUSTOMERS", name: "My Customers" },
    { id: "LEADS", name: "All Leads" },
  ];
  layouts;
  templateDispatchForm;
  disableCustomerSegmentSelection;
  disableAllCustomerSelection;
  selectedSegments;
  selectedExclusionSegments;
  segments = [];
  todayDate;
  finalData;
  templateId;
  templatesnapshotUrl;
  emailLayoutName;
  emailLayoutId;
  recAction: boolean = false;
  recomentedSegments;
  htmlrend;
  subject;
  htmlloading: boolean = false;
  layoutSnapshotUrl = null;
  submitted: boolean = false;
  apiCount = 0;
  loading;
  fullLayoutList = [];
  emailId;
  finalScreenHeight;
  finalScreenWidth;
  scrHeight;
  scrWidth;
  masterOffer;
  OfferName;
  saveSurevyClicked: boolean = false;
  emailLayoutSaved: boolean = false;
  emailTemplateSnapshotUrl;
  displayParameter;
  radioStatus = 0;
  selectedTemplateData;
  layoutPlusTemplateList = [];
  newLayoutDetails = { id: null, layoutSnapshotUrl: null, layoutName: null };
  layoutStorage = [];
  interval;
  destroyed: boolean = false;
  rewardTemplateList = [];
  rawJson;
  preEmailTemplateName;
  preemailId;
  presmsLayoutId = null;
  presmsrewardId = null;
  presmsTemplateId;
  presmsTemplateName;
  presmsrewadLayoutName;
  prewhatsappLayoutName;
  prewhatsappLayoutId = null;
  prewhatsappLayoutData = null;
  preemailLayoutId = null;
  preemailLayoutTemplateId;
  preemailLayoutName;
  preemailTableType;
  prerewardId;
  prewhatsapprewardId = null;
  mainRewardtemplate;
  barrierofZero;
  rewardofZero;
  rewardTypeAndName = {
    BUY_X_GET_Y: "Buy X Get Y Reward Email",
    PERCENTAGE_OFF: "Percentage Off Reward Email",
    FIXED_AMOUNT_OFF: "Fixed Amount Off Reward Email",
    FREE_SHIPPING: "Free Shipping Reward Email",
  };
  smsResponse;
  whatsAppResponse;
  emailResponse;
  selectedSmsOfferPart;
  selectedSmsTemporaryId = null;
  selectedWhatsAppTemporaryId = null;
  smsTemplateList;
  whatsAppTemplateList;
  offerDiscountTypeEmailTemplateList;
  otherEmailTemplateList;
  barrierSMSLayoutJson;
  rewardSMSLayoutJson;
  rewardWhatsAppLayoutJson;
  countryCode;
  timeISTpreWindow;
  timeISTpostWindow;
  currentUTC;
  staticUTCWindow;
  showSMSWarning = 2;
  startDateMinValue;
  currentDateTime;
  currentTimeMin;
  timeMax = "23:59";
  customerCount = 0;
  NotAllowedCustomer = 0;
  totalCustomer = 0;
  InvalidCustomer = 0;
  UnsubscribedCount = 0;
  FirstTimeCustomer = 0;
  ConsentGivenCount = 0;
  ValidCustomerCount = 0;
  CustomerNotInUSA = 0;
  validScreens;
  isOnboarded = "false";
  pos = "";
  customerSegmentList = [];
  customerTierList = [];
  customerList = [];
  employeeList = [];
  isAdminPos = false;
  adminCreated = {
    storeId: null,
    storeName: "ADMIN",
    qid: null,
    userName: this.tokenStorage.getUsernameFull(),
    userEmail: this.tokenStorage.getMerchantUserEmail()
  };
  storeId;
  storeDataJSON = {};
  offerEmailSubject: string;
  offerEmailSubjectErrorText = null;
  offerPushNotificationSubject: string;
  offerPushNotificationErrorText = null;
  offerPushTitleAlias: string;
  offerPushNotificationBody: string;
  serviceCountry = sessionStorage.getItem("serviceCountry");
  @HostListener("window:resize", ["$event"])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
  }
  channels = ["SMS", "Email", "WhatsApp", "appOnly", "POS", "pushNotification"];
  errorChannelSelect = "";
  attachEmailError = "";
  attachSmsError = "";
  attachWhatsappError = "";
  smsNoOfCustomersZeroError = "";
  customSmsTemplateList = [];
  isAllStoresSelected: boolean = true;
  previousStoreIdList = [];
  storeCreatedCustomList = []; 
  otherCustomList = [];
  storeCreatedSegments = [];
  otherSegments= [];


  constructor(
    private apiCall: ApiCallService,
    private snackBar: SnackbarCollection,
    private router: Router,
    private datePipe: DatePipe,
    private tokenStorage: TokenStorageService,
    public dialog: MatDialog,
    private _WidgetConfigService: WidgetConfigService,
    private create360dialog: create360dialogaccoountService,
    private date_format: getdateformatService,
    private _i18nDynamicTranslate: TranslateDynamicText,
  ) {
    this.getScreenSize();
  }

  ngOnDestroy() {
    this.modifyDivOpened = false;
  }

  ngOnInit() {
    this.storeId = this.tokenStorage.getqId() === 'null' ? null : this.tokenStorage.getqId();
    if (this.tokenStorage.getqId() != "null") {
      this.getParticularStoreJSON();
      this.getParticularStore();
      this.isAdminPos = false;
    } else {
      // this.getStores();
      this.isAdminPos = true;
    }
    if (this.isLImitedTime) {
      this.channels = ["appOnly", "POS"];
    }
    this.getCustomerSegmentsAndTiers();
    this.isOnboarded = this.tokenStorage.getIsAccountCreated();
    this.pos = this.tokenStorage.getMerchantOnlineStore();
    this.getScreenId();
    this.checkIfOnBoarded();
    var currentDate = new Date();
    this.startDateMinValue = new Date(
      currentDate.toLocaleString("en-US", {
        timeZone: this.tokenStorage.getMerchantTimeZone(),
      })
    );
    this.currentDateTime = new Date(
      currentDate.toLocaleString("en-US", {
        timeZone: this.tokenStorage.getMerchantTimeZone(),
      })
    );
    var m = new Date().getUTCDate();
    const activationDate = new Date();
    this.currentUTC = new Date(
      activationDate.getUTCFullYear(),
      activationDate.getUTCMonth(),
      activationDate.getUTCDate(),
      activationDate.getUTCHours(),
      activationDate.getUTCMinutes(),
      activationDate.getUTCSeconds()
    );
    var s = new Date(activationDate).toLocaleString(undefined, {
      timeZone:
        this.pos === "shopify"
          ? "Asia/Kolkata"
          : this.tokenStorage.getMerchantTimeZone(),
      hour12: false,
    });
    let staticTimeWindow0830 = new Date("01/01/2011 20:55");
    this.staticUTCWindow = new Date(
      staticTimeWindow0830.getUTCFullYear(),
      staticTimeWindow0830.getUTCMonth(),
      staticTimeWindow0830.getUTCDate(),
      staticTimeWindow0830.getUTCHours(),
      staticTimeWindow0830.getUTCMinutes(),
      staticTimeWindow0830.getUTCSeconds()
    );
    var r = new Date(staticTimeWindow0830).toLocaleString(undefined, {
      timeZone: "Asia/Kolkata",
      hour12: false,
    });
    let staticTimeWindow0900 = new Date("01/01/2011 10:00");
    let static930 = new Date(
      staticTimeWindow0900.getUTCFullYear(),
      staticTimeWindow0900.getUTCMonth(),
      staticTimeWindow0900.getUTCDate(),
      staticTimeWindow0900.getUTCHours(),
      staticTimeWindow0900.getUTCMinutes(),
      staticTimeWindow0900.getUTCSeconds()
    );
    var t = new Date(staticTimeWindow0900).toLocaleString(undefined, {
      timeZone: "Asia/Kolkata",
      hour12: false,
    });
    if (
      this.dateCompare(r.split(",")[1], s.split(",")[1]) == 1 &&
      this.dateCompare(s.split(",")[1], t.split(",")[1]) == 1
    ) {
      this.showSMSWarning = 1;
    }
    this.apiCall.getMerchantDetails().subscribe((response) => {
      let data = JSON.parse(response["body"]);
      this.countryCode = data.country;
    });
    // console.log("REQBODY",this.offerReqBodyObj)
    this.rawJson = JSON.parse(this.offerReqBodyObj["offerJson"]);
    // this.selectedChannel = this.rawJson.channel == "SMS" ? "SMS" : "EMAIL";
    // if (this.rawJson.channel === null) {
    //   this.selectedChannel = "EMAIL";
    // } else {
    //   this.selectedChannel = this.rawJson.channel;
    // }
    // console.log("RAWJSON", this.rawJson);
    this.prerewardId =
      this.rawJson["barrierRewards"][0]["reward"]["emailLayoutId"];
    this.barrierofZero = this.rawJson["barrierRewards"][0]["barrier"];
    this.emailId = this.rawJson["emailLayoutId"];
    this.rewardofZero = this.rawJson["barrierRewards"][0]["reward"];
    this.modifyDivOpened = true;
    this.preemailLayoutId = this.rawJson["emailLayoutId"];
    this.presmsTemplateName = this.rawJson["smsTemplateName"];
    if (this.selectedChannel === "SMS") {
      this.presmsrewadLayoutName =
        this.rawJson["barrierRewards"][0]["reward"]["rewardLayoutName"];
      this.presmsrewardId =
        this.rawJson["barrierRewards"][0]["reward"]["layoutId"];
      this.presmsLayoutId = this.rawJson["layoutId"];
    }
    if (this.selectedChannel === "WHATSAPP") {
      this.prewhatsappLayoutName =
        this.rawJson["barrierRewards"][0]["reward"]["rewardLayoutName"];
      this.prewhatsappLayoutId =
        this.rawJson["barrierRewards"][0]["reward"]["layoutId"];
    }
    this.getEmailTemplate(this.preemailLayoutId);
    this.getSelectedChannelList();
    this.getParticularOfferDetail();
    this.getrewardemail(this.preemailLayoutId);
    this.getTemplates(this.preemailLayoutId);
    this.initSchedule();
    this.getOfferSMSTemplate(this.presmsrewardId);
    this.getAllCustomSmsTemplates(this.presmsrewardId);
    this.getWhatsAppTemplate();

    this.selectedChannel = this.selectedChannelList[0];
    console.log(this.selectedChannelList);
    console.log(this.selectedChannel);

    this.getStores();
  }
  async getScreenId() {
    this.validScreens = await this._WidgetConfigService.getScreen();
    let index = this.validScreens?.findIndex(d => d === this.getId("leads"));
    if(index === -1){
      this.allcustomerObj = this.allcustomerObj.filter(item => item.id !== "LEADS");
    }
  }

  prefillScheduleData() {
    this.selectedChannel = this.rawJson.channel == "EMAIL" ? "EMAIL" : "SMS";
    this.preEmailTemplateName = this.rawJson.emailTemplateName;
  }

  selectChannel(value) {
    this.channelConfigureStatus = false;
    this.selectedChannel = value;
    if (this.templateDispatchForm["value"]["dispatchType"] === "SCHEDULED") {
      this.checkTypeTime();
    }
    if (this.selectedChannelList.indexOf("SMS") === -1) {
      this.currentTimeMin = "00:00";
    } else {
      this.currentTimeMin = "10:00 AM";
    }
  }

  initSchedule() {
    this.todayDate = new Date();
    this.todayDate = this.todayDate.toLocaleTimeString();
    this.recAction = this.data["recomendedAction"];
    this.templatesnapshotUrl = this.data["emailtemplatesnapshotUrl"];
    this.emailLayoutName = this.data["emailLayoutName"];
    this.templateDispatchForm = new FormGroup({
      dispatchType: new FormControl("INSTANT", [Validators.required]),
      dispatchDate: new FormControl(null, [Validators.required]),
      dispatchTime: new FormControl(null, [Validators.required]),
      dispatchcustomerSegments: new FormControl("", [Validators.required]),
      dispatchexclusioncustomerSegments: new FormControl(""),
      targetedStores: new FormControl(),
      tempsegmentFilter: new FormControl(""),
      tempexclusionsegmentFilter: new FormControl(""),
      tempstoreFilter: new FormControl(""),
      isCustomerId: new FormControl(),
    });
    this.startDateChange();
    this.getCustomerData();
  }

  getCustomerData() {
    this.apiCall.getAllCustomerSegments().subscribe((response) => {
      let segment = response["body"];
      for (let i = 0; i < segment.length; i++) {
        this.segments.push({
          id: segment[i]["id"],
          name: segment[i]["segmentName"],
          isActive: segment[i]["isActive"],
          status: false,
        });
      }
    });
  }

  getAllCustomSmsTemplates(preSelectId?) {
    this.customSmsTemplateList = [];
    let layoutAPI = this.apiCall
      .getSmsTemplates("SMS", "SMS")
      .subscribe((response) => {
        let smsList = response["body"];
        if (smsList.length > 0) {
          this.customSmsTemplateList = smsList.filter(
            (layout) => layout["layoutType"] == "CUSTOM"
          );
          this.customSmsTemplateList.sort((a, b) => {
            const dateA: Date = new Date(a.lastModifiedTime);
            const dateB: Date = new Date(b.lastModifiedTime);
            return dateB.getTime() - dateA.getTime();
          });
          if (preSelectId) {
            const preSelectedTempalte = this.customSmsTemplateList.find(
              (template) => (template.id = preSelectId)
            );
            this.presmsrewadLayoutName = preSelectedTempalte.name;
            this.presmsrewardId = preSelectedTempalte.id;
          }
        }
      });
  }

  launchDateConfig() {
    var launchData;
    var currentTime = new Date();
    if (this.templateDispatchForm["value"]["dispatchType"] != "SCHEDULED") {
      var launchdate = this.datePipe.transform(
        currentTime.toDateString(),
        "yyyy-MM-dd"
      );
      launchData =
        launchdate + "T" + currentTime.toTimeString().split("GMT")[0];
    } else {
      var launchdate = this.datePipe.transform(
        this.templateDispatchForm["value"]["dispatchDate"].toDateString(),
        "yyyy-MM-dd"
      );
      launchData =
        launchdate +
        "T" +
        this.transformTo24HourFormat(
          this.templateDispatchForm.get("dispatchTime").value
        ).split(" ")[0] +
        ":00";
    }
    return launchData;
  }

  changeSegmentSelection() {
    // console.log(this.templateDispatchForm.controls.dispatchcustomerSegments.value, this.selectedSegments)
    // if (
    //   this.templateDispatchForm.controls.dispatchcustomerSegments["value"]
    //     .length === 1
    // ) {
    const hasNumber =
      this.templateDispatchForm.controls.dispatchcustomerSegments["value"].some(
        (item) => typeof item === "number"
      );
    if (
      this.templateDispatchForm.controls.dispatchcustomerSegments[
        "value"
      ].includes("ALL_CUSTOMERS")
    ) {
      this.disableCustomerSegmentSelection = true;
    } else if (
      !this.templateDispatchForm.controls.dispatchcustomerSegments[
        "value"
      ].includes("ALL_CUSTOMERS") &&
      hasNumber
    ) {
      this.disableAllCustomerSelection = true;
    }
    else{
      this.disableAllCustomerSelection = false;
      this.disableCustomerSegmentSelection = false;
    }
    if (
      this.templateDispatchForm.controls.dispatchcustomerSegments["value"]
        .length === 0
    ) {
      this.selectedSegments = [];
      this.disableAllCustomerSelection = false;
      this.disableCustomerSegmentSelection = false;
      this.customerCount = 0;
      this.totalCustomer = 0;
      this.InvalidCustomer = 0;
      this.NotAllowedCustomer = 0;
      this.FirstTimeCustomer = 0;
      this.CustomerNotInUSA = 0;
    }
    this.getCustomerCount();
  }

  selectStoreInDropdown(storeId) {
    let storeIdList = [...this.previousStoreIdList];
  
    if (storeId === -1) {
      if (!storeIdList.includes(-1)) {
        storeIdList = [-1, ...this.storeData.map(store => store.qid)];
      } else {
        storeIdList = [];
      }
    } 
    else {
      const index = storeIdList.indexOf(storeId);
      if (index === -1) {
        storeIdList.push(storeId);
      } else {
        storeIdList.splice(index, 1);
      }
  
      if (storeIdList.includes(-1) && storeIdList.length > 1) {
        storeIdList = storeIdList.filter(id => id !== -1);
      }
      const allSelected = this.storeData.every(store => storeIdList.includes(store.qid));
      if (allSelected && !storeIdList.includes(-1)) {
        storeIdList.push(-1);
      }
    }
    this.templateDispatchForm.controls.targetedStores.setValue(storeIdList);
    this.previousStoreIdList = [...storeIdList];
  }

  launchOffer() {
    var isCustSegmented = true;
    const hasNumber =
      this.templateDispatchForm.controls.dispatchcustomerSegments["value"]?.some(
        (item) => typeof item === "number"
      );
    this.submitted = true;
    if (
      this.templateDispatchForm["value"]["dispatchType"] == "SCHEDULED" &&
      !this.templateDispatchForm.valid
    ) {
      this.snackBar.openSnackBar(
        this._i18nDynamicTranslate.transform("Fill all the forms", [
          "POS_PAGE",
        ]),
        2000
      );
      return;
    } else {
      if (
        this.templateDispatchForm["value"]["dispatchType"] != "SCHEDULED" &&
        this.templateDispatchForm.controls.dispatchcustomerSegments["value"]
          .length == 0
      ) {
        this.snackBar.openSnackBar(
          this._i18nDynamicTranslate.transform("Fill all the forms", [
            "POS_PAGE",
          ]),
          2000
        );
        return;
      }
    }
    if (
      this.templateDispatchForm.controls.dispatchcustomerSegments[
        "value"
      ].includes("ALL_CUSTOMERS")
    ) {
      isCustSegmented = false;
    }
    if (
      !this.templateDispatchForm.controls.dispatchcustomerSegments[
        "value"
      ].includes("ALL_CUSTOMERS") &&
      hasNumber
    ) {
      isCustSegmented = true;
    }
    if(!hasNumber){
      isCustSegmented = false;
    }
    if (this.templateDispatchForm["value"]["dispatchType"] != "SCHEDULED") {
      this.finalData = {
        // channel: this.selectedChannel,
        segments:
        this.getOnlySegments(this.templateDispatchForm.controls.dispatchcustomerSegments.value),
        offerId: this.offerId,
        isSegmented: isCustSegmented,
        dispatchType: "INSTANT",
        // stores: this.storeData,
        rewardCommunication: JSON.stringify(this.getCommunication()),
        adminCreated: this.tokenStorage.getqId() === "null" ? true : false,
        allCustomer:
          this.templateDispatchForm.controls.dispatchcustomerSegments[
            "value"
          ].includes("ALL_CUSTOMERS")
            ? true
            : false,
        allLead: this.templateDispatchForm.controls.dispatchcustomerSegments[
          "value"
        ].includes("LEADS")
          ? true
          : false,
        createdBy:
          this.tokenStorage.getqId() === "null"
            ? this.adminCreated
            : this.storeDataJSON,
        isAllStoresSelected: this.isAllStoresSelected
      };
    } else {
      this.finalData = {
        channel: this.selectedChannel,
        segments:
        this.getOnlySegments(this.templateDispatchForm.controls.dispatchcustomerSegments.value),
        offerId: this.offerId,
        isSegmented: isCustSegmented,
        date: this.launchDateConfig(),
        dispatchType: "SCHEDULED",
        // stores: this.storeData,
        rewardCommunication: JSON.stringify(this.getCommunication()),
        adminCreated: this.tokenStorage.getqId() === "null" ? true : false,
        allCustomer:
          this.templateDispatchForm.controls.dispatchcustomerSegments[
            "value"
          ].includes("ALL_CUSTOMERS")
            ? true
            : false,
        allLead: this.templateDispatchForm.controls.dispatchcustomerSegments[
          "value"
        ].includes("LEADS")
          ? true
          : false,
        createdBy:
          this.tokenStorage.getqId() === "null"
            ? this.adminCreated
            : this.storeDataJSON,
        isAllStoresSelected: this.isAllStoresSelected
      };
    }
    //seperate the tiers and segements
    if (
      this.templateDispatchForm.controls.dispatchcustomerSegments.value &&
      this.templateDispatchForm.controls.dispatchcustomerSegments.value.length >
        0 &&
      isCustSegmented
    ) {
      this.finalData.segments = [];
      this.getOnlySegments(this.templateDispatchForm.controls.dispatchcustomerSegments.value).forEach(
        (selectedSegment) => {
          let req = [
            ...this.customerSegmentList,
            ...this.customerTierList,
            ...this.customerList,
            ...this.employeeList,
          ].find((segment) => selectedSegment === segment.id);
          if (req.category.toLowerCase() === "tier") {
            if (!this.finalData.hasOwnProperty("tiers")) {
              this.finalData["tiers"] = [];
            }
            this.finalData["tiers"].push(req.id);
          }
          if (req.category.toLowerCase() === "segment") {
            this.finalData["segments"].push(req.id);
          }
          if (req.category.toLowerCase() === "customerlist") {
            if (!this.finalData.hasOwnProperty("customerListIds")) {
              this.finalData["customerListIds"] = [];
            }
            this.finalData["customerListIds"].push(req.id);
          }
          if (req.category.toLowerCase() === "employeelist") {
            if (!this.finalData.hasOwnProperty("employeeListIds")) {
              this.finalData["employeeListIds"] = [];
            }
            this.finalData["employeeListIds"].push(req.id);
          }
        }
      );
    }
    // Check if there are excluded customer segments
    if (
      this.templateDispatchForm.controls.dispatchexclusioncustomerSegments.value &&
      this.templateDispatchForm.controls.dispatchexclusioncustomerSegments.value.length > 0
    ) {
      this.templateDispatchForm.controls.dispatchexclusioncustomerSegments.value.forEach((selectedSegment) => {
        let req = [
          ...this.customerSegmentList,
          ...this.customerTierList,
          ...this.customerList,
          ...this.employeeList,
        ].find((segment) => selectedSegment === segment.id);
        if (req) {
          if (this.templateDispatchForm.controls.dispatchexclusioncustomerSegments.value.includes(req.id)) {
            if (req.category.toLowerCase() === "tier") {
              if (!this.finalData.hasOwnProperty("excludedTiers")) {
                this.finalData["excludedTiers"] = [];
              }
              this.finalData["excludedTiers"].push(req.id); 
            }

            if (req.category.toLowerCase() === "segment") {
              if (!this.finalData.hasOwnProperty("excludedSegments")) {
                this.finalData["excludedSegments"] = [];
              }
              this.finalData["excludedSegments"].push(req.id); 
            }

            if (req.category.toLowerCase() === "customerlist") {
              if (!this.finalData.hasOwnProperty("excludedCustomerListIds")) {
                this.finalData["excludedCustomerListIds"] = [];
              }
              this.finalData["excludedCustomerListIds"].push(req.id); 
            }

            if (req.category.toLowerCase() === "employeelist") {
              if (!this.finalData.hasOwnProperty("excludedEmployeeListIds")) {
                this.finalData["excludedEmployeeListIds"] = [];
              }
              this.finalData["excludedEmployeeListIds"].push(req.id);
            }
          }
        }
      });
    }
    this.apiCall.launchOffer(this.finalData, this.offerId).subscribe(
      (response) => {
        if (response["message"] == "success") {
          this.snackBar.openSnackBar("Offer Scheduled", 4000);
          if (!this.popUpMode) {
            this.router.navigate(["/app/offer/choose-offer"]);
          }
        }
      },
      (err) => {
        this.openErrorDialog(err);
      }
    );
  }

  openErrorDialog(err) {
    const dialogRef = this.dialog.open(ContactUsDialogComponent, {
      panelClass: "no-padding-dialog-popup",
      height: "auto",
      disableClose: true,
      width: "380px",
      data: {
        header: this._i18nDynamicTranslate.transform("Error", ["POS_PAGE"]),
        body: err.error.body,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      return;
    });
  }

  selectTemplate(
    type: string,
    id: number,
    templateName: string,
    tableType?,
    isNonDiscountTypeEmailPicked?: boolean
  ) {
    if (type === "EMAIL") {
      this.selectedEmailTemplateId = id;
      this.selectedEmailTemplateName = templateName;
      this.selectedTemplateTableType = tableType;
      this.isNonDiscountTypeEmailPicked = isNonDiscountTypeEmailPicked
        ? isNonDiscountTypeEmailPicked
        : false;
    }
  }

  handleChange2(evt, layout) {
    this.displayParameter = "C" + evt.value;
    this.selectedTemplateData = layout;
    this.emailId = this.displayParameter.split("C")[2];
  }

  handleChange(evt, layout) {
    this.newLayoutDetails = {
      id: null,
      layoutSnapshotUrl: null,
      layoutName: null,
    };
    this.newLayoutDetails.layoutName = layout.layoutName;
    this.displayParameter = "T" + evt.value;
    this.selectedTemplateData = layout;
    this.emailTemplateSnapshotUrl = layout.snapshotUrl;
    this.editTemplate(layout.id, "template");
  }

  editTemplate(id, emailType) {
    if (id == "new") {
      this.newLayoutDetails = {
        id: null,
        layoutSnapshotUrl: null,
        layoutName: null,
      };
    }
    this.loadEmailEditor(id, emailType);
  }

  loadEmailEditor(status, emailType, tableType?) {
    this.finalScreenHeight = this.scrHeight - 61;
    this.finalScreenWidth = this.scrWidth;
    let sh = this.finalScreenHeight * (90 / 100);
    let sw = this.finalScreenWidth * (70 / 100);
    this.data.offerId = this.offerId ? this.offerId : null;
    if (status == 0) {
      this.data.process = "AddTextBody";
      this.data.id = null;
    } else if (status == "new") {
      this.data.process = "NewTemplateAndLayout";
      this.data.id = null;
    } else if (emailType == "layoutEdit") {
      this.data.process = "EditTextBody";
      this.data.id = status;
    } else {
      this.data.process = "AddTextBody";
      this.data.id = status;
    }
    this.getAllMessageTemplateVariables(sh, sw);
  }

  openOfferAttachEmailDialog(sh, sw) {
    const dialogRefer = this.dialog.open(OfferAttachEmailDialogComponent, {
      panelClass: "customAttachEmail",
      height: sh + "px",
      width: this.finalScreenWidth < 1500 ? "1024px" : sw + "px",
      maxWidth: "99vw",
      disableClose: true,
      data: this.data,
    });
    dialogRefer.afterClosed().subscribe((result) => {
      if (result) {
        this.emailId = result.layoutId;
        this.channelConfigureStatus = false;
        this.preemailLayoutId = result.layoutId;
      } else {
        this.displayParameter = null;
        this.emailTemplateSnapshotUrl = null;
        this.emailId = null;
        this.selectedTemplateData = null;
      }
    });
  }

  getAllMessageTemplateVariables(sh, sw) {
    this.apiCall.getAllMessageTemplateVariablesforOffer(this.offerId).subscribe(
      (response) => {
        if (response["message"] === "success") {
          let messageVariables = JSON.parse(response["body"]);
          let finalVariables = [];
          for (let key in messageVariables) {
            finalVariables.push({
              name: messageVariables[key],
              value: key,
            });
          }
          this.data.tags = finalVariables;
          this.data.imageTagStatus = true;
          this.data.offerId = this.offerId;
          this.openOfferAttachEmailDialog(sh, sw);
        } else {
          this.snackBar.openSnackBar(
            this._i18nDynamicTranslate.transform(
              "Fetching template variable failed",
              ["POS_PAGE"]
            ),
            2000
          );
        }
      },
      (err) => {
        this.snackBar.openSnackBar(
          this._i18nDynamicTranslate.transform(
            "Fetching merge tag variables failed",
            ["POS_PAGE"]
          ),
          2000
        );
      }
    );
  }

  openchannelDiv() {
    this.channelConfigureStatus = true;
  }

  getParticularOfferDetail() {
    this.apiCall
      .getParticularOfferDetails(this.offerId)
      .subscribe((response) => {
        if (response["message"] == "success") {
          this.offerdataFetched = true;
          this.masterOffer = JSON.parse(response["body"]);
          this.barrierReward = JSON.parse(this.masterOffer["offerjson"])[
            "barrierRewards"
          ];
          this.selectedbarrier = this.barrierReward.barrier;
          this.offerType =
            this.masterOffer["type"] == "STANDARD" ? "standard" : "custom";
        }
      });
  }

  getTemplates(preSelectId?) {
    this.loading = true;
    this.apiCall.listEmailLayout().subscribe((response) => {
      if (response["message"] === "success") {
        this.templateList = response["body"];
        if (this.templateList.length > 0) {
          this.templateList.forEach((template) => {
            template["name"] = template.layoutName;
            template["tableType"] = "CAMPAIGN";
          });
        }
        var i;
        this.loading = false;
        if (preSelectId) {
          this.selectedEmailTemplateId = preSelectId;
          const preSelectedTempalte = this.templateList.find(
            (template) => template.id == preSelectId
          );
          if (preSelectedTempalte) {
            this.selectedEmailTemplateName = preSelectedTempalte.layoutName;
            this.selectedTemplateTableType = "CAMPAIGN";
            this.onEmailSave();
          }
        }
      } else {
        this.snackBar.openSnackBar("Error", 2000);
      }
    });
  }

  getHtml(id) {
    this.apiCall.getEmailContent(id).subscribe((response) => {
      if (response["message"] === "success") {
        this.apicount++;
        let layout = JSON.parse(response["body"]);
        this.layoutSnapshotUrl = layout["snapShot"];
        if (!this.layoutSnapshotUrl && this.apicount < 6) {
          if (!this.destroyed) {
            this.interval = setTimeout(() => {
              this.getHtml(id);
            }, 2200);
          }
        } else {
          this.apicount = 6;
          this.newLayoutDetails.layoutSnapshotUrl = this.layoutSnapshotUrl;
          var z = null;
          if (sessionStorage.getItem("layoutDetails")) {
            z = JSON.parse(sessionStorage.getItem("layoutDetails"));
            z[z.length - 1].layoutSnapshotUrl = this.layoutSnapshotUrl;
            sessionStorage.setItem("layoutDetails", JSON.stringify(z));
            this.additionallayoutSet[
              this.additionallayoutSet.length - 1
            ].layoutSnapshotUrl = this.layoutSnapshotUrl;
          } else {
            z = this.newLayoutDetails;
            z.layoutSnapshotUrl = this.layoutSnapshotUrl;
            sessionStorage.setItem("layoutDetails", JSON.stringify(z));
            this.additionallayoutSet = z;
            this.newLayoutDetails = {
              id: null,
              layoutSnapshotUrl: null,
              layoutName: null,
            };
          }
        }
      }
    });
  }

  checkVaidationSchedule() {
    if (this.selectedChannelList.length === 0) {
      this.errorChannelSelect = this._i18nDynamicTranslate.transform(
        "Select atleast one channel",
        ["POS_PAGE"]
      );
      return 1;
    } else if (this.action === "DISPATCH" && this.ValidCustomerCount === 0) {
      this.smsNoOfCustomersZeroError =
        "Cannot schedule offer as customers targeted is 0";
      return 1;
    } else {
      this.errorChannelSelect = "";
    }

    if(this.templateDispatchForm?.controls?.targetedStores?.value?.length == 0) {
      return 1;
    }


    //Offer email subject validation

    let whastappcheck = this.checkWhastapp();
    let emailCheck = this.checkEmail();
    let smsCheck = this.checkSMS();
    let scheduleCheck = this.checkSchedule();
    let pushNotificationCheck = this.checkPushNotification();

    if (
      whastappcheck &&
      smsCheck &&
      emailCheck &&
      scheduleCheck &&
      pushNotificationCheck
    ) {
      return 0;
    } else {
      return 1;
    }
    // return 0;
  }
  checkEmail() {
    if (this.selectedChannelList.indexOf("Email") != -1) {
      if (
        this.preemailLayoutId == null &&
        this.rawJson["isBuyXGetYDetected"] != true &&
        this.rawJson["isNoneBarrierDetected"] != true
      ) {
        this.attachEmailError = this._i18nDynamicTranslate.transform(
          "Select email template",
          ["POS_PAGE"]
        );
        return 0;
      } else if (this.selectedEmailTemplateId == null) {
        this.attachEmailError = this._i18nDynamicTranslate.transform(
          "Select email template",
          ["POS_PAGE"]
        );
        return 0;
      } else {
        if (
          this.selectedChannelList.length > 0 &&
          this.selectedChannelList.indexOf("Email") != -1
        ) {
          if (!this.offerEmailSubject) {
            this.offerEmailSubjectErrorText = "Please enter subject";
            return 0;
          } else if (this.offerEmailSubject.length < 3) {
            this.offerEmailSubjectErrorText = "Minimum 3 Characters";
            return 0;
          } else {
            this.offerEmailSubjectErrorText = null;
            return 1;
          }
        } else {
          return 1;
        }
      }
    } else {
      return 1;
    }
  }
  checkSMS() {
    if (this.selectedChannelList.indexOf("SMS") != -1) {
      if (
        this.presmsLayoutId == null &&
        this.rawJson["isBuyXGetYDetected"] != true &&
        this.rawJson["isNoneBarrierDetected"] != true
      ) {
        this.attachSmsError = this._i18nDynamicTranslate.transform(
          "Select SMS template",
          ["POS_PAGE"]
        );
        return 0;
      } else if (this.presmsrewardId == null) {
        this.attachSmsError = this._i18nDynamicTranslate.transform(
          "Select SMS template",
          ["POS_PAGE"]
        );
        return 0;
      } else if (
        this.showSMSWarning !== 1 &&
        this.templateDispatchForm["value"]["dispatchType"] != "SCHEDULED" &&
        this.action === "DISPATCH"
      ) {
        return 0;
      } else {
        return 1;
      }
    } else {
      return 1;
    }
  }
  checkWhastapp() {
    if (this.selectedChannelList.indexOf("WhatsApp") != -1) {
      if (this.prewhatsappLayoutId == null) {
        this.attachWhatsappError = this._i18nDynamicTranslate.transform(
          "Select WhatsApp template",
          ["POS_PAGE"]
        );
        return 0;
      } else {
        return 1;
      }
    } else {
      return 1;
    }
  }
  checkSchedule() {
    if (this.templateDispatchForm.controls.dispatchType.value === "SCHEDULED") {
      if (
        this.templateDispatchForm.controls.dispatchDate.value === null ||
        this.templateDispatchForm.controls.dispatchDate.value === undefined
      ) {
        this.snackBar.openSnackBar("Select date", 2000);
        return 0;
      }
      if (
        this.templateDispatchForm.controls.dispatchTime.value === null ||
        this.templateDispatchForm.controls.dispatchTime.value === undefined
      ) {
        this.snackBar.openSnackBar("Select time", 2000);
        return 0;
      }
      if (
        this.templateDispatchForm.controls.dispatchDate.value !== null &&
        this.templateDispatchForm.controls.dispatchDate.value !== undefined &&
        this.templateDispatchForm.controls.dispatchTime.value !== null &&
        this.templateDispatchForm.controls.dispatchTime.value !== undefined
      ) {
        return 1;
      }
    } else {
      return 1;
    }
  }

  checkPushNotification() {
    if (
      this.selectedChannelList.length > 0 &&
      this.selectedChannelList.indexOf("pushNotification") != -1
    ) {
      if (!this.offerPushNotificationSubject) {
        this.offerPushNotificationErrorText = "Please enter subject";
        return 0;
      } else if (this.offerPushNotificationSubject.length < 3) {
        this.offerPushNotificationErrorText = "Minimum 3 Characters";
        return 0;
      } else {
        this.offerPushNotificationErrorText = null;
        return 1;
      }
    } else {
      return 1;
    }
  }
  saveOfferDispatchEmail() {
    this.saveSurevyClicked = true;
    if (
      this.selectedChannel === "WHATSAPP" &&
      this.tokenStorage.getIsAccountCreated() === "false"
    ) {
      const dialogRef = this.dialog.open(ConfirmationDialogBoxComponent, {
        panelClass: "no-padding-dialog-popup",
        width: "50  ",
        data: {
          subject: this._i18nDynamicTranslate.transform(
            "WhatsApp account not linked",
            ["POS_PAGE"]
          ),
          message: this._i18nDynamicTranslate.transform(
            "You can link WhatsApp Business account by clicking on Link.",
            ["POS_PAGE"]
          ),
          cancelButtonText: this._i18nDynamicTranslate.transform("Cancel", [
            "POS_PAGE",
          ]),
          successButtonText: this._i18nDynamicTranslate.transform("Link", [
            "POS_PAGE",
          ]),
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.create360dialog.createAccountNew();
        }
      });
    } else {
      if (this.checkVaidationSchedule()) {
        return 1;
      } else {
        var barrierRewardWithEmailId = {
          emailLayoutId: this.emailId,
          barrierRewards: this.barrierReward,
          channel: "EMAIL",
        };
        let layoutId = null;
        let layoutrewardId = null;
        let layoutName = null;
        if (this.selectedChannel === "SMS") {
          layoutId = this.presmsrewardId;
          layoutName = this.presmsrewadLayoutName;
          layoutrewardId = this.prerewardId;
        }
        if (this.selectedChannel === "WHATSAPP") {
          layoutId = this.prewhatsappLayoutId;
          layoutName = this.prewhatsappLayoutName;
          layoutrewardId = this.prewhatsappLayoutId;
        }
        this.barrierReward[0]["reward"]["channel"] = this.selectedChannel;
        this.barrierReward[0]["reward"]["emailLayoutId"] =
          this.selectedChannel == "EMAIL" ? this.preemailLayoutId : null;
        this.barrierReward[0]["reward"]["layoutId"] = layoutId;
        this.barrierReward[0]["reward"]["rewardLayoutName"] = layoutName;
        this.barrierReward[0]["reward"]["communication"] = JSON.stringify(
          this.getCommunication()
        );
        let targetedStores = [];
        if(this.isAdminPos) {
          if(this.templateDispatchForm.controls.targetedStores?.value?.some(id=>id==-1)) { //check if all stores selected
            targetedStores = [];
          } else {
            this.templateDispatchForm.controls.targetedStores?.value?.forEach(id=>targetedStores.push(parseInt(id)));
          }
        } else {
          targetedStores.push(parseInt(this.storeDataJSON['storeId']));
        }
        var offerJson = {
          emailLayoutId:
            this.selectedChannel == "EMAIL"
              ? this.emailId == null
                ? this.preemailLayoutId
                : this.emailId
              : null,
          barrierRewards: this.barrierReward,
          emailLayoutTemplateId:
            this.selectedTemplateData && this.selectedChannel == "EMAIL"
              ? this.selectedTemplateData.id
              : null,
          emailLayoutName:
            this.selectedTemplateData && this.selectedChannel == "EMAIL"
              ? this.selectedTemplateData.layoutName
              : null,
          emailLayoutSubject: null,
          emailTemplateSnapshotUrl: this.emailTemplateSnapshotUrl,
          channel: this.selectedChannel,
          // layoutId: this.selectedChannel == "SMS" ? this.presmsLayoutId : null,
          layoutId: layoutrewardId,
          smsLayoutId:
            this.selectedChannel == "SMS" ? this.presmsLayoutId : null,
          smsTemplateName:
            this.selectedChannel == "SMS" ? this.presmsTemplateName : null,
          isNoneBarrierDetected: this.rawJson["isNoneBarrierDetected"],
          isBuyXGetYDetected: this.rawJson["isBuyXGetYDetected"],
          // stores: this.storeData,
          adminCreated: this.tokenStorage.getqId() === "null" ? true : false,
          targetedStores:targetedStores,
          redeemableAtStores: this.rawJson['redeemableAtStores'],
          createdBy:
            this.tokenStorage.getqId() === "null"
              ? this.adminCreated
              : this.storeDataJSON,
        };
        if (
          this.pos == "posist" &&
          JSON.parse(this.offerReqBodyObj["offerJson"])["isSpecialOffer"]
        ) {
          offerJson["isSpecialOffer"] = JSON.parse(
            this.offerReqBodyObj["offerJson"]
          )["isSpecialOffer"];
        }
        var OfferData = {
          offerName: this.masterOffer["offerName"],
          isPersonalised: this.masterOffer["isPersonalised"],
          offerJson: JSON.stringify(offerJson),
          // channel: this.selectedChannel,
          offerId: this.offerId,
          isLimitedTime: this.isLImitedTime,
          // offerHtml: this.finalHtml,
          offerStartTime: this.datePipe.transform(
            this.masterOffer["offerStartTime"],
            "yyyy-MM-dd HH:mm:ss"
          ),
          offerEndTime: this.datePipe.transform(
            this.masterOffer["offerEndTime"],
            "yyyy-MM-dd HH:mm:ss"
          ),
        };
        if (this.offerdataFetched) {
          this.apiCall
            .updateParticularOfferDetails(this.offerId, OfferData)
            .subscribe((response) => {
              if (response["message"] == "success") {
                if (this.action == "DISPATCH") {
                  this.launchOffer();
                } else if (this.action == "saveTemplateAndChannel") {
                  if (!this.popUpMode) {
                    this.router.navigate(["/app/offer/choose-offer"]);
                  }
                }
                this.emailLayoutSaved = true;
                if (this.popUpMode) {
                  this.message = this.offerId.toString();
                  this.sendcancelMessage();
                }
              }
              (err) => {
                this.snackBar.openSnackBar(
                  this._i18nDynamicTranslate.transform("Error", ["POS_PAGE"]),
                  2000
                );
              };
            });
        }
      }
    }
  }

  opensnapshotHtmlDialogBarrier(id, tableType?) {
    const dialogRef = this.dialog.open(HtmlDataDialogComponent, {
      width: "60%",
      height: "500px",
      data: {
        type: "offer",
        id: id,
        channel: this.selectedChannel,
        templateID: this.offerId,
        rewardType: this.BarrierRewardFull.reward.rewardType,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == "edit") {
        this.loadEmailEditor(id, "layoutEdit", tableType);
      }
    });
  }

  opensnapshotHtmlDialog(id, tableType?) {
    var dataId = this.rawJson["barrierRewards"][0]["reward"]["rewardId"];
    var dat = {
      type: null,
      id: null,
      channel: null,
      templateID: null,
      rewardType: null,
      actionType: null,
    };
    // console.log(this.prerewardId, id, this.preemailLayoutId)
    // if (this.prerewardId > 0) {
    //   const dialogRef = this.dialog.open(HtmlDataDialogComponent, {
    //     panelClass: "no-padding-dialog-popup",
    //     width: "60%",
    //     height: "500px",
    //     data: {
    //       type: "offer",
    //       id: this.prerewardId,
    //       channel: this.selectedChannel,
    //       templateID: this.offerId,
    //       rewardType: this.BarrierRewardFull.reward.rewardType,
    //     },
    //   });
    //   dialogRef.afterClosed().subscribe((result) => {
    //     if (result == "edit") {
    //       this.loadEmailEditorReward();
    //     }
    //   });

    if (id != null) {
      dat = {
        type: tableType.toLowerCase(),
        id: id,
        channel: this.selectedChannel,
        templateID: this.offerId,
        rewardType: this.BarrierRewardFull.reward.rewardType,
        actionType: "Offer",
      };
    } else {
      dat = {
        type: this.preemailTableType.toLowerCase(),
        id: this.preemailLayoutId,
        channel: this.selectedChannel,
        templateID: this.offerId,
        rewardType: this.BarrierRewardFull.reward.rewardType,
        actionType: "Offer",
      };
    }
    const dialogRef = this.dialog.open(HtmlForEmailTemplatesDialogComponent, {
      panelClass: "no-padding-dialog-popup",
      width: "60%",
      height: "500px",
      data: dat,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == "edit") {
        if (id) {
          this.loadEmailEditorReward(id, tableType);
        } else {
          this.loadEmailEditorReward(
            this.preemailLayoutId,
            this.preemailTableType
          );
        }
      }
    });
  }

  getrewardemail(preSelectId?) {
    this.apiCall.getRewardEmailList().subscribe((result) => {
      this.rewardTemplateList = result["body"];
      if (this.rewardTemplateList.length == 0) {
        this.snackBar.openSnackBar(
          this._i18nDynamicTranslate.transform(
            "No Reward Templates available. Cannot dispatch offer",
            ["POS_PAGE"]
          ),
          2000
        );
        this.sendcancelMessage();
        return 0;
      }
      for (let i = 0; i < this.rewardTemplateList.length; i++) {
        if (
          this.rewardTypeAndName[
            this.rawJson["barrierRewards"][0]["reward"]["rewardType"]
          ] == this.rewardTemplateList[i]["layoutName"]
        ) {
          this.mainRewardtemplate = this.rewardTemplateList[i]["id"];
        }
        this.rewardTemplateList[i]["name"] =
          this.rewardTemplateList[i]["layoutName"];
        this.rewardTemplateList[i]["tableType"] = "REWARD";
      }
      if (preSelectId) {
        this.selectedEmailTemplateId = preSelectId;
        const preSelectedTempalte = this.rewardTemplateList.find(
          (template) => template.id == preSelectId
        );
        if (preSelectedTempalte) {
          this.selectedEmailTemplateName = preSelectedTempalte.layoutName;
          this.selectedTemplateTableType = "REWARD";
          this.onEmailSave();
        }
      }
      // console.log('ALL REWARD EMAILS',this.rewardTemplateList)
    });
  }

  loadEmailEditorReward(id?, tableType?) {
    var specialTagType = null;
    var imageTagStatus = false;
    this.finalScreenHeight = this.scrHeight - 61;
    this.finalScreenWidth = this.scrWidth;
    let sh = this.finalScreenHeight * (90 / 100);
    let sw = this.finalScreenWidth * (90 / 100);
    if (this.barrierofZero["barrierParamType"] == "NONE") {
      if (
        this.rewardofZero["rewardType"] == "PERCENTAGE_OFF" ||
        this.rewardofZero["rewardType"] == "FIXED_AMOUNT_OFF"
      ) {
        if (
          this.rewardofZero["productPropensity"] != null ||
          this.rewardofZero["productCategoryPropensity"] != null
        ) {
          imageTagStatus = true;
          specialTagType = "REWARD_ALL";
        } else {
          imageTagStatus = false;
          specialTagType = null;
        }
      }
    }
    if (this.rewardofZero["rewardType"] == "BUY_X_GET_Y") {
      if (this.barrierofZero["barrierParamType"] == "PERSONALIZED") {
        imageTagStatus = true;
        specialTagType = "BUYXGETY";
      } else {
        imageTagStatus = false;
        specialTagType = null;
      }
    }
    if (this.prerewardId == null) {
      var datas = {
        process: "createRewardTemplateLayout",
        id: id,
        tags: [],
        imageTagStatus: false,
        specialTagType: "",
        offerId: null,
        type: "OFFER",
        rewardType: this.BarrierRewardFull.reward.rewardType,
        isReward: true,
        tableType: tableType,
      };
    } else {
      var datas = {
        process: "createRewardTemplateLayout",
        id: this.prerewardId,
        tags: [],
        imageTagStatus: false,
        specialTagType: "",
        offerId: null,
        type: "OFFER",
        rewardType: this.BarrierRewardFull.reward.rewardType,
        isReward: true,
        tableType: tableType,
      };
    }
    this.getRewardOfferTags(datas, imageTagStatus, specialTagType, sh, sw);
  }

  getRewardOfferTags(datas, imageTagStatus, specialTagType, sh, sw) {
    this.apiCall
      .getRewardOfferTags(this.rewardofZero["rewardType"])
      .subscribe((response) => {
        if (response["message"] === "success") {
          let messageVariables = JSON.parse(response["body"]);
          let finalVariables = [];
          for (let key in messageVariables) {
            finalVariables.push({
              name: messageVariables[key],
              value: key,
            });
          }
          this.data.tags = finalVariables;
          datas.tags = finalVariables;
          datas.imageTagStatus = imageTagStatus;
          datas.specialTagType = specialTagType;
          datas.offerId = this.offerId;
          this.openOfferEmailDialog(sh, sw, datas);
        } else {
          this.snackBar.openSnackBar(
            this._i18nDynamicTranslate.transform(
              "Fetching template variable failed",
              ["POS_PAGE"]
            ),
            2000
          );
        }
      });
    (err) => {
      this.snackBar.openSnackBar(
        this._i18nDynamicTranslate.transform(
          "Fetching message template variable failed",
          ["POS_PAGE"]
        ),
        2000
      );
    };
  }

  openOfferEmailDialog(sh, sw, datas) {
    const dialogRefer = this.dialog.open(OfferAttachEmailDialogComponent, {
      panelClass: "customAttachEmail",
      height: sh + "px",
      width: sw + "px",
      maxWidth: "99vw",
      minWidth: "1250px",
      minHeight: "620px",
      disableClose: true,
      data: datas,
    });
    dialogRefer.afterClosed().subscribe((result) => {
      if (result) {
        if (result.tableType == "REWARD") {
          this.getrewardemail(result.layoutId);
          this.getEmailTemplate(result.layoutId);
        } else {
          this.getTemplates(result.layoutId);
        }
      }
    });
  }

  openViewOfferSummary() {
    const dialogRefer = this.dialog.open(OfferSummaryDialogV2Component, {
      panelClass: "offerSummarydialog",
      width: "60%",
      maxWidth: "90vw",
      maxHeight: "95vh",
      data: this.offerId,
    });
    dialogRefer.afterClosed().subscribe((result) => {
      if (result) {
      }
    });
  }

  getOfferSMSTemplate(preSelectId?) {
    let offerType;
    if (
      this.rawJson["isNoneBarrierDetected"] == true ||
      this.rawJson["isBuyXGetYDetected"] == true
    ) {
      offerType = "Reward";
    } else {
      offerType = "BarrierReward";
    }
    this.apiCall
      .getOfferSMSTemplate(this.offerId, offerType)
      .subscribe((response) => {
        if (
          response["message"] == "success" ||
          response["message"] == "SUCCESS"
        ) {
          this.smsResponse = response["body"];
          if (preSelectId) {
            const preSelectedTempalte = this.smsResponse.Reward?.find(
              (template) => template.id == preSelectId
            );
            this.presmsrewadLayoutName = preSelectedTempalte.name;
            this.presmsrewardId = preSelectedTempalte.id;
          }
        }
      });
  }
  getWhatsAppTemplate() {
    this.apiCall
      .getOfferWhatsAppTemplate(this.offerId, "Reward", "OFFER")
      .subscribe((response) => {
        // console.log("WHATSAPP_RESPONSE", response);
        this.whatsAppResponse = response["body"];
      });
  }
  getEmailTemplate(preSelectId?) {
    this.offerDiscountTypeEmailTemplateList = [];
    this.apiCall
      .getEmailTemplateOffers(this.offerId, "Reward", "OFFER")
      .subscribe((response) => {
        this.emailResponse = response["body"];
        this.offerDiscountTypeEmailTemplateList = this.emailResponse.Reward;
        this.offerDiscountTypeEmailTemplateList.sort((a, b) => {
          const dateA: Date = new Date(a.lastModifiedTime);
          const dateB: Date = new Date(b.lastModifiedTime);
          return dateB.getTime() - dateA.getTime();
        });
        this.offerDiscountTypeEmailTemplateList.forEach(
          (template) => (template["tableType"] = "REWARD")
        );
        if (preSelectId) {
          this.selectedEmailTemplateId = preSelectId;
          const preSelectedTempalte =
            this.offerDiscountTypeEmailTemplateList.find(
              (template) => template.id == preSelectId
            );
          if (preSelectedTempalte) {
            this.selectedEmailTemplateName = preSelectedTempalte.name;
            this.selectedTemplateTableType = "REWARD";
            this.onEmailSave();
          }
        }
      });
  }

  openSMSPreview(layout) {
    const dialogRef = this.dialog.open(SmsPreviewDialogComponent, {
      panelClass: "sms-preview-dialog",
      data: {
        smsText: layout["layoutData"],
        type: "SMS",
      },
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }
  openWhatsAppPreview(layoutData) {
    let layout = JSON.parse(layoutData);
    // console.log("LAYOUTYES",layout)
    const dialogRef = this.dialog.open(SmsPreviewDialogComponent, {
      panelClass: "sms-preview-dialog",
      data: {
        smsText: layout,
        type: "WhatsApp",
      },
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }
  opensmsPreviewDialog(id) {
    const dialogRef = this.dialog.open(SmsPreviewIdDialogComponent, {
      panelClass: "sms-preview-dialog",
      data: id,
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  openchannelSMSDiv(type) {
    this.attachSmsError = "";
    this.selectedSmsOfferPart = type;
    if (type == "barrier") {
      this.smsTemplateList = this.smsResponse?.Barrier
        ? this.smsResponse?.Barrier
        : [];
    } else {
      this.smsTemplateList = this.smsResponse?.Reward
        ? this.smsResponse?.Reward
        : [];
    }
    this.channelConfigureStatus = true;
  }
  openchannelWhatsappDiv() {
    this.attachWhatsappError = "";
    this.channelConfigureStatus = true;
    this.whatsAppTemplateList = this.whatsAppResponse.Reward;
  }
  openchannelEmailDiv() {
    this.attachEmailError = "";
    this.prerewardId = null;
    this.channelConfigureStatus = true;

    //get all custom emails from template getAllEmailLayouts api result
    this.otherEmailTemplateList = [];
    if (this.templateList.length > 0) {
      this.templateList.forEach((template) => {
        if (template.emailType == "CUSTOM") {
          this.otherEmailTemplateList.push(template);
        }
      });
    }
    //filter out discount type reward email from all getRewardEmails api result
    let idsToFilterOut = this.offerDiscountTypeEmailTemplateList.map(
      (template) => template.id
    );
    this.otherEmailTemplateList = [
      ...this.otherEmailTemplateList,
      ...this.rewardTemplateList.filter(
        (template) => !idsToFilterOut.includes(template.id)
      ),
    ];
    this.otherEmailTemplateList.sort((a, b) => {
      const dateA: Date = new Date(a.lastModifiedTime);
      const dateB: Date = new Date(b.lastModifiedTime);
      return dateB.getTime() - dateA.getTime();
    });
    // console.log('DISCOUNT TYPE EMAILS',this.offerDiscountTypeEmailTemplateList);
    // console.log('FILTERED-->',this.otherEmailTemplateList);
  }

  selectSMStemplateOBJ(layout, id, isNonDiscountTypeSMSPicked?) {
    this.selectedSmsTemporaryId = id;
    let type = this.selectedSmsOfferPart;
    if (type == "barrier") {
      this.barrierSMSLayoutJson = layout;
    } else {
      this.rewardSMSLayoutJson = layout;
    }
    this.isNonDiscountTypeSMSPicked = isNonDiscountTypeSMSPicked
      ? isNonDiscountTypeSMSPicked
      : false;
  }
  selectWhatsApptemplateOBJ(layout, id) {
    this.selectedWhatsAppTemporaryId = id;
    this.rewardWhatsAppLayoutJson = layout;
    // console.log("LAYOUT", this.rewardWhatsAppLayoutJson);
  }
  cancelWhatsAppSave() {
    this.selectedWhatsAppTemporaryId = null;
    this.channelConfigureStatus = false;
  }
  onWhatsAppSave() {
    this.prewhatsappLayoutName = this.rewardWhatsAppLayoutJson.name;
    this.prewhatsappLayoutId = this.rewardWhatsAppLayoutJson.id;
    this.prewhatsappLayoutData = this.rewardWhatsAppLayoutJson.layoutData;
    this.selectedWhatsAppTemporaryId = null;
    this.channelConfigureStatus = false;
  }

  cancelSMSSave() {
    this.selectedSmsTemporaryId = null;
    this.channelConfigureStatus = false;
  }
  selectSMSTemplate(id) {
    this.selectedSmsTemporaryId = id;
  }
  cancelEmailSave() {
    this.selectedEmailTemplateId = null;
    this.channelConfigureStatus = false;
  }
  onEmailSave() {
    this.preemailLayoutId = this.selectedEmailTemplateId;
    this.preemailLayoutName = this.selectedEmailTemplateName;
    this.preemailTableType = this.selectedTemplateTableType;
    this.channelConfigureStatus = false;
  }

  onSMSSave() {
    if (this.selectedSmsTemporaryId == null) {
      this.snackBar.openSnackBar(
        this._i18nDynamicTranslate.transform("Select any template", [
          "POS_PAGE",
        ]),
        2000
      );
      return;
    }
    if (this.selectedSmsOfferPart == "barrier") {
      this.presmsTemplateName = this.barrierSMSLayoutJson.name;
      this.presmsLayoutId = this.barrierSMSLayoutJson.id;
    } else {
      this.presmsrewadLayoutName = this.rewardSMSLayoutJson.name;
      this.presmsrewardId = this.rewardSMSLayoutJson.id;
    }
    this.selectedSmsTemporaryId = null;
    this.channelConfigureStatus = false;
  }

  dateCompare(time1, time2) {
    // console.log(time1, time2)
    var t1 = new Date();
    var parts = time1.split(":");
    t1.setHours(parts[0], parts[1], parts[2], 0);
    var t2 = new Date();
    parts = time2.split(":");
    t2.setHours(parts[0], parts[1], parts[2], 0);
    // returns 1 if greater, -1 if less and 0 if the same
    if (t1.getTime() > t2.getTime()) return 1;
    if (t1.getTime() < t2.getTime()) return -1;
    return 0;
  }

  dateCompareSub(time1, time2) {
    if (time1 !== null && time2 !== null) {
      var t1 = new Date();
      var parts = time1.split(":");
      t1.setHours(parts[0], parts[1], 0);
      var t2 = new Date();
      parts = time2.split(":");
      t2.setHours(parts[0], parts[1], 0);
      // returns 1 if greater, -1 if less and 0 if the same
      if (t1.getTime() > t2.getTime()) return 1;
      if (t1.getTime() < t2.getTime()) return -1;
    } else {
    }
    return 0;
  }

  checkTypeTime() {
    if (
      this.dateCompareSub(
        this.templateDispatchForm.get("dispatchTime").value,
        "20:55:00"
      ) == 1
    ) {
      this.templateDispatchForm.get("dispatchTime").setValue(null);
    } else if (
      this.dateCompareSub(
        "10:00:00 AM",
        this.templateDispatchForm.get("dispatchTime").value
      ) == 1
    ) {
      this.templateDispatchForm.get("dispatchTime").setValue(null);
    }
  }

  convertDispatchType() {
    this.updateMinTime(0);
    this.templateDispatchForm.controls.dispatchType.setValue("SCHEDULED");
    this.checkTypeTime();
  }

  scheduledClick() {
    if (this.selectedChannel == "SMS") {
      this.updateMinTime(0);
      this.checkTypeTime();
    }
    return 0;
  }

  selectopenclose(value) {
    this.templateDispatchForm.controls.tempsegmentFilter.setValue('');
    this.templateDispatchForm.controls.tempexclusionsegmentFilter.setValue('');
    this.templateDispatchForm.controls.tempstoreFilter.setValue('');
    switch (value) {
      case "customerSegmentsSelect":
        this.customerSegmentsSelect.open();
        break;
      case "dispatchTypeSelect":
        this.dispatchTypeSelect.open();
        break;
      case "dispatchDateSelect":
        this.dispatchDateSelect.open();
        break;
      case "dispatchTimeSelect":
        this.dispatchTimeSelect.open();
        break;
      case 'targetedStoresSelect':
        this.targetedStoresSelect.open();
        break;
      case "customerSegmentsExclusion":
      this.customerSegmentsExclusion.open();
    }
  }

  startDateChange() {
    var ct = new Date(this.startDateMinValue.setHours(0, 0, 0, 0));
    if (
      this.templateDispatchForm.controls.dispatchDate.value &&
      ct.getTime() ==
        this.templateDispatchForm.controls.dispatchDate.value.getTime()
    ) {
      this.updateMinTime(1);
    } else {
      this.updateMinTime(0);
    }
  }

  updateMinTime(value) {
    if (value) {
      this.currentTimeMin = this.addMinutes(
        this.currentDateTime.toTimeString(),
        0
      );
      if (
        this.dateCompareSub(
          this.currentTimeMin,
          this.templateDispatchForm.controls.dispatchTime.value
        ) != -1
      ) {
        this.templateDispatchForm.controls.dispatchTime.setValue(null);
      }
      if (this.selectedChannel == "SMS") {
        if (this.dateCompareSub(this.currentTimeMin, "10:00:00") != 1) {
          this.currentTimeMin = "10:00 AM";
        }
      }
    } else {
      if (this.selectedChannel == "SMS") {
        this.currentTimeMin = "10:00 AM";
      } else {
        this.currentTimeMin = "00:00";
      }
    }
  }
  segmentLength = false;
  apiloading = false;

  getCustomerCount() {
    this.segmentLength = false;
    this.customerCount = 0;
    this.NotAllowedCustomer = 0;
    this.ValidCustomerCount = 0;
    this.totalCustomer = 0;
    this.UnsubscribedCount = 0;

    this.selectedSegments = this.customerSegmentsSelect?.value
      ? this.customerSegmentsSelect?.value
      : "";

      this.selectedExclusionSegments = this.customerSegmentsExclusion?.value
      ? this.customerSegmentsExclusion?.value
      : "";

    let selectedTiers = [],
      selectedSegments = [],
      selectedCustomerList = [],
      selectedEmployeeList = [];

    if (this.selectedSegments && this.selectedSegments.length > 0) {
      this.selectedSegments.forEach((selectedSegment) => {
        let req = [
          ...this.customerSegmentList,
          ...this.customerTierList,
          ...this.customerList,
          ...this.employeeList,
        ].find((segment) => selectedSegment === segment.id);
        if (req?.category.toLowerCase() === "tier") {
          selectedTiers.push(req);
        }
        if (req?.category.toLowerCase() === "segment") {
          selectedSegments.push(req);
        }
        if (req?.category.toLowerCase() === "customerlist") {
          selectedCustomerList.push(req);
        }
        if (req?.category.toLowerCase() === "employeelist") {
          selectedEmployeeList.push(req);
        }
      });
    }

      let selectedexcludedTiers = [],
      selectedexcludedSegments = [],
      selectedexcludedCustomerList = [],
      selectedexcludedEmployeeList = [];

  if (this.selectedExclusionSegments && this.selectedExclusionSegments.length > 0) {
    this.selectedExclusionSegments.forEach((selectedExclusionSegment) => {
      let req = [
        ...this.customerSegmentList,
        ...this.customerTierList,
        ...this.customerList,
        ...this.employeeList,
      ].find((segment) => selectedExclusionSegment === segment.id);
      if (req?.category.toLowerCase() === "tier") {
        selectedexcludedTiers.push(req);
      }
      if (req?.category.toLowerCase() === "segment") {
        selectedexcludedSegments.push(req);
      }
      if (req?.category.toLowerCase() === "customerlist") {
        selectedexcludedCustomerList.push(req);
      }
      if (req?.category.toLowerCase() === "employeelist") {
        selectedexcludedEmployeeList.push(req);
      }
    });
  }

    let targetedStores = [];
    if(this.isAdminPos) {
      if(this.templateDispatchForm.controls.targetedStores?.value.some(id=>id==-1)) { //check if all stores selected
        targetedStores = [];
      } else {
        this.templateDispatchForm.controls.targetedStores?.value.forEach(id=>targetedStores.push(parseInt(id)));
      }
    } else {
      targetedStores.push(parseInt(this.storeDataJSON['storeId']));
    }
    if (
      this.disableCustomerSegmentSelection != false ||
      this.disableAllCustomerSelection != false || this.selectedSegments.includes("LEADS")
    ) {
      if (this.selectedSegments?.length != 0) {
        this.segmentLength = true;
        let isSegmented = this.selectedSegments[0] != "ALL_CUSTOMERS";
        let data = {
          isSegmented: (selectedSegments.length > 0 || selectedTiers.length > 0 || selectedCustomerList.length > 0 || selectedEmployeeList.length > 0)
            ? true
            : false,
          isLead: this.selectedSegments.includes("LEADS") ? true : false,
          isCustomer: this.selectedSegments.includes("ALL_CUSTOMERS")
            ? true
            : false,
          customerSegmentIds: isSegmented
            ? selectedSegments.map((s) => s.id)
            : null,
          customerTierIds: isSegmented ? selectedTiers.map((t) => t.id) : null,
          customerListIds: isSegmented
            ? selectedCustomerList.map((c) => c.id)
            : null,
          channel: this.selectedChannelList,
          targetedStoreIds: targetedStores,
          excludedSegmentIds: selectedexcludedSegments.map((s) => s.id),
          excludedTierIds: selectedexcludedTiers.map((t) => t.id),
          excludedCustomerListIds: selectedexcludedCustomerList.map((c) => c.id),
          excludedEmployeeListIds: selectedexcludedEmployeeList.map((e) => e.id),
        };

        this.apiCall.getCustomerCount(data).subscribe((response) => {
          this.apiloading = true;
          if (response["body"].totalCount !== null) {
            this.totalCustomer = response["body"].totalCount;
          }
          if (response["body"].invalidNumberCount !== null) {
            this.InvalidCustomer = response["body"].invalidNumberCount;
          }
          if (response["body"].unsubscribedCount !== null) {
            this.UnsubscribedCount = response["body"].unsubscribedCount;
          }
          if (
            response["body"].unsubscribedCount !== null &&
            response["body"].totalCount !== null
          ) {
            this.ValidCustomerCount =
              response["body"].totalCount - response["body"].unsubscribedCount;
          }
          if(this.ValidCustomerCount>0){
            this.smsNoOfCustomersZeroError = "";
          }

          this.apiloading = false;
        });
      } else {
        this.segmentLength = false;
      }
    }
  }
  storeData = [];
  addStoreData() {
    let tempstoreData = this.storeData;
    const dialogRef = this.dialog.open(SelectStoreDialogBoxComponent, {
      panelClass: "no-padding-dialog-popup",
      width: "350px",
      height: "380px",
      data: {
        storeData: tempstoreData,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.storeData = result.selectedStore;
        this.isAllStoresSelected = result.isAllStoresSelected;
      } else {
      }
    });
  }
  viewStore() {
    const dialogRef = this.dialog.open(ViewStoreDialogBoxComponent, {
      panelClass: "no-padding-dialog-popup",
      width: "350px",
      height: "380px",
      data: {
        storeData: this.storeData,
      },
    });
  }
  checkIfOnBoarded() {
    this.apiCall.checkWhatsAppOnboarded().subscribe(
      (response) => {
        this.tokenStorage.setIsAccountCreated(true);
      },
      (err) => {
        this.tokenStorage.setIsAccountCreated(false);
      }
    );
  }
  addMinutes(time, minsToAdd) {
    var piece = time.split(":");

    var mins = piece[0] * 60 + +piece[1] + +minsToAdd;

    return (
      this.convertNgxTimerFormat(((mins % (24 * 60)) / 60) | 0) +
      ":" +
      this.convertNgxTimerFormat(mins % 60)
    );
  }
  convertNgxTimerFormat(J) {
    return (J < 10 ? "0" : "") + J;
  }
  getCustomerSegmentsAndTiers() {
    this.apiCall.getCustomerSegmentsAndTiers().subscribe(
      (response) => {
        let res = response["body"];
        for (let i = 0; i < res.length; i++) {
          if (res[i].category.toLowerCase() === "segment") {
            this.customerSegmentList.push({
              id: res[i]["id"],
              name: res[i]["segmentName"],
              isActive: res[i]["isActive"],
              status: false,
              category: res[i]["category"],
              createdBy: res[i]["createdBy"]
            });
          }
          if (res[i].category.toLowerCase() === "tier") {
            this.customerTierList.push({
              id: res[i]["id"],
              name: res[i]["segmentName"],
              isActive: res[i]["isActive"],
              status: false,
              category: res[i]["category"],
            });
          }
          if (res[i].category.toLowerCase() === "customerlist") {
            this.customerList.push({
              id: res[i]["id"],
              name: res[i]["segmentName"],
              isActive: res[i]["isActive"],
              status: false,
              category: res[i]["category"],
              createdBy: res[i]["createdBy"]
            });
          }
          if (res[i].category.toLowerCase() === "employeelist") {
            this.employeeList.push({
              id: res[i]["id"],
              name: res[i]["segmentName"],
              isActive: res[i]["isActive"],
              status: false,
              category: res[i]["category"],
            });
          }
        }
        this.filterCustomerLists();
        this.filterSegments();
      },
      (error) => {
        this.snackBar.openSnackBar(
          this._i18nDynamicTranslate.transform(
            "Error fetching getCustomerSegmentsAndTiers data",
            ["POS_PAGE"]
          ),
          2000
        );
      }
    );
  }

  allStoresObj = {
    qid: -1, //FOR ALL STORES SELCTION
    storeName: 'All stores',
  }
  getStores() {
    this.apiCall.getStoreData().subscribe(
      (response) => {
        this.storeData = response["body"];
        if(this.isAdminPos && this.storeData.length>0) {
          this.selectStoreInDropdown(-1);
        }
      },
      (error) => {
        this.storeData = [];
      }
    );
  }
  getDateFormat(type?) {
    return this.date_format.getDateFormat(type);
  }
  getParticularStore() {
    let data: any = {};
    this.apiCall.getSpecificStoreOffer().subscribe((response) => {
      data.storeId = response["body"]["storeMetaInfo"].storeId;
      data.storeName = response["body"]["storeMetaInfo"].storeName;
      data.qid = response["body"]["storeMetaInfo"].qid;
      this.storeData.push(data);
    });
  }
  getParticularStoreJSON() {
    let data: any = {};
    this.apiCall.getSpecificStoreOffer().subscribe((response) => {
      // console.log(response)
      data.storeId = response["body"]["storeMetaInfo"].storeId;
      data.storeName = response["body"]["storeMetaInfo"].storeName;
      data.qid = response["body"]["storeMetaInfo"].qid;
      data.userName = this.tokenStorage.getUsernameFull();
      data.userEmail = this.tokenStorage.getMerchantUserEmail();
      this.storeDataJSON = data;
      this.templateDispatchForm.controls.targetedStores.setValue([data.storeId]);
    });
  }
  transformTo24HourFormat(time12hr: string): string {
    if (sessionStorage.getItem("serviceCountry") === "US") {
      // Use the DatePipe to format the time in 12-hour format
      let formattedTime = time12hr;

      // Parse the formatted time to get the hour and minute
      let parts = formattedTime.split(":");
      let hour = parseInt(parts[0], 10);
      let minute = parseInt(parts[1], 10);

      // Check if it's PM, and if it is, add 12 to the hour
      if (time12hr.toLowerCase().includes("pm")) {
        hour += 12;
      }

      // Format the hour and minute as two-digit strings
      let hourStr = hour.toString().padStart(2, "0");
      let minuteStr = minute.toString().padStart(2, "0");

      // Return the time in 24-hour format
      return `${hourStr}:${minuteStr}`;
    } else {
      return time12hr;
    }
  }
  transformTo12HourFormat(time24hr: string): string {
    // Split the time string into hours and minutes
    if (sessionStorage.getItem("serviceCountry") === "US") {
      const [hoursStr, minutesStr] = time24hr.split(":");
      const hours = parseInt(hoursStr, 10);
      const minutes = parseInt(minutesStr, 10);

      // Determine AM or PM based on hours
      const period = hours >= 12 ? "PM" : "AM";

      // Convert hours to 12-hour format
      const hours12 = hours > 12 ? hours - 12 : hours === 0 ? 12 : hours;

      // Format the time in 12-hour format
      const formattedTime = `${hours12}:${minutes
        .toString()
        .padStart(2, "0")} ${period}`;

      return formattedTime;
    } else {
      return time24hr;
    }
  }
  getCommunication() {
    var commu: any = {};
    let data1: any = {};
    for (let i = 0; i < this.selectedChannelList.length; i++) {
      let value: any = {};
      if (this.selectedChannelList[i].toLowerCase() === "email") {
        value.id =
          this.prerewardId > 0
            ? this.prerewardId
            : this.selectedEmailTemplateId;
        value.subject = this.offerEmailSubject;
        data1[this.selectedChannelList[i].toLowerCase()] = value;
        commu = data1;
      } else if (this.selectedChannelList[i].toLowerCase() === "sms") {
        value.id = this.presmsrewardId;
        data1[this.selectedChannelList[i].toLowerCase()] = value;
        commu = data1;
      } else if (this.selectedChannelList[i] === "appOnly") {
        value = {};
        data1[this.selectedChannelList[i]] = value;
        commu = data1;
      } else if (this.selectedChannelList[i].toLowerCase() === "pos") {
        value = {};
        data1[this.selectedChannelList[i].toLowerCase()] = value;
        commu = data1;
      } else if (this.selectedChannelList[i] === "pushNotification") {
        value = {};
        value.title = this.offerPushNotificationSubject;
        value.aliasTitle =
          this.offerPushTitleAlias?.length > 0
            ? this.offerPushTitleAlias
            : null;
        value.body = this.offerPushNotificationBody?.length>0? this.offerPushNotificationBody : null;
        data1[this.selectedChannelList[i]] = value;
        commu = data1;
      } else {
        value.id = this.prewhatsappLayoutId;
        data1[this.selectedChannelList[i].toLowerCase()] = value;
        commu = data1;
      }
    }
    return commu;
  }
  removeError() {
    this.errorChannelSelect = "";
  }
  onSelectChange(value) {
    this.selectedChannel = this.selectedChannelList[0]?.toUpperCase();
    if (this.selectedChannelList.indexOf("SMS") === -1) {
      this.currentTimeMin = "00:00";
    } else {
      this.templateDispatchForm.get("dispatchTime").setValue("");
      this.currentTimeMin = "10:00 AM";
    }
  }
  getSelectedChannelList() {
    // console.log(this.rawJson["barrierRewards"][0]["reward"]["communication"])
    let channelList =
      this.rawJson["barrierRewards"][0]["reward"]["communication"];
    if (channelList) {
      let list = JSON.parse(channelList);
      for (let k in list) {
        if (k === "email" && !this.isLImitedTime) {
          this.selectedChannelList.push(k);
          this.preemailLayoutId = list[k].id;
          if (list[k].subject) {
            this.offerEmailSubject = list[k].subject;
          }
        }
        if (k === "sms" && !this.isLImitedTime) {
          this.selectedChannelList.push(k);
          this.presmsrewardId = list[k].id;
        }
        if (k === "whatsapp" && !this.isLImitedTime) {
          this.selectedChannelList.push(k);
          this.prewhatsappLayoutId = list[k].id;
        }
        if (k === "appOnly") {
          this.selectedChannelList.push(k);
          this.prewhatsappLayoutId = list[k].id;
        }
        if (k === "pos") {
          this.selectedChannelList.push(k);
          this.prewhatsappLayoutId = list[k].id;
        }
        if (k === "pushNotification") {
          this.selectedChannelList.push(k);
          this.prewhatsappLayoutId = list[k].id;
          this.offerPushNotificationSubject = list[k].title;
          this.offerPushTitleAlias = list[k].aliasTitle;
          this.offerPushNotificationBody = list[k].body;
        }
      }
    }
  }
  isChannelChecked(channel) {
    if (
      this.selectedChannelList.indexOf(channel.toLowerCase()) !== -1 ||
      this.selectedChannelList.indexOf(channel)
    ) {
      return true;
    } else {
      return false;
    }
  }
  isChannelChecked2(channel) {
    if (
      this.selectedChannelList.find(
        (item) => item.toLowerCase() === channel.toLowerCase()
      )
    ) {
      return true;
    } else {
      return false;
    }
  }
  checkChannel(channel) {
    if (this.isChannelChecked2(channel)) {
      this.selectedChannelList = this.selectedChannelList.filter(
        (ele) => ele.toLowerCase() != channel.toLowerCase()
      );
    } else {
      this.selectedChannelList.push(channel);
    }
    this.channelConfigureStatus = false;
    this.onSelectChange(channel);
    this.getCustomerCount();
  }
  getId(channel) {
    switch (channel) {
      case "SMS":
        return 18;
      case "Email":
        return 10;
      case "WhatsApp":
        return 41;
      case "appOnly":
        return 50;
      case "POS":
        return 52;
      case "pushNotification":
        return 55;
      case 'leads':
        return 57;
    }
  }
  isNoDataCondition() {
    const filterValue =
      this.templateDispatchForm.controls.tempsegmentFilter.value?.toLowerCase();
    return (
      this.customerSegmentList.filter((each) =>
        each["name"].toLowerCase().includes(filterValue)
      ).length === 0 &&
      this.customerTierList.filter((each) =>
        each["name"].toLowerCase().includes(filterValue)
      ).length === 0 &&
      this.customerList.filter((each) =>
        each["name"].toLowerCase().includes(filterValue)
      ).length === 0 &&
      this.employeeList.filter((each) =>
        each["name"].toLowerCase().includes(filterValue)
      ).length === 0
    );
  }
  stringInputHandler(event: KeyboardEvent, maxLength, comparisonStatement?) {
    const inputValue = (event.target as HTMLInputElement).value;
    if (inputValue.length >= maxLength && event.key !== "Backspace") {
      event.preventDefault();
    }
  }

  getOnlySegments(data) {
    const temp = [...data]; // Create a shallow copy of the array
    let index1 = temp.indexOf("LEADS");
    let index2 = temp.indexOf("ALL_CUSTOMERS");

    if (index1 !== -1) {
      temp.splice(index1, 1);
    }
    // Recalculate index2 after modifying the array
    index2 = temp.indexOf("ALL_CUSTOMERS");
    if (index2 !== -1) {
      temp.splice(index2, 1);
    }

    return temp;
  }

  filterCustomerLists() {
    this.storeCreatedCustomList = this.customerList.filter(
      segment => segment.createdBy?.storeId === this.storeId
    );
    this.otherCustomList = this.customerList.filter(
      segment => segment.createdBy?.storeId !== this.storeId
    );
  }

  filterSegments() {
    this.storeCreatedSegments = this.customerSegmentList.filter(segment =>
      segment.createdBy?.storeId === this.storeId
    );
    this.otherSegments = this.customerSegmentList.filter(segment =>
      segment.createdBy?.storeId !== this.storeId
    );
  }
  
  checkChannelAvailability(channel: string): boolean {
    if (channel === 'POS') {
      return this.discountOn === 'CART';
    }
    return true;
  }

}
