<div class="qb-store-details-container">
  <div class="button-row" *ngIf="!isloading">
    <app-breadcrumb></app-breadcrumb>
    <div class="qb-button-wrapper">
      <button
        class="primary-button"
        (click)="addNewCustomer()"
        *ngIf="!editMode"
      >
        Add New Customer
      </button>
      <button
        class="primary-button"
        (click)="enableStoreEdit('EDIT')"
        *ngIf="!editMode && storeQId != null"
      >
        Edit
      </button>
      <button
        class="primary-button"
        (click)="enableStoreEdit('NEW')"
        *ngIf="!editMode && qid == 'null'"
      >
        Add New Store
      </button>
      <button
        class="secondary-button qb-get-customer-offer"
        (click)="backToBasicDetails()"
        *ngIf="editMode"
      >
        Back
      </button>
      <div *ngIf="editMode">
        <button
          (click)="saveStoreMetaInfo()"
          class="primary-button"
          *ngIf="!isApiCalling"
        >
          Save and Close
        </button>
        <button class="primary-button" *ngIf="isApiCalling">
          <mat-spinner diameter="15"></mat-spinner>
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="!isloading && storeQId != null && !editMode" class="qb-w-h-100">
    <div class="store-info-container">
      <div class="section">
        <h2 style="display: flex; gap: 16px; align-items: center">
          Store Information
        </h2>
        <div class="info-item">
          <label class="label-bold">Store ID:</label>
          <span class="label-value">{{
            storeInfo?.storeMetaInfo.storeId
          }}</span>
        </div>
        <div class="qb-first-line-container">
          <div class="info-item">
            <label class="label-bold">Store Name:</label>
            <span class="label-value">{{
              storeInfo?.storeMetaInfo.storeName
            }}</span>
          </div>
          <div class="info-item">
            <label class="label-bold">Chain ID:</label>
            <span class="label-value">{{
              storeInfo?.storeMetaInfo.chainId
            }}</span>
          </div>
          <div class="info-item">
            <label class="label-bold">Platform:</label>
            <span class="label-value">{{
              storeInfo?.storeMetaInfo.platform
            }}</span>
          </div>
          <div class="info-item">
            <label class="label-bold">Category:</label>
            <span class="label-value">{{
              getCategoryName(storeInfo?.storeMetaInfo.category)
            }}</span>
          </div>
        </div>
        <div class="qb-first-line-container">
          <div class="info-item">
            <label class="label-bold">Tags:</label>
            <span class="label-value">{{ storeInfo?.storeMetaInfo.tags ? storeInfo?.storeMetaInfo.tags : "---" }}</span>
          </div>
          <!-- <div class="info-item">
                  <label class="label-bold">Is Offer Enabled:</label>
                  <span class="label-value">{{ storeInfo?.storeMetaInfo.isQfferEnabled }}</span>
                </div> -->
          <div class="info-item">
            <label class="label-bold">Currency:</label>
            <span class="label-value">{{
              storeInfo?.storeMetaInfo.currency
            }}</span>
          </div>
          <div class="info-item">
            <label class="label-bold">Country:</label>
            <span class="label-value">{{
              storeInfo?.storeMetaInfo.country
            }}</span>
          </div>
          <div class="info-item">
            <label class="label-bold">Time-zone:</label>
            <span class="label-value">{{
              storeInfo?.storeMetaInfo?.timezone
            }}</span>
          </div>
        </div>
        <div class="info-item">
          <label class="label-bold">Google review link</label>
          <span class="label-value label-word-break">{{
            storeInfo?.storeMetaInfo?.googleReviewLink ? storeInfo.storeMetaInfo.googleReviewLink : "----"
          }}</span>
        </div>
        <!-- <div class="info-item">
                <label class="label-bold">OneSignal ID:</label>
                <span class="label-value">{{ storeInfo?.storeMetaInfo.onesignalId }}</span>
              </div> -->
      </div>

      <div class="section">
        <!-- <h2> Store Location</h2> -->
        <h2 style="display: flex; gap: 16px; align-items: center">
          Store Location
        </h2>
        <div>
          <div class="info-item">
            <label class="label-bold">Latitude:</label>
            <span class="label-value">{{
              storeInfo?.storeMetaInfo.latitude
            }}</span>
          </div>
          <div class="info-item">
            <label class="label-bold">Longitude:</label>
            <span class="label-value">{{
              storeInfo?.storeMetaInfo.longitude
            }}</span>
          </div>
          <div
            class="info-item"
            *ngIf="
              parseAddressLocationJson(
                storeInfo.storeMetaInfo.addressLocationJson
              )
            "
          >
            <label class="label-bold">Location URL:</label>
            <span class="label-value">{{
              parseAddressLocationJson(
                storeInfo.storeMetaInfo.addressLocationJson
              ).locationUrl || "----"
            }}</span>
          </div>

          <div class="info-item">
            <label class="label-bold">PlaceID:</label>
            <span class="label-value">{{
              storeInfo?.placeId || '----'
            }}</span>
          </div>
        </div>
        <!-- <div class="info-item">
                <label class="label-bold">Address Location JSON:</label>
                <span class="label-value">{{ storeInfo?.storeMetaInfo.addressLocationJson }}</span>
              </div> -->
        <div *ngIf="storeInfo?.storeMetaInfo.addressLocationJson">
          <div
            class="info-item"
            *ngIf="
              parseAddressLocationJson(
                storeInfo.storeMetaInfo.addressLocationJson
              )
            "
          >
            <label class="label-bold">Address:</label>
            <span class="label-value">{{
              parseAddressLocationJson(
                storeInfo.storeMetaInfo.addressLocationJson
              ).address
            }}</span>
          </div>
        </div>

        <div class="location-accordion">
          <div class="label-bold">
            <span
              class="arrow"
              (click)="toggleAccordion()"
              [ngClass]="{ 'arrow-down': showMap, 'arrow-right': !showMap }"
              style="margin-right: 16px"
            ></span>
            <span *ngIf="!showMap" (click)="toggleAccordion()"
              >View in Map</span
            >
            <span *ngIf="showMap" (click)="toggleAccordion()">Hide Map</span>
          </div>
          <div
            class="accordion-content"
            *ngIf="showMap"
            [ngClass]="{ show: showMap }"
          >
            <!-- <div class="info-item" *ngIf="parseAddressLocationJson(storeInfo.storeMetaInfo.addressLocationJson)">
                        <label class="label-bold">Address:</label>
                        <span class="label-value">{{ parseAddressLocationJson(storeInfo.storeMetaInfo.addressLocationJson).address }}</span>
                      </div> -->

            <agm-map
              style="height: 200px"
              [latitude]="parseString(storeInfo?.storeMetaInfo.latitude)"
              [longitude]="parseString(storeInfo?.storeMetaInfo.longitude)"
              [zoom]="zoom"
            >
              <agm-marker
                [latitude]="parseString(storeInfo?.storeMetaInfo.latitude)"
                [longitude]="storeInfo?.storeMetaInfo.longitude"
              ></agm-marker>
            </agm-map>
          </div>
        </div>
      </div>

      <div class="section">
        <!-- <h2>Contact Details</h2> -->
        <h2 style="display: flex; gap: 16px; align-items: center">
          Contact Details
        </h2>
        <div class="qb-first-line-container qb-w-50">
          <div class="info-item">
            <label class="label-bold">Phone Number:</label>
            <div class="qb-d-flex">
              <span class="label-value"><span class="label-value" *ngIf="storeInfo?.phoneNumber">{{ selectedCountry?.callingCodes[0] ? "+" : "" }}{{ selectedCountry?.callingCodes[0] }}</span>&nbsp;{{ storeInfo?.phoneNumber || "--" }}</span>
            </div>
            <!-- <div class="editable-input-disabled-ph">
              {{ selectedCountry?.callingCodes[0] ? "+" : "" }}
              {{ selectedCountry?.callingCodes[0] }}
            </div> -->
          </div>
          <div class="info-item">
            <label class="label-bold">Email:</label>
            <span class="label-value">{{ storeInfo?.email }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="isloading && storeQId != null"
    class="qb-w-h-100 qb-center-center"
  >
    <mat-spinner diameter="24"></mat-spinner>
  </div>

  <div *ngIf="storeQId == null && !editMode" class="qb-w-h-100">
    <div class="qb-center-center qb-flex-row">
      <img
        src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/assets2/registration/18111484.jpg"
        alt=""
        class="qb-img-450"
      />
      <button class="primary-button" (click)="enableStoreEdit('NEW')">
        Add New Store
      </button>
    </div>
  </div>
  <div
    id="qb-edit-page"
    style="display: none"
    class="qb-w-h-100 qb-edit-area"
    #scrollContainerStore
  >
    <div class="section" id="qb-store-top">
      <h2>Store Information</h2>
      <div class="qb-first-line-container">
        <div class="qb-d-flex qb-fd-coloumn qb-flex-1">
          <div class="info-item">
            <label class="label-bold"
              >Store Name<span class="qb-imp-color">*</span>:</label
            >
    
            <input
              *ngIf="editMode"
              [(ngModel)]="tempStoreInfo?.storeMetaInfo.storeName"
              [disabled]="operatons === 'edit' && qid === 'null'"
              class="editable-input"
              (keydown)="storenamepress()"
            />
          </div>
          <small
            *ngIf="errorStoreName.length > 0"
            style="font-size: 12px; color: red"
            >{{ errorStoreName }}</small
          >
        </div>
        <div class="info-item">
          <label class="label-bold">Category:</label>
  
          <!-- <input *ngIf="editMode" [(ngModel)]="tempStoreInfo?.storeMetaInfo.category" class="editable-input" /> -->
          <div class="editable-input">
            <mat-select
              class="qb-select"
              (selectionChange)="selectCategory($event)"
              [(ngModel)]="category"
              placeholder="Select the Category"
            >
              <mat-option
                *ngFor="let item of categories | keyvalue"
                [value]="item.value.categoryId"
                >{{ item.value.categoryName }}</mat-option
              >
            </mat-select>
          </div>
        </div>
        <div class="qb-d-flex qb-fd-coloumn qb-flex-1">
          <div class="info-item relative-pos">
            <label class="label-bold"
              >Country<span class="qb-imp-color">*</span>:</label
            >
            <div class="input-with-dropdown-country">
              <input
                type="text"
                [(ngModel)]="country && country.name"
                #input3
                placeholder="Select"
                (focus)="inpDropdown2.style.display = 'block'"
                (blur)="closeDropdown(inpDropdown2)"
                class="div-inp-country"
                (keydown)="$event.preventDefault()"
                readonly
              />
              <mat-icon class="mat-icon div-icon-country" (click)="input3.focus()"
                >keyboard_arrow_down</mat-icon
              >
              <div #inpDropdown2 class="custom-dropdown">
                <input
                  type="text"
                  class="dropdown-search"
                  [(ngModel)]="coutrySearchValue"
                  (mousedown)="$event.stopPropagation()"
                  (blur)="closeDropdown(inpDropdown2)"
                  placeholder="Search"
                  #input4
                />
                <span
                  class="material-icons-outlined search-icon"
                  (click)="input4.focus()"
                  >search</span
                >
                <div class="ls-container">
                  <div
                    *ngFor="
                      let country of filteredBanks
                        | countryFilter : coutrySearchValue
                    "
                    (mousedown)="selectCountry(country)"
                    class="div-ls"
                  >
                    <img
                      [src]="country.flag"
                      alt="Flag of {{ country.name }}"
                      width="20"
                      height="15"
                    />
                    {{ country.name }} ({{ country.alpha2Code }})
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
          <small
            *ngIf="errorCountry.length > 0"
            style="font-size: 12px; color: red"
            >{{ errorCountry }}</small
          >
        </div>
        <div class="info-item">
          <label class="label-bold">Currency:</label>
          <div class="editable-input-disabled">
            {{ selectedCountry?.currencies[0]["code"] }}
          </div>
        </div>
      </div>

      <div class="info-item">
        <label class="label-bold">Tags:</label>
        <div *ngIf="isTagsLoading">
          <mat-spinner diameter="12"></mat-spinner>
        </div>
        <!-- <input *ngIf="editMode" [(ngModel)]="tempStoreInfo?.storeMetaInfo.tags" class="editable-input" /> -->
        <div class="qb-tag-area">
          <div
            [ngClass]="{
              'qb-tag': !selectedTags.includes(tag),
              'qb-selected-tag': selectedTags.includes(tag)
            }"
            *ngFor="let tag of allTags"
            (click)="addOrRemoveTag(tag)"
          >
            <div>{{ tag }}</div>
            <div
              class="material-icons-outlined qb-add"
              *ngIf="selectedTags.includes(tag)"
            >
              close
            </div>
            <div
              class="material-icons-outlined qb-add"
              *ngIf="!selectedTags.includes(tag)"
            >
              add
            </div>
          </div>
        </div>
      </div>

      <div class="info-item relative-pos">
        <label class="label-bold">Timezone:</label>
        <div class="input-with-dropdown">
          <input
            type="text"
            [(ngModel)]="selectedTimezone"
            #input1
            placeholder="Select"
            (focus)="inpDropdown1.style.display = 'block'"
            (blur)="closeDropdown(inpDropdown1)"
            class="div-inp"
            (keydown)="$event.preventDefault()"
            readonly
          />
          <mat-icon class="mat-icon div-icon" (click)="input1.focus()"
            >keyboard_arrow_down</mat-icon
          >
          <div #inpDropdown1 class="custom-dropdown">
            <input
              type="text"
              class="dropdown-search"
              [(ngModel)]="timezoneSearchValue"
              (mousedown)="$event.stopPropagation()"
              (blur)="closeDropdown(inpDropdown1)"
              placeholder="Search"
              #input2
            />
            <span
              class="material-icons-outlined search-icon"
              (click)="input2.focus()"
              >search</span
            >
            <div class="ls-container">
              <div
                *ngFor="
                  let item of timezoneList | filterPipe : timezoneSearchValue
                "
                (mousedown)="selectTimezone(item)"
                class="div-ls"
              >
                {{ item }}
              </div>
              <div></div>
            </div>
          </div>
        </div>
      </div>

      <div class="info-item">
        <label class="label-bold">Google review link:</label>
        <input
          *ngIf="editMode"
          [(ngModel)]="tempStoreInfo?.storeMetaInfo.googleReviewLink"
          class="editable-input"
          style="width: 50%;"
        />
        <small
          *ngIf="urlMessage.length > 0"
          style="font-size: 12px; color: red"
          >{{ urlMessage }}</small
        >
      </div>
    </div>

    <div class="section">
      <h2>Store Location</h2>
      <!-- <div class="info-item">
              <label class="label-bold">Latitude:</label>
              
              <input *ngIf="editMode" [(ngModel)]="tempStoreInfo?.storeMetaInfo.latitude" class="editable-input" />
            </div>
            <div class="info-item">
              <label class="label-bold">Longitude:</label>
              
              <input *ngIf="editMode" [(ngModel)]="tempStoreInfo?.storeMetaInfo.longitude" class="editable-input" />
            </div> -->
      <div class="info-item">
        <label class="label-bold">Address:</label>
        <div>
          <div>
            <textarea
              class="qb-textarea-st"
              [(ngModel)]="address"
              type="text"
              (keydown.enter)="$event.preventDefault()"
              autocorrect="off"
              autocapitalize="off"
              spellcheck="off"
              type="text"
              placeholder="Search your address here"
              #search
            ></textarea>
          </div>
        </div>
      </div>

      <div class="info-item">
        <label class="label-bold">PlaceID:</label>
        <div>
          <input
            class="editable-input"
            [(ngModel)]="placeId"
            type="text"
            readonly
          />
        </div>
      </div>

      <agm-map
        style="height: 200px"
        [latitude]="lat"
        [longitude]="long"
        [mapTypeId]="googleMapType"
        (mapClick)="addMarker($event)"
        [zoom]="zoom"
      >
        <agm-marker
          [latitude]="lat"
          [longitude]="long"
          (dragEnd)="markerDragEnd($event)"
          [markerDraggable]="true"
        >
        </agm-marker>
      </agm-map>
    </div>

    <div class="section">
      <h2>Contact Details</h2>
      <div class="qb-first-line-container qb-w-50">
        <div class="qb-d-flex qb-fd-coloumn qb-flex-1">
          <div class="info-item">
            <label class="label-bold">Phone Number:</label>
            <div class="qb-input-wrapper-number">
              {{ selectedCountry?.callingCodes[0] ? "+" : "" }}
              {{ selectedCountry?.callingCodes[0] }}
              <input
              type="number"
              *ngIf="editMode"
              [(ngModel)]="tempStoreInfo.phoneNumber"
              class="editable-input-number"
              (keydown)="numberpress($event, 13)"
            />
            </div>
            <!-- <div class="editable-input-disabled-ph">
           
            </div> -->
            <!-- <span *ngIf="!editMode" class="label-value">{{ storeInfo?.phoneNumber }}</span> -->
          </div>
          <small
            style="font-size: 12px; color: red"
            *ngIf="phoneNumberErrorText.length > 0"
            >{{ phoneNumberErrorText }}</small
          >
        </div>
        <div class="qb-d-flex qb-fd-coloumn qb-flex-1">
          <div class="info-item">
            <label class="label-bold"
              >Email<span class="qb-imp-color">*</span>:</label
            >
              <input
              *ngIf="editMode"
              [(ngModel)]="tempStoreInfo.email"
              class="editable-input"
              (keydown)="emailpress()"
            />
           
            <!-- <span *ngIf="!editMode" class="label-value">{{ storeInfo?.email }}</span> -->
          </div>
          <small *ngIf="errorMail.length > 0" style="font-size: 12px; color: red">{{
            errorMail
          }}</small>
        </div>
      </div>
    </div>
  </div>
</div>
