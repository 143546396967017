import { getCurrencySymbol } from "@angular/common";
import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import * as echarts from "echarts";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { SnackbarCollection } from "src/app/core/services/snackbar.service";
import {
  DataPreviewAmalyticsDialogBoxComponent,
  InsightGraphDialogBoxComponent,
} from "src/app/shared/components/dialog-box";
import { TranslateDynamicText } from "src/app/shared/pipes/dynamic-translation.pipe";
import { EChartsOption } from "echarts";
import { MatSelect } from "@angular/material/select";
import { Globals } from "src/app/core/services/global.service";

@Component({
  selector: "app-loyalty-trend",
  templateUrl: "./loyalty-trend.component.html",
  styleUrls: ["./loyalty-trend.component.css"],
})
export class LoyaltyTrendComponent implements OnInit {
  constructor(
    private _apiCall: ApiCallService,
    private _snackBar: SnackbarCollection,
    private _i18nDynamicTranslate: TranslateDynamicText,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private globalService: Globals,
  ) {}

  totalCustomerRevenueFrameList = ["Quarter", "Month", "Week"];
  timeFrame = "Month";
  loyaltyTrendGraph: echarts.EChartsCoreOption;
  totalSignUpPieChart: echarts.EChartsCoreOption;
  dataLoading = false;
  revCurrency = sessionStorage.getItem("serviceCurrency");
  currencySymbol = getCurrencySymbol(this.revCurrency, "narrow");
  availableFilter: any = {};
  selectedFilter: any = {};
  loyaltyRevenueSelectedFilter: any = {};
  otherSelectedFilters: any = {};
  selectedMonth = "";
  availableMonths = [];
  selectedStartIndex;
  selectedEndIndex;
  isLoading = false;
  previousYearDataSales = [];
  selectedStartPeriod;
  selectedEndPeriod;
  isNoDataAvailable = false;
  totalSignUpPie = [
    {
      name: "Current Year:",
      count: "0",
      color: "#8280FF",
    },
    {
      name: "Previous Year:",
      count: "0",
      color: "#ADCCFF",
    },
  ];
  totalComparisonReveuePie = [
    {
      name: "Loyalty Customers:",
      count: "0",
      color: "#8280FF",
    },
    {
      name: "Non-Loyalty Customers:",
      count: "0",
      color: "#ADCCFF",
    },
  ];
  totalComparisonCustomerCountPie = [
    {
      name: "Loyalty Customers:",
      count: "0",
      color: "#8280FF",
    },
    {
      name: "Non-Loyalty Customers:",
      count: "0",
      color: "#ADCCFF",
    },
  ];
  totalComparisonAvgSpendPie = [
    {
      name: "Loyalty Customers:",
      count: "0",
      color: "#8280FF",
    },
    {
      name: "Non-Loyalty Customers:",
      count: "0",
      color: "#ADCCFF",
    },
  ];
  selectedTab = "";
  earnedByTierTimeFrameList = ["Week", "Month", "Year"];
  earnedByTierValueList = ["Points", "Revenue"];
  loyaltyMembersTimeFrame = "month";
  earnedByTierTimeFrame = "Month";
  earnedByTierValue = "Points";
  pointsByTier = [];
  dashboardData;
  activeUsersData = [];
  tierWiseRevenue = [];
  totalRevenue = 0;
  totalActiveUsers = 0;
  rewardRedemptionRateStat = [];
  tempSelectedStart;
  tempSelectedEnd;
  totalLoyaltyCustomerCount = 0;
  totalNonLoyaltyCustomerCount = 0;
  rewardRedemptionRateStatOptions: EChartsOption;
  earnedByTierChartOptions: EChartsOption;
  activeUsersChartOptions: EChartsOption;
  tierWiseRevenueOptions: EChartsOption;
  loyaltyComparisonGraph: EChartsOption;
  loyaltyComparisonRevenuePieChart: EChartsOption;
  loyaltyComparisonCustomerCountPieChart: EChartsOption;
  loyaltyCompariosnAvgSpendPieChart: EChartsOption;
  @ViewChild("startPeriodLoyalty") startPeriod: MatSelect;
  @ViewChild("endPeriodLoyalty") endPeriod: MatSelect;

  ngOnInit(): void {
    // this.getFilters();
    this.route.queryParams.subscribe((params) => {
      if (params.type) {
        this.selectedTab = params.type;
        this.selectTab(params.type);
      } else {
        this.router.navigate(["/app/loyalty-trends"], {
          queryParams: { type: "signUp" },
        });
        this.selectedTab = "signUp";
        this.selectTab("signUp");
      }
    });
  }

  selectTab(tab) {
    this.selectedTab = tab;
    if (tab === "signUp") {
      this.getFilters();
    } else if (tab === "loyaltyRevenue") {
      this.getComparisonGraphFilters();
    } else {
      this.getLoyaltyProgramDashboardData();
    }
  }

  totalCustomerRevenueTimeFrameDropDownHandler() {
    if (this.selectedTab === "signUp") {
      this.getFilters();
    } else if (this.selectedTab === "loyaltyRevenue") {
      this.getComparisonGraphFilters();
    }
  }
  previousYearDataFuntion(data): any {
    if (data != null) {
      const dataMap = new Map(
        data.map((item) => [
          item[this.timeFrame.toLowerCase()],
          item.totalCustomers,
        ])
      );

      const newArray = this.otherSelectedFilters[
        this.timeFrame.toLowerCase()
      ].map((groupValue) => ({
        group_value: groupValue,
        amount: dataMap.has(this.getpreviousYear(groupValue))
          ? dataMap.get(this.getpreviousYear(groupValue))
          : 0,
      }));
      console.log(newArray);
      return newArray;
    }
  }

  getpreviousYear(date) {
    if (
      this.timeFrame.toLowerCase() === "month" ||
      this.timeFrame.toLowerCase() === "week"
    ) {
      const [year, month] = date?.split("-").map(Number); // Extract month and year
      const previousYear = year - 1; // Calculate the previous year

      // Generate 'MM-YYYY' format for previous year with the same month
      const previousYearSameMonth = `${previousYear}-${month
        .toString()
        .padStart(2, "0")}`;
      return previousYearSameMonth;
    } else if (this.timeFrame.toLowerCase() === "quarter") {
      const [year, quarter] = date?.split("Q").map(Number); // Extract month and year
      const previousYear = year - 1; // Calculate the previous year

      // Generate 'MM-YYYY' format for previous year with the same month
      const previousYearSameMonth = `${previousYear}Q${quarter}`;
      return previousYearSameMonth;
    }
  }

  getComparisonGraph() {
    this.dataLoading = true;
    let data = {
      stringFilters: [],
      stringValuesToCheck: [],
      doubleFilters: [],
      doubleValuesToCheck: [],
      listFilters: [],
      listValuesToCheck: [],
    };
    data = this.applyFilter();
    this.getCountOfLoyalNonLoyalCustomers(data);
    this.resetPieData();

    this.getBucketChartsApi(data, "loyaltyrevenuecomparison", this.timeFrame.toLowerCase()).then(
      (result) => {
        console.log(result);
        this.dataLoading = false;
        if (result["body"]) {
          console.log(result);
          this.previousYearDataSales = this.previousYearDataFuntion(
            data["body"]
          );
          this.createComparisonGraph(result["body"]);
          this.dataLoading = false;
        } else {
          this.loyaltyComparisonRevenuePieChart = {
            graphic: {
              elements: [
                {
                  type: "text",
                  left: "center",
                  top: "center",
                  style: {
                    text: "No Data Available",
                    font: "18px Arial, sans-serif",
                    fill: "#999",
                  },
                },
              ],
            },
          };
          this.loyaltyComparisonGraph = {
            graphic: {
              elements: [
                {
                  type: "text",
                  left: "center",
                  top: "center",
                  style: {
                    text: "No Data Available",
                    font: "18px Arial, sans-serif",
                    fill: "#999",
                  },
                },
              ],
            },
          };
          this.loyaltyComparisonCustomerCountPieChart = {
            graphic: {
              elements: [
                {
                  type: "text",
                  left: "center",
                  top: "center",
                  style: {
                    text: "No Data Available",
                    font: "18px Arial, sans-serif",
                    fill: "#999",
                  },
                },
              ],
            },
          };
          this.loyaltyCompariosnAvgSpendPieChart = {
            graphic: {
              elements: [
                {
                  type: "text",
                  left: "center",
                  top: "center",
                  style: {
                    text: "No Data Available",
                    font: "18px Arial, sans-serif",
                    fill: "#999",
                  },
                },
              ],
            },
          };
        }
      },
      (err) => {
        this.dataLoading = false;
      }
    );
  }

  getAnalytics() {
    this.dataLoading = true;
    let data = {
      stringFilters: [],
      stringValuesToCheck: [],
      doubleFilters: [],
      doubleValuesToCheck: [],
      listFilters: [],
      listValuesToCheck: [],
    };
    data = this.applyFilter();

    this.getBucketChartsApi(data, "loyaltychart", this.timeFrame.toLowerCase()).then(
      (result) => {
        this.getBucketChartsApi(data, "previousloyaltychart", this.timeFrame.toLowerCase()).then((data) => {
          if (result["body"]) {
            console.log(result);
            this.previousYearDataSales = this.previousYearDataFuntion(
              data["body"]
            );
            this.createLoyaltyTrendGraph(result["body"]);
            this.getLoyaltyProgramDashboardData();
            this.dataLoading = false;
          } else {
            this.totalSignUpPieChart = {
              graphic: {
                elements: [
                  {
                    type: "text",
                    left: "center",
                    top: "center",
                    style: {
                      text: "No Data Available",
                      font: "18px Arial, sans-serif",
                      fill: "#999",
                    },
                  },
                ],
              },
            };
            this.loyaltyTrendGraph = {
              graphic: {
                elements: [
                  {
                    type: "text",
                    left: "center",
                    top: "center",
                    style: {
                      text: "No Data Available",
                      font: "18px Arial, sans-serif",
                      fill: "#999",
                    },
                  },
                ],
              },
            };
          }
        });
      },
      (err) => {
        this.dataLoading = false;
      }
    );
  }

  createComparisonGraph(data) {
    let currencySymbol = this.currencySymbol;
    let type = this.timeFrame;
    let x = [], loyaltyRev = [], nonLoyaltyRev = [], totalLoyaltyRev = 0, totalNonLoayltyRev = 0, avgSpendPerLoyaltyCustomerRevenue = 0, avgSpendPerNonLoyaltyCustomerRevenue = 0;

    data.sort((a, b) => a["group_value"].localeCompare(b["group_value"]));
    data.forEach((data) => {
      x.push(data["group_value"]);
      loyaltyRev.push(
        data.loyalty_customer_count > 0
          ? (data.loyaltyRevenue / data.loyalty_customer_count).toFixed(2)
          : 0
      );
      avgSpendPerLoyaltyCustomerRevenue +=
        data.loyalty_customer_count > 0
          ? data.loyaltyRevenue / data.loyalty_customer_count
          : 0;
      totalLoyaltyRev += data.loyaltyRevenue;
      // this.totalLoyaltyCustomerCount += data.loyalty_customer_count;
      nonLoyaltyRev.push(data.non_loyalty_customer_count > 0 ? (data.nonLoyaltyRevenue / data.non_loyalty_customer_count).toFixed(2) : 0);
      avgSpendPerNonLoyaltyCustomerRevenue += (data.non_loyalty_customer_count > 0 ? (data.nonLoyaltyRevenue / data.non_loyalty_customer_count) : 0);
      totalNonLoayltyRev += data.nonLoyaltyRevenue;
      // this.totalNonLoyaltyCustomerCount += data.non_loyalty_customer_count;
    });
    this.totalComparisonReveuePie[0].count = totalLoyaltyRev >= 1000 ? (totalLoyaltyRev / 1000).toFixed(2) + 'K' : totalLoyaltyRev.toFixed(2).toString();
    this.totalComparisonReveuePie[1].count = totalNonLoayltyRev >= 1000 ? (totalNonLoayltyRev / 1000).toFixed(2) + 'K' : totalNonLoayltyRev.toFixed(2).toString();
    this.totalComparisonCustomerCountPie[0].count = this.totalLoyaltyCustomerCount >= 1000 ? (this.totalLoyaltyCustomerCount / 1000).toFixed(2) + 'K' : this.totalLoyaltyCustomerCount.toFixed(2).toString();
    this.totalComparisonCustomerCountPie[1].count = this.totalNonLoyaltyCustomerCount >= 1000 ? (this.totalNonLoyaltyCustomerCount / 1000).toFixed(2) + 'K' : this.totalNonLoyaltyCustomerCount.toFixed(2).toString();
    this.totalComparisonAvgSpendPie[0].count = this.totalLoyaltyCustomerCount > 0 
    ? ((totalLoyaltyRev / this.totalLoyaltyCustomerCount) >= 1000 
        ? (totalLoyaltyRev / this.totalLoyaltyCustomerCount / 1000).toFixed(2) + 'K' 
        : (totalLoyaltyRev / this.totalLoyaltyCustomerCount).toFixed(2)) 
    : '0';
    this.totalComparisonAvgSpendPie[1].count = this.totalNonLoyaltyCustomerCount > 0 
    ? ((totalNonLoayltyRev / this.totalNonLoyaltyCustomerCount) >= 1000 
        ? (totalNonLoayltyRev / this.totalNonLoyaltyCustomerCount / 1000).toFixed(2) + 'K' 
        : (totalNonLoayltyRev / this.totalNonLoyaltyCustomerCount).toFixed(2)) 
    : '0';

    function getAggratedValue(value) {
      return value >= 1000 ? (value / 1000).toFixed(2) + "K" : value.toFixed(2);
    }

    this.loyaltyComparisonRevenuePieChart = {
      title: {
        text: `${currencySymbol} ${getAggratedValue(
          totalLoyaltyRev + totalNonLoayltyRev
        )}`,
        left: "center",
        top: "center",
      },
      tooltip: {
        formatter: function (params) {
          const dataIndex = params.dataIndex;
          console.log(params);
          const formattedRevenue =
            params.value >= 1000
              ? (params.value / 1000).toFixed(2) + "K"
              : params.value.toFixed(2);

          const formattedTooltip = `${params.name}: ${currencySymbol} ${formattedRevenue}`;
          return formattedTooltip;
        },
      },
      series: [
        {
          type: "pie",
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: "center",
          },
          labelLine: {
            show: false,
          },
          data: [
            {
              value: totalLoyaltyRev,
              name: "Loyalty Customers",
            },
            {
              value: totalNonLoayltyRev,
              name: "Non-Loyalty Customers",
            },
          ],
          radius: ["70px", "85px"],
          color: ["#8280FF", "#ADCCFF"],
        },
      ],
    };

    this.loyaltyComparisonCustomerCountPieChart = {
      title: {
        text: `${getAggratedValue(this.totalLoyaltyCustomerCount + this.totalNonLoyaltyCustomerCount)}`,
        left: 'center',
        top: 'center'
      },
      tooltip: {
        formatter: function (params) {
          const dataIndex = params.dataIndex;
          console.log(params);
          const formattedRevenue =
            params.value >= 1000
              ? (params.value / 1000).toFixed(2) + "K"
              : params.value;

          const formattedTooltip = `${params.name}: ${formattedRevenue}`;
          return formattedTooltip;
        },
      },
      series: [
        {
          type: "pie",
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: "center",
          },
          labelLine: {
            show: false,
          },
          data: [
            {
              value: this.totalLoyaltyCustomerCount,
              name: 'Loyalty Customers'
            },
            {
              value: this.totalNonLoyaltyCustomerCount,
              name: 'Non-Loyalty Customers'
            }
          ],
          radius: ["70px", "85px"],
          color: ["#8280FF", "#ADCCFF"],
        },
      ],
    };

    this.loyaltyCompariosnAvgSpendPieChart = {
      title: {
        text: `${currencySymbol} ${getAggratedValue((totalLoyaltyRev / this.totalLoyaltyCustomerCount) + (totalNonLoayltyRev / this.totalNonLoyaltyCustomerCount))}`,
        left: 'center',
        top: 'center'
      },
      tooltip: {
        formatter: function (params) {
          const dataIndex = params.dataIndex;
          console.log(params);
          const formattedRevenue =
            params.value >= 1000
              ? (params.value / 1000).toFixed(2) + "K"
              : params.value.toFixed(2);

          const formattedTooltip = `${params.name}: ${currencySymbol} ${formattedRevenue}`;
          return formattedTooltip;
        },
      },
      series: [
        {
          type: "pie",
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: "center",
          },
          labelLine: {
            show: false,
          },
          data: [
            {
              value: (totalLoyaltyRev / this.totalLoyaltyCustomerCount),
              name: 'Loyalty Customers'
            },
            {
              value: (totalNonLoayltyRev / this.totalNonLoyaltyCustomerCount),
              name: 'Non-Loyalty Customers'
            }
          ],
          radius: ["70px", "85px"],
          color: ["#8280FF", "#ADCCFF"],
        },
      ],
    };

    this.loyaltyComparisonGraph = {
      tooltip: {
        formatter: function (params) {
          console.log(params);
          const dataValue = params.data;
          const dataIndex = params.dataIndex;
          let formattedTooltip = "";
          if (params.seriesName === "Non-Loyalty Customers") {
            formattedTooltip = `Total Revenue: ${currencySymbol} ${data[
              dataIndex
            ]["nonLoyaltyRevenue"].toFixed(
              2
            )} <br/> Average Spend: ${currencySymbol} ${dataValue} <br/> Customer Count: ${
              data[dataIndex]["non_loyalty_customer_count"]
            }`;
          } else {
            formattedTooltip = `Total Revenue: ${currencySymbol} ${data[
              dataIndex
            ]["loyaltyRevenue"].toFixed(
              2
            )} <br/> Average Spend: ${currencySymbol} ${dataValue} <br/> Customer Count: ${
              data[dataIndex]["loyalty_customer_count"]
            }`;
          }
          return formattedTooltip; // Customize the tooltip content
        },
      },
      xAxis: {
        data: x,
        axisLabel: {
          formatter: function (value, index) {
            if (value) {
              if (type.toLowerCase() === "month") {
                let [year, month] = value.split("-");

                // Convert numeric month to three-letter abbreviation
                let monthNames = [
                  "Jan",
                  "Feb",
                  "Mar",
                  "Apr",
                  "May",
                  "Jun",
                  "Jul",
                  "Aug",
                  "Sep",
                  "Oct",
                  "Nov",
                  "Dec",
                ];
                let monthAbbreviation = monthNames[parseInt(month, 10) - 1];

                // Format the label as 'AbbreviatedMonth-Year'
                return `${monthAbbreviation}-${year}`;
              } else if (type.toLowerCase() === "week") {
                let [year, week] = value.split("-");
                return `Week-${week} ${year}`;
              } else {
                let [year, quarter] = value.split("Q");
                return `${year} Q${quarter}`;
              }
            } else {
              return null;
            }
          },
        },
      },
      legend: {
        data: ["Loyalty Customers", "Non-Loyalty Customers"],
        orient: "horizontal",
        left: 20,
        bottom: 10,
      },
      yAxis: {},
      series: [
        {
          type: "bar",
          data: nonLoyaltyRev,
          barGap: "0%",
          name: "Non-Loyalty Customers",
          barCategoryGap: "40%",
          itemStyle: {
            color: "#ADCCFF",
          },
        },
        {
          type: "bar",
          name: "Loyalty Customers",
          data: loyaltyRev,
          itemStyle: {
            color: "#8280FF",
          },
        },
      ],
    };
  }

  createLoyaltyTrendGraph(data) {
    console.log(data);
    data.sort((a, b) =>
      a[this.timeFrame.toLowerCase()].localeCompare(
        b[this.timeFrame.toLowerCase()]
      )
    );
    let currencySymbol = this.currencySymbol;
    let x1 = [],
      y1 = [],
      py1 = [],
      totaly1 = 0,
      totalpy1 = 0;
    data.forEach((data) => {
      //data['name'] = data.dateRange;
      x1.push(data[this.timeFrame.toLowerCase()]);
      y1.push(data.totalCustomers);
      totaly1 += data.totalCustomers;
      // data['value'] = data.count;
    });
    this.previousYearDataSales?.forEach((data) => {
      py1.push(data.amount);
      totalpy1 += data.amount;
    });
    this.totalSignUpPie[0].count =
      totaly1 >= 1000 ? totaly1 / 1000 + "K" : totaly1.toString();
    this.totalSignUpPie[1].count =
      totalpy1 >= 1000 ? totalpy1 / 1000 + "K" : totalpy1.toString();

    function getAggratedValue(value) {
      return value >= 1000 ? (value / 1000).toFixed(2) + "K" : value.toFixed(2);
    }

    let type = this.timeFrame;
    this.totalSignUpPieChart = {
      title: {
        text: `${getAggratedValue(totaly1 + totalpy1)}`,
        left: "center",
        top: "center",
      },
      tooltip: {
        formatter: function (params) {
          const dataIndex = params.dataIndex;
          console.log(params);
          const formattedRevenue =
            params.value >= 1000
              ? (params.value / 1000).toFixed(2) + "K"
              : params.value;

          const formattedTooltip = `${params.name}: ${formattedRevenue}`;
          return formattedTooltip;
        },
      },
      series: [
        {
          type: "pie",
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: "center",
          },
          labelLine: {
            show: false,
          },
          data: [
            {
              value: totaly1,
              name: "Current Year",
            },
            {
              value: totalpy1,
              name: "Previous Year",
            },
          ],
          radius: ["70px", "85px"],
          color: ["#8280FF", "#ADCCFF"],
        },
      ],
    };
    this.loyaltyTrendGraph = {
      tooltip: {
        formatter: function (params) {
          const dataIndex = params.data;
          return `Total Customers: ${dataIndex}`; // Customize the tooltip content
        },
      },
      xAxis: {
        data: x1,
        axisLabel: {
          formatter: function (value, index) {
            if (value) {
              if (type.toLowerCase() === "month") {
                let [year, month] = value.split("-");

                // Convert numeric month to three-letter abbreviation
                let monthNames = [
                  "Jan",
                  "Feb",
                  "Mar",
                  "Apr",
                  "May",
                  "Jun",
                  "Jul",
                  "Aug",
                  "Sep",
                  "Oct",
                  "Nov",
                  "Dec",
                ];
                let monthAbbreviation = monthNames[parseInt(month, 10) - 1];

                // Format the label as 'AbbreviatedMonth-Year'
                return `${monthAbbreviation}-${year}`;
              } else if (type.toLowerCase() === "week") {
                let [year, week] = value.split("-");
                return `Week-${week} ${year}`;
              } else {
                let [year, quarter] = value.split("Q");
                return `${year} Q${quarter}`;
              }
            } else {
              return null;
            }
          },
        },
      },
      legend: {
        data: ["Current Year", "Previous Year"],
        orient: "horizontal",
        left: 20,
        bottom: 10,
      },
      yAxis: {},
      series: [
        {
          type: "bar",
          data: py1,
          barGap: "0%",
          name: "Previous Year",
          barCategoryGap: "40%",
          itemStyle: {
            color: "#ADCCFF",
          },
        },
        {
          type: "bar",
          name: "Current Year",
          data: y1,
          itemStyle: {
            color: "#8280FF ",
          },
        },
      ],
    };
  }

  getFilters() {
    this.dataLoading = true;
    this.availableFilter = {};
    this.otherSelectedFilters = {};
    this.getAllFiltersApi("loyalty").then(
      (result) => {
        this.dataLoading = false;
        if (result) {
          let data = result["body"];
          // this.createTotalCustomerSalesGraph(result['body']['totalSales'], result['body']['totalCustomers']);
          for (let d in data) {
            console.log(d, data[d]);
            if (d !== "Segment") {
              this.availableFilter[d] = data[d];
              this.otherSelectedFilters[d] = [];
              if (d == this.timeFrame.toLowerCase()) {
                this.availableMonths = data[d];
              }
            } else {
              this.availableFilter[d] = this.getSegmentData(data[d]);
              this.otherSelectedFilters[d] = [];
            }
          }
          console.log(this.availableFilter, this.otherSelectedFilters);
          this.selectedStartPeriod =
            this.availableFilter[this.timeFrame.toLowerCase()][0];
          this.selectedEndPeriod =
            this.availableFilter[this.timeFrame.toLowerCase()][
              this.availableFilter[this.timeFrame.toLowerCase()].length > 12
                ? 12
                : this.availableFilter[this.timeFrame.toLowerCase()].length - 1
            ];
          for (
            let i = this.availableFilter[this.timeFrame.toLowerCase()].indexOf(
                this.selectedStartPeriod
              ),
              j = this.availableFilter[this.timeFrame.toLowerCase()].indexOf(
                this.selectedEndPeriod
              );
            i <= j;
            i++
          ) {
            this.otherSelectedFilters[this.timeFrame.toLowerCase()]?.push(
              this.availableFilter[this.timeFrame.toLowerCase()][i]
            );
          }
          this.selectedFilter = this.otherSelectedFilters;
          this.getAnalytics();
        }
      },
      (error) => {
        this.dataLoading = false;
        this.isNoDataAvailable = true;
      }
    );
  }

  getComparisonGraphFilters() {
    this.dataLoading = true;
    this.availableFilter = {};
    this.selectedFilter = {};
    this.loyaltyRevenueSelectedFilter = {};
    this.getAllFiltersApi(this.timeFrame.toLowerCase()).then(
      (result) => {
        this.dataLoading = false;
        if (result) {
          let data = result["body"];
          // this.createTotalCustomerSalesGraph(result['body']['totalSales'], result['body']['totalCustomers']);
          for (let d in data) {
            console.log(d, data[d]);
            if (d !== "Segment") {
              this.availableFilter[d] = data[d].sort();
              this.loyaltyRevenueSelectedFilter[d] = [];
              if (d == "group_value") {
                this.availableMonths = data[d];
              }
            } else {
              this.availableFilter[d] = this.getSegmentData(data[d]).sort();
              this.loyaltyRevenueSelectedFilter[d] = [];
            }
          }
          this.selectedStartPeriod =
            this.availableFilter["group_value"][
              this.availableFilter["group_value"].length > 12
                ? this.availableFilter["group_value"].length - 12 - 1
                : 0
            ];
          this.tempSelectedStart = this.selectedStartPeriod;
          this.selectedEndPeriod =
            this.availableFilter["group_value"][
              this.availableFilter["group_value"].length - 1
            ];
          this.tempSelectedEnd = this.selectedEndPeriod;
          for (
            let i = this.availableFilter["group_value"].indexOf(
                this.selectedStartPeriod
              ),
              j = this.availableFilter["group_value"].indexOf(
                this.selectedEndPeriod
              );
            i <= j;
            i++
          ) {
            this.loyaltyRevenueSelectedFilter["group_value"]?.push(
              this.availableFilter["group_value"][i]
            );
          }
          console.log(this.availableFilter, this.selectedFilter);
          this.selectedFilter = this.loyaltyRevenueSelectedFilter;
          this.getComparisonGraph();
        }
      },
      (error) => {
        this.dataLoading = false;
        this.isNoDataAvailable = true;
      }
    );
  }

  getSegmentData(data) {
    let customerSegment = [];
    this._apiCall.getAllCustomerSegments().subscribe(
      (response) => {
        if (response["message"] == "success") {
          let segment = response["body"];
          for (let i = 0; i < segment.length; i++) {
            if (data.indexOf(segment[i]["id"]) !== -1) {
              customerSegment.push({
                id: segment[i]["id"],
                name: segment[i]["segmentName"],
                // isActive: segment[i]["isActive"],
                // status: false,
                // category: segment[i]["category"],
              });
            }
          }
        } else {
          this._snackBar.openSnackBar(
            this._i18nDynamicTranslate.transform(
              "Failed to load customer segments",
              ["POS_PAGE"]
            ),
            1000
          );
          return [];
        }
      },
      (err) => {
        this._snackBar.openSnackBar(
          this._i18nDynamicTranslate.transform(err.error.body, ["POS_PAGE"]),
          1000
        );
        return [];
      }
    );
    return customerSegment;
  }

  getDisplayFilterName(value) {
    switch (value) {
      case "PointsBucket":
        return "Loyalty Points Bucket";
      case "Segment":
        return "Segment Name";
      case "age_bucket":
        return "Age Bucket";
      case "customerAge":
        return "Customer's Age";
      case "tier_level":
        return "Tier Level";
      case "tier_name":
        return "Tier Name";
      case "storeName":
        return "Store Name";
      case "category":
        return "Product Category";
      case "cltv_bucket":
        return "Cltv Bucket";
      default:
        return value.toLowerCase().replace(/\b\w/g, function (char) {
          return char.toUpperCase();
        });
    }
  }

  onChartClick(event: any): void {
    // Access the clicked data and handle the click event
    if (event.data) {
      console.log("Bubble clicked! Data:", event.name);
      // Call your function or perform actions based on the clicked bubble data
      this.isLoading = true;
      this.getCustomerList(event.name);
    }
  }

  getCustomerList(val) {
    let data = this.applyFilter();
    data.stringFilters.push(this.timeFrame.toLowerCase());
    data.stringValuesToCheck.push([val]);
    this.getBucketChartsApi(data, "loyaltycustomerslist", this.timeFrame.toLowerCase()).then((result) => {
      this.dataLoading = false;
      if (result) {
        this.isLoading = false;
        console.log(result);
        const dialogRef = this.dialog.open(
          DataPreviewAmalyticsDialogBoxComponent,
          {
            panelClass: "no-padding-dialog-popup",
            width: "780px",
            height: "auto",
            data: {
              customerDetails: result["body"],
              header: "Customer Details",
            },
          }
        );
      }
    });
  }

  applyFilter() {
    let data = {
      stringFilters: [],
      stringValuesToCheck: [],
      doubleFilters: [],
      doubleValuesToCheck: [],
      listFilters: [],
      listValuesToCheck: [],
    };

    let tabFilter = this.selectedFilter;

    for (let value in tabFilter) {
      if (tabFilter[value].length !== 0) {
        if (value !== "tier_level" && value !== "Segment") {
          data.stringFilters.push(value);
          data.stringValuesToCheck.push(tabFilter[value]);
        } else if (value === "Segment") {
          data.listFilters.push(value);
          data.listValuesToCheck.push(tabFilter[value]);
        } else {
          data.doubleFilters.push(value);
          data.doubleValuesToCheck.push(tabFilter[value]);
        }
      }
    }

    console.log(data);
    return data;
  }

  selectAllOptions(key, value) {
    if (this.selectedFilter[key].length === value.length) {
      this.selectedFilter[key] = [];
    } else {
      if (key !== "Segment") this.selectedFilter[key] = [...value];
      else {
        let valueSegment = [];
        value.forEach((data) => {
          valueSegment.push(data.id);
        });
        this.selectedFilter[key] = valueSegment;
      }
    }
  }

  earnedByTierDropdownHandler() {
    this._apiCall
      .earnedByTierDropdownChange(
        this.earnedByTierTimeFrame.toLowerCase(),
        this.earnedByTierValue.toLowerCase()
      )
      .subscribe(
        (data) => {
          this.pointsByTier = data["body"];
        },
        (error) => {
          this._snackBar.openSnackBar(
            this._i18nDynamicTranslate.transform("Error performing action", [
              "POS_PAGE",
            ]),
            2000
          );
        }
      );
  }

  getLoyaltyProgramDashboardData() {
    this.dataLoading = true;
    this._apiCall.getLoyaltyProgramDashboardData().subscribe(
      (data) => {
        this.dashboardData = data["body"];
        // this.newLoyaltyMembers = this.dashboardData.newLoyaltyMembers;
        this.activeUsersData = this.dashboardData.activeUsers;
        this.setActiveUsersChartOptions();
        this.tierWiseRevenue = this.dashboardData.tierWiseRevenue;
        this.setTierWiseRevenueChartOptions();
        this.rewardRedemptionRateStat =
          this.dashboardData.rewardRedamationRateStat;
        this.setRewardRedemptionRateStatChartOptions();
        this.pointsByTier = this.dashboardData.pointsByTier;
        this.setEarnedByTierChartOptions();
        this.dataLoading = false;
        this.isNoDataAvailable = false;
      },
      (error) => {
        this._snackBar.openSnackBar(
          this._i18nDynamicTranslate.transform(
            "Error fetching dashboard data",
            ["POS_PAGE"]
          ),
          2000
        );
        console.log(error);
        this.isNoDataAvailable = true;
        this.dataLoading = false;
      }
    );
  }

  getRandomColor() {
    // Generate random RGB values between 0 and 255
    var r = Math.floor(Math.random() * 256);
    var g = Math.floor(Math.random() * 256);
    var b = Math.floor(Math.random() * 256);

    // Return the contrast color and the original color as an object
    return "rgb(" + r + "," + g + "," + b + ")";
  }

  getBucketChartsApi(data, type, timeFrame){
    return new Promise((resolve, reject) => {
      this._apiCall.getReportChart(data, type, "POST", timeFrame).subscribe(
        (res) => {
          console.log(res);
          resolve(res);
        },
        (err) => {
          console.log(err.error);

            reject(false);
          }
        );
    });
  }

  openInsight(type) {
    const dialogref = this.dialog.open(InsightGraphDialogBoxComponent, {
      panelClass: "no-padding-dialog-popup",
      width: "800px",
      height: "530px",
      data: {
        type: type,
      },
    });
  }

  getAllFiltersApi(type) {
    return new Promise((resolve, reject) => {
      this._apiCall.getFilterForAnalytics(type).subscribe(
        (res) => {
          console.log(res);
          resolve(res);
        },
        (err) => {
          console.log(err.error);

          reject(false);
        }
      );
    });
  }

  setRewardRedemptionRateStatChartOptions() {
    let valSerOpt = [];
    let xAxisDataOpt = [];
    for (let each of this.rewardRedemptionRateStat) {
      xAxisDataOpt.push(each.dateRange);
      valSerOpt.push(parseInt(each.count));
    }
    this.rewardRedemptionRateStatOptions = {
      tooltip: {},
      xAxis: {
        type: "category",
        data: xAxisDataOpt,
        name: "Month",
        nameLocation: "middle",
        nameGap: 23,
      },
      yAxis: {
        type: "value",
        name: "Number of Customers",
      },
      series: [
        {
          data: valSerOpt,
          type: "bar",
        },
      ],
    };
  }

  setEarnedByTierChartOptions() {
    let valSerOpt: EChartsOption["series"] = [];
    let xAxisOpt: EChartsOption["xAxis"] = {
      type: "category",
      boundaryGap: false,
      data: [],
      name: this.earnedByTierTimeFrame,
      nameLocation: "middle",
      nameGap: 23,
    };
    let legendOpt: EChartsOption["legend"] = {
      data: [],
      type: "scroll",
    };
    for (let each of this.pointsByTier) {
      legendOpt.data.push(each.tier);
      let val = [];
      for (let dataPoint of each.values) {
        val.push(parseFloat(dataPoint.value));
        if (xAxisOpt.data.length < each.values.length) {
          xAxisOpt.data.push(dataPoint.dateRange);
        }
      }
      valSerOpt.push({
        name: each.tier,
        type: "line",
        data: val,
      });
    }
    this.earnedByTierChartOptions = {
      tooltip: {
        trigger: "axis",
      },
      legend: legendOpt,
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      xAxis: xAxisOpt,
      yAxis: {
        type: "value",
        name: this.earnedByTierValue,
        nameLocation: "end",
        min: "dataMin",
      },
      series: valSerOpt,
    };
  }

  setActiveUsersChartOptions() {
    let dataOpt = this.activeUsersData.map((each) => {
      return {
        value: each.count,
        name: each.name,
      };
    });
    this.activeUsersChartOptions = {
      tooltip: {
        trigger: "item",
      },
      legend: {
        type: "scroll",
        top: "90%",
        width: "100%",
        formatter: function (name: string) {
          const dataItem = dataOpt.find((item) => item.name === name);
          return `${name} - ${dataItem ? dataItem.value : ""}`;
        },
        textStyle: {
          fontSize: "13px",
          fontWeight: "bold",
        },
      },
      series: [
        {
          name: "Active users",
          type: "pie",
          center: ["50%", "50%"],
          radius: ["80px", "125px"],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 10,
            borderColor: "#fff",
            borderWidth: 2,
          },
          label: {
            show: false,
            position: "center",
          },
          labelLine: {
            show: false,
          },
          data: dataOpt,
        },
      ],
    };
  }

  setTierWiseRevenueChartOptions() {
    let dataOpt = this.tierWiseRevenue.map((each) => {
      return {
        value: parseFloat(each.totalRevenue),
        name: each.tierName,
      };
    });
    this.tierWiseRevenueOptions = {
      tooltip: {
        trigger: "item",
        formatter: (params: any) => {
          const value = params.value || 0;
          return `${params.name}: ${this.currencySymbol} ${value}`;
        },
      },
      legend: {
        type: "scroll",
        top: "90%",
        width: "100%",
        formatter: (name: string) => {
          const dataItem = dataOpt.find((item) => item.name === name);
          return `${name} - ${this.currencySymbol} ${
            dataItem ? dataItem.value : ""
          }`;
        },
        textStyle: {
          fontSize: "13px",
          fontWeight: "bold",
        },
      },
      series: [
        {
          name: "Active users",
          type: "pie",
          center: ["50%", "50%"],
          radius: ["110px", "125px"],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 10,
            borderColor: "#fff",
            borderWidth: 2,
          },
          label: {
            show: false,
            position: "center",
          },
          labelLine: {
            show: false,
          },
          data: dataOpt,
        },
      ],
    };
  }

  startPeriodChange() {
    this.endPeriod.open();
  }

  endPeriodChange(isClosed) {
    if (
      this.tempSelectedEnd !== this.selectedEndPeriod ||
      this.tempSelectedStart !== this.selectedStartPeriod
    )
      this.getDateChangeData();
    if (!isClosed || this.tempSelectedStart !== this.selectedStartPeriod) {
      this.tempSelectedEnd = this.selectedEndPeriod;
      this.tempSelectedStart = this.selectedStartPeriod;
    }
  }

  getDateChangeData() {
    console.log(this.selectedFilter, this.availableFilter);
    this.selectedFilter["group_value"] = [];
    for (
      let i = this.availableFilter["group_value"].indexOf(
          this.selectedStartPeriod
        ),
        j = this.availableFilter["group_value"].indexOf(this.selectedEndPeriod);
      i <= j;
      i++
    ) {
      this.selectedFilter["group_value"]?.push(
        this.availableFilter["group_value"][i]
      );
    }
    console.log(this.availableFilter, this.selectedFilter);
    this.getComparisonGraph();
  }

  convertToMonthName(value) {
    if (value) {
      if (this.timeFrame.toLowerCase() === "month") {
        let [year, month] = value.split("-");

        // Convert numeric month to three-letter abbreviation
        let monthNames = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        let monthAbbreviation = monthNames[parseInt(month, 10) - 1];

        // Format the label as 'AbbreviatedMonth-Year'
        return `${monthAbbreviation}-${year}`;
      } else if (this.timeFrame.toLowerCase() === "week") {
        let [year, week] = value.split("-");
        let monthConvertedData = this.globalService.getStartOfWeekByYearWeek(
          year,
          week
        );
        return `${this.globalService.formatDateToCustomDateString(monthConvertedData)} (Week-${week} ${year})`;
      } else {
        let [year, quarter] = value.split("Q");
        return `${year} Q${quarter}`;
      }
    } else {
      return null;
    }
  }
    getCountOfLoyalNonLoyalCustomers(data){
      this.getBucketChartsApi(data, "revenueandcountofloyalnonloyalusers", this.timeFrame.toLowerCase()).then((result)=>{
        if(result){
          result['body'].forEach(entry => {
            if (entry.teirid == -1) {
              this.totalNonLoyaltyCustomerCount += entry.customercount;
            } else if (entry.teirid > -1) {
              this.totalLoyaltyCustomerCount += entry.customercount;
            }
          });
        }
      });
    }

    resetPieData(){
      this.totalComparisonReveuePie = [
        {
          name: "Loyalty Customers:",
          count: "0",
          color: "#8280FF",
        },
        {
          name: "Non-Loyalty Customers:",
          count: "0",
          color: "#ADCCFF",
        },
      ];
      this.totalComparisonCustomerCountPie = [
        {
          name: "Loyalty Customers:",
          count: "0",
          color: "#8280FF",
        },
        {
          name: "Non-Loyalty Customers:",
          count: "0",
          color: "#ADCCFF",
        },
      ];
      this.totalComparisonAvgSpendPie = [
        {
          name: "Loyalty Customers:",
          count: "0",
          color: "#8280FF",
        },
        {
          name: "Non-Loyalty Customers:",
          count: "0",
          color: "#ADCCFF",
        },
      ];
    }
}
