import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loyalty-rule-preview',
  templateUrl: './loyalty-rule-preview.component.html',
  styleUrls: ['./loyalty-rule-preview.component.css']
})
export class LoyaltyRulePreviewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

  }

  @Input() rule;
  @Input() loyaltyPoints:{};
  @Input() offerList = [];
  @Input() applicableCustomerGroupList = [];
  @Input() loyaltyRules:[];

  getOfferNameFromId(id) {
    if(this.offerList.length>1) {
      return (this.offerList.find(obj=>obj.id == id)).offerName;
    } else {
      return null;
    }
  }

  getExplicitlyExcludedProductNames(rule) {
    let allInclusionRuleProducts = [];
    if(rule.inclusionRule?.length>0) {
      const allInclusionRuleProducts = rule.inclusionRule.flatMap(innerRule => innerRule.includedProducts);
      return rule.excludedProducts.filter(productName=>!allInclusionRuleProducts.includes(productName));
    }
    return rule.excludedProducts;
  }

  getExplicitlyExcludedCategoryNames(rule) {
    let allInclusionRuleCategories = [];
    if(rule.inclusionRule?.length>0) {
      const allInclusionRuleCategories = rule.inclusionRule.flatMap(innerRule => innerRule.includedProductCategories);
      return rule.excludedProductCategories.filter(categoryName=>!allInclusionRuleCategories.includes(categoryName));
    }
    return rule.excludedProductCategories;
  }

  getGroupName(id) {
    return this.applicableCustomerGroupList?.find(cs=>cs.id==id)?.name;
  }

  getDateInFormatForDisplay(dateInp: number): string | null {
    if (!dateInp) return null; 
    let date = new Date(dateInp);
    const year = date.getFullYear();
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const month = monthNames[date.getMonth()];
    const day = date.getDate();
    return `${month} ${day}, ${year}`;
  }

}
