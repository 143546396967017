<div class="budget-container">
    <div class="row g-0 justify-content-start">
        <div class="col-md-6">
            <div class="label-container">
                <mat-checkbox color="primary" [(ngModel)]="hasRedemptionCount" (change)="onHasRedemptionCountChange()"></mat-checkbox>
                <div class="label-style">Redemption Count</div>
            </div>
            <div class="ob-name-inp-container" id="coinNameInput">
                <input class="ob-name-inp" type="number" placeholder="Enter Duration" (keydown)="numberInputHandler($event,8,'number')" [(ngModel)]="redemptionCount" [disabled]="!hasRedemptionCount" [matTooltip]="!hasRedemptionCount?'Please check the box first':''"
                                        #inp9 maxlength="8" min="0" max="99999999">
                <div class="inp-text-left">{{inp9.value.length}}/{{inp9.getAttribute('maxlength')}}</div>
            </div>
            <small class="error-text" *ngIf="showError && hasRedemptionCount && !redemptionCount">Please enter a valid value</small>
        </div>
        
        
        <div class="col-md-6">
            <div class="label-container">
                <mat-checkbox color="primary" [(ngModel)]="hasBudgetLimit" (change)="onHasBudgetLimitChange()"></mat-checkbox>
                <div class="label-style">Budget Limit</div>
            </div>
            <div class="ob-name-inp-container">
                {{currencySymbol}}
                <input class="ob-name-inp" type="number" placeholder="Amount" (keydown)="numberInputHandler($event,15,'float')" [(ngModel)]="budgetLimit"  #inp5 maxlength="15" min="0" max="999999999999999" [disabled]="!hasBudgetLimit" [matTooltip]="!hasBudgetLimit?'Please check the box first':''">
                <div class="inp-text-left">{{inp5.value.length}}/{{inp5.getAttribute('maxlength')}}</div>
            </div>
            <small class="error-text" *ngIf="showError && hasBudgetLimit && !budgetLimit">Please enter a valid value</small>
        </div>
    </div>

    <div class="row g-0">
        <div class="label-style">Reset</div>
        <mat-select [(ngModel)]="resetFrequency" class="ob-name-inp-container width-300">
            <mat-option *ngFor="let freq of resetFrequencyPossibilities" [value]="freq.value">
              {{ freq.display }}
            </mat-option>
        </mat-select>
    </div>
    
</div>




