<div
  id="parent-div"
  class="main"
  *ngIf="validWidgets"
  data-widget-name="CAMPAIGN LISTING"
  (scroll)="onScroll($event)"
>
  <div class="main-div" *ngIf="mainTopic !== 'campaignkpi'">
    <div class="d-j-c">
      <app-breadcrumb></app-breadcrumb>
      <button
        *ngIf="(validWidgets | widgetConfig: 11)"
        class="primary-button create-button button-hover-effect"
        (click)="createCampaign()"
        style="border: none; outline: none"
        data-button-name="Create New Campaign">
        Create New Journey
      </button>
    </div>
    <div class="revenueInfo">
      <div class="revenueDiv">
        <div class="rectangle r">
          <div class="activeCampaigns">{{'CAMPAIGN_PAGE.ACTIVE_CAMPAIGNS' | i18nTranslate}}</div>
          <div class="numberOfCampaigns">{{ activeCampaigns }}</div>
          <div class="absolute-icon-container-2 bg-2-1">
            <span class="material-icons icons-primary absolute-icon-2">
                flag
            </span>
          </div>
        </div>
        <div class="rectangle revenueRectangle">
          <div class="activeCampaigns">{{'CAMPAIGN_PAGE.REVENUE_ACTIVE_CAMP' | i18nTranslate}}</div>
          <div class="numberOfCampaigns number">
            {{ activeCampaignsRevenue | customCurrency: revCurrency: true}}
          </div>
          <div class="absolute-icon-container-2 bg-3-1">
            <span class="material-symbols-outlined">
              archive
            </span>
          </div>
        </div>
        <div class="rectangle revenueRectangle r2">
          <div class="activeCampaigns">{{'CAMPAIGN_PAGE.TOTAL_REV' | i18nTranslate}}</div>
          <div class="numberOfCampaigns total">
            {{ totalRevenue | customCurrency: revCurrency: true }}
          </div>
          <div class="absolute-icon-container-2 bg-1-1">
            <span class="material-symbols-outlined absolute-icon-2">
              payments
            </span>
          </div>
        </div>
      </div>
      <!-- <div
        *ngIf="validWidgets | widgetConfig: 11"
        class="button"
        widget-id="15"
        event-id="11"
        type="button"
        object-type="custom"
      >
        <div>
          <div
            *ngIf="appSubscriptionPlan"
            class="create-campaign-button primary-button"
            (click)="createCampaign()"
            data-button-name="Create New Campaign"
          >
            <span class="material-icons button-icon">add</span>
            <span>Create New Strategy</span>
          </div>
        </div>
      </div> -->
    </div>
    <div class="button-div main-white-panel">
      <div class="buttons" *ngIf="(validWidgets | widgetConfig: 10) && !loading">
        <div class="campaignlistRectangle">
          <div class="campaignlistsearchBar">
            <input
              #search
              type="text"
              placeholder="Search by Journey Name"
              (input)="onKeySearch($event,search.value)"
            />
            <span class="material-icons icons-primary" style="font-size: 19px;" *ngIf="search.value.length==0"> search </span>
            <span class="material-icons-outlined icons-primary cursor-pointer" style="font-size: 19px;"  *ngIf="search.value.length>0" (click)="search.value='';onKeySearch(search.value)">
              close
            </span>
          </div>
        </div>
        <div
            class="filterButton button-hover-effect"
            (click)="openFiltersDiv()"
            data-button-name="Filter"
            style="display: flex" style="cursor: pointer" [matTooltipShowDelay]="200" [matTooltip]="'COMMON_PAGE.FILTERS' | i18nTranslate">
            <svg width="22" height="25" viewBox="0 0 22 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M10.75 10C16.1348 10 20.5 7.98528 20.5 5.5C20.5 3.01472 16.1348 1 10.75 1C5.36522 1 1 3.01472 1 5.5C1 7.98528 5.36522 10 10.75 10Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M1 5.5C1.00253 10.0155 4.10614 13.938 8.5 14.979V21.25C8.5 22.4926 9.50736 23.5 10.75 23.5C11.9926 23.5 13 22.4926 13 21.25V14.979C17.3939 13.938 20.4975 10.0155 20.5 5.5" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </div>
        <div class="allFilters" *ngIf="selectedCampaignListPageWise[index].length > 0">
          <div
            class="filterButton button-hover-effect"
            (click)="deleteSelectedCampaign()"
            data-button-name="Filter"
          >
            <div style="display: flex">
              <span
                class="material-icons-outlined icons-primary"
                style="cursor: pointer;color: var(--main-font-black);"
                [matTooltipShowDelay]="200"
                [matTooltip]="'COMMON_PAGE.DELETE' | i18nTranslate"
              >
                delete
              </span>
            </div>
          </div>
        </div>
        <div class="active-filters" *ngIf="hasFilter()">
          <div *ngIf="filters['star'].length>0" class="filters-container">
            Favourite filter
            <div *ngIf="filters['star'].indexOf('STARRED') != -1" class="each-filter">
              <div>
                {{'CAMPAIGN_PAGE.STARRED' | i18nTranslate}}
              </div>
              <div class="material-icons icons-primary filter-clear" (click)="removeFilter('STARRED')">clear</div>
            </div>
            <div *ngIf="filters['star'].indexOf('UNSTARRED') != -1" class="each-filter">
              <div>
                {{'CAMPAIGN_PAGE.UNSTARRED' | i18nTranslate}}
              </div>
              <div class="material-icons icons-primary filter-clear" (click)="removeFilter('UNSTARRED')">clear</div>
            </div>
          </div>
          <div *ngIf="filters['status'].length>0" class="filters-container">
            Status filter
            <div *ngIf="filters['status'].indexOf('ACTIVE') != -1" class="each-filter">
              <div>
                {{'CAMPAIGN_PAGE.ACTIVE' | i18nTranslate}}
              </div>
              <div class="material-icons icons-primary filter-clear" (click)="removeFilter('ACTIVE')">clear</div>
            </div>
            <div *ngIf="filters['status'].indexOf('DRAFT') != -1" class="each-filter">
              <div>
                {{'CAMPAIGN_PAGE.DRAFT' | i18nTranslate}}
              </div>
              <div class="material-icons icons-primary filter-clear" (click)="removeFilter('DRAFT')">clear</div>
            </div>
            <div *ngIf="filters['status'].indexOf('SCHEDULED') != -1" class="each-filter">
              <div>
                {{'CAMPAIGN_PAGE.SCHEDULED' | i18nTranslate}}
              </div>
              <div class="material-icons icons-primary filter-clear" (click)="removeFilter('SCHEDULED')">clear</div>
            </div>
            <div *ngIf="filters['status'].indexOf('ENDED') != -1" class="each-filter">
              <div>
                {{'CAMPAIGN_PAGE.ENDED' | i18nTranslate}}
              </div>
              <div class="material-icons icons-primary filter-clear" (click)="removeFilter('ENDED')">clear</div>
            </div>
          </div>
          <div *ngIf="filters['segments']?.length>0" class="filters-container">
            Segment filter
            <div *ngFor="let segmentId of filters['segments']" class="each-filter">
              <div class="overflowable-text" [matTooltip]="customerSegmentAndTiersMap[segmentId]" [matTooltipShowDelay]="200">
                {{customerSegmentAndTiersMap[segmentId]}}
              </div>
              <div class="material-icons icons-primary filter-clear" (click)="removeFilter('segments',segmentId)">clear</div>
            </div>
          </div>
          <div *ngIf="filters['tiers']?.length>0" class="filters-container">
            Tiers filter
            <div *ngFor="let tierId of filters['tiers']" class="each-filter">
              <div class="overflowable-text" [matTooltip]="customerSegmentAndTiersMap[tierId]" [matTooltipShowDelay]="200">
                {{customerSegmentAndTiersMap[tierId]}}
              </div>
              <div class="material-icons icons-primary filter-clear" (click)="removeFilter('tiers',tierId)">clear</div>
            </div>
          </div>
          <div *ngIf="filters['customerLists']?.length>0" class="filters-container">
            Custom List filter
            <div *ngFor="let customerListId of filters['customerLists']" class="each-filter">
              <div class="overflowable-text" [matTooltip]="customerSegmentAndTiersMap[customerListId]" [matTooltipShowDelay]="200">
                {{customerSegmentAndTiersMap[customerListId]}}
              </div>
              <div class="material-icons icons-primary filter-clear" (click)="removeFilter('customerLists',customerListId)">clear</div>
            </div>
          </div>
        </div>
      </div>
      <div class="table-main" *ngIf="validWidgets | widgetConfig: 10">  
        <div class="table-header">
          <div class="header-items-top">
            <mat-checkbox class="example-margin"
            *ngIf="(searchArray !== null || length > 0) && !tableLoading"
              [checked]="(selectedCampaignListPageWise[index].length === getCampaignListLength(searchArray)) && getCampaignListLength(searchArray) > 0"
              [indeterminate]="selectedCampaignListPageWise[index].length > 0 && selectedCampaignListPageWise[index].length !== getCampaignListLength(searchArray)"
              (change)="checkCampaignList($event.checked, 'all', null)"
              color="primary">
          </mat-checkbox>
            <span 
              >{{'CAMPAIGN_PAGE.CAMP_NAME' | i18nTranslate}}</span
            >
          </div>
          <span class="header-items-top">{{'CAMPAIGN_PAGE.SEG_TIERS' | i18nTranslate}}</span>
          <span class="header-items-top narrow-flex"
            >{{'CAMPAIGN_PAGE.REVENUE' | i18nTranslate}}
            <!-- <div
              style="
                display: flex;
                flex-direction: column;
                cursor: pointer;
              "
              (click)="reOrder(); sort(); selected = 'Revenue'"
              [attr.data-button-name]="
                'Sort Revenue:' +
                (selectedSortBy === 'Asc' ? 'DESC' : 'ASC')
              "
            >
              <mat-icon
                class="campaignlistarrows icons-primary"
                [ngClass]="{
                  active:
                    selectedSortBy === 'Asc' && selected === 'Revenue'
                }"
                >arrow_drop_up</mat-icon
              >
              <mat-icon
                class="campaignlistarrows icons-primary"
                [ngClass]="{
                  active:
                    selectedSortBy === 'Desc' && selected === 'Revenue'
                }"
                >arrow_drop_down</mat-icon
              >
            </div> -->
            <div>
              <span class="material-symbols-outlined sort-icon-style transform-1" (click)="sortByRevenue('DESCENDING')" 
                [ngClass]="{'active':selectedSortBy == 'DESCENDING' && selected == 'revenue'}" matTooltip="Sort by Revenue in Descending order">
                sort
              </span>
              <span class="material-symbols-outlined sort-icon-style transform-2" (click)="sortByRevenue('ASCENDING')" 
                [ngClass]="{'active':selectedSortBy == 'ASCENDING' && selected == 'revenue'}" matTooltip="Sort by Revenue in Ascending order">
                sort
              </span>
            </div>
            </span
          >
          <span class="header-items-top narrow-flex">{{'CAMPAIGN_PAGE.STATUS' | i18nTranslate}}</span>
          <span class="header-items-top narrow-flex"
            >{{'CAMPAIGN_PAGE.LAST_MOD' | i18nTranslate}}
            <!-- <div
              style="
                display: flex;
                flex-direction: column;
                cursor: pointer;
              "
              (click)="reOrder(); sort(); selected = 'Last Modified'"
              [attr.data-button-name]="
                'Sort Last Modified:' +
                (selectedSortBy === 'Asc' ? 'DESC' : 'ASC')
              "
            >
              <mat-icon
                class="campaignlistarrows icons-primary"
                [ngClass]="{
                  active:
                    selectedSortBy === 'Asc' &&
                    selected === 'Last Modified'
                }"
                >arrow_drop_up</mat-icon
              >
              <mat-icon
                class="campaignlistarrows icons-primary"
                [ngClass]="{
                  active:
                    selectedSortBy === 'Desc' &&
                    selected === 'Last Modified'
                }"
                >arrow_drop_down</mat-icon
              >
            </div> -->
          </span>
          <span class="header-items-top super-narrow-flex">
            Actions
          </span>
        </div>
        <div style="color: #222222; overflow: auto;">
          <div *ngIf="tableLoading">
            <div>
              <div>
                <div
                  style="
                    display: flex;
                    justify-content: center;
                    padding: 30px 0px;
                  "
                >
                  <app-qubriux-loading  [size]="65" [loadingText]="'Fetching your Journeys...'"></app-qubriux-loading>
                </div>
              </div>
            </div>
          </div>
          <div
            *ngIf="searchArray == null || length <= 0"
            class="empty-customers-div"
          >
            <div>{{'CAMPAIGN_PAGE.NO_DATA_AVAILABLE' | i18nTranslate}}</div>
          </div>
          <div *ngFor="let item of searchArray | keyvalue: valueAscOrder;let i = index" class="notfirst">
              <div class="header-items">
                <div class="qb-d-flex qb-ai-center qb-mb-4">
                  <mat-checkbox class="example-margin"
                    [checked]="checkIfPresent(item.value.strategy.id)"
                    (change)="checkCampaignList($event.checked, 'single', item.value.strategy.id)"
                    color="primary">
                  </mat-checkbox>
                </div>
                <div class="qb-d-flex qb-ai-center">
                  <span
                    (click)="addToFavourite(item, true)"
                    *ngIf="!item.value.strategy.favorite"
                    class="material-icons icons-primary"
                    style="margin-top: -3px; cursor: pointer"
                    data-button-name="Confirm:Add to Favourite"
                    [attr.data-item-name]="
                      '(' +
                      item?.value?.strategy?.id +
                      ')' +
                      item?.value?.strategy?.strategyName
                    "
                    >star_border</span
                  >
                  <span
                    *ngIf="item.value.strategy.favorite"
                    (click)="addToFavourite(item, false)"
                    class="material-icons favStar icons-primary"
                    data-button-name="Cancel:Add to Favourite"
                    [attr.data-item-name]="
                      '(' +
                      item?.value?.strategy?.id +
                      ')' +
                      item?.value?.strategy?.strategyName
                    "
                  >
                    star
                  </span>
                </div>
                <div
                  *ngIf="item.value.strategy === undefined"
                  class="header-items qb-d-flex qb-ai-center"
                >
                  <span
                    class="material-icons icons-primary"
                    style="cursor: pointer"
                    >star_border</span
                  >
                </div>
                <div class="qb-camp-name-wrapper">
                  <div class="strategy-name">
                    <span [matTooltip]="item?.value?.strategy?.strategyName"
                    matTooltipClass="ex-tooltip-red1"
                    matTooltipShowDelay="500" (click)="editStrategy(item, getCreatedByData(item?.value?.strategy?.created_by, 'qid'), getCreatedByData(item?.value?.strategy?.created_by, 'storeName'))" class="cursor-pointer">{{ item?.value?.strategy?.strategyName || '----' }}</span>
                  </div>
                  <div style="font-size: 10px;" class="mini-list-item" [matTooltip]="
                  'Created by ' +  (getCreatedByData(item?.value?.strategy?.created_by, 'storeName')
                  ? getCreatedByData(item?.value?.strategy?.created_by, 'storeName') + '\n' + (getCreatedByData(item?.value?.strategy?.created_by, 'userEmail') || '') 
                  : getCreatedByData(item?.value?.strategy?.created_by, 'storeName') || '')
                  "
                  matTooltipClass="custom-tooltip"
                  >
                    <span class="material-symbols-outlined">person_edit</span>
                    {{getCreatedByData(item?.value?.strategy?.created_by, 'storeName')}}
                  </div>
                </div>
              </div>
              <div style="position: relative;display: block;" class="header-items">
                  <div *ngIf="isLeads[item.key]" class="mini-list-item-3">
                    All Leads
                  </div>
                  <div *ngIf="isCustomer[item.key];else segmentList" class="mini-list-item-3">
                    My Customers
                  </div>
                  <ng-template #segmentList>
                    <div class="mini-list-item-3">{{campaignSegments[item.key][0]}}</div>
                    <div *ngIf="campaignSegments[item.key]?.length>1" [matMenuTriggerFor]="menu" class="see-more" (click)="$event.stopPropagation()">See All {{campaignSegments[item.key].length}}<span class="material-symbols-outlined">keyboard_double_arrow_right</span></div>
                    <mat-menu #menu="matMenu"  class="custom-menu">
                      <div *ngFor="let name of campaignSegments[item.key]" class="mini-list-item-3">
                        {{name}}
                      </div>
                    </mat-menu>
                  </ng-template>
                  <div style="display: flex">
                    <span
                      class="material-icons-outlined icons-primary"
                      style="font-size: 15px; margin-top: 4px"
                    >
                      person
                    </span>
                    <div
                      style="
                        vertical-align: super;
                        font-size: 10px;
                        margin-top: 5px;
                      "
                    >
                      {{ item?.value?.customerCount }}
                    </div>
                  </div>
              </div>
        
              <div class="header-items narrow-flex">
                <span *ngIf="item.value.analytics !== undefined"> {{ item?.value?.analytics?.totalRevenue | customCurrency: revCurrency: true }} </span>
                <span *ngIf="item.value.analytics === undefined">0</span>
              </div>

              <div class="header-items narrow-flex">
                <div>
                  <div style="display: flex">
                    <div>
                      <div
                        *ngIf="item.value.strategy.status == 'ACTIVE'"
                        class="material-icons icons-primary"
                        style="color: #97cb64; font-size: 16px"
                      >
                        flag
                      </div>

                      <div
                        *ngIf="item.value.strategy.status == 'DRAFT'"
                        class="material-icons icons-primary"
                        style="color: var(--main-font-grey2); font-size: 16px"
                      >
                        flag
                      </div>

                      <div
                        *ngIf="item.value.strategy.status == 'SCHEDULED'"
                        class="material-icons icons-primary"
                        style="color: #f7941d; font-size: 16px"
                      >
                        flag
                      </div>

                      <div
                        *ngIf="item.value.strategy.status == 'ENDED'"
                        class="material-icons icons-primary"
                        style="color: var(--main-font-red); font-size: 16px"
                      >
                        flag
                      </div>
                    </div>
                    <div
                      class="campaign-status"
                      [ngClass]="item.value.strategy.status"
                    >
                      {{ item?.value?.strategy?.status | i18nDynamicTranslate : 'POS_PAGE'}}
                    </div>
                  </div>
                  <mat-slide-toggle
                    *ngIf="
                      item.value.strategy.status == 'ACTIVE' ||
                      item.value.strategy.status == 'SCHEDULED'
                    "
                    (change)="toggleStrategyStatus($event, item, getCreatedByData(item?.value?.strategy?.created_by, 'qid'), getCreatedByData(item?.value?.strategy?.created_by, 'storeName'))"
                    [checked]="
                      item.value.strategy.status == 'ACTIVE' ||
                      item.value.strategy.status == 'SCHEDULED'
                    "
                    widget-id="15"
                    event-id="12"
                    object-type="custom"
                    [attr.data-item-name]="
                      item.value.strategy.status == 'ACTIVE' ||
                      item.value.strategy.status == 'SCHEDULED'
                        ? 'strategy-list-deactivate'
                        : 'strategy-list-activate'
                    "
                  >
                  </mat-slide-toggle>
                </div>
              </div>
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                "
                class="header-items narrow-flex"
              >
                {{ item?.value?.strategy?.date | date: getDateFormat("dd MMM yyyy") }}<br />{{
                  item?.value?.strategy?.time | customTime
                }}

              </div>

              <div class="header-items super-narrow-flex">

                <span *ngIf="appSubscriptionPlan && !canEdit(getCreatedByData(item?.value?.strategy?.created_by, 'qid'))" [matTooltip]="'View'" class="material-symbols-outlined view-icon" (click)="updateStrategy(item.key);editStrategy(item, getCreatedByData(item?.value?.strategy?.created_by, 'qid'), getCreatedByData(item?.value?.strategy?.created_by, 'storeName'))">
                  visibility
                </span>

                <svg *ngIf="appSubscriptionPlan && canEdit(getCreatedByData(item?.value?.strategy?.created_by, 'qid'))" [matTooltip]="'CAMPAIGN_PAGE.EDIT' | i18nTranslate" class="cursor-pointer"
                  (click)="updateStrategy(item.key);editStrategy(item, getCreatedByData(item?.value?.strategy?.created_by, 'qid'), getCreatedByData(item?.value?.strategy?.created_by, 'storeName'))"
                 width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.5625 10.7812L13.5625 9.78125C13.6458 9.69792 13.7396 9.67708 13.8438 9.71875C13.9479 9.76042 14 9.84375 14 9.96875V14.5C14 14.9167 13.8542 15.2708 13.5625 15.5625C13.2708 15.8542 12.9167 16 12.5 16H1.5C1.08333 16 0.729167 15.8542 0.4375 15.5625C0.145833 15.2708 0 14.9167 0 14.5V3.5C0 3.08333 0.145833 2.72917 0.4375 2.4375C0.729167 2.14583 1.08333 2 1.5 2H10.0625C10.1667 2 10.2396 2.05208 10.2812 2.15625C10.3229 2.26042 10.3021 2.35417 10.2188 2.4375L9.21875 3.4375C9.17708 3.47917 9.125 3.5 9.0625 3.5H1.5V14.5H12.5V10.9375C12.5 10.875 12.5208 10.8229 12.5625 10.7812ZM17.4688 4.46875L9.25 12.6875L6.4375 13C6.02083 13.0417 5.66667 12.9167 5.375 12.625C5.08333 12.3333 4.95833 11.9792 5 11.5625L5.3125 8.75L13.5312 0.53125C13.8854 0.177083 14.3125 0 14.8125 0C15.3125 0 15.75 0.177083 16.125 0.53125L17.4688 1.875C17.8229 2.22917 18 2.66667 18 3.1875C18 3.6875 17.8229 4.11458 17.4688 4.46875ZM14.375 5.4375L12.5625 3.625L6.75 9.4375L6.53125 11.4688L8.5625 11.25L14.375 5.4375ZM16.4062 2.9375L15.0625 1.59375C14.8958 1.44792 14.7396 1.44792 14.5938 1.59375L13.625 2.5625L15.4375 4.375L16.4062 3.40625C16.5521 3.26042 16.5521 3.10417 16.4062 2.9375Z" fill="#4379EF"/>
                </svg>
                  
                <svg *ngIf="appSubscriptionPlan" [matTooltip]="'CAMPAIGN_PAGE.CLONE' | i18nTranslate" class="cursor-pointer"
                  (click)="updateStrategy(item.key);cloneStrategy(item)"
                 width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14.5 0C14.9167 0 15.2708 0.145833 15.5625 0.4375C15.8542 0.729167 16 1.08333 16 1.5V11.5C16 11.9167 15.8542 12.2708 15.5625 12.5625C15.2708 12.8542 14.9167 13 14.5 13H13V14.5C13 14.9167 12.8542 15.2708 12.5625 15.5625C12.2708 15.8542 11.9167 16 11.5 16H1.5C1.08333 16 0.729167 15.8542 0.4375 15.5625C0.145833 15.2708 0 14.9167 0 14.5V4.5C0 4.08333 0.145833 3.72917 0.4375 3.4375C0.729167 3.14583 1.08333 3 1.5 3H3V1.5C3 1.08333 3.14583 0.729167 3.4375 0.4375C3.72917 0.145833 4.08333 0 4.5 0H14.5ZM11.3125 14.5C11.4375 14.5 11.5 14.4375 11.5 14.3125V13H4.5C4.08333 13 3.72917 12.8542 3.4375 12.5625C3.14583 12.2708 3 11.9167 3 11.5V4.5H1.6875C1.5625 4.5 1.5 4.5625 1.5 4.6875V14.3125C1.5 14.4375 1.5625 14.5 1.6875 14.5H11.3125ZM14.3125 11.5C14.4375 11.5 14.5 11.4375 14.5 11.3125V1.6875C14.5 1.5625 14.4375 1.5 14.3125 1.5H4.6875C4.5625 1.5 4.5 1.5625 4.5 1.6875V11.3125C4.5 11.4375 4.5625 11.5 4.6875 11.5H14.3125Z" fill="#4379EF"/>
                </svg>

                <svg *ngIf="item.value.strategy.type !== 'STANDARD' && appSubscriptionPlan" [matTooltip]="'CAMPAIGN_PAGE.DELETE' | i18nTranslate" class="cursor-pointer"
                  (click)="updateStrategy(item.key);deleteStrategy(item?.value?.strategy?.id, getCreatedByData(item?.value?.strategy?.created_by, 'qid'), getCreatedByData(item?.value?.strategy?.created_by, 'storeName'))" 
                  width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13.5 2.5C13.6458 2.5 13.7604 2.55208 13.8438 2.65625C13.9479 2.73958 14 2.85417 14 3V3.5C14 3.64583 13.9479 3.77083 13.8438 3.875C13.7604 3.95833 13.6458 4 13.5 4H13L12.3438 14.5938C12.3229 14.9896 12.1667 15.3229 11.875 15.5938C11.5833 15.8646 11.2396 16 10.8438 16H3.15625C2.76042 16 2.41667 15.8646 2.125 15.5938C1.83333 15.3229 1.67708 14.9896 1.65625 14.5938L1 4H0.5C0.354167 4 0.229167 3.95833 0.125 3.875C0.0416667 3.77083 0 3.64583 0 3.5V3C0 2.85417 0.0416667 2.73958 0.125 2.65625C0.229167 2.55208 0.354167 2.5 0.5 2.5H3.0625L4.125 0.71875C4.41667 0.239583 4.85417 0 5.4375 0H8.5625C9.14583 0 9.58333 0.239583 9.875 0.71875L10.9375 2.5H13.5ZM5.4375 1.5L4.8125 2.5H9.1875L8.5625 1.5H5.4375ZM10.8438 14.5L11.5 4H2.5L3.15625 14.5H10.8438Z" fill="#4379EF"/>
                </svg>
                  
              </div>
          </div>
        </div>
      </div>
      <div class="paginate-div">
        <mat-paginator
          style="background: #fff"
          [pageIndex]="index - 1"
          [length]="length"
          [pageSize]="size"
          [pageSizeOptions]="[5, 10, 25, 100]"
          (page)="refreshPaginateddata($event)"
        >
        </mat-paginator>
      </div>
    </div>
  </div>
  <div *ngIf="mainTopic === 'campaignkpi'" class="qb-main-container">
    <app-campaign-kpi></app-campaign-kpi>
  </div>
</div>
<div *ngIf="showFiltersDiv">
  <div class="overlay"></div>
  <div class="filter-actions">
    <div class="titleDiv">
      <div
        style="display: flex; justify-content: space-between;align-items: center;"
      >
        <div class="title">{{'CAMPAIGN_PAGE.FILTERS_NOR' | i18nTranslate}}</div>
        <div
          *ngIf="showClearAll"
          class="clear-all-button button-hover-effect"
          (click)="clear()"
        >
          {{'CAMPAIGN_PAGE.CLEAR_ALL_CAPS' | i18nTranslate}}
        </div>
      </div>
      <div (click)="closeFiltersDiv()"
        class="material-icons icons-primary close-style"
      >
        clear
      </div>
    </div>
    <div class="filter-container-journey">
      <div class="filter">
        <div>
          <p><strong style="font-size: 13px">{{'CAMPAIGN_PAGE.FAV_CAPS' | i18nTranslate}}</strong></p>
          <!-- <p class="inline-blocker">
            <mat-checkbox
              [checked]="filters['star'].indexOf('ALL') != -1"
              value="ALL"
              (change)="addFilter('ALL')"
            ></mat-checkbox
            ><span class="option">{{'CAMPAIGN_PAGE.ALL' | i18nTranslate}}</span>
          </p> -->
          <p class="inline-blocker">
            <mat-checkbox
              [checked]="filters['star'].indexOf('STARRED') != -1"
              value="STARRED"
              (change)="addFilter('STARRED')"
            ></mat-checkbox
            ><span class="option">{{'CAMPAIGN_PAGE.STARRED' | i18nTranslate}}</span>
          </p>
          <p class="inline-blocker">
            <mat-checkbox
              [checked]="filters['star'].indexOf('UNSTARRED') != -1"
              value="UNSTARRED"
              (change)="addFilter('UNSTARRED')"
            ></mat-checkbox
            ><span class="option">{{'CAMPAIGN_PAGE.UNSTARRED' | i18nTranslate}}</span>
          </p>
        </div>
      </div>
      <div class="filter">
        <div>
          <p><strong style="font-size: 13px">{{'CAMPAIGN_PAGE.STATUS_CAPS' | i18nTranslate}}</strong></p>
          <!-- <p>
            <mat-checkbox
              [checked]="filters['status'].indexOf('ALL') != -1"
              value="ALL"
              (change)="addStatusFilter('ALL')"
            ></mat-checkbox
            ><span class="option">{{'CAMPAIGN_PAGE.ALL' | i18nTranslate}}</span>
          </p> -->
          <p class="inline-blocker">
            <mat-checkbox
              [checked]="filters['status'].indexOf('ACTIVE') != -1"
              value="ACTIVE"
              (change)="addStatusFilter('ACTIVE')"
            ></mat-checkbox
            ><span class="option">{{'CAMPAIGN_PAGE.ACTIVE' | i18nTranslate}}</span>
          </p>
          <p class="inline-blocker">
            <mat-checkbox
              [checked]="filters['status'].indexOf('DRAFT') != -1"
              value="DRAFT"
              (change)="addStatusFilter('DRAFT')"
            ></mat-checkbox
            ><span class="option">{{'CAMPAIGN_PAGE.DRAFT' | i18nTranslate}}</span>
          </p>
          <p class="inline-blocker">
            <mat-checkbox
              [checked]="filters['status'].indexOf('SCHEDULED') != -1"
              value="SCHEDULED"
              (change)="addStatusFilter('SCHEDULED')"
            ></mat-checkbox
            ><span class="option">{{'CAMPAIGN_PAGE.SCHEDULED' | i18nTranslate}}</span>
          </p>
          <p class="inline-blocker">
            <mat-checkbox
              [checked]="filters['status'].indexOf('ENDED') != -1"
              value="ENDED"
              (change)="addStatusFilter('ENDED')"
            ></mat-checkbox
            ><span class="option">{{'CAMPAIGN_PAGE.ENDED' | i18nTranslate}}</span>
          </p>
        </div>
      </div>
      <div class="filter">
        <div>
          <p><strong style="font-size: 13px">{{'CAMPAIGN_PAGE.SEGMENTS_CAPS' | i18nTranslate}}</strong></p>
          <div *ngIf="loading" style="display: flex; justify-content: center">
            <mat-spinner diameter="30"></mat-spinner>
          </div>
          <div *ngIf="!loading">
            <!-- <p>
              <mat-checkbox
                [checked]="filters['segments'].indexOf('ALL') != -1"
                name="ALL"
                (change)="addSegmentFilter('ALL')"
              ></mat-checkbox
              ><span class="option">{{'CAMPAIGN_PAGE.ALL' | i18nTranslate}}</span>
            </p> -->
            <div class="campaignlistRectangle2" *ngIf="this.segments.length>4">
              <div class="campaignlistsearchBar">
                <input
                  #search1
                  type="text"
                  placeholder="Search by Segment Name"
                  [(ngModel)]="segmentFilterSearch"
                />
                <span class="material-icons icons-primary" style="font-size: 19px;" *ngIf="search1.value.length==0"> search </span>
                <span class="material-icons-outlined icons-primary cursor-pointer" style="font-size: 19px;"  *ngIf="search1.value.length>0" (click)="segmentFilterSearch='';">
                  close
                </span>
              </div>
            </div>
            <div class="height-150">
              <p *ngFor="let item of getFilteredSegments()" style="word-break: break-all">
                <mat-checkbox
                  [checked]="filters['segments'].indexOf(item.id) != -1"
                  name="{{ item.segmentName }}"
                  (change)="addSegmentFilter(item.id,'segments')"
                  [disabled]="!item['isActive']"
                  [matTooltip]="
                    item['isActive']
                      ? ''
                      : 'We are preparing your segment. It will be available for use shortly'
                  "
                  >{{ item.segmentName }}</mat-checkbox
                >
              </p>
              <p *ngIf="getFilteredSegments().length == 0" class="no-data-div">
                {{'CAMPAIGN_PAGE.NO_DATA_AVAILABLE' | i18nTranslate}}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="filter" *ngIf="tiers.length>0">
        <div>
          <p><strong style="font-size: 13px">LOYALTY TIERS</strong></p>
          <div *ngIf="loading" style="display: flex; justify-content: center">
            <mat-spinner diameter="30"></mat-spinner>
          </div>
          <div *ngIf="!loading">
            <!-- <p>
              <mat-checkbox
                [checked]="filters['segments'].indexOf('ALL') != -1"
                name="ALL"
                (change)="addSegmentFilter('ALL')"
              ></mat-checkbox
              ><span class="option">{{'CAMPAIGN_PAGE.ALL' | i18nTranslate}}</span>
            </p> -->
            <div class="campaignlistRectangle2" *ngIf="this.tiers.length>4">
              <div class="campaignlistsearchBar">
                <input
                  #search2
                  type="text"
                  placeholder="Search by Tier Name"
                  [(ngModel)]="tierFilterSearch"
                />
                <span class="material-icons icons-primary" style="font-size: 19px;" *ngIf="search2.value.length==0"> search </span>
                <span class="material-icons-outlined icons-primary cursor-pointer" style="font-size: 19px;"  *ngIf="search2.value.length>0" (click)="tierFilterSearch='';">
                  close
                </span>
              </div>
            </div>
            <div class="height-150">
              <p *ngFor="let item of getFilteredTiers()" style="word-break: break-all">
                <mat-checkbox
                  [checked]="filters['tiers'].indexOf(item.id) != -1"
                  name="{{ item.tierName }}"
                  (change)="addSegmentFilter(item.id,'tiers')"
                  [disabled]="!item['isActive']"
                  >{{ item.tierName }}</mat-checkbox
                >
              </p>
              <p *ngIf="getFilteredTiers().length == 0" class="no-data-div">
                {{'CAMPAIGN_PAGE.NO_DATA_AVAILABLE' | i18nTranslate}}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="filter" *ngIf="customerLists.length>0">
        <div>
          <p><strong style="font-size: 13px">CUSTOM LISTS</strong></p>
          <div *ngIf="loading" style="display: flex; justify-content: center">
            <mat-spinner diameter="30"></mat-spinner>
          </div>
          <div *ngIf="!loading">
            <!-- <p>
              <mat-checkbox
                [checked]="filters['segments'].indexOf('ALL') != -1"
                name="ALL"
                (change)="addSegmentFilter('ALL')"
              ></mat-checkbox
              ><span class="option">{{'CAMPAIGN_PAGE.ALL' | i18nTranslate}}</span>
            </p> -->
            <div class="campaignlistRectangle2" *ngIf="this.customerLists.length>4">
              <div class="campaignlistsearchBar">
                <input
                  #search3
                  type="text"
                  placeholder="Search by Custom List Name"
                  [(ngModel)]="customerListFilterSearch"
                />
                <span class="material-icons icons-primary" style="font-size: 19px;" *ngIf="search3.value.length==0"> search </span>
                <span class="material-icons-outlined icons-primary cursor-pointer" style="font-size: 19px;"  *ngIf="search3.value.length>0" (click)="customerListFilterSearch='';">
                  close
                </span>
              </div>
            </div>
            <div class="height-150">
              <p *ngFor="let item of getFilteredCustomerList()" style="word-break: break-all">
                <mat-checkbox
                  [checked]="filters['customerLists'].indexOf(item.id) != -1"
                  name="{{ item.customerListName }}"
                  (change)="addSegmentFilter(item.id,'customerLists')"
                  [disabled]="!item['isActive']"
                  >{{ item.customerListName }}</mat-checkbox
                >
              </p>
              <p *ngIf="getFilteredCustomerList().length == 0" class="no-data-div">
                {{'CAMPAIGN_PAGE.NO_DATA_AVAILABLE' | i18nTranslate}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-button-container">
        <button class="secondary-button-border" (click)="closeFiltersDiv()">
          {{'CAMPAIGN_PAGE.CANCEL' | i18nTranslate}}
        </button>
        <button class="apply primary-button" (click)="applyFilter()">
          {{'CAMPAIGN_PAGE.APPLY' | i18nTranslate}}
        </button>
    </div>
  </div>
  <div class="paginate-div">
    <mat-paginator
      style="background: #fff"
      [pageIndex]="index - 1"
      [length]="length"
      [pageSize]="size"
      [pageSizeOptions]="[5, 10, 25, 100]"
      (page)="refreshPaginateddata($event)"
    >
    </mat-paginator>
  </div>
</div>
