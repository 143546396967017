<div class="review-card">




  <div class="review-header">
    <h3 style="font-weight: 600; font-size: 18px;">{{ surveyData.reviewerName }}'s Review</h3>
    <div class="rating">
      <mat-icon *ngFor="let star of starsArray">star</mat-icon>
    </div>
  </div>
  
  <div class="review-box">
    <div class="review-text" *ngIf="surveyData.reviewText !== 'NaN'; else noReviewText"> 
      {{ surveyData.reviewText }}
    </div>
    
    <ng-template #noReviewText>
      <div class="review-text">
        No review provided.
      </div>
    </ng-template>

    <div class="review-footer">
      <div class="footer-item left">
        <label>Submitted:</label> {{ formatDate(surveyData.dateSubmitted) }}
      </div>
    </div>
  </div>

  <div class="review-ids" *ngIf="surveyData.reviewPlatform == 'App Review'">
    <div class="order-details" *ngIf="surveyData.orderId" >
      <span class="label">Order ID : </span>
      <span class="value">{{ surveyData.orderId }}</span>
    </div>
    <div class="customer-details" *ngIf="surveyData.customerId">
      <span class="label">Customer ID : </span>
      <span class="value">{{ surveyData.customerId }}</span>
    </div>
  </div>

  <div class="sentiment">
    <label>Sentiment: </label>
    <span class="sentiment-icon">{{ getSentimentEmoji(surveyData.sentiment) }}</span>
  </div>

  <div class="key-phrases" *ngIf="surveyData.keyPhrase?.length > 0">
    <label style="font-weight: 700;">Key Phrases:</label>
    <div class="key-phrases-tags">
      <span class="tag" *ngFor="let phrase of surveyData.keyPhrase">{{ phrase }}</span>
    </div>
  </div>

  <div class="key-phrases" *ngIf="surveyData.keywords">
    <label style="font-weight: 700;">Keywords:</label>
    <div class="key-phrases-tags">
      <span class="tag" *ngFor="let keyword of surveyData.keywords.split(',')">{{ keyword.trim() }}</span>
    </div>
  </div>

  <div class="view-link" *ngIf="surveyData.reviewPlatform == 'google'">
    <a [href]="surveyData.reviewLink" target="_blank" class="primary-button btn-primary">View on Google</a>
  </div>

</div>
