<div>
  <div style="display: flex; background-color: #F8F8F8; padding: 20px; justify-content: space-between; align-items: center;">
    <div>
      <span class="material-icons-outlined" style="cursor: pointer;" (click)="back()">
        arrow_back_ios
        </span>
    </div>
    <div style="font-size: 16px; display: flex; justify-content: center; font-weight: 600;">{{channel}} {{'STATIC_PAGE.PREVIEW' | i18nTranslate}}</div>
    <div *ngIf="channel === 'SMS'" class="primary-button" (click)="openSendTestSMSDialog()" matTooltip="Send Test SMS">
         Send Test SMS
    </div>
  </div>
  <div style="display: flex; justify-content: space-between; padding: 20px;">
    <div style="font-size: 14px;">{{layoutName}}</div>
    <div *ngIf="channel === 'Email'" style="color: #0183FB; font-size: 14px;" (click)="openSendTestEmailDialog()">{{'STATIC_PAGE.SEND_TEST_EMAIL' | i18nTranslate}}</div>

    <!-- FOR WHATSAPP V2 -->
    <div *ngIf="status === 'null' && channel === 'WhatsApp'" class="cursor-pointer" (click)="editTemplate()">
      <span class="material-icons-outlined">edit</span>
    </div>
    <div  *ngIf="(status?.toUpperCase() === 'SUBMITTED' || status?.toUpperCase() === 'PENDING') && channel === 'WhatsApp'" class="cursor-pointer">
      <span class="material-icons-outlined icons-primary qb-icon"
        [matTooltipShowDelay]="500"
        matTooltip="Template submitted, waiting for approval.">
        update
      </span>
    </div>
    <div style="color: #60CA18;" *ngIf="status?.toUpperCase() === 'APPROVED' && channel === 'WhatsApp'" class="cursor-pointer">
      <span class="material-icons-outlined qb-icon"
        [matTooltipShowDelay]="500"
        matTooltip="Template Approved">
        check_circle
      </span>
    </div>
    <div style="color: #D22928;" *ngIf="status?.toUpperCase() === 'REJECTED' && channel === 'WhatsApp'" class="cursor-pointer">
      <span class="material-icons-outlined qb-icon"
        [matTooltipShowDelay]="500"
        matTooltip="Template Declined">
        cancel
      </span>
    </div>
  </div>
</div>
<app-email-template-preview *ngIf="channel === 'Email'"
        [selectedLayout]="selectedLayout"
      ></app-email-template-preview>
      <div style="width: 100%; display: flex; justify-content: center; height: 100%" *ngIf="channel === 'SMS' || 'WhatsApp'">
        <div [ngClass]="channel == 'WhatsApp' ? 'phone-container-whatsapp' : 'phone-container-sms'">
          <div [ngClass]="channel == 'WhatsApp' ? 'phone-background-whatsapp' : 'phone-background-sms'"></div>
          <div class="phone-image-preview">
            <div *ngIf="channel == 'WhatsApp'" class="phone-background-whatsapp-contact-name-preview">
              <div class="qb-d-flex ">
                <span style="color: black; font-size: 20px;">{{shopName | limitTo: 10}} </span>
                <img src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/whatsapp/verified_logo.svg" style="margin-top: 7px; margin-left: 5px;" width="20px" height="20px">
              </div>
            </div>
            <div class="text-container-div">
              <div [ngClass]="channel == 'WhatsApp' ? 'preview-text-content-whatsapp' : 'preview-text-content-sms'">
                <!-- <span style="white-space: pre-line; word-break: break-word">{{
                  selectedLayout
                }}</span> -->

                <div *ngIf="header === 'image'">
                  <img
                  *ngIf="headerData"
                  [src]="headerData"
                  alt=""
                  style="
                    height: auto;
                    width: 100%;
                    object-fit: contain;
                  "
                />
                </div>
                <div *ngIf="header === 'document'">
                  <embed [src]="headerData" type="application/pdf"  style="
                height: 125px;
                width: 280px;
                object-fit: contain;
              ">
                </div>
                <div *ngIf="header === 'text'">
                  <span style="font-size: 18px; font-weight: 600; white-space: pre-line;
                  word-break: break-word;">{{headerData}}</span>
                </div>
                <div *ngIf="header === 'video'">
                  <div *ngIf="headerData" style="display: flex; gap: 16px; flex-wrap: wrap; padding: 10px 0px;">
                    <div class="img_wrp">
                      <video
                    alt=""
                    style="
                      height: 125px;
                      width: 280px;
                      object-fit: contain;
                    ">
                    <source [src]="headerData" />  
                  </video>
                  <mat-icon class="qb-play-btn"> play_circle_filled</mat-icon>
                    </div>
                  </div>
                </div>
                <span style="white-space: pre-line; word-break: break-word">{{
                  selectedLayout
                }}</span>
                <div *ngIf="footer !== 'null'" style="color: #636466; font-size: 14px;">{{footer}}</div>
                <div class="preview-text-content-after"></div>
              </div>

              <div class="qb-d-flex" *ngIf="channel == 'WhatsApp'">
                <div class="qb-button-text-whatsapp-preview-mobile" *ngIf="buttonText !== ''">
                  {{buttonText}}
                </div>
              </div>
              
             
            </div>
          </div>
        </div>
      </div>
      