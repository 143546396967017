import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Event, NavigationEnd, Router } from "@angular/router";
import e from "express";
import { TokenStorageService } from "src/app/core/auth/token-storage.service";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { SubscriptionPlanHandlerService } from "src/app/core/services/subscription-plan-handler.service";
import { WidgetConfigService } from "src/app/core/services/widget-config.service";

@Component({
  selector: "app-customer-list-view",
  templateUrl: "./customer-list-view.component.html",
  styleUrls: ["./customer-list-view.component.css"],
})
export class CustomerListViewComponent implements OnInit {
  constructor(
    private _WidgetConfigService: WidgetConfigService,
    private _SubscriptionPlanHandlerService: SubscriptionPlanHandlerService,
    private apiCall: ApiCallService,
    private route: ActivatedRoute,
    private router: Router,
    private tokenStorage: TokenStorageService,
  ) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url.split(";")[0];
      }
    });
  }

  validWidgets = null;
  appSubscriptionPlan;
  mainLoading = true;
  customerList = [];
  filter = {
    label: "",
  };
  selectedCustomerId;
  currentUrl = "";
  listType = "";
  qId;
  customerListWithCreatedByStoreId;
  customerListWithoutMatchingCreatedBy

  ngOnInit(): void {
    this.qId = this.tokenStorage.getqId()=='null'?null:this.tokenStorage.getqId();
    this.getWidgets();
    this.listType = this.getListType();
    if(this.listType === 'customerList')
      this.getCustomerList();
    else
      this.getEmployeeList();
  }

  createCustomerList() {
    switch(this.listType){
      case "customerList":
        this.router.navigate(["/app/customer-list/create-customer-list"]);
        break;
      case "employeeList":
        this.router.navigate(["/app/customer-list/employee/create-employee-list"]);
        break;
    }
  }

  viewCustomerList(id) {
    this.router.navigate(["/app/customer-list", { id: id }]);
    this.selectedCustomerId = id;
  }

  viewEmployeeList(id) {
    this.router.navigate(["/app/customer-list/employee", { id: id }]);
    this.selectedCustomerId = id;
  }

  getCustomerList() {
    this.getCustomerListApi().then((result) => {
      this.mainLoading = true;
      const startTime = performance.now();
      if (result) {
        if(result["body"]){
          const customerData = result["body"]["data"];
          if (this.listType ==='employeeList') {
            this.customerList = customerData;
          } else if(this.listType ==='customerList'){
            const customerListWithCreatedByStoreId = customerData.filter(
              (item) => item.createdBy && item.createdBy.storeId === this.qId
            );
            const customerListWithoutMatchingCreatedBy = customerData.filter(
              (item) => !item.createdBy || item.createdBy.storeId !== this.qId
            );
            this.customerListWithCreatedByStoreId = customerListWithCreatedByStoreId;
            this.customerListWithoutMatchingCreatedBy = customerListWithoutMatchingCreatedBy;
            this.customerList = [...customerListWithCreatedByStoreId,...customerListWithoutMatchingCreatedBy];
          }
        }
        let param = this.route.params["_value"]["id"];
        if (param) {
          this.selectedCustomerId = param;
          this.scrollToLocation(param);
        } else {
          if (this.listType ==='employeeList'){
            this.selectedCustomerId = this.customerList.length > 0 ? this.customerList[0]["id"]  : null;
          }
          else if(this.listType ==='customerList'){
            if(this.customerListWithCreatedByStoreId.length>0){
              this.selectedCustomerId = this.customerListWithCreatedByStoreId[0]["id"];
            }
            else if(this.customerListWithoutMatchingCreatedBy.length>0){
              this.selectedCustomerId = this.customerListWithoutMatchingCreatedBy[0]["id"]
            }
          }
          
        }
        const apiDuration = performance.now() - startTime;
        if (apiDuration <= 1500) {
          setTimeout(() => this.mainLoading = false, 1500 - apiDuration);
        } else {
          this.mainLoading = false;
        }
      }
    });
  }

  getEmployeeList() {
    this.getEmployeeListApi().then((result) => {
      this.mainLoading = true;
      const startTime = performance.now();
      if (result) {
        if(result["body"]?.data){
          this.customerList = result["body"]["data"];
        }
        let param = this.route.params["_value"]["id"];
        if (param) {
          this.selectedCustomerId = param;
          this.scrollToLocation(param);
        } else {
          this.selectedCustomerId = this.customerList.length > 0 ? this.customerList[0]["id"] : null;
        }
        const apiDuration = performance.now() - startTime;
        if (apiDuration <= 1500) {
          setTimeout(() => this.mainLoading = false, 1500 - apiDuration);
        } else {
          this.mainLoading = false;
        }
      }
    });
  }

  async getWidgets() {
    this.validWidgets = await this._WidgetConfigService.getWidget();
    this.appSubscriptionPlan =
      await this._WidgetConfigService.getAppSubscriptionPlan();
  }

  scrollToLocation(data) {
    let interval = setInterval(() => {
      let element = document.getElementById(data);
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        clearInterval(interval);
      }
    }, 10);
  }

  getListType(): string {
    this.currentUrl = this.currentUrl.split(";")[0];
    let currentUrlPath = this.currentUrl.split(";")[0];
    if (currentUrlPath.includes("app/customer-list/employee")) {
      return "employeeList";
    } else {
      return "customerList";
    }
  }

  getListName() {
    return this.listType === 'employeeList' ? "Employee List" : "Custom List";
  }

  getCustomerListApi(): Promise<object> {
    return new Promise((resolve, reject) => {
      this.apiCall.getCustomerLists().subscribe(
        (res) => {
          console.log(res);
          resolve(res);
        },
        (err) => {
          console.log(err.error);
          reject(err.error);
        }
      );
    });
  }

  getEmployeeListApi(): Promise<object>{
    return new Promise((resolve, reject) => {
      this.apiCall.getEmployeeLists().subscribe(
        (res) => {
          console.log(res);
          resolve(res);
        },
        (err) => {
          console.log(err.error);
          reject(err.error);
        }
      );
    });
  }

  customerDataFromChild(event) {
    if (event.type === "delete") {
      this.filter.label = "";
      if (this.listType ==='employeeList'){
        const index = this.customerList.findIndex((x) => x.id === event.id);
        this.customerList.splice(index, 1);
      }
      else if(this.listType ==='customerList'){
          let index = this.customerListWithCreatedByStoreId.findIndex((x) => x.id === event.id);
          if (index !== -1) {
          this.customerListWithCreatedByStoreId.splice(index, 1);
          }
          else {
          index = this.customerListWithoutMatchingCreatedBy.findIndex((x) => x.id === event.id);
          if (index !== -1) {
          this.customerListWithoutMatchingCreatedBy.splice(index, 1);
          }
          }
      }
      
      if(this.listType === 'customerList'){
        this.router.navigate([
          "/app/customer-list",
          { id: this.customerList[0]["id"] },
        ]);
      }
      else{
        this.router.navigate([
          "/app/customer-list/employee",
          { id: this.customerList[0]["id"] },
        ]);
      }
      this.selectedCustomerId = this.customerList[0]["id"];
    } else {
      if(this.listType === 'customerList'){
        this.router.navigate([
          "/app/customer-list",
          { id: this.customerList[0]["id"] },
        ]);
      }
      else{
        this.router.navigate([
          "/app/customer-list/employee",
          { id: this.customerList[0]["id"] },
        ]);
      }
      this.selectedCustomerId = event.id;
    }
  }
}
