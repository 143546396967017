import { Component, ElementRef, OnInit, Renderer2 } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { TokenStorageService } from "src/app/core/auth/token-storage.service";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { SnackbarCollection } from "src/app/core/services/snackbar.service";
import { WidgetConfigService } from "src/app/core/services/widget-config.service";
import { AnalyticsCustomerListDialogBoxComponent } from "src/app/shared/components/dialog-box";

@Component({
  selector: "app-offer-analytics-view",
  templateUrl: "./offer-analytics-view.component.html",
  styleUrls: ["./offer-analytics-view.component.css"],
})
export class OfferAnalyticsViewComponent implements OnInit {
  private documentClickListener: () => void;
  constructor(
    private dialog: MatDialog,
    private apiCall: ApiCallService,
    private tokenStorageService: TokenStorageService,
    private snackBar: SnackbarCollection,
    private renderer: Renderer2,
    private elRef: ElementRef,
    private _WidgetConfigService: WidgetConfigService
  ) {}

  selectedId: number = -1;
  selectedModule = [];
  offerCustomerDetails = [];
  mainTopic = "";
  op2ListSearch = "";
  mainCategory = "all";
  categoryList = ["all", "active", "ended"];
  modules = [];
  noDataSubModule: boolean = false;
  dropdownListLoading: boolean = false;
  validWidgets = null;
  moduleAnalyticsData = {};
  nonClickableEvent = ["sent", "delivered"];
  allowedInSms = ["sent", "delivered", "failed"];
  channel = ["EMAIL", "SMS"];
  moduleData = [
    {
      displayName: "Email",
      type: "EMAIL",
    },
    {
      displayName: "SMS",
      type: "SMS",
    },
    {
      displayName: "Offer",
      type: "OFFER",
    },
    {
      displayName: "Survey",
      type: "SURVEY",
    },
  ];
  orderByRowNum = (a, b) => {
    return a;
  }; //keyValue is always displyed alphabetically so a fix to show it in orderbyrow

  ngOnInit(): void {
    document
      .getElementById("offer-parent-div")
      .addEventListener("click", (event) => this.hideHeaderEvents());
    this.getWidgets();
    this.getModulesData("offer");
    this.documentClickListener = this.renderer.listen(
      "document",
      "click",
      (event) => {
        const isInsideDropdown = this.elRef?.nativeElement
          .querySelector(".dropdown")
          .contains(event.target);
        if (!isInsideDropdown) {
          this.hideAllDropdowns(event);
        }
      }
    );
  }

  ngOnDestroy() {
    if (this.documentClickListener) {
      // Clean up the event listener when the component is destroyed
      this.documentClickListener();
    }
  }

  async getWidgets() {
    this.validWidgets = await this._WidgetConfigService.getWidget();
  }

  getModulesData(module) {
    this.dropdownListLoading = true;
    this.getModuleDataApi(module).then(
      (response) => {
        this.modules = response["body"];
        this.dropdownListLoading = false;
      },
      (reject) => {
        this.dropdownListLoading = false;
        this.snackBar.openSnackBar("Failed to fetch Journey List", 5000);
      }
    );
  }

  hideHeaderEvents() {
    let element = document.getElementById("segment-popup");
    if (element) {
      element.style.display = "none";
    }
  }

  hideAllDropdowns(event, exceptionIdForDropdown?, exceptionIdForDownArrow?) {
    const dropdownElements =
      this.elRef.nativeElement.querySelectorAll(".dropdown");
    const downArrowElements =
      this.elRef.nativeElement.querySelectorAll(".down-arrow");
    dropdownElements.forEach((element: HTMLElement) => {
      if (element.id != exceptionIdForDropdown) {
        this.renderer.setStyle(element, "height", "0px");
        this.renderer.setStyle(element, "border", "unset");
      }
    });
    downArrowElements.forEach((element: HTMLElement) => {
      if (element.id != exceptionIdForDownArrow) {
        this.renderer.setStyle(element, "transform", "rotate(0deg)");
      }
    });
  }

  getListType(type) {
    switch (type) {
      case "campaign":
        return "Select Journeys";
      case "offer":
        return "Select Offers";
      case "survey":
        return "Select Surveys";
    }
  }

  getDropDownType(type) {
    switch (type) {
      case "campaign":
        return "Selected Journey";
      case "offer":
        return "Selected Offer";
      case "survey":
        return "Selected Survey";
    }
  }

  getCategoryDisplayName(value) {
    switch (value) {
      case "all":
        return "All";
      case "active":
        return "Active";
      case "ended":
        return "Ended";
    }
  }

  ChannelCheck(channel, event): boolean {
    if (channel === "SMS") {
      return this.allowedInSms.indexOf(event) === -1 ? false : true;
    } else if (channel === "EMAIL") {
      return true;
    }
  }

  toggleDropdown(event, logExpIndex, compIndex, dropdownName) {
    event.stopPropagation();

    //check if click happened within category-container or within search
    if (
      event.target.parentElement.classList.contains("category-container") ||
      event.target.classList.contains("op-search")
    ) {
      return; //if so dont toggle
    }
    let exceptionIdForDropdown = `${dropdownName}-dropdown${logExpIndex}${compIndex}`;
    let exceptionIdForDownArrow = `down-arrow-${dropdownName}${logExpIndex}${compIndex}`;
    const dropdownElement = this.elRef.nativeElement.querySelector(
      `#${exceptionIdForDropdown}`
    );
    const keyboardDownIconElement = this.elRef.nativeElement.querySelector(
      `#${exceptionIdForDownArrow}`
    );
    this.hideAllDropdowns(
      event,
      exceptionIdForDropdown,
      exceptionIdForDownArrow
    );
    if (dropdownElement.style.height === "180px") {
      this.renderer.setStyle(dropdownElement, "height", "0px");
      this.renderer.setStyle(dropdownElement, "border", "unset");
      this.renderer.setStyle(
        keyboardDownIconElement,
        "transform",
        "rotate(0deg)"
      );
      this.op2ListSearch = "";
    } else {
      this.renderer.setStyle(dropdownElement, "z-index", "10");
      this.renderer.setStyle(dropdownElement, "height", "180px");
      this.renderer.setStyle(dropdownElement, "border", "1px solid #636466");
      this.renderer.setStyle(
        keyboardDownIconElement,
        "transform",
        "rotate(180deg)"
      );
    }
  }

  getNameFromEmailSenderList(id) {
    if (id == -1) {
      return this.getListType("offer");
    } else {
      let item = this.modules.find((item) => item.moduleId == id);
      if (item) {
        return item.moduleName;
      }
    }
  }

  selectJourneyCategory(value) {
    this.mainCategory = value;
  }

  selectJourney(result) {
    this.noDataSubModule = false;
    this.selectedId = result["moduleId"];
    this.selectedModule = result;
    this.getChannels(this.channel);
    // this.getSubModules();
    this.getParticularOfferDetails();
  }

  getParticularOfferDetails() {}

  searchFiltered(list): any[] {
    if (!this.op2ListSearch) {
      // If no search term is provided, return only items with status "ENDED"
      return this.mainCategory === "all"
        ? list
        : list.filter(
            (item) => item.status === this.mainCategory.toUpperCase()
          );
    }

    const searchTerm = this.op2ListSearch.trim().toLowerCase();

    return list.filter((item) => {
      // Check if the item matches the search term and has the desired status
      return this.mainCategory !== "all"
        ? item.moduleName.toLowerCase().includes(searchTerm) &&
            item.status === this.mainCategory.toUpperCase()
        : item.moduleName.toLowerCase().includes(searchTerm);
    });
  }

  getChannels(data) {
    this.moduleAnalyticsData = {};
    this.moduleAnalyticsData = data.reduce((acc, channelType) => {
      acc[channelType] = {};
      return acc;
    }, []);
    if (Object.keys(this.moduleAnalyticsData)[0]) {
      this.mainTopic = Object.keys(this.moduleAnalyticsData)[0];
      this.getModuleData();
      // this.showChannelNoData(false);
    } else {
      this.mainTopic = "";
      // this.showChannelNoData(true);
    }
  }

  getModuleData() {
    let data = {
      moduleType: "OFFER",
      merchantId: this.tokenStorageService.getMerchantId(),
      moduleId: this.selectedId,
      storeId: null,
      subModuleId: null,
      event: null,
      channel: this.mainTopic,
      url: null,
    };
    this.getRateOrCountInsightApi(data).then(
      (response) => {
        let res = response["body"]["eventWiseRateList"];
        this.setDataForModule(res, this.mainTopic);
      },
      (reject) => {
        this.noDataSubModule = true;
      }
    );
  }

  setDataForModule(data, channel) {
    for (let i in data) {
      if (
        this.ChannelCheck(channel.toUpperCase(), data[i].event.toLowerCase())
      ) {
        this.moduleAnalyticsData[channel] =
          this.moduleAnalyticsData[channel] || {};
        this.moduleAnalyticsData[channel][data[i].event] =
          this.moduleAnalyticsData[channel][data[i].event] || {};
        this.moduleAnalyticsData[channel][data[i].event]["value"] =
          data[i].count;
        // if (data[i].event.toLowerCase() === "sent") {
        // } else {
        //   this.moduleAnalyticsData[channel][data[i].event]["value"] =
        //     data[i].rate;
        // }
        this.moduleAnalyticsData[channel][data[i].event]["displayName"] =
          this.getDisplayNameBasedOnEvent(data[i].event, "count", channel);
      }
    }
    this.getOfferCountInsightApi(this.selectedId).then(
      (response) => {
        let res = response["body"]["totalRedeemedCount"];
        this.offerCustomerDetails = response["body"];
        this.moduleAnalyticsData[channel] =
          this.moduleAnalyticsData[channel] || {};
        this.moduleAnalyticsData[channel]["redeemed"] =
          this.moduleAnalyticsData[channel]["redeemed"] || {};
        this.moduleAnalyticsData[channel]["redeemed"]["value"] = res;
        this.moduleAnalyticsData[channel]["redeemed"]["displayName"] =
          this.getDisplayNameBasedOnEvent("redeemed", "count", null);
        this.moduleAnalyticsData[channel]["conversionRate"] =
          this.moduleAnalyticsData[channel]["conversionRate"] || {};
        this.moduleAnalyticsData[channel]["conversionRate"]["value"] =
          this.moduleAnalyticsData[channel]["Sent"]["value"] > 0
            ? (
                (this.moduleAnalyticsData[channel]["redeemed"]["value"] /
                  this.moduleAnalyticsData[channel]["Sent"]["value"]) *
                100
              ).toFixed(3)
            : 0;
        this.moduleAnalyticsData[channel]["conversionRate"]["displayName"] =
          this.getDisplayNameBasedOnEvent("conversionRate", "rate", null);
      },
      (reject) => {}
    );
  }

  subModucleChange(data) {
    if (this.mainTopic !== data) {
      this.mainTopic = data;
      this.getModuleData();
    } else {
      return;
    }
  }

  getDisplayNameBasedOnEvent(event, type, channel) {
    switch (event.toLowerCase()) {
      case "delivered":
        return type === "rate" ? "Delivery Rate" : "Delivered";
      case "opened":
        return type === "rate" ? "Open Rate" : "Opened";
      case "clicked":
        return type === "rate" ? "Clicked Rate" : "Clicked";
      case "sent":
        return channel.toLowerCase() + " Sent";
      case "redeemed":
        return "Offer Redeemed";
      case "conversionrate":
        return "Conversion Rate";
      default:
        return event.toLowerCase();
    }
  }

  getDisplayName(type: string): string | undefined {
    return this.moduleData.find((module) => module.type === type)?.displayName;
  }

  // showChannelNoData(hide) {
  //   let element = document.getElementById("no-moduleAnalytics-data");
  //   if (element && hide) {
  //     element.style.display = "block";
  //   } else {
  //     element.style.display = "none";
  //   }
  // }

  getModuleDataApi(moduleName): Promise<object> {
    return new Promise((resolve, reject) => {
      this.apiCall.getModulesForInsightData(moduleName).subscribe(
        (res) => {
          console.log(res);
          resolve(res);
        },
        (err) => {
          console.log(err.error);
          reject(err.error);
        }
      );
      // let res = {
      //   message: "SUCCESS",
      //   body: [
      //     {
      //       channelType: null,
      //       moduleId: "123",
      //       moduleName: "DoubleStar",
      //       status: "ACTIVE",
      //       channel:["SMS","EMAIL"]
      //     },
      //     {
      //       channelType: null,
      //       moduleId: "456",
      //       moduleName: "Test campaign",
      //       status: "ENDED",
      //       channel:["SMS","EMAIL"]
      //     },
      //   ],
      // };
      // resolve(res);
    });
  }

  getCustomerData(subData) {
    let payLoadData = {
      moduleType: "OFFER",
      merchantId: this.tokenStorageService.getMerchantId(),
      moduleId: this.selectedId,
      subModuleId: null,
      channel: this.mainTopic.toLowerCase(),
      event:
        subData.key.toLowerCase() === "spam"
          ? "complained"
          : subData.key.toLowerCase(),
      url: null,
    };
    const dialogRef = this.dialog.open(
      AnalyticsCustomerListDialogBoxComponent,
      {
        panelClass: "no-padding-dialog-popup",
        width: "780px",
        height: "auto",
        data: {
          header:
            subData.key.toLowerCase() === "clicked"
              ? "Click Performance"
              : "Customer Details",
          payLoadData: payLoadData,
          isClicked: subData.key.toLowerCase() === "clicked" ? true : false,
          customerData:
            subData.key.toLowerCase() === "redeemed"
              ? this.offerCustomerDetails
              : null,
        },
      }
    );
  }

  stringInputHandler(event: KeyboardEvent, maxLength) {
    const inputValue = (event.target as HTMLInputElement).value;
    if (inputValue.length >= maxLength && event.key !== "Backspace") {
      event.preventDefault();
    }
  }

  getRateOrCountInsightApi(data): Promise<object> {
    return new Promise((resolve, reject) => {
      this.apiCall.getRateOrCountInsights(data).subscribe(
        (res) => {
          console.log(res);
          resolve(res);
        },
        (err) => {
          console.log(err.error);
          reject(err.error);
        }
      );
      // let res = {
      //   message: "SUCCESS",
      //   body: {
      //     moduleName: "CAMPAIGN",
      //     moduleId: "1232",
      //     subModuleId: "4567",
      //     eventWiseRateList: [
      //       {
      //         event: "sent",
      //         count: 60,
      //       },
      //       {
      //         event: "delivered",
      //         rate: 98.5,
      //         count: 150,
      //       },
      //       {
      //         event: "opened",
      //         rate: 75.0,
      //         count: 120,
      //       },
      //       {
      //         event: "clicked",
      //         rate: 55.5,
      //         count: 60,
      //       },
      //     ],
      //   },
      // };
      // resolve(res);
    });
  }

  getOfferCountInsightApi(id): Promise<object> {
    return new Promise((resolve, reject) => {
      this.apiCall.getOfferRedeemedCount(id).subscribe(
        (res) => {
          console.log(res);
          resolve(res);
        },
        (err) => {
          console.log(err.error);
          reject(err.error);
        }
      );
      // let res = {
      //   message: "SUCCESS",
      //   body: {
      //     moduleName: "CAMPAIGN",
      //     moduleId: "1232",
      //     subModuleId: "4567",
      //     eventWiseRateList: [
      //       {
      //         event: "sent",
      //         count: 60,
      //       },
      //       {
      //         event: "delivered",
      //         rate: 98.5,
      //         count: 150,
      //       },
      //       {
      //         event: "opened",
      //         rate: 75.0,
      //         count: 120,
      //       },
      //       {
      //         event: "clicked",
      //         rate: 55.5,
      //         count: 60,
      //       },
      //     ],
      //   },
      // };
      // resolve(res);
    });
  }
}
