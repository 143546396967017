<div class="column-flex">
    <div class="loyalty-program-preview-panel-heading">
        <div class="material-icons-outlined loyalty-program-preview-panel-heading-icon"
            matTooltipPosition="right">
            hotel_class
        </div>
        <h2 class="loyalty-program-preview-panel-heading-text">{{'SEGMENT_PAGE.LOYALITY_PROGRAM_PREVIEW' | i18nTranslate}} </h2>
    </div>
    <div class="preview-details-container">
        <div class="preview-left-details">
            <div *ngIf="completedSteps>=1"  class="flex coin-preview">
                <img width="48px" height="48px" class="coin pointer-event-none" [src]="loyaltyPoints.logoPath" alt="pointsImg">
                <div class="margin-left-10">
                    <p class="coin-name-preview">{{loyaltyPoints.name}}</p>
                </div>
            </div>
            <div class="tier-preview-container"  *ngIf="completedSteps>=2">
                <div *ngFor="let tier of sortedTierMap"  class="flex tier-preview pointer-action-none">
                    <div class="flex margin-bottom-0">
                        <img *ngIf="tier.logoPath" width="60px" height="60px" class="coin pointer-event-none margin-top-12" [src]="tier.logoPath" alt="tierImg">
                        <div *ngIf="!tier.logoPath"class="no-tier-img">
                            <span class="material-symbols-outlined no-tier-icon">dialogs</span>
                        </div>
                        <div class="tier-details-container">
                            <p class="tier-name-preview">{{tier.name}}</p>
                            <p class="tier-description-preview overflow-scroll" title="{{tier.description}}">{{tier.description}}</p>
                        </div>
                    </div>
                    <div class="flex tier-coin-preview">
                        <img width="34px" height="34px" class="coin pointer-event-none" [src]="loyaltyPoints.logoPath" alt="pointsImg">
                        <div class="margin-left-10">
                            <p class="coin-value-preview">{{'SEGMENT_PAGE.CONVERSION' | i18nTranslate}}: <span class="black-text">{{tier.conversion.points}} {{loyaltyPoints.name}}<span *ngIf="loyaltyPoints.points>1">s</span> = {{currencySymbol}} {{tier.conversion.amount}} </span></p>
                        </div>
                    </div>
                    <div class="flex">
                         <p class="coin-value-preview">Tier Entry:
                            <span class="black-text" *ngIf="tier.entryPoints != null">{{tier.entryPoints}} {{loyaltyPoints.name}}</span>
                            <ng-container *ngIf="tier.entryPoints !=null && (tier.lifeTimeOrderValue != null || tier.lifeTimeOrderCount != null)">
                                <span class="coin-value-preview"> OR </span>
                            </ng-container>
                            <span class="black-text" *ngIf="tier.lifeTimeOrderValue != null">{{currencySymbol}} {{tier.lifeTimeOrderValue}} of Accumulated Order Value</span>
                            <ng-container *ngIf="tier.lifeTimeOrderValue != null && tier.lifeTimeOrderCount != null">
                                <span class="coin-value-preview"> OR </span>
                            </ng-container>
                            <span class="black-text" *ngIf="tier.lifeTimeOrderCount != null">{{tier.lifeTimeOrderCount}} of Life Time Order Count</span>
                        </p>
                    </div>
                    <div class="flex">
                        <p class="coin-value-preview">{{'CAMPAIGN_PAGE.COIN_REDEMPTION_LIMIT' | i18nTranslate}} : <span class="black-text">{{tier.coinRedemptionLimit.value}}
                            <span *ngIf="tier.coinRedemptionLimit.type == 'coins'">{{loyaltyPoints.name}}</span>
                            <span *ngIf="tier.coinRedemptionLimit.type == 'percentage'">% {{'CAMPAIGN_PAGE.OF_TOTAL_BILL_AMT' | i18nTranslate}}</span> </span>
                        </p>
                    </div>
                    <div class="flex" *ngIf="tier.loyaltyPointsExpiryConfig">
                        <p class="coin-value-preview">Points Expire after : <span class="black-text">
                            {{tier.loyaltyPointsExpiryConfig.duration}} {{getDisplay(tier.loyaltyPointsExpiryConfig.timeFrame)}}</span>
                        </p>
                    </div>
                    <div class="flex" *ngIf="tier.expiryConfigForPushNotification">
                        <p class="coin-value-preview">Notify Expiry before : <span class="black-text">
                            {{tier.expiryConfigForPushNotification.duration}} {{getDisplay(tier.expiryConfigForPushNotification.timeFrame)}}</span>
                        </p>
                    </div>
                </div>
            </div>
    
            <div class="preview-building-container" *ngIf="completedSteps<=1">
                <img src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/loyalty/completingTask.svg" alt="buildingImg" class="building-img">
                <p class="building-text">{{'SEGMENT_PAGE.COMPLETE_DETAILS_MESSAGE' | i18nTranslate}}<br>{{'SEGMENT_PAGE.YOUR_LOYALITY_PROGRAM' | i18nTranslate}}</p>
                <img src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/loyalty/curvedArrow.svg" alt="arrowImg" class="margin-bottom-50">
            </div>
            <p *ngIf="currentStep<=3" class="asterisk-text">* {{'SEGMENT_PAGE.PREVIEW_LOYALITY_PROGRAM' | i18nTranslate}}</p>
        </div>
        <div class="preview-right-details" *ngIf="currentStep>3">
            <div class="rule-title">
                <div class="material-symbols-outlined">list_alt</div>
                <h2>{{'SEGMENT_PAGE.RULES' | i18nTranslate}}</h2>
            </div>
            <div class="tier-preview-container">
                <div *ngFor="let rule of sorted(loyaltyRules);let ruleIndex = index">
                    <app-loyalty-rule-preview [rule]="rule" [loyaltyPoints]="loyaltyPoints" [loyaltyRules]="loyaltyRules" [offerList]="offerList" [applicableCustomerGroupList]="customerGroupings"></app-loyalty-rule-preview>
                </div>
            </div>
        </div>
    </div>
</div>

