import { Component, OnInit, Input } from '@angular/core';
import { ApiCallService } from 'src/app/core/services/api-call-service';
import { SnackbarCollection } from 'src/app/core/services/snackbar.service';
import { TranslateDynamicText } from 'src/app/shared/pipes/dynamic-translation.pipe';
import { getCurrencySymbol } from "@angular/common";

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.css']
})
export class PreviewComponent implements OnInit {

  constructor( private _apicall: ApiCallService, private _snackBar: SnackbarCollection, private _i18nDynamicTranslate: TranslateDynamicText) { }

  @Input() currentStep: number;
  @Input() completedSteps: number;
  @Input() loyaltyPoints:{};
  @Input() sortedTierMap:[];
  @Input() loyaltyRules:[];
  revCurrency;
  currencySymbol;

  ngOnInit(): void {
    this.revCurrency = sessionStorage.getItem("serviceCurrency");
    this.currencySymbol = getCurrencySymbol(this.revCurrency, "narrow");
    this.getOfferTemplates();
    this.getCustomerSegmentsAndTiers();
    if(this.loyaltyRules) {
      for(let rule of this.loyaltyRules as any) {
        if(rule.validFrom && Array.isArray(rule.validFrom)) {
          this.formDateText(rule,'from');
        }
        if(rule.validTill && Array.isArray(rule.validTill)) {
          this.formDateText(rule,'till');
        }

      }
    }

  }
  offerList = [];
  getOfferTemplates() {
    this._apicall.getAllOfferList().subscribe(
      (response) => {
        if (response["message"] === "success") {
          this.offerList = (response["body"]).offers;
        }
      },
      (err) => {
        this._snackBar.openSnackBar(err,3000);
      }
    );
  }

  customerGroupings = [];
  getCustomerSegmentsAndTiers() {
    this._apicall.getCustomerSegmentsAndTiers().subscribe(response => {
      let res = (response['body']);
      for (let i = 0; i < res.length; i++) {
        if(res[i].category.toLowerCase() != 'tier') {
          this.customerGroupings.push({
            id: res[i]["id"],
            name: res[i]["segmentName"],
            isActive: res[i]["isActive"],
            category: res[i]["category"].toLowerCase(),
            checked: false,
          });
        }
      }
    },
    error => {
      this._snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Error fetching getCustomerSegmentsAndTiers data", ['POS_PAGE']), 3000);
    });
  }

  


  formDateText(rule,setTofromOrTill) {
    let year,month,day;
    switch(setTofromOrTill) {
      case 'from':
        year = rule.validFrom[0];
        month = String(rule.validFrom[1]).padStart(2, '0');
        day = String(rule.validFrom[2]).padStart(2, '0');
        rule.validFrom = `${year}-${month}-${day}`;
        break;
      case 'till':
        year = rule.validTill[0];
        month = String(rule.validTill[1]).padStart(2, '0');
        day = String(rule.validTill[2]).padStart(2, '0');
        rule.validTill = `${year}-${month}-${day}`;
        break;
    }
  }

  getOfferNameFromId(id) {
    if(this.offerList.length > 1) {
      return (this.offerList.find(obj=>obj.id == id)).offerName;
    } else {
      return null;
    }
  }

  getExplicitlyExcludedProductNames(rule) {
    let allInclusionRuleProducts = [];
    if(rule.inclusionRule?.length>0) {
      const allInclusionRuleProducts = rule.inclusionRule.flatMap(innerRule => innerRule.includedProducts);
      return rule.excludedProducts.filter(productName=>!allInclusionRuleProducts.includes(productName));
    }
    return rule.excludedProducts;
  }

  getExplicitlyExcludedCategoryNames(rule) {
    let allInclusionRuleCategories = [];
    if(rule.inclusionRule?.length>0) {
      const allInclusionRuleCategories = rule.inclusionRule.flatMap(innerRule => innerRule.includedProductCategories);
      return rule.excludedProductCategories.filter(categoryName=>!allInclusionRuleCategories.includes(categoryName));
    }
    return rule.excludedProductCategories;
  }

  getName(id) {
    return this.customerGroupings.find(cs=>cs.id==id).name;
  }

  sorted(loyaltyRules: any[]): any[] {
    return loyaltyRules.sort((a, b) => {
      if (a.priorityId === null) return 1;
      if (b.priorityId === null) return -1;
      return a.priorityId - b.priorityId;
    });
  }

  getDisplay(timeFrameConstantString) {
    if(timeFrameConstantString == 'YEAR')
      return 'Year(s)';
    if(timeFrameConstantString == 'MONTH')
      return 'Month(s)';
    if(timeFrameConstantString == 'WEEK')
      return 'Week(s)';
  }
}
