import { trigger, transition, style, animate } from "@angular/animations";
import { Component, EventEmitter, HostListener, Inject, Input, OnInit, Output, SimpleChanges } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { SnackbarCollection } from "src/app/core/services/snackbar.service";
import { TranslateDynamicText } from "src/app/shared/pipes/dynamic-translation.pipe";

@Component({
  selector: "app-product-list-dialog",
  templateUrl: "./product-list-dialog.component.html",
  styleUrls: ["./product-list-dialog.component.css"],
  animations: [
    trigger("openClose", [
      transition(":enter", [
        // :enter is alias to 'void => *'
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 })),
      ]),
      transition(":leave", [
        // :leave is alias to '* => void'
        animate(100, style({ opacity: 0 })),
      ]),
    ]),
    trigger("appear", [
      transition(":enter", [
        // :enter is alias to 'void => *'
        style({ opacity: 0 }),
        animate(1000, style({ opacity: 1 })),
      ]),
      transition(":leave", [
        // :leave is alias to '* => void'
        animate(100, style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class ProductListDialogComponent implements OnInit {
  @Input() dataSelectorBean: any;
  @Input() dataBean: any;
  @Output() getDataBeanData = new EventEmitter<any>();
  apiProductList = [];
  previousData = "";
  databean;
  parentProductIdArray = [];
  parentProductIdAll = [];//Array of parent product gfId. Can have more than 1 entry.
  varaintProductIdArray = [];//Array of variant product gfId. Can have max 1 entry.
  selectedVariantObj = []; //Array of selected variant object
  loading = false;

  totalProductCount;
  pageNo = 1;
  pagesize = 1000;
  currentChar = "";
  previousChar = "";
  timeout;
  search = "";
  thresholdCrossed:boolean = false;
  singleSelectMode = false;
  constructor(
    private apiCall: ApiCallService,
    private snackBar: SnackbarCollection,
    public dialogRef: MatDialogRef<ProductListDialogComponent>,
    private _i18nDynamicTranslate: TranslateDynamicText,
    @Inject(MAT_DIALOG_DATA) public initialDatabean
  ) {}

  // @HostListener("scroll", ["$event"])
  // onScroll(event: any) {

  //   if (
  //     event.target.offsetHeight + event.target.scrollTop >=
  //     event.target.scrollHeight - 1
  //   ) {

  //     if (this.totalProductCount > this.apiProductList.length) {
  //       this.pageNo += 1;
  //       this.getProduct(this.currentChar, this.pageNo, this.pagesize); // get next page on scroll
  //     }
  //   }
  // }

  async ngOnInit() {
    this.databean = this.dataBean ? this.dataBean : JSON.parse(JSON.stringify(this.initialDatabean));
    console.log(this.databean)
if(this.databean.searchString!=null){
  this.search=this.databean.searchString;
}
    if (this.databean.selectedProductList?.length > 0) {
      await this.generateVariantArray(this.databean.selectedProductList); // generate variant array
      this.getProduct(this.search, this.pageNo, this.pagesize);
    } else {
      this.getProduct(this.search, this.pageNo, this.pagesize);
    }

    if(this.initialDatabean.pos == 'posist') {
      this.singleSelectMode = true;
    }
    if(this.initialDatabean?.isHiddenItemOffer){
      this.singleSelectMode = true;
    }
  }

  getInputField(data) { // get input field value using debounce
    console.log(data)
    clearTimeout(this.timeout);
    var $this = this;
    if (data && data.length >= 3) { 
      this.thresholdCrossed = true;
    }
    this.timeout = setTimeout(function () {    

        if (data && data.length >= 3) { // check if input field value is greater than 3 and greater than previous value
          this.apiProductList = [];
          $this.getProduct(data, 1, $this.pagesize, 'search');

        } else {
          if ($this.previousData.length >= 3) {
            this.apiProductList = [];
            $this.getProduct("", 1, $this.pagesize, 'search');
          }
        }
        $this.previousData = data || ""; // update previous data to current data after backend call.
      // }
    }, 600);


  }

  getProduct(char, pageno, pagesize, type?) { // get product list
    this.currentChar = char;
    this.loading = true;
    if(type === 'search'){
      this.apiProductList = [];
    }
    if(this.databean.selectedCategory === null){
      this.apiCall
        .getOfferManagementProductsList(char, pageno, pagesize)
        .subscribe((resultList) => {
          let data = (resultList["body"]);
          this.loading = false;
          this.totalProductCount = Object.keys(data)[0]; // get total product count
          // if (this.currentChar == this.previousChar) {// check if current char is same as previous char
          //   this.apiProductList = this.apiProductList.concat(
          //     data[Object.keys(data)[0]]
          //   );
          // } else {
            this.previousChar = this.currentChar;
            this.apiProductList = [...this.apiProductList, ...data[Object.keys(data)[0]]];
          // }
          if (this.totalProductCount > this.apiProductList.length && type !='search') {
            this.pageNo += 1;
            this.getProduct(this.currentChar, this.pageNo, this.pagesize); // get next page on scroll
          }
          console.log(this.apiProductList)
        }, (error) => {
          this.loading = false;
          this.snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Failed loading product list", ['SHARED_PAGE']),  2000);
         });
    }
    else{
      this.apiCall
        .getCategoryBasedProductList(char, pageno, pagesize, this.transformCollection(this.databean.selectedCategory))
        .subscribe((resultList) => {
          let data = (resultList["body"]);
          this.loading = false;
          this.totalProductCount = Object.keys(data)[0]; // get total product count
          // if (this.currentChar == this.previousChar) {// check if current char is same as previous char
          //   this.apiProductList = this.apiProductList.concat(
          //     data[Object.keys(data)[0]]
          //   );
          // } else {
            this.previousChar = this.currentChar;
            this.apiProductList = [...this.apiProductList, ...data[Object.keys(data)[0]]];
            if (this.totalProductCount > this.apiProductList.length && type !='search') {
              this.pageNo += 1;
              this.getProduct(this.currentChar, this.pageNo, this.pagesize); // get next page on scroll
            }
          // }
          console.log(this.apiProductList)
        }, (error) => {
          this.loading = false;
          this.snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Failed loading product list", ['SHARED_PAGE']),  2000);
         });
    }
  }

  transformCollection(collections) {
    return collections.map(collection => ({
      productCategoryName: collection.collectionName,
      productCategoryId: collection.collectionId,
    }));
  }

  allComplete: boolean = false;

  save() {
    var variantObject = [];
    variantObject = this.selectedVariantObj;
    let k = { variantIdArray: variantObject }; // send selected variant object to parent component
    if(this.dataBean){
      this.getDataBeanData.emit(k);
    }
    else{
      this.dialogRef.close(k);
    }
  }
  cancel() {
    this.dialogRef.close(null); // close dialog
  }

  parentArrowClicked(i) {
    document.getElementById("variant-section-" + i).classList.toggle("active"); // toggle variant section
  }

  generateVariantArray(obj) {
    this.selectedVariantObj = obj;
    var variantArray = [];
    var i = 0;
    for (i = 0; i < obj.length; i++) {
      //  variantArray.push(obj.variants[i].gfId);
      if(this.databean.actionType === null){
        this.varaintProductIdArray.push(obj[i].gfId); // push variant gfId to array
        this.parentProductIdAll.push(obj[i].parentProductGFId);
      }
      else{
        this.parentProductIdAll.push(obj[i].productId);
      }

    }

  }

  getDataForTable($event: any) {
    $event.target.classList.toggle("active");// toggle variant section
    // get data for table since we are opening the div to show the body
  }


  countInArray(what) {
    let array = this.parentProductIdAll;
    var count = 0;
    for (var i = 0; i < array.length; i++) { // loop through array
      if (array[i] === what) {
        count++;
      }
    }
    return count;
  }

  productSelected = false;
  updateSelection(variantGfId, parentGfId, event, variant) { // update selected variant

    //if checked, add the parentgfid to the parentProductIdArray and push the variant object to selectedVariantObj
    //varaintProductIdArray is used to check if the variant is selected or not.
    if (event) {
      this.parentProductIdAll.push(parentGfId);
      this.varaintProductIdArray.push(variantGfId);
      this.selectedVariantObj.push(variant);
      if(this.singleSelectMode) {
        this.productSelected = true;
      }
    } else {
      const index: number = this.parentProductIdAll.indexOf(parentGfId);
      if (index !== -1) {
        this.parentProductIdAll.splice(index, 1);
      }
      const index1: number = this.varaintProductIdArray.indexOf(variantGfId);
      if (index1 !== -1) {
        this.varaintProductIdArray.splice(index1, 1);
      }
      const index2: number = this.selectedVariantObj.indexOf(variant);
      if (index1 !== -1) {
        this.selectedVariantObj.splice(index1, 1);
      }
      if(this.singleSelectMode) {
        this.productSelected = false;
      }
    }
    console.log(this.parentProductIdAll, this.varaintProductIdArray)
  }

  setAllChecked(event, variantArray, product) {
    // if checked, add the parentgfid to the parentProductIdArray and push the variant object to selectedVariantObj
    var i = 0;
    let data = {};
    if(this.databean.actionType === null){
      for (i = 0; i < variantArray.length; i++) {
        if (
          1 == event &&
          this.varaintProductIdArray.indexOf(variantArray[i].gfId) === -1
        ) {
          this.updateSelection(
            variantArray[i].gfId,
            variantArray[i].parentProductGFId,
            1,
            variantArray[i]
          );
        } else if (
          0 == event &&
          this.varaintProductIdArray.indexOf(variantArray[i].gfId) != -1
        ) {
          this.updateSelection( // if unchecked, remove the parentgfid from the parentProductIdArray and remove the variant object from selectedVariantObj
            variantArray[i].gfId,
            variantArray[i].parentProductGFId,
            0,
            variantArray[i]
          );
        }
      }
    }
    else{
      if(event == 1){
        data['productId'] = product.productId;
        data['productName'] = product.productName;
        data['productCategoryId'] = null;
        data['productCategory'] = null;
        this.parentProductIdAll.push(product.productId);
        this.selectedVariantObj.push(data);
      }
      else{
        const index: number = this.parentProductIdAll.indexOf(product.productId);
        if(index != -1){
          this.parentProductIdAll.splice(index, 1);
          this.selectedVariantObj.splice(index, 1);
        }
      }
    }
   }
   selectAllCategoryAndProduct(event){
     var i = 0;
     console.log(this.apiProductList)
     for (i = 0; i < this.apiProductList.length; i++) {
       const index: number = this.parentProductIdAll.indexOf(this.apiProductList[i].productId);
       if(event === true) {
          if(this.databean.actionType === null &&
            this.varaintProductIdArray.indexOf(this.apiProductList[i]['variants'][0].gfId) === -1){
            this.updateSelection(
              this.apiProductList[i]['variants'][0].gfId,
              this.apiProductList[i]['variants'][0].parentProductGFId,
              1,
              this.apiProductList[i]['variants'][0]
            );
          }
          else if(this.databean.actionType !== null && index === -1){
            const data = {};
            data['productId'] = this.apiProductList[i].productId;
            data['productName'] = this.apiProductList[i].productName;
            data['productCategoryId'] = null;
            data['productCategory'] = null;
            this.parentProductIdAll.push(this.apiProductList[i].productId);
            this.selectedVariantObj.push(data);
          }
      }
      else if(event === false) {
          if(this.databean.actionType === null  &&
            this.varaintProductIdArray.indexOf(this.apiProductList[i]['variants'][0].gfId) !== -1){
            this.updateSelection(
              this.apiProductList[i]['variants'][0].gfId,
              this.apiProductList[i]['variants'][0].parentProductGFId,
              0,
              this.apiProductList[i]['variants'][0]
            );
          }
          else if(this.databean.actionType !== null && index != -1){
              this.parentProductIdAll.splice(index, 1);
              this.selectedVariantObj.splice(index, 1);
          }
        }

    }
   }

   ifProductChecked(product){
    if(this.databean.actionType === null)
      return ((product?.variants?.length)==(this.countInArray(product.gfId)));
    else{
      return this.parentProductIdAll.indexOf(product.gfId)!=-1
    }
   }

   ifProductPartialyChecked(product){
    if(this.databean.actionType === null)
      return ((product?.variants?.length)>(this.countInArray(product.gfId))&&(this.countInArray(product.gfId)!=0)) 
    else return false;
   }
}
