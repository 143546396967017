import {
  Component,
  OnInit,
  Input,
  ViewEncapsulation,
  ElementRef,
  Renderer2,
} from "@angular/core";

@Component({
  selector: "app-email-template-preview",
  templateUrl: "./email-template-preview.component.html",
  styleUrls: ["./email-template-preview.component.css"],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class EmailTemplatePreviewComponent implements OnInit {
  constructor(private renderer: Renderer2, private el: ElementRef) {}
  @Input() selectedLayout;

  ngOnInit() {
    this.selectedLayout = this.selectedLayout?.replace(
      /href="(?!http)([^"]*)"/g,
      'href="https://$1"'
    );
  }
}
