import { Component, HostListener, OnInit, Inject  } from "@angular/core";
import { MAT_DIALOG_DATA,MatDialog, MatDialogRef } from "@angular/material/dialog";
import { TokenStorageService } from "src/app/core/auth/token-storage.service";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { SnackbarCollection } from "src/app/core/services/snackbar.service";
import { TranslateDynamicText } from "src/app/shared/pipes/dynamic-translation.pipe";
import { ContactUsDialogComponent } from "../contact-us-dialog/contact-us-dialog.component";

@Component({
  selector: "app-upload-csv-dialog-box",
  templateUrl: "./upload-csv-dialog-box.component.html",
  styleUrls: ["./upload-csv-dialog-box.component.css"],
})
export class UploadCsvDialogBoxComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<UploadCsvDialogBoxComponent>,
    private _apiCall: ApiCallService,
    private tokenStorage: TokenStorageService,
    private snackBar: SnackbarCollection,
    private _i18nDynamicTranslate: TranslateDynamicText,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: { leads: boolean }
  ) {}

  isHovering: boolean;
  dataLoading: boolean = false;
  imag;
  dropDownStatus = false;
  csvHeader = [];
  csvSampleData = {};
  typeFilter = "";
  selectedData = [];
  fileSize = {
    size: "",
    bytes: "",
  };
  fileName = "";
  fileToUpload = null;
  numberOfRows = 0;
  readonly MAX_FILE_SIZE = 100 * 1024 * 1024;
  noteMessage: string = "";
  noteMessages: { [key: string]: string } = {};
  selectedOptions: { [key: string]: string } = {};
  currentOpenIndex: number = -1;
  tagName = "";
  tagList = [];
  submit = false;
  isAdmin = false;
  storeFilterList: any[] = [];
  filteredStoreList: any[] = [];
  
  selectedStoreId: string = '';

  typeOptions = [
    {
      name: "Do not Import",
      value: null,
    },
    {
      name: "Customer Name",
      value: "customerName",
    },
    {
      name: "Mobile Number",
      value: "mobileNumber",
    },
    {
      name: "Address",
      value: "address",
    },
    {
      name: "Email",
      value: "emailAddress",
    },
    {
      name: "Additional Details",
      value: "merchantCustomerAdditionalDetails",
    },
    {
      name: "Customer Created At",
      value: "customerCreatedAt",
    },
    {
      name: "Country Code",
      value: "countryCode",
    },
    {
      name: "Accepts Email Marketing",
      value: "acceptsEmailMarketing",
    },
    {
      name: "Accepts SMS Marketing",
      value: "acceptsSMSMarketing",
    },
    {
      name: "Merchant Marketing Email",
      value: "merchantMarketingEmail",
    },
    {
      name: "First Name",
      value: "firstName",
    },
    {
      name: "Last Name",
      value: "lastName",
    },
    {
      name: "Birthday",
      value: "birthday",
    },
    {
      name: "Anniversary",
      value: "anniversary",
    },
    {
      name: "Gender",
      value: "gender",
    },
  ];
  dropdownElementIndex;

  @HostListener("document:click", ["$event"])
  clickOutside(event) {
    if (this.currentOpenIndex !== -1) {
      let parent = document.getElementById(
        "multi-select-panel" + this.currentOpenIndex
      );
      let element = document.getElementById(
        "multi-select-dropdown" + this.currentOpenIndex
      );
      const clickedElement = event.target as HTMLElement;
      if (
        !parent.contains(clickedElement) &&
        !element.contains(clickedElement)
      ) {
        this.toggleSelect(
          event,
          "multi-select-dropdown",
          "segment-select",
          "multi-select-panel",
          "keyboard-down-icon-container",
          this.currentOpenIndex
        );
      }
    }
  }

  ngOnInit(): void {
    if(this.tokenStorage.getqId() == 'null'){
      this.isAdmin = true;
      this._apiCall.getListStore().subscribe(
        (response) => {
          if(response['body']) {
            this.storeFilterList = response['body'];
            this.filteredStoreList = this.storeFilterList.filter(store => store.qid !== null);
          }
        },
        (err) => {
          this.snackBar.openSnackBar("Error ", 2000);
        }
      );
    }
  }

  updateNoteMessage(rowItem: string, selectedOption: string) {
    switch (selectedOption) {
      case "acceptsEmailMarketing":
      case "acceptsSMSMarketing":
        this.noteMessages[rowItem] =
          "<b>Note:</b> accepted formats are :<br>true, yes, t, y <br> false, no, f, n";
        break;
      case "customerCreatedAt":
        this.noteMessages[rowItem] =
          '<b>Note:</b> accepted date format is "mm-dd-yyyy"';
        break;
        case "birthday":
        this.noteMessages[rowItem] =
          '<b>Note:</b> accepted date format is "mm-dd-yyyy"';
        break;
      default:
        this.noteMessages[rowItem] = "";
    }
  }

  onSelectionChange(rowItem: string, option: string) {
    this.selectedOptions[rowItem] = option;
    this.updateNoteMessage(rowItem, option);
  }

  onFileDropped(event, fileData) {
    this.fileBrowseHandler(event, fileData);
  }

  fileBrowseHandler(event, fileData) {
    console.log(event, fileData);
    const file: File = event.target.files[0];

    if (file.size > this.MAX_FILE_SIZE) {
      this.snackBar.openSnackBar(
        "The selected file is too large. Please select a file smaller than 100 MB.",
        2000
      );
      return;
    }
    if (file.type !== "text/csv") {
      this.snackBar.openSnackBar("Only CSV file type is supported", 2000);
      return;
    }
    this.fileSize.size = this.getFileSize(fileData[0].size);
    this.fileSize.bytes = fileData[0].size;
    this.fileName = fileData[0].name;
    this.fileToUpload = file;

    if (!file) return; // No file selected

    const reader = new FileReader();

    reader.onload = (e: any) => {
      const csvString: string = e.target.result;
      this.csvHeader = this.extractHeadersFromCSV(csvString);
      console.log(this.csvHeader);
      console.log(csvString);
      console.log(csvString.length);
      this.csvSampleData = this.extractDataFromCSV(csvString);
      this.numberOfRows = this.getNumberOfRows(csvString);
      console.log(this.numberOfRows);
      this.dataLoading = false;
    };
    this.dataLoading = true;
    reader.readAsText(file);
  }

  getFileSize(fileSizeInBytes) {
    if (fileSizeInBytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(fileSizeInBytes) / Math.log(k));
    return (
      parseFloat((fileSizeInBytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i]
    );
  }

  extractHeadersFromCSV(csvString: string): string[] {
    if (!csvString || csvString.trim().length === 0) {
      return [];
    }
    const lines = csvString.split("\n");
    const headers = lines[0].split(",").map((header) => header.trim());
    return headers;
  }

  getNumberOfRows(csvString: string) {
    if (!csvString || csvString.trim().length === 0) {
      return 0; 
  }
    const rows = csvString.split("\n").filter(row => row.trim() !== "");
    return rows.length;
  }

  extractDataFromCSV(csvString: string): any {
    const lines = csvString.split("\n");
    const data = {};

    for (let i = 1; i < lines.length; i++) {
      const values = lines[i].split(",");
      for (let j = 0; j < values.length; j++) {
        if (!data[j]) {
          data[j] = [];
        }
        const trimmedValue = values[j] ? values[j].trim() : "";
        if (trimmedValue !== "" && data[j].length < 5) {
          data[j].push(trimmedValue);
        }
      }
    }

    return data;
  }

  toggleSelect(
    event,
    elementId,
    segmentSelectId,
    segmentSelectPanelId,
    keyboardDownIconElementId,
    index
  ) {
    this.typeFilter = "";
    let element = document.getElementById(elementId + index);
    let segmentSelect = document.getElementById(segmentSelectId + index);
    let segmentSelectPanel = document.getElementById(
      segmentSelectPanelId + index
    );
    let keyboardDownIconElement = document.getElementById(
      keyboardDownIconElementId + index
    );

    if (this.currentOpenIndex === index) {
      this.currentOpenIndex = -1;
      element.style.height = "0px";
      segmentSelect.style.overflow = "hidden";
      segmentSelectPanel.style.boxShadow = "0px 2px 20px 0px #c6ced6;";
      keyboardDownIconElement.style.transform = "rotate(0deg)";
    } else {
      if (this.currentOpenIndex !== -1) {
        let prevElement = document.getElementById(
          elementId + this.currentOpenIndex
        );
        let prevSegmentSelect = document.getElementById(
          segmentSelectId + this.currentOpenIndex
        );
        let prevSegmentSelectPanel = document.getElementById(
          segmentSelectPanelId + this.currentOpenIndex
        );
        let prevKeyboardDownIconElement = document.getElementById(
          keyboardDownIconElementId + this.currentOpenIndex
        );

        prevElement.style.height = "0px";
        prevSegmentSelect.style.overflow = "hidden";
        prevSegmentSelectPanel.style.boxShadow = "0px 2px 20px 0px #c6ced6;";
        prevKeyboardDownIconElement.style.transform = "rotate(0deg)";
      }
      this.currentOpenIndex = index;
      element.style.height = "140px";
      segmentSelect.style.overflow = "auto";
      segmentSelectPanel.style.boxShadow = "0px 1px 6px 0px #25a7f9";
      keyboardDownIconElement.style.transform = "rotate(180deg)";
    }
  }

  addType(
    value,
    elementId,
    segmentSelectId,
    segmentSelectPanelId,
    keyboardDownIconElementId,
    index
  ) {
    this.typeFilter = "";
    let element = document.getElementById(elementId + index);
    let segmentSelect = document.getElementById(segmentSelectId + index);
    let segmentSelectPanel = document.getElementById(
      segmentSelectPanelId + index
    );
    let keyboardDownIconElement = document.getElementById(
      keyboardDownIconElementId + index
    );

    if (this.checkIfPresent(index) !== "") {
      delete this.selectedData[this.getType(index)];
      this.selectedData[value.name] = value;
      this.selectedData[value.name].index = index;
    } else {
      this.selectedData[value.name] = value;
      this.selectedData[value.name].index = index;
    }

    this.dropDownStatus = false;
    element.style.height = "0px";
    segmentSelect.style.overflow = "hidden";
    segmentSelectPanel.style.boxShadow = "unset";
    segmentSelectPanel.style.border = "1px solid #919191";
    keyboardDownIconElement.style.transform = "rotate(0deg)";
  }

  getType(type) {
    let isValuePresent = false;
    let data = "";

    for (const key in this.selectedData) {
      if (
        this.selectedData.hasOwnProperty(key) &&
        this.selectedData[key].index === type
      ) {
        isValuePresent = true;
        data = this.selectedData[key].name;
        break;
      }
    }

    if (isValuePresent) {
      return data;
    } else {
      return "Do not Import";
    }
  }

  checkIfPresent(type) {
    let isValuePresent = false;
    let data = "";

    for (const key in this.selectedData) {
      if (
        this.selectedData.hasOwnProperty(key) &&
        this.selectedData[key].index === type
      ) {
        isValuePresent = true;
        data = this.selectedData[key].name;
        break;
      }
    }

    if (isValuePresent) {
      return data;
    } else {
      return "";
    }
  }

  getFormatedData() {
    const fd = new FormData();

    fd.append("file", this.fileToUpload, this.fileToUpload.name);
    let data: any = {
      fileCharacteristics: {},
      posCustomerColumnIndex: {},
      tagName: this.tagName,
      merchantId: this.tokenStorage.getMerchantId(),
      provider: this.tokenStorage.getMerchantOnlineStore(),
      storeId: this.isAdmin? this.selectedStoreId : this.tokenStorage.getUploadStoreId(),
      leads : this.data.leads,
      createdBy: {
        storeId: this.tokenStorage.getqId() === "null" ? null : this.tokenStorage.getqId(),
        storeName: this.tokenStorage.getStoreName()
    }
    };
    data.fileCharacteristics.fileSize = this.fileSize.bytes;
    data.fileCharacteristics.customerDetailsStartsAtRow = 1;
    data.fileCharacteristics.customerDetailsEndsAtRow = this.numberOfRows;
    data.fileCharacteristics.fileType = "CSV";

    for (let k in this.selectedData) {
      data.posCustomerColumnIndex[this.selectedData[k]["value"]] =
        this.selectedData[k]["index"];
    }

    fd.append("body", JSON.stringify(data));

    return fd;
  }

  numberInputHandler(event: KeyboardEvent, maxLength) {
    const invalidCharacters = ["e", "-", "+"];
    const inputValue = (event.target as HTMLInputElement).value;
    const hasDecimalPoint = inputValue.includes(".");
    if (
      (inputValue.length >= maxLength && event.key !== "Backspace") ||
      invalidCharacters.includes(event.key) ||
      (event.key === "." && hasDecimalPoint)
    ) {
      event.preventDefault();
    }
  }

  uploadData() {
    this.submit = true;
    if (this.validationCheck()) {
      this.dataLoading = true;
      let data = this.getFormatedData();
      this.uploadDataApi(data).then(
        (result) => {
          console.log(result);
          let data = {
            decision: true,
            response: result,
          };
          this.dataLoading = false;
          this.dialogRef.close(data);
        },
        (err) => {
          this.dataLoading = false;
          this.snackBar.openSnackBar(err.body, 2000);
        }
      );
    } else {
      if((this.selectedStoreId.length != 0 || !this.isAdmin) && this.tagName.length!=0  ) {
        this.openErrorDialog(
          'Your CSV File must contain column names  "Customer Name / First Name" and "Phone Number / Email"'
        );
      }
    }
  }

  openErrorDialog(err) {
    const dialogRef = this.dialog.open(ContactUsDialogComponent, {
      panelClass: "no-padding-dialog-popup",
      height: "auto",
      disableClose: true,
      width: "380px",
      data: {
        header: "Validation Failed",
        body: err,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      return;
    });
  }

  validationCheck(): Boolean {
    if (
      (this.selectedData.hasOwnProperty("First Name") ||
        this.selectedData.hasOwnProperty("Customer Name")) &&
      (this.selectedData.hasOwnProperty("Mobile Number") ||
        this.selectedData.hasOwnProperty("Email")) &&
      this.tagName.length !== 0 && (!this.isAdmin || this.selectedStoreId.length!=0)
    )
      return true;
    else return false;
  }

  getToolTipForUploadData() {
    if (this.numberOfRows === 0) {
      return "Please enter atleast one row";
    } else {
      return this._i18nDynamicTranslate.transform("Upload Data", ["POS_PAGE"]);
    }
  }

  uploadDataApi(data) {
    return new Promise((resolve, reject) => {
      this._apiCall.uploadCustomerData(data).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          console.log(err.error);
          reject(err.error);
        }
      );
    });
  }

  changeFile() {
    this.fileToUpload = null;
    this.fileName = "";
    this.fileSize = {
      size: "",
      bytes: "",
    };
    this.csvHeader = [];
    this.csvSampleData = {};
  }

  removeTag(tag) {
    const index: number = this.tagList.indexOf(tag);
    if (index !== -1) {
      this.tagList.splice(index, 1);
    }
  }

  addTag() {
    if (this.tagName.length !== 0) {
      this.tagList.push(this.tagName);
      this.tagName = "";
    }
  }
  closeDialog(decision) {
    let data = {
      decision: decision,
      response: null,
    };
    this.dialogRef.close(data);
  }
}
