import { Component, ElementRef, OnInit, HostListener, Renderer2, ChangeDetectorRef } from '@angular/core';
import { getCurrencySymbol } from "@angular/common";
import { Router } from "@angular/router";
import { ApiCallService } from 'src/app/core/services/api-call-service';
import { SnackbarCollection } from 'src/app/core/services/snackbar.service';
import { MatDialog } from "@angular/material/dialog";
import { ConfirmationDialogBoxComponent } from "src/app/shared/components/dialog-box";
import { TokenStorageService } from "src/app/core/auth/token-storage.service";
import { TranslateDynamicText } from 'src/app/shared/pipes/dynamic-translation.pipe';
import { getdateformatService } from 'src/app/core/services/get-date-format.service';
import { WidgetConfigService } from 'src/app/core/services/widget-config.service';


class Conversion {
  points: number;
  amount: number;
  currency: string;
}
class CoinRedemptionLimit {
  type = 'coins';
  value: number;
}
class LoyaltyPointsExpiryConfig {
  timeFrame = 'MONTH';
  duration: number;
}
class loyaltyPoints {
  name: string;
  logoPath: string;
}
class Tier {
  id: number;
  name: string;
  entryPoints: number = null;
  lifeTimeOrderValue: number = null;
  lifeTimeOrderCount: number = null;
  level: number;
  description: string;
  logoPath: string;
  conversion = new Conversion();
  coinRedemptionLimit = new CoinRedemptionLimit();
  constructor(level: number) {
    this.level = level;
  }
  loyaltyPointsExpiryConfig :LoyaltyPointsExpiryConfig;
  expiryConfigForPushNotification :LoyaltyPointsExpiryConfig;
  useExpiryForTierEvaluation: boolean;
}
class RuleDefinition {
  event: string;
  eventCount: number;
  //default value for event dropdown
  eventDropdownObj = {
    defaultValue:null,
    displayName:null,
    name:null,
    valueEditable:true,
    dataType:null,
  };
}

class LoyaltyRule {
  ruleName: string;
  ruleType = "EARN";
  validTillDateObj: Date;
  validFromDateObj: Date;
  validTill:string;
  validFrom: string;
  ruleCategory = "BASIC";
  rewardPoints: number;
  ruleDefinition:{};
  tiersApplicableTo = [];
  segmentsApplicableTo = [];
  customerListApplicableTo = [];
  employeeListApplicableTo = [];
  offerIds = [];
  hasRewardPoints = true;
  hasOffers = false;
  isAllCustomers = false;
  priorityId:number;
  excludedOrderCategories = [];
  //for amount purchased event's inner inclusion/exclusion
  //inclusionRule:[], dynamically added for inclusion rules
  includedProducts = [];
  includedProductCategories = [];
  excludedProducts = [];
  excludedProductCategories = [];
  hasExclusion = false;
  constructor(priorityId?:number) {
    this.priorityId = priorityId;
  }
  isRecursive:true; //means the reward can be earned over and over again (every time)
}
class ComparisonStatement {
  operand1:string = null;
  operator:string = 'is greater than or equal to';
  operand2:number = null;
  //stores display name of operand1
  operand1Display:string = null;
  // logicalOperator:string; assigned dynamically when new one is added
  //eventDropdownObj is used to handle event drop down selection
  eventDropdownObj = {
    defaultValue:null,
    displayName:null,
    name:null,
    valueEditable:true,
    operator:null,
    dataType:null,
  };
  
}
class ComparisonStatementsList {
  comparisonStatements: ComparisonStatement[] = [];
  // logicalOperator:string; assigned dynamically when new one is added
}
class RuleDefinitionAdvancedCustom {
  loyaltyRuleSegmentDefinition = {
    logicalExpressions : [],
  }
}

@Component({
  selector: 'app-loyalty-program-create',
  templateUrl: './loyalty-program-create.component.html',
  styleUrls: ['./loyalty-program-create.component.css'],
})
export class LoyaltyProgramCreateComponent implements OnInit {

  constructor(private _router: Router,
    private _apicall: ApiCallService,
    private _snackBar: SnackbarCollection,
    private dialog: MatDialog,
    private _TokenStorageService: TokenStorageService,
    private _i18nDynamicTranslate: TranslateDynamicText,
    private date_format: getdateformatService,
    private elRef: ElementRef,
    private renderer: Renderer2,
    private _WidgetConfigService: WidgetConfigService) {}

    
  completedSteps: number = 0;
  currentStep: number = 1;
  maxStepReached = 1;
  coinImgUrls = [
    "https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/loyalty/defaultCoin1.svg",
    "https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/loyalty/defaultCoin2.svg",
    "https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/loyalty/defaultCoin3.svg",
  ]
  tierImgUrls = [
    {
      url: "https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/loyalty/defaultTier1.svg",
      chosen: -1,//indicates image hasn't been chosen for any tier
    },
    {
      url: "https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/loyalty/defaultTier2.svg",
      chosen: -1,
    },
    {
      url: "https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/loyalty/defaultTier3.svg",
      chosen: -1,
    },
  ];
  timeFrameOptions1 = [
    {
      display:'Months',
      value:'MONTH'
    },
    {
      display:'Years',
      value:'YEAR'
    },
  ];
  timeFrameOptions2 = [
    {
      display:'Weeks',
      value:'WEEK'
    },
    {
      display:'Months',
      value:'MONTH'
    },
  ];
  currencySymbol: string;
  loyaltyPoints = new loyaltyPoints();
  tierMap = [];
  sortedTierMap;
  loyaltyRules = [];
  minDate;
  validTillDate;
  ruleTypeDropdownValues = [];
  ruleEventDropdownValues = [];
  segmentDropdownValues = [];
  dataLoaded = false;
  ruleFrequencies = [];
  segmentVariables;
  loyaltyProgramConfig;
  applicableCustomerGroupList = [];
  revCurrency;
  validWidgets;
  

  ngOnInit(): void {
    this.revCurrency = sessionStorage.getItem("serviceCurrency");
    this.currencySymbol = getCurrencySymbol(this.revCurrency, "narrow");
    const currentDate = new Date();
    this.minDate = new Date(
      currentDate.toLocaleString("en-US", {
        timeZone: this._TokenStorageService.getMerchantTimeZone(),
      })
    );
    //api calls for data
    this.getOfferTemplates();
    this.getLoyaltyProgramConfig();
    this.getLoyaltyProgramDropdowns();
    this.getProduct('');
    this.getConstantProducts();
    this.getCustomerSegmentsAndTiers();
    this.getWidgets();
  }


  hasSpecialTierEntryWidget = null;
  async getWidgets() {
    this.validWidgets = await this._WidgetConfigService.getWidget();
    //widgetId 84 is for special tier entry widget with more fields
    this.hasSpecialTierEntryWidget = this.validWidgets?.find(d => d.widget.id === 84);
  }

  customerGroupings = [];
  categoryAbbreviationMap = {
    tier: 'LT',
    segment: 'SG',
    customerlist: 'CL',
    employeelist: 'EL',
  }
  getCustomerSegmentsAndTiers() {
    this.dataLoaded = false;
    this._apicall.getCustomerSegmentsAndTiers().subscribe(response => {
      let res = (response['body']);
      for (let i = 0; i < res.length; i++) {
        if(res[i].category.toLowerCase() != 'tier') {
          this.customerGroupings.push({
            id: res[i]["id"],
            name: res[i]["segmentName"],
            isActive: res[i]["isActive"],
            category: res[i]["category"].toLowerCase(),
            checked: false,
            categoryAbbreviation: this.categoryAbbreviationMap[res[i].category.toLowerCase()],
            diabled: false,
          });
        }
      }
      this.dataLoaded = true;
    },
    error => {
      this.dataLoaded = true;
      this._snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Error fetching getCustomerSegmentsAndTiers data", ['POS_PAGE']), 2000);
    });
  }

  productListApiData = {
    pageNo:1,
    pageSize:1500,
    maxProductListPrize:null,
  }
  apiProductList= [];
  getProduct(char) {
    this.dataLoaded = false;
    this._apicall
      .getOfferManagementProductsList(char,this.productListApiData.pageNo,this.productListApiData.pageSize)
      .subscribe((resultList) => {
        let data = (resultList["body"]);
        this.productListApiData.maxProductListPrize = parseInt(Object.keys(data)[0]);
        data[Object.keys(data)[0]].forEach((product)=>this.apiProductList.push({
          name:product.productName,
          disabled:false,
          checked:false,
        }));
        //lazy fetch other products
        if(this.productListApiData.maxProductListPrize > this.productListApiData.pageNo * this.productListApiData.pageSize) {
          this.productListApiData.pageNo++;
          this.getProduct('');
        }
        //after getting all products
        if(this.productListApiData.maxProductListPrize == this.apiProductList.length) {
          this.dataLoaded = true;
        }
      }, (error) => {
        // this.productCategoryMap['PRODUCTLIST'] = this.apiProductList;
        this._snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Failed loading product list", ['SHARED_PAGE']),  3000);
        this.dataLoaded = true;
    });
  }

  apiProductCategoryList = [];
  getConstantProducts() {
    this.dataLoaded = false;
    this._apicall.getConstantProducts().subscribe(
      (response)=>{
        let res = JSON.parse(response['body']);
        this.apiProductCategoryList = res.productCategories.map((categoryObj=> {
          return {
            name:categoryObj.productCategoryName,
            disabled:false,
            checked:false,
          }
        }));
      },
      (err)=>{
        this._snackBar.openSnackBar("Error fetching product categories",3000);
        this.dataLoaded = true;
      }
    );
  }

  offerList = [];
  getOfferTemplates() {
    this.dataLoaded = false;
    this._apicall.getAllOfferList().subscribe(
      (response) => {
        if (response["message"] === "success") {
          this.offerList = (response["body"]).offers;
          //Show only saved offers
          this.offerList = this.offerList.length>0?this.offerList.filter(offer=>offer.status.toLowerCase() == 'draft'):this.offerList;
          this.dataLoaded = true;
        }
      },
      (err) => {
        this.dataLoaded = true;
        this._snackBar.openSnackBar("Error fetching offer data", 2000);
        console.log(err);
      }
    );
  }

  getLoyaltyProgramConfig() {
    this.dataLoaded = false;
    this._apicall.getLoyaltyProgramConfig().subscribe(
      response=>{
        this.loyaltyProgramConfig = response['body'];
        if(this.loyaltyProgramConfig) {
          //check if step1 or reward coin has been setup
          if(this.loyaltyProgramConfig.rewardPoints) {
            this.loyaltyPoints = this.loyaltyProgramConfig.rewardPoints;
            //check for user uploaded imgage 
            if(!this.coinImgUrls.includes(this.loyaltyPoints.logoPath) && (this.loyaltyPoints.logoPath)) {
              this.coinImgUrls[3] = this.loyaltyPoints.logoPath;
            }
          }
          //check if step2 or tiers have been setup
          if(this.loyaltyProgramConfig.tierConfig.tierMap && this.loyaltyProgramConfig.tierConfig.tierMap.length > 0) {
            this.tierMap = this.loyaltyProgramConfig.tierConfig.tierMap;
            this.goToStep(2,true);
            //choose images for tier
            for(let i=0;i<this.tierMap.length;i++) {
              let found = false;
              for(let j=0;j<this.tierImgUrls.length;j++) {
                if (this.tierMap[i].logoPath == this.tierImgUrls[j].url) {
                  this.tierImgUrls[j].chosen = i;
                  found = true;
                  break;
                }
              }
              if(!found) {
                if(this.tierMap[i].logoPath) {
                  this.tierImgUrls.push({
                    url: this.tierMap[i].logoPath,
                    chosen: i,
                  });
                }
              }
            }            
          }
          //check if step3 or rules have been setup
          if(this.loyaltyProgramConfig.ruleConfig.loyaltyRules && this.loyaltyProgramConfig.ruleConfig.loyaltyRules.length>0) {
            this.loyaltyRules = this.loyaltyProgramConfig.ruleConfig.loyaltyRules;
            this.goToStep(3,true);
            this.loyaltyRules.forEach((rule)=>{
              if(rule.validFrom) {
                // rule.validFrom = rule.validFrom.split("-").map(Number);
                rule.validFromDateObj = new Date(rule.validFrom[0], rule.validFrom[1] - 1, rule.validFrom[2]);
                this.datePicked(rule,'from');
              }
              if(rule.validTill) {
                // rule.validTill = rule.validTill.split("-").map(Number);
                rule.validTillDateObj = new Date(rule.validTill[0], rule.validTill[1] - 1, rule.validTill[2]);
                this.datePicked(rule,'till');
              }
              if(rule.ruleCategory === 'BASIC') {
                this.initializeBasicRuleDetails(rule);
              } else if(rule.ruleCategory === 'ADVANCE_CUSTOM') {
                this.initializeAdvanceCustomRuleDetails(rule);
              }
              if(rule.rewardPoints) {
                rule.hasRewardPoints = true;
              } else {
                rule.hasRewardPoints = false;
              }
              if(rule.offerIds && rule.offerIds.length>0) {
                rule.hasOffers = true;
              } else {
                rule.hasOffers = false;
                rule.offerIds = [];
              }
              if(rule.inclusionRule?.length>0) {
                for(let innerRule of rule.inclusionRule) {
                  if(innerRule.rewardPoints) {
                    innerRule.hasRewardPoints = true;
                  } else {
                    innerRule.hasRewardPoints = false;
                  }
                  if(innerRule.offerIds && innerRule.offerIds.length>0) {
                    innerRule.hasOffers = true;
                  } else {
                    innerRule.hasOffers = false;
                    innerRule.offerIds = [];
                  }
                }
              }
              if(rule.strategyId) {
                this._apicall.getStrategy(rule.strategyId).subscribe(
                  response => {
                    let strategyData = JSON.parse(response["body"]);
                    rule['strategyName'] = strategyData.strategyName;
                  },
                  error => {
                    this._snackBar.openSnackBar('Error fetching campaign name for id '+rule.strategyId,2000);
                  }
                );
              }
            });
          }
          if(!this.loyaltyProgramConfig.partialUpdate) {
            this.goToStep(1,false);
            this.completedSteps = 4;
            this.maxStepReached = 4;
          }
        }
      },
      error=>{
        this._snackBar.openSnackBar("Error fetching config data", 2000);
        console.log(error);
      }
    );
  }
  
  orderCategoriesList = [];
  getLoyaltyProgramDropdowns() {
    this._apicall.getLoyaltyProgramDropdowns().subscribe(response => {
      let responseData = response["body"];
      this.ruleFrequencies = responseData.eventFrequencies
      this.ruleTypeDropdownValues = responseData.ruleTypes;
      // console.log(responseData);
      const events: any[] = Object.values(responseData.loyaltyEvents);
      for (let event of events) {
        event.forEach((each) => {
          if(each.displayName.toLowerCase() === 'amount spent') {
            each.displayName = `${this.currencySymbol} spent in a purchase`;
          }
          this.ruleEventDropdownValues.push({
            name: each.name,
            displayName: each.displayName,
            valueEditable: each.valueEditable,
            defaultValue: each.defaultValue,
            operators: each.operators,
            dataType: each.dataType,
          })
        });
      }
      responseData.orderCategories?.forEach(cat=>this.orderCategoriesList.push({
        name:cat,
        disabled:false,
        checked:false,
      }));
      this.dataLoaded = true;
      // console.log(this.ruleEventDropdownValues);
    },
    error => {
      this._snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Error fetching events dropdown data", ['POS_PAGE']), 2000);
      this.dataLoaded = true;
    });
  }
  
  getDisplayName(name,comparisonStatement) {
    for (let key in this.segmentVariables) {
      let index = this.segmentVariables[key].findIndex((s) => s.name === name);
      if (index > -1) {
        comparisonStatement.operand1Display = this.segmentVariables[key][index]["displayName"];
        return this.segmentVariables[key][index]["displayName"];
      }
    }
  }

  createapplicable() {
    const allCustomers = {
      "id": -1,
      "name": "My Customers",
      "isActive": true,
      "category": 'ALL',
      "checked":false,
      "disabled":false,
    };
    const noTierOption = {
      "id": 0,
      "name": "No Tier",
      "entryPoints": 0,
      "level": 0,
      "description": null,
      "logoPath": null,
      "isActive": true,
      "category": 'tier',
      "checked":false,
      "categoryAbbreviation":'LT',
      "disabled":false,
    };
    this.applicableCustomerGroupList = [];
    this.applicableCustomerGroupList.push(allCustomers);
    this.applicableCustomerGroupList.push(noTierOption);
    for(let tier of this.tierMap) {
      this.applicableCustomerGroupList.push({
        ...tier,category:'tier',checked:false,categoryAbbreviation:'LT',disabled:false,
      });
    }
    this.applicableCustomerGroupList = [...this.applicableCustomerGroupList,...this.customerGroupings];

    //for auto preselection in multi select dropdown equate the objects in the array
    // this.loyaltyRules.forEach((rule) => {
    //   if (rule.tiersApplicableTo) {
    //     for (let i = 0; i < rule.tiersApplicableTo.length; i++) {
    //       const appliedTier = rule.tiersApplicableTo[i];
    //       const matchingTier = this.applicableCustomerGroupList.find(tier => {
    //         if(tier.id === null) {
    //           return tier.name === appliedTier.name;
    //         } else {
    //           return tier.id === appliedTier.id;
    //         }
    //       });
    //       if (matchingTier) {
    //         rule.tiersApplicableTo[i] = matchingTier;
    //       }
    //     }
    //   }
    // });


  }

  goToStep(step, stepCompleted,revalidateToshowError?) {
    this.resetErrorState();
    if(step == 3) {
      this.createapplicable();
    }
    if (step <= this.maxStepReached && !stepCompleted) {
      this.currentStep = step;
    }
    if (stepCompleted) {
      if (this.validateBeforeGoingTo(step)) {
        this.maxStepReached = Math.max(step, this.maxStepReached);
        this.currentStep = step;
        this.completedSteps = step - 1;
        if(step==2 && !this.tierMap.length) {
          let newTier = new Tier(1);
          newTier.conversion.currency = this.revCurrency;
          this.tierMap.push(newTier);
        }
        if(step==3 && !this.loyaltyRules.length) {
          this.addNewRule();
        }
        //compute sorted tier list
        if(step == 4) {
          let tierMapCopy = JSON.parse(JSON.stringify(this.tierMap));
          tierMapCopy.sort((first, second) => first.level - second.level);
          this.sortedTierMap = tierMapCopy;
        }
        let top = document.getElementById('scroll');
        top.scrollIntoView({ behavior: 'smooth' });
      }
    }
    if(revalidateToshowError) {
      setTimeout(() => {
        this.validateBeforeGoingTo(step+1);
      }, 500);
    }
  }

  private resetErrorState() {
    const inputFields = document.querySelectorAll('.error-input');
      // Remove error class from all input fields
      inputFields.forEach(inputField => {
        inputField.classList.remove('error-input');
      });
      const popovers = document.querySelectorAll('.error-popover');
      // Remove the popover if found
      popovers.forEach(popover => {
        popover.remove();
      });
  }

  private setErrorState(inputId: string,message) {
    // Add error class to the specified input field
    // this.resetErrorState();
    const inputField = this.elRef.nativeElement.querySelector(`#${inputId}`);
    if (inputField) {
      inputField.classList.add('error-input');
      this.addPopover(inputField,message);
    }
  }

  addPopover(inputElement: HTMLElement,message: string) {
    const popover = this.renderer.createElement('div');
    this.renderer.addClass(popover, 'error-popover'); 
    this.renderer.setProperty(popover, 'innerHTML', message); // Set popover content
    // Calculate the right position
    const containerWidth = inputElement.getBoundingClientRect().width;
    // const containerHeight = inputElement.getBoundingClientRect().height;
    // const rightPosition = window.innerWidth - containerRight + popoverWidth; // Calculate the right position

    this.renderer.setStyle(popover, 'left', `${(containerWidth/2)-40}px`); // Set the right position

    // this.renderer.setStyle(popover, 'top', `${containerHeight-70}px`);

    this.renderer.appendChild(inputElement, popover);
  }

  scrollToErrorField() {
    setTimeout(() => {
      const errorField = document.querySelector('.error-input');
      if (errorField) {
          errorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, 250);
  }

  validateBeforeGoingTo(step) {
    if (step == 2) {
      let hasError = false;
      this.loyaltyPoints.name = this.loyaltyPoints.name?.trim();
      if (!this.loyaltyPoints.name) {
        this.setErrorState('coinNameInput','Please enter valid name');
        this.scrollToErrorField();
        hasError = true;
      }
      if(this.loyaltyPoints.name.length<2) {
        this.setErrorState('coinNameInput','Name cannot be less than 2 characters');
        this.scrollToErrorField();
        hasError = true;
      }
      if(!this.loyaltyPoints.logoPath) {
        this.setErrorState('coinImgInput','Please select an image');
        this.scrollToErrorField();
        hasError = true;
      }
      if(hasError) {
        return false;
      }
      return true;
    }
    if (step == 3) {
      if(this.tierMap.length<1) {
        return false;
      }
      let levelSet = new Set();
      let tierNameSet = new Set();
      let hasError = false;
      for (let i = 0; i < this.tierMap.length; i++) { //check if all values are entered without duplicate name and level
        let curTier = this.tierMap[i];
        //set currency value
        
        curTier.name = curTier.name?.trim();
        if(!curTier.name) {
          this.setErrorState('tierNameInput'+i,'Please enter valid name');
          this.scrollToErrorField();
          hasError = true;
        }
        if(curTier.name.length<2) {
          this.setErrorState('tierNameInput'+i,'Name cannot be less than 2 characters');
          this.scrollToErrorField();
          hasError = true;
        }
        if(!this.hasSpecialTierEntryWidget) {
          if(curTier.entryPoints == null || curTier.entryPoints < 0) {
            this.setErrorState('tierPointsInput'+i,'Please enter valid value');
            this.scrollToErrorField();
            hasError = true;
          }
        } else {
          if(curTier.entryPoints == null &&  curTier.lifeTimeOrderValue == null && curTier.lifeTimeOrderCount == null) {
            this.setErrorState('tierEntryInput'+i,'Please enter valid value for at least one field');
            this.scrollToErrorField();
            hasError = true;
          }
        }
        
        if(!curTier.logoPath) {
          this.setErrorState('tierImgInput'+i,'Please select an image');
          this.scrollToErrorField();
          hasError = true;
        }
        if(!curTier.conversion.points || curTier.conversion.points < 0) {
          this.setErrorState('pointsInput'+i,'Please enter valid value');
          this.scrollToErrorField();
          hasError = true;
        }
        if(!curTier.conversion.amount || curTier.conversion.amount < 0) {
          this.setErrorState('amountInput'+i,'Please enter valid value');
          this.scrollToErrorField();
          hasError = true;
        }
        if (!curTier.coinRedemptionLimit.value || curTier.coinRedemptionLimit.value < 0) {
          this.setErrorState('coinRedemptionLimitInput'+i,'Please enter valid value');
          this.scrollToErrorField();
          hasError = true;
        }
        if(curTier.loyaltyPointsExpiryConfig && (!curTier.loyaltyPointsExpiryConfig.duration || curTier.loyaltyPointsExpiryConfig.duration < 0)) {
          this.setErrorState('expDurationInput'+i,'Please enter a valid value');
          this.scrollToErrorField();
          hasError = true;
        }
        if(curTier.expiryConfigForPushNotification && (!curTier.expiryConfigForPushNotification.duration || curTier.expiryConfigForPushNotification.duration < 0)) {
          this.setErrorState('notiDurationInput'+i,'Please enter a valid value');
          this.scrollToErrorField();
          hasError = true;
        }
        curTier.conversion.currency = this.revCurrency;
        if(curTier.coinRedemptionLimit.type == 'percentage' && (curTier.coinRedemptionLimit.value>100 || curTier.coinRedemptionLimit.value < 0)) {
          this.setErrorState('coinRedemptionLimitInput'+i,'Coin Redemption Percentage is Invalid');
          this.scrollToErrorField();
          hasError = true;
        }
        if (levelSet.has(curTier.level)) {
          this.setErrorState('levelInput'+i,'Duplicate Level for Tier');
          this.scrollToErrorField();
          hasError = true;
        } else {
          levelSet.add(curTier.level);
        }
        if (tierNameSet.has(curTier.name.toLowerCase())) {
          this.setErrorState('tierNameInput'+i,'Duplicate tier name not allowed');
          this.scrollToErrorField();
          hasError = true;
        } else {
          tierNameSet.add(curTier.name.toLowerCase());
        }
      }

      //check if points for each tier is greater than the lower level tier
      let tierMapCopy = JSON.parse(JSON.stringify(this.tierMap));
      //sort the tiers by their levels descending order
      tierMapCopy.sort((first, second) => second.level - first.level);
      for (let i = 0; i < tierMapCopy.length; i++) {
        //higher level tier cannot have lesser entryPoints than lower level tier
        for(let j=i+1; j<tierMapCopy.length; j++) {
          if(tierMapCopy[i].entryPoints != null && tierMapCopy[j].entryPoints != null) {
            if(tierMapCopy[i].entryPoints <= tierMapCopy[j].entryPoints) {
              let errorTierIndex = this.tierMap.findIndex(actualTier=> tierMapCopy[i].level == actualTier.level);
              let errorTierIndex2 = this.tierMap.findIndex(actualTier=> tierMapCopy[j].level == actualTier.level);
              this.setErrorState('tierPointsInput'+errorTierIndex,`Level ${this.tierMap[errorTierIndex].level} tier cannot have lesser or equal points than level ${this.tierMap[errorTierIndex2].level} tier`);
              this.scrollToErrorField();
              hasError = true;
            }
          }
          //hasSpecialTierEntryWidget case where entry has more fields
          if(tierMapCopy[i].lifeTimeOrderValue != null && tierMapCopy[j].lifeTimeOrderValue != null) {
            if(tierMapCopy[i].lifeTimeOrderValue <= tierMapCopy[j].lifeTimeOrderValue) {
              let errorTierIndex = this.tierMap.findIndex(actualTier=> tierMapCopy[i].level == actualTier.level);
              let errorTierIndex2 = this.tierMap.findIndex(actualTier=> tierMapCopy[j].level == actualTier.level);
              this.setErrorState('tierLTOVInput'+errorTierIndex,`Level ${this.tierMap[errorTierIndex].level} tier cannot have lesser or equal value than level ${this.tierMap[errorTierIndex2].level} tier`);
              this.scrollToErrorField();
              hasError = true;
            }
          }
          if(tierMapCopy[i].lifeTimeOrderCount != null && tierMapCopy[j].lifeTimeOrderCount != null) {
            if(tierMapCopy[i].lifeTimeOrderCount <= tierMapCopy[j].lifeTimeOrderCount) {
              let errorTierIndex = this.tierMap.findIndex(actualTier=> tierMapCopy[i].level == actualTier.level);
              let errorTierIndex2 = this.tierMap.findIndex(actualTier=> tierMapCopy[j].level == actualTier.level);
              this.setErrorState('tierLTOCInput'+errorTierIndex,`Level ${this.tierMap[errorTierIndex].level} tier cannot have lesser or equal count than level ${this.tierMap[errorTierIndex2].level} tier`);
              this.scrollToErrorField();
              hasError = true;
            }
          }
        }
      }
      //sort the actual tier list in decending order or level in view
      this.sortedTierMap = tierMapCopy;

      if(hasError) {
        return false;
      }
      return true;
    }
    if(step>3) {
      if(this.loyaltyRules.length<1) {
        return false;
      }
      let prioritySet = new Set();
      let ruleNameSet = new Set();
      let hasError = false;
      for(let i = 0; i < this.loyaltyRules.length; i++) {
        let curRule = this.loyaltyRules[i];
        curRule.ruleName = curRule.ruleName?.trim();
        if(!curRule.ruleName) {
          this.setErrorState('ruleNameInput'+i,'Please enter valid rule name');
          this.scrollToErrorField();
          hasError = true
        }
        if(curRule.ruleName.length < 2) {
          this.setErrorState('ruleNameInput'+i,'Name cannot be less than 2 characters');
          this.scrollToErrorField();
          hasError = true
        }
        if(!curRule.isAllCustomers 
          && (!curRule.tiersApplicableTo || curRule.tiersApplicableTo.length == 0)
          && (!curRule.segmentsApplicableTo || curRule.segmentsApplicableTo.length == 0)
          && (!curRule.customerListApplicableTo || curRule.customerListApplicableTo.length == 0)
          && (!curRule.employeeListApplicableTo || curRule.employeeListApplicableTo.length == 0)) {
          this.setErrorState('appliGroupInput'+i,'Please select a group');
          this.scrollToErrorField();
          hasError = true
        }
        if(!curRule.priorityId) {
          this.setErrorState('rankInput'+i,'Please select a Priority rank');
          this.scrollToErrorField();
          hasError = true
        } else {
          if(prioritySet.has(curRule.priorityId)) {
            this.setErrorState('rankInput'+i,'Duplicate Priority rank');
            this.scrollToErrorField();
            hasError = true
          } else {
            prioritySet.add(curRule.priorityId);
          }
        }
        if(!curRule.validFrom) {
          this.setErrorState('validFromInput'+i,'Please select a From date');
          this.scrollToErrorField();
          hasError = true
        }
        if(!curRule.validTill) {
          this.setErrorState('validTillInput'+i,'Please select a Till date');
          this.scrollToErrorField();
          hasError = true
        }
        if(curRule.validTillDateObj && curRule.validTillDateObj < this.minDate) {
          this.setErrorState('validTillInput'+i,'Please select a valid Till date');
          this.scrollToErrorField();
          hasError = true
        }
        if (ruleNameSet.has(curRule.ruleName.toLowerCase())) {
          this.setErrorState('ruleNameInput'+i,'Duplicate rule name not allowed');
          this.scrollToErrorField();
          hasError = true
        } else {
          ruleNameSet.add(curRule.ruleName.toLowerCase());
        }
        if(curRule.ruleCategory == 'BASIC') {
          if(!curRule.ruleDefinition.eventCount || curRule.ruleDefinition.eventCount < 0) {
            this.setErrorState('eventCountInput'+i,'Please enter a valid value');
            this.scrollToErrorField();
            hasError = true
          }
          if(!(curRule.hasRewardPoints || curRule.hasOffers)) {
            this.setErrorState('checkBoxInput'+i,'Please select a reward for the rule');
            this.scrollToErrorField();
            hasError = true
          }
          if(curRule.hasRewardPoints && (!curRule.rewardPoints || curRule.rewardPoints < 0)) {
            this.setErrorState('rewardPointInput'+i,'Please enter valid loyalty points');
            this.scrollToErrorField();
            hasError = true
          }
          if(curRule.hasOffers && !(curRule.offerIds?.length>0)) {
            this.setErrorState('offerInput'+i,'Please select an offer');
            this.scrollToErrorField();
            hasError = true
          }
          //validation for inner rules
          if(curRule.ruleDefinition.event == '$amount_spent') {
            if(curRule.inclusionRule?.length>0) {
              for(let j = 0; j < curRule.inclusionRule.length; j++) {
                let curInnerRule = curRule.inclusionRule[j];
                if(!curInnerRule.ruleDefinition.eventCount || curInnerRule.ruleDefinition.eventCount < 0) {
                  this.setErrorState('eventCountInput'+i+'-'+j,'Please enter a valid value');
                  this.scrollToErrorField();
                  hasError = true
                }
                //inclusion rules validation
                if(!(curInnerRule.includedProducts?.length>0) && !(curInnerRule.includedProductCategories?.length>0)) {
                  this.setErrorState('listInput'+i+'-'+j,'Please select a product or category');
                  this.scrollToErrorField();
                  hasError = true
                }
                if(!(curInnerRule.hasRewardPoints || curInnerRule.hasOffers)) {
                  this.setErrorState('checkBoxInput'+i+'-'+j,'Please select a reward for the rule');
                  this.scrollToErrorField();
                  hasError = true
                }
                if(curInnerRule.hasRewardPoints && (!curInnerRule.rewardPoints || curInnerRule.rewardPoints < 0)) {
                  this.setErrorState('rewardPointInput'+i+'-'+j,'Please enter valid loyalty points');
                  this.scrollToErrorField();
                  hasError = true
                }
                if(curInnerRule.hasOffers && !(curInnerRule.offerIds?.length>0)) {
                  this.setErrorState('offerInput'+i+'-'+j,'Please select an offer');
                  this.scrollToErrorField();
                  hasError = true
                }
              }
            }
            //exclusion validation
            if(curRule.hasExclusion && (!(curRule.excludedProducts?.length>0) && !(curRule.excludedProductCategories?.length>0))) {
              this.setErrorState('listInput'+i+'-'+'exclusion','Please select a product or category');
              this.scrollToErrorField();
              hasError = true
            }
          }
        }
        if(curRule.ruleCategory == 'ADVANCE_CUSTOM') {
          for(let m=0;m<curRule.ruleDefinition.loyaltyRuleSegmentDefinition.logicalExpressions.length;m++) {
            let curLogicalGroup = curRule.ruleDefinition.loyaltyRuleSegmentDefinition.logicalExpressions[m];
            for(let n=0;n<curLogicalGroup.comparisonStatements.length;n++) {
              let comparisonStatements = curLogicalGroup.comparisonStatements[n];
              if(!comparisonStatements.operand2 || comparisonStatements.operand2 < 0 ) {
                this.setErrorState(`csOp2Input${i}-${m}-${n}`,'Please enter a valid value');
                this.scrollToErrorField();
                hasError = true
              }
            }
          }
          if(!(curRule.hasRewardPoints || curRule.hasOffers)) {
            this.setErrorState('checkBoxInput'+i,'Please select a reward for the rule');
            this.scrollToErrorField();
            hasError = true
          }
          if(curRule.hasRewardPoints && (!curRule.rewardPoints || curRule.rewardPoints < 0)) {
            this.setErrorState('rewardPointInput'+i,'Please enter valid loyalty points');
            this.scrollToErrorField();
            hasError = true
          }
          if(curRule.hasOffers && !(curRule.offerIds?.length>0)) {
            this.setErrorState('offerInput'+i,'Please select an offer');
            this.scrollToErrorField();
            hasError = true
          }
        }
      }
      if(hasError) {
        return false;
      }
      return true;
    }
    return false;
  }

  onTabLabelClick(event, rule: any): void {
    if (event.index === 0) {
      this.onBasicPanelOpened(rule);
    } else if (event.index === 1) {
      this.onAdvancePanelOpened(rule);
    }
  }

  getSelectedTabIndex(rule): number {
    return rule.ruleCategory === 'BASIC' ? 0 : 1;
  }

  setUploadedFile(event, imgOf, tierIndex, imageIndex) {
    //CALL IMG UPLOAD API TO GET IMG PATH IN S3
    const fd = new FormData();
    fd.append("files", event.target.files[0], event.target.files[0].name);
    this._apicall.uploadLoyaltyProgramCoinImg(fd).subscribe(response => {
      // console.log('Response received:', response);
      if (imgOf === 'coin') {
        this.coinImgUrls[3] = response["body"][0]+ '?v=' + Date.now();
        this.selectImg(this.coinImgUrls[3],'coin',null,null);
      }
      if (imgOf === 'tier') {
        if(this.tierImgUrls.length > this.tierMap.length+2) {
          this.tierImgUrls[this.tierImgUrls.length-1] = {
              url: response["body"][0]+ '?v=' + Date.now(),
              chosen: -1,
          }
        } else {
          this.tierImgUrls.push(
            {
              url: response["body"][0]+ '?v=' + Date.now(),
              chosen: -1,
            });
        }
        this.selectImg(this.tierImgUrls[this.tierImgUrls.length-1],'tier',tierIndex,this.tierImgUrls.length-1);
      }
    },
      error => {
        this._snackBar.openSnackBar("Error uploading image", 2000);
        // console.error('Error occurred:', error);
      });
  }

  selectImg(imgobjOrUrl, imgOf, tierIndex, imageIndex) {
    this.resetErrorState();
    if (imgOf === 'coin') {
      this.loyaltyPoints.logoPath = imgobjOrUrl;
    }
    if (imgOf === 'tier') {
      this.tierMap[tierIndex].logoPath = imgobjOrUrl.url;
      this.tierImgUrls.forEach((obj) => {
        if (obj.chosen == tierIndex) {
          obj.chosen = -1;
        }
      });//unchose all other images chosen at that tier previously
      this.tierImgUrls[imageIndex].chosen = tierIndex;//set chose property in imgUrl list to current tierIndex
    }
  }

  addNewTier() {
    let newTier = new Tier(this.tierMap.length + 1);
    newTier.conversion.currency = this.revCurrency;
    this.tierMap.push(newTier);
  }
  addNewRule() {
    this.loyaltyRules.push(new LoyaltyRule(this.getNextPrioritRank()));
    this.initializeBasicRuleDetails(this.loyaltyRules[this.loyaltyRules.length-1]);
  }
  deleteTier(tierIndex) {
    this.tierMap.splice(tierIndex, 1);//delete element
    this.tierImgUrls.forEach((obj) => {
      if (obj.chosen == tierIndex) {
        obj.chosen = -1;
      }
    });//unchoses image if chosen
    this.tierMap.forEach((obj, index) => {
      obj.level = index + 1;
    });//reset weights
    this._snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Tier Deleted, Save to ensure delete", ['POS_PAGE']), 3000);
  }
  deleteRule(ruleIndex) {
    if(this.loyaltyRules[ruleIndex].strategyId) {
      const dialogref = this.dialog.open(
        ConfirmationDialogBoxComponent,
        {
          panelClass: "no-padding-dialog-popup",
          width: "280px",
          data: {
            subject: `Cannot delete this rule`,
            message: `Rule cannot be deleted as its already in use with one of the active campings`,
            successButtonText: this._i18nDynamicTranslate.transform("Ok", ['POS_PAGE']),
          }
        }
      );
    } else {
      this.loyaltyRules.splice(ruleIndex,1);
      this.loyaltyRules.forEach(element => {
        element.priorityId = null;
      });
      this._snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Rule Deleted and rule Priorities reset, Save to ensure delete", ['POS_PAGE']), 4000); 
    }
  }
  openDeleteConfirmDialog(itemType,index) {
    //check if tier has rule applied to it
    let allowDelete = true;
    if(itemType === 'tier') {
        let matchingTier;
        if(this.loyaltyRules?.length>0) {
          for(let rule of this.loyaltyRules) {
            if (rule.tiersApplicableTo) {
                matchingTier = rule.tiersApplicableTo.find(tier => {
                  if(tier.id === null) {
                    return tier.name === this.tierMap[index].name;
                  } else {
                    return tier.id === this.tierMap[index].id;
                  }
                });   
              if (matchingTier) {
                break;
              }
            }
          }
        }
        if(matchingTier) {
          allowDelete = false;
          this.dialog.open(
            ConfirmationDialogBoxComponent,
            {
              panelClass: "no-padding-dialog-popup",
              width: "280px",
              data: {
                subject: `CAN NOT DELETE  ${itemType.toUpperCase()} ${(index + 1)}`,
                message: `Loyalty rule is applied to the tier being deleted. Please ensure no rule is applied to the tier before deleting`,
                successButtonText: "Ok",
              }
            }
          );
        } else {
          if(this.tierMap[index].id) {
            this._apicall.deleteTierCheck(this.tierMap[index].id).subscribe(
              (response)=> {
                const dialogref = this.dialog.open(
                  ConfirmationDialogBoxComponent,
                  {
                    panelClass: "no-padding-dialog-popup",
                    width: "280px",
                    data: {
                      subject: `DELETE  ${itemType.toUpperCase()} ${(index + 1)}`,
                      message: `Are you sure you want to delete the ${itemType} ${(index + 1)} setup ? All data filled for this ${itemType} will be lost once deleted.`,
                      cancelButtonText: this._i18nDynamicTranslate.transform("Cancel", ['POS_PAGE']),
                      successButtonText: this._i18nDynamicTranslate.transform("Delete", ['POS_PAGE']),
                    }
                  }
                );
                dialogref.afterClosed().subscribe((result) => {
                  if(result) this.deleteTier(index);
                });
              },
              (error)=> {
                this.dialog.open(
                  ConfirmationDialogBoxComponent,
                  {
                    panelClass: "no-padding-dialog-popup",
                    width: "380px",
                    data: {
                      subject: `CAN NOT DELETE  ${itemType.toUpperCase()} ${(index + 1)}`,
                      message: error.error.body,
                      successButtonText: "Ok",
                    }
                  }
                );
              }
            );
          } else {
            const dialogref = this.dialog.open(
              ConfirmationDialogBoxComponent,
              {
                panelClass: "no-padding-dialog-popup",
                width: "280px",
                data: {
                  subject: `DELETE  ${itemType.toUpperCase()} ${(index + 1)}`,
                  message: `Are you sure you want to delete the ${itemType} ${(index + 1)} setup ? All data filled for this ${itemType} will be lost once deleted.`,
                  cancelButtonText: this._i18nDynamicTranslate.transform("Cancel", ['POS_PAGE']),
                  successButtonText: this._i18nDynamicTranslate.transform("Delete", ['POS_PAGE']),
                }
              }
            );
            dialogref.afterClosed().subscribe((result) => {
              if(result) {
                this.deleteTier(index);
              }
            });
          }
        }

    }
    if(allowDelete && itemType === 'rule') {
      const dialogref = this.dialog.open(
        ConfirmationDialogBoxComponent,
        {
          panelClass: "no-padding-dialog-popup",
          width: "280px",
          data: {
            subject: `DELETE  ${itemType.toUpperCase()} ${(index + 1)}`,
            message: `Are you sure you want to delete the ${itemType} ${(index + 1)} setup ? All data filled for this ${itemType} will be lost once deleted.`,
            cancelButtonText: this._i18nDynamicTranslate.transform("Cancel", ['POS_PAGE']),
            successButtonText: this._i18nDynamicTranslate.transform("Delete", ['POS_PAGE']),
          }
        }
      );
      dialogref.afterClosed().subscribe((result) => {
        if(result) this.deleteRule(index);
      });
    }
  }
  datePicked(rule,setTofromOrTill) {
    let year,month,day;
    switch(setTofromOrTill) {
      case 'from':
        year = rule.validFromDateObj.getFullYear();
        month = String(rule.validFromDateObj.getMonth() + 1).padStart(2, '0');
        day = String(rule.validFromDateObj.getDate()).padStart(2, '0');
        rule.validFrom = `${year}-${month}-${day}`;
        if(rule.validTillDateObj && (rule.validTillDateObj<rule.validFromDateObj)) {
          rule.validTillDateObj = null;
          rule.validTill = null;
        }
        break;
      case 'till':
        year = rule.validTillDateObj.getFullYear();
        month = String(rule.validTillDateObj.getMonth() + 1).padStart(2, '0');
        day = String(rule.validTillDateObj.getDate()).padStart(2, '0');
        rule.validTill = `${year}-${month}-${day}`;
        break;

    }
  }

  setBasicRuleEventDetail(rule) {
    rule.ruleDefinition.eventCount = rule.ruleDefinition.eventDropdownObj.defaultValue?rule.ruleDefinition.eventDropdownObj.defaultValue:rule.ruleDefinition.eventCount;
    rule.ruleDefinition.event = rule.ruleDefinition.eventDropdownObj.name;
    if(rule.ruleDefinition.eventCount && rule.ruleDefinition.eventCount.toString().includes('.')) {
      rule.ruleDefinition.eventCount = null;
    }
    //clearing for exceptions
    if(rule.ruleDefinition.event != '$amount_spent') {
      if(rule['inclusionRule']) {
        delete rule.inclusionRule;
      }
      rule.excludedProductCategories = [];
      rule.excludedProducts = [];
    }
    if(rule.ruleDefinition.event != '$order_placed' && rule.ruleDefinition.event != '$amount_spent') {
      rule.excludedOrderCategories = [];
    }
    if(rule.ruleDefinition.event == '$milestone_reached') {
      rule.isRecursive = false;
    } else {
      rule.isRecursive = true;
    }
    //clearing for exceptions
    if(rule.ruleDefinition.event != '$amount_spent') {
      if(rule['inclusionRule']) {
        delete rule.inclusionRule;
      }
      rule.excludedProductCategories = [];
      rule.excludedProducts = [];
    }
  }

  initializeBasicRuleDetails(rule,optionalEventName?) {
    
    //check if value is present to prepopulate
    if(rule.oldRuleDefinition && rule.oldRuleDefinition && rule.oldRuleDefinition.event) {
      let temp = rule.ruleDefinition;
      rule.ruleDefinition = rule.oldRuleDefinition;
      rule.oldRuleDefinition = temp;//temp save other rule type values if present
      this.ruleEventDropdownValues.forEach((curDropdownVal)=>{
        if(curDropdownVal.name == rule.ruleDefinition.event) {
          rule.ruleDefinition.eventDropdownObj = curDropdownVal;
          return;
        }
      });
    } else if(!rule.ruleDefinition || !rule.ruleDefinition.event) {
      rule.oldRuleDefinition = rule.ruleDefinition;//temp save other rule type values if present
      rule.ruleDefinition = new RuleDefinition();
      rule.ruleDefinition.eventDropdownObj = optionalEventName?this.ruleEventDropdownValues.find(ruleEventDv=>ruleEventDv.name == optionalEventName):this.ruleEventDropdownValues[0];
      rule.ruleDefinition.eventCount = rule.ruleDefinition.eventDropdownObj.defaultValue;
      rule.ruleDefinition.event = rule.ruleDefinition.eventDropdownObj.name;
      
    } else {
      this.ruleEventDropdownValues.forEach((curDropdownVal)=>{
        if(curDropdownVal.name == rule.ruleDefinition.event) {
          rule.ruleDefinition.eventDropdownObj = curDropdownVal;
          return;
        }
      });
      //special case for amount spent
      if(rule.ruleDefinition.event == '$amount_spent') {
        if(rule.excludedProducts?.length>0 || rule.excludedProductCategories?.length>0) {
          rule.hasExclusion = true;
        }
      }
    } 
  }
  
  initializeAdvanceCustomRuleDetails(rule) {
    
    //check if its a rule with no logical exp data or if its segment rule with logical exp data. If so clear it
    if( rule.oldRuleDefinition && rule.oldRuleDefinition.loyaltyRuleSegmentDefinition && rule.oldRuleDefinition.loyaltyRuleSegmentDefinition.logicalExpressions.length) {
      let temp = rule.ruleDefinition;
      rule.ruleDefinition = rule.oldRuleDefinition;
      rule.oldRuleDefinition = temp;//temp save other rule type values if present
      rule.ruleDefinition.loyaltyRuleSegmentDefinition.logicalExpressions.forEach((logicalExpress)=>{
        logicalExpress.comparisonStatements.forEach((comparisonStatement)=>{
          this.ruleEventDropdownValues.forEach((curDropdownVal)=>{
            if(curDropdownVal.name == comparisonStatement.operand1) {
              comparisonStatement.eventDropdownObj = curDropdownVal;
              return;
            }
          });
        });
      });
      for(let freqObj of this.ruleFrequencies as any) {
        if(rule.ruleFrequency === freqObj.value) {
          rule['ruleFrequencyDisplay'] = freqObj.key;
        }
      }
    } else if(!rule.ruleDefinition.loyaltyRuleSegmentDefinition || !rule.ruleDefinition.loyaltyRuleSegmentDefinition.logicalExpressions.length) {
      rule.oldRuleDefinition = rule.ruleDefinition;//temp save other rule type values if present
      rule.ruleDefinition = new RuleDefinitionAdvancedCustom();
      rule.ruleDefinition.loyaltyRuleSegmentDefinition.logicalExpressions.push(new ComparisonStatementsList());
      rule.ruleDefinition.loyaltyRuleSegmentDefinition.logicalExpressions[0].comparisonStatements.push(new ComparisonStatement());
      rule.ruleDefinition.loyaltyRuleSegmentDefinition.logicalExpressions[0].comparisonStatements[0].eventDropdownObj = this.ruleEventDropdownValues[0];
      rule.ruleDefinition.loyaltyRuleSegmentDefinition.logicalExpressions[0].comparisonStatements[0].operand1 = this.ruleEventDropdownValues[0].name;
      if(rule.ruleType === 'EARN') {
        rule.ruleDefinition.loyaltyRuleSegmentDefinition.logicalExpressions[0].comparisonStatements[0].operator = 'is greater than or equal to'
      } else if(rule.ruleType === 'EXPIRE') {
        rule.ruleDefinition.loyaltyRuleSegmentDefinition.logicalExpressions[0].comparisonStatements[0].operator = 'is less than or equal to'
      }
      Object.assign(rule, { ruleFrequency: this.ruleFrequencies[0].value});
      Object.assign(rule, { ruleFrequencyDisplay: this.ruleFrequencies[0].key});
    } else {
      rule.ruleDefinition.loyaltyRuleSegmentDefinition.logicalExpressions.forEach((logicalExpress)=>{
        logicalExpress.comparisonStatements.forEach((comparisonStatement)=>{
          this.ruleEventDropdownValues.forEach((curDropdownVal)=>{
            if(curDropdownVal.name == comparisonStatement.operand1) {
              comparisonStatement.eventDropdownObj = curDropdownVal;
              return;
            }
          });
        });
      });
      for(let freqObj of this.ruleFrequencies as any) {
        if(rule.ruleFrequency === freqObj.value) {
          rule['ruleFrequencyDisplay'] = freqObj.key;
        }
      }
    }
  }
  setRuleFrequencyDisplay(rule) {
    for(let freqObj of this.ruleFrequencies as any) {
      if(rule.ruleFrequency === freqObj.value) {
        rule['ruleFrequencyDisplay'] = freqObj.key;
      }
    }
  }
  onBasicPanelOpened(rule) {
    rule.ruleCategory = 'BASIC';
    rule.ruleType = 'EARN';
    this.initializeBasicRuleDetails(rule);
  }
  onAdvancePanelOpened(rule) {
    rule.ruleCategory='ADVANCE_CUSTOM';
    this.initializeAdvanceCustomRuleDetails(rule);
  }
  setEventForRule(comparisonStatement) {
    comparisonStatement.operand1 = comparisonStatement.eventDropdownObj.name;
    comparisonStatement.operand2 = comparisonStatement.eventDropdownObj.defaultValue?comparisonStatement.eventDropdownObj.defaultValue:comparisonStatement.operand2;
    if(comparisonStatement.operand2 && comparisonStatement.operand2.toString().includes('.')) {
      comparisonStatement.operand2 = null;
    }
  }
  
  deleteComparisonStatement(compIndex,logicalGroup) {
    logicalGroup.comparisonStatements.splice(compIndex,1);
  }
  addNewComparisonStatement(logicalGroup,rule) {
    let tempComp = new ComparisonStatement();
    //check if the new comparison statement is first in the logical group and only then add logical operator
    if(logicalGroup.comparisonStatements.length>=1) {
      Object.assign(tempComp, { logicalOperator: 'AND'});
    }
    tempComp.eventDropdownObj = this.ruleEventDropdownValues[0];
    tempComp.operand1 = this.ruleEventDropdownValues[0].name;
    if(rule.ruleType === 'EARN') {
      tempComp.operator = 'is greater than or equal to';
    } else if(rule.ruleType === 'EXPIRE') {
      tempComp.operator = 'is less than or equal to';
    }
    logicalGroup.comparisonStatements.push(tempComp); 
  }

  selectCondition(logicalOp,logicalGroup) {
    for(let i=1;i<=logicalGroup.comparisonStatements.length-1;i++) {
      logicalGroup.comparisonStatements[i].logicalOperator = logicalOp;
    }
  }
  addNewLogicalGroup(rule) {
    let tempLogicalGroup = new ComparisonStatementsList();
    this.addNewComparisonStatement(tempLogicalGroup,rule);
    Object.assign(tempLogicalGroup, { logicalOperator: 'AND'});
    rule.ruleDefinition.loyaltyRuleSegmentDefinition.logicalExpressions.push(tempLogicalGroup);
    
  }
  selectConditionForLogicalGroup(logicalOp,logicalGroup) {
    logicalGroup.logicalOperator = logicalOp;
  }
  deleteLogicalGroup(ruleIndex,logicalExpIndex) {
    this.loyaltyRules[ruleIndex].ruleDefinition.loyaltyRuleSegmentDefinition.logicalExpressions.splice(logicalExpIndex,1);
  }
  discardForm() {
    this._router.navigate(['/app/loyalty']);
  }

  initializeDataToSave(dataToSave) {
    dataToSave['partialUpdate'] = true;
    dataToSave['rewardPoints'] = this.loyaltyPoints; //save rewardPoints
    dataToSave['tierConfig'] = {};
    dataToSave['tierConfig']['tierMap'] = this.tierMap; //save tierMap
    dataToSave['ruleConfig'] = {};
    dataToSave['ruleConfig']['loyaltyRules']  = this.loyaltyRules; //save rules

    //new flags
    if( this.loyaltyProgramConfig?.hasOwnProperty('isSubscribedToGoogelWallet'))  {
      dataToSave['isSubscribedToGoogelWallet'] = this.loyaltyProgramConfig.isSubscribedToGoogelWallet;
    }
    if( this.loyaltyProgramConfig?.hasOwnProperty('isSubscribedToAppleWallet'))  {
      dataToSave['isSubscribedToAppleWallet'] = this.loyaltyProgramConfig.isSubscribedToAppleWallet;
    }
    if( this.loyaltyProgramConfig?.hasOwnProperty('isLoyaltyCampaignActive'))  {
      dataToSave['isLoyaltyCampaignActive'] = this.loyaltyProgramConfig.isLoyaltyCampaignActive;
    }

    //check for isactive
    if(!this.loyaltyProgramConfig) {
      dataToSave['loyaltyProgramActive'] = true;
    } else {
      //check if its false due to null or undefined or if its actually boolean false
      if(this.loyaltyProgramConfig.loyaltyProgramActive === false) {
        dataToSave['loyaltyProgramActive'] = false;
      } else {
        dataToSave['loyaltyProgramActive'] = true;
      }
    } 
  }

  saveLoyaltyProgramConfig(dataToSave) {
    this._apicall.saveLoyaltyProgramConfig(dataToSave).subscribe(
      response=>{
        this._snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Saved loyalty program", ['POS_PAGE']),3000);
        this._router.navigate(['/app/loyalty']);
      },
      error=>{
        this._snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Error saving loyalty program", ['POS_PAGE']),2000);
      }
    );
  }
  

  validateAllStepsAndSave() {
    if(!this.validateBeforeGoingTo(2)){
      this.goToStep(1,false,true);
    } else {
      if(!this.validateBeforeGoingTo(3)) {
        this.goToStep(2,false,true);
      } else {
        if(!this.validateBeforeGoingTo(4)) {
          this.goToStep(3,false,true);
        } else {
          this.confirmAndSaveLoyaltyConfig();
        }
      }
    }
  }
  saveForm() {
    // saveLoyaltyProgramConfig
    let dataToSave = {};
    this.initializeDataToSave(dataToSave);
    //Check if loyalty program has been configured completly
    //if so validate all fields and save the data fully
    //else perform partial save which doesnt need validation
    if(this.loyaltyProgramConfig && !this.loyaltyProgramConfig.partialUpdate){
        this.validateAllStepsAndSave();
    } else {
      this.saveLoyaltyProgramConfig(dataToSave);
      // console.log(dataToSave);
    }
  }

  confirmAndSaveLoyaltyConfig() {
    let dataToSave = {};
    this.initializeDataToSave(dataToSave);
    dataToSave['partialUpdate'] = false;
    this.saveLoyaltyProgramConfig(dataToSave);
  }
  numberInputHandler(event: KeyboardEvent,maxLength,inputType?) {

    const inputValue = (event.target as HTMLInputElement).value;
    const decimalPlaces = 2;
    if (inputType.toLowerCase() != 'float') {
      const invalidCharacters = ['e', '-', '+', '.'];
      if ((inputValue.length >= maxLength && event.key !== 'Backspace') || invalidCharacters.includes(event.key)) {
        event.preventDefault();
      }
    } else {
      const invalidCharacters = ['e', '-', '+']
      const dotIndex = inputValue.indexOf('.');
      if (dotIndex !== -1 && inputValue.substring(dotIndex + 1).length >= decimalPlaces && event.key !== 'Backspace') {
        event.preventDefault();
      } else {
        if ((inputValue.length >= maxLength && event.key !== 'Backspace') || invalidCharacters.includes(event.key)) {
          event.preventDefault();
        }
      }
    }
  }
  changeOperandValue(curRule) {
    for(let curLogicalGroup of curRule.ruleDefinition.loyaltyRuleSegmentDefinition.logicalExpressions) {
      for(let comparisonStatement of curLogicalGroup.comparisonStatements) {
        if(curRule.ruleType === 'EARN') {
          comparisonStatement.operator = 'is greater than or equal to';
        } else if(curRule.ruleType === 'EXPIRE') {
          comparisonStatement.operator = 'is less than or equal to';
          curRule.hasOffers = false;
          curRule.offerIds = [];
          curRule.hasRewardPoints = true;
        }
      }
    }
  }
  getDateFormat(type?){
    return this.date_format.getDateFormat(type);
                }
  changeRedemptionType(type: string,tier) {
    tier.coinRedemptionLimit.type = type;
    tier.coinRedemptionLimit.value = null;
  }
  checkExpiry(tier) {
    if(tier.loyaltyPointsExpiryConfig) {
      tier.loyaltyPointsExpiryConfig = null;
      tier.expiryConfigForPushNotification = null;
    } else {
      tier.loyaltyPointsExpiryConfig = new LoyaltyPointsExpiryConfig();
    }
  }

  checkNotification(tier) {
    if(tier.loyaltyPointsExpiryConfig) {
      if(tier.expiryConfigForPushNotification) {
        tier.expiryConfigForPushNotification = null;
      } else {
        tier.expiryConfigForPushNotification = new LoyaltyPointsExpiryConfig();
      }
    }
  }

  getOfferNameFromId(id) {
    if(this.offerList.length>1) {
      return (this.offerList.find(obj=>obj.id == id)).offerName;
    } else {
      return null;
    }
  }

  onHasRewardPointsChange(checkBoxVal,rule) {
    this.resetErrorState();
    if(!checkBoxVal) {
      rule.rewardPoints = null;
    } 
  }
  onHasOffersChange(checkBoxVal,rule) {
    this.resetErrorState();
    if(!checkBoxVal) {
      rule.offerIds = [];
    } 
  }

  showMultiselectDropDownFor = '';
  showMultiselectDropdown(showMultiSelectDropDownFor) {
    this.showMultiselectDropDownFor= this.showMultiselectDropDownFor?'':showMultiSelectDropDownFor;
    if(!this.showMultiselectDropDownFor) this.dropdownSearch = '';
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    this.showMultiselectDropDownFor = '';
    this.dropdownSearch = '';
  }

  dropdownSearch = '';
  filteredOfferList = [];
  getFilteredOfferList() {
    if(this.offerList?.length>0 && (this.dropdownSearch || this.dropdownSearch=='')) {
      this.filteredOfferList = this.offerList.filter(obj => obj.offerName.toLowerCase().includes(this.dropdownSearch.toLowerCase())); 
    }
  }


  filteredApplicableCustomerGroupList = [];
  getFilteredApplicableCustomerGroupList() {
    if(this.applicableCustomerGroupList?.length>0 && (this.dropdownSearch || this.dropdownSearch=='')) {
      this.filteredApplicableCustomerGroupList = this.applicableCustomerGroupList.filter(obj => obj.name.toLowerCase().includes(this.dropdownSearch.toLowerCase())); 
    }
  }

  filteredOrderCategoriesList = [];
  getFilteredOrderCategoriesList() {
    if(this.orderCategoriesList?.length>0 && (this.dropdownSearch || this.dropdownSearch=='')) {
      this.filteredOrderCategoriesList = this.orderCategoriesList.filter(obj => obj.name.toLowerCase().includes(this.dropdownSearch.toLowerCase())); 
    }
  }
  

  filteredProductList = [];
  getFilteredProductList() {
    if(this.apiProductList?.length>0 && (this.dropdownSearch || this.dropdownSearch=='')) {
      this.filteredProductList = this.apiProductList.filter(product =>  {
        // if(rule.excludedProducts.filter(ruleExclusionProduct => innerRule.includedProducts.some(innerInclusionProduct=>innerInclusionProduct==) 
        return product.name.toLowerCase().includes(this.dropdownSearch.toLowerCase());
      }); 
    }
  }

  filteredCategoryList = [];
  getFilteredCategoryList() {
    if(this.apiProductCategoryList?.length>0 && (this.dropdownSearch || this.dropdownSearch=='')) {
      this.filteredCategoryList = this.apiProductCategoryList.filter(cat => cat.name.toLowerCase().includes(this.dropdownSearch.toLowerCase())); 
    }
  }

  setListFlags(rule,innerRule,listType,subRuleTypeOfDropdown) {
    if(listType == 'applicableGroups' && !subRuleTypeOfDropdown) {
      if(rule.isAllCustomers) {
        this.applicableCustomerGroupList.forEach(obj=>{
          if(obj.category == 'ALL') {
            obj.checked = true;
          } else {
            obj.checked = false;
            obj.disabled = true;
          }
        });
      } else {
        this.applicableCustomerGroupList.forEach(obj=>{
          obj.disabled = false;
          if(obj.category == 'ALL') {
            obj.checked = false;
          }
          if(obj.category == 'tier') {
            obj.checked = rule.tiersApplicableTo?.some(tierObj=>obj.name == tierObj.name);
          }
          if(obj.category == 'segment') {
            obj.checked = rule.segmentsApplicableTo?.includes(obj.id);
          }
          if(obj.category == 'customerlist') {
            obj.checked = rule.customerListApplicableTo?.includes(obj.id);
          }
          if(obj.category == 'employeelist') {
            obj.checked = rule.employeeListApplicableTo?.includes(obj.id);
          }
        });
      }
    }
    if(listType == 'orderCategories' && !subRuleTypeOfDropdown) {
      this.orderCategoriesList.forEach(orderCat=>{
        if(rule.excludedOrderCategories.includes(orderCat.name)) {
          orderCat.checked = true;
        } else {
          orderCat.checked = false;
        }
      });
      this.filteredOrderCategoriesList = this.orderCategoriesList;
    }
    if(subRuleTypeOfDropdown == 'inclusion') {
      if(listType == 'product') {
        this.apiProductList.forEach(product=>{
          if(innerRule.includedProducts.includes(product.name)) {
            product.disabled = false;
            product.checked = true;
          } else if(rule.excludedProducts.includes(product.name)) {
            product.disabled = true;
            product.checked = false;
          } else {
            product.disabled = false;
            product.checked = false;
          }
        });
        this.filteredProductList = this.apiProductList;
      } else {
        this.apiProductCategoryList.forEach(cat=>{
          if(innerRule.includedProductCategories.includes(cat.name)) {
            cat.disabled = false;
            cat.checked = true;
          } else if(rule.excludedProductCategories.includes(cat.name)) {
            cat.disabled = true;
            cat.checked = false;
          } else {
            cat.disabled = false;
            cat.checked = false;
          }
        });
        this.filteredCategoryList = this.apiProductCategoryList;
      }
    }  
    if(subRuleTypeOfDropdown == 'exclusion') {
      if(listType == 'product') {
        const allInclusionRuleProducts = rule.inclusionRule.flatMap(innerRule => innerRule.includedProducts);
        this.apiProductList.forEach(product=>{
          if(allInclusionRuleProducts.includes(product.name)) {
            product.disabled = true;
            product.checked = true;
          } else if(rule.excludedProducts.includes(product.name)) {
            product.disabled = false;
            product.checked = true;
          } else {
            product.disabled = false;
            product.checked = false;
          }
        });
        this.filteredProductList = this.apiProductList;
      } else {
        const allInclusionRuleCategories = rule.inclusionRule.flatMap(innerRule => innerRule.includedProductCategories);
        this.apiProductCategoryList.forEach(cat=>{
          if(allInclusionRuleCategories.includes(cat.name)) {
            cat.disabled = true;
            cat.checked = true;
          } else if(rule.excludedProductCategories.includes(cat.name)) {
            cat.disabled = false;
            cat.checked = true;
          } else {
            cat.disabled = false;
            cat.checked = false;
          }
        });
        this.filteredCategoryList = this.apiProductCategoryList;
      }
    }
  }

  getExplicitlyExcludedProductNames(rule) {
    let allInclusionRuleProducts = [];
    if(rule.inclusionRule?.length>0) {
      const allInclusionRuleProducts = rule.inclusionRule.flatMap(innerRule => innerRule.includedProducts);
      return rule.excludedProducts.filter(productName=>!allInclusionRuleProducts.includes(productName));
    }
    return rule.excludedProducts;
  }

  getExplicitlyExcludedCategoryNames(rule) {
    let allInclusionRuleCategories = [];
    if(rule.inclusionRule?.length>0) {
      const allInclusionRuleCategories = rule.inclusionRule.flatMap(innerRule => innerRule.includedProductCategories);
      return rule.excludedProductCategories.filter(categoryName=>!allInclusionRuleCategories.includes(categoryName));
    }
    return rule.excludedProductCategories;
  }

  selectRuleOffer(offer,rule) {
    this.resetErrorState();
    if(rule.offerIds.includes(offer.id)) {
      rule.offerIds = rule.offerIds.filter(id => id != offer.id);
    } else {
      rule.offerIds.push(offer.id);
    }
  }

  selectApplicableGroup(groupObj,rule) {
    this.resetErrorState();
    if(groupObj.category == 'ALL') {
      if(rule['isAllCustomers']) {
        rule['isAllCustomers'] = false;
        groupObj.checked = false;
        this.applicableCustomerGroupList.forEach((each)=>each.disabled = false);
      } else {
        rule['isAllCustomers'] = true;
        groupObj.checked = true;
        this.applicableCustomerGroupList.forEach((each)=>each.disabled = each.category!='ALL'?true:false);
        //clear the selections
        if(rule.tiersApplicableTo && rule.tiersApplicableTo.length>0) {
          for(let i=0;i<rule.tiersApplicableTo.length;i++) {
            for(let dropdownTier of this.applicableCustomerGroupList.filter(t=>t.category == 'tier')) {
              if(rule.tiersApplicableTo[i].name == dropdownTier.name) {
                dropdownTier.checked = false;
                break;
              }
            }
          }
          rule.tiersApplicableTo = [];
        }
        if(rule.segmentsApplicableTo && rule.segmentsApplicableTo.length>0) {
          for(let i=0;i<rule.segmentsApplicableTo.length;i++) {
            for(let dropdownSegment of this.applicableCustomerGroupList.filter(s=>s.category == 'segment')) {
              if(rule.segmentsApplicableTo[i] == dropdownSegment.id) {
                dropdownSegment.checked = false;
                break;
              }
            }
          }
          rule.segmentsApplicableTo = [];
        }
        if(rule.customerListApplicableTo && rule.customerListApplicableTo.length>0) {
          for(let i=0;i<rule.customerListApplicableTo.length;i++) {
            for(let dropdownCL of this.applicableCustomerGroupList.filter(s=>s.category == 'customerList')) {
              if(rule.customerListApplicableTo[i] == dropdownCL.id) {
                dropdownCL.checked = false;
                break;
              }
            }
          }
          rule.customerListApplicableTo = [];
        }
        if(rule.employeeListApplicableTo && rule.employeeListApplicableTo.length>0) {
          for(let i=0;i<rule.employeeListApplicableTo.length;i++) {
            for(let dropdownEL of this.applicableCustomerGroupList.filter(s=>s.category == 'employeeList')) {
              if(rule.employeeListApplicableTo[i] == dropdownEL.id) {
                dropdownEL.checked = false;
                break;
              }
            }
          }
          rule.employeeListApplicableTo = [];
        }
      }
    }
    if(groupObj.category == 'tier') {
      let tierObject = rule.tiersApplicableTo?.find(tier=>tier.name==groupObj.name); 
      if(tierObject) {
        rule.tiersApplicableTo = rule.tiersApplicableTo.filter(tier => tier.name != tierObject.name);
        groupObj.checked = false;
      } else {
        if(rule['tiersApplicableTo']) {
          rule['tiersApplicableTo'].push(groupObj);
        } else {
          rule['tiersApplicableTo'] = [groupObj];
        }
        groupObj.checked = true;
      }
    }
    if(groupObj.category == 'segment') {
      if(rule.segmentsApplicableTo?.includes(groupObj.id)) {
        rule.segmentsApplicableTo = rule.segmentsApplicableTo.filter(id => id != groupObj.id);
        groupObj.checked = false;
      } else {
        if(rule['segmentsApplicableTo']) {
          rule['segmentsApplicableTo'].push(groupObj.id);
        } else {
          rule['segmentsApplicableTo'] = [groupObj.id];
        }
        groupObj.checked = true;
      }
    }
    if(groupObj.category == 'customerlist') {
      if(rule.customerListApplicableTo?.includes(groupObj.id)) {
        rule.customerListApplicableTo = rule.customerListApplicableTo.filter(id => id != groupObj.id);
        groupObj.checked = false; 
      } else {
        if(rule['customerListApplicableTo']) {
          rule['customerListApplicableTo'].push(groupObj.id);
        } else {
          rule['customerListApplicableTo'] = [groupObj.id];
        }
        groupObj.checked = true;
      }
    }
    if(groupObj.category == 'employeelist') {
      if(rule.employeeListApplicableTo?.includes(groupObj.id)) {
        rule.employeeListApplicableTo = rule.employeeListApplicableTo.filter(id => id != groupObj.id);
        groupObj.checked = false;
      } else {
        if(rule['employeeListApplicableTo']) {
          rule['employeeListApplicableTo'].push(groupObj.id);
        } else {
          rule['employeeListApplicableTo'] = [groupObj.id];
        }
        groupObj.checked = true;
      }
    }
  }

  selectOrderCategories(orderCat,rule) {
    this.resetErrorState();
    if(rule.excludedOrderCategories?.includes(orderCat.name)) {
      const index1 = rule.excludedOrderCategories.indexOf(orderCat.name);
      rule.excludedOrderCategories.splice(index1, 1);
      orderCat.checked = false;
    } else {
      if(rule['excludedOrderCategories']?.length>0) {
        rule['excludedOrderCategories'].push(orderCat.name);
      } else {
        rule['excludedOrderCategories'] = [orderCat.name];
      }
      orderCat.checked = true;
    }
  }

  selectProduct(product,rule,innerRule,isInclude) {
    this.resetErrorState();
    if(isInclude) {
      if(innerRule.includedProducts?.includes(product.name)) {
        const index1 = innerRule.includedProducts.indexOf(product.name);
        innerRule.includedProducts.splice(index1, 1);
        const index2 = rule.excludedProducts.indexOf(product.name);
        rule.excludedProducts.splice(index2,1);  
        product.checked = false;
      } else {
        innerRule.includedProducts.push(product.name);
        if(rule['excludedProducts']?.length>0) {
          rule['excludedProducts'].push(product.name);
        } else {
          rule['excludedProducts'] = [product.name];
        }
        product.checked = true;
      }
    } else {
      if(rule.excludedProducts?.includes(product.name)) {
        const index2 = rule.excludedProducts.indexOf(product.name);
        rule.excludedProducts.splice(index2,1);
        product.checked = false;
      } else {
        rule.excludedProducts.push(product.name);
        product.checked = true;
      }
    }
  }

  selectProductCategories(category,rule,innerRule,isInclude) {
    this.resetErrorState();
    if(isInclude) {
      if(innerRule.includedProductCategories?.includes(category.name)) {
        const index1 = innerRule.includedProductCategories.indexOf(category.name);
        innerRule.includedProductCategories.splice(index1, 1);
        const index2 = rule.excludedProductCategories.indexOf(category.name);
        rule.excludedProductCategories.splice(index2,1);
        category.checked = false;
      } else {
        innerRule.includedProductCategories.push(category.name);
        if(rule['excludedProductCategories']?.length>0) {
          rule['excludedProductCategories'].push(category.name);
        } else {
          rule['excludedProductCategories'] = [category.name];
        }
        category.checked = true;
      }
    } else {
      if(rule.excludedProductCategories?.includes(category.name)) {
        const index2 = rule.excludedProductCategories.indexOf(category.name);
        rule.excludedProductCategories.splice(index2, 1);
        category.checked = false;
      } else {
        rule.excludedProductCategories.push(category.name);
        category.checked = true;
      }
    }
  }

  getRuleOfferDisplay(offerIds) {
    if(this.offerList?.length == 0) return 'No Offers';
    else {
      if(offerIds?.length == 0) return 'Select Offer(s)';
      else {
        let res = '';
        offerIds.forEach((id) => {
          let offerName = this.offerList.find((offer) => offer.id === id)?.offerName;
          res += (res === '' ? '' : ', ') + offerName;
        });
        return res;
      }
    } 
  }

  getRuleApplicableGroupsDisplay(rule) {
    if(this.applicableCustomerGroupList?.length == 0) return 'No Applicable Groups';
    else {
      if(rule.isAllCustomers) return 'My Customers';
      if((!rule.tiersApplicableTo||rule.tiersApplicableTo.length==0) 
          && (!rule.segmentsApplicableTo||rule.segmentsApplicableTo.length==0) 
          && (!rule.customerListApplicableTo|| rule.customerListApplicableTo.length==0) 
          && (!rule.employeeListApplicableTo|| rule.employeeListApplicableTo==0)) return 'Select Applicable Group';
      else {
        let res = '';
        rule.tiersApplicableTo?.forEach((obj) => {
          res += (res === '' ? '' : ', ') + obj.name;
        });
        rule.segmentsApplicableTo?.forEach((id) => {
          let name = this.applicableCustomerGroupList.find((listItem) => listItem.id === id)?.name;
          res += (res === '' ? '' : ', ') + name;
        });
        rule.customerListApplicableTo?.forEach((id) => {
          let name = this.applicableCustomerGroupList.find((listItem) => listItem.id === id)?.name;
          res += (res === '' ? '' : ', ') + name;
        });
        rule.employeeListApplicableTo?.forEach((id) => {
          let name = this.applicableCustomerGroupList.find((listItem) => listItem.id === id)?.name;
          res += (res === '' ? '' : ', ') + name;
        });
        return res;
      }
    } 
  }

  getOrderCategoriesDisplay(list) {
      if(!list || list.length === 0) return 'Select Order Categories';
      else {
        return list.join();
      }
  }

  getProductListDisplay(productList) {
    if(this.apiProductList?.length == 0) return 'No Products';
    else {
      if(productList?.length == 0) return 'Select Product(s)';
      else {
        return productList.join();
      }
    }
  }

  getCategoryListDisplay(categoryList) {
    if(this.apiProductCategoryList?.length == 0) return 'No Category';
    else {
      if(categoryList?.length == 0) return 'Select Category(s)';
      else {
        return categoryList.join();
      }
    }
  }

  addInclusionRule(rule) {
    rule['inclusionRule'] = rule['inclusionRule']?.length>0?rule['inclusionRule']:[];
    let newSubRule = new LoyaltyRule();
    rule['inclusionRule'].push(newSubRule);
    //basic rule has to be created with amount spent event for inner rule
    this.initializeBasicRuleDetails(rule['inclusionRule'][rule['inclusionRule'].length-1],'$amount_spent');
    // rule['includedProducts'] = rule['includedProducts']?rule['includedProducts']:[];
    // rule['includedProductCategories'] = rule['includedProductCategories']?rule['includedProductCategories']:[];
    rule['excludedProducts'] = rule['excludedProducts']?rule['excludedProducts']:[];
    rule['excludedProductCategories'] = rule['excludedProductCategories']?rule['excludedProductCategories']:[];
    rule.hasExclusion = true;
  }

  checkDisableProduct(rule,innerRule,productName) {
    if (innerRule.includedProducts.includes(productName)) {
      return false;
    } else if (rule.excludedProducts?.includes(productName)) {
      return true;
    } else {
     return false;
    }
  }

  trackByProductName(index: number, product): string {
    return product.name;
  }

  trackByCategoryName(index: number, category): string {
    return category.name;
  }

  isProductIncluded(innerRule: any, productName: string): boolean {
    return innerRule.includedProducts?.includes(productName);
  }
  
  isCategoryIncluded(innerRule: any, categoryName: string): boolean {
    return innerRule.includedProductCategories?.includes(categoryName);
  }

  deleteInclusionRule(rule,innerRuleIndex) {
    rule.excludedProducts = rule.excludedProducts.filter(eachProductName => !rule.inclusionRule[innerRuleIndex].includedProducts.includes(eachProductName));
    rule.excludedProductCategories = rule.excludedProductCategories.filter(eachCatName => !rule.inclusionRule[innerRuleIndex].includedProductCategories.includes(eachCatName));
    rule.inclusionRule.splice(innerRuleIndex,1);
  }

  deleteExclusion(rule) {
    rule.hasExclusion = false;
    rule.excludedProducts = [];
    rule.excludedProductCategories = [];
  }

  getRankNumberArray(): number[] {
    return Array.from({ length: this.loyaltyRules.length }, (_, i) => i + 1);
  }

  getNextPrioritRank() {
    return this.loyaltyRules?.length>0?this.loyaltyRules.length+1:1;
  }
  
  scrollToNext(type) {
    let container;
    let items;
    if(type == 'rule') {
      items = Array.from(document.querySelectorAll('.each-rule')) as HTMLElement[];
      container = document.querySelector('.step-3');
    } else {
      items = Array.from(document.querySelectorAll('.each-tier')) as HTMLElement[];
      container = document.querySelector('.step-2');
    }
    
    if (!container) return; // Exit if the container doesn't exist
  
    const containerRect = container.getBoundingClientRect();
  
    if (!items.length) return; // Exit if no elements found
  
    // Find the index of the first visible item
    const currentIndex = items.findIndex((rule) => {
      const rect = rule.getBoundingClientRect();
      // Check if the items top is within the parent's visible height
      return rect.bottom >= containerRect.top;
    });
  
    if (currentIndex !== -1) {
      // Get the next item in the list
      const prevRule = items[currentIndex + 1];
      if (prevRule) {
        // Scroll to the next 
        prevRule.scrollIntoView({ behavior: 'smooth', block: 'start' });
      } else {
        this._snackBar.openSnackBar(`No next ${type} to scroll to`, 2000);
      }
    } else {
      this._snackBar.openSnackBar(`No ${type} on screen`, 2000);
    }
  }

  scrollToPrev(type) {
    let container;
    let items;
    if(type == 'rule') {
      items = Array.from(document.querySelectorAll('.each-rule')) as HTMLElement[];
      container = document.querySelector('.step-3');
    } else {
      items = Array.from(document.querySelectorAll('.each-tier')) as HTMLElement[];
      container = document.querySelector('.step-2');
    }
    
    if (!container) return; // Exit if the container doesn't exist
  
    const containerRect = container.getBoundingClientRect();
  
    if (!items.length) return; // Exit if no elements found
  
    // Find the index of the first visible item
    const currentIndex = items.findIndex((rule) => {
      const rect = rule.getBoundingClientRect();
      // Check if the items top is within the parent's visible height
      return rect.bottom >= containerRect.top;
    });
  
    if (currentIndex !== -1) {
      // Get the prev item in the list
      const prevRule = items[currentIndex - 1];
      if (prevRule) {
        // Scroll to the prev 
        prevRule.scrollIntoView({ behavior: 'smooth', block: 'start' });
      } else {
        this._snackBar.openSnackBar(`No previous ${type} to scroll to`, 2000);
      }
    } else {
      this._snackBar.openSnackBar(`No ${type} on screen`, 2000);
    }
  }

  // isCampaignOnlyRulePresent() {
  //   for(let rule of this.loyaltyRules) {
  //     if(rule.isCampaignRule)
  //       return true;
  //   }
  //   return false;
  // }
  getFiltered(ruleEventDropdownValues) {
    return ruleEventDropdownValues.filter(obj=>obj.name!='$milestone_reached');
  }

  onIsRevursiveChange(event,rule) {
    rule.isRecursive = !event.checked;
  }

}

