import { Component, ElementRef, OnInit, Renderer2, HostListener,Inject } from '@angular/core';
import { MAT_DIALOG_DATA,MatDialogRef } from '@angular/material/dialog';
import { ApiCallService } from 'src/app/core/services/api-call-service';
import { getCurrencySymbol } from "@angular/common";
import { SnackbarCollection } from 'src/app/core/services/snackbar.service';
import { TranslateDynamicText } from 'src/app/shared/pipes/dynamic-translation.pipe';

class RuleDefinition {
  event: string;
  eventCount: number;
  //default value for event dropdown
  eventDropdownObj = {
    defaultValue:null,
    displayName:null,
    name:null,
    valueEditable:true,
    dataType:null,
  };
}

class LoyaltyRule {
  ruleName: string;
  ruleType = "EARN";
  validTillDateObj: Date;
  validFromDateObj: Date;
  validTill:string;
  validFrom: string;
  ruleCategory = "BASIC";
  rewardPoints: number;
  ruleDefinition:{};
  tiersApplicableTo = [];
  segmentsApplicableTo = [];
  customerListApplicableTo = [];
  employeeListApplicableTo = [];
  offerIds = [];
  hasRewardPoints = true;
  hasOffers = false;
  isAllCustomers = false;
  priorityId:number;
  excludedOrderCategories = [];
  //for amount purchased event's inner inclusion/exclusion
  //inclusionRule:[], dynamically added for inclusion rules
  includedProducts = [];
  includedProductCategories = [];
  excludedProducts = [];
  excludedProductCategories = [];
  hasExclusion = false;
  constructor(priorityId?:number) {
    this.priorityId = priorityId;
  }
}

@Component({
  selector: 'app-loyalty-rule-creation-dialog',
  templateUrl: './loyalty-rule-creation-dialog.component.html',
  styleUrls: ['./loyalty-rule-creation-dialog.component.css']
})
export class LoyaltyRuleCreationDialogComponent implements OnInit {

  constructor(
    private _apicall: ApiCallService,
    private _snackBar: SnackbarCollection,
    private _i18nDynamicTranslate: TranslateDynamicText,
    private dialogRef:MatDialogRef<LoyaltyRuleCreationDialogComponent>,
    private renderer: Renderer2,
    private elRef: ElementRef,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  closedialog(){
    this.dialogRef.close();
  }

  rule;
  revCurrency;
  currencySymbol;
  dataLoaded = false;
  loyaltyPoints;
  ngOnInit(): void {
    this.loyaltyPoints = this.data.loyaltyPoints;
    this.revCurrency = sessionStorage.getItem("serviceCurrency");
    this.currencySymbol = getCurrencySymbol(this.revCurrency, "narrow");
    if(this.data.rule) {
      this.rule = this.data.rule;
    } else {
      this.rule = new LoyaltyRule();
    }
    this.rule.hasRewardPoints = true;
    this.getLoyaltyProgramDropdowns();
    this.getProduct('');
    this.getConstantProducts();
  }

  ruleEventDropdownValues = [];
  orderCategoriesList = [];
  getLoyaltyProgramDropdowns() {
    this._apicall.getLoyaltyProgramDropdowns().subscribe(response => {
      let responseData = response["body"];
      // this.ruleFrequencies = responseData.eventFrequencies;
      // this.ruleTypeDropdownValues = responseData.ruleTypes;
      // console.log(responseData);
      const events: any[] = Object.values(responseData.loyaltyEvents);
      for (let event of events) {
        event.forEach((each) => {
          if(each.displayName.toLowerCase() === 'amount spent') {
            each.displayName = `${this.currencySymbol} spent in a purchase`;
          }
          this.ruleEventDropdownValues.push({
            name: each.name,
            displayName: each.displayName,
            valueEditable: each.valueEditable,
            defaultValue: each.defaultValue,
            operators: each.operators,
            dataType: each.dataType,
          })
        });
      }
      responseData.orderCategories?.forEach(cat=>this.orderCategoriesList.push({
        name:cat,
        disabled:false,
        checked:false,
      }));
      this.dataLoaded = true;
      this.initializeBasicRuleDetails(this.rule);
    },
    error => {
      this._snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Error fetching events dropdown data", ['POS_PAGE']), 2000);
      this.dataLoaded = true;
    });
  }

  productListApiData = {
    pageNo:1,
    pageSize:1500,
    maxProductListPrize:null,
  }
  apiProductList= [];
  getProduct(char) {
    this.dataLoaded = false;
    this._apicall
      .getOfferManagementProductsList(char,this.productListApiData.pageNo,this.productListApiData.pageSize)
      .subscribe((resultList) => {
        let data = (resultList["body"]);
        this.productListApiData.maxProductListPrize = parseInt(Object.keys(data)[0]);
        data[Object.keys(data)[0]].forEach((product)=>this.apiProductList.push({
          name:product.productName,
          disabled:false,
          checked:false,
        }));
        //lazy fetch other products
        if(this.productListApiData.maxProductListPrize > this.productListApiData.pageNo * this.productListApiData.pageSize) {
          this.productListApiData.pageNo++;
          this.getProduct('');
        }
        //after getting all products
        if(this.productListApiData.maxProductListPrize == this.apiProductList.length) {
          this.dataLoaded = true;
        }
      }, (error) => {
        // this.productCategoryMap['PRODUCTLIST'] = this.apiProductList;
        this._snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Failed loading product list", ['SHARED_PAGE']),  3000);
        this.dataLoaded = true;
    });
  }

  apiProductCategoryList = [];
  getConstantProducts() {
    this.dataLoaded = false;
    this._apicall.getConstantProducts().subscribe(
      (response)=>{
        let res = JSON.parse(response['body']);
        this.apiProductCategoryList = res.productCategories.map((categoryObj=> {
          return {
            name:categoryObj.productCategoryName,
            disabled:false,
            checked:false,
          }
        }));
      },
      (err)=>{
        this._snackBar.openSnackBar("Error fetching product categories",3000);
        this.dataLoaded = true;
      }
    );
  }

  setBasicRuleEventDetail(rule) {
    rule.ruleDefinition.eventCount = rule.ruleDefinition.eventDropdownObj.defaultValue?rule.ruleDefinition.eventDropdownObj.defaultValue:rule.ruleDefinition.eventCount;
    rule.ruleDefinition.event = rule.ruleDefinition.eventDropdownObj.name;
    if(rule.ruleDefinition.eventCount && rule.ruleDefinition.eventCount.toString().includes('.')) {
      rule.ruleDefinition.eventCount = null;
    }
    //clearing for exceptions
    if(rule.ruleDefinition.event != '$amount_spent') {
      if(rule['inclusionRule']) {
        delete rule.inclusionRule;
      }
      rule.excludedProductCategories = [];
      rule.excludedProducts = [];
    }
    if(rule.ruleDefinition.event != '$order_placed' && rule.ruleDefinition.event != '$amount_spent') {
      rule.excludedOrderCategories = [];
    }
  }

  initializeBasicRuleDetails(rule,optionalEventName?) {
    
    //check if value is present to prepopulate
    if(rule.oldRuleDefinition && rule.oldRuleDefinition && rule.oldRuleDefinition.event) {
      let temp = rule.ruleDefinition;
      rule.ruleDefinition = rule.oldRuleDefinition;
      rule.oldRuleDefinition = temp;//temp save other rule type values if present
      this.ruleEventDropdownValues.forEach((curDropdownVal)=>{
        if(curDropdownVal.name == rule.ruleDefinition.event) {
          rule.ruleDefinition.eventDropdownObj = curDropdownVal;
          return;
        }
      });
    } else if(!rule.ruleDefinition || !rule.ruleDefinition.event) {
      rule.oldRuleDefinition = rule.ruleDefinition;//temp save other rule type values if present
      rule.ruleDefinition = new RuleDefinition();
      rule.ruleDefinition.eventDropdownObj = optionalEventName?this.ruleEventDropdownValues.find(ruleEventDv=>ruleEventDv.name == optionalEventName):this.ruleEventDropdownValues[0];
      rule.ruleDefinition.eventCount = rule.ruleDefinition.eventDropdownObj.defaultValue;
      rule.ruleDefinition.event = rule.ruleDefinition.eventDropdownObj.name;
      
    } else {
      this.ruleEventDropdownValues.forEach((curDropdownVal)=>{
        if(curDropdownVal.name == rule.ruleDefinition.event) {
          rule.ruleDefinition.eventDropdownObj = curDropdownVal;
          return;
        }
      });
      //special case for amount spent
      if(rule.ruleDefinition.event == '$amount_spent') {
        if(rule.excludedProducts?.length>0 || rule.excludedProductCategories?.length>0) {
          rule.hasExclusion = true;
        }
      }
    } 
  }

  numberInputHandler(event: KeyboardEvent,maxLength,inputType?) {

    const inputValue = (event.target as HTMLInputElement).value;
    const decimalPlaces = 2;
    if (inputType.toLowerCase() != 'float') {
      const invalidCharacters = ['e', '-', '+', '.'];
      if ((inputValue.length >= maxLength && event.key !== 'Backspace') || invalidCharacters.includes(event.key)) {
        event.preventDefault();
      }
    } else {
      const invalidCharacters = ['e', '-', '+']
      const dotIndex = inputValue.indexOf('.');
      if (dotIndex !== -1 && inputValue.substring(dotIndex + 1).length >= decimalPlaces && event.key !== 'Backspace') {
        event.preventDefault();
      } else {
        if ((inputValue.length >= maxLength && event.key !== 'Backspace') || invalidCharacters.includes(event.key)) {
          event.preventDefault();
        }
      }
    }
  }

  showMultiselectDropDownFor = '';
  showMultiselectDropdown(showMultiSelectDropDownFor) {
    this.showMultiselectDropDownFor= this.showMultiselectDropDownFor?'':showMultiSelectDropDownFor;
    if(!this.showMultiselectDropDownFor) this.dropdownSearch = '';
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    this.showMultiselectDropDownFor = '';
    this.dropdownSearch = '';
  }

  dropdownSearch = '';
  filteredOrderCategoriesList = [];
  getFilteredOrderCategoriesList() {
    if(this.orderCategoriesList?.length>0 && (this.dropdownSearch || this.dropdownSearch=='')) {
      this.filteredOrderCategoriesList = this.orderCategoriesList.filter(obj => obj.name.toLowerCase().includes(this.dropdownSearch.toLowerCase())); 
    }
  }

  getOrderCategoriesDisplay(list) {
    if(!list || list.length === 0) return 'Select Order Categories';
    else {
      return list.join();
    }
  }

  selectOrderCategories(orderCat,rule) {
    this.resetErrorState();
    if(rule.excludedOrderCategories?.includes(orderCat.name)) {
      const index1 = rule.excludedOrderCategories.indexOf(orderCat.name);
      rule.excludedOrderCategories.splice(index1, 1);
      orderCat.checked = false;
    } else {
      if(rule['excludedOrderCategories']?.length>0) {
        rule['excludedOrderCategories'].push(orderCat.name);
      } else {
        rule['excludedOrderCategories'] = [orderCat.name];
      }
      orderCat.checked = true;
    }
  }

  filteredProductList = [];
  getFilteredProductList() {
    if(this.apiProductList?.length>0 && (this.dropdownSearch || this.dropdownSearch=='')) {
      this.filteredProductList = this.apiProductList.filter(product =>  {
        // if(rule.excludedProducts.filter(ruleExclusionProduct => innerRule.includedProducts.some(innerInclusionProduct=>innerInclusionProduct==) 
        return product.name.toLowerCase().includes(this.dropdownSearch.toLowerCase());
      }); 
    }
  }

  filteredCategoryList = [];
  getFilteredCategoryList() {
    if(this.apiProductCategoryList?.length>0 && (this.dropdownSearch || this.dropdownSearch=='')) {
      this.filteredCategoryList = this.apiProductCategoryList.filter(cat => cat.name.toLowerCase().includes(this.dropdownSearch.toLowerCase())); 
    }
  }

  setListFlags(rule,innerRule,listType,subRuleTypeOfDropdown) {
    if(listType == 'orderCategories' && !subRuleTypeOfDropdown) {
      this.orderCategoriesList.forEach(orderCat=>{
        if(rule.excludedOrderCategories.includes(orderCat.name)) {
          orderCat.checked = true;
        } else {
          orderCat.checked = false;
        }
      });
      this.filteredOrderCategoriesList = this.orderCategoriesList;
    }
    if(subRuleTypeOfDropdown == 'inclusion') {
      if(listType == 'product') {
        this.apiProductList.forEach(product=>{
          if(innerRule.includedProducts.includes(product.name)) {
            product.disabled = false;
            product.checked = true;
          } else if(rule.excludedProducts.includes(product.name)) {
            product.disabled = true;
            product.checked = false;
          } else {
            product.disabled = false;
            product.checked = false;
          }
        });
        this.filteredProductList = this.apiProductList;
      } else {
        this.apiProductCategoryList.forEach(cat=>{
          if(innerRule.includedProductCategories.includes(cat.name)) {
            cat.disabled = false;
            cat.checked = true;
          } else if(rule.excludedProductCategories.includes(cat.name)) {
            cat.disabled = true;
            cat.checked = false;
          } else {
            cat.disabled = false;
            cat.checked = false;
          }
        });
        this.filteredCategoryList = this.apiProductCategoryList;
      }
    }  
    if(subRuleTypeOfDropdown == 'exclusion') {
      if(listType == 'product') {
        const allInclusionRuleProducts = rule.inclusionRule.flatMap(innerRule => innerRule.includedProducts);
        this.apiProductList.forEach(product=>{
          if(allInclusionRuleProducts.includes(product.name)) {
            product.disabled = true;
            product.checked = true;
          } else if(rule.excludedProducts.includes(product.name)) {
            product.disabled = false;
            product.checked = true;
          } else {
            product.disabled = false;
            product.checked = false;
          }
        });
        this.filteredProductList = this.apiProductList;
      } else {
        const allInclusionRuleCategories = rule.inclusionRule.flatMap(innerRule => innerRule.includedProductCategories);
        this.apiProductCategoryList.forEach(cat=>{
          if(allInclusionRuleCategories.includes(cat.name)) {
            cat.disabled = true;
            cat.checked = true;
          } else if(rule.excludedProductCategories.includes(cat.name)) {
            cat.disabled = false;
            cat.checked = true;
          } else {
            cat.disabled = false;
            cat.checked = false;
          }
        });
        this.filteredCategoryList = this.apiProductCategoryList;
      }
    }
  }

  getExplicitlyExcludedProductNames(rule) {
    let allInclusionRuleProducts = [];
    if(rule.inclusionRule?.length>0) {
      const allInclusionRuleProducts = rule.inclusionRule.flatMap(innerRule => innerRule.includedProducts);
      return rule.excludedProducts.filter(productName=>!allInclusionRuleProducts.includes(productName));
    }
    return rule.excludedProducts;
  }

  getExplicitlyExcludedCategoryNames(rule) {
    let allInclusionRuleCategories = [];
    if(rule.inclusionRule?.length>0) {
      const allInclusionRuleCategories = rule.inclusionRule.flatMap(innerRule => innerRule.includedProductCategories);
      return rule.excludedProductCategories.filter(categoryName=>!allInclusionRuleCategories.includes(categoryName));
    }
    return rule.excludedProductCategories;
  }

  getProductListDisplay(productList) {
    if(this.apiProductList?.length == 0) return 'No Products';
    else {
      if(productList?.length == 0) return 'Select Product(s)';
      else {
        return productList.join();
      }
    }
  }

  getCategoryListDisplay(categoryList) {
    if(this.apiProductCategoryList?.length == 0) return 'No Category';
    else {
      if(categoryList?.length == 0) return 'Select Category(s)';
      else {
        return categoryList.join();
      }
    }
  }

  addInclusionRule(rule) {
    rule['inclusionRule'] = rule['inclusionRule']?.length>0?rule['inclusionRule']:[];
    let newSubRule = new LoyaltyRule();
    rule['inclusionRule'].push(newSubRule);
    //basic rule has to be created with amount spent event for inner rule
    this.initializeBasicRuleDetails(rule['inclusionRule'][rule['inclusionRule'].length-1],'$amount_spent');
    // rule['includedProducts'] = rule['includedProducts']?rule['includedProducts']:[];
    // rule['includedProductCategories'] = rule['includedProductCategories']?rule['includedProductCategories']:[];
    rule['excludedProducts'] = rule['excludedProducts']?rule['excludedProducts']:[];
    rule['excludedProductCategories'] = rule['excludedProductCategories']?rule['excludedProductCategories']:[];
    rule.hasExclusion = true;
  }

  selectProduct(product,rule,innerRule,isInclude) {
    this.resetErrorState();
    if(isInclude) {
      if(innerRule.includedProducts?.includes(product.name)) {
        const index1 = innerRule.includedProducts.indexOf(product.name);
        innerRule.includedProducts.splice(index1, 1);
        const index2 = rule.excludedProducts.indexOf(product.name);
        rule.excludedProducts.splice(index2,1);  
        product.checked = false;
      } else {
        innerRule.includedProducts.push(product.name);
        if(rule['excludedProducts']?.length>0) {
          rule['excludedProducts'].push(product.name);
        } else {
          rule['excludedProducts'] = [product.name];
        }
        product.checked = true;
      }
    } else {
      if(rule.excludedProducts?.includes(product.name)) {
        const index2 = rule.excludedProducts.indexOf(product.name);
        rule.excludedProducts.splice(index2,1);
        product.checked = false;
      } else {
        rule.excludedProducts.push(product.name);
        product.checked = true;
      }
    }
  }

  selectProductCategories(category,rule,innerRule,isInclude) {
    this.resetErrorState();
    if(isInclude) {
      if(innerRule.includedProductCategories?.includes(category.name)) {
        const index1 = innerRule.includedProductCategories.indexOf(category.name);
        innerRule.includedProductCategories.splice(index1, 1);
        const index2 = rule.excludedProductCategories.indexOf(category.name);
        rule.excludedProductCategories.splice(index2,1);
        category.checked = false;
      } else {
        innerRule.includedProductCategories.push(category.name);
        if(rule['excludedProductCategories']?.length>0) {
          rule['excludedProductCategories'].push(category.name);
        } else {
          rule['excludedProductCategories'] = [category.name];
        }
        category.checked = true;
      }
    } else {
      if(rule.excludedProductCategories?.includes(category.name)) {
        const index2 = rule.excludedProductCategories.indexOf(category.name);
        rule.excludedProductCategories.splice(index2, 1);
        category.checked = false;
      } else {
        rule.excludedProductCategories.push(category.name);
        category.checked = true;
      }
    }
  }

  deleteInclusionRule(rule,innerRuleIndex) {
    rule.excludedProducts = rule.excludedProducts.filter(eachProductName => !rule.inclusionRule[innerRuleIndex].includedProducts.includes(eachProductName));
    rule.excludedProductCategories = rule.excludedProductCategories.filter(eachCatName => !rule.inclusionRule[innerRuleIndex].includedProductCategories.includes(eachCatName));
    rule.inclusionRule.splice(innerRuleIndex,1);
  }

  resetErrorState() {
    const inputFields = document.querySelectorAll('.error-input');
      // Remove error class from all input fields
      inputFields.forEach(inputField => {
        inputField.classList.remove('error-input');
      });
      const popovers = document.querySelectorAll('.error-popover');
      // Remove the popover if found
      popovers.forEach(popover => {
        popover.remove();
      });
  }

  isRuleValid() {
    let curRule = this.rule;
    curRule.ruleName = curRule.ruleName?.trim();
    let hasError = false;
    if(!curRule.ruleName) {
      this.setErrorState('ruleNameInput','Please enter valid rule name');
      this.scrollToErrorField();
      hasError = true;
    }
    if(curRule.ruleName?.length < 2) {
      this.setErrorState('ruleNameInput','Name cannot be less than 2 characters');
      this.scrollToErrorField();
      hasError = true;
    }
    if(!curRule.ruleDefinition.eventCount || curRule.ruleDefinition.eventCount < 0) {
      this.setErrorState('eventCountInput','Please enter a valid value');
      this.scrollToErrorField();
      hasError = true;
    }
    if(curRule.hasRewardPoints && (!curRule.rewardPoints || curRule.rewardPoints < 0)) {
      this.setErrorState('rewardPointInput','Please enter valid loyalty points');
      this.scrollToErrorField();
      hasError = true;
    }
    //validation for inner rules
    if(curRule.ruleDefinition.event == '$amount_spent') {
      if(curRule.inclusionRule?.length>0) {
        for(let j = 0; j < curRule.inclusionRule.length; j++) {
          let curInnerRule = curRule.inclusionRule[j];
          if(!curInnerRule.ruleDefinition.eventCount || curInnerRule.ruleDefinition.eventCount < 0) {
            this.setErrorState('eventCountInput'+'-'+j,'Please enter a valid value');
            this.scrollToErrorField();
            hasError = true;
          }
          //inclusion rules validation
          if(!(curInnerRule.includedProducts?.length>0) && !(curInnerRule.includedProductCategories?.length>0)) {
            this.setErrorState('listInput'+'-'+j,'Please select a product or category');
            this.scrollToErrorField();
            hasError = true;
          }
          if(!(curInnerRule.hasRewardPoints || curInnerRule.hasOffers)) {
            this.setErrorState('checkBoxInput'+'-'+j,'Please select a reward for the rule');
            this.scrollToErrorField();
            hasError = true;
          }
          if(curInnerRule.hasRewardPoints && (!curInnerRule.rewardPoints || curInnerRule.rewardPoints < 0)) {
            this.setErrorState('rewardPointInput'+'-'+j,'Please enter valid loyalty points');
            this.scrollToErrorField();
            hasError = true;
          }
          if(curInnerRule.hasOffers && !(curInnerRule.offerIds?.length>0)) {
            this.setErrorState('offerInput'+'-'+j,'Please select an offer');
            this.scrollToErrorField();
            hasError = true;
          }
        }
      }
      //exclusion validation
      if(curRule.hasExclusion && (!(curRule.excludedProducts?.length>0) && !(curRule.excludedProductCategories?.length>0))) {
        this.setErrorState('listInput'+'-'+'exclusion','Please select a product or category');
        this.scrollToErrorField();
        hasError = true;
      }
    }
    if(hasError) {
      return false;
    }
    return true;
  }

  private setErrorState(inputId: string,message) {
    // Add error class to the specified input field
    // this.resetErrorState();
    const inputField = this.elRef.nativeElement.querySelector(`#${inputId}`);
    if (inputField) {
      inputField.classList.add('error-input');
      this.addPopover(inputField,message);
    }
  }

  addPopover(inputElement: HTMLElement,message: string) {
    const popover = this.renderer.createElement('div');
    this.renderer.addClass(popover, 'error-popover'); // Add your popover class here
    this.renderer.setProperty(popover, 'innerHTML', message); // Set popover content
    // Calculate the right position
    const containerWidth = inputElement.getBoundingClientRect().width;
    // const rightPosition = window.innerWidth - containerRight + popoverWidth; // Calculate the right position

    this.renderer.setStyle(popover, 'left', `${(containerWidth/2)-40}px`); // Set the right position

    this.renderer.appendChild(inputElement, popover);
  }

  scrollToErrorField() {
    setTimeout(() => {
      const errorField = document.querySelector('.error-input');
      if (errorField) {
          errorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, 250);
  }




  validateAndReturnRule() {
    this.resetErrorState();
    if(this.isRuleValid()) {
      // let ruleCopy = JSON.parse(JSON.stringify(this.rule));
      // delete ruleCopy.ruleDefinition.eventDropdownObj;
      // if(ruleCopy.inclusionRule?.length>0) {
      //   ruleCopy.inclusionRule.forEach(r=>delete r.ruleDefinition.eventDropdownObj)
      // }
      // console.log(`RESULT-->${JSON.stringify(ruleCopy)}`);

      //validate ruleName calling api and then save
      this.validateLoyaltyRuleName(this.rule.ruleName);
    }
  }

  getFiltered(ruleEventDropdownValues) {
    return ruleEventDropdownValues.filter(obj=>obj.name!='$milestone_reached');
  }

  validateLoyaltyRuleName(ruleName) {
    this._snackBar.openSnackBar("Validating rule name....",0);	
    this._apicall.validateLoyaltyRuleName(ruleName).subscribe(
      (response)=>{
        this._snackBar.dismiss();
        this.dialogRef.close(this.rule);
      },
      (err)=>{
        this._snackBar.dismiss();
        this.setErrorState('ruleNameInput','Rule name already exists');
      }
    );
  }

}
