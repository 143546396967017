import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { getCurrencySymbol } from "@angular/common";

@Component({
  selector: 'app-offer-budget',
  templateUrl: './offer-budget.component.html',
  styleUrls: ['./offer-budget.component.css']
})
export class OfferBudgetComponent implements OnInit {
  

  constructor() { }

  ngOnInit(): void {
    this.revCurrency = sessionStorage.getItem("serviceCurrency");
    this.currencySymbol = getCurrencySymbol(this.revCurrency, "narrow");
    this.resetFrequency = this.resetFrequencyPossibilities[0].value;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['budgetEntity'] && changes['budgetEntity'].currentValue) {
      const newBudgetEntity = changes['budgetEntity'].currentValue;
      if (newBudgetEntity.budgetLimit) {
        this.budgetLimit = newBudgetEntity.budgetLimit;
        this.hasBudgetLimit = true;
      }
      if (newBudgetEntity.redemptionCount) {
        this.redemptionCount = newBudgetEntity.redemptionCount;
        this.hasRedemptionCount = true;
      }
      if (newBudgetEntity.resetFrequency) {
        this.resetFrequency = newBudgetEntity.resetFrequency;
      }
    }
  }

  @Input() budgetEntity;

  revCurrency;
  currencySymbol;
  hasRedemptionCount = true;
  hasBudgetLimit = false;
  redemptionCount;
  budgetLimit;
  showError = false;
  resetFrequencyPossibilities = [
    {
      display: 'Never',
      value: 'NEVER',
    },
    {
      display: 'Daily',
      value: 'DAILY',
    },
    {
      display: 'Weekly',
      value: 'WEEKLY',
    },
    {
      display: 'Monthly',
      value: 'MONTHLY',
    },
    {
      display: 'Daily',
      value: 'YEARLY',
    },
  ];
  resetFrequency;


  onHasBudgetLimitChange() {
    if(!this.hasBudgetLimit) {
      this.budgetLimit = null;
      if(!this.hasRedemptionCount) {
        this.hasRedemptionCount = true;
      }
    }
  }

  onHasRedemptionCountChange() {
    if(!this.hasRedemptionCount) {
      this.redemptionCount = null
      if(!this.hasBudgetLimit) {
        this.hasBudgetLimit = true;
      }
    }
  }

  numberInputHandler(event: KeyboardEvent,maxLength,inputType?) {

    const inputValue = (event.target as HTMLInputElement).value;
    const decimalPlaces = 2;
    if (inputType.toLowerCase() != 'float') {
      const invalidCharacters = ['e', '-', '+', '.'];
      if ((inputValue.length >= maxLength && event.key !== 'Backspace') || invalidCharacters.includes(event.key)) {
        event.preventDefault();
      }
    } else {
      const invalidCharacters = ['e', '-', '+']
      const dotIndex = inputValue.indexOf('.');
      if (dotIndex !== -1 && inputValue.substring(dotIndex + 1).length >= decimalPlaces && event.key !== 'Backspace') {
        event.preventDefault();
      } else {
        if ((inputValue.length >= maxLength && event.key !== 'Backspace') || invalidCharacters.includes(event.key)) {
          event.preventDefault();
        }
      }
    }
  }

  returnBudgetObj() {
    return {
      redemptionCount: this.hasRedemptionCount ? this.redemptionCount : null,
      budgetLimit: this.hasBudgetLimit ? this.budgetLimit : null,
      resetFrequency: this.resetFrequency !== 'NEVER' ? this.resetFrequency : null,
    };
  }

  isValuesValid() {
    if(this.hasRedemptionCount) {
      if(!this.redemptionCount) {
        this.showError = true;
        return false;
      }
    }
    if(this.hasBudgetLimit) {
      if(!this.budgetLimit) {
        this.showError = true
        return false;
      }
    }
    return true;
  }

  resetValues() {
    this.showError = false;
    this.redemptionCount = null;
    this.budgetLimit = null;
  }

}
