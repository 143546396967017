import { Component, OnInit, HostListener, ErrorHandler } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatDialog } from "@angular/material/dialog"; 
import { dataBase } from "src/app/shared/models/model";
import { assetPath } from "src/app/configs/asset-path-config";
import { TokenStorageService } from "src/app/core/auth/token-storage.service";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { Globals } from "src/app/core/services/global.service";
import { NotificationServiceService } from "src/app/core/services/notification-service.service";
import { SignoutService } from "src/app/core/services/signout.service";

declare global {
  interface Window {
    dataLayer: any[];
    __insp: any[];
  }
}

@Component({
  selector: "app-data-ingestion",
  templateUrl: "./data-ingestion.component.html",
  styleUrls: ["./data-ingestion.component.css"],
})
export class DataIngestionComponent implements OnInit {
  pathParam: string;
  height = 200;
  width = 400;
  scrHeight;
  scrContentHeight;
  userPosDetails;
  scrWidth;
  hasPos;
  dataFetchComplete: boolean = false;
  userCategory = [];
  totalCategory = 33000;
  segmentWidth;
  dataIngestionHomeSelect: boolean = true;
  userName;
  assetPath = {
    shopifyImg: assetPath.path + "/shopify.png",
  };
  private roles: string[];

  dataOnboarded: boolean;
  returnUrl;

  constructor(
    private route: ActivatedRoute,
    private apiCall: ApiCallService,
    private errorHandle: ErrorHandler,
    private router: Router,
    public dialog: MatDialog,
    public dataBase: dataBase,
    private _snackBar: MatSnackBar,
    private tokenStorage: TokenStorageService,
    private notificationService: NotificationServiceService,
    private globalService: Globals,
    private _signoutService: SignoutService
  ) {
    this.getScreenSize();
  }

  @HostListener("window:resize", ["$event"])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.scrContentHeight = window.innerHeight - 60 - 90;
    this.scrWidth = window.innerWidth;
  }

  ngOnInit() {
    this.userName = this.tokenStorage.getUsername();
    this.roles = this.tokenStorage.getAuthorities();
    this.returnUrl = this.route.snapshot.queryParams["returnUrl"] || "/";
    let v = this.tokenStorage.getAuthorities();
    for (const x in v) {
      if (v[x] == "ROLE_SUPER_ADMIN") {
        this.tokenStorage.setIsSuperAdmin("true");
        break;
      } else {
        this.tokenStorage.setIsSuperAdmin("false");
      }
    }
    var mid = this.route.snapshot.queryParams["mid"] || 0;
    // this.router.navigate([
    //   "app/onboard"
    // ]);
    this.apiCall.fetchUserMerchantData(mid).subscribe(
      (response) => {
      
        this.userPosDetails = response["body"];
        this.userPosDetails = JSON.parse(this.userPosDetails);
        let posProviderAccountObj = JSON.parse(
          this.userPosDetails[0]["posProviderAccount"]
        );
        if (
          this.userPosDetails[0]["userName"] !=
          this.tokenStorage.getUsernameFull()
        ) {
          localStorage.setItem("lStatus", "exit20");
        }

        this.tokenStorage.saveUserNameFull(this.userPosDetails[0]["userName"]);
        this.tokenStorage.setMerchantUserEmail(this.userPosDetails[0]["userEmail"]);
        this.tokenStorage.setUserEmail(posProviderAccountObj["username"]);
        this.tokenStorage.setshopUrl(posProviderAccountObj["shopurl"]);
        this.tokenStorage.setShopName(posProviderAccountObj["shopname"]);
        this.userName = this.userPosDetails[0]["userName"];
        // console.log("111")
        if (this.userPosDetails.length === 0) {
          if (!this.tokenStorage.getMerchantId()) {
            localStorage.setItem("lStatus", "exit10");
            // this.tokenStorage.eventStartListen();
          }
          this.hasPos = false;
          this.dataFetchComplete = true;
          //    this.onShopifyClick();
          this.route.params.subscribe((params) => {
            this.pathParam = params["posProvider"];
          });
        } else {
          if (!this.tokenStorage.getMerchantId()) {
            //console.log(this.tokenStorage.getMerchantId())
            localStorage.setItem("lStatus", "exit10");
            // this.tokenStorage.eventStartListen();
          }
          this.tokenStorage.saveMerchantId(this.userPosDetails[0]["id"]);
          this.tokenStorage.saveMerchantOnlineStore(
            this.userPosDetails[0]["posProvider"]
          );
          // this.tokenStorage.setUserEmail(this.userPosDetails[0]["username"]);
          // this.tokenStorage.setshopUrl(this.userPosDetails[0]["shopurl"]);
          if (this.tokenStorage.getMerchantOnlineStore() == "clover") {
            // var shop= this.tokenStorage.getShopId();
            this.apiCall.getOnboardingProgressStatus().subscribe((response) => {
              let responseBody = response["body"];

              if (responseBody.onboardingCompleted) {
                this.tokenStorage.removeShopID();
                this.redirectToApp();
              } else {
                if (
                  this.tokenStorage.getOnlineStoreUrl() &&
                  this.tokenStorage.getMerchantOnlineStore() &&
                  !this.tokenStorage.getExistingUserFlag()
                ) {
                  this.router.navigate([
                    "data-ingestion/" +
                      this.tokenStorage.getMerchantOnlineStore(),
                  ]);
                } else {
                  this.tokenStorage.removeShopID();
                  this.redirectToApp();
                }
              }
            });
          } else {
            if (
              this.tokenStorage.getOnlineStoreUrl() &&
              this.tokenStorage.getMerchantOnlineStore() &&
              !this.tokenStorage.getExistingUserFlag()
            ) {
              this.router.navigate([
                "data-ingestion/" + this.tokenStorage.getMerchantOnlineStore(),
              ]);
            } else {
              this.tokenStorage.removeShopID();
              this.redirectToApp();
            }
          }
        }
      },
      (error) => {
        this.router.navigate(["/app/app-home"], {
          queryParams: { type: "Objectives" },
        });

        // this.apiCall.getCurrentPlanPos().subscribe((res)=>{
        //   if(res['body']['status'] !== 'FRESH'){
        //     this.onOtherClick();
        //   }
        //   else{
        //     this.dataFetchComplete = true;
        //   }
        // },(err)=>{
        //   this.onOtherClick();
        // })
        // console.log("TESTTT",this.tokenStorage.getExistingUserFlag())
        // if(this.tokenStorage.getExistingUserFlag() === 'true'){
        //   this.onOtherClick();
        // }
        // this.onShopifyClick();
      }
    );
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

  onShopifyClick() {
    let path = "";
    if (!this.pathParam || this.pathParam === "") {
      path = "shopify";
    } else {
      path = "../shopify";
    }
    this.router.navigate([path], {
      relativeTo: this.route,
    });
  }
  onCloverClick() {
    let path = "";
    if (!this.pathParam || this.pathParam === "") {
      path = "clover";
    } else {
      path = "../clover";
    }
    this.router.navigate([path], {
      relativeTo: this.route,
    });
  }
  onOtherClick(){
    let path = "";
    if (!this.pathParam || this.pathParam === "") {
      path = "check";
    } else {
      path = "../check";
    }
    // this.router.navigate([path], {
    //   relativeTo: this.route,
    // });
    this.router.navigate(["/app/app-home"], {
      queryParams: { type: "Objectives" },
    });  }

  logout() {
    this._signoutService.signOut();
    this.tokenStorage.setUserLogin("false");
    this.router.navigate(["auth/login"], {});
  }

  redirectToApp() {
    window.dataLayer.push({
      userId: this.userPosDetails[0]["id"],
      shop_url: encodeURIComponent(this.tokenStorage.getShopUrl()),
      user_email: encodeURIComponent(this.tokenStorage.getUserEmail()),
    });

    // window.__insp.push([
    //   "identify",
    //   encodeURIComponent(this.tokenStorage.getShopUrl()),
    // ]);
    this.notificationService.getnotificationBanner();
    this.tokenStorage.setshopLinkedStatus("true");
    this.apiCall.getMerchantDetails().subscribe((response) => {
      let data = JSON.parse(response["body"]);
      this.globalService.setMerchantCurrencyandCountry(
        data.currency,
        data.country
      );
      this.tokenStorage.saveMerchantName(data.merchantName);
      this.tokenStorage.setPartnershipConsentStatus(data.partnershipConsent);
      this.tokenStorage.setIsOnboardingCompleted(data.isOnboarded);
      if (!data.isOnboarded) {
        this.getProgressBarStatus();
      }else{
        this.tokenStorage.setProgressBarShowStatus(
          'false')
         }
      // if (this.globalService.redirectToPaymentPlan == true) {
      //  this.globalService.setSidenavToggleStatus(false,'other');

      //   this.router.navigate(['app/payments/order-summary'], { queryParams: { planSelectedId: this.globalService.getplanIdValue } });
      // }
      if (this.returnUrl != "/") {
        this.globalService.setSidenavToggleStatus(false, "other");
        this.router.navigateByUrl(this.returnUrl);
      } else {
        this.globalService.setSidenavToggleStatus(false, "home");
        // this.checkMerchantOnboardWidgetStatus();
        this.router.navigate(["/app/app-home"], {
          queryParams: { type: "Objectives" },
        });
      }
    });
  }

  checkMerchantOnboardWidgetStatus() {
    this.apiCall.getWelcomeWidgetStatus().subscribe((response) => {
      if (response["message"] == "success") {
        if (response["body"] == false || response["body"] == null) {
          this.router.navigate(["/welcome-feature"]);
          this.tokenStorage.setIsNewUser("true");
        } else {
            if(this.userPosDetails[0]["posProvider"] === 'shopify'){
              this.router.navigate(["/app/app-home"], {
                queryParams: { type: "Objectives" },
              });
              this.tokenStorage.setIsNewUser("false");
              console.log(this.tokenStorage.getIsNewUser());
            }
            else{
              let path = "";
              if (!this.pathParam || this.pathParam === "") {
                path = "check";
              } else {
                path = "../check";
              }
              // this.router.navigate([path], {
              //   relativeTo: this.route,
              // });
              if(this.roles?.indexOf('ROLE_REPORTING_VIEW') !== -1){
                this.router.navigate(['/app/all-report']);
              }
              else{
                this.router.navigate(["/app/app-home"], {
                  queryParams: { type: "Objectives" },
                });
              }
              this.tokenStorage.setIsNewUser("false");
              console.log(this.tokenStorage.getIsNewUser());
            }
        }
      } else {
        this.router.navigate(["/app/app-home"], {
          queryParams: { type: "Objectives" },
        });
      }
    });
  }
  getProgressBarStatus() {
    this.apiCall.getOnboardingProgressStatus().subscribe((response) => {
      let responseBody = response["body"];
      if(responseBody.onboardingCompleted){
        this.tokenStorage.setProgressBarShowStatus(
          false
        );
      }else{
        this.tokenStorage.setProgressBarShowStatus(
          true
        );
      }

    });
  }
}
