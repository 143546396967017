import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  HostListener,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  Renderer2,
  
} from "@angular/core";
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { DatePipe, getCurrencySymbol } from "@angular/common";
import { ReplaySubject, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { MatChipList } from "@angular/material/chips";
import { TokenStorageService } from "src/app/core/auth/token-storage.service";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { EventIconMappingService } from "src/app/core/services/event-icon-mapping.service";
import { SnackbarCollection } from "src/app/core/services/snackbar.service";
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConnectAdAccountComponent } from "../../pages/connect-ad-account/connect-ad-account.component";
import { environment } from "src/environments/environment";
import { create360dialogaccoountService } from "src/app/core/services/create-360-dialog-account-service";
import { WidgetConfigService } from "src/app/core/services/widget-config.service";
import { SocialMediaConnectChannelDialogComponent, ConfirmationDialogBoxComponent, PreviewTemplateComponent, EmailEditorDialogComponent, SmsTemplateCreationDialogComponent, OfferSummaryDialogComponent, SmsPreviewDialogComponent, PreviewComarketingOfferComponent,OfferCreateDialogComponent, OfferSummaryDialogV2Component } from "src/app/shared/components/dialog-box";
import { StrategyNodeRequest } from "src/app/shared/models/strategy-node";
import { OfferAttachEmailDialogComponent } from "../../../offer/pages";
import { Router } from "@angular/router";
import { CampaignAddFilterComponent } from "../campaign-add-filter/campaign-add-filter.component";
import { TriggerAdditionalConfigComponent } from "../trigger-additional-config/trigger-additional-config.component";
import { CampaignChannelSelectComponent } from "../campaign-channel-select/campaign-channel-select.component";
import { TranslateDynamicText } from "src/app/shared/pipes/dynamic-translation.pipe";
import { getdateformatService } from "src/app/core/services/get-date-format.service";
import { LoyaltyRulePreviewDialogComponent } from "src/app/shared/components/loyalty-rule-preview-dialog/loyalty-rule-preview-dialog.component";
import { MatDatepicker } from "@angular/material/datepicker";
import { DateFormatService } from "src/app/core/services/date-format.service";
import { LoyaltyRuleCreationDialogComponent } from "../loyalty-rule-creation-dialog/loyalty-rule-creation-dialog.component";

@Component({
  selector: "app-campaign-strategy-node",
  templateUrl: "./campaign-strategy-node.component.html",
  styleUrls: ["./campaign-strategy-node.component.css"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CampaignStrategyNodeComponent implements OnInit {
  @ViewChild("chipList") chipList: MatChipList;
  @ViewChild("userSegment") elementViewProfile: any;
  // @ViewChild('filterNodeData') childComponent: CampaignAddFilterComponent;
  @ViewChild('channelSelectChild') channelSelectComponent : CampaignChannelSelectComponent;
  @ViewChild('triggerAdditionalConfig') triggerAdditionalConfig : TriggerAdditionalConfigComponent;
  @ViewChild('triggerAdditionalConfig2') triggerAdditionalConfig2 : TriggerAdditionalConfigComponent;
  @Input() type: string;
  @Input() nodeId: string;
  @Input() strategyId: any;
  @Input() nodeData: any;
  @Input() nodeType: string;
  @Input() previousNodeId: any;
  @Input() previousNodeRelation: string;
  @Input() nextNodeId: any;
  @Input() isAllCustomers: boolean;
  @Input() strategyData: any;
  @Input() allowABTestingNodeCreation: boolean = true; //controls display of AB testing in node selection
  @Input() isTimeEvent: boolean = true; //to check whether its time based or event based journey
  @Input() allowAudienceSplitNodeCreation: boolean = true; //controls display of AudienceSplit in node selection
  @Output() nodeConfiguredData = new EventEmitter<any>();
  channelType = "";
  customerEvents = [];
  surveyTemplates = [];
  customerSegments = [];
  emailTemplates: any[] = [];
  emailCustomeTemplates: any = [];
  whatsAppTemplates = [];
  smsLayouts = [];
  surveyEmailTemplate;
  allLayout = [];
  filteredData = [];
  offers = [];
  coMarketingOffers:any = [];
  submitAudience = false;
  weekdays = [];
  weekDaysChips: any[] = [
    { name: "SUN", selected: false },
    { name: "MON", selected: false },
    { name: "TUE", selected: false },
    { name: "WED", selected: false },
    { name: "THU", selected: false },
    { name: "FRI", selected: false },
    { name: "SAT", selected: false },
  ];
  arrayDynamic = [];
  dataForm;
  selectedTemplate;
  selectedLayout;
  layoutId;
  layoutName;
  masterOffer;
  offerJson;
  offerDetailEmailLayout;
  emailtemplateName;
  emailtemplateSnapshot;
  isNoneBarrierDetected: boolean;
  isBuyXGetYDetected: boolean;
  selectedbarrier;
  htmlloading;
  layoutSnapshotUrl;
  selectedSurvey = null;
  surveyId;
  surveyIdFinal;
  selectedSurveyName;
  triggerNodeType = "EVENT";
  dateRadioOption = 1;
  editDateRadioOption = 1;
  anyTimeSliderValue: boolean = false;
  everyDaySliderValue: boolean = false;
  loaded: boolean = false;
  nodeOptionType: string = "";
  selectedEmailTemplateId: number = -1;
  selectedEmailTemplateName: string = "";
  selectedSurveyTemplateId: number = -1;
  selectedSurveyTemplateName: string = "";
  selectedSurveyTitle: string = "";
  selectedOfferTemplateId: number = -1;
  selectedOfferTemplateName: string = "";
  selectedSmsTemplateId: number = -1;
  selectedSmsTemplateName: string = "";
  selectedWhatsappTemplateId: number = -1;
  selectedWhatsappTemplateName: string = "";
  selectedCoMarketingTemplateId: any = [];
  selectedComarketingOffers: any = [];
  selectedCoMarketingTemplateName: string = "";
  emailTemplateId: number = -1;
  emailTemplateName: string = "";
  surveyTemplateId: number = -1;
  surveyTemplateName: string = "";
  offerTemplateId: number = -1;
  offerTemplateName: string = "";
  selectedSmsLayout: any = {};
  selectedWhatsappLayout: any ={};
  selectedWhatsapptemplateSource: string="";
  whatsappLayout: any ={};
  smsTemplateId: number = -1;
  smsTemplateName: string = "";
  whatsappTemplateId: number = -1;
  whatsappTemplateName: string = "";
  whatsappTemplatetemplateSource:string="";
  coMarketingOfferTemplateId: any = [];
  coMarketingOfferTemplateName: string = "";
  coMarketingOffer:any = [];
  showSecondNav: boolean = false;
  showFirstNav: boolean = false;
  firstNavTitle: string = "";
  secondNavTitle: string = "";
  hours = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23,
  ];
  minutes = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55];
  filter = {
    label: "",
  };
  validWidgets = null;
  daysControl = new FormControl(0, [Validators.required, this.min(0)]);
  hoursControl = new FormControl(0, Validators.required);
  minutesControl = new FormControl(0, Validators.required);
  eventName = new FormControl("", [Validators.required]);
  customerEventFilterCtrl: FormControl = new FormControl();
  filteredcustomerEvents: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  startDate = new FormControl(Validators.required);
  endDate = new FormControl(Validators.required);
  startTime = new FormControl();
  endTime = new FormControl();
  emailLayoutId = new FormControl();
  offerId = new FormControl();
  emailSubject = new FormControl("", [
    Validators.required,
    Validators.minLength(1),
    Validators.maxLength(100),
  ]);
  surveyTitle = new FormControl("", [
    Validators.required,
    Validators.minLength(1),
    Validators.maxLength(100),
  ]);
  surveyEmailSubject = new FormControl("", [
    Validators.required,
    Validators.minLength(1),
    Validators.maxLength(100),
  ]);
  audienceGroupName = new FormControl("", [
    Validators.required,
    Validators.minLength(1),
    Validators.maxLength(100),
  ]);
  audienceGroupDescription = new FormControl("", [Validators.maxLength(100)]);
  emailSubjectContent: string = "";
  submitted: boolean = false;
  triggerGroupv2: any = {};
  responseEventGroup: any = {};
  triggerGroupIndex: number = -1;
  triggerChildIndex: number = -1;
  responseGroupIndex: number = -1;
  responseChildIndex: number = -1;
  isLoading: boolean = false;
  scrHeight: any = 0;
  finalScreenHeight: any = 0;
  finalScreenWidth: any = 0;
  scrWidth: any = 0;
  surveyEmailInstanceId;
  allcustomer = [{ id: "ALL_CUSTOMERS", name: "All customers" }];
  customerSegment = [];
  customerSelectedSegment = [];
  segmentVariables = [];
  ruleEventDropdownValues = [];
  dataFetchComplete: boolean = false;
  actionStatus = "action";
  isErrorAudience = false;
  isErrorAB = false;
  isErrorResponse = false;
  errorText = "";
  segmentName = [];
  pathedit = false;
  splitedit =false;
  temp_split_Name = "";
  channelData : any;
  channelSave = false;
  serviceCountry = sessionStorage.getItem("serviceCountry");
  @HostListener("window:resize", ["$event"])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
  }
  disableNodeSelection: boolean = false;
  startDateMinValue;
  ruleTypeDropdownValues=[];
  //  = new Date(
  // new Date().toLocaleString("en-US", {
  //   timeZone: this._TokenStorageService.getMerchantTimeZone(),
  // })
  // );
  // startDateMaxValue = new Date(
  //   new Date().toLocaleString("en-US", {
  //     timeZone: this._TokenStorageService.getMerchantTimeZone(),
  //   })
  // );
  endDateMinValue;
  //  = new Date(
  // new Date(
  // this.startDateMinValue.getFullYear(),
  // this.startDateMinValue.getMonth(),
  // this.startDateMinValue.getDate() + 1
  // ).toLocaleString("en-US", {
  //   timeZone: this._TokenStorageService.getMerchantTimeZone(),
  // })
  // );
  pos = "";
  timezone;
  allowScroll = true;
  endMinTime;
  startMaxTime;
  startMinTime;
  currentDateTimeMinValue;
  disableDateRadioSelection: boolean = false;
  parentConditionIndex = -1;
  childConditionIndex = -1;
  submitAud = false;
  triggerNodeEditableGroupLength: number = 0;
  triggerNodeEditableChildLength: number = 0;
  responseNodeEditableGroupLength: number = 0;
  responseNodeEditableChildLength: number = 0;
  templateLoader: boolean = false;
  snapShotInterval;
  audienceId = null;
  smsCheckbox: boolean = false;
  emailCheckbox: boolean = true;
  whatsappCheckBox: boolean = true;
  countryCode;
  shopName = "";
  abTesting = [];
  ab = [];
  showfilter = false;
  tempSegmentGameFilter = "";
  dropdownStatusSegment = false;
  filterSegment: FormGroup;
  atrWindow = [0,7]; //0 index is hours 1st index is Days
  waitWindow = [0,7];
  // selectedKpis = [];
  hourValues = Array.from({ length: 24 }, (_, i) => i);
  groupName = "";
  groupNameGroup = [];
  inputPath = 0;
  splitGroup: any = {};
  pathGroup: any = {};
  controlPath: boolean = false;
  controlValue = 0;
  validScreens = null;
  adAccountId;
  _gaConditionString = "";
  loyaltyProgramConfig = {};
  showRuleCreationPopup = false;
  ruleFrequencies= [];
  submittedData = false;
  filterData = {};
  showClicked = false;
  isOnboarded = 'false';
  zeroError = false;
  channelSelect = false;  
  currencySymbol;
  customerSegmentAndTiers = [];
  isfilterError = false;
  isCelebration = false;
  celebrationType = "";
  celebrationRuleEevnt = "";
  celebration = "";
  daysPriorToCelebration = 0;
  filters = [];
  appointmentType = "INSTANT";
  hoursControlAppointment = 0; 
  daysControlAppointment = 0;
  minutesControlAppointment = 0;
  customerTierList = [];
  fromTier = "";
  toTier = "";
  toTierLevel;
  fromTierLevel;
  loadEmoji = false;
  toggled = false;
  searchEmoji = ""
  emojiTheme =             {
    martShowHeader: true,
    martShowFooter: false,
    martHeaderPadding: {x: '0px', y: '0px'},
    martFooterPadding: {x: '0px', y: '0px'},
    martHeaderBG: '#e3e7e8',
    martFooterBG: '#e3e7e8',
    martBG: '#ebeff2',
    martCategoryColor: '#94a0a6',
    martCategoryColorActive:  '#455a64',
    martActiveCategoryIndicatorColor: '#00897b',
    martEmojiFontSize: '110%',
    martCategoryFontSize: '12px',
    martBorderRadius: '14px',
    martActiveCategoryIndicatorHeight: '1px',
    martEmojiPadding: {x: '40px', y: '40px'}
  };
  eventsInfo = {
    ORDER : "The journey will be triggered for any customer falling in the target segment when they place their first order within the scheduled duration",
    add_cart : "The journey will be triggered for any customer falling in the target segment when they add item to their cart within the scheduled duration",
    create_checkout : "The journey will be triggered for any customer falling in the target segment when they initiate a cart checkout within the scheduled duration",
    create_customer : "The journey will be triggered for any customer falling in the target segment when they sign up for loyalty within the scheduled duration",
    create_orders : "The journey will be triggered for any customer falling in the target segment when they place their first order within the scheduled duration",
    recentlyviewed_products : "The journey will be triggered for any customer falling in the target segment when they view any product within the scheduled duration",
    search_products : "The journey will be triggered for any customer falling in the target segment when they search for any product within the scheduled duration",
    update_checkout : "The journey will be triggered for any customer falling in the target segment when they update their checkout cart within the scheduled duration",
    sign_up : "The journey will be triggered for any customer falling in the target segment when they sign up for loyalty within the scheduled duration",
    lead_created : "The journey will be triggered for any new lead that is created within the scheduled duration",
    proposal_won : "The journey will be triggered for any customer falling in the target segment when a proposal curated for them is won within the scheduled duration",
    proposal_active: "The journey will be triggered for any customer falling in the target segment when a new proposal is created for them within the scheduled duration",
    proposal_lost: "The journey will be triggered for any customer falling in the target segment when a proposal curated for them is lost(retired) within the scheduled duration",
    APPOINTMENT: "The journey will be triggered scheduled number of hours/days before any appointment for the concerned customer",
    tier_upgrade: "The journey will be triggered for any customer falling in the target segment when their tier gets upgraded within the scheduled duration",
    profile_completion: "The journey will be triggered for any customer falling in the target segment when they complete their profile within the scheduled duration",
    time_event : "The journey will be activated for all customers falling in the targeted segment as per the selected schedule.",
    MEETING: "The journey will be triggered scheduled number of hours/days before any meeting for the concerned customer",
    
  };
  showAdditionalTriggerConfig = false;
  showAdditionalTriggerConfig2 = false;
  isAddButtonClicked = false;
  additionalTriggerConfigs;
  preSelectedLogicalExpressions = null;
  apiProductList = [];
  isProductsLoading = false;
  productListApiData = {
    pageNo:1,
    pageSize:1500,
    maxProductListPrize:null,
  }
  disableSaveConfigOnLeftPanel = false;
  storeCreatedSegments= [];
  otherSegments = [];
  storeCreatedCustomerLists = [];
  otherCustomerLists= [];
  storeId;
  platform;
  selectedABConfigType = 'MANUAL';
  expWindowOption = 'custom';
  selectedKPI;
  distributionConfigOptions= [];
  kpiOptions = [];
  remainingPercentage = 80;

  protected _onDestroy = new Subject<void>();
  constructor(
    private apiCallService: ApiCallService,
    private snackbar: SnackbarCollection,
    private dialog: MatDialog,
    private eventIconMappingService: EventIconMappingService,
    private tokenStorage: TokenStorageService,
    private changeDetection: ChangeDetectorRef,
    private datePipe: DatePipe,
    private _TokenStorageService: TokenStorageService,
    private _WidgetConfigService: WidgetConfigService,
    private create360dialog: create360dialogaccoountService,
    private ren: Renderer2,
    private _router: Router,
    private _i18nDynamicTranslate: TranslateDynamicText,
    private date_format: getdateformatService,
    private matSnackbar: MatSnackBar,
    private _dateFormatService: DateFormatService
  ) {
    this.getScreenSize();
  }
  // @HostListener("document:click", ["$event"])
  // onGlobalClick(event): void {
  //   if (this.firstNavTitle === 'ADD NEW CONDITION') {
  //     if (this.elementViewProfile.nativeElement.contains(event.target)) {
  //     } else {
  //       this.toggleSelectSegment(1);
  //     }
  //   }
  // }

  async ngOnInit() {
    this.storeId = this.tokenStorage.getqId() === 'null' ? null : this.tokenStorage.getqId();
    this.getCustomerSegmentsAndTiers();
    this.addFilter('ALL');
    let revCurrency = sessionStorage.getItem("serviceCurrency");
    this.currencySymbol = getCurrencySymbol(revCurrency, "narrow");
    this.shopName = this.tokenStorage.getShopName();
    if(this.tokenStorage.getMerchantOnlineStore() === 'clover'){
      this.smsCheckbox = false;
    } else {
      this.smsCheckbox = true;
    }
    this.getScrrenId();
    // this.timezone = new Date()
    //   .toString()
    //   .split("(")[1]
    //   .match(/\b([A-Z])/g)
    //   .join("");
    // let event = [];
    this.timezone = this._TokenStorageService.getMerchantTimeZone();
    this.pos = this.tokenStorage.getMerchantOnlineStore();
    this.validWidgets = await this._WidgetConfigService.getWidget();
    this.getCustomerSegment();
    let currentDate = new Date();
    this.startDateMinValue = new Date(
      currentDate.toLocaleString("en-US", {
        timeZone: this._TokenStorageService.getMerchantTimeZone(),
      })
    );
    this.endDateMinValue = new Date(
      new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() + 1
      ).toLocaleString("en-US", {
        timeZone: this._TokenStorageService.getMerchantTimeZone(),
      })
    );
    this.endMinTime;
    this.startMaxTime = "23:58";
    if(this.dateRadioOption === 2){
      this.endMinTime = this.addMinutes(this.startMinTime, 1);
    }
    else{
      this.endMinTime = "00:01";
    }
    // this.endMinTime = "00:01";
    this.currentDateTimeMinValue = this.startDateMinValue.toTimeString();
    this.startMinTime = this.addMinutes(
      this.startDateMinValue.toTimeString(),
      0
    );
    // this.endMinTime = this.addMinutes(this.startMinTime, 1);
    this.customerEvents = this.eventIconMappingService
      .getCustomerEvents()
      .slice();
    // if (this.pos.toLowerCase() !== "shopify") {
    //   event.splice(0);
    //   event.push(this.customerEvents[3]);
    //   this.customerEvents = event;
    // }
    let hh = this.currentDateTimeMinValue.split(":")[0];
    let mm = this.currentDateTimeMinValue.split(":")[1].split(":")[0];
    this.startTime.setValue(hh + ":" + mm);
    this.getInitData();
    this.eventName.setValue(this.customerEvents[10]);
    this.filteredcustomerEvents.next(this.customerEvents.slice());
    this.customerEventFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterCustomerEvents();
      });
    this.startDate.setValue(
      new Date(
        currentDate.toLocaleString("en-US", {
          timeZone: this._TokenStorageService.getMerchantTimeZone(),
        })
      )
    );
    this.endDateMinValue.setDate(this.startDate.value.getDate() + 1);
    this.endDate.setValue(
      new Date(new Date().setMonth(new Date().getMonth() + 6))
    );

    if (this.nodeType != "TRIGGER") {
      if (this.type == "CREATE") {
        this.secondNavTitle = "ADD BLOCK";
      } else if (this.nodeType == "RESPONSE_EVENT") {
        this.secondNavTitle = "EDITING CONDITIONAL NODE";
      }else if (this.nodeType == "AUDIENCESPLIT") {
        this.secondNavTitle = "EDITING AUDIENCE SPLIT NODE";
      } else if (this.nodeType == "ABTESTING") {
        this.secondNavTitle = "EDITING A/B TESTING NODE";
      }  
      else {
        this.secondNavTitle =
          "EDITING " + this.nodeType.toUpperCase() + " NODE";
      }
      this.firstNavTitle = this.nodeType + " TEMPLATES";
    } else {
      this.secondNavTitle = "TRIGGER CONDITIONS";
      if (this.type == "CREATE") {
        this.firstNavTitle = "ADD NEW CONDITION";
      } else {
        this.firstNavTitle = "EDIT CONDITION";
      }
    }
    this.showSecondNav = true;
    this.arrayDynamic = [];
    this.weekDaysChips = this.weekDaysChips.map((x) => {
      x.selected = this.weekdays.includes(x.name);
      return x;
    });
    this.startDateChange();
    
    if(this.nodeData && this.nodeType === "RESPONSE_EVENT"){
      var newNodeData = JSON.parse(JSON.stringify(this.nodeData));
          var data: any = newNodeData.definition;
      this.collectData(data);
    }
    else{
      this.responseEventGroup.config = {};
      this.responseEventGroup.config.conditionGroups = [];
    }
    this.checkIfOnBoarded();
    this.getProduct('');
    this.getStores();
    this.getABConfigOptions();
    
  }


  isStoresLoading = false;
  storeList = [];
  getStores(){
    this.isStoresLoading = true;
    this.apiCallService.getStoreData().subscribe((response)=>{
      this.storeList = response['body'];
      this.isStoresLoading = false;
    },(error)=>{
        this.storeList = [];
        this.isStoresLoading = true;
    })
  }

  getProduct(char, type?) { // get product list
    this.isProductsLoading = true;
    this.apiCallService
      .getOfferManagementProductsList(char,this.productListApiData.pageNo,this.productListApiData.pageSize)
      .subscribe((resultList) => {
        let data = (resultList["body"]);
        this.productListApiData.maxProductListPrize = parseInt(Object.keys(data)[0]);
        data[Object.keys(data)[0]].forEach((product)=>{this.apiProductList.push(product.productName)});
        //lazy fetch other products
        if(this.productListApiData.maxProductListPrize > this.productListApiData.pageNo * this.productListApiData.pageSize) {
          this.productListApiData.pageNo++;
          this.getProduct('');
        }
        this.isProductsLoading = false;
      }, (error) => {
        this.snackbar.openSnackBar(this._i18nDynamicTranslate.transform("Failed loading product list", ['SHARED_PAGE']),  2000);
        this.isProductsLoading = true;
       });
  }

  async getScrrenId() {
    this.validScreens = await this._WidgetConfigService.getScreen();
  }

  saveAudience() {
    if(this.audienceGroupName.value.length > 0) {
    this.submitAudience = true;
    let data = {
      merchantId: this.tokenStorage.getMerchantId(),
      audienceName: this.audienceGroupName.value,
      description: this.audienceGroupDescription.value,
      module: "Campaign",
      moduleId: this.strategyId,
      adAccountId: this.adAccountId
    };
    this.apiCallService.createFromCampaign(data).subscribe(response => {
      this.audienceId = response["body"]["audienceId"];
    })
  } else {
    this.submitAud = true;
  }
  }

  ngOnChanges() {
    this.platform = this.tokenStorage.getMerchantOnlineStore();
    var self = this;
    self.getWhatsAppTemplate();
    // self.getEmailTemplates()
    if (self.nodeType) {
      if(self.nodeType != 'CHANNEL')
        self.scrollToLocation(self.nodeType);
      else
        self.scrollToLocation(self.nodeData.definition.channelType);
    }
    if (self.nodeType == "TRIGGER") {
      if (self.nodeId) {
        var newNodeData = JSON.parse(JSON.stringify(self.nodeData));
        self.triggerGroupv2 = newNodeData.definition;
        if (
          self.triggerGroupv2 &&
          self.triggerGroupv2.config &&
          self.triggerGroupv2.config.conditionGroups &&
          self.triggerGroupv2.config.conditionGroups.length > 0
        ) {
          self.triggerNodeEditableGroupLength =
            self.triggerGroupv2.config.conditionGroups.length;
          if (self.triggerNodeEditableGroupLength == 1) {
            self.triggerNodeEditableChildLength =
              self.triggerGroupv2.config.conditionGroups[0].conditions.length;
          }
        } else {
          self.triggerNodeEditableGroupLength = 0;
          self.triggerNodeEditableChildLength = -1;
        }
      } else {
        self.triggerGroupv2.config = {};
        self.triggerGroupv2.config.conditionGroups = [];
        self.triggerNodeEditableGroupLength = 0;
        self.triggerNodeEditableChildLength = -1;
      }
    }
    // else if(self.nodeType == "RESPONSE_EVENT"){
    //   self.nodeOptionType = self.nodeType;
    //   console.log("HELLOOO23", this.nodeId)
    //   // self.responseEventGroup.config = {};
    //   // self.responseEventGroup.config.conditionGroups = [];
    //   if (self.type == "CONFIGURE") {
    //     self.disableNodeSelection = true;
    //   } else {
    //     self.disableNodeSelection = false;
    //   }
    //   if (self.nodeId) {
    //     var newNodeData = JSON.parse(JSON.stringify(self.nodeData));
    //     self.responseEventGroup = newNodeData.definition;
    //     if (
    //       self.responseEventGroup &&
    //       self.responseEventGroup.config &&
    //       self.responseEventGroup.config.conditionGroups &&
    //       self.responseEventGroup.config.conditionGroups.length > 0
    //     ) {
    //       self.responseNodeEditableGroupLength =
    //         self.responseEventGroup.config.conditionGroups.length;
    //       if (self.responseNodeEditableGroupLength == 1) {
    //         self.responseNodeEditableChildLength =
    //           self.responseEventGroup.config.conditionGroups[0].conditions.length;
    //       }
    //     } else {
    //       self.responseNodeEditableGroupLength = 0;
    //       self.responseNodeEditableChildLength = -1;
    //     }
    //   } else {
    //     self.triggerGroupv2.config = {};
    //     self.triggerGroupv2.config.conditionGroups = [];
    //     self.triggerNodeEditableGroupLength = 0;
    //     self.triggerNodeEditableChildLength = -1;
    //   }
    //   console.log("TESTT", this.responseEventGroup)
    // }
    else {
      if (self.type == "CONFIGURE") {
        self.disableNodeSelection = true;
        self.pathedit = true;
        self.splitedit = true;
      } else {
        self.disableNodeSelection = false;
      }
      if (self.nodeType == null && this.platform!='restomail' ) {
        self.nodeType = "RESPONSE_EVENT";
      }
      else if(self.nodeType == null && this.platform=='restomail'){
        self.nodeType = "AUDIENCESPLIT";
      }
      self.nodeOptionType = self.nodeType;
      if (self.nodeId) {
        var newNodeData = JSON.parse(JSON.stringify(self.nodeData));
        var data: any = newNodeData.definition;
        if (self.nodeType == "RESPONSE_EVENT") {
          // this.responseEventGroup = data
          this.collectData(data);
        } else if (self.nodeType == "WAIT") {
          self.daysControl.setValue(data.days);
          self.hoursControl.setValue(data.hours);
          self.minutesControl.setValue(data.minutes);
        } else if (self.nodeType == "CHANNEL" && data.channelType == "EMAIL") {
          self.nodeOptionType = "EMAIL";
          self.channelType = data.channelType;
          self.emailSubject.setValue(data.subject);
          self.emailTemplateId = data.emailLayoutId;
          self.emailTemplateName = data.emailTemplateName;
          self.selectedEmailTemplateName = self.emailtemplateName;
          self.selectedEmailTemplateId = self.emailTemplateId;
        } else if (self.nodeType == "CHANNEL" && data.channelType == "WHATSAPP") {
          self.getWhatsAppTemplate();
          self.nodeOptionType = "WHATSAPP";
          self.channelType = data.channelType;
          // self.emailSubject.setValue(data.subject);
          self.whatsappTemplateId = data.layoutId;
          self.whatsappTemplateName = data.smsTemplateName;
          self.selectedWhatsappTemplateName = self.whatsappTemplateName;
          self.selectedWhatsappTemplateId = self.whatsappTemplateId;
          let index = this.whatsAppTemplates.findIndex(
            (x) => x.id === data.LayoutId
          );
          // self.whatsappLayout = 
        }
         else if (self.nodeType == "CHANNEL" && data.channelType == "SMS") {
          self.getSMSTemplates()
          self.nodeOptionType = "SMS";
          self.channelType = data.channelType;
          self.layoutId = data.layoutId;
          self.selectedSmsTemplateId = data.layoutId;
          self.smsTemplateName = data.smsTemplateName;
          self.selectedSmsTemplateName = self.smsTemplateName;
        } else if (self.nodeType == "OFFER") {
          self.offerTemplateId = data.offerId;
          self.offerTemplateName = data.offerTemplateName;
          self.selectedOfferTemplateName = self.offerTemplateName;
          self.selectedOfferTemplateId = self.offerTemplateId;
        } else if (self.nodeType == "SURVEY") {
          self.surveyTemplateId = data.surveyTemplateId;
          self.surveyTemplateName = data.surveyTemplateName;
          self.selectedSurveyTemplateName = self.surveyTemplateName;
          self.selectedSurveyTemplateId = self.surveyTemplateId;
          self.surveyEmailInstanceId = data.layoutId;
          self.surveyTitle.setValue(data.surveyTitleName);
          self.surveyEmailSubject.setValue(data.surveyEmailSubject);
        } else if (self.nodeType == "AUDIENCESPLIT") {
          self.groupNameGroup = data.groupName;
          self.splitGroup = data.splitGroups;
        } else if (self.nodeType == "CUSTOM_AUDIENCE") {
          self.audienceGroupName.setValue(data.audienceName);
          self.audienceGroupDescription.setValue(data.audienceDesc);
        } else if(self.nodeType == "ABTESTING"){
          self.collectAbTestingData(data);
        } else if(self.nodeType == "LOYALTY") {
          self.getAndInitLoyaltyData(data);
        }
      }
    }
  }

  radioChange1(event, element) {
    this.apiCallService.getMerchantProfileData().subscribe(response => {
      if(response['body']['facebookAdAccountId'] === null)
      this.openConnectSocialProfilesDialog(element);
else
this.adAccountId = response['body']['facebookAdAccountId'];
    })
  }

  openConnectSocialProfilesDialog(element) {
    const dialogref = this.dialog.open(
      SocialMediaConnectChannelDialogComponent,
      {
        panelClass: "no-padding-dialog-popup",
        height: "auto",
        disableClose: true,
        width: "50%",
        data: {
          connectAds: true,
          refresh: false,
          textMsg: null
        }
      }
    );

    dialogref.afterClosed().subscribe((result) => {
      if (result) {
      //  this.authenticateFB();
      this.redirectForFbOauth(); // this is for redirecting to facebook oauth
      } else {
        setTimeout(() => {
            element.checked = false;
            this.ren.removeClass(element['_elementRef'].nativeElement, 'cdk-focused');
            this.ren.removeClass(element['_elementRef'].nativeElement, 'cdk-program-focused');
            this.nodeOptionType = "";
        })
      }
    });
  }

  redirectForFbOauth(){
    let url = `https://www.facebook.com/v14.0/dialog/oauth?client_id=`+environment.socialFBAppId+`&redirect_uri=
    `+window.location.protocol +
    "//" +
    environment.Origin +`/app/social-eng?channel=facebook&state=true:` + this.strategyId + `&display=popup&scope=`+environment.fbScope;
        window.open(url,"_self");
  }

  async collectData(data?) {
    var self = this;
    // var newNodeData = JSON.parse(JSON.stringify(this.nodeData));
    // var data: any = newNodeData.definition;
    // this.responseEventGroup = await data;
    // let segmentName = [];
    // let segment = [];

    // for (let i = 0; i < data["config"]["conditionGroups"].length; i++) {
    //   for (
    //     let j = 0;
    //     j < data["config"]["conditionGroups"][i]["conditions"].length;
    //     j++
    //   ) {
    //     if (
    //       data["config"]["conditionGroups"][i]["conditions"][j]["rules"][
    //         "EVENT"
    //       ]["event"] === "segment"
    //     ) {
    //       for (
    //         let k = 0;
    //         k <
    //         data["config"]["conditionGroups"][i]["conditions"][j]["rules"][
    //           "EVENT"
    //         ]["segments"].length;
    //         k++
    //       ) {
    //         if (
    //           data["config"]["conditionGroups"][i]["conditions"][j]["rules"][
    //             "EVENT"
    //           ]["segments"][k] === "ALL"
    //         ) {
    //           segmentName.push("All customers");
    //           break;
    //         } else {
    //           // for (let i = 0; i < segmentIds.length; i++) {
    //           segment.push(
    //             this.customerSegment[
    //               this.customerSegment.findIndex(
    //                 (x) =>
    //                   x.id ===
    //                   data["config"]["conditionGroups"][i]["conditions"][j][
    //                     "rules"
    //                   ]["EVENT"]["segments"][k]
    //               )
    //             ]
    //           );
    //           // if(segment)
    //           // segmentName.push(segment[k].segmentName)
    //           // }
    //         }
    //         if (segment[0] != undefined)
    //           for (let i = 0; i < segment.length; i++) {
    //             segmentName.push(segment[i]["name"]);
    //           }
    //       }
    //     }
    //   }
    // }

    if (self.nodeId && data != null) {
      self.responseEventGroup = data;
      // this.eventName.value.eventName=(data.config.conditionGroups[0].conditions[0].rules.EVENT.ruleEvent);
      if (
        self.responseEventGroup &&
        self.responseEventGroup.config &&
        self.responseEventGroup.config.conditionGroups &&
        self.responseEventGroup.config.conditionGroups.length > 0
      ) {
        self.responseNodeEditableGroupLength =
          self.responseEventGroup.config.conditionGroups.length;
        if (self.responseNodeEditableGroupLength == 1) {
          self.responseNodeEditableChildLength =
            self.responseEventGroup.config.conditionGroups[0].conditions.length;
        }
      } else {
        self.responseNodeEditableGroupLength = 0;
        self.responseNodeEditableChildLength = -1;
      }
    } else {
      this.responseEventGroup.config = {};
      this.responseEventGroup.config.conditionGroups = [];  
      self.responseNodeEditableGroupLength = 0;
      self.responseNodeEditableChildLength = -1;
    }


    // let segmentData = data.config.
    // let index = this.customerSelectedSegment.findIndex((x) => x === segment.id);
    // if (index < 0) {
    //   this.segmentName.push(segment['name']);
    //   this.customerSelectedSegment.push(segment.id);
    //   console.log("SELECT-SEGMENT", this.customerSelectedSegment)
    // } else {
    //   this.segmentName.splice(index, 1);
    //   this.customerSelectedSegment.splice(index, 1);
    // }
  }

  editSurveyEmail() {
    let sh = this.finalScreenHeight * (90 / 100);
    let sw = this.finalScreenWidth * (70 / 100);
    var datas = {
      process: "EditTextBodySurvey",
      id: this.surveyEmailTemplate,
      tags: [],
      imageTagStatus: false,
      specialTagType: "",
      type: "Survey",
      offerId: "",
      isReward: false,
      rewardType: null,
    };
    const dialogRefer = this.dialog.open(OfferAttachEmailDialogComponent, {
      panelClass: "customAttachEmail",
      height: sh + "px",
      width: this.finalScreenWidth < 1500 ? "1024px" : sw + "px",
      maxWidth: "99vw",
      disableClose: true,
      data: datas,
    });
    dialogRefer.afterClosed().subscribe((result) => {
      if (result) {
      }
    });
  }

  min(min: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (
        control.value !== undefined &&
        (isNaN(control.value) || control.value < min)
      ) {
        return { minVal: true };
      }
      return null;
    };
  }

  startDateChange() {
    
    let startDate = new Date(this.startDate.value);
    startDate.setHours(0);
    startDate.setMinutes(0);
    startDate.setSeconds(0);
    startDate.setMilliseconds(0);
    let endDate = new Date(this.endDate.value);
    endDate.setHours(0);
    endDate.setMinutes(0);
    endDate.setSeconds(0);
    endDate.setMilliseconds(0);
    if (startDate >= endDate) {
      this.endDate.setValue(
        new Date(
          this.startDate.value.getFullYear(),
          this.startDate.value.getMonth(),
          this.startDate.value.getDate() + 1
        )
      );

    }
    this.checkStartDateTime();
  }

  endDateChange() {
    let startDate = new Date(this.startDate.value);
    startDate.setHours(0);
    startDate.setMinutes(0);
    startDate.setSeconds(0);
    startDate.setMilliseconds(0);
    let endDate = new Date(this.endDate.value);
    endDate.setHours(0);
    endDate.setMinutes(0);
    endDate.setSeconds(0);
    endDate.setMilliseconds(0);
    if (endDate <= startDate) {
      this.startDate.setValue(
        new Date(
          this.endDate.value.getFullYear(),
          this.endDate.value.getMonth(),
          this.endDate.value.getDate() - 1
        )
      );
    }
    this.checkStartDateTime();
  }

  startTimeChange() {
    // if (!this.startTime.value) {
    //   return;
    // }
    // if (this.dateRadioOption == 2) {
    //   var time = this.startTime.value.split(":");
    //   var hour = +time[0];
    //   var min = +time[1];
    //   if (min == 59) {
    //     min = 0;
    //     hour = hour + 1;
    //   } else {
    //     min = min + 1;
    //   }
    //   this.endMinTime = hour + ":" + min;
    // }
    let currentTime = new Date();
    let startTime = new Date();
    startTime.setHours(+this.transformTo24HourFormat(this.startTime.value).split(":")[0]);
    startTime.setMinutes(+this.transformTo24HourFormat(this.startTime.value).split(":")[1]);
    startTime.setSeconds(0);
    startTime.setMilliseconds(0);
    let endTime = new Date();
    endTime.setHours(+this.transformTo24HourFormat(this.endTime.value).split(":")[0]);
    endTime.setMinutes(+this.transformTo24HourFormat(this.endTime.value).split(":")[1]);
    endTime.setSeconds(0);
    endTime.setMilliseconds(0);
    if (endTime <= startTime) {
      let hour: any = +this.transformTo24HourFormat(this.startTime.value).split(":")[0];
      let minute: any = +this.transformTo24HourFormat(this.startTime.value).split(":")[1];
      if (minute === 59) {
        minute = "00";
        hour += 1;
      } else {
        minute += 1;
      }
      if (hour < 10) {
        hour = "0" + hour;
      }
      this.endTime.setValue(this.transformTo12HourFormat(hour + ":" + (minute < 10 ? '0' + minute : minute.toString())));
      //this.endMinTime=this.endTime.value;
    }
  }

  endTimeChange() {
    let currentTime = new Date();
    let startTime = new Date(this.startDate.value);
    startTime.setHours(+this.transformTo24HourFormat(this.startTime.value).split(":")[0]);
    startTime.setMinutes(+this.transformTo24HourFormat(this.startTime.value).split(":")[1]);
    startTime.setSeconds(0);
    startTime.setMilliseconds(0);
    let endTime = new Date(this.endDate.value);
    endTime.setHours(+this.transformTo24HourFormat(this.endTime.value).split(":")[0]);
    endTime.setMinutes(+this.transformTo24HourFormat(this.endTime.value).split(":")[1]);
    endTime.setSeconds(0);
    endTime.setMilliseconds(0);
    if (endTime <= startTime) {
      let hour: any = +this.transformTo24HourFormat(this.endTime.value).split(":")[0];
      let minute: any = +this.transformTo24HourFormat(this.endTime.value).split(":")[1];
      if (minute === 0) {
        minute = "59";
        hour -= 1;
      } else {
        minute -= 1;
      }
      if (hour < 10) {
        hour = "0" + hour;
      }
      this.startTime.setValue(this.transformTo12HourFormat(hour + ":" + (minute < 10 ? '0' + minute : minute.toString())));
    }
    // if (!this.endTime.value) {
    //   return;
    // }
    // if (this.dateRadioOption == 2) {
    //   var time = this.endTime.value.split(":");
    //   var hour = +time[0];
    //   var min = +time[1];
    //   if (min == 0) {
    //     min = 59;
    //     hour = hour - 1;
    //   } else {
    //     min = min - 1;
    //   }
    //   this.startMaxTime = hour + ":" + min;
    // }
  }

  checkStartDateTime() {
    if (
      this.startDate.value.setHours(0, 0, 0, 0) ==
      this.startDateMinValue.setHours(0, 0, 0, 0)
    ) {
      this.startMinTime = this.addMinutes(this.currentDateTimeMinValue, 0);
      if(this.dateRadioOption === 2){
        this.endMinTime = this.addMinutes(this.startMinTime, 1);
        let hh = this.currentDateTimeMinValue.split(":")[0];
        let mm = this.currentDateTimeMinValue.split(":")[1].split(":")[0];
        this.startTime.setValue(hh + ":" + mm);
      }
      else{
        this.endMinTime = "00:01";
      }
      if (
        this.dateCompareSub(
          this.currentDateTimeMinValue,
          this.startTime.value
        ) == 1
      ) {
        this.startTime.setValue(null);
      }
      if (
        this.endTime.value != null &&
        this.dateCompareSub(this.currentDateTimeMinValue, this.endTime.value) ==
          1
      ) {
        this.endTime.setValue(null);
      }
    } else {
      this.startMinTime = "00:00";
      if(this.dateRadioOption === 2){
        this.endMinTime = this.addMinutes(this.startMinTime, 1);
      }
      else{
        this.endMinTime = "00:01";
      }
    }
  }
  getEmailTemplates() {
    var self = this;
    let AllEmailLayoutList
    self.apiCallService.listEmailLayout().subscribe(
      (response) => {
        AllEmailLayoutList = response["body"];
        AllEmailLayoutList.forEach((layout) => {
        if (layout["type"] != "SURVEY") {
          self.emailTemplates.push(layout);
        }
      });
      },
      (err) => {}
    );
    let rewardTemplateList
    let rewardAPI = this.apiCallService.getRewardEmailList().subscribe((result) => {
      rewardTemplateList = result["body"];
      rewardTemplateList.forEach((layout) => {
        if (layout["emailType"] != "STANDARD" && layout["type"] == "GENERIC") {
          this.emailCustomeTemplates.push(layout);
        }
      });
    });
    self.apiCallService.getSurveyEmailTemplate().subscribe((response) => {
      this.surveyEmailTemplate = response["body"][0];
      self.changeDetection.markForCheck();
    });
  }
  getWhatsAppTemplate(){
    this.apiCallService.getWhatsAppTemplates("CAMPAIGN").subscribe((response)=>{
      this.whatsAppTemplates = response['body'];
    })
  }
  getSMSTemplates(){
    this.apiCallService.getSmsTemplates("SMS",null).subscribe((response)=>{
      let datasmsLayouts = response['body'];
      this.smsLayouts = datasmsLayouts.filter((obj) => {
        return(
          obj.layoutType === 'CUSTOM' && obj.subLayout==='GENERIC'
        );
       
        // if (!query && this.filters.length > 0) {
        //   return (
        //     this.filters.indexOf(obj.emailType) !== -1
        //   )
        // }
        // if(!query && this.filters.length == 0){
        //   return obj;
        // }
        // if(query && this.filters.length > 0){
        //   return (
        //     obj.layoutName.toLowerCase().indexOf(query.toLowerCase()) !== -1 && this.filters.indexOf(obj.emailType) !== -1
        //   );
        // }
        // if(query&& this.filters.length == 0){
        //   return (
        //     obj.layoutName.toLowerCase().indexOf(query.toLowerCase()) !== -1
        //   );
        // }
      });
    })
  }

  getIcon(eventName: string) {
    return this.eventIconMappingService.getEventIconName(eventName);
  }

  getImage(eventName) {
    switch (eventName) {
      case "add_cart":
        return "add_shopping_cart";
      case "create_checkout":
        return "shopping_cart";
      case "sign_up":
        return "person_add";
      case "create_orders":
        return "check_circle";
      case "recentlyviewed_products":
        return "visibility";
      case "search_products":
        return "search";
      case "time_event":
        return "timer";
      case "create_customer":
        return "person_add";
      case "update_checkout":
        return "update";
      case "BIRTHDAY":
        return "cake";
      case "ANNIVERSARY":
        return "celebration"
      case "lead_created":
        return "group";
      case "APPOINTMENT":
        return "calendar_clock";
      case "MEETING":
        return "calendar_clock";
      case "tier_upgrade":
        return "hotel_class";
      case "proposal_won":
        return "beenhere";
      case "proposal_active":
        return "domain_verification";
      case "proposal_lost":
        return "contract_delete";
      default:
        return "check_circle";
    }
  }

  toggleSelection(day: any) {
    day.selected = !day.selected;
    this.arrayDynamic = [];
    for (let day of this.weekDaysChips) {
      if (day.selected) {
        this.arrayDynamic.push(day.name);
      }
    }
  }

  changeChildOperator(j: number, operator: string) {
    if (this.nodeType == "TRIGGER")
      this.triggerGroupv2.config.conditionGroups[j].conditionsRelation =
        operator;
    else
      this.responseEventGroup.config.conditionGroups[j].conditionsRelation =
        operator;
  }

  changeParentOperator(j: number, operator: string) {
    if (this.nodeType == "TRIGGER")
      this.triggerGroupv2.config.conditionGroups[j].conditionGroupRelation =
        operator;
    else
      this.responseEventGroup.config.conditionGroups[j].conditionGroupRelation =
        operator;
  }

  radioChange(event: any) {
    this.triggerNodeType = event.value;
  }

  radioNodeChange(event: any) {
    this.disableSaveConfigOnLeftPanel = false;
    this.checkIfOnBoarded();
    this.channelSelect = false;
    this.showfilter = false;
    this.showClicked = false;
    this._gaConditionString = "";
    this.gaAnalyticsBlockCondition(event.value);
    if (!event.value) {
      return;
    }
    if (event.value == "EMAIL" || event.value == "SMS" || event.value == "WHATSAPP") {
      this.channelType = event.value;
      this.nodeType = "CHANNEL";
      this.scrollToLocation(this.channelType);
    } else {
      this.nodeType = event.value;
      this.scrollToLocation(this.nodeType);
    }
    this.submitted = false;
    if (event.value == "EMAIL") {
      this.filteredData = this.emailTemplates.concat(this.emailCustomeTemplates);
      // this.filterData = [...this.emailTemplates, ...this.emailCustomeTemplates];
      this.firstNavTitle = "EMAIL TEMPLATES";
    } else if (event.value == "SMS") {
      this.filteredData = this.smsLayouts;
      this.firstNavTitle = "SMS TEMPLATES";
    } else if (event.value == "WHATSAPP") {
      this.filteredData = this.whatsAppTemplates;
      this.firstNavTitle = "WHATSAPP TEMPLATES";
    } 
    else if (event.value == "TRIGGER") {
      this.showFirstNav = false;
    } else if (event.value == "RESPONSE_EVENT") {
      this.firstNavTitle = "ADD NEW CONDITION"
      this.customerEvents = this.customerEvents.filter(
        (x) => x.eventName !== "create_customer"
      );
      this.filteredcustomerEvents.next(this.customerEvents.slice());
      if (this.type == "CREATE") {
        this.daysControl.setValue(0);
        this.hoursControl.setValue(0);
        this.minutesControl.setValue(0);
      }
    } else if (event.value == "WAIT") {
      if (this.type == "CREATE") {
        this.daysControl.setValue(0);
        this.hoursControl.setValue(0);
        this.minutesControl.setValue(0);
      }
    } else if (event.value == "OFFER") {
      this.firstNavTitle = "OFFER TEMPLATES";
      //Show only saved offers
      this.filteredData = this.offers.filter(offer=>offer.status.toLowerCase() != 'sent');
    } else if (event.value == "SURVEY") {
      this.firstNavTitle = "SURVEY TEMPLATES";
      this.filteredData = this.surveyTemplates;
    } else if (event.value == "ABTESTING") {
      this.firstNavTitle = "CONFIGURE AB TESTING";
      // this.prefillData();
    } else if (event.value == "AUDIENCESPLIT") {
      this.firstNavTitle = "ADD NEW CONDITION";
      // this.getAttribute();
    }else if (event.value == "COMARKETING_OFFER") {
      this.filteredData = this.coMarketingOffers;

      this.firstNavTitle = "Select Co-Marketing Offers";
      // this.getAttribute();
    }
    this.showFirstNav = false;
  }

  scrollToLocation(id) {
    if (this.allowScroll) {
      let interval = setInterval(() => {
        let element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "start",
          });
          clearInterval(interval);
        }
      }, 10);
    }
  }

  onDateRadioChange(event: any) {
    this.dateRadioOption = +event.value;
    if(this.dateRadioOption === 2){
      this.endMinTime = this.addMinutes(this.startMinTime, 1);
    }
    else{
      this.endMinTime = "00:01";
    }
    if (
      this.parentConditionIndex != -1 &&
      this.childConditionIndex != -1 &&
      this.triggerGroupv2.config.conditionGroups[this.parentConditionIndex]
        .conditions[this.childConditionIndex].rules.TIME
    ) {
      if (
        (this.editDateRadioOption == 2 && this.dateRadioOption == 1) ||
        (this.editDateRadioOption == 1 && this.dateRadioOption == 2)
      ) {
        // this.startTime.setValue("08:00");
        // this.endTime.setValue("20:00");
      } else {
        var sTime: string ;
        //   sTime = this.triggerGroupv2.config.conditionGroups[this.parentConditionIndex]
        //     .conditions[this.childConditionIndex].rules.TIME.ruleTimes[0];
        // this.startTime.setValue(sTime);
        this.checkStartDateTime();
        if (
          this.triggerGroupv2.config.conditionGroups[0].conditions[0].rules
            .EVENT.ruleEvent == "time_event"
        ) {
          // this.endTime.setValue("20:00");
        } else {
          var eTime: string =
            this.triggerGroupv2.config.conditionGroups[
              this.parentConditionIndex
            ].conditions[this.childConditionIndex].rules.TIME.ruleTimes[1];
          this.endTime.setValue(eTime);
        }
      }
    }
    //this.checkStartDateTime();
  }

  attachTemplate(type: String) {
    if(this.tokenStorage.getIsAccountCreated() === 'false' && type==='WHATSAPP'){
      this.checkAccountCreated();
      return;
    }
    this.showSecondNav = true;
    this.showFirstNav = true;
    if (this.nodeType == "CHANNEL" && type == "EMAIL") {
      if (this.selectedEmailTemplateId != -1) {
        let allemails = this.emailTemplates.concat(this.emailCustomeTemplates);
        var data: any = allemails.find(
          (x) => x.id == this.selectedEmailTemplateId
        );
        allemails = allemails.filter(
          (x) => x.id !== this.selectedEmailTemplateId
        );
        allemails.unshift(data);
        this.filteredData = allemails;
        this.changeDetection.detectChanges();
      }
    } else if (type == "SMS") {
      if (this.selectedSmsTemplateId != -1) {
        var data: any = this.smsLayouts.find(
          (x) => x.id == this.selectedSmsTemplateId
        );
        this.smsLayouts = this.smsLayouts.filter(
          (x) => x.id !== this.selectedSmsTemplateId
        );
        this.smsLayouts.unshift(data);
        this.filteredData = this.smsLayouts;
        this.changeDetection.detectChanges();
      }
    } else if (this.nodeType == "OFFER") {
      this.filteredData = this.offers.filter(offer=>offer.status.toLowerCase() != 'sent');
      if (this.selectedOfferTemplateId != -1) {
        var data: any = this.filteredData.find(
          (x) => x.id == this.selectedOfferTemplateId
        );
        this.filteredData = this.filteredData.filter(
          (x) => x.id != this.selectedOfferTemplateId
        );
        this.filteredData.unshift(data);
        this.changeDetection.detectChanges();
      }
    } else if (this.nodeType == "SURVEY") {
      if (this.selectedSurveyTemplateId != -1) {
        var data: any = this.surveyTemplates.find(
          (x) => x.id == this.selectedSurveyTemplateId
        );
        this.surveyTemplates = this.surveyTemplates.filter(
          (x) => x.id !== this.selectedSurveyTemplateId
        );
        this.surveyTemplates.unshift(data);
        this.filteredData = this.surveyTemplates;
        this.changeDetection.detectChanges();
      }
    } else if (type === 'WHATSAPP'){
      if (this.selectedWhatsappTemplateId != -1) {
        var data: any = this.whatsAppTemplates.find(
          (x) => x.id == this.selectedWhatsappTemplateId
        );
        this.whatsappLayout = this.whatsAppTemplates.filter(
          (x) => x.id !== this.selectedWhatsappTemplateId
        );
        this.whatsappLayout.unshift(data);
        this.filteredData = this.whatsAppTemplates;
        this.changeDetection.detectChanges();
      }
    }
  }

  selectTemplate(
    type: string,
    id: number,
    templateName: string,
    layout: any,
    title?: string,
    layoutData?,
    templateSource?:string
  ) {
    if (type === "CHANNEL") {
      this.selectedEmailTemplateId = id;
      this.selectedEmailTemplateName = templateName;
    } else if (type === "SMS") {
      this.selectedSmsTemplateId = id;
      this.selectedSmsTemplateName = templateName;
      this.selectedSmsLayout = layout;
    } else if (type === "SURVEY") {
      this.selectedSurveyTemplateId = id;
      this.selectedSurveyTemplateName = templateName;
      this.selectedSurveyTitle = title;
    } else if (type === "OFFER") {
      this.selectedOfferTemplateId = id;
      this.selectedOfferTemplateName = templateName;
    }
    else if (type === "WHATSAPP") {
      this.selectedWhatsappTemplateId = id;
      this.selectedWhatsappTemplateName = templateName;
      this.selectedWhatsappLayout = layout;
      this.selectedWhatsapptemplateSource=templateSource;
    }
    else if( type ==='COMARKETING_OFFER'){
      if (this.selectedCoMarketingTemplateId.indexOf(id) == -1){
        this.selectedCoMarketingTemplateId.push(id);
        this.selectedComarketingOffers.push(layoutData);
        this.selectedCoMarketingTemplateName = templateName;
      }
      else {
        const index: number = this.selectedCoMarketingTemplateId.indexOf(id);
        if (index !== -1) {
          this.selectedCoMarketingTemplateId.splice(index, 1);
          this.selectedComarketingOffers.splice(index, 1);
        }
      }
    }
  }
  selectTemplateComarketing(id, data){
    if (this.selectedCoMarketingTemplateId.indexOf(id) == -1){
      this.selectedCoMarketingTemplateId.push(id);
      this.selectedComarketingOffers.push(data);
    }
    else {
      const index: number = this.selectedCoMarketingTemplateId.indexOf(id);
      if (index !== -1) {
        this.selectedCoMarketingTemplateId.splice(index, 1);
        this.selectedComarketingOffers.splice(index, 1);
      }
    }
  }

  getInitData() {
    var self = this;
    if (self.nodeType == "TRIGGER") {
      self.customerEvents.unshift({
        eventName: "time_event",
        displayName: "Time based Journey",
      });
      // if (!self.isAllCustomers) {
      //   self.customerEvents = this.customerEvents.filter(
      //     (x) => x.eventName !== "create_customer"
      //   );
      // }
    } else if (self.nodeType == "RESPONSE_EVENT") {
      // self.customerEvents = this.customerEvents.filter(
      //   (x) => x.eventName !== "create_customer"
      // );
      self.filteredcustomerEvents.next(this.customerEvents.slice());
      console.log(self.filterCustomerEvents);
    } 
    self.getEmailTemplates();
    self.getWhatsAppTemplate();
    self.getSMSTemplates();
    self.getComarketingOfferTemplate();
    self.apiCallService.getAllSurveyTemplates().subscribe(
      (response) => {
        self.surveyTemplates = response["body"];
      },
      (err) => {}
    );
    this.getOfferData();
    self.apiCallService.getMerchantDetails().subscribe((response) => {
      let data = JSON.parse(response["body"]);
      this.countryCode = data.country;
    });
    if(self.type != 'CONFIGURE')
      self.getAndInitLoyaltyData();
    // self.getCustomerSegmentVariables();
    // self.apiCallService.getSmsTemplates("SMS", "SMS").subscribe((response) => {
    //   self.smsLayouts = response["body"];
    // });
  }
  getOfferData(refreshAndSelectOfferId?) {
    let self = this;
    self.apiCallService.getOfferDispatchOffers().subscribe(
      (response) => {
        var offersData: any = (response["body"]);
        var offersArray: any[] = offersData.offers;
        self.offers = [];
        let interval = setInterval(() => {
          if (this.countryCode) {
            clearInterval(interval);
            // if (
            //   (this.countryCode === "IN" ||
            //     this.countryCode === "CA" ||
            //     this.countryCode === "US") &&
            //   this.smsCheckbox == true
            // ) {
              for (var i = 0; i < offersArray.length; i++) {
                // if (
                //   (offersArray[i].channel !== null || offersArray[i].channelList !== null)
                // ) {
                  self.offers.push(offersArray[i]);
                // }
              }
            // } else {
            //   for (var i = 0; i < offersArray.length; i++) {
            //     if (offersArray[i].channel == "EMAIL") {
            //       self.offers.push(offersArray[i]);
            //     }
            //   }
            // }
            if(refreshAndSelectOfferId) {
              this.filteredData = this.offers;
              let offerIndex = this.filteredData.findIndex((offer)=>refreshAndSelectOfferId == offer.id);
              if(offerIndex !== -1) {
                const offer = this.filteredData.splice(offerIndex,1)[0];
                this.filteredData.unshift(offer);
                this.selectTemplate('OFFER', refreshAndSelectOfferId, offer.offerName, '');
              }
            }
            self.changeDetection.detectChanges();
          }
        });
      },
      (err) => {}
    );
  }


  getAndInitLoyaltyData(data?) {
    let self = this;
    this.apiCallService.getLoyaltyProgramConfig().subscribe(
      response=>{
        if(response['body'] && !response['body'].partialUpdate && response['body'].tierConfig.tierMap.length>0) {
          self.loyaltyProgramConfig['fullConfig'] = response['body'];
          self.loyaltyProgramConfig['loyaltyRules'] = response['body'].ruleConfig.loyaltyRules;
          self.loyaltyProgramConfig['buttonType'] = null;
          this.resetLoyaltyData();
          if(data?.type) {
            this.loyaltyNodeData = data;
          }
          self.changeDetection.detectChanges();
          // this.apiCallService.getLoyaltyProgramDropdowns().subscribe(response => {
          //   let responseData = response["body"];
          //   this.ruleFrequencies = responseData.eventFrequencies
          //   this.ruleTypeDropdownValues = responseData.ruleTypes;
          //   const events: any[] = Object.values(responseData.loyaltyEvents);
          //   for (let event of events) {
          //     event.forEach((each) => {
          //       if(each.displayName.toLowerCase() === 'amount spent') {
          //         each.displayName = `${this.currencySymbol} spent in a purchase`;
          //       }
          //       this.ruleEventDropdownValues.push({
          //         name: each.name,
          //         displayName: each.displayName,
          //         valueEditable: each.valueEditable,
          //         defaultValue: each.defaultValue,
          //         operators: each.operators,
          //         dataType: each.dataType,
          //       })
          //     });
          //   }
          //   self.loyaltyProgramConfig['loyaltyRules'].forEach(rule=>{
          //     if(rule.ruleCategory == 'BASIC') {
          //       this.ruleEventDropdownValues.forEach((curDropdownVal)=>{
          //         if(curDropdownVal.name == rule.ruleDefinition.event) {
          //           rule.ruleDefinition.eventDropdownObj = curDropdownVal;
          //           return;
          //         }
          //       });
          //     } else {
          //       rule.ruleDefinition.loyaltyRuleSegmentDefinition.logicalExpressions.forEach((logicalExpress)=>{
          //         logicalExpress.comparisonStatements.forEach((comparisonStatement)=>{
          //           this.ruleEventDropdownValues.forEach((curDropdownVal)=>{
          //             if(curDropdownVal.name == comparisonStatement.operand1) {
          //               comparisonStatement.eventDropdownObj = curDropdownVal;
          //               return;
          //             }
          //           });
          //         });
          //       });
          //       for(let freqObj of this.ruleFrequencies as any) {
          //         if(rule.ruleFrequency === freqObj.value) {
          //           rule['ruleFrequencyDisplay'] = freqObj.key;
          //         }
          //       }
          //     }
          //   });
          //   self.changeDetection.detectChanges();
          // },
          // error => {
          //   this.snackbar.openSnackBar("Error fetching loyalty events dropdown data", 2000);
          // });
        } else if(response['body'] && response['body'].partialUpdate) {
          self.loyaltyProgramConfig['buttonType'] = 'finish';
        } else if(!response['body']) {
          self.loyaltyProgramConfig['buttonType'] = 'create';
        }
      },
      error=>{
        this.snackbar.openSnackBar("Error fetching loyalty config", 2000);
      }
    );
  }


  openSnackBar(message: string, action: string) {
    this.snackbar.openSnackBar(this._i18nDynamicTranslate.transform(message, ['POS_PAGE']), 2000);
  }

  saveNodeConfig(status: boolean) {
    if((this.nodeType === "CUSTOM_AUDIENCE" && !status) || (this.nodeType === "CUSTOM_AUDIENCE" && this.audienceId !== null) || this.nodeType !== "CUSTOM_AUDIENCE") {
    var self = this;
    if (self.isLoading) {
      return;
    }
    var strategyNodeRequest: StrategyNodeRequest = new StrategyNodeRequest();
    strategyNodeRequest.strategyId = self.strategyId;
    strategyNodeRequest.previousNodeId = self.previousNodeId;
    strategyNodeRequest.previousNodeRelation = self.previousNodeRelation;
    strategyNodeRequest.nodeType = self.nodeType;
    strategyNodeRequest.merchantId = self.tokenStorage.getMerchantId();
    var data: any = {};
    data.previousNodeId = self.previousNodeId;
    data.previousNodeRelation = self.previousNodeRelation;
    data.nodeType = self.nodeType;
    data.nextNodeId = self.nextNodeId;
    data.actionType = self.type;
    data.error = false;
    if (status) {
      data.delete = false;
      self.submitted = true;
    } else {
      data.delete = true;
      self.submitted = false;
      self.nodeConfiguredData.emit(data);
      return;
    }
    if (self.nodeType == "CHANNEL" && self.channelType == "EMAIL") {
      if (self.emailSubject.invalid) {
        return;
      } else if (self.emailTemplateId == -1 || self.emailTemplateName == "") {
        return;
      }
    } else if (self.nodeType == "CHANNEL" && self.channelType == "SMS") {
      if (self.smsTemplateId == -1 || self.smsTemplateName == "") {
        return;
      }
    }else if (self.nodeType == "CHANNEL" && self.channelType == "WHATSAPP") {
      if (self.whatsappTemplateId == -1 || self.whatsappTemplateName == "") {
        return;
      }
    } 
    else if (self.nodeType == "SURVEY") {
      if (!self.channelData) {
        return;
      } else if (self.surveyTemplateId == -1 || self.surveyTemplateName == "") {
        return;
      }
    } else if (self.nodeType == "CUSTOM_AUDIENCE") {
      if (self.audienceGroupName.value.length == 0) {
        return;
      }
    } else if (self.nodeType == "OFFER") {
      if (self.offerTemplateId == -1 || self.offerTemplateName == "") {
        return;
      }
    } else if (self.nodeType == "RESPONSE_EVENT") {
      if (
        this.actionStatus === "action" &&
        self.responseEventGroup.config.conditionGroups.length === 0
      )
        return;
    } else if (
      self.nodeType == "WAIT" &&
      (self.daysControl.invalid ||
        (self.daysControl.value == 0 &&
          self.minutesControl.value == 0 &&
          self.hoursControl.value == 0))
    ) {
      return;
    }else if(
      self.nodeType == "ABTESTING" && this.abTesting.length === 0)
    {
      this.snackbar.openSnackBar("Please configure the node before saving.", 5000);
      return;
    }else if(
      self.nodeType == "AUDIENCESPLIT" && this.groupNameGroup.length == 0
    ){
      return;
    }
    if (!self.nodeType) {
      data.delete = false;
      self.nodeConfiguredData.emit(data);
      return;
    }
    if(self.nodeType == 'LOYALTY') {
      if(!this.loyaltyNodeData.type) {
        this.snackbar.openSnackBar("No loyalty node feature selected", 2000);
        return;
      }
      if(this.loyaltyNodeData.type == 'REWARD_POINTS') {
        if(!this.loyaltyNodeData.rewardPointsBean.points) {
          return;
        }
      }
      if(this.loyaltyNodeData.type == 'LOAYLTY_RULE' && !this.loyaltyNodeData.loyaltyRule) {
        this.snackbar.openSnackBar("No loyalty rule selected", 2000);
        return;
      }
      
    }
    //
    var nodeObjectData: any = {};
    var definition: any = {};
    if (data.nodeType == "OFFER") {
      nodeObjectData.nodeType = "OFFER";
      definition.offerId = self.offerTemplateId;
      definition.offerTemplateName = self.offerTemplateName;
      strategyNodeRequest.definition = JSON.stringify(definition);
      nodeObjectData.definition = definition;
      data.nodeData = nodeObjectData;
    } else if(data.nodeType == "CUSTOM_AUDIENCE") {
      nodeObjectData.nodeType = "CUSTOM_AUDIENCE";
      definition.audienceId = this.audienceId;
      definition.audienceName = self.audienceGroupName.value;
      definition.audienceDesc = self.audienceGroupDescription.value;
      strategyNodeRequest.definition = JSON.stringify(definition);
      nodeObjectData.definition = definition;
      data.nodeData = nodeObjectData;
    } else if (data.nodeType == "SURVEY") {
      this.apiCallService.getSurveyTemplate;
      nodeObjectData.nodeType = "SURVEY";
      definition.surveyTemplateId = this.surveyTemplateId;
      definition.surveyTemplateName = this.surveyTemplateName;
      definition.surveyTitleName = this.surveyTitle.value;
      // definition.surveyEmailSubject = this.surveyEmailSubject.value;
      // definition.layoutId = this.surveyEmailInstanceId;
      definition.communication = this.channelData.communication;
      strategyNodeRequest.definition = JSON.stringify(definition);
      nodeObjectData.definition = definition;
      data.nodeData = nodeObjectData;
    } else if (data.nodeType == "CHANNEL" && this.channelType == "EMAIL") {
      nodeObjectData.nodeType = "CHANNEL";
      definition.channelType = "EMAIL";
      strategyNodeRequest.nodeType = "CHANNEL";
      definition.subject = this.emailSubject.value;
      definition.emailLayoutId = this.emailTemplateId;
      definition.emailTemplateName = this.emailTemplateName;
      strategyNodeRequest.definition = JSON.stringify(definition);
      nodeObjectData.definition = definition;
      data.nodeData = nodeObjectData;
    } else if (data.nodeType == "CHANNEL" && this.channelType == "SMS") {
      nodeObjectData.nodeType = "CHANNEL";
      definition.channelType = "SMS";
      strategyNodeRequest.nodeType = "CHANNEL";
      definition.layoutId = this.smsTemplateId;
      definition.smsTemplateName = this.smsTemplateName;
      strategyNodeRequest.definition = JSON.stringify(definition);
      nodeObjectData.definition = definition;
      data.nodeData = nodeObjectData;
    }else if (data.nodeType == "CHANNEL" && this.channelType == "WHATSAPP") {
      this.checkIfOnBoarded();
      if(this.tokenStorage.getIsAccountCreated() === 'false'){
        const dialogRef = this.dialog.open(ConfirmationDialogBoxComponent, {
          panelClass: "no-padding-dialog-popup",
          data: {
            subject: 'WhatsApp account not linked',
            message: 'You can link WhatsApp Business account by clicking on Link.',
            cancelButtonText: "Cancel",
            successButtonText: "Link",
          }
        })
        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            this.create360dialog.createAccountNew();
          }
        });
      }
      else{
        nodeObjectData.nodeType = "CHANNEL";
        definition.channelType = "WHATSAPP";
        strategyNodeRequest.nodeType = "CHANNEL";
        definition.layoutId = this.whatsappTemplateId;
        definition.smsTemplateName = this.whatsappTemplateName;
        definition.templateType = this.whatsappTemplatetemplateSource;
        strategyNodeRequest.definition = JSON.stringify(definition);
        nodeObjectData.definition = definition;
        data.nodeData = nodeObjectData;
      }
    }
     else if (data.nodeType == "RESPONSE_EVENT") {
      nodeObjectData.nodeType = data.nodeType;
      definition = this.responseEventGroup;
      strategyNodeRequest.definition = JSON.stringify(definition);
      nodeObjectData.definition = definition;
      data.nodeData = nodeObjectData;
    } else if (data.nodeType == "TRIGGER") {
      nodeObjectData.nodeType = data.nodeType;
      definition = this.triggerGroupv2;
      nodeObjectData.definition = definition;
      strategyNodeRequest.definition = JSON.stringify(definition);
      data.nodeData = nodeObjectData;
    } else if (data.nodeType == "WAIT") {
      nodeObjectData.nodeType = data.nodeType;
      definition.timeUnit = "MINUTE";
      definition.timeoutValue = this.getTimeInMinutes();
      definition.days = self.daysControl.value;
      definition.hours = self.hoursControl.value;
      definition.minutes = self.minutesControl.value;
      strategyNodeRequest.definition = JSON.stringify(definition);
      nodeObjectData.definition = definition;
      data.nodeData = nodeObjectData;
    } else if (data.nodeType == "ABTESTING") {
      nodeObjectData.nodeType = data.nodeType;
      definition.mabType = this.selectedABConfigType;
      definition.attributionDuration = this.atrWindow;
      if(this.selectedABConfigType!='MANUAL'){
        let mabRequest:any = {};
        mabRequest.selectedKpi = this.selectedKPI;
        if (this.isTimeEvent) {
          mabRequest.waitTimePeriod = this.waitWindow;  // Set only if isTimeEvent is true
      }
        definition.mabRequest = mabRequest
      }
      
      this.pathGroup = {};
      for(let i =0;i<this.abTesting.length;i++) {
        let u :any = {};
        u.pathName = this.abTesting[i]['pathName'];
        u.weight = this.abTesting[i]['percValue'];
        u.pathId = null;
        u.nextNodeId = null;
        this.pathGroup["Path-"+(i+1)] = u;
      }
      definition.pathGroups = this.pathGroup;
      definition.randomSplit = false;
      nodeObjectData.definition = definition;
      data.nodeData = nodeObjectData;
      strategyNodeRequest.definition = JSON.stringify(definition);
    } else if (data.nodeType == "AUDIENCESPLIT") {
      nodeObjectData.nodeType = data.nodeType;
      definition.groupName = this.groupNameGroup;
      definition.splitGroups = this.splitGroup;
      nodeObjectData.definition = definition;
      data.nodeData = nodeObjectData;
      strategyNodeRequest.definition = JSON.stringify(definition);
    } else if(data.nodeType == "COMARKETING_OFFER") {
      nodeObjectData.nodeType = "COMARKETING_OFFER";
      definition.offers = self.coMarketingOffer;
      strategyNodeRequest.definition = JSON.stringify(definition);
      nodeObjectData.definition = definition;
      data.nodeData = nodeObjectData;
    }
    else if(data.nodeType == 'LOYALTY') {
      if(this.loyaltyNodeData) {
        //if its a rule then validate and save node
        if(status && this.loyaltyNodeData.type =='LOYALTY_RULE') {
          this.validateLoyaltyRule(this.loyaltyNodeData.loyaltyRule,self.nodeId,data,strategyNodeRequest);
        } else {
          definition = this.loyaltyNodeData;
          strategyNodeRequest.definition = JSON.stringify(definition);
          nodeObjectData.nodeType = data.nodeType;
          nodeObjectData.definition = definition;
          data.nodeData = nodeObjectData;
        }
      }
    } 
    else {
      strategyNodeRequest.definition = "";
      data.nodeData = "";
    }
    if(data.nodeType != 'LOYALTY' ||  (data.nodeType == 'LOYALTY' && this.loyaltyNodeData.type !='LOYALTY_RULE')){
      this.updateOrCreateNode(self.nodeId,data,strategyNodeRequest);
    }
    this._gaConditionString = "";
    //
  } else {
    this.snackbar.openSnackBar(this._i18nDynamicTranslate.transform("Please create an audience", ['POS_PAGE']), 2000);
  }
  }

  updateOrCreateNode(nodeId,data,strategyNodeRequest) {
    let self = this;
    if (nodeId) {
      self.isLoading = true;
      self.apiCallService
        .updateNode(self.strategyId, nodeId, strategyNodeRequest)
        .subscribe(
          (response) => {
            data.nodeId = nodeId;
            data.error = false;
            self.isLoading = false;
            self.nodeConfiguredData.emit(data);
            self.changeDetection.markForCheck();
          },
          (err) => {
            data.error = true;
            self.isLoading = false;
            self.changeDetection.markForCheck();
          }
        );
    } else {
      self.isLoading = true;
      self.apiCallService
        .createNode(strategyNodeRequest, self.strategyId)
        .subscribe(
          (response) => {
            data.nodeId = JSON.parse(response["body"]);
            data.error = false;
            self.isLoading = false;
            self.nodeConfiguredData.emit(data);
            self.changeDetection.markForCheck();
          },
          (err) => {
            data.error = true;
            data.nodeId = "";
            self.isLoading = false;
            self.changeDetection.markForCheck();
          }
        );
    }
  }
  
  leForAdditionalTriggerConfig :any= true;
  leForAdditionalTriggerConfig2 :any = true;
  errorDiff = "";
  saveConfigOnLeftPanel(status: boolean) {
    this.disableSaveConfigOnLeftPanel = false;
    this.submittedData = true;
    this._gaConditionString = "";
    this.checkIfOnBoarded();
    if (this.disableSaveButton() && status) {
      return;
    }
    //check if trigger has additional config
    if(this.showAdditionalTriggerConfig && status) {
      this.leForAdditionalTriggerConfig = this.triggerAdditionalConfig?.runValidationAndReturnValue();
      if(!this.leForAdditionalTriggerConfig) {
        return;
      }
    }
    
    // if(this.checkIfBeforeCurrentDate(this.startDate.value)){
    //   return;
    // }
    if (this.nodeType == "TRIGGER") {
      if((this.celebration === 'ANNNIVERSARY' || this.celebration === 'BIRTHDAY') && this.celebrationType === 'Custom' && this.dateRadioOption === 1){
        if(this.checkDiffForCustom()){
          this.errorDiff = "Days prior cannot be greater than date range difference."
          return;
        }
      }
      if((this.celebration === 'APPOINTMENT' || this.celebration === 'MEETING') && this.appointmentType === 'SCHEDULED'){
        if(this.checkDiffForAppointment()){
          this.errorDiff = "Days prior cannot be greater than date range difference."
          return;
        }
      }
      if (!status) {
        this.showFirstNav = false;
        this.arrayDynamic = [];
        this.weekDaysChips = this.weekDaysChips.map((x) => {
          x.selected = this.weekdays.includes(x.name);

          return x;
        });
        this.isCelebration = false;
        this.celebrationType = "";
        this.celebrationRuleEevnt = "";
        this.celebration = "";
        this.daysPriorToCelebration = 0;
        this.anyTimeSliderValue = false;
        this.dateRadioOption = 1;
        this.anyTimeSliderValue = false;
        this.everyDaySliderValue = false;
        this.startDate.setValue(new Date());
        this.endDate.setValue(
          new Date(new Date().setMonth(new Date().getMonth() + 6))
        );
        // this.startTime.setValue("08:00");
        // this.endTime.setValue("20:00");
        this.eventName.setValue("");
      } else {
        this.showFirstNav = false;
        var tdata: any = {};
        tdata.conditionsRelation = "AND";
        tdata.conditions = [];
        var subdataChild: any = {};
        subdataChild.rules = {};
        var eventData: any = {};
        if(this.celebration === 'ANNIVERSARY' || this.celebration === 'BIRTHDAY' || this.celebration ==='APPOINTMENT' || this.celebration ==='MEETING'){
          eventData.operator = "EQUALS";
          eventData.ruleType = "EVENT";
          eventData.ruleEvent = this.celebration;
          eventData.ttl = null;
          eventData.eventValue = null;
        }
        else{
          eventData.operator = "EQUALS";
          eventData.ruleType = "EVENT";
          eventData.ruleEvent = this.eventName.value.eventName;
          eventData.ttl = null;
        }
        subdataChild.rules.EVENT = eventData;
        //for nth lifetime order count value is stored in similar fashion to addition config/filter
        if(this.eventName.value.eventName == 'nth lifetime order count') {
          subdataChild.rules['CUSTOM'] = {};
          subdataChild.rules['CUSTOM']['ruleType'] = 'CUSTOM';
          subdataChild.rules['CUSTOM']['logicalExpressions'] = [{
            comparisonStatements:[
              {
                "operand1": "$life_time_order_count",
                "operator": "equals",
                "operand2": this.nthLifeTimeOrderCountValue,
              }
            ]
          }];
        }
        if (!(this.dateRadioOption == 2 || this.everyDaySliderValue)) {
          var dayData: any = {};
          dayData.operator = "CONTAINS";
          dayData.ruleType = "DAYS";
          dayData.daysRule = this.arrayDynamic;
          subdataChild.rules.DAYS = dayData;
        }
        var dateDate: any = {};
        if (this.dateRadioOption == 1) {
          dateDate.operator = "BETWEEN";
          dateDate.ruleType = "DATE";
          dateDate.ruleDates = [
            this.datePipe.transform(
              this.startDate.value,
              "yyyy-MM-dd HH:mm:ss"
            ),
            this.datePipe.transform(this.endDate.value, "yyyy-MM-dd HH:mm:ss"),
          ];
        } else {
          dateDate.operator = "EQUALS";
          dateDate.ruleType = "DATE";
          dateDate.ruleDates = [
            this.datePipe.transform(
              this.startDate.value,
              "yyyy-MM-dd HH:mm:ss"
            ),
          ];
        }
        subdataChild.rules.DATE = dateDate;
        if (!this.anyTimeSliderValue && (this.dateRadioOption == 1) && (subdataChild.rules.EVENT.ruleEvent !== 'ANNIVERSARY' || subdataChild.rules.EVENT.ruleEvent !== 'BIRTHDAY')) {
          var timeData: any = {};
          timeData.operator = "BETWEEN";
          timeData.ruleType = "TIME";
          timeData.ruleTimes = [this.transformTo24HourFormat(this.startTime.value), this.transformTo24HourFormat(this.endTime.value)];
          subdataChild.rules.TIME = timeData;
        }
        if(!this.anyTimeSliderValue && this.dateRadioOption == 2 && (subdataChild.rules.EVENT.ruleEvent !== 'ANNIVERSARY' || subdataChild.rules.EVENT.ruleEvent !== 'BIRTHDAY') && subdataChild.rules.EVENT.ruleEvent === 'time_event') {
    

          let timeData: any = {};
          timeData.operator = "EQUALS";
          timeData.ruleType = "TIME";
          timeData.ruleTimes = [this.transformTo24HourFormat(this.startTime.value)];
          subdataChild.rules.TIME = timeData;
        }
        if(!this.anyTimeSliderValue && this.dateRadioOption == 2 && (subdataChild.rules.EVENT.ruleEvent !== 'ANNIVERSARY' || subdataChild.rules.EVENT.ruleEvent !== 'BIRTHDAY') && subdataChild.rules.EVENT.ruleEvent !== 'time_event') {
          let timeData: any = {};
          timeData.operator = "BETWEEN";
          timeData.ruleType = "TIME";
          timeData.ruleTimes = [this.transformTo24HourFormat(this.startTime.value), this.transformTo24HourFormat(this.endTime.value)];
          subdataChild.rules.TIME = timeData;
        }
        if(this.celebration === 'ANNIVERSARY' || this.celebration === 'BIRTHDAY'){
          var celebtrationData: any = {};
          celebtrationData.handledGapDays = false;
          celebtrationData.ruleDates = [
            this.datePipe.transform(
              this.startDate.value,
              "yyyy-MM-dd HH:mm:ss"
            ),
            this.datePipe.transform(this.endDate.value, "yyyy-MM-dd HH:mm:ss"),
          ];
          celebtrationData.ruleType = 'CUSTOM';
          celebtrationData.operator = "BETWEEN";
          celebtrationData.event = this.celebrationRuleEevnt;
          if(this.celebrationType === 'Custom' && this.dateRadioOption === 1){
            celebtrationData.timeBeforeToSend = [this.daysPriorToCelebration];
          }
          subdataChild.rules.CUSTOM = celebtrationData;
        }
        if(this.celebration ==='APPOINTMENT' || this.celebration ==='MEETING'){
          var celebtrationData: any = {};
          celebtrationData.handledGapDays = false;
          if(this.appointmentType === 'SCHEDULED'){
            celebtrationData.timeBeforeToSend = [
              this.daysControlAppointment, this.hoursControlAppointment
            ];
          }
          if(this.appointmentType === 'INSTANT'){
            celebtrationData.timeBeforeToSend = null;
          }
          celebtrationData.ruleType = 'CUSTOM';
          celebtrationData.operator = "BETWEEN";
          celebtrationData.event = this.appointmentType;
          subdataChild.rules.CUSTOM = celebtrationData;
        }
        if(this.celebration === 'tier_upgrade'){
          var celebtrationData: any = {};
          celebtrationData.handledGapDays = false;
          celebtrationData.ruleType = 'CUSTOM';
          celebtrationData.operator = "BETWEEN";
          celebtrationData.event = this.fromTier + "#-#" + this.toTier;
          subdataChild.rules.CUSTOM = celebtrationData;
        }
         //check if trigger  event has additional config
        if(this.showAdditionalTriggerConfig) {
          this.leForAdditionalTriggerConfig = this.triggerAdditionalConfig.runValidationAndReturnValue();
          if(this.leForAdditionalTriggerConfig && this.leForAdditionalTriggerConfig.length >= 1) {
            let eventType = this.leForAdditionalTriggerConfig[0].comparisonStatements[0].eventType ? this.leForAdditionalTriggerConfig[0].comparisonStatements[0].eventType : "CUSTOM";
            if(subdataChild.rules[eventType]) {
              subdataChild.rules[eventType]['logicalExpressions'] = this.leForAdditionalTriggerConfig;
              subdataChild.rules[eventType]['ruleType'] = eventType;
            } else {
              subdataChild.rules[eventType] = {};
              subdataChild.rules[eventType]['ruleType'] = eventType;
              subdataChild.rules[eventType]['logicalExpressions'] = this.leForAdditionalTriggerConfig;
            }
          }
        }
        var updateData: any = this.triggerGroupv2;
        if (this.triggerGroupIndex != -1) {
          if (this.triggerChildIndex != -1) {
            updateData.config.conditionGroups[
              this.triggerGroupIndex
            ].conditions[this.triggerChildIndex] = subdataChild;
          } else {
            updateData.config.conditionGroups[
              this.triggerGroupIndex
            ].conditions.push(subdataChild);
          }
        } else {
          tdata.conditions.push(subdataChild);
          tdata.conditionGroupRelation = "";
          updateData.config.conditionGroups.push(tdata);
          if (updateData.config.conditionGroups.length > 1) {
            updateData.config.conditionGroups[
              updateData.config.conditionGroups.length - 2
            ].conditionGroupRelation = "AND";
          }
        }
        this.triggerGroupv2 = updateData;
        this.gaAnalyticsConditionSet(updateData);
      }
      this.parentConditionIndex = -1;
      this.childConditionIndex = -1;
      this.editDateRadioOption = this.dateRadioOption;
      this.triggerNodeEditableGroupLength =
        this.triggerGroupv2.config.conditionGroups.length;
      if (this.triggerNodeEditableGroupLength == 1) {
        this.triggerNodeEditableChildLength =
          this.triggerGroupv2.config.conditionGroups[0].conditions.length;
      }
    } else if (this.nodeType == "RESPONSE_EVENT") {
      
      //  check if response event has additional config
     if(this.showAdditionalTriggerConfig2 && status) {
      this.leForAdditionalTriggerConfig2 = this.triggerAdditionalConfig2?.runValidationAndReturnValue();
      if(!this.leForAdditionalTriggerConfig2) {
        return;
      }
    }

      if (!status) {
        this.showFirstNav = false;
      }
      else{
        this.showFirstNav = false;
          var tdata: any = {};
          tdata.conditionsRelation = "AND";
          tdata.conditions = [];
          var subdataChild: any = {};
          subdataChild.rules = {};
          let def :any ={};
          let ttl :any ={};
          if(this.actionStatus === "action"){
            def.ruleEvent = this.eventName.value.eventName;
            def.ruleType = "EVENT";
            ttl.timeUnit = "MINUTE";
            ttl.timeoutValue = this.getTimeInMinutes();
            ttl.days = this.daysControl.value;
            ttl.hours = this.hoursControl.value;
            ttl.minutes = this.minutesControl.value;
            def.ttl = ttl;
            subdataChild.rules.EVENT = def;
            subdataChild.rules.DATE = null;
            subdataChild.rules.TIME = null;
          }
          else{
            def.ruleEvent = "segment";
            def.segments = this.customerSelectedSegment;
            subdataChild.rules.EVENT = def;
          }
          //check if ifelse node event has additional config
          if(this.showAdditionalTriggerConfig2) {
            let eventType = this.leForAdditionalTriggerConfig2[0]?.comparisonStatements[0]?.eventType ? this.leForAdditionalTriggerConfig2[0].comparisonStatements[0].eventType : "CUSTOM";
            this.leForAdditionalTriggerConfig2 = this.triggerAdditionalConfig2.runValidationAndReturnValue();
            if(this.leForAdditionalTriggerConfig2 && this.leForAdditionalTriggerConfig2.length >= 1) {
              if(subdataChild.rules[eventType]) {
                subdataChild.rules[eventType]['logicalExpressions'] = this.leForAdditionalTriggerConfig2;
                subdataChild.rules[eventType]['ruleType'] = eventType;
              } else {
                subdataChild.rules[eventType] = {};
                subdataChild.rules[eventType]['ruleType'] = 'CUSTOM';
                subdataChild.rules[eventType]['logicalExpressions'] = this.leForAdditionalTriggerConfig2;
              }
            }
          }
          var updateData: any = this.responseEventGroup;
          if (this.responseGroupIndex != -1) {
            if (this.responseChildIndex != -1) {
              updateData.config.conditionGroups[
                this.responseGroupIndex
              ].conditions[this.responseChildIndex] = subdataChild;
            } else {
              updateData.config.conditionGroups[
                this.responseGroupIndex
              ].conditions.push(subdataChild);
            }
          }
          else {
            tdata.conditions.push(subdataChild);
            tdata.conditionGroupRelation = "";
            updateData.config.conditionGroups.push(tdata);
            if (updateData.config.conditionGroups.length > 1) {
              updateData.config.conditionGroups[
                updateData.config.conditionGroups.length - 2
              ].conditionGroupRelation = "AND";
            }
          }
          this.responseEventGroup = updateData;
          this.parentConditionIndex = -1;
          this.childConditionIndex = -1;
          this.responseNodeEditableGroupLength =
            this.responseEventGroup.config.conditionGroups.length;
          if (this.responseNodeEditableGroupLength == 1) {
            this.responseNodeEditableChildLength =
              this.responseEventGroup.config.conditionGroups[0].conditions.length;
          }
        //} 
      }
    } else if (this.nodeType == "CHANNEL") {
      if (this.channelType == "EMAIL") {
        if (!status) {
          this.showFirstNav = false;
          this.selectedEmailTemplateId = -1;
          this.selectedEmailTemplateName = "";
        } else {
          this.showFirstNav = false;
          this.emailTemplateId = this.selectedEmailTemplateId;
          this.emailTemplateName = this.selectedEmailTemplateName;
        }
      } else if (this.channelType == "SMS") {
        if (!status) {
          this.showFirstNav = false;
          this.selectedSmsTemplateId = -1;
          this.selectedSmsTemplateName = "";
        } else {
          this.showFirstNav = false;
          this.smsTemplateId = this.selectedSmsTemplateId;
          this.smsTemplateName = this.selectedSmsTemplateName;
        }
      }
      else if (this.channelType == "WHATSAPP") {
        if (!status) {
          this.showFirstNav = false;
          if (this.type != "CONFIGURE"){
            this.selectedWhatsappTemplateId = -1;
            this.selectedWhatsappTemplateName = "";
          }
        } else {
          this.showFirstNav = false;
          this.whatsappTemplateId = this.selectedWhatsappTemplateId;
          this.whatsappTemplateName = this.selectedWhatsappTemplateName;
          this.whatsappLayout = this.selectedWhatsappLayout;
          this.whatsappTemplatetemplateSource = this.selectedWhatsapptemplateSource;
        }
      }
    } else if (this.nodeType == "SURVEY") {
      if (!status) {
        this.showFirstNav = false;
        this.channelSelect = false;
        this.selectedSurveyTemplateId = -1;
        this.selectedSurveyTemplateName = "";
      } else {
        if(!this.channelSelect)
          this.showChannel();
        else{
          this.channelSelectComponent.sendChannel();
          if(this.channelSave){
            this.showFirstNav = false;
            this.channelSave = false;
          }
          this.surveyTemplateId = this.selectedSurveyTemplateId;
          this.surveyTemplateName = this.selectedSurveyTemplateName;

        }
        // let html;
        // let json;
        // this.apiCallService
        //   .getEmailLayoutDetail(this.surveyEmailTemplate["id"])
        //   .subscribe(
        //     (response) => {
        //       let layout = JSON.parse(response["body"]);
        //       html = layout["layoutHtml"];
        //       json = layout["layoutJson"];
        //       this.surveyTemplateId = this.selectedSurveyTemplateId;
        //       this.surveyTemplateName = this.selectedSurveyTemplateName;
        //       this.surveyTitle.setValue(this.selectedSurveyTitle);
        //       let data = {
        //         merchantId: this.tokenStorage.getMerchantId(),
        //         subject: this.surveyEmailSubject.value,
        //         layoutHtml: html,
        //         layoutJson: json,
        //         id: null,
        //         type: "SURVEY",
        //       };
        //       this.apiCallService
        //         .addEmailContent(data)
        //         .subscribe((response) => {
        //           this.surveyEmailInstanceId = JSON.parse(response["body"]);
        //           this.changeDetection.markForCheck();
        //         });
        //     },
        //     (err) => {}
        //   );
      }
    } else if (this.nodeType == "OFFER") {
      this.emailCheckbox = true;
      this.smsCheckbox = true;
      this.whatsappCheckBox = true;
      if (!status) {
        this.showFirstNav = false;
        this.selectedOfferTemplateId = -1;
        this.selectedOfferTemplateName = "";
      } else {
        this.showFirstNav = false;
        this.offerTemplateId = this.selectedOfferTemplateId;
        this.offerTemplateName = this.selectedOfferTemplateName;
      }
    } else if (this.nodeType == "ABTESTING") {
      if (!status) {
        this.ab = [];
        this.showFirstNav = false;
        this.isErrorAB = false;
      }
      else{
        this.atrWindow[1] = this.atrWindow[1]?this.atrWindow[1]:0;
        this.waitWindow[1] = this.waitWindow[1]?this.waitWindow[1]:0;
        if(this.checkPathFields() || !this.isKpiSelected()||  !this.checkSum() || (this.expWindowOption === 'custom' && (this.atrWindow[0] === 0 && (this.atrWindow[1] === 0 || !this.atrWindow[1])) ) || (this.selectedABConfigType != 'MANUAL' && this.isTimeEvent && (this.waitWindow[0] === 0 && (this.waitWindow[1] === 0 || !this.waitWindow[1])))  ){
            this.isErrorAB = true;
        }
        // Also Check for waitWindow
        else{
          this.isErrorAB = false;
          this.ab = this.abTesting;
          this.pathGroup = {};
          for(let i =0;i<this.abTesting.length;i++){
            let u :any ={};
            // this.groupNameGroup.splice(l-1,0,this.groupName)
            u.pathName = this.abTesting[i]['pathName'];
            u.weight = this.abTesting[i]['percValue'];
            u.pathId = null;
            u.nextNodeId = null;
            this.pathGroup["Path-"+(i+1)] = u;
          }
          if(this.controlPath){
            let u :any ={};
            u.pathName = "Control";
            u.weight = this.controlValue;
            u.pathId = null;
            u.nextNodeId = null;
            // this.pathGroup["Control"] = this.controlValue
            this.pathGroup["Control"] = u;
          }

        }
      
      }
    } else if (this.nodeType == "AUDIENCESPLIT") {
      if (!status) {
        // this.groupNameGroup = [];
        this.showFirstNav = false;
        this.showfilter = false;
        this.showClicked = false;
      } else {
        // this.childComponent.sendMessage();
        let l = this.groupNameGroup.length;
        if (l == 0) {
          this.groupNameGroup.push("Everyone else");
          let v: any = {};
          v.groupName = "others";
          v.segments = null;
          v.tiers = null;
          v.filter = null;
          v.nextNodeId = null;
          v.exitFromCanvas = false;
          this.splitGroup["others"] = v;
        }
        if (
          this.groupNameGroup.indexOf(this.groupName) === -1 &&
          this.groupName.length !== 0 &&
          this.customerSelectedSegment.length > 0 && 
          /^[A-Za-z0-9]+$/.test(this.groupName)
        ) {
          this.isErrorAudience = false;
          if(this.splitedit === true){
            let u: any = {};
            let index = this.groupNameGroup.indexOf(this.temp_split_Name);
            this.groupNameGroup.splice(index, 1);
            this.groupNameGroup.splice(index, 0, this.groupName);
            delete this.splitGroup[this.temp_split_Name];
            u.groupName = this.groupName;
            let tiers = [];
            let segments = [];
            let customerList = [];
            let employeeList = [];
            for(let id of this.customerSelectedSegment) {
              let segment = this.customerSegmentAndTiers.find(segment => segment.id == id);
              if(segment) {
                if(segment.category.toLowerCase() == 'segment') {
                  segments.push(id);
                }
                if(segment.category.toLowerCase() == 'tier') {
                  tiers.push(id);
                }
                if(segment.category.toLowerCase() == 'customerlist') {
                  customerList.push(id);
                }
                if(segment.category.toLowerCase() == 'employeelist') {
                  employeeList.push(id);
                }
              }
            }
            u.segments = segments.length>0?segments:null;
            u.tiers = tiers.length>0?tiers:null;
            u.customerList = customerList.length>0?customerList:null;
            u.employeeList = employeeList.length>0?employeeList:null;
            u.filter = this.filterData;
            u.nextNodeId = this.nodeData['definition'].splitGroups[this.temp_split_Name].nextNodeId;
            u.exitFromCanvas = false;
            this.isfilterError = false;
            this.isErrorAudience = false;
            if(u.filter.hasOwnProperty('segmentDefinition')) {
              u.filter.segmentDefinition.logicalExpressions.forEach((le)=>{
                le.comparisonStatements.forEach((cs)=>{
                  if(cs.operand2 == null || cs.operand2 == '') {
                    this.isfilterError = true;
                    this.isErrorAudience = true;
                  }
                });
              });
            }
            if(!this.isfilterError) {
              this.splitGroup[this.groupName] = u;
            }
          }
          else{
            let u: any = {};
            u.groupName = this.groupName;
            let tiers = [];
            let segments = [];
            let customerList = [];
            let employeeList = [];
            for(let id of this.customerSelectedSegment) {
              let segment = this.customerSegmentAndTiers.find(segment => segment.id == id);
              if(segment) {
                if(segment.category.toLowerCase() == 'segment') {
                  segments.push(id);
                }
                if(segment.category.toLowerCase() == 'tier') {
                  tiers.push(id);
                }
                if(segment.category.toLowerCase() == 'customerlist') {
                  customerList.push(id);
                }
                if(segment.category.toLowerCase() == 'employeelist') {
                  employeeList.push(id);
                }
              }
            }
            u.segments = segments.length>0?segments:null;
            u.tiers = tiers.length>0?tiers:null;
            u.customerList = customerList.length>0?customerList:null;
            u.employeeList = employeeList.length>0?employeeList:null;
            u.filter = (this.filterData);
            u.nextNodeId = null;
            u.exitFromCanvas = false;
            this.isfilterError = false;
            this.isErrorAudience = false;
            if(u.filter.hasOwnProperty('segmentDefinition')) {
              u.filter.segmentDefinition.logicalExpressions.forEach((le)=>{
                le.comparisonStatements.forEach((cs)=>{
                  if(cs.operand2 == null || cs.operand2 == '') {
                    this.isfilterError = true;
                    this.isErrorAudience = true;
                  }
                });
              });
            }
            if(!this.isfilterError) {
              this.groupNameGroup.splice(l - 1, 0, this.groupName);
              this.splitGroup[this.groupName] = u;
              this.groupName = "";
              this.customerSelectedSegment = [];
            }
          }
        } else if (this.groupName.length === 0) {
          this.errorText = "Group name cannot be empty.";
          this.isErrorAudience = true;
        } else if (this.groupNameGroup.indexOf(this.groupName) !== -1 && !this.pathedit) {
          this.errorText = "Group name should not be same";
          this.isErrorAudience = true;
        } else if (this.customerSelectedSegment.length === 0) {
          this.isErrorAudience = true;
        } else if (/^[A-Za-z0-9]+$/.test(this.groupName) === false) {
          this.errorText = "Enter Valid Group Name, Doesn't allow any special characters";
          this.isErrorAudience = true;
        }
        else if (this.groupNameGroup.indexOf(this.groupName) !== -1 &&
        this.groupName.length !== 0 && /^[A-Za-z0-9]+$/.test(this.groupName) && 
        this.customerSelectedSegment.length > 0 && this.splitedit){
          let u: any = {};
          delete this.splitGroup[this.temp_split_Name];
          u.groupName = this.groupName;
          let tiers = [];
            let segments = [];
            let customerList = [];
            let employeeList = [];
            for(let id of this.customerSelectedSegment) {
              let segment = this.customerSegmentAndTiers.find(segment => segment.id == id);
              if(segment) {
                if(segment.category.toLowerCase() == 'segment') {
                  segments.push(id);
                }
                if(segment.category.toLowerCase() == 'tier') {
                  tiers.push(id);
                }
                if(segment.category.toLowerCase() == 'customerlist') {
                  customerList.push(id);
                }
                if(segment.category.toLowerCase() == 'employeelist') {
                  employeeList.push(id);
                }
              }
            }
            u.segments = segments.length>0?segments:null;
            u.tiers = tiers.length>0?tiers:null;
            u.customerList = customerList.length>0?customerList:null;
            u.employeeList = employeeList.length>0?employeeList:null;
          u.filter = this.filterData;
          u.nextNodeId = null;
          u.exitFromCanvas = false;
          this.isfilterError = false;
          this.isErrorAudience = false;
          if(u.filter.hasOwnProperty('segmentDefinition')) {
            u.filter.segmentDefinition.logicalExpressions.forEach((le)=>{
              le.comparisonStatements.forEach((cs)=>{
                if(cs.operand2 == null || cs.operand2 == '') {
                  this.isfilterError = true;
                  this.isErrorAudience = true;
                }
              });
            });
          }
          if(!this.isfilterError) {
            this.splitGroup[this.groupName] = u;
          }
        }
      }
    } else if(this.nodeType == "COMARKETING_OFFER"){
      this.emailCheckbox = true;
      this.smsCheckbox = true;
      this.whatsappCheckBox = true;
      if (!status) {
        this.showFirstNav = false;
        this.selectedCoMarketingTemplateId = [];
        this.coMarketingOffer = [];
        this.selectedCoMarketingTemplateName = "";
      } else {
        this.coMarketingOfferTemplateId = this.selectedCoMarketingTemplateId;
        this.coMarketingOffer = this.selectedComarketingOffers;
        this.coMarketingOfferTemplateName = this.selectedCoMarketingTemplateName;
      }
    }
    if(this.isErrorAudience === false && this.isErrorAB === false && (this.nodeType == "AUDIENCESPLIT" || this.nodeType == "ABTESTING")){
      this.showFirstNav = false;
      this.showfilter = false;
      this.showClicked = false;
    }
  }

  disableSaveButton() {
    if (this.nodeType == "TRIGGER") {
      if (this.eventName.invalid) {
        return true;
      }
      else if(this.checkIfBeforeCurrentDate(this.startDate.value)){
        return true;
      }
      else if(this.eventName.value && this.eventName.value.eventName == 'time_event') {
        if(this.dateRadioOption == 1) {
          if((((!this.startTime.value || !this.endTime.value) && !this.anyTimeSliderValue) || !this.startDate.value || !this.endDate.value ) || (this.dateRadioOption == 1 &&
            !this.everyDaySliderValue &&
            this.arrayDynamic.length == 0)) {
            return true;
          } else return false;
        }
        if(this.dateRadioOption == 2) {
          if(!this.startTime.value || !this.startDate.value) {
            return true;
          } else return false;
        }
      } else if (
        (this.dateRadioOption == 1 &&
          !this.everyDaySliderValue &&
          this.arrayDynamic.length == 0) ||
        this.endDate.value == null ||
        this.startDate.value == null ||
        (!this.anyTimeSliderValue &&
          (this.startTime.value == null || this.startTime.value.length == 0) && !this.isCelebration) ||
        this.endDate.value.length == 0 ||
        this.startDate.value.length == 0 ||
        (!(
          this.dateRadioOption == 2 && this.disableDateRadioSelection == true
        ) &&
          !this.anyTimeSliderValue &&
          (this.endTime.value == null || this.endTime.value.length == 0) && !this.isCelebration)
        // (this.disableDateRadioSelection == false &&
        //   !this.anyTimeSliderValue &&
        //   (this.endTime.value == null || this.endTime.value.length == 0))
      ) {
        return true;
      } else if((this.dateRadioOption == 1 && this.isCelebration && this.celebrationType == 'Custom' && (this.daysPriorToCelebration === null) && this.celebration !== 'APPOINTMENT' && this.celebration !== 'MEETING') || (this.isCelebration && this.celebrationType === "" && this.celebration !== 'APPOINTMENT' && this.celebration !== 'MEETING')){
        return true;
      } 
      else if((this.celebration === 'APPOINTMENT' || this.celebration === 'MEETING') && this.appointmentType === 'SCHEDULED' && (this.daysControlAppointment === 0 && this.hoursControlAppointment === 0) && this.dateRadioOption === 1){
        return true;
      }
      else if((this.celebration === 'APPOINTMENT' || this.celebration === 'MEETING') && this.appointmentType === 'SCHEDULED' && (this.hoursControlAppointment === 0) && this.dateRadioOption === 2){
        return true;
      }
       else {
        //check for nth lifetime order count event
        if(this.eventName.value.eventName == 'nth lifetime order count' && !this.nthLifeTimeOrderCountValue) {
          return true;
        }
        return false;
      }
    } else if (this.nodeType == "CHANNEL") {
      if (
        this.channelType == "EMAIL" &&
        (this.selectedEmailTemplateId == -1 ||
          this.selectedEmailTemplateName == "")
      ) {
        return true;
      }
      if (
        this.channelType == "SMS" &&
        (this.selectedSmsTemplateId == -1 || this.selectedSmsTemplateName == "")
      ) {
        return true;
      } else return false;
    } else if (this.nodeType == "OFFER") {
      if (
        this.selectedOfferTemplateId == -1 ||
        this.selectedOfferTemplateName == ""
      ) {
        return true;
      } else return false;
    } else if (this.nodeType == "SURVEY") {
      if (
        this.selectedSurveyTemplateId == -1 ||
        this.selectedSurveyTemplateName == ""
      ) {
        return true;
      } else return false;
    }
    else if(this.nodeType === "RESPONSE_EVENT"){
      if(
        this.eventName.value.eventName === undefined || ((this.daysControl.value ===0 ||this.daysControl.value === null) && this.hoursControl.value === 0 && this.minutesControl.value === 0)
      ){
        return true;
      } else return false;
    }
    else if (this.nodeType === "LOYALTY") {
      if(!this.loyaltyProgramConfig['selectedRule']) {
        return true;
      }
    } else {
      return false;
    }
  }

  getTimeInMinutes() {
    return (
      this.daysControl.value * 1440 +
      this.hoursControl.value * 60 +
      this.minutesControl.value
    );
  }

  previewSms() {
    var self = this;
    var id: any = self.smsTemplateId;
    if (!id) {
    } else {
      var data: any = self.smsLayouts.find(
        (x) => x.id == this.selectedSmsTemplateId
      );
      this.openDialog(data, "SMS", "SMS");
    }
  }

  previewEmail() {
    var id: any = this.emailTemplateId;
    if (!id) {
    } else {
      this.apiCallService.getEmailLayoutDetail(id).subscribe(
        (response) => {
          if (response["message"] == "success" && response["body"] !== "null") {
            this.loaded = true;
            let layout = JSON.parse(response["body"]);
            this.selectedLayout = layout["layoutHtml"];
            this.layoutName = layout["name"];
            var emailData: any = {};
            emailData.selectedLayout = this.selectedLayout;
            emailData.templateName = this.emailTemplateName;
            emailData.templateId = this.emailTemplateId;
            this.openDialog(emailData, "EMAIL", "EMAIL");
          }
          else{
            this.getFromRewardPreview();
          }
        },
        (err) => {
          this.getFromRewardPreview()
        }
      );
    }
  }
  getFromRewardPreview(){
    var id: any = this.emailTemplateId;
    if (!id) {
    } else {
      this.apiCallService.getRewardEmailDetail(id).subscribe(
        (response) => {
          if (response["message"] == "success") {
            this.loaded = true;
            let layout = JSON.parse(response["body"]);
            this.selectedLayout = layout["layoutHtml"];
            this.layoutName = layout["name"];
            var emailData: any = {};
            emailData.selectedLayout = this.selectedLayout;
            emailData.templateName = this.emailTemplateName;
            emailData.templateId = this.emailTemplateId;
            this.openDialog(emailData, "EMAIL", "REWARD");
          }
        },
        (err) => {
          
        }
      );
    }
  }

  previewSurveyEmail() {
    this.apiCallService.getEmailContent(this.surveyEmailInstanceId).subscribe(
      (response) => {
        if (response["message"] == "success") {
          this.loaded = true;
          let layout = JSON.parse(response["body"]);
          this.selectedLayout = layout["layoutHtml"];
          this.layoutName = layout["name"];
          var emailData: any = {};
          emailData.selectedLayout = this.selectedLayout;
          emailData.templateName = this.emailTemplateName;
          emailData.templateId = this.surveyEmailInstanceId;
          this.openDialog(emailData, "SURVEYEMAIL", "SURVEYEMAIL");
        }
      },
      (err) => {}
    );
  }

  openDialog(objectData: any, type: string, emailType): void {
    var self = this;
    self.finalScreenHeight = self.scrHeight - 61;
    self.finalScreenWidth = self.scrWidth;
    let sh = self.finalScreenHeight * (90 / 100);
    let sw = self.finalScreenWidth * (70 / 100);
    const dialogRef = self.dialog.open(PreviewTemplateComponent, {
      panelClass: "previewDialog",
      height: sh + "px",
      // width: self.finalScreenWidth < 1500 ? "1024px" : sw + "px",
      // minWidth: "1024px",
      width: "70%",
      maxWidth: "99vw",
      maxHeight: "90vh",
      disableClose: true,
      autoFocus: false,
      data: {
        obj: objectData,
        type: type,
        emailType: emailType,
        height: self.finalScreenHeight,
        showButton: true,
      },
    });

    dialogRef.afterClosed().subscribe((id) => {
      if (self.nodeType == "CHANNEL" && self.channelType == "EMAIL") {
        self.apiCallService.getEmailLayoutDetail(id).subscribe(
          (response) => {
            if (response["message"] == "success") {
              let layout = JSON.parse(response["body"]);
              self.emailTemplateId = self.selectedEmailTemplateId = id;
              self.emailTemplateName = self.selectedEmailTemplateName =
                layout.name;
            }
          },
          (err) => {

          }
        );
      }
    });
  }

  disableEmailPreview() {
    if (!this.dataForm.get("emailLayoutId").value) {
      return true;
    } else {
      return false;
    }
  }

  disableOfferPreview() {
    if (!this.dataForm.get("offerId").value) {
      return true;
    } else {
      return false;
    }
  }

  disableSurveyPreview() {
    if (!this.dataForm.get("surveyTemplateId").value) {
      return true;
    } else {
      return false;
    }
  }

  previewSurvey(previewId: any) {
    var id: any;
    if (previewId) {
      id = previewId;
    } else {
      id = this.surveyTemplateId;
    }
    if (!id) {
    } else {
      this.apiCallService.getSurveyTemplate(id).subscribe(
        (response) => {
          if (response["message"] == "SUCCESS") {
            var surveyData: any = {};
            var type: string = "";
            let survey = JSON.parse(response["body"]);
            this.selectedSurvey = survey["layoutHtml"];
            this.selectedSurveyName = survey["name"];
            this.surveyIdFinal = survey["id"];
            if (survey["type"] == "REGULAR") {
              type = "custom";
            } else {
              type = "standard";
            }
            var m = `class="session-newsurvey" id="mainDiv"`;
              var n = `id="mainDiv"`;
              this.selectedSurvey = this.selectedSurvey.replace(m, n);

               m = `id="mainDiv" class="session-newsurvey"`;
               n = `id="mainDiv"`;
              this.selectedSurvey = this.selectedSurvey.replace(m, n);
            //For older survey compatibility
            var a = `id="mainDiv" style="display:none;border-radius: 3px;`;
            var b = `id="mainDiv" style="border-radius: 3px;`;
            this.selectedSurvey = this.selectedSurvey.replace(a, b);
            
            surveyData.selectedSurvey = this.selectedSurvey;
            surveyData.selectedSurveyName = this.selectedSurveyName;
            surveyData.surveyIdFinal = this.surveyIdFinal;
            surveyData.type = type;
            this.openDialog(surveyData, "SURVEY", "SURVEY");
          }
        },
        (err) => {
          this.openSnackBar("Failed", "2000");
        }
      );
    }
  }

  previewOffer() {
    var id: any = this.offerTemplateId;
    if (!id) {
    } else {
      this.apiCallService
        .getParticularOfferDetails(id)
        .subscribe((response) => {
          if (response["message"] == "success") {
            var offerData: any = {};
            this.masterOffer = JSON.parse(response["body"]);
            offerData.masterOffer = this.masterOffer;
            this.offerJson = JSON.parse(this.masterOffer["offerjson"])[
              "barrierRewards"
            ];
            offerData.offerJson = this.offerJson;
            let parsedbody2 = JSON.parse(this.masterOffer["offerjson"]);
            if (JSON.parse(this.masterOffer["offerjson"])["emailLayoutId"]) {
              let parsedbody = JSON.parse(this.masterOffer["offerjson"]);
              this.offerDetailEmailLayout = parsedbody["emailLayoutId"];
              this.emailtemplateSnapshot =
                parsedbody["emailTemplateSnapshotUrl"];
              this.emailtemplateName = parsedbody["emailLayoutName"];
              this.isNoneBarrierDetected = parsedbody["isNoneBarrierDetected"];
              this.isBuyXGetYDetected = parsedbody["isBuyXGetYDetected"];
              if (this.offerDetailEmailLayout) {
                this.getHtml(this.offerDetailEmailLayout, offerData);
              }
              offerData.offerDetailEmailLayout = this.offerDetailEmailLayout;
              offerData.emailtemplateSnapshot = this.emailtemplateSnapshot;
              offerData.emailtemplateName = this.emailtemplateName;
              offerData.isNoneBarrierDetected = this.isNoneBarrierDetected;
              offerData.isBuyXGetYDetected = this.isBuyXGetYDetected;
            } else {
              this.offerDetailEmailLayout = null;
              offerData.offerDetailEmailLayout = this.offerDetailEmailLayout;
              let parsedbody2 = JSON.parse(this.masterOffer["offerjson"]);
              this.isNoneBarrierDetected = parsedbody2["isNoneBarrierDetected"];
              this.isBuyXGetYDetected = parsedbody2["isBuyXGetYDetected"];
              offerData.isNoneBarrierDetected = this.isNoneBarrierDetected;
              offerData.isBuyXGetYDetected = this.isBuyXGetYDetected;
            }
            this.selectedbarrier = this.offerJson;
            offerData.selectedbarrier = this.selectedbarrier;
            this.openDialog(offerData, "OFFER", "OFFER");
          }
        });
    }
  }

  getHtml(offerDetailEmailLayout, offerData: any) {
    this.apiCallService
      .getEmailContent(offerDetailEmailLayout)
      .subscribe((response) => {
        this.htmlloading = false;
        offerData.htmlloading = false;
        if (response["message"] == "success") {
          let layout = JSON.parse(response["body"]);
          this.layoutSnapshotUrl = layout["snapShot"];
          offerData.layoutSnapshotUrl = this.layoutSnapshotUrl;
        }
      });
  }

  randomIntFromInterval(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  compareCustomerEvent(o1: any, o2: any): boolean {
    if (
      o1 &&
      o2 &&
      (o1.displayName == o2.displayName ||
        o1.eventName == o2.eventName ||
        o1.eventName == o2)
    ) {
      return true;
    } else {
      return false;
    }
  }
  // configResponseNode(j: any, i: any, edit: boolean){
  //   this.responseGroupIndex = j;
  //   this.responseChildIndex = i;
  // }
  configTriggerNode(j: any, i: any, edit: boolean) {
    let event = [];
    if (!edit && this.disableAddingNewTriggerConditions()) {
      return;
    }
    this.arrayDynamic = [];
    this.triggerGroupIndex = j;
    this.triggerChildIndex = i;
    this.isCelebration = false;
    this.customerEvents = this.eventIconMappingService
      .getCustomerEvents()
      .slice();
    // if (this.pos.toLowerCase() !== "shopify") {
    //   event.splice(0);
    //   event.push(this.customerEvents[3]);
    //   this.customerEvents = event;
    //   //   event = this.customerEvents;
    //   //   // this.customerEvents = [];
    //   //   this.customerEvents.forEach(e => {
    //   //     const index: number = event.indexOf(e);
    //   //     if(e.eventName === 'create_orders' || e.eventName === 'time_event') {
    //   //       this.customerEvents.splice(index,1);
    //   //     }
    //   //   })
    // }
    // if (!this.isAllCustomers && this.pos.toLowerCase() === "shopify") {
    //   this.customerEvents = this.customerEvents.filter(
    //     (x) => x.eventName !== "create_customer"
    //   );
    // }
    if (edit) {
      this.showFirstNav = true;
      if (
        this.triggerGroupv2 &&
        this.triggerGroupv2.config &&
        this.triggerGroupv2.config.conditionGroups &&
        this.triggerGroupv2.config.conditionGroups.length > 0
      ) {
        if (this.triggerNodeEditableChildLength == 1) {
          this.customerEvents.unshift({
            type: "TRIGGER",
            eventName: "time_event",
            displayName: "Time based Journey",
          });
          this.filteredcustomerEvents.next(this.customerEvents.slice());
        } else if (
          !(
            this.triggerGroupv2.config.conditionGroups[0].conditions[0].rules
              .EVENT.ruleEvent == "time_event"
          )
        ) {
          this.customerEvents = this.customerEvents.filter(
            (x) => x.eventName !== "time_event"
          );
          this.filteredcustomerEvents.next(this.customerEvents.slice());
        }
      } else {
        this.customerEvents = this.customerEvents.filter(
          (x) => x.eventName !== "time_event"
        );
        this.filteredcustomerEvents.next(this.customerEvents.slice());
      }
      this.firstNavTitle = "CONFIGURE NEW CONDITION";
      var eventData: any
      if(this.triggerGroupv2.config.conditionGroups[j].conditions[i].rules?.EVENT?.ruleEvent ==='ANNIVERSARY' || this.triggerGroupv2.config.conditionGroups[j].conditions[i].rules?.EVENT?.ruleEvent ==='BIRTHDAY'){
        eventData = this.customerEvents.find(
          (x) =>
            x.eventName ==
            this.triggerGroupv2.config.conditionGroups[j].conditions[i].rules?.EVENT?.ruleEvent
        );
        this.eventName.setValue(eventData);
        this.celebrationRuleEevnt =   this.triggerGroupv2.config.conditionGroups[j].conditions[i].rules?.CUSTOM?.event;
        this.celebration = this.triggerGroupv2.config.conditionGroups[j].conditions[i].rules?.EVENT?.ruleEvent
        this.isCelebration = true;
        this.anyTimeSliderValue = true;
        this.disableDateRadioSelection = true;
        if(this.celebrationRuleEevnt.indexOf('MONTH') !== -1){
          this.celebrationType = 'Month';
        }
        else if(this.celebrationRuleEevnt.indexOf('WEEK') !== -1){
          this.celebrationType = "Week";
        }
        else{
          this.celebrationType = "Custom";
          if(this.triggerGroupv2.config.conditionGroups[j].conditions[i].rules?.CUSTOM?.timeBeforeToSend){
            this.daysPriorToCelebration = this.triggerGroupv2.config.conditionGroups[j].conditions[i].rules?.CUSTOM?.timeBeforeToSend[0];
          }
          else{
            this.dateRadioOption = 2;
          }
        }
      }
      else{
        if(this.triggerGroupv2.config.conditionGroups[j].conditions[i].rules?.EVENT?.ruleEvent ==='APPOINTMENT' || this.triggerGroupv2.config.conditionGroups[j].conditions[i].rules?.EVENT?.ruleEvent ==='MEETING' ){
          this.celebration = this.triggerGroupv2.config.conditionGroups[j].conditions[i].rules?.EVENT?.ruleEvent;
          this.isCelebration = true;
          this.disableDateRadioSelection = true;
          if(this.triggerGroupv2.config.conditionGroups[j].conditions[i].rules?.CUSTOM?.timeBeforeToSend){
            this.appointmentType = 'SCHEDULED';
            this.daysControlAppointment = this.triggerGroupv2.config.conditionGroups[j].conditions[i].rules?.CUSTOM?.timeBeforeToSend[0];
            this.hoursControlAppointment = this.triggerGroupv2.config.conditionGroups[j].conditions[i].rules?.CUSTOM?.timeBeforeToSend[1];
          }
        }
        if(this.triggerGroupv2.config.conditionGroups[j].conditions[i].rules?.EVENT?.ruleEvent ==='tier_upgrade' ){
          this.celebration = this.triggerGroupv2.config.conditionGroups[j].conditions[i].rules?.EVENT?.ruleEvent;
          let data = this.triggerGroupv2.config.conditionGroups[j].conditions[i].rules?.CUSTOM?.event.split("#-#");
          this.fromTier = data[0];
          this.toTier = data[1];
          this.fromTierLevel =this.customerTierList[this.customerTierList.findIndex(
            (x) => x.name === this.fromTier
          )].level;
          this.toTierLevel =this.customerTierList[this.customerTierList.findIndex(
            (x) => x.name === this.toTier
          )].level;
        }
        eventData = this.customerEvents.find(
          (x) =>
            x.eventName ==
            this.triggerGroupv2.config.conditionGroups[j].conditions[i].rules
              .EVENT.ruleEvent
        );
        this.disableDateRadioSelection = false;
        this.eventName.setValue(eventData);
      }
      this.anyTimeSliderValue = this.triggerGroupv2.config.conditionGroups[j]
        .conditions[i].rules.TIME
        ? false
        : true;
      if (
        !this.triggerGroupv2.config.conditionGroups[j].conditions[i].rules.DAYS
      ) {
        this.everyDaySliderValue = true;
      } else {
        this.everyDaySliderValue =
          this.triggerGroupv2.config.conditionGroups[j].conditions[i].rules.DAYS
            .daysRule.length > 0
            ? false
            : true;
      }
      // if (this.disableDateRadioSelection) {
        this.dateRadioOption =
          this.triggerGroupv2.config.conditionGroups[j].conditions[i].rules.DATE
            .operator == "BETWEEN"
            ? 1
            : 2;
            if (eventData.eventName == "time_event" && (this.dateRadioOption == 2 || this.dateRadioOption == 1)) {
              this.disableDateRadioSelection = true;
            } else {
              this.disableDateRadioSelection = false;
            }
            if(this.triggerGroupv2.config.conditionGroups[j].conditions[i].rules?.TIME?.operator == 'BETWEEN'){
              var sTime: string =
              this.triggerGroupv2.config.conditionGroups[j].conditions[i].rules.TIME
                .ruleTimes[0];
              this.startTime.setValue(sTime);
              var eTime: string =
              this.triggerGroupv2.config.conditionGroups[j].conditions[i].rules
                .TIME.ruleTimes[1];
              this.endTime.setValue(eTime);
            }
      // }
      
      this.editDateRadioOption = this.dateRadioOption;
      var startDateValue = new Date(
        this.triggerGroupv2.config.conditionGroups[j].conditions[
          i
        ].rules.DATE.ruleDates[0]
      );
      this.startDate.setValue(startDateValue);
      if (this.dateRadioOption == 1) {
        var endDateValue = new Date(
          this.triggerGroupv2.config.conditionGroups[j].conditions[
            i
          ].rules.DATE.ruleDates[1]
        );
        this.endDate.setValue(endDateValue);

        //this.startDateMinValue = new Date(this.startDate.value);
        // this.startDateMaxValue = new Date(endDateValue);
        // this.startDateMaxValue = new Date(
        //   this.endDate.value.getFullYear(),
        //   this.endDate.value.getMonth(),
        //   this.endDate.value.getDate() - 1
        // );
        // this.endDateMinValue = new Date(
        //   this.startDate.value.getFullYear(),
        //   this.startDate.value.getMonth(),
        //   this.startDate.value.getDate() + 1
        // );
      }
      if (!this.anyTimeSliderValue && !this.disableDateRadioSelection) {
        var sTime: string =
          this.triggerGroupv2.config.conditionGroups[j].conditions[i].rules.TIME
            .ruleTimes[0];
        this.startTime.setValue(sTime);

        //this.startMinTime="00:00";
        if (
          this.triggerGroupv2.config.conditionGroups[j].conditions[i].rules.TIME
            .ruleTimes[1]
        ) {
          var eTime: string =
            this.triggerGroupv2.config.conditionGroups[j].conditions[i].rules
              .TIME.ruleTimes[1];
          this.endTime.setValue(eTime);
        } else {
          this.endTime.setValue("23:59");
        }
      }
      if (!this.everyDaySliderValue ) {
        this.weekdays =
          this.triggerGroupv2.config.conditionGroups[j].conditions[
            i
          ].rules.DAYS.daysRule;
        this.arrayDynamic = this.weekdays;
        this.weekDaysChips = this.weekDaysChips.map((x) => {
          x.selected = this.weekdays.includes(x.name);
          return x;
        });
      } else {
        this.weekdays = [];
        this.arrayDynamic = [];
        this.weekDaysChips = this.weekDaysChips.map((x) => {
          x.selected = this.weekdays.includes(x.name);
          return x;
        });
      }
      let eventKey = this.getEventKey(this.triggerGroupv2.config.conditionGroups[j].conditions[i]);
      if(this.triggerGroupv2.config.conditionGroups[j].conditions[i].rules[eventKey]?.logicalExpressions.length>=1
         && this.triggerGroupv2.config.conditionGroups[j].conditions[i].rules?.EVENT?.ruleEvent != 'nth lifetime order count') {
        this.showAdditionalTriggerConfig = true;
        this.additionalTriggerConfigs = this.eventIconMappingService.getCustomerEvents().find(event=>event.eventName === 
          this.triggerGroupv2.config.conditionGroups[j].conditions[i].rules.EVENT.ruleEvent).subEventList;
        this.preSelectedLogicalExpressions = this.triggerGroupv2.config.conditionGroups[j].conditions[i].rules[eventKey].logicalExpressions;
      } else {
        //check if additional config button must be displayed based on the name proeperty
        let eventUnderEdit = this.eventIconMappingService.getCustomerEvents().find(event=>event.eventName === 
          this.triggerGroupv2.config.conditionGroups[j].conditions[i].rules.EVENT.ruleEvent);
        if(eventUnderEdit?.subEventList?.[0]?.name) {
            this.showAdditionalTriggerConfig = true;
            this.additionalTriggerConfigs = this.eventIconMappingService.getCustomerEvents().find(event=>event.eventName === 
              this.triggerGroupv2.config.conditionGroups[j].conditions[i].rules.EVENT.ruleEvent).subEventList;
            this.preSelectedLogicalExpressions = null;
        } else {
          this.preSelectedLogicalExpressions = null;
          this.showAdditionalTriggerConfig = false;
          this.additionalTriggerConfigs = null;
        }
      }

      if(this.triggerGroupv2.config.conditionGroups[j].conditions[i].rules?.EVENT?.ruleEvent == 'nth lifetime order count') {
        this.showNumberInputForEvnet = true;
        this.nthLifeTimeOrderCountValue = this.triggerGroupv2.config.conditionGroups[j].conditions[i].rules[eventKey].logicalExpressions[0].comparisonStatements[0].operand2;
      } else {
        this.showNumberInputForEvnet = false;
      }
    } else {
      this.showNumberInputForEvnet = false;
      this.nthLifeTimeOrderCountValue = false;
      this.preSelectedLogicalExpressions = null;
      this.showAdditionalTriggerConfig = false;
      this.additionalTriggerConfigs = null;
      if (
        this.triggerGroupv2 &&
        this.triggerGroupv2.config &&
        this.triggerGroupv2.config.conditionGroups &&
        this.triggerGroupv2.config.conditionGroups.length > 0
      ) {
        this.customerEvents = this.customerEvents.filter(
          (x) => x.eventName !== "time_event"
        );
        this.filteredcustomerEvents.next(this.customerEvents.slice());
      } else {
        this.customerEvents.unshift({
          eventName: "time_event",
          displayName: "Time based Journey",
          type: "TRIGGER",
        });
        this.filteredcustomerEvents.next(this.customerEvents.slice());
      }
      this.showFirstNav = true;
      this.firstNavTitle = "ADD NEW CONDITION";
      this.arrayDynamic = [];
      this.weekdays = [];
      this.weekDaysChips = this.weekDaysChips.map((x) => {
        x.selected = this.weekdays.includes(x.name);
        return x;
      });
      this.anyTimeSliderValue = false;
      this.everyDaySliderValue = false;
      this.dateRadioOption = 1;
      //  this.startDate.setValue(new Date());

      this.startDate.setValue(
        new Date(
          new Date().toLocaleString("en-US", {
            timeZone: this._TokenStorageService.getMerchantTimeZone(),
          })
        )
      );

      this.endDate.setValue(
        new Date(new Date().setMonth(new Date().getMonth() + 6))
      );
      this.startDateMinValue = new Date(
        new Date().toLocaleString("en-US", {
          timeZone: this._TokenStorageService.getMerchantTimeZone(),
        })
      );
      // this.endDateMinValue = new Date(
      //   this.startDate.value.getFullYear(),
      //   this.startDate.value.getMonth(),
      //   this.startDate.value.getDate() + 1
      // );
      // this.startDateMaxValue = new Date(
      //   this.endDate.value.getFullYear(),
      //   this.endDate.value.getMonth(),
      //   this.endDate.value.getDate() - 1
      // );
      //  this.startTime.setValue("00:00");
      this.endTime.setValue("23:59");
      this.eventName.setValue("");
      this.anyTimeSliderValue = false;
      this.everyDaySliderValue = false;
    }
    this.checkStartDateTime();
      
  }

  deleteCondition(j: number, i: number) {
    var self = this;
    self.parentConditionIndex = j;
    self.childConditionIndex = i;
    var successButtonText: any = "";
    var disableNodeDeletion: boolean = false;
    var message: any = "";
    successButtonText = "CONFIRM";
    message = "Click confirm to proceed";
    disableNodeDeletion = false;
    const dialogRef = self.dialog.open(ConfirmationDialogBoxComponent, {
      panelClass: "no-padding-dialog-popup",
      width: "20%",
      data: {
        subject: "You are about to delete this condition",
        message: message,
        successButtonText: successButtonText,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && disableNodeDeletion) {
        self.showFirstNav = true;
        self.editCondition(j, i);
        self.changeDetection.detectChanges();
      } else if (result) {
        self.triggerGroupv2.config.conditionGroups[j].conditions.splice(i, 1);
        if (
          self.triggerGroupv2.config.conditionGroups[j].conditions.length == 0
        ) {
          if (j == 0) {
            self.triggerGroupv2.config.conditionGroups.splice(j, 1);
          } else if (
            j >= 1 &&
            j < self.triggerGroupv2.config.conditionGroups.length - 1
          ) {
            self.triggerGroupv2.config.conditionGroups[
              j - 1
            ].conditionGroupRelation =
              self.triggerGroupv2.config.conditionGroups[
                j
              ].conditionGroupRelation;
            self.triggerGroupv2.config.conditionGroups.splice(j, 1);
          } else if (
            j ==
            self.triggerGroupv2.config.conditionGroups.length - 1
          ) {
            self.triggerGroupv2.config.conditionGroups.splice(j, 1);
            self.triggerGroupv2.config.conditionGroups[
              j - 1
            ].conditionGroupRelation = "";
          }
        }
        if (
          self.triggerGroupv2 &&
          self.triggerGroupv2.config &&
          self.triggerGroupv2.config.conditionGroups &&
          self.triggerGroupv2.config.conditionGroups.length > 0
        ) {
          self.triggerNodeEditableGroupLength =
            self.triggerGroupv2.config.conditionGroups.length;
          if (self.triggerNodeEditableGroupLength == 1) {
            self.triggerNodeEditableChildLength =
              self.triggerGroupv2.config.conditionGroups[0].conditions.length;
          }
        }
        self.changeDetection.detectChanges();
      }
    });
  }
  deleteConditionResponse(j: number, i: number) {
    var self = this;
    self.parentConditionIndex = j;
    self.childConditionIndex = i;
    var successButtonText: any = "";
    var disableNodeDeletion: boolean = false;
    var message: any = "";
    successButtonText = "CONFIRM";
    message = "Click confirm to proceed";
    disableNodeDeletion = false;
    const dialogRef = self.dialog.open(ConfirmationDialogBoxComponent, {
      panelClass: "no-padding-dialog-popup",
      width: "20%",
      data: {
        subject: "You are about to delete this condition",
        message: message,
        successButtonText: successButtonText,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && disableNodeDeletion) {
        self.showFirstNav = true;
        self.editResponseEvent(j, i);
        self.changeDetection.detectChanges();
      } else if (result) {
        self.responseEventGroup.config.conditionGroups[j].conditions.splice(i, 1);
        if (
          self.responseEventGroup.config.conditionGroups[j].conditions.length == 0
        ) {
          if (j == 0) {
            self.responseEventGroup.config.conditionGroups.splice(j, 1);
          } else if (
            j >= 1 &&
            j < self.responseEventGroup.config.conditionGroups.length - 1
          ) {
            self.responseEventGroup.config.conditionGroups[
              j - 1
            ].conditionGroupRelation =
              self.responseEventGroup.config.conditionGroups[
                j
              ].conditionGroupRelation;
            self.responseEventGroup.config.conditionGroups.splice(j, 1);
          } else if (
            j ==
            self.responseEventGroup.config.conditionGroups.length - 1
          ) {
            self.responseEventGroup.config.conditionGroups.splice(j, 1);
            self.responseEventGroup.config.conditionGroups[
              j - 1
            ].conditionGroupRelation = "";
          }
        }
        if (
          self.responseEventGroup &&
          self.responseEventGroup.config &&
          self.responseEventGroup.config.conditionGroups &&
          self.responseEventGroup.config.conditionGroups.length > 0
        ) {
          self.triggerNodeEditableGroupLength =
            self.responseEventGroup.config.conditionGroups.length;
          if (self.triggerNodeEditableGroupLength == 1) {
            self.triggerNodeEditableChildLength =
              self.responseEventGroup.config.conditionGroups[0].conditions.length;
          }
        }
        self.changeDetection.detectChanges();
      }
    });
  }

  editCondition(j: number, i: number) {
    this.parentConditionIndex = j;
    this.childConditionIndex = i;
    this.configTriggerNode(j, i, true);
  }
  editResponseEvent(i: number, j: number) {
    this.showFirstNav = true;
    this.parentConditionIndex = j;
    this.childConditionIndex = i;
    // console.log("NodeData", this.nodeData)
    // var newNodeData = JSON.parse(JSON.stringify(this.nodeData));
    // var data: any = newNodeData.definition;
    // if(data["config"]["conditionGroups"][i]["conditions"][j]["rules"]["EVENT"]["ruleEvent"]==='segment'){
    //   this.actionStatus = "segment";
    //   this.customerSelectedSegment = data["config"]["conditionGroups"][i]["conditions"][j]["rules"]["EVENT"]["segments"]
    // }else{
    //   // def.event = this.eventName.value.eventName;
    //   //       def.timeUnit = "MINUTE";
    //   //       def.timeoutValue = this.getTimeInMinutes();
    //   //       def.days = this.daysControl.value;
    //   //       def.hours = this.hoursControl.value;
    //   //       def.minutes = this.minutesControl.value;
    //         // this.eventName.setValue(data["config"]["conditionGroups"][i]["conditions"][j]["rules"]["EVENT"]["event"]);
    //         this.apiCallService.getCustomerEvents().subscribe(
    //           (response) => {
    //             this.customerEvents = JSON.parse(response["body"]);
    //             var eventData: any = this.customerEvents.find(
    //               (x) => x.eventName == data["config"]["conditionGroups"][i]["conditions"][j]["rules"]["EVENT"]["ruleEvent"]
    //             );
    //             this.eventName.setValue(eventData);
    //             // self.daysControl.setValue(data.days);
    //             // self.hoursControl.setValue(data.hours);
    //             // self.minutesControl.setValue(data.minutes);
    //           },
    //           (err) => {}
    //         );
    //         // definition.days = self.daysControl.value;
    //           // definition.hours = self.hoursControl.value;
    //           // definition.minutes = self.minutesControl.value;
    //           this.daysControl.setValue(data["config"]["conditionGroups"][i]["conditions"][j]["rules"]["EVENT"]["ttl"]["days"]);
    //           this.hoursControl.setValue(data["config"]["conditionGroups"][i]["conditions"][j]["rules"]["EVENT"]["ttl"]["hours"]);
    //           this.minutesControl.setValue(data["config"]["conditionGroups"][i]["conditions"][j]["rules"]["EVENT"]["ttl"]["minutes"]);
    //           this.actionStatus = "action";
    // }
    this.showResponsePath(i, j,true);
  }

  getEventName(data: any) {
    if(data.rules.EVENT.ruleEvent == 'nth lifetime order count') {
      return this.eventIconMappingService.getEventLabel('nth lifetime order count', this.pos,
          data.rules.CUSTOM.logicalExpressions[0].comparisonStatements[0].operand2);
    } else {
      let id = data.rules.EVENT.ruleEvent;
      if (id && this.customerEvents.length > 0) {
        let eventData: any = this.customerEvents.find((x) => x.eventName == id);
        if (eventData) {
          return this.getName(eventData.eventName);
        }
        else{
          return this.getName(id);
        }
      }
    }
  }

  onSearchChange(query: any) {
    var self = this;
    // if (query) {
      if (self.nodeType == "CHANNEL" && self.channelType == "EMAIL") {
        // self.filteredData = self.emailTemplates;
        let allEmails = this.emailTemplates.concat(this.emailCustomeTemplates);
        // allEmails = [...this.emailTemplates, ...this.emailCustomeTemplates];
        this.filteredData = this.emailTemplates.concat(this.emailCustomeTemplates);
        
        self.filteredData = allEmails.filter((obj) => {
          if (!query && this.filters.length > 0) {
            return (
              this.filters.indexOf(obj.emailType) !== -1
            )
          }
          if(!query && this.filters.length == 0){
            return obj;
          }
          if(query && this.filters.length > 0){
            return (
              obj.layoutName?.toLowerCase().indexOf(query.toLowerCase()) !== -1 && this.filters.indexOf(obj.emailType) !== -1
            );
          }
          if(query&& this.filters.length == 0){
            return (
              obj.layoutName?.toLowerCase().indexOf(query.toLowerCase()) !== -1
            );
          }
        });
      } else if (self.nodeType == "CHANNEL" && self.channelType == "SMS") {
        self.filteredData = self.smsLayouts;
        self.filteredData = self.smsLayouts.filter((obj) => {
          if (!query) {
            return obj;
          }
          return obj.name.toLowerCase().indexOf(query.toLowerCase()) !== -1;
        });
      } else if (self.nodeType == "CHANNEL" && self.channelType == "WHATSAPP") {
        self.filteredData = self.whatsAppTemplates;
        self.filteredData = self.whatsAppTemplates.filter((obj) => {
          if (!query) {
            return obj;
          }
          return obj.name.toLowerCase().indexOf(query.toLowerCase()) !== -1;
        });
      }
       else if (self.nodeType == "SURVEY") {
        self.filteredData = self.surveyTemplates;
        self.filteredData = self.surveyTemplates.filter((obj) => {
          if (!query) {
            return obj;
          }
          return (
            obj.layoutName?.toLowerCase().indexOf(query.toLowerCase()) !== -1
          );
        });
      } else if (self.nodeType == "OFFER") {
        if(query) {
          self.filteredData = this.offers.filter(offer=> (offer.status.toLowerCase() != 'sent' && offer.offerName.toLowerCase().includes(query.toLowerCase())));
        } else {
          self.filteredData = this.offers.filter(offer=> (offer.status.toLowerCase() != 'sent'));
        }
        // self.filteredData = self.offers.filter((obj) => {
        //   if (!query) {
        //     return obj;
        //   }
        //   return (
        //     obj.offerName.toLowerCase().indexOf(query.toLowerCase()) !== -1
        //   );
        // });
      }
    // } else {
    //   if (self.nodeType == "CHANNEL" && self.channelType == "EMAIL") {
    //     this.filteredData = this.emailTemplates.concat(this.emailCustomeTemplates);
    //     // this.filteredData = [...this.emailTemplates, ...this.emailCustomeTemplates];
    //   } else if (self.nodeType == "CHANNEL" && self.channelType == "SMS") {
    //     self.filteredData = self.smsLayouts;
    //   }else if (self.nodeType == "CHANNEL" && self.channelType == "WHATSAPP") {
    //     self.filteredData = self.whatsAppTemplates;
    //   } 
    //   else if (self.nodeType == "SURVEY") {
    //     self.filteredData = self.surveyTemplates;
    //   } else if (self.nodeType == "OFFER") {
    //     self.filteredData = self.offers;
    //   }
    // }
  }

  public trackItem(index: number, item: any) {
    return item.id;
  }

  createEmailTemplate(id: any, clone?: String) {
    var self = this;
    self.finalScreenHeight = self.scrHeight - 61;
    self.finalScreenWidth = self.scrWidth;
    let sh = self.finalScreenHeight * (90 / 100);
    let sw = self.finalScreenWidth * (30 / 100);
    const dialogRef = self.dialog.open(EmailEditorDialogComponent, {
      panelClass: "customAttachEmail",
      height: sh + "px",
      width: self.finalScreenWidth < 1500 ? "1250px" : sw + "px",
      minWidth: "1250px",
      maxWidth: "99vw",
      minHeight: "620px",
      // maxHeight: "90vh",
      disableClose: true,
      data: {
        type: "CUSTOM",
        id: id,
        clone: clone ? clone : "false",
        return: "false",
      },
    });
    dialogRef.afterClosed().subscribe((id) => {
      let dataFinal = [];
      if (id!="null") {
        self.snackbar.openSnackBar(this._i18nDynamicTranslate.transform("Successfull", ['POS_PAGE']), 2000);
        self.selectedEmailTemplateId = id;
        self.apiCallService.listEmailLayout().subscribe(
          (response) => {
            self.emailTemplates = response["body"];
              let index: any = self.emailTemplates.findIndex(
                (x) => 
                  x.id === self.selectedEmailTemplateId
              );
              self.selectedEmailTemplateName = self.emailTemplates[index].layoutName;
              if (index>0) {
                self.getEmailLayoutContent( self.emailTemplates[index], 0);
              }
              this.attachTemplate('EMAIL');
              self.changeDetection.detectChanges();
            });
      }
    });
  }

  createSmsTemplate() {
    var self = this;
    let dialogRef = this.dialog.open(SmsTemplateCreationDialogComponent, {
      data: null,
      panelClass: "create-sms-dialog-container",
      width: "80%",
      maxHeight: "80%",
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        self.selectedSmsTemplateId = +result;
        self.snackbar.openSnackBar(this._i18nDynamicTranslate.transform("Successfull", ['POS_PAGE']), 2000);
        self.apiCallService
          .getSmsTemplates("SMS", "SMS")
          .subscribe((response) => {
            self.smsLayouts = response["body"];
            var data: any = self.smsLayouts.find(
              (x) => x.id == self.selectedEmailTemplateId
            );
            self.smsLayouts = self.smsLayouts.filter(
              (x) => x.id !== self.selectedEmailTemplateId
            );
            self.smsLayouts.unshift(data);
            self.filteredData = self.smsLayouts;
            self.selectedSmsTemplateName = data.name;
            self.changeDetection.detectChanges();
          }),
          (err) => {};
      }
    });
  }

  editLayout(layout) {
    let dialogRef = this.dialog.open(SmsTemplateCreationDialogComponent, {
      data: {
        layoutData: layout,
      },
      panelClass: "create-sms-dialog-container",
      width: "80%",
      maxHeight: "80%",
    });
  }

  getEmailLayoutContent(data, count) {
    var self = this;
    self.templateLoader = true;
    self.apiCallService.getEmailLayoutDetail(data.id).subscribe(
      (response) => {
        if (response["message"] === "success") {
          count++;
          let layout = JSON.parse(response["body"]);
          self.layoutSnapshotUrl = layout["snapShot"];
          if (!self.layoutSnapshotUrl && count < 6) {
            self.snapShotInterval = setTimeout(() => {
              self.getEmailLayoutContent(data, count);
            }, 2500);
          } else {
            clearInterval(self.snapShotInterval);
            if (self.layoutSnapshotUrl) {
              data.snapshotUrl = self.layoutSnapshotUrl;
            } else {
              data.snapshotUrl = "No Preview available";
            }
            self.emailTemplates = self.emailTemplates.filter(
              (x) => x.id !== data.id
            );
            self.emailTemplates.unshift(data);
            self.filteredData = self.emailTemplates;
            self.selectedEmailTemplateName = data.layoutName;
            self.templateLoader = false;
            self.changeDetection.detectChanges();
          }
        }
      },
      (error) => {
        if (count == 6) {
          data.snapshotUrl = "No Preview available";
          self.emailTemplates = self.emailTemplates.filter(
            (x) => x.id !== data.id
          );
          self.emailTemplates.unshift(data);
          self.filteredData = self.emailTemplates;
          self.selectedEmailTemplateName = data.layoutName;
          self.templateLoader = false;
          self.changeDetection.detectChanges();
        }
      }
    );
  }

  protected filterCustomerEvents() {
    var self = this;
    if (!self.customerEvents) {
      return;
    }
    let search = self.customerEventFilterCtrl.value;
    if (!search) {
      self.filteredcustomerEvents.next(self.customerEvents.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    self.filteredcustomerEvents.next(
      self.customerEvents.filter(
        (bank) => bank.displayName.toLowerCase().indexOf(search) > -1
      )
    );
  }

  disableAddingNewTriggerConditions() {
    if (
      this.triggerGroupv2 &&
      this.triggerGroupv2.config &&
      this.triggerGroupv2.config.conditionGroups &&
      this.triggerGroupv2.config.conditionGroups.length == 1
    ) {
      if (
        this.triggerGroupv2.config.conditionGroups[0].conditions[0].rules.EVENT
          .ruleEvent == "time_event"
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  getToolTip() {
    var tooltip = "";
    if (this.disableAddingNewTriggerConditions()) {
      tooltip = 'Multiple events can\'t be clubbed with "Time based Journey"';
    }
    return tooltip;
  }
  getTooltipOffer(channel){
    var tooltip = "";
    tooltip = "Channel: " + channel
    return tooltip;
  }

  nthLifeTimeOrderCountValue;
  showNumberInputForEvnet = false
  onCustomerEventChange(event: any) {
    if (event.value && event.value.eventName == "time_event") {
      this.isCelebration = false;
      this.disableDateRadioSelection = true;
      this.celebration = "";
      this.anyTimeSliderValue = false;
      //If days added while editing, need to remove days
      if(this.triggerGroupv2.config.conditionGroups[0] && this.triggerGroupv2.config.conditionGroups[0].conditions[0].rules.hasOwnProperty('DAYS')) {
        delete this.triggerGroupv2.config.conditionGroups[0].conditions[0].rules.DAYS;
      }
      let currentDate = new Date();
      this.startDateMinValue = new Date(
        currentDate.toLocaleString("en-US", {
          timeZone: this._TokenStorageService.getMerchantTimeZone(),
        })
      );
      this.startDate.setValue(this.startDateMinValue);
      // this.endDateMinValue = new Date(
      //   new Date(
      //     currentDate.getFullYear(),
      //     currentDate.getMonth(),
      //     currentDate.getDate() + 1
      //   ).toLocaleString("en-US", {
      //     timeZone: this._TokenStorageService.getMerchantTimeZone(),
      //   })
      // );
      this.endDateMinValue.setDate(this.startDate.value.getDate() + 1);
    }else if(event.value && (event.value.eventName == "BIRTHDAY" || event.value.eventName == "ANNIVERSARY" || event.value.eventName == "APPOINTMENT" || event.value.eventName == "MEETING")){
      // this.disableDateRadioSelection = false;
      let currentDate = new Date();
      this.startDateMinValue = new Date(
        currentDate.toLocaleString("en-US", {
          timeZone: this._TokenStorageService.getMerchantTimeZone(),
        })
      );
      this.isCelebration = true;
      this.anyTimeSliderValue = true;
      this.disableDateRadioSelection = true;
      this.celebration = event.value.eventName;
      this.everyDaySliderValue = true;
      if(event.value.eventName == "BIRTHDAY" || event.value.eventName == "ANNIVERSARY"){
        this.anyTimeSliderValue = true;
        this.disableDateRadioSelection = true;
        this.startDateMinValue.setDate(this.startDateMinValue.getDate());
        this.startDate.setValue(this.startDateMinValue);
        this.endDateMinValue.setDate(this.startDate.value.getDate() + 1); 
      }
      else{
        this.disableDateRadioSelection = false;
        this.anyTimeSliderValue = false;
        let currentDate = new Date();
        this.startDateMinValue = new Date(
          currentDate.toLocaleString("en-US", {
            timeZone: this._TokenStorageService.getMerchantTimeZone(),
          })
        );
        this.startDate.setValue(this.startDateMinValue);
        this.endDateMinValue.setDate(this.startDate.value.getDate() + 1);
      }
    } 
    else {
      if(event.value.eventName == "tier_upgrade"){
        this.celebration = event.value.eventName;
      }
      else{
        this.celebration = "";
      }
      this.disableDateRadioSelection = false;
      this.isCelebration = false;
      let currentDate = new Date();
      this.startDateMinValue = new Date(
        currentDate.toLocaleString("en-US", {
          timeZone: this._TokenStorageService.getMerchantTimeZone(),
        })
      );
      this.startDate.setValue(this.startDateMinValue);
      // this.endDateMinValue = new Date(
      //   new Date(
      //     currentDate.getFullYear(),
      //     currentDate.getMonth(),
      //     currentDate.getDate() + 1
      //   ).toLocaleString("en-US", {
      //     timeZone: this._TokenStorageService.getMerchantTimeZone(),
      //   })
      // );
      this.endDateMinValue.setDate(this.startDate.value.getDate() + 1);
    }
    //check for nth lifetime order count
    if(event.value.eventName == 'nth lifetime order count') {
      this.showNumberInputForEvnet = true;
    } else {
      this.showNumberInputForEvnet = false;
    }
    //Check if trigger has more config
    //name property currently used to show additional config button 
    if(event.value?.subEventList?.[0]?.name) {
      this.showAdditionalTriggerConfig = true;
      this.preSelectedLogicalExpressions = null;
      this.additionalTriggerConfigs = event.value.subEventList;
    } else {
      this.preSelectedLogicalExpressions = null;
      this.showAdditionalTriggerConfig = false;
      this.additionalTriggerConfigs = null;
    }
  }

  onOfferTypeSelectChange(checked: boolean, type: string) {
    if (type == "SMS") {
      this.smsCheckbox = !this.smsCheckbox;
    } else if (type == "EMAIL") {
      this.emailCheckbox = !checked;
    }
    else if (type == "WHATSAPP"){
      this.whatsappCheckBox = !checked;
    }
    if (this.smsCheckbox && !this.emailCheckbox && !this.whatsappCheckBox) {
      this.filteredData = this.offers.filter((x) => x.channel == "SMS");
    } else if (!this.smsCheckbox && this.emailCheckbox && !this.whatsappCheckBox) {
      this.filteredData = this.offers.filter((x) => x.channel == "EMAIL");
    } else if (!this.smsCheckbox && !this.emailCheckbox && !this.whatsappCheckBox) {
      this.filteredData = [];
    } else if (this.smsCheckbox && !this.emailCheckbox && this.whatsappCheckBox){
      this.filteredData = this.offers.filter((x) => (x.channel == "SMS" || x.channel == "WHATSAPP"));
    } else if (!this.smsCheckbox && this.emailCheckbox && this.whatsappCheckBox){
      this.filteredData = this.offers.filter((x) => (x.channel == "EMAIL" || x.channel == "WHATSAPP"));
    } else if (!this.smsCheckbox && !this.emailCheckbox && this.whatsappCheckBox){
      this.filteredData = this.offers.filter((x) => x.channel == "WHATSAPP");
    } 
    else {
      this.filteredData = this.offers;
    }
  }

  previewOfferSummary(id: any) {
    const dialogRefer = this.dialog.open(OfferSummaryDialogV2Component, {
      panelClass: "offerSummarydialog",
      width: "60%",
      maxWidth: "90vw",
      maxHeight: "95vh",
      data: id,
    });
    dialogRefer.afterClosed().subscribe((result) => {
      if (result) {
      }
    });
  }
  previewComarketingOfferSummary(objectData){
    const dialogRefer = this.dialog.open(PreviewComarketingOfferComponent, {
      panelClass: "customAttachEmail",
      width: "40%",
      maxWidth: "546px",
      data: objectData,
    });
    dialogRefer.afterClosed().subscribe((result) => {
      if (result) {
      }
    });
  }

  previewLoyaltyRule(rule) {
      const dialogRefer = this.dialog.open(LoyaltyRulePreviewDialogComponent, {
        panelClass: "customer-dialog-popup",
        maxWidth: "80%",
        data: {
          rule: rule,
          loyaltyPoints : this.loyaltyProgramConfig['fullConfig'].rewardPoints,
          loyaltyRules : this.loyaltyProgramConfig['loyaltyRules'],
          offerList : this.offers,
          applicableCustomerGroupList : this.customerSegmentAndTiers,
        },
      });
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  gaAnalyticsConditionSet(data) {
    this._gaConditionString = "";
    var i, j;
    for (i = 0; i < data.config.conditionGroups.length; i++) {
      for (j = 0; j < data.config.conditionGroups[i].conditions.length; j++) {
        this._gaConditionString =
          this._gaConditionString +
          this.getEventName(
            data.config.conditionGroups[i].conditions[j]
          ) +
          ";";
      }
    }
  }

  gaAnalyticsBlockCondition(value) {
    switch (value) {
      case "RESPONSE_EVENT":
        this._gaConditionString = "Save:Add If/Else Branch";
        break;
      case "WAIT":
        this._gaConditionString = "Save:Add Time Delay";
        break;
      case "EMAIL":
        this._gaConditionString = "Save:Send Email";
        break;
      case "OFFER":
        this._gaConditionString = "Save:Send Offer";
        break;
      case "SURVEY":
        this._gaConditionString = "Save:Send Survey";
        break;
      default:
        this._gaConditionString = "";
    }
  }
  dateCompareSub(time1, time2) {
    var t1 = new Date();
    var parts = time1.split(":");
    t1.setHours(parts[0], parts[1], 0);
    var t2 = new Date();
    parts = time2.split(":");
    t2.setHours(parts[0], parts[1], 0);
    // returns 1 if greater, -1 if less and 0 if the same
    if (t1.getTime() > t2.getTime()) return 1;
    if (t1.getTime() < t2.getTime()) return -1;
    return 0;
  }
  addMinutes(time, minsToAdd) {
    var piece = time.split(":");

    var mins = piece[0] * 60 + +piece[1] + +minsToAdd;

    return this.D(((mins % (24 * 60)) / 60) | 0) + ":" + this.D(mins % 60);
  }
  D(J) {
    return (J < 10 ? "0" : "") + J;
  }
  getLayout2(layout){
    let layoutData = JSON.parse(layout['layoutData']);
    return layoutData['body'];
  }

  getLayout(layout, returnType){
    let layoutData = JSON.parse(layout['layoutData']);
    if(returnType === 'header'){
      if(layoutData['header'])
        return layoutData['header'].toLowerCase();
      else
        return
    }
    else if(returnType ==='buttons'){
      if(layoutData['buttons'] != null){
        if(layoutData['buttons'].length>0){
          if(layoutData['buttons'][0]['quickReply']){
            return layoutData['buttons'][0]['quickReply'];
          }
          if(layoutData['buttons'][0]['urlText']){
            return layoutData['buttons'][0]['urlText'];
          }
        }
      }
    }
    else{
      return layoutData[returnType];

    }
  }

  previewWhatsApp(){
    var data: any = this.whatsAppTemplates.find(
      (x) => ((x.id == this.selectedWhatsappTemplateId)  && (x.name == this.selectedWhatsappTemplateName))
    );
    let layout = JSON.parse(data['layoutData']);
    const dialogRef = this.dialog.open(SmsPreviewDialogComponent, {
      panelClass: "sms-preview-dialog",
      data: {
        smsText: layout,
        type: "WhatsApp"
      },
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }
  checkAccountCreated(element?){
    if(this.tokenStorage.getIsAccountCreated() === 'false'){
      const dialogRef = this.dialog.open(ConfirmationDialogBoxComponent, {
        panelClass: "no-padding-dialog-popup",
        data: {
          subject: 'WhatsApp account not linked',
          message: 'You can link WhatsApp Business account by clicking on Link.',
          cancelButtonText: "Cancel",
          successButtonText: "Link",
        }
      })
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.create360dialog.createAccountNew();
        }
        else{
          setTimeout(() => {
            element.checked = false;
            this.ren.removeClass(element['_elementRef'].nativeElement, 'cdk-focused');
            this.ren.removeClass(element['_elementRef'].nativeElement, 'cdk-program-focused');
            this.nodeOptionType = "";
        })
        }
      });
    }
  }
  checkIfOnBoarded(){
    this.apiCallService.checkWhatsAppOnboarded().subscribe((response)=>{
      this.isOnboarded = 'true';
    },
    (err)=>{
      this.isOnboarded = 'false';
    });
  }
  showExperimentationPath(isAbTesting?) {
    this.filterData = {};
    this.showFirstNav = true;
    if(isAbTesting == 'ABTESTING') {
      this.prefillABTestingData();
    }
    //PRESELECT SEGMENT LOGIC FOR AUDIENCE SPLIT REMOVED
     // this.strategyData['segments'].forEach((segment)=> {
    //   this.updateCheckboxValuesCustomerSegment(segment);
    // });
  }

  resetLoyaltyData() {
    this.loyaltyNodeData = {
      "type": null,
      "loyaltyRule": null,
      "rewardPointsBean": null,
      "tierChangeType": null,
    }
  }


  loyaltyNodeData;
  openPointsInputPanel() {
    this.loyaltyNodeData =  {
      "type": 'REWARD_POINTS',
      "loyaltyRule": null,
      "rewardPointsBean": {
        "adjustmentType": "EARN",
        "points": null,
      },
      "tierChangeType": null,
    }
  }

  openTierUpgradeDowngradePanel() {
    this.loyaltyNodeData = {
      "type": 'TIER_CHANGE',
      "loyaltyRule": null,
      "rewardPointsBean": null,
      "tierChangeType": 'UPGRADE',
    }
  }


  onCheckboxChange1(type: 'EARN' | 'EXPIRE', event: any) {
    if (event.checked) {
      this.loyaltyNodeData.rewardPointsBean.adjustmentType = type;
    } else {
      this.loyaltyNodeData.rewardPointsBean.adjustmentType = type == 'EARN'?'EXPIRE':'EARN';
    }
  }

  onCheckboxChange2(type: 'UPGRADE' | 'DOWNGRADE', event: any) {
    if (event.checked) {
      this.loyaltyNodeData.tierChangeType = type;
    } else {
      this.loyaltyNodeData.tierChangeType = type == 'UPGRADE'?'DOWNGRADE':'UPGRADE';
    }
  }

  openLoyaltyRuleCreation(rule?) {
    const dialogRef1 = this.dialog.open(LoyaltyRuleCreationDialogComponent, {
      panelClass: "customer-dialog-popup",
      width: "80%",
      height: "90%",
      data: {
        rule: rule,
        loyaltyPoints : this.loyaltyProgramConfig['fullConfig'].rewardPoints,
        // loyaltyRules : this.loyaltyProgramConfig['loyaltyRules'],
        // offerList : this.offers,
        // applicableCustomerGroupList : this.customerSegmentAndTiers,
      },
    });

    dialogRef1.afterClosed().subscribe((result) => {
      if (result) {
        this.loyaltyNodeData = {
          "type": 'LOYALTY_RULE',
          "loyaltyRule": result,
          "rewardPointsBean": null,
          "tierChangeType": null,
        };
        this.changeDetection.detectChanges();
      } else {
        console.log('Dialog closed without data');
      }
    });
  }

  editExperimentationPath(){
    this.showFirstNav = true;
    this.firstNavTitle = "CONFIGURE A/B TESTING";
  }
  showResponsePath(j?, i?, edit?: boolean){
    this.showFirstNav = true;
    this.responseGroupIndex = j;
    this.responseChildIndex = i;
    if(edit ===true){
      this.firstNavTitle = "CONFIGURE NEW CONDITION"
      var eventData: any = this.customerEvents.find(
        (x) =>
          x.eventName ==
          this.responseEventGroup.config.conditionGroups[j].conditions[i].rules
            .EVENT.ruleEvent
      );
      this.eventName.setValue(eventData);
      var dayControlTime =
            this.responseEventGroup.config.conditionGroups[j].conditions[i].rules.EVENT.ttl.days;
      var hourControlTime =
            this.responseEventGroup.config.conditionGroups[j].conditions[i].rules.EVENT.ttl.hours;
      var minutesControlTime =
            this.responseEventGroup.config.conditionGroups[j].conditions[i].rules.EVENT.ttl.minutes;
            this.daysControl.setValue(dayControlTime);
            this.hoursControl.setValue(hourControlTime);
            this.minutesControl.setValue(minutesControlTime);    
      
      let eventType = this.getEventKey(this.responseEventGroup.config.conditionGroups[j].conditions[i]);
      if(this.responseEventGroup.config.conditionGroups[j].conditions[i].rules[eventType]?.logicalExpressions.length>=1) {
             this.showAdditionalTriggerConfig2 = true;
             this.additionalTriggerConfigs2 = this.eventIconMappingService.getCustomerEvents().find(event=>event.eventName === 
               this.responseEventGroup.config.conditionGroups[j].conditions[i].rules.EVENT.ruleEvent).subEventList;
             this.preSelectedLogicalExpressions2 = this.responseEventGroup.config.conditionGroups[j].conditions[i].rules[eventType].logicalExpressions;
      } else {
             //check if additional config button must be displayed based on the name proeperty
             let eventUnderEdit = this.eventIconMappingService.getCustomerEvents().find(event=>event.eventName === 
               this.responseEventGroup.config.conditionGroups[j].conditions[i].rules.EVENT.ruleEvent);
             if(eventUnderEdit.subEventList?.[0]?.name) {
                 this.showAdditionalTriggerConfig2 = true;
                 this.additionalTriggerConfigs2 = this.eventIconMappingService.getCustomerEvents().find(event=>event.eventName === 
                   this.responseEventGroup.config.conditionGroups[j].conditions[i].rules.EVENT.ruleEvent).subEventList;
                 this.preSelectedLogicalExpressions2 = null;
             } else {
               this.preSelectedLogicalExpressions2 = null;
               this.showAdditionalTriggerConfig2 = false;
               this.additionalTriggerConfigs2 = null;
             }
      }
    }
    else{
      this.preSelectedLogicalExpressions2 = null;
      this.showAdditionalTriggerConfig2 = false;
      this.additionalTriggerConfigs2 = null;
      this.firstNavTitle = "ADD NEW CONDITION"
      this.eventName.setValue("");
      // definition.days = self.daysControl.value;
        // definition.hours = self.hoursControl.value;
        // definition.minutes = self.minutesControl.value;
        this.daysControl.setValue(0);
        this.hoursControl.setValue(0);
        this.minutesControl.setValue(0);
        this.actionStatus = "action";
    }
      // this.segmentName = [];
      // this.customerSelectedSegment = [];

    // this.responseEventGroup.config.conditionGroups[this.responseGroupIndex].conditions[this.responseChildIndex]
    // this.responseEventGroup.config.conditionGroups[this.responseGroupIndex].conditions[this.responseChildIndex] = [];
  }

  addPath() {
    if(this.selectedABConfigType==='MANUAL'){
      if(this.abTesting.length <= 4) {
        this.abTesting.push({
          percValue: 0,
          pathName: `Path-${this.abTesting.length+1}`,
        });
      }
      this.distrubuteEvenly();
    }
    else{
      if(this.abTesting.length <= 4) {
        this.abTesting.push({
          percValue: 10,
          pathName: `Path-${this.abTesting.length+1}`,
        });
      }
      this.updateRemainingPercentage()
    }
    this.clearPathErrors();
  }

  prefillABTestingData() {
    if(this.abTesting.length==0 ) {
      this.abTesting = [
        {
          percValue: 50,
          pathName: 'Path-1',
        },
        {
          percValue: 50,
          pathName: 'Path-2',
        }
      ];
      this.distrubuteEvenly();
    }
  }
  clearPathErrors() {
    for(let path of this.abTesting) {
      path['pathErrorMessage'] = '';
    }
  }
  deletePath(i) {
    this.abTesting.splice(i, 1);
    if(this.selectedABConfigType==='MANUAL'){
      this.distrubuteEvenly();
    }
    else{
      this.updateRemainingPercentage()
    }
    this.clearPathErrors();
  }
  
  deleteGroup(i){
    this.groupNameGroup.splice(i,1);
  }
  editGroup(i){
    this.showFirstNav = true;
    this.firstNavTitle = "CONFIGURE AUDIENCE SPLIT"
    this.groupName = this.groupNameGroup[i];
    this.chnagePreviousNodeIdWithPrevName();
    this.temp_split_Name = this.groupName;
    this.showfilter = false;
    this.showClicked = false;
    this.filterData = {};
    this.customerSelectedSegment = [...(this.nodeData.definition.splitGroups[this.groupName]['segments'] || []),...(this.nodeData.definition.splitGroups[this.groupName]['tiers'] || []),...(this.nodeData.definition.splitGroups[this.groupName]['customerList'] || [])];
    if(this.nodeData.definition.splitGroups[this.groupName] && this.nodeData.definition.splitGroups[this.groupName]['filter'].hasOwnProperty('segmentDefinition')){
      this.filterData = { ...this.nodeData.definition.splitGroups[this.groupName]['filter'] };
      this.filterChange();
    }
    if(this.splitGroup && this.splitGroup[this.groupName].filter.hasOwnProperty('segmentDefinition')){
      this.filterData = { ...this.splitGroup[this.groupName].filter };
      this.filterChange();
      this.customerSelectedSegment = [...(this.splitGroup[this.groupName]['segments'] || []),...(this.splitGroup[this.groupName]['tiers'] || [])];
    }
    setTimeout(() => {
      this.changeDetection.detectChanges();
    }, 1000);
  }

  chnagePreviousNodeIdWithPrevName(){

  }
  distrubuteEvenly() {
    this.clearPathErrors();
    let len = this.abTesting.length;
    const eachPart = 100 / len;
    const values = Array.from({ length: len }, () => eachPart);

    // Adjust the last value to make the sum exactly 100
    const sum = values.reduce((acc, val) => acc + val, 0);
    const diff = 100 - sum;
    values[values.length - 1] += diff;

    // Round values to the specified number of decimal places
    const roundedValues = values.map(val => Number(val.toFixed(2)));

    for(let i=0;i<roundedValues.length;i++) {
      this.abTesting[i].percValue = roundedValues[i];
    }
  }
  getCustomerSegment() {
    //NEED
    this.apiCallService.getAllCustomerSegments().subscribe(
      (response) => {
        if (response["message"] == "success") {
          let segment = (response["body"]);
          for (let i = 0; i < segment.length; i++) {
            this.customerSegment.push({
              id: segment[i]["id"],
              name: segment[i]["segmentName"],
              isActive: segment[i]["isActive"],
              status: false,
              category: segment[i]["category"],
            });
          }
        } else {
          this.snackbar.openSnackBar(this._i18nDynamicTranslate.transform("Failed to load customer segments", ['POS_PAGE']), 1000);
        }
      },
      (err) => {
        this.snackbar.openSnackBar(this._i18nDynamicTranslate.transform(err.error.body, ['POS_PAGE']), 1000);
      }
    );
  }
  isChecked(segment) {
    if (
      this.customerSelectedSegment.length === 1 &&
      this.customerSelectedSegment[0] === "ALL"
    ) {
      return false;
    } else {
      let index = this.customerSelectedSegment.findIndex(
        (x) => x === segment.id
      );
      if (index > -1) {
        return true;
      } else {
        return false;
      }
    }
  }
  addAllCustomers(event) {
    this.customerSelectedSegment = [];
    if (event["checked"] === true) {
      this.customerSelectedSegment = [];
      this.customerSelectedSegment.push("ALL");
    }
  }
  updateCheckboxValuesCustomerSegment(segment) {
    if (
      this.customerSelectedSegment.length === 1 &&
      this.customerSelectedSegment[0] === "ALL"
    ) {
      this.customerSelectedSegment = [];
    }
    let index = this.customerSelectedSegment.findIndex((x) => x === segment.id);
    if (index < 0) {
      this.segmentName.push(segment["name"]);
      this.customerSelectedSegment.push(segment.id);
    } else {
      this.segmentName.splice(index, 1);
      this.customerSelectedSegment.splice(index, 1);
    }
  }

  @HostListener('document:click', ['$event'])
  clickOutside(event) {
    if (this.dropdownStatusSegment) {
      let parent = document.getElementById("qb-user-segment-div");
      let element = document.getElementById("multi-select-dropdown-seg");
      let segmentSelect = document.getElementById("segment-select-seg");
      let segmentSelectPanel = document.getElementById("multi-select-panel-seg");
      let keyboardDownIconElement = document.getElementById(
        "keyboard-down-icon-container-seg"
      );
      const clickedElement = event.target as HTMLElement;
      if (!parent.contains(clickedElement)) {
        this.dropdownStatusSegment = false;
        element.style.height = "0px";
        segmentSelect.style.overflow = "hidden";
        segmentSelectPanel.style.boxShadow = "unset";
        segmentSelectPanel.style.border = "1px solid #636466";
        keyboardDownIconElement.style.transform = "rotate(0deg)";
      }
    }
  }
  
  toggleSelectSegment(click?) {
    let element = document.getElementById("multi-select-dropdown-seg");
    let segmentSelect = document.getElementById("segment-select-seg");
    let segmentSelectPanel = document.getElementById("multi-select-panel-seg");
    let keyboardDownIconElement = document.getElementById(
      "keyboard-down-icon-container-seg"
    );
    if (click && element && segmentSelect && segmentSelectPanel) {
      this.dropdownStatusSegment = false;
      element.style.height = "0px";
      segmentSelect.style.overflow = "hidden";
      segmentSelectPanel.style.boxShadow = "unset";
      segmentSelectPanel.style.border = "1px solid #636466";
      keyboardDownIconElement.style.transform = "rotate(0deg)";
    } else {
      if (this.dropdownStatusSegment) {
        this.dropdownStatusSegment = false;
        element.style.height = "0px";
        segmentSelect.style.overflow = "hidden";
        segmentSelectPanel.style.boxShadow = "unset";
        segmentSelectPanel.style.border = "1px solid #636466";
        keyboardDownIconElement.style.transform = "rotate(0deg)";
        //element.style.display = 'none';
      } else {
        this.dropdownStatusSegment = true;
        element.style.height = "200px";
        segmentSelect.style.overflow = "auto";
        segmentSelectPanel.style.boxShadow = "0px 1px 6px 0px #25a7f9";
        segmentSelectPanel.style.border = "1px solid #636466";
        keyboardDownIconElement.style.transform = "rotate(180deg)";
        //element.style.display = 'block';
      }
    }
  }
  filterChange() {
    this.showfilter = true;
    this.showClicked = true;
  }
  // changeInputhPath(index, v) {
  //   this.abTesting[index] = parseFloat(v);
  //   let l = this.abTesting.length;
  //   if (l == 2 && !this.controlPath) {
  //     let v2 = 100 - v;
  //     this.abTesting[(index + 1) % 2] = v2;
  //   }
  //   // for(let i =0; i< l-1;i++){
  //   //   if(i != index)
  //   //     this.abTesting[i] = (100-v)/(l-1);
  //   // }
  // }
  addControl() {
    this.controlPath = !this.controlPath;
  }
  changeValue(i, v) {
    this.abTesting[i]['percValue'] = parseFloat(v);
  }
  checkSum(){
    // let sum = 0;
    // for(let i =0;i<this.abTesting.length;i++){
    //   sum = sum + parseFloat(this.abTesting[i]);
    // }
    // console.log("TESTT",sum)
    // if((sum === 100 || sum === 99.99) && this.zeroError === false)
    //   return true;
    // else 
    //   return false;
      // if (this.abTesting.reduce((acc, val) => acc + val, 0) === 100) if (distributedValues.reduce((acc, val) => acc + val, 0) === totalValue) {
      //   console.log('Distributed values:', this.abTesting);
      //   return true;
      // } else {
      //   console.log('Distribution error');
      //   return false;
      // }

      const tolerance = 0.03; // Tolerance for sum check


      const sum = this.abTesting.reduce((acc, val) => parseFloat(acc) + parseFloat(val.percValue), 0);

      if (this.selectedABConfigType === 'MANUAL') {
        return Math.abs(sum - 100) <= tolerance;
      } else {
        const allPathsValid = this.abTesting.every((condition) => parseFloat(condition.percValue || '0') <= 100);
        return sum <= 100 && allPathsValid;
      }
  }

  checkZero(){
    for(let i =0;i<this.abTesting.length;i++){
    if(this.abTesting[i]['percValue'] === 0)
      return true;
  }
  return false;
  }

  checkPathFields() {
    let pathNameSet = new Set();
    for(let i =0;i<this.abTesting.length;i++) {
      this.abTesting[i].pathName = this.abTesting[i].pathName.trim();
      if(pathNameSet.has(this.abTesting[i].pathName)) {
        this.abTesting[i]['pathErrorMessage'] = `Cannot use the same branch name`;
        return true;
      } else {
        pathNameSet.add(this.abTesting[i].pathName);
      }
      if(!this.abTesting[i].pathName || this.abTesting[i].pathName.length < 3) {
        this.abTesting[i]['pathErrorMessage'] = 'Name length should be greater than 3';
        return true;
      }
      if(!this.abTesting[i]['percValue']){
        this.abTesting[i]['pathErrorMessage'] = 'Percentage value invalid'
        return true;
      }
    }
    return false;
  }

  collectAbTestingData(data){
    for(let i in data.pathGroups){
      this.abTesting.push({
        pathName: data.pathGroups[i].pathName,
        percValue:  data.pathGroups[i].weight,
      });
    }
    this.atrWindow = data.attributionDuration;
    this.selectedABConfigType = data.mabType;
    if( data.mabType!='MANUAL'){
      if (this.isTimeEvent) {
        this.waitWindow = data.mabRequest.waitTimePeriod; 
    }
      this.selectedKPI = data.mabRequest.selectedKpi
      if(this.atrWindow[0] === 0 && (this.atrWindow[1] === 0 )){
        this.expWindowOption = 'default';
      }
    }
  }

  // }
  // getAttribute(){
  //   this.apiCallService.getCustomerSegmentVariables().subscribe(
  //     (response) => {
  //       if (response["message"] === "success") {
  //         let segmentVariable = response["body"];
  //         let keys = [];
  //         for (let key in segmentVariable) {
  //           keys.push(key);
  //         }
  //         keys = keys.sort();
  //         keys.forEach((element) => {
  //           this.segmentVariables[element] = segmentVariable[element];
  //         });
  //         this.dataFetchComplete = true;
  //         this.initForm();
  //       } else {
  //         this.popUp.openPopup("failure", "Failed to load data");
  //       }
  //     },
  //     (err) => {
  //       this.popUp.openPopup("failure", err.error.body);
  //     }
  //   );
  // }

  redirectToLoyalty() {
    this._router.navigate(["/app/loyalty"]);
  }
  async getComarketingOfferTemplate(){
    await this.apiCallService.getCoMarketingOffers().subscribe((response)=>{
      console.log("RESPOMNSE",response)
      this.coMarketingOffers = response['body']['comarketingOffers'];
      console.log("TESDTT",this.coMarketingOffers)
    })
  }
  recieveChannelSelect($event){
    this.channelData = $event;
    this.channelSelect = false;
    this.channelSave = true;
  }
  numberInputHandler(event: KeyboardEvent,maxLength,inputType?) {

    const inputValue = (event.target as HTMLInputElement).value;
    const decimalPlaces = 2;
    if (inputType.toLowerCase() != 'float') {
      const invalidCharacters = ['e', '-', '+', '.'];
      if ((inputValue.length >= maxLength && event.key !== 'Backspace') || invalidCharacters.includes(event.key)) {
        event.preventDefault();
      }
    } else {
      const invalidCharacters = ['e', '-', '+']
      const dotIndex = inputValue.indexOf('.');
      if (dotIndex !== -1 && inputValue.substring(dotIndex + 1).length >= decimalPlaces && event.key !== 'Backspace') {
        event.preventDefault();
      } else {
        if ((inputValue.length >= maxLength && event.key !== 'Backspace') || invalidCharacters.includes(event.key)) {
          event.preventDefault();
        }
      }
    }
  }
  showChannel(){
    this.channelSelect = true;
    this.firstNavTitle = "SELECT SURVEY CHANNEL";
  }
  isTierSegmentPresent(type): boolean {
    switch(type){
      case 'segment':
        return (this.customerSegmentAndTiers).some(segment => segment.category.toLowerCase() === 'segment');
      case 'tier':
        return (this.customerSegmentAndTiers).some(segment => segment.category.toLowerCase() === 'tier');
      case 'customerlist':
        return (this.customerSegmentAndTiers).some(segment => segment.category.toLowerCase() === 'customerlist');
      case 'employeelist':
        return (this.customerSegmentAndTiers).some(segment => segment.category.toLowerCase() === 'employeelist');
    }
  }
  getCustomerSegmentsAndTiers() {
    this.apiCallService.getCustomerSegmentsAndTiers().subscribe(response => {
      let res = (response['body']);
      let j = 1;
      for (let i = 0; i < res.length; i++) {
        this.customerSegmentAndTiers.push({
          id: res[i]["id"],
          name: res[i]["segmentName"],
          isActive: res[i]["isActive"],
          status: false,
          category: res[i]["category"],
          createdBy: res[i]["createdBy"]
        });
        if(res[i].category.toLowerCase() === 'tier') {
          this.customerTierList.push({
            id: res[i]["id"],
            name: res[i]["segmentName"],
            isActive: res[i]["isActive"],
            status: false,
            category: res[i]["category"],
            level: res[i]["tierLevel"]
          });
        }
      }
      this.assigntoAndFromForTier()
      this.filterSegmentsAndCustomerLists();
      console.log(this.customerTierList)
    },
    error => {
      this.snackbar.openSnackBar(this._i18nDynamicTranslate.transform("Error fetching getCustomerSegmentsAndTiers data", ['POS_PAGE']), 2000);
    });
  }

  filterSegmentsAndCustomerLists() {
    // StoreCreated segments
    this.storeCreatedSegments = this.customerSegmentAndTiers.filter(segment => 
      segment.category.toLowerCase() === 'segment' && this.isStoreCreated(segment)
    );

    // Other segments
    this.otherSegments = this.customerSegmentAndTiers.filter(segment => 
      segment.category.toLowerCase() === 'segment' && !this.isStoreCreated(segment)
    );

    // StoreCreated customer lists
    this.storeCreatedCustomerLists = this.customerSegmentAndTiers.filter(segment => 
      segment.category.toLowerCase() === 'customerlist' && this.isStoreCreated(segment)
    );

    // Other customer lists
    this.otherCustomerLists = this.customerSegmentAndTiers.filter(segment => 
      segment.category.toLowerCase() === 'customerlist' && !this.isStoreCreated(segment)
    );
  }

  isStoreCreated(segment) {
    return segment.createdBy?.storeId === this.storeId;
  }


  getName(event){
    return this._i18nDynamicTranslate.transform(this.eventIconMappingService.getEventLabel(
      event, this.pos
    ), ['POS_PAGE']);
  }
  getDateFormat(type?){
    // this.date_format.getDateFormat();
    return this.date_format.getDateFormat(type);
  }

  changeCelebration(value, celeb){
    this.celebrationType = value;
    if(value === 'Custom'){
      this.celebrationRuleEevnt = value.toUpperCase() + "_" + celeb;
    }
    else{
      this.celebrationRuleEevnt = celeb + "_" + value.toUpperCase();
    }
  }

  openOfferCreateDiaglog() {
    const dialogref = this.dialog.open(OfferCreateDialogComponent,{});

    dialogref.afterClosed().subscribe((result) => {
      if (result && result.action === 'closeAfterOfferCreation') {
        this.getOfferData(result.offerId);
      }
    });
  }
  getCelebrationName(value, days){
    switch (value) {
        case "BIRTHDAY_WEEK":
        case "ANNIVERSARY_WEEK":
          return "Week"
        case "BIRTHDAY_MONTH":
        case "ANNIVERSARY_MONTH":
          return "Month"
        case "CUSTOM_BIRTHDAY":
        case "CUSTOM_ANNIVERSARY":
          return days[0] + " " + "days prior";
        case "APPOINTMENT":
          return "Instant"
        case "MEETING":
          return "Instant"
    }
  }
  getCelebrationType(value){
    switch(value){
      case "Week":
      case "Month":
        return this.celebration + " " + value;
      case "Custom":
        return this.dateRadioOption === 1 ? value + " " + this.celebration : "On " + this.celebration;
        
    }
  }
  checkDiffForCustom(){
    console.log(this.startDate, this.endDate);
    let timediff = this.endDate.value.getTime() - this.startDate.value.getTime();
    let diffdays =  Math.floor(timediff / (1000 * 60 * 60 * 24));
    if(this.daysPriorToCelebration > diffdays){
      return true;
    }
    else{
      return false;
    }
  }
  checkDiffForAppointment(){
    console.log(this.startDate, this.endDate);
    let timediff = this.endDate.value.getTime() - this.startDate.value.getTime();
    let diffdays =  Math.floor(timediff / (1000 * 60 * 60 * 24));
    if(this.daysControlAppointment > diffdays){
      return true;
    }
    else{
      return false;
    }
  }
  transformTo24HourFormat(time12hr: string): string {
    if (sessionStorage.getItem("serviceCountry") === "US") {
    // Use the DatePipe to format the time in 12-hour format
    let formattedTime = time12hr
    
    // Parse the formatted time to get the hour and minute
    let parts = formattedTime.split(':');
    let hour = parseInt(parts[0], 10);
    let minute = parseInt(parts[1], 10);
  
    // Check if it's PM, and if it is, add 12 to the hour
    if (time12hr.toLowerCase().includes('pm') && hour < 12) {
      hour += 12;
    }
    else if (time12hr.toLowerCase().includes('am') && hour == 12){
      hour = 0;
    }
  
    // Format the hour and minute as two-digit strings
    let hourStr = hour.toString().padStart(2, '0');
    let minuteStr = minute.toString().padStart(2, '0');
  
    // Return the time in 24-hour format
    return `${hourStr}:${minuteStr}`;
  }
  else{
    return time12hr;
  }
  }
  transformTo12HourFormat(time24hr: string): string {
    // Split the time string into hours and minutes
    if (sessionStorage.getItem("serviceCountry") === "US") {
    const [hoursStr, minutesStr] = time24hr.split(':');
    const hours = parseInt(hoursStr, 10);
    const minutes = parseInt(minutesStr, 10);
  
    // Determine AM or PM based on hours
    const period = hours >= 12 ? 'PM' : 'AM';
  
    // Convert hours to 12-hour format
    const hours12 = hours > 12 ? hours - 12 : hours === 0 ? 12 : hours;
  
    // Format the time in 12-hour format
    const formattedTime = `${hours12}:${minutes.toString().padStart(2, '0')} ${period}`;
  
    return formattedTime;
    }
    else{
      return time24hr;
    }
  }
  
  checkChannel(type, list, channel) {
    let channelList;
  
    try {
      channelList = JSON.parse(list); // Safely parse the list
    } catch (error) {
      console.error("Invalid JSON:", error);
      return false; // Return false if JSON parsing fails
    }
  
    if (channelList && typeof channelList === 'object') {
      if (channelList.hasOwnProperty(type.toLowerCase())) {
        return true;
      }
    }
  
    return channel === type;
  }
  
  addFilter(type){
    let allTypes = ["STANDARD", "CUSTOM"];
    if(this.filters.indexOf(type) !== -1){
      this.filters.splice(this.filters.indexOf(type), 1);
    }else{
      this.filters.push(type)
      if(type === 'ALL'){
        allTypes.forEach((customer) => {
          if (this.filters.indexOf(customer) == -1)
            this.filters.push(customer);
        });
      }
    }
    allTypes.forEach((customer) => {
      if (this.filters.indexOf(customer) == -1)
        if (this.filters.indexOf("ALL") != -1)
          this.filters.splice(this.filters.indexOf("ALL"), 1);
    });
    if (type === "ALL") {
      if (this.filters.indexOf("ALL") == -1) {
        this.filters = [];
      }
    }
    this.onSearchChange('');
  }
  changeAppointment(type){
    this.appointmentType = type;
  }
  getAppointmentName(type, data){
    return data[0] + ' days ' + data[1] + ' hours prior.';
  }
  changeToTier(name, level){
    this.toTier = name;
    this.toTierLevel = level;
    if(this.fromTierLevel >= this.toTierLevel){
      let index = this.customerTierList.findIndex(
        (x) => x.level === this.fromTierLevel
      );
      this.fromTierLevel = this.customerTierList[index - 1].level;
      this.fromTier = this.customerTierList[index - 1].name;
    }
  }
  changeFromTier(name, level){
    this.fromTier = name;
    this.fromTierLevel = level;
    if(this.fromTierLevel >= this.toTierLevel){
      let index = this.customerTierList.findIndex(
        (x) => x.level === this.fromTierLevel
      );
      this.toTierLevel = this.customerTierList[index + 1].level;
      this.toTier = this.customerTierList[index + 1].name;
    }
  }
  assigntoAndFromForTier(){
    this.customerTierList.sort((a,b)=> a.level - b.level);
    this.fromTier = this.customerTierList[0]?.name;
    this.fromTierLevel = this.customerTierList[0]?.level;
    this.toTier = this.customerTierList[1]?.name;
    this.toTierLevel = this.customerTierList[1]?.level;
  }
  handleEmoji(e)  {
    let inputField: any = document.querySelector('#emailSubjectInputField');
    const caretPosition = inputField.selectionStart;
    let text = '';
    text = this.emailSubject.value;
    let updatedContent =`${text.slice(0, caretPosition)}${e.char}${text.slice(caretPosition)}`;
    this.emailSubject.setValue(updatedContent);
  }

  handleCaretChange(eve){
    console.log(eve);
  }
  @HostListener("document:click", ["$event"])
  clickout3(event3) {
    // if (document.getElementById("helpProfile").contains(event3.target)) {
    //   this.displayHelpPopover();
    // } else {
    //   this.closeHelpPopover();
    // }
    if (document.getElementById("emojiContainer")?.contains(event3.target) || document.getElementById("emojiselector")?.contains(event3.target)) {
    } else {
      this.toggled = false;
    }
  }
  getInfoForEvents(value){
    let ele = document.getElementById('inforForIcon');
    if(this.eventsInfo[value]){
      ele.style.display = 'flex';
      return this.eventsInfo[value];
    }
    else{
      ele.style.display = 'none';
    }
  }
  checkIfBeforeCurrentDate(date){
    const options = { timeZone: this._TokenStorageService.getMerchantTimeZone() };
    const today = new Date();
    const todayInTimezone = today.toLocaleString('en-US', options);
    const todayInTimezoneDate = new Date(todayInTimezone);
    // Extract only the date part (year, month, and day)
    const year = todayInTimezoneDate.getFullYear();
    const month = todayInTimezoneDate.getMonth() + 1; // Month is zero-indexed, so add 1
    const day = todayInTimezoneDate.getDate();

  // Create a new Date object with only the date (time will be set to midnight)
  const todayWithoutTime = new Date(year, month - 1, day); 
  // const todayWithoutTime = new Date(Date.UTC(year, month - 1, day));

  // Convert to specific timezone

  // If you need it as a Date object

  // Assuming you have the selected date in a variable called selectedDate
  const selectedDate = new Date(date); // Replace this with your selected date

  // Check if the selected date is before today's date
  if (selectedDate < todayWithoutTime) {
    return true;
  } else if (selectedDate > todayWithoutTime) {
    return false;
  } else {
    return false;
  }
  }

  addValueAbtesting(event, index){
    console.log(event.srcElement.value, index);
    this.abTesting[index] = event.srcElement.value
  }

  isDigit(input) {
    return /^\d+$/.test(input);
  }

  
  additionalTriggerConfigs2;
  preSelectedLogicalExpressions2;
  onResponseEventSelect(event){
    let selectedEvent = event.value;
    if(selectedEvent.subEventList?.[0]?.name) {
      this.showAdditionalTriggerConfig2 = true;
      this.additionalTriggerConfigs2 = selectedEvent.subEventList;
      this.preSelectedLogicalExpressions2 = null;
    } else {
      this.preSelectedLogicalExpressions2 = null;
      this.showAdditionalTriggerConfig2 = false;
      this.additionalTriggerConfigs2 = null;
    }
  }

  stringInputHandler(event: KeyboardEvent,maxLength) {
    // if(this.duplicateNameErrorText) this.duplicateNameErrorText = '';
    const inputValue = (event.target as HTMLInputElement).value;
    if (inputValue.length >= maxLength && event.key !== 'Backspace') {
      event.preventDefault();
    }
  }

  checkCelebration(celebration, checkEqual){
    switch(celebration){
      case "APPOINTMENT":
      return checkEqual?true:false
      case "MEETING":
        return checkEqual?true:false
      default:
        return !checkEqual;
    }
  }


  validateLoyaltyRule(selectedRule,nodeId,data,strategyNodeRequest) {
    this.disableSaveConfigOnLeftPanel = true;
    const snackBarRef = this.matSnackbar.open("Validating Rule...", '', {
      duration: undefined
    });
    this.apiCallService.validateCampaignLoyaltyNodeRule(selectedRule).subscribe(
      response => {
        let self = this;
        if(response['body'].hasOwnProperty(102)) {
          self.showRuleCreationPopup = false;
          const dialogref = self.dialog.open(
            ConfirmationDialogBoxComponent,
            {
              panelClass: "no-padding-dialog-popup",
              width: "280px",
              data: {
                message: response['body'][102],
                successButtonText: "Confirm",
              }
            }
          );
          //save
          let definition = this.loyaltyNodeData;
          strategyNodeRequest.definition = JSON.stringify(definition);
          data.nodeData = {
            nodeType: data.nodeType,
            definition: definition,
          };
          this.updateOrCreateNode(nodeId,data,strategyNodeRequest);
        } else if(response['body'].hasOwnProperty(100)) {
          const dialogref = self.dialog.open(
            ConfirmationDialogBoxComponent,
            {
              panelClass: "no-padding-dialog-popup",
              width: "280px",
              data: {
                message: response['body'][100],
                successButtonText: "Confirm",
              }
            }
          );
          //dont save
        } else if(response['body'].hasOwnProperty(103)) {
          const dialogref = self.dialog.open(
            ConfirmationDialogBoxComponent,
            {
              panelClass: "no-padding-dialog-popup",
              width: "280px",
              data: {
                message: response['body'][103],
                successButtonText: "Conrim",
              }
            }
          );
          //dont save
        } else {
          let definition = this.loyaltyNodeData;
          strategyNodeRequest.definition = JSON.stringify(definition);
          data.nodeData = {
            nodeType: data.nodeType,
            definition: definition,
          };
          this.updateOrCreateNode(nodeId,data,strategyNodeRequest);
        }
        self.changeDetection.detectChanges();
        snackBarRef.dismiss();
      },
      error=>{
        snackBarRef.dismiss();
        this.snackbar.openSnackBar(this._i18nDynamicTranslate.transform("Error validating rule", ['POS_PAGE']), 2000);
      }
    );
  }

  cloneEmail(data, event){
    event.stopPropagation();
    console.log("data of template:", data);
    this.createEmailTemplate(data.id, "true");
  }

  getEventKey(child): string | null {
    const searchKeys = ['NTHTRIGGER', 'CUSTOM'];
    const mySet = new Set(searchKeys);
 
    // Use find to get the first matching key
    const foundKey = Object.keys(child.rules).find(key => mySet.has(key));

    console.log("FOUND",foundKey);
    
    return foundKey || null; // Return the found key or null if none is found
  }

  monthControl = new FormControl();
  
  onMonthSelected(event: Date, picker: any) {
    // Set startDate to the first day of the selected month
    const selectedDate = new Date(event);

    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear(); 
  
    if (selectedDate.getMonth() === currentMonth && selectedDate.getFullYear() === currentYear) {
      // If it's the current month and year, set to today's date
      selectedDate.setDate(currentDate.getDate()); 
    } else {
      // otherwise select the first date of that month
      selectedDate.setDate(1);
    }
    this.startDate.setValue(selectedDate);

    // Close the month picker
    picker.close();

    this.startDateChange();
  }

  getMonthDisplay(date: Date): string {
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long' }; 
    return date ? new Intl.DateTimeFormat('en-US', options).format(date) : '';
  }

  getWeekRange(date: Date): string {
    const startOfWeek = this.getStartOfWeek(date);
    const endOfWeek = this.getEndOfWeek(date);

    const startDate = this.formatDate(startOfWeek);
    const endDate = this.formatDate(endOfWeek);

    return `${startDate} - ${endDate}`;
  }

  // Helper method to get the start of the week (Sunday)
  getStartOfWeek(date: Date): Date {
    const startDate = new Date(date);
    const day = startDate.getDay();
    const diff = startDate.getDate() - day; // Adjust to get the Sunday of the current week
    startDate.setDate(diff);
    startDate.setHours(0, 0, 0, 0);
    return startDate;
  }

  // Helper method to get the end of the week (Saturday)
  getEndOfWeek(date: Date): Date {
    const startDate = this.getStartOfWeek(date);
    const endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() + 6); // Saturday
    endDate.setHours(23, 59, 59, 999);
    return endDate;
  }

  // Format the date as "MMM dd, yyyy" (e.g., "Jan 01, 2024")
  formatDate(date: Date): string {
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
    }).format(date);
  }

  getDisplay(data, value) {
    if (data === "BIRTHDAY_MONTH" || data === "ANNIVERSARY_MONTH") {
        return this.getMonthDisplay(new Date(value));
    }
    if (data === "BIRTHDAY_WEEK" || data === "ANNIVERSARY_WEEK") {
        return this.getWeekRange(new Date(value));
    }
    if (data === "CUSTOM_BIRTHDAY" || data === "CUSTOM_ANNIVERSARY") {
        return this._dateFormatService.formatDate(value);
    }
}

onABConfigChange(event){
  if((this.selectedABConfigType==='MANUAL')) {
    this.abTesting = [
      {
        percValue: 50,
        pathName: 'Path-1',
      },
      {
        percValue: 50,
        pathName: 'Path-2',
      }
    ];
    this.distrubuteEvenly();
  }
  else if((this.selectedABConfigType==='BAYESIAN_BANDIT_MEAN_SELECTOR' || this.selectedABConfigType==='BAYESIAN_BANDIT') ){
    this.abTesting = [
      {
        percValue: 10,
        pathName: 'Path-1',
      },
      {
        percValue: 10,
        pathName: 'Path-2',
      }
    ];
  }

  this.updateRemainingPercentage();
  if(this.selectedABConfigType=='MANUAL'){
    this.expWindowOption = 'custom';
    this.atrWindow=[0,7]
  }
  else{
    this.expWindowOption = 'default';
    this.atrWindow=[0,0];
  }
  
}

isKpiSelected(): boolean {
  if (this.selectedABConfigType != 'MANUAL' && (!this.selectedKPI)) {
    return false;
  }
  return true;
}

getABConfigOptions(){
  this.apiCallService.getABTestingConfigs().subscribe((response: any) => {
    const body = response.body;
    this.distributionConfigOptions = Object.entries(body.DISTRIBUTION_CONFIG).map(([key, value]) => ({
      key,
      value
    }));
    this.kpiOptions = Object.entries(body.ANALYSIS_KPIS).map(([key, value]) => ({
      key,
      value
    }));
  });
}

updateRemainingPercentage(): void {
  const totalPercentage = this.abTesting.reduce((sum, condition) => {
    return sum + (condition.percValue ? +condition.percValue : 0);
  }, 0);
  this.remainingPercentage = Math.max(0, 100 - totalPercentage); // Ensure it doesn't go below 0
}

onDurationTypeChange(event: any): void {
  this.expWindowOption = event.value;
  
  if (this.expWindowOption === 'default') {
    this.atrWindow = [0, 0]; // Reset atrWindow for default duration
  }
  else{
    this.atrWindow=[0,7]
  }
}

getABTestHeading(): string {
  switch (this.selectedABConfigType) {
    case 'MANUAL':
      return 'CAMPAIGN_PAGE.AB_TESTING_MANUAL_HEADING';
    case 'BAYESIAN_BANDIT_MEAN_SELECTOR':
      return 'CAMPAIGN_PAGE.AB_TESTING_BMD_HEADING';
    case 'BAYESIAN_BANDIT':
      return 'CAMPAIGN_PAGE.AB_TESTING_BD_HEADING';
  }
}


}
