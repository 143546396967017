import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute,Router } from '@angular/router';
import { EChartsOption } from 'echarts';
import { ApiCallService } from 'src/app/core/services/api-call-service';
import { SnackbarCollection } from 'src/app/core/services/snackbar.service';
import { SubscriptionPlanHandlerService } from 'src/app/core/services/subscription-plan-handler.service';
import { WidgetConfigService } from 'src/app/core/services/widget-config.service';
import { AbGraphInsightDialogComponent } from 'src/app/shared/components/dialog-box/ab-graph-insight-dialog/ab-graph-insight-dialog.component';

@Component({
  selector: 'app-campaign-ab-testing-analytics',
  templateUrl: './campaign-ab-testing-analytics.component.html',
  styleUrls: ['./campaign-ab-testing-analytics.component.css']
})
export class CampaignAbTestingAnalyticsComponent implements OnInit {
  selectedKPIforOverallAnalytics;
  validWidgets;
  appSubscriptionPlan;
  graphOptions: string[] = ['Date range graph', 'Intial conversion rate', 'Total conversion rate']; // Graph option names
  selectedGraphIndex = 0; 
  chartOptions: EChartsOption = {}; 
  showDateRangeFilter: boolean = false; 
  startDate; 
  endDate; 
  selectedInterval: 'day'; 
  intervalOptions: ['day', 'week', 'month']; 
  minDate: Date;
  maxDate: Date; 
  trackedMetrices;
  pathAnalyticsData;
  pathAnalysisList = [];
  strategyData: any;
  xIntervalConstantList = ['day','month','week','hour'];
  isgraphDataEmpty;
  noDataOption = {
    title: {
      text: 'No Data Available',
      left: 'center',
      top: 'middle',
      textStyle: {
        color: '#ccc',
        fontSize: 16,
      },
    },
    tooltip: { show: false },
    xAxis: { show: false },
    yAxis: { show: false },
    series: [],
  };
  isKPIAvailable;
  winnerDetails: { winnerText: string; improvementText: string } | null;
  selectedGraphTitle = 'Date range graph';
  selectedGraphDescription = 'Comparing KPIs over time';
  isLoading: boolean = true; // Loader state
  hasData: boolean = false; // Indicates if there is valid data for analysis
  

  constructor(
    private _WidgetConfigService: WidgetConfigService,
    private dialog: MatDialog,
    private apiCallService : ApiCallService,
    private snackBar : SnackbarCollection,
    private route: ActivatedRoute,
     private _SubscriptionPlanHandlerService: SubscriptionPlanHandlerService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.initializeData();
  }
  
  async initializeData(){
      this.strategyData = history.state.abTestCamp;
      if(!this.strategyData){
        this.router.navigate(['/app/campaign/ab-testing']);
      }
      await this.getABNodeMetrices();
      this.getWidgets();
      this.getABPathAnalytics(this.strategyData.strategyId, this.strategyData.nodeId);
      this.updateChartOptions();
  }
  

  async getWidgets() {
    this.validWidgets = await this._WidgetConfigService.getWidget();
    this.appSubscriptionPlan =
      await this._WidgetConfigService.getAppSubscriptionPlan();
  }

  async getABNodeMetrices(): Promise<void> {
    try {
      const response: any = await this.apiCallService.getABNodeMetrices(this.strategyData.strategyId).toPromise();
      
      this.trackedMetrices = Object.keys(response['body']).map((key) => ({
        value: key,
        displayName: response['body'][key]
      }));
  
      if (this.trackedMetrices.length > 0) {
        // Automatically select the first KPI by default
        this.selectedKPIforOverallAnalytics = this.trackedMetrices[0].value;
        this.hasData = true; 
      }
    } catch (err) {
      console.log('Failed to get AB Testing Metrices');
      this.hasData = false;
      this.isLoading = false; 
    }
  }

  async createCampaign() {
    if (await this._SubscriptionPlanHandlerService.IsValidOperation(this.appSubscriptionPlan)) {
        this.router.navigate(
          [
            "../creation",
            {
              "return-url": "campaign/ab-testing",
              id: null,
              recommendation: false,
            },
          ],
          { relativeTo: this.route }
        );
      }
  }


  selectGraph(index): void {
    this.selectedGraphIndex=index;
    // this.showDateRangeFilter = index === 2;
    this.updateChartOptions(); 
  }

  updateChartOptions(): void {
    let graphType;
    let requestData: any = {
      startDate: this.getDateTimeInFormatForRequest(this.strategyData.startDate, 'start'),
      endDate: this.getDateTimeInFormatForRequest(this.strategyData.endDate, 'end'),
      kpiType: this.selectedKPIforOverallAnalytics,
      event: null,
      timePeriod: this.xIntervalConstantList[0],  // Default to 'day' for now
      abNodeId: this.strategyData.nodeId,
      strategyId: this.strategyData.strategyId
    };
    switch (this.selectedGraphIndex) {
      case 0:
        // Date Range Graph
        graphType = 'DATE_RANGE';
        this.selectedGraphTitle = 'Date range graph';
        this.selectedGraphDescription = 'Comparing KPIs over time';
        requestData.graphType = graphType;
        this.getGraphData(requestData);
        break;
      case 1:
        // Initial Conversion Rate Graph
        graphType = 'INITIAL_CONVERSION_RATE';
        this.selectedGraphTitle = 'Intial Conversion Rate Distribution';
        this.selectedGraphDescription = 'Comparing initial conversion rates between paths';
        requestData.graphType = graphType;
        this.getGraphData(requestData);
        break;
      case 2:
        // Total Conversion Rate Graph
        graphType = 'TOTAL_CONVERSION_RATE';
        this.selectedGraphTitle = 'Total Conversion Rate Distribution';
        this.selectedGraphDescription = 'Comparing total conversion rates between paths';
        requestData.graphType = graphType;
        this.getGraphData(requestData);
        break;
    }

  }


  getGraphData(requestData: any): void {
    this.apiCallService.getABGraphAnalytics(requestData).subscribe(
      (response: any) => {
        if (response.message === 'SUCCESS') {
          this.updateChartData(response.body);
        }
      },
      (error) => {
        console.error('Error fetching graph data', error);
      }
    );
  }

  getWindowNoGraphOption(): EChartsOption {
    const xAxisOpt: EChartsOption['xAxis'] = {
      type: 'category',
      boundaryGap: true,
      data: ['No Data', 'No Data', 'No Data'], // Placeholder data
      nameLocation: 'end',
      nameGap: 3,
    };
  
    const yAxisOpt: EChartsOption['yAxis'] = {
      type: 'value',
      show: true,
      min: 0,
      max: 100, // Static range for default chart
    };
  
    const tooltipOpt: EChartsOption['tooltip'] = {
      trigger: 'axis',
    };
  
    const legendOpt: EChartsOption['legend'] = {
      data: [],
      type: 'scroll',
    };
  
    const gridOpt: EChartsOption['grid'] = {
      left: '7%',
      right: '8%',
      bottom: '5%',
      containLabel: true,
    };
  
    const seriesOpt: EChartsOption['series'] = []; // Empty series for "No Data"
  
    return {
      tooltip: tooltipOpt,
      legend: legendOpt,
      grid: gridOpt,
      xAxis: xAxisOpt,
      yAxis: yAxisOpt,
      series: seriesOpt,
    };
  }
  

  updateChartData(responseBody: any): void {
   
    if (!responseBody || Object.keys(responseBody).length === 0 ||  Object.values(responseBody).every(pathData => Object.keys(pathData).length === 0)) {
      this.isgraphDataEmpty = true;
      this.chartOptions = this.getWindowNoGraphOption();
    }
    else{
      this.isgraphDataEmpty = false;
      switch (this.selectedGraphIndex) {
        case 0:  // Date Range Graph
          this.updateDateRangeGraph(responseBody);
          break;
        case 1:  // Initial Conversion Rate Graph
          this.updateConversionRateGraph(responseBody, 'INITIAL_CONVERSION_RATE');
          break;
        case 2:  // Overall Conversion Rate Graph
          this.updateConversionRateGraph(responseBody, 'TOTAL_CONVERSION_RATE');
          break;
      }
    }

    
  }

  updateDateRangeGraph(responseBody: any): void {
    const legendData = Object.keys(responseBody); // Path names like "Path-1", "Path-2"
    const xAxisData = this.getSortedKeys2(
        Object.keys(responseBody[legendData[0]]) // Get dates from the first path
    );

    const selectedKPIDisplayName = this.trackedMetrices.find(
      (metric) => metric.value === this.selectedKPIforOverallAnalytics)?.displayName || 'Value'; 

    // Prepare series data with proper mapping
    const seriesData = legendData.map(pathKey => ({
        name: pathKey,
        type: 'line',
        smooth: true,
        data: xAxisData.map(dateKey =>
            responseBody[pathKey][dateKey] !== undefined
                ? parseFloat(responseBody[pathKey][dateKey])
                : 0 // Use 0 for missing values
        ),
    }))as any;

    // Configure the chart options
    this.chartOptions = {
        tooltip: { trigger: 'axis' },
        legend: { data: legendData },
        xAxis: {
            type: 'category',
            data: xAxisData,
            name: 'Date',
        },
        yAxis: {
            type: 'value',
            name: selectedKPIDisplayName
        },
        series: seriesData,
    };
}

getSortedKeys2(keys: string[]): string[] {
  return keys.sort((a, b) => {
      const dateA = new Date(a);
      const dateB = new Date(b);
      return dateA.getTime() - dateB.getTime();
  });
}

updateConversionRateGraph(responseBody: any, graphType: string): void {
  // Ensure pathAnalysisList is available
  if (!this.pathAnalysisList || this.pathAnalysisList.length === 0) {
      return;
  }

  const legendData = this.pathAnalysisList.map((path) => path.pathName); // Path names

  const largestMaxIndex = legendData.reduce((maxIndex, pathKey, index) => {
    const maxKeyValue = Math.max(...Object.keys(responseBody[pathKey] || {}).map(Number));
    const currentMaxValue = Math.max(...Object.keys(responseBody[legendData[maxIndex]] || {}).map(Number));
    return maxKeyValue > currentMaxValue ? index : maxIndex;
}, 0);
  
  const xAxisData = this.getSortedKeys(responseBody[legendData[largestMaxIndex]]); // Sorted keys for X-Axis

  // Prepare the series data
  const seriesData = legendData.map(pathKey => {
      // Find the path data from pathAnalysisList
      const pathData = this.pathAnalysisList.find((path) => path.pathName === pathKey);

      // Use the appropriate conversion rate (initial or total) based on graphType
      const meanValue = graphType === 'initial' ? pathData?.initialConversionRate : pathData?.totalConversionRate;

      
      // Get the full data for this path (all points)
      const fullData = xAxisData.map(key => parseFloat(responseBody[pathKey][key]) || 0);

      return {
          name: pathKey,
          type: 'line',
          smooth: true,
          areaStyle: {}, // Optional, for smoother area appearance
          data: fullData, // Full data for the graph
          lineStyle: {
              opacity: 0.6, // Reduce line opacity for better emphasis on the mean point
          },
          itemStyle: {
              opacity: 0, // Hide all data points by default
          },
          emphasis: {
              focus: 'none', // Prevent highlighting other points
          },
          markPoint: {
              symbol: 'circle',
              symbolSize: 10,
              data: [
                  {
                      coord: [meanValue, fullData.find(val => val === meanValue)], // Coordinates for the mean point
                      itemStyle: {
                          color: 'red', // Highlight color for the mean
                          borderWidth: 2,
                          borderColor: 'black',
                      },
                      label: {
                          show: true,
                          formatter: `Mean: ${meanValue.toFixed(2)}`,
                          position: 'top',
                      },
                  },
              ],
          },
      };
  }) as any;

  console.log(seriesData);

  // Set chart options
  this.chartOptions = {
      tooltip: {
          trigger: 'axis',
          formatter: (params: any) => {
              const series = params[0].seriesName;
              const xAxisValue = params[0].dataIndex; 
              console.log(xAxisValue);
              return `${series}: ${xAxisValue/10}%(Conversion rate)`;
          },
      },
      xAxis: {
          type: 'category',
          data: xAxisData,
          name: 'Conversion Rate (%)', // Label for X-Axis
          nameLocation: 'middle',
          nameGap: 30,
      },
      yAxis: {
          type: 'value',
          name: 'Probability Density', // Change Y-Axis label to "Probability Density"
          nameLocation: 'middle',
          nameGap: 50,
          axisLabel: {
              show: false, // Hide Y-axis data values
          },
      },
      series: seriesData,
      legend: {
          data: legendData,
      },
  };
}

// Helper function to get color for each path (can customize)
getPathColor(pathKey: string): string {
  const pathColors: { [key: string]: string } = {
      'Path-1': 'rgba(75, 192, 192, 1)', // Color for Path-1
      'Path-2': 'rgba(153, 102, 255, 1)', // Color for Path-2
      // Add more paths and their colors as needed
  };

  return pathColors[pathKey] || 'rgba(0, 0, 0, 1)'; // Default color if path is not found
}


getSortedKeys(data: any): string[] {
  return Object.keys(data).sort((a, b) => parseFloat(a) - parseFloat(b)); // Sort keys numerically
}


  getDateTimeInFormatForRequest(timestamp, timeToAppend?) {
    if (!timestamp) return null;
  
    let date = new Date(timestamp);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
  
    let hours, minutes, seconds;
  
    if (timeToAppend === 'start') {
      hours = '00';
      minutes = '00';
      seconds = '00';
    } else if (timeToAppend === 'end') {
      hours = '23';
      minutes = '59';
      seconds = '59';
    } else {
      hours = String(date.getHours()).padStart(2, '0');
      minutes = String(date.getMinutes()).padStart(2, '0');
      seconds = String(date.getSeconds()).padStart(2, '0');
    }
  
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }
  

  KPIChange(){
    this.getABPathAnalytics(this.strategyData.strategyId, this.strategyData.nodeId);
    this.updateChartOptions();
    this.evaluateWinner();
  }

  // applyFilters(): void {
  //   if (this.selectedGraphIndex === 2) {
  //     const filteredData = this.getFilteredData(this.graphData, this.startDate, this.endDate, this.selectedInterval);
  //     this.chartOptions.series[0].data = filteredData; // Update chart data dynamically
  //   }
  // }
  
  // Mock function to filter data based on date range and interval
  // getFilteredData(data: any[], startDate: Date, endDate: Date, interval: string): any[] {
    // Logic to filter and transform data based on the date range and interval
    // return data.filter(item => {
    //   const date = new Date(item.date);
    //   return date >= startDate && date <= endDate;
    // });

// }

openUnderstandGraphDialog(): void {
  const title =
    this.selectedGraphIndex === 1
      ? 'Initial conversion rate'
      : 'Total conversion rate';
    this.dialog.open(AbGraphInsightDialogComponent, {
      panelClass: "no-padding-dialog-popup",
      width: "600px",
    data: { title }, 
  });
}

getABPathAnalytics(strategyId, nodeId) {
  this.apiCallService.getABPathAnalytics(strategyId,nodeId).subscribe(
    (response)=>{
      this.pathAnalyticsData = response['body'];
      this.evaluateWinner();
      this.updatePathAnalysisList();
    },
    (err)=>{
      this.isLoading = false;
      // this.snackBar.openSnackBar('Failed to get AB Testing Path Analytics',2000);
      console.log('Failed to get AB Testing Path Analytics')
    }
  );
};

updatePathAnalysisList() {
  if (this.pathAnalyticsData) {
    this.pathAnalysisList = Object.keys(this.pathAnalyticsData).map((pathKey) => {
      const pathData = this.pathAnalyticsData[pathKey];

      // Use the selected KPI to fetch the correct data from kpiAnalyticsMap
      const selectedKPIData = pathData.kpiAnalyticsMap?.[this.selectedKPIforOverallAnalytics] ?? {};

      return {
        pathName: pathKey,
        uniqueConversions: selectedKPIData.uniqueConversions ?? 0,
        totalCustomers: pathData.customerCount ?? 0,
        initialConversionRate: selectedKPIData.mabAnalytics?.priorConversionRate ?? 0,
        totalConversionRate: selectedKPIData.totalConversionRate ?? 0,
        totalConversions: selectedKPIData.totalKpiValue ?? 0,
        isWinner: selectedKPIData.mabAnalytics?.winner ?? false
      };
      
    });
    this.isLoading=false;
  } else {
    this.isLoading = false;
    this.pathAnalysisList = [];
  }
}

evaluateWinner() {
  if (!this.selectedKPIforOverallAnalytics || !this.pathAnalyticsData) {
    this.winnerDetails = null;
    this.isLoading = false;
    return;
  }
  for (const path in this.pathAnalyticsData) {
    const mabAnalytics =
      this.pathAnalyticsData[path]?.kpiAnalyticsMap?.[this.selectedKPIforOverallAnalytics]?.mabAnalytics;

    if (mabAnalytics?.winner) {
      this.winnerDetails = {
        winnerText: mabAnalytics.winnerText,
        improvementText: mabAnalytics.improvementText,
      };
      return;
    }
  }
  this.winnerDetails = null;
}



}
