<div class="qb-header" >
    <span class="qb-header-title"><strong>{{'SHARED_PAGE.SEND_TEST_SMS' | i18nTranslate}}</strong></span>
    <mat-dialog-actions>
      <button class="qb-xIcon" mat-dialog-close><div class="material-icons qb-icon-position">clear</div></button>
    </mat-dialog-actions>
  </div>
  <div >
    <div >
      <div class="qb-enterEmail">{{'CUSTOMER_PAGE.TO_SEND_PHONE_NUMBER' | i18nTranslate}}</div>
      <div class="qb-selectDomainName qb-d-flex qb-fd-coloumn">
        <span class="qb-filterTitle">{{'CUSTOMER_PAGE.PHONE_NUMBER' | i18nTranslate}}</span>
        <input type="text"
        ng2TelInput
        [ng2TelInputOptions]="{onlyCountries: [country]}"
        (ng2TelOutput)="getNumber($event)"
        (intlTelInputObject)="telInputObject($event)"
        (keypress)="keypress()"
        (countryChange)="onCountryChange($event)" />
        <div *ngIf="error.length > 0" class="qb-error-text">{{error | i18nDynamicTranslate : 'POS_PAGE'}}</div>
      </div>
    </div>
    <div class="qb-buttons">
      <mat-dialog-actions>
        <button class="qb-cancel secondary-button-border" mat-dialog-close>{{'CUSTOMER_PAGE.CANCEL' | i18nTranslate}}</button>
      </mat-dialog-actions>
      <mat-dialog-actions>
        <button class="qb-confirm primary-button" (click)="sendTestSMS()"  >{{'SHARED_PAGE.SEND' | i18nTranslate}}</button>
      </mat-dialog-actions>
    </div>
  </div>