<div style="width: 100%;display:flex;justify-content: space-between;align-items: center;">
    <div style="font-weight: bold;font-size: 16px;">Rule Creation</div>
    <div class="top-buttons">
        <button class="primary-button button-hover-effect" (click)="validateAndReturnRule()"><span class="material-symbols-outlined">save</span>  Save</button>
        <mat-icon (click)="closedialog()" style="cursor: pointer;">close</mat-icon>
    </div>
</div>
<div class="dialog-content-container">
    <div class="center-loading"  *ngIf="!dataLoaded">
        <app-qubriux-loading [size]="55"></app-qubriux-loading>
    </div>
    <div class="rc-values-container"  *ngIf="dataLoaded">
        <label class="label-style">{{'SEGMENT_PAGE.RULE_NAME' | i18nTranslate}}</label><span class="qb-imp-color">*</span>
        <div class="rp-name-inp-container max-width-100" [id]="'ruleNameInput'">
            <input class="rp-name-inp" type="text" placeholder="Enter your rule name" [(ngModel)]="rule.ruleName" maxlength="30" (keydown)="resetErrorState()" #inp>
            <div class="inp-text-left">{{inp.getAttribute('maxlength') - inp.value.length}} {{'SEGMENT_PAGE.CHAEACTER_LEFT' | i18nTranslate}}</div>
        </div>
    </div>
    <div *ngIf="dataLoaded">
        <div class="flex">
            <div class="title-label">{{'SEGMENT_PAGE.CONDITION_SETUP' | i18nTranslate}}</div>
            <span class="qb-i-div"
                popoverclass="qb-my-custom-class"
                placement="right"
                [ngbPopover]="popConditionSetup"
                triggers="mouseenter:mouseleave"
                >i
            </span>
            <ng-template #popConditionSetup>
                <div class="qb-ng-template-text">
                    {{'SEGMENT_PAGE.CONDITION_SETUP_INFO_MSG' | i18nTranslate}}
                </div>
            </ng-template>
        </div>
        <div class="flex basic-rule-box st-1">
            <div class="rule-text">{{'SEGMENT_PAGE.FOR_EVERY' | i18nTranslate}}</div>
            <div class="basic-values-container">
                <label class="label-style">{{'SEGMENT_PAGE.EVENT_FREQ' | i18nTranslate}}</label><span class="qb-imp-color">*</span>
                <div class="rp-name-inp-container rule-value" [id]="'eventCountInput'">
                    <input class="rp-name-inp" type="number" placeholder="eg. 2" [(ngModel)]="rule.ruleDefinition.eventCount" [disabled]="!rule.ruleDefinition.eventDropdownObj.valueEditable" (keydown)="numberInputHandler($event,7,rule.ruleDefinition.eventDropdownObj.dataType)"
                    (keyup)="resetErrorState()" #inp2 maxlength="7" min="0" max="9999999">
                    <div class="inp-text-left">{{inp2.getAttribute('maxlength') - inp2.value.length}} {{'SEGMENT_PAGE.CHAEACTER_LEFT' | i18nTranslate}}</div>
                </div>
            </div>
            <div class="basic-values-container width-24-perc">
                <label class="label-style">{{'SEGMENT_PAGE.EVENT_NAME' | i18nTranslate}}</label><span class="qb-imp-color">*</span>
                <div class="rp-name-inp-container rule-value">
                    <mat-select [(ngModel)]="rule.ruleDefinition.eventDropdownObj" (selectionChange)="setBasicRuleEventDetail(rule)" >
                        <mat-option *ngFor="let ruleEvent of getFiltered(ruleEventDropdownValues)" [value]="ruleEvent" selected>{{ruleEvent.displayName}}</mat-option>
                    </mat-select>
                </div>
            </div>
            <div class="earn-container">
                <div class="rule-text">{{'SEGMENT_PAGE.EARN' | i18nTranslate}}</div>
                <div class="display-flex-2">
                    <div class="width-135">
                        <label class="label-style">Loyalty Point</label><span class="qb-imp-color">*</span>
                        <div class="rp-name-inp-container rule-value" [id]="'rewardPointInput'">
                            <img class="margin-right-5" src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/loyalty/inputFieldCoin.svg" alt="coinImg" width="20px" height="20px">
                            <input class="rp-name-inp" type="number" placeholder="eg. 100" [(ngModel)]="rule.rewardPoints" (keydown)="numberInputHandler($event,10,'float')" (keyup)="resetErrorState()" #inp3 maxlength="10" [disabled]="!rule.hasRewardPoints" min="0" max="9999999999">
                            <div class="inp-text-left">{{inp3.getAttribute('maxlength') - inp3.value.length}} {{'SEGMENT_PAGE.CHAEACTER_LEFT' | i18nTranslate}}</div>
                        </div>
                    </div>
                    <div class="rule-text padding-top-34">{{'SEGMENT_PAGE.POINTS' | i18nTranslate}}</div>
                </div>
            </div>
        </div>
        <!-- SPECIAL CONFIG FOR ORDER CATEGORIES OF AMOUNT SPENT AND ORDER PLACED RULE-->
        <div *ngIf="orderCategoriesList?.length>0 && (rule.ruleDefinition.event == '$amount_spent' || rule.ruleDefinition.event == '$order_placed')" class="margin-bottom-10">
            <div class="inner-rule-container">
                <div class="padding-13">
                    <div class="title-label-smaller">Excluded Order Categories</div>
                    <div class="label-style">Order Categories selected here do not receive points from the parent rule.
                    </div>
                </div>
                <div class="margin-right-20 center-flex-column">
                    <div class="qb-d-flex">
                        <label class="label-style">Order Categories</label>
                    </div>
                    <div class="rp-name-inp-container" [id]="'orderCategoryInput'">
                        <div (click)="showMultiselectDropdown('orderCategoryInput');$event.stopPropagation();getFilteredCategoryList();setListFlags(rule,null,'orderCategories',null);" class="offer-select-container">
                            <div class="offer-select-disp" [matTooltip]="getOrderCategoriesDisplay(rule.excludedOrderCategories)">
                                {{getOrderCategoriesDisplay(rule.excludedOrderCategories)}}
                            </div>
                            <mat-icon class="down-arrow" [ngClass]="{'down-arrow-rotated':showMultiselectDropDownFor == 'orderCategoryInput'}">keyboard_arrow_down</mat-icon>
                            <div class="offer-dropdown sp-pos width-320" *ngIf="showMultiselectDropDownFor == 'orderCategoryInput'">
                                <input class="op-search" placeholder="Search order categories" type="text" [(ngModel)]="dropdownSearch" (input)="getFilteredOrderCategoriesList();" (click)="$event.stopPropagation()"/>
                                <div class="search-filtered-dropdown">
                                    <div *ngFor="let orderCat of filteredOrderCategoriesList" [ngClass]="{'disabled-list-item':orderCat.disabled}" class="dropdown-opt">
                                        <mat-checkbox class="padding-5-10" color="primary" 
                                            [checked]="orderCat.checked" 
                                            (change)="selectOrderCategories(orderCat,rule);" 
                                            (click)="$event.stopPropagation()"></mat-checkbox>
                                        <div class="dropdown-opt-text" (click)="selectOrderCategories(orderCat,rule);$event.stopPropagation()" [matTooltip]="orderCat.name">
                                            {{orderCat.name}}
                                        </div>
                                    </div>
                                    <div class="no-offer" *ngIf="filteredOrderCategoriesList.length==0" (click)="$event.stopPropagation()">
                                        {{'SEGMENT_PAGE.NO_DATA_AVAILABLE' | i18nTranslate}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
        </div>
        <!-- SPECIAL CONFIG FOR AMOUNT SPENT RULE-->
        <div *ngIf="rule.ruleDefinition.event == '$amount_spent'">
            <!-- exclusion logic is maintained in parent rule and doesn't have inner rule in json-->
            <div class="inner-rule-container-2" *ngIf="rule.hasExclusion">
                <div class="display-flex-3">
                    <div>
                        <div class="title-label-smaller">Exclusion Rule</div>
                        <div class="label-style">Products/Categories selected here do no receive points from the parent rule. 
                            They receive no points or receive points defined in any inclusion rule.
                        </div>
                    </div>
                    <div class="margin-left-auto" *ngIf="rule.inclusionRule?.length==0">
                        <button class="delete-button button-hover-effect" title="Delete Exclusion" (click)="deleteExclusion(rule)">
                            <span class="material-symbols-outlined">
                                delete
                                </span>
                        </button>
                    </div>
                </div>
                <div class="flex basic-rule-box">
                    <div class="rule-text">Earn no points on the purchase of</div>
                    <div class="list-selection-container position-relative" [id]="'listInput'+'-'+'exclusion'">
                        <div class="position-relative">
                            <label class="label-style">Products</label><span class="qb-imp-color">*</span>
                            <div class="rp-name-inp-container rule-value padding-5 width-164">
                                <div (click)="showMultiselectDropdown('product'+'exclusion');$event.stopPropagation();setListFlags(rule,innerRule,'product','exclusion');" class="offer-select-container">
                                    <div class="offer-select-disp" [matTooltip]="getProductListDisplay(rule.excludedProducts)" [matTooltipPosition]="'left'" [matTooltipShowDelay]="500">
                                        {{getProductListDisplay(rule.excludedProducts)}}
                                    </div>
                                    <mat-icon class="down-arrow sp-pos" [ngClass]="{'down-arrow-rotated':showMultiselectDropDownFor == 'product'+'exclusion'}" >keyboard_arrow_down</mat-icon>
                                    <div class="offer-dropdown" *ngIf="showMultiselectDropDownFor == 'product'+'exclusion'">
                                        <input class="op-search" placeholder="Search Products" type="text" 
                                               [(ngModel)]="dropdownSearch" (input)="getFilteredProductList()" 
                                               (click)="$event.stopPropagation()"/>
                                        <cdk-virtual-scroll-viewport itemSize="filteredProductList.length>40?40:filteredProductList.length" class="virtual-scroll-viewport">
                                            <div *cdkVirtualFor="let product of filteredProductList; trackBy: trackByProductName" 
                                                 class="dropdown-opt" 
                                                 [title]="product.disabled ? 'Already selected in inclusion rule' : product.name"
                                                 [ngClass]="{'disabled-list-item': product.disabled}">
                                              <mat-checkbox class="padding-5-10" color="primary"
                                                            [checked]="product.checked"
                                                            (change)="!product.disabled && selectProduct(product, rule, null, false);"
                                                            [disabled]="product.disabled"
                                                            (click)="$event.stopPropagation()">
                                              </mat-checkbox>
                                              <div class="dropdown-opt-text"
                                                   (click)="!product.disabled && selectProduct(product, rule, null, false); $event.stopPropagation()">
                                                {{product.name}}
                                              </div>
                                            </div>
                                            <div class="no-offer" *ngIf="filteredProductList.length==0" (click)="$event.stopPropagation()">
                                                {{'SEGMENT_PAGE.NO_DATA_AVAILABLE' | i18nTranslate}}
                                            </div>
                                        </cdk-virtual-scroll-viewport>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="rule-text-2"> OR </div>
                        <div class="position-relative">
                            <label class="label-style">Categories</label><span class="qb-imp-color">*</span>
                            <div class="rp-name-inp-container rule-value padding-5 width-164">
                                <div (click)="showMultiselectDropdown('category'+'exclusion');$event.stopPropagation();setListFlags(rule,innerRule,'category','exclusion');" class="offer-select-container">
                                    <div class="offer-select-disp" [matTooltip]="getCategoryListDisplay(rule.excludedProductCategories)" [matTooltipPosition]="'left'" [matTooltipShowDelay]="500">
                                        {{getCategoryListDisplay(rule.excludedProductCategories)}}
                                    </div>
                                    <mat-icon class="down-arrow" [ngClass]="{'down-arrow-rotated':showMultiselectDropDownFor == 'category'+'exclusion'}">keyboard_arrow_down</mat-icon>
                                    <div class="offer-dropdown sp-pos" *ngIf="showMultiselectDropDownFor == 'category'+'exclusion'">
                                        <input class="op-search" placeholder="Search categories" type="text" 
                                            [(ngModel)]="dropdownSearch" (input)="getFilteredCategoryList()" 
                                            (click)="$event.stopPropagation()"/>
                                        <cdk-virtual-scroll-viewport [itemSize]="filteredCategoryList.length>40?40:filteredCategoryList.length" class="virtual-scroll-viewport">
                                            <div *cdkVirtualFor="let category of filteredCategoryList;trackBy: trackByCategoryName" 
                                                class="dropdown-opt" 
                                                [title]="category.disabled?'Already selected in inclusion rule':category.name"
                                                [ngClass]="{'disabled-list-item':category.disabled}">
                                                <mat-checkbox class="padding-5-10" color="primary" 
                                                    [checked]="category.checked" 
                                                    (change)="!category.disabled && selectProductCategories(category,rule,null,false);"
                                                    [disabled]="category.disabled"
                                                    (click)="$event.stopPropagation()">
                                                </mat-checkbox>
                                                <div class="dropdown-opt-text" (click)="!category.disabled && selectProductCategories(category,rule,null,false);$event.stopPropagation()">
                                                    {{category.name}}
                                                </div>
                                            </div>
                                            <div class="no-offer" *ngIf="filteredCategoryList.length==0" (click)="$event.stopPropagation()">
                                                {{'SEGMENT_PAGE.NO_DATA_AVAILABLE' | i18nTranslate}}
                                            </div>
                                        </cdk-virtual-scroll-viewport>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div *ngFor="let innerRule of rule.inclusionRule;let i = index;" class="inner-rule-container-2">
                <div class="display-flex-3">
                    <div>
                        <div class="title-label-smaller">Inclusion Rule {{i+1}}</div>
                        <div class="label-style">Products/Categories selected here do no receive points from the parent rule. 
                            They receive points based on the below definition and are automatically added to exclusion from parent.
                        </div>
                    </div>
                    <div class="margin-left-auto">
                        <button class="delete-button button-hover-effect" title="Delete Inner Rule" (click)="deleteInclusionRule(rule,i)">
                            <span class="material-symbols-outlined">
                                delete
                                </span>
                        </button>
                    </div>
                </div>
                <div class="flex basic-rule-box">
                    <div class="rule-text">{{'SEGMENT_PAGE.FOR_EVERY' | i18nTranslate}}</div>
                    <div class="basic-values-container small-width">
                        <label class="label-style">Amount</label><span class="qb-imp-color">*</span>
                        <div class="rp-name-inp-container rule-value" [id]="'eventCountInput'+'-'+i">
                            <input class="rp-name-inp" type="number" placeholder="eg. 2" [(ngModel)]="innerRule.ruleDefinition.eventCount" (keydown)="numberInputHandler($event,7,rule.ruleDefinition.eventDropdownObj.dataType)"
                            (keyup)="resetErrorState()" #inp2 maxlength="7" min="0" max="9999999">
                            <div class="inp-text-left">{{inp2.getAttribute('maxlength') - inp2.value.length}} {{'SEGMENT_PAGE.CHAEACTER_LEFT' | i18nTranslate}}</div>
                        </div>
                    </div>
                    <div class="rule-text">{{rule.ruleDefinition.eventDropdownObj.displayName}} of</div>
                    <div class="list-selection-container position-relative" [id]="'listInput'+'-'+i">
                        <div class="position-relative">
                            <label class="label-style">Products</label><span class="qb-imp-color">*</span>
                            <div class="rp-name-inp-container rule-value padding-5 width-164" >
                                <div (click)="showMultiselectDropdown('product'+'-'+i);$event.stopPropagation();setListFlags(rule,innerRule,'product','inclusion');" class="offer-select-container">
                                    <div class="offer-select-disp" [matTooltip]="getProductListDisplay(innerRule.includedProducts)" [matTooltipPosition]="'left'" [matTooltipShowDelay]="500">
                                        {{getProductListDisplay(innerRule.includedProducts)}}
                                    </div>
                                    <mat-icon class="down-arrow" [ngClass]="{'down-arrow-rotated':showMultiselectDropDownFor == 'product'+'-'+i}">keyboard_arrow_down</mat-icon>
                                    <div class="offer-dropdown sp-pos" *ngIf="showMultiselectDropDownFor == 'product'+'-'+i">
                                        <input class="op-search" placeholder="Search Products" type="text" 
                                            [(ngModel)]="dropdownSearch" (input)="getFilteredProductList()" 
                                            (click)="$event.stopPropagation();"/>
                                        <cdk-virtual-scroll-viewport itemSize="filteredProductList.length>40?40:filteredProductList.length" class="virtual-scroll-viewport">
                                            <div *cdkVirtualFor="let product of filteredProductList;trackBy: trackByProductName"
                                                class="dropdown-opt" 
                                                [title]="product.disabled?'Already selected':product.name" 
                                                [ngClass]="{'disabled-list-item':product.disabled}">
                                                <mat-checkbox class="padding-5-10" color="primary" 
                                                    [checked]="product.checked" 
                                                    (change)="!product.disabled && selectProduct(product,rule,innerRule,true);"
                                                    [disabled]="product.disabled"
                                                    (click)="$event.stopPropagation();">
                                                </mat-checkbox>
                                                <div class="dropdown-opt-text" (click)="!product.disabled && selectProduct(product,rule,innerRule,true);$event.stopPropagation();">
                                                    {{product.name}}
                                                </div>
                                            </div>
                                            <div class="no-offer" *ngIf="filteredProductList.length==0" (click)="$event.stopPropagation();">
                                                {{'SEGMENT_PAGE.NO_DATA_AVAILABLE' | i18nTranslate}}
                                            </div>
                                        </cdk-virtual-scroll-viewport>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="rule-text-2"> OR </div>
                        <div class="position-relative">
                            <label class="label-style">Categories</label><span class="qb-imp-color">*</span>
                            <div class="rp-name-inp-container rule-value padding-5 width-164">
                                <div (click)="showMultiselectDropdown('category'+'-'+i);$event.stopPropagation();setListFlags(rule,innerRule,'category','inclusion');" class="offer-select-container">
                                    <div class="offer-select-disp" [matTooltip]="getCategoryListDisplay(innerRule.includedProductCategories)" [matTooltipPosition]="'left'" [matTooltipShowDelay]="500">
                                        {{getCategoryListDisplay(innerRule.includedProductCategories)}}
                                    </div>
                                    <mat-icon class="down-arrow" [ngClass]="{'down-arrow-rotated':showMultiselectDropDownFor == 'category'+'-'+i}">keyboard_arrow_down</mat-icon>
                                    <div class="offer-dropdown sp-pos" *ngIf="showMultiselectDropDownFor == 'category'+'-'+i">
                                        <input class="op-search" placeholder="Search Categories" type="text" 
                                            [(ngModel)]="dropdownSearch" (input)="getFilteredCategoryList()" 
                                            (click)="$event.stopPropagation()"/>
                                        <cdk-virtual-scroll-viewport [itemSize]="filteredCategoryList.length>40?40:filteredCategoryList.length" class="virtual-scroll-viewport">
                                            <div *cdkVirtualFor="let category of filteredCategoryList;trackBy: trackByCategoryName" 
                                                class="dropdown-opt"  
                                                [title]="category.disabled?'Already selected':category.name"
                                                [ngClass]="{'disabled-list-item':category.disabled}"
                                                (click)="$event.stopPropagation()">
                                                <mat-checkbox class="padding-5-10" color="primary" 
                                                    [checked]="category.checked" 
                                                    (change)="!category.disabled && selectProductCategories(category,rule,innerRule,true);"
                                                    [disabled]="category.disabled"
                                                    (click)="$event.stopPropagation()">
                                                </mat-checkbox>
                                                <div class="dropdown-opt-text" (click)="!category.disabled && selectProductCategories(category,rule,innerRule,true);event.stopPropagation()">
                                                    {{category.name}}
                                                </div>
                                            </div>
                                            <div class="no-offer" *ngIf="filteredCategoryList.length==0" (click)="$event.stopPropagation()">
                                                {{'SEGMENT_PAGE.NO_DATA_AVAILABLE' | i18nTranslate}}
                                            </div>
                                        </cdk-virtual-scroll-viewport>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="earn-container">
                        <div class="rule-text">{{'SEGMENT_PAGE.EARN' | i18nTranslate}}</div>
                        <div class="display-flex-2">
                            <div class="width-135">
                                <label class="label-style">Loyalty Point</label><span *ngIf="innerRule.hasRewardPoints" class="qb-imp-color">*</span>
                                <div class="rp-name-inp-container rule-value" [id]="'rewardPointInput'+'-'+i">
                                    <img class="margin-right-5" src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/loyalty/inputFieldCoin.svg" alt="coinImg" width="20px" height="20px">
                                    <input class="rp-name-inp" type="number" placeholder="eg. 100" [(ngModel)]="innerRule.rewardPoints" (keydown)="numberInputHandler($event,10,'float')" (keyup)="resetErrorState()" #inp13 maxlength="10" [disabled]="!innerRule.hasRewardPoints" min="0" max="9999999999">
                                    <div class="inp-text-left">{{inp13.getAttribute('maxlength') - inp13.value.length}} {{'SEGMENT_PAGE.CHAEACTER_LEFT' | i18nTranslate}}</div>
                                </div>
                            </div>
                            <div class="rule-text padding-top-34" [ngClass]="{'disabled-text':!rule.hasRewardPoints}" >{{'SEGMENT_PAGE.POINTS' | i18nTranslate}}</div>
                        </div>
                    </div>
                </div>
            </div>
    
            <div class="display-flex-3">
                <button class="primary-button button-hover-effect margin-auto" *ngIf="apiProductList.length>0 || apiProductCategoryList.length>0" (click)="addInclusionRule(rule)">
                    <span class="material-symbols-outlined">
                        add_circle
                    </span>{{ rule.hasExclusion ? 'Add Inclusion sub rule' : 'Add Inclusion/Exclusion sub rule' }}
                </button>
            </div>
        </div>
    
        <div class="margin-top-std" *ngIf="this.dataLoaded">
            <app-loyalty-rule-preview [loyaltyPoints]="loyaltyPoints" [rule]="rule"></app-loyalty-rule-preview>
        </div>
        
    </div>
</div>

