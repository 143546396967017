import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { NgbModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { LineChartAnalyticsComponent, LineChartHomescreenComponent, ScatterPlotChartAnalyticsComponent } from 'src/app/shared/components/charts';
import { LabelPipe, SafeHtmlPipe, ScreenConfig,KeysPipe, CustomTimePipe, CustomCurrencyPipe, checkIfRoleCustomerCare } from 'src/app/shared/pipes';
import { AreaChartComponent, PieChartComponent, BarChartComponent, VennDiagramComponent, LineChartComponent, BubbleChartAdComponent, HeatMapComponent, LineChartWithTooltipComponent, MultiLineChartComponent, StackedBarChartComponent, MultiLineChartTtComponent, DonutChartComponent, InsightsBarChartComponent, SegmentLineChartComponent, SegmentCustomerCountBarChartComponent, NegotiatorSingleGameGraphComponent, RevenueRewardsChartComponent, RevenueOfferChartComponent, ProductsSoldChartComponent,LoyaltyBarChartComponent,LoyaltyMultiLineChartComponent } from 'src/app/shared/components/charts';
import { WidgetLabel,WidgetConfig, TruncatePipe, NumberToIterablePipe, FilterPipe, RemoveUnderscorePipe, ReversePipe, CurrencySymbolPipe, IsCheckedPYG, IsChecked, ToDateObjPipe, BoldTextPipe } from 'src/app/shared/pipes';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { ButtonDebounceDirective } from 'src/app/shared/directives';
import { RouterModule } from '@angular/router';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EmailTemplatePreviewComponent } from 'src/app/shared/components/email-template-preview/email-template-preview.component';
import { MatExpansionModule } from "@angular/material/expansion";
import { ColorPickerModule } from "ngx-color-picker";
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { NgxEmojiPickerModule } from 'ngx-emoji-picker';
import { CountryFilterPipe } from 'src/app/shared/pipes/country-filter-pipe';
import { CountryDropdownWrapperComponent } from 'src/app/shared/components/country-dropdown/country-dropdown-wrapper-component/country-dropdown-wrapper-component.component';
import { CamelCaseToTitleCasePipe } from 'src/app/shared/pipes/camelcase-to-title-case-pipe';
import { TranslateDynamicText } from 'src/app/shared/pipes/dynamic-translation.pipe';
import { TranslateText } from 'src/app/shared/pipes/translation.pipe';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { LogicalExpressionsViewerComponent } from 'src/app/shared/components/logical-expression-viewer/logical-expression-viewer.component';
import { QubriuxLoadingComponent } from 'src/app/shared/components/qubriux-loading/qubriux-loading.component';
import { SegmentationCustomerListRedirectComponent } from 'src/app/shared/components/segmentation-customer-list-redirect/segmentation-customer-list-redirect.component'
import { WalletReportComponent } from '../pos/modules/wallet/pages';
import { ReportComponent } from '../pos/modules/loyalty/pages';
import { OfferReportComponent } from '../pos/modules/offer/pages';
import { checkIfReportingView } from 'src/app/shared/pipes/check-if-report-view-pipe';
import { NgxEchartsModule } from 'ngx-echarts';
import { LoyaltyRulePreviewComponent } from 'src/app/shared/components/loyalty-rule-preview/loyalty-rule-preview.component'
import { LoyaltyRulePreviewDialogComponent } from 'src/app/shared/components/loyalty-rule-preview-dialog/loyalty-rule-preview-dialog.component';
import { DynamicBackgroundComponent } from 'src/app/shared/components/dynamic-background/dynamic-background.component';
import { BreadcrumbComponent } from '../pos/components/breadcrumb/breadcrumb.component';
import { LastSyncBarriersComponent } from '../pos/components/last-sync-barriers/last-sync-barriers.component';
import { OfferBudgetComponent } from 'src/app/shared/components/offer-budget/offer-budget.component';

@NgModule({
 imports:      [ 
    MatExpansionModule,
    CommonModule,
    MatNativeDateModule,
    MatSelectModule, 
    MatOptionModule, 
    MatRadioModule,
    MatDialogModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatTabsModule,
    MatDatepickerModule,
    MatMenuModule,
    MatSlideToggleModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgbPopoverModule, 
    NgxSliderModule,
    RouterModule,
    MatTooltipModule,
    ColorPickerModule,
    MatAutocompleteModule,
    NgxEmojiPickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    NgxEchartsModule.forRoot({
        echarts: () => import('echarts')
    })
],
 declarations: [ 
    LabelPipe, 
    SafeHtmlPipe, 
    WidgetLabel, 
    WidgetConfig,
    ScreenConfig, 
    TruncatePipe, 
    NumberToIterablePipe, 
    FilterPipe, 
    RemoveUnderscorePipe, 
    ReversePipe, 
    CurrencySymbolPipe, 
    IsCheckedPYG, 
    IsChecked, 
    ToDateObjPipe, 
    BoldTextPipe, 
    AreaChartComponent,
    PieChartComponent, 
    LineChartHomescreenComponent, 
    BarChartComponent, 
    VennDiagramComponent, 
    LineChartComponent, 
    BubbleChartAdComponent, 
    HeatMapComponent, 
    LineChartWithTooltipComponent, 
    MultiLineChartComponent, 
    StackedBarChartComponent, 
    MultiLineChartTtComponent, 
    DonutChartComponent, 
    InsightsBarChartComponent, 
    SegmentLineChartComponent, 
    SegmentCustomerCountBarChartComponent, 
    NegotiatorSingleGameGraphComponent, 
    RevenueRewardsChartComponent, 
    RevenueOfferChartComponent, 
    ProductsSoldChartComponent,
    EmailTemplatePreviewComponent,
    ButtonDebounceDirective,
    KeysPipe,
    LoyaltyBarChartComponent,
    LoyaltyMultiLineChartComponent,
    CountryFilterPipe,
    CountryDropdownWrapperComponent,
    CamelCaseToTitleCasePipe,
    TranslateText,
    TranslateDynamicText,
    CustomTimePipe,
    CustomCurrencyPipe,
    LineChartAnalyticsComponent,
    ScatterPlotChartAnalyticsComponent,
    LogicalExpressionsViewerComponent,
    QubriuxLoadingComponent,
    SegmentationCustomerListRedirectComponent,
    LoyaltyRulePreviewComponent,
    LoyaltyRulePreviewDialogComponent,
    DynamicBackgroundComponent,
    checkIfRoleCustomerCare,
    OfferReportComponent,
    ReportComponent,
    WalletReportComponent,
    checkIfReportingView,
    BreadcrumbComponent,
    LastSyncBarriersComponent,
    OfferBudgetComponent
],
 exports:      [ 
    MatExpansionModule,
    CommonModule, 
    MatIconModule, 
    MatBadgeModule, 
    LineChartHomescreenComponent,
    AreaChartComponent,
    MatSelectModule,
    MatOptionModule,
    MatRadioModule,
    MatDialogModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatTabsModule,
    MatDatepickerModule,
    MatMenuModule,
    MatSlideToggleModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSliderModule,
    NgbModule,
    NgbPopoverModule,
    LabelPipe,
    SafeHtmlPipe,
    WidgetLabel,
    WidgetConfig,
    ScreenConfig, 
    TruncatePipe, 
    NumberToIterablePipe, 
    FilterPipe, 
    RemoveUnderscorePipe, 
    ReversePipe, 
    CurrencySymbolPipe, 
    IsCheckedPYG, 
    IsChecked, 
    ToDateObjPipe, 
    BoldTextPipe,
    PieChartComponent, 
    BarChartComponent,
    VennDiagramComponent, 
    LineChartComponent, 
    BubbleChartAdComponent, 
    HeatMapComponent, 
    LineChartWithTooltipComponent, 
    MultiLineChartComponent, 
    StackedBarChartComponent, 
    MultiLineChartTtComponent, 
    DonutChartComponent, 
    InsightsBarChartComponent, 
    SegmentLineChartComponent, 
    SegmentCustomerCountBarChartComponent, 
    NegotiatorSingleGameGraphComponent, 
    RevenueRewardsChartComponent, 
    RevenueOfferChartComponent, 
    ProductsSoldChartComponent,
    ButtonDebounceDirective,
    RouterModule,
    MatTooltipModule,
    EmailTemplatePreviewComponent,
    ColorPickerModule,
    MatAutocompleteModule,
    KeysPipe,
    LoyaltyBarChartComponent,
    LoyaltyMultiLineChartComponent,
    CountryFilterPipe,
    CamelCaseToTitleCasePipe,
    MatFormFieldModule,
    MatInputModule,
    TranslateText,
    TranslateDynamicText,
    CustomTimePipe,
    CustomCurrencyPipe,
    LineChartAnalyticsComponent,
    ScatterPlotChartAnalyticsComponent,
    LogicalExpressionsViewerComponent,
    QubriuxLoadingComponent,
    SegmentationCustomerListRedirectComponent,
    LoyaltyRulePreviewComponent,
    LoyaltyRulePreviewDialogComponent,
    DynamicBackgroundComponent,
    checkIfRoleCustomerCare,
    OfferReportComponent,
    ReportComponent,
    WalletReportComponent,
    checkIfReportingView,
    BreadcrumbComponent,
    LastSyncBarriersComponent,
    OfferBudgetComponent
]
})
export class SharedModule { }