<div class="offerCreationHead" id="viewconfigDiv"
  style="display: flex;flex-direction: column;width: 100%;height: 100%;font-size: 14px;overflow: hidden;"
  data-widget-name="OFFER CREATION/EDITING">

  <div style="width: 100%;height: 100%; display: flex; align-items: stretch; flex-direction: column; overflow: hidden;">
    <div *ngIf="!popUpMode">
    <div style="display: flex;justify-content: space-between;align-items: center;width: 100%;height: 80px;">
      <div class="qb-ml-20">
        <app-breadcrumb></app-breadcrumb>
      </div>
      <div style="display: flex;flex-direction:column; width: 50%;justify-content: flex-end;    align-items: center;">
        <div style="display: flex;width: 100%;justify-content: flex-end;    align-items: center;" *ngIf="!previewmode && !adminMode && !managerAdmin">
          <div style="display: flex;width: 100%;justify-content: flex-end;    align-items: center;">
            <!-- <div class="primary-button" style="gap: 5px; margin-right: 5px;
            padding: 12px 10px 12px 6px;" (click)="createOffer()" *ngIf="router.url === '/app/offer/create-offer'&& tokenStorage.getChatGPT() === 'true'">
              <div>
                <img src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/icons/chat-gpt/chat-bot.png" width="25">
              </div>
              <div>Generate Offer using AI</div>
            </div> -->
            <!-- <mat-icon *ngIf="!popUpMode" 
            style="color: #636466;
            border-radius: 50%;
            height: 18px;
            width: 18px;
            display: flex;
            justify-content: center;
            align-items: center;cursor: pointer;margin-right: 30px;" (click)="closeOfferScreen()" [matTooltip]="'COMMON_PAGE.CLOSE' | i18nTranslate"
              data-button-name="Close">close</mat-icon> -->

            <!-- if no button click -->
            <!-- <button class="save-rectangle" > -->
            <div class="secondary-button qb-save-exit-button" appButtonDebounce [throttleTime]="600" style="margin-right: 20px;"
              (throttledClick)="saveandExitPressed()" data-button-name="Save and Exit"
              *ngIf="(saveAndScheduleButtonLoading==false&&saveAndExitButtonLoading==false) && !popUpMode"
              [matTooltip]="'COMMON_PAGE.SAVE_EXIT' | i18nTranslate">
              <span class="normText" style="cursor: pointer;">{{'COMMON_PAGE.SAVE_EXIT' | i18nTranslate}}</span>
            </div>
            <!-- <mat-icon class="save-icon" type="button">save</mat-icon>
              <span class="save-segment-text" type="button">Save and Exit</span> -->
            <!-- </button> -->


            <!-- <button class="schedule-rectangle" appButtonDebounce [throttleTime]="600"
              (throttledClick)="submitOfferForm('DISPATCH');" type="button"
              *ngIf="(saveAndScheduleButtonLoading==false&&saveAndExitButtonLoading==false)"
              data-button-name="Save and Schedule">
              <span class="schedule-text" type="button">Save and Schedule</span>
            </button> -->
            <div class="primary-button" appButtonDebounce [throttleTime]="600" style="margin-right: 15px;"
              (throttledClick)="submitOfferForm('DISPATCH');" data-button-name="Save and Schedule"
              *ngIf="(saveAndScheduleButtonLoading==false&&saveAndExitButtonLoading==false) && !popUpMode"
              [matTooltip]="'COMMON_PAGE.SAVE_AND_SCHEDULE' | i18nTranslate">
              <span class="normText" style="color: #fff; margin-left: 3px;">{{'OFFER_PAGE.SAVE_SCHEDULE' | i18nTranslate}}</span>
            </div>

           

            <!-- if save and exit button clicked -->
            <button class="save-rectangle"
              *ngIf="(saveAndScheduleButtonLoading==false&&saveAndExitButtonLoading==true) && !popUpMode">
              <div style="display: flex;justify-content: center;width: 100%;height: 100%;align-items: center; ">
                <mat-spinner diameter="18" color="accent"></mat-spinner>
              </div>
            </button>

            <!-- <button class="schedule-rectangle-disabled" type="button"
              *ngIf="(saveAndScheduleButtonLoading==false&&saveAndExitButtonLoading==true)">
              <span class="schedule-text" type="button">Save and Schedule</span>
            </button> -->
            <div class="primary-button" *ngIf="(saveAndScheduleButtonLoading==false&&saveAndExitButtonLoading==true) && !popUpMode"
              style="margin-right: 15px;">
              <span class="normText" style="color: #fff;">{{'OFFER_PAGE.SAVE_SCHEDULE' | i18nTranslate}}</span>
            </div>

            <!-- if save and schedule button clicked -->

            <!-- <button class="save-rectangle-disabled"
              *ngIf="saveAndScheduleButtonLoading==true&&saveAndExitButtonLoading==false">
              <mat-icon class="save-icon" type="button">save</mat-icon>
              <span class="save-segment-text" type="button">Save and Exit</span>
            </button> -->
            <span _ngcontent-c42="" class="material-icons-outlined icons-primary"
              style="padding: 6px 0;;color: #636466;margin-right: 30px; cursor: pointer;"
              *ngIf="saveAndScheduleButtonLoading==true&&saveAndExitButtonLoading==false && !popUpMode" [matTooltip]="'COMMON_PAGE.SAVE_EXIT' | i18nTranslate">
              save </span>

            <button class="schedule-rectangle"
              *ngIf="saveAndScheduleButtonLoading==true&&saveAndExitButtonLoading==false && !popUpMode">
              <div style="display: flex;justify-content: center;width: 100%;height: 100%;align-items: center; ">
                <mat-spinner diameter="18" color="accent"></mat-spinner>
              </div>
            </button>
          </div>
        </div>
        <div style="display: flex;width: 100%;justify-content: flex-end;    align-items: center;margin-right: 20px;"
          *ngIf="previewmode">

          <div style="color:#0183FB;font-size: 13px;font-weight: 600;margin: 0 25px;" *ngIf="previewmode">{{'OFFER_PAGE.PREVIEW_ONLY' | i18nTranslate}}</div>

          <mat-icon style="    color: #d8d8d8;
        background: #000;
        border-radius: 50%;
        height: 18px;
        width: 18px;
        display: flex;
        justify-content: center;
        align-items: center;cursor: pointer;" (click)="closeOfferScreen()">cancel</mat-icon>
        </div>
        <div style="display: flex;width: 100%;justify-content: flex-end;    align-items: center;margin-right: 20px;"
        *ngIf="adminMode">

        <div style="color:#0183FB;font-size: 13px;font-weight: 600;margin: 0 25px;" *ngIf="adminMode">{{'OFFER_PAGE.ADMIN_CREATED' | i18nTranslate}}</div>

        <mat-icon style="    color: #d8d8d8;
      background: #000;
      border-radius: 50%;
      height: 18px;
      width: 18px;
      display: flex;
      justify-content: center;
      align-items: center;cursor: pointer;" (click)="closeOfferScreen()">cancel</mat-icon>
      </div>
      <div style="display: flex;width: 100%;justify-content: flex-end;    align-items: center;margin-right: 20px;"
      *ngIf="managerAdmin">

      <div style="color:#0183FB;font-size: 13px;font-weight: 600;margin: 0 25px;" *ngIf="managerAdmin">{{'OFFER_PAGE.CREATED_BY_SOMEONE' | i18nTranslate}}</div>

      <mat-icon style="    color: #d8d8d8;
    background: #000;
    border-radius: 50%;
    height: 18px;
    width: 18px;
    display: flex;
    justify-content: center;
    align-items: center;cursor: pointer;" (click)="closeOfferScreen()">cancel</mat-icon>
    </div>

        <div class="fontExo-13p" style="height:15px">

        </div>
      </div>

    </div>
    </div>


    <div class="offer-card">
      <div class="offer-details-card">
        <!-- <div class="offer-details-card"> -->
        <div [ngClass]="{'pointerevents-none':previewmode || adminMode || managerAdmin}">
          <div class="label-title">{{'OFFER_PAGE.OFFER_DETAILS' | i18nTranslate}}<span class="qb-imp-color">*</span></div>
          <div class="qb-wrapper-input-data">
            <div style="width:50%; margin-top: 16px; margin-bottom: 16px;">
              <div class="label-subtitle">{{'OFFER_PAGE.OFFER_NAME' | i18nTranslate}}<span class="qb-imp-color">*</span></div>
              <div style="width: 100%;">
                <div class="formDiv" style="    width: 95%;    background: #F5F6FA;"
                  [ngClass]="{active:submitClicked&&(offerTemplateName.length<1)}">
                  <input class="formInput" type="text" placeholder="Offer Name (Minimum 3 characters)" maxlength="100"
                    [(ngModel)]="offerTemplateName" *ngIf="!previewmode && !adminMode && !managerAdmin" (keyup)="onKeyOfferNameCheck($event)">
                  <div *ngIf="previewmode || adminMode || managerAdmin" style="width: 100%;" [matTooltip]="'COMMON_PAGE.PREVIEW_ONLY' | i18nTranslate"><input
                      class="formInput" type="text" placeholder="Offer Name" maxlength="100"
                      [(ngModel)]="offerTemplateName" disabled></div>
  
                  <span class="charText" style="color:#636466 ;"> {{offerTemplateName.length}} <span
                      class="charText">/100</span> </span>
                </div>
                <div class="fontExo-13p" style="height:15px;color:red">
                  <small style="color:red" *ngIf="submitClicked&&offerTemplateName.length<1">{{'OFFER_PAGE.OFFER_TEMPLATE_NAME_REQ' | i18nTranslate}}</small>
                  <small style="color:red"
                    *ngIf="submitClicked&&offerTemplateName.length<3&&offerTemplateName.length>0">{{'OFFER_PAGE.MIN_3_CHARACTER' | i18nTranslate}}</small>
                </div>
                <div *ngIf="isofferNameCheckLoading && offerTemplateName.length>=3" style="height:15px;color:rgba(34, 34, 34, 0.6);">
                      <span style="display: flex;align-items: center;">Validating Offer Name<mat-spinner
                        diameter="15"></mat-spinner></span>
                </div>
                <div *ngIf="!isofferNameCheckLoading && offerNameCheckInitialised && offerTemplateName.length>=3">
                  <div *ngIf="!offerNameDuplicate" style="height:15px;color:green">{{'OFFER_PAGE.OFFER_TEMPLATE_NAME_VERIFIED' | i18nTranslate}}</div>
                  <div *ngIf="offerNameDuplicate" style="height:15px;color:red">{{'OFFER_PAGE.USE_ANOTHER_TEMPLATE_NAME' | i18nTranslate}}</div>
                </div>
  
              </div>
            </div>
            <div style="width: 50%;    margin-top: 16px;" *ngIf="(validWidgets | widgetConfig: 75)">
              <div class="label-subtitle" *ngIf="shop !== 'lightspeed'">Discount Code<span class="qb-imp-color">*</span></div>
              <div class="label-subtitle" *ngIf="shop === 'lightspeed'">Discount Name<span class="qb-imp-color">*</span></div>
              <div style="width: 100%;">
  
                <div class="formDiv" id="discountCode" style="    width: 95%;    background: #F5F6FA; justify-content: flex-end;"
                  [ngClass]="{active:submitClicked&&(offerDiscountCode.length>0&&offerDiscountCode.length<3)&&!couponCodeVerified}">
                  <input class="formInput" type="text" placeholder="Discount Code (Minimum 3 characters)" maxlength="100"
                    [(ngModel)]="offerDiscountCode" *ngIf="(!previewmode && !adminMode && !managerAdmin &&!((hurdleRewardType!='FREE_SHIPPING'&&hurdleRewardType!='BUY_X_GET_Y'&&rewardPersonalizationType === 'PERSONALIZED')||(hurdleRewardType==='BUY_X_GET_Y'&&barrierParamType === 'PERSONALIZED'))) && shop === 'shopify'" (keyup)="onKeyDiscountCodeCheck($event)"
                    (keydown.space)="$event.preventDefault()">
                    <input class="formInput" type="text" placeholder="Discount Name (Minimum 3 characters)" maxlength="100"
                    [(ngModel)]="offerDiscountCode" *ngIf="(!previewmode && !adminMode && !managerAdmin &&!((hurdleRewardType!='FREE_SHIPPING'&&hurdleRewardType!='BUY_X_GET_Y'&&rewardPersonalizationType === 'PERSONALIZED')||(hurdleRewardType==='BUY_X_GET_Y'&&barrierParamType === 'PERSONALIZED'))) && shop !== 'shopify'" (keyup)="onKeyDiscountCodeCheck($event)"
                    (keydown.space)="$event.preventDefault()">
                    <input class="formInput" type="text" placeholder="Discount Name (Minimum 3 characters)" maxlength="100"
                    [(ngModel)]="offerDiscountCode" *ngIf="(!previewmode && !adminMode && !managerAdmin && ((hurdleRewardType!='FREE_SHIPPING'&&hurdleRewardType!='BUY_X_GET_Y'&&rewardPersonalizationType === 'PERSONALIZED')||(hurdleRewardType==='BUY_X_GET_Y'&&barrierParamType === 'PERSONALIZED'))) && shop !== 'shopify'" (keyup)="onKeyDiscountCodeCheck($event)"
                    (keydown.space)="$event.preventDefault()">
                  <div style="width: 100%;" *ngIf="((hurdleRewardType != 'FREE_SHIPPING' &&
                  
                  (rewardPersonalizationType === 'PERSONALIZED' ||  barrierParamType === 'PERSONALIZED') && shop === 'shopify') || previewmode || adminMode || managerAdmin)" [matTooltip]='getTooltipText()'><input
                      class="formInput" type="text" placeholder="Discount Code" maxlength="100"
                      [(ngModel)]="offerDiscountCode" disabled></div>
                      
                      <!-- <div style="width: 100%;" *ngIf="((hurdleRewardType != 'FREE_SHIPPING' &&
                  hurdleRewardType != 'BUY_X_GET_Y' &&
                  rewardPersonalizationType === 'PERSONALIZED') || previewmode) && (shop !== 'shopify')" [matTooltip]='previewmode?"Preview only, can’t edit":" AI Assistance does not allow custom coupon codes"'><input
                      class="formInput" type="text" placeholder="Discount Name" maxlength="100"
                      [(ngModel)]="offerDiscountCode" disabled></div> -->
                  <span class="charText" style="color:#636466 ;"> {{offerDiscountCode.length}} <span
                      class="charText">/100</span> </span>
                </div>
                <div *ngIf="offerDiscountCode.length>2">
                  <div *ngIf="iscouponcodeCheckLoading" style="height:15px;color:rgba(34, 34, 34, 0.6);">
                    <span *ngIf="shop === 'shopify'" style="display: flex;align-items: center;"> {{'OFFER_PAGE.CHECKING_DISCOUNT_CODE' | i18nTranslate}}<mat-spinner
                        diameter="15"></mat-spinner></span>
                        <span *ngIf="shop !== 'shopify'" style="display: flex;align-items: center;"> {{'OFFER_PAGE.CHECKING_DISCOUNT_CODE' | i18nTranslate}}<mat-spinner
                          diameter="15"></mat-spinner></span>
                  </div>
                  <div *ngIf="!iscouponcodeCheckLoading &&couponcodeCheckInitialised && shop === 'shopify'">
                    <div *ngIf="couponCodeVerified" style="height:15px;color:green">{{'OFFER_PAGE.DISCOUNT_VERIFIED' | i18nTranslate}}</div>
                    <div *ngIf="!couponCodeVerified" style="height:15px;color:red">{{'OFFER_PAGE.USE_ANOTHER_DISCOUNT_CODE' | i18nTranslate}}</div>
                  </div>
                  <div *ngIf="!iscouponcodeCheckLoading &&couponcodeCheckInitialised && (shop !== 'shopify')">
                    <div *ngIf="couponCodeVerified" style="height:15px;color:green">{{'OFFER_PAGE.DISCOUNT_NAME_VERIFIED' | i18nTranslate}}</div>
                    <div *ngIf="!couponCodeVerified" style="height:15px;color:red">{{'OFFER_PAGE.USE_ANOTHER_DISCOUNT_NAME' | i18nTranslate}}</div>
                  </div>
                </div>
                <div class="fontExo-13p" style="height:15px;color:red">
                  <small style="color:red"
                    *ngIf="submitClicked&&offerDiscountCode.length>0&&offerDiscountCode.length<3 || submitClicked&&!offerDiscountCode">{{'OFFER_PAGE.MINIMUN_3_CHARCTER_REQ' | i18nTranslate}}</small>
                </div>
  
              </div>
            </div>
          </div>
          <div class="qb-check-container">
            <div class="flex-check" *ngIf="(validWidgets | widgetConfig: 50)">
              <mat-checkbox class="margin-left-10" color="primary" [(ngModel)]="isSpecialOffer">
              </mat-checkbox>
              <div>Special Offer</div>
              <span class="qb-i-div"
                      popoverclass="qb-my-custom-class"
                      placement="right"
                      [ngbPopover]="popTierSetup"
                      triggers="mouseenter:mouseleave"
                      >i
              </span>
              <ng-template #popTierSetup>
                <div class="qb-ng-template-text">
                  Special Offers are managed and configured by posist, Qubriux only send the offer name to posist.
                </div>
              </ng-template>
            </div>
  
            <div class="flex-check" *ngIf="(validWidgets | widgetConfig: 92)">
              <mat-checkbox (change)="onCheckboxChange($event, 'toApplyOnMultipleProduct')" class="margin-left-10" color="primary" [(ngModel)]="toApplyOnMultipleProduct">
              </mat-checkbox>
              <div>Applicable on Multiple Products in Cart</div>
              <span class="qb-i-div"
                      popoverclass="qb-my-custom-class"
                      placement="right"
                      [ngbPopover]="popTierSetup2"
                      triggers="mouseenter:mouseleave"
                      >i
              </span>
              <ng-template #popTierSetup2>
                <div class="qb-ng-template-text">
                  The Offer will give discount on all the eligible products in the cart.
                </div>
              </ng-template>
            </div>

            <div class="flex-check" *ngIf="(validWidgets | widgetConfig: 90)"><!-- update the widget -->
              <mat-checkbox class="margin-left-10" (change)="onCheckboxChange($event, 'isGiveAway')" color="primary" [(ngModel)]="isGiveAway">
              </mat-checkbox>
              <div>Give away</div>
              <span class="qb-i-div"
                      popoverclass="qb-my-custom-class"
                      placement="right"
                      [ngbPopover]="giveAway"
                      triggers="mouseenter:mouseleave"
                      >i
              </span>
              <ng-template #giveAway>
                <div class="qb-ng-template-text">
                  Give something freely as a gift.
                </div>
              </ng-template>
            </div>
            
            <div class="flex-check" *ngIf="(validWidgets | widgetConfig: 91)"><!-- update the widget -->
              <mat-checkbox class="margin-left-10" color="primary" (change)="onCheckboxChange($event, 'isHiddenItemOffer')" [(ngModel)]="isHiddenItemOffer">
              </mat-checkbox>
              <div>Hidden/Exclusive item </div>
              <span class="qb-i-div"
                      popoverclass="qb-my-custom-class"
                      placement="right"
                      [ngbPopover]="hiddenItem"
                      triggers="mouseenter:mouseleave"
                      >i
              </span>
              <ng-template #hiddenItem>
                <div class="qb-ng-template-text">
                  Add special products to the cart
                </div>
              </ng-template>
            </div>
          </div>

          <span *ngIf="(validWidgets | widgetConfig: 87)">
            <hr id="1" >
            <div style="width: 100%;">
              <div class="label-title">{{'OFFER_PAGE.DISCOUNT_ON' | i18nTranslate}}<span class="qb-imp-color">*</span></div>
              <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" (change)="changeDiscountOn($event.value)"
                [(ngModel)]="discountOn">
                <mat-radio-button color="primary" class="example-radio-button" [value]="type.value" *ngFor="let type of typeOfDiscountOn" [disabled]='checkIfDisabled("DISCOUNT_ON", type.value)'>
                  {{type.displayName}}
                </mat-radio-button>
              </mat-radio-group>
            </div>
          </span>


          <hr id="1">
          <div style="width: 100%;">
            <div class="label-title">{{'OFFER_PAGE.DISCOUNT_TYPE' | i18nTranslate}}<span class="qb-imp-color">*</span></div>
            <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
              [(ngModel)]="hurdleRewardType" (change)="hurdleRewardTypeRadioChange($event.value)">
              <mat-radio-button color="primary" class="example-radio-button" value="NONE" *ngIf="isHiddenItemOffer">
                {{'OFFER_PAGE.NONE' | i18nTranslate}}
              </mat-radio-button>
              <mat-radio-button color="primary" class="example-radio-button" value="PERCENTAGE_OFF">
                {{'OFFER_PAGE.PERCENTAGE' | i18nTranslate}}
              </mat-radio-button>
              <mat-radio-button color="primary" class="example-radio-button" value="FIXED_AMOUNT_OFF">
                {{'OFFER_PAGE.FIXED_AMT' | i18nTranslate}}
              </mat-radio-button>
              <mat-radio-button *ngIf="pos === 'shopify'" color="primary" class="example-radio-button" value="FREE_SHIPPING" [disabled]="discountOn !== 'CART'">
                {{'OFFER_PAGE.FREE_SHIPPING' | i18nTranslate}}
              </mat-radio-button>
              <mat-radio-button color="primary" class="example-radio-button" value="BUY_X_GET_Y" *ngIf="shop === 'shopify' || allowedAccount.indexOf(email) !== -1 || (validWidgets | widgetConfig: 81)" [disabled]="discountOn !== 'CART' || isGiveAway || isHiddenItemOffer">
                {{'OFFER_PAGE.BUY_X_GET_Y' | i18nTranslate}}
              </mat-radio-button>
              <mat-radio-button color="primary" class="example-radio-button" value="PERCENTAGE_REWARD_COINS_GAIN" *ngIf="pos != 'posist'" [disabled]="discountOn !== 'CART' || isGiveAway || isHiddenItemOffer">
                {{'OFFER_PAGE.PERCENTAGE_REWARD_COIN' | i18nTranslate}}
              </mat-radio-button>
              <mat-radio-button color="primary" class="example-radio-button" value="FIXED_REWARD_COINS_GAIN" *ngIf="pos != 'posist'" [disabled]="discountOn !== 'CART' || isGiveAway || isHiddenItemOffer">
                {{'OFFER_PAGE.FIXED_REWARD_COIN' | i18nTranslate}}
              </mat-radio-button>
              <mat-radio-button color="primary" class="example-radio-button" value="BURN_LOYALTY_POINTS" [disabled]="discountOn !== 'CART' || isGiveAway || isHiddenItemOffer" *ngIf="(validWidgets | widgetConfig: 93)">
                {{'OFFER_PAGE.BURN_LOYALTY_POINTS' | i18nTranslate}}
              </mat-radio-button>
              <mat-radio-button color="primary" class="example-radio-button" value="FIXED_CASHBACK" [disabled]="discountOn !== 'CART' || isGiveAway || isHiddenItemOffer" *ngIf="(validWidgets | widgetConfig: 94)">
                {{'OFFER_PAGE.FIXED_CASHBACK' | i18nTranslate}}
              </mat-radio-button>
              <mat-radio-button color="primary" class="example-radio-button" value="PERCENTAGE_CASHBACK" [disabled]="discountOn !== 'CART' || isGiveAway || isHiddenItemOffer" *ngIf="(validWidgets | widgetConfig: 94)">
                {{'OFFER_PAGE.PERCENTAGE_CASHBACK' | i18nTranslate}}
              </mat-radio-button>
            </mat-radio-group>
          </div>
          <hr id="2" *ngIf="valueTypes.includes(hurdleRewardType)">



          <div style="width: 100%;" *ngIf="valueTypes.includes(hurdleRewardType)">
            <div class="label-title">{{'OFFER_PAGE.VALUE' | i18nTranslate}}<span class="qb-imp-color">*</span></div>
            <div class="label-subtitle">{{'OFFER_PAGE.DISCOUNT_VALUE' | i18nTranslate}} </div>

            <div *ngIf="percentageOffTypes.includes(hurdleRewardType)">
              <div style="width:10%;">
                <!-- <span
                class="fontExo2">Percentage</span> -->
                <div style="width: 100%;display: flex;align-items: center;">
                  <div class="formDiv2" id="percentageOff"
                    [ngClass]="{active:submitClicked&&(percentageOff==null)||(percentageOff!=null&&(percentageOff<1||percentageOff==e||percentageOff>100))}"
                    style="width: 100%;display: flex;">
                    <!-- [ngClass]="{'active' : (offerTemplate.controls.offerBarrier.controls[i].controls['percentageOff'].errors ? (offerTemplate.controls.offerBarrier.controls[i].controls['percentageOff'].errors['min'] || offerTemplate.controls.offerBarrier.controls[i].controls['percentageOff'].errors['max']) : false)
  || (offerTemplate.controls.offerBarrier.controls[i].controls['percentageOff'].errors ? (offerTemplate.controls.offerBarrier.controls[i].controls['percentageOff'].errors.required && offerTemplate.controls.offerBarrier.controls[i].controls['percentageOff'].touched || submitClicked) : false)}"> -->
                    <input class=" formInput fontExo2" type="text" min=0 max=100 [(ngModel)]="percentageOff" (keydown)="keypressNumber($event)">
                  </div>
                  <span matSuffix [id]="'pvalue-'+i+'-'+j" style="font-size: 12px;margin: 0 8px;color: #3a3a3a;">
                    %</span>

                </div>
                <div class="fontExo2 qb-error-msg" *ngIf="percentageOff!=null&&(percentageOff<1||percentageOff==e||percentageOff>100)">
                  <span>{{'OFFER_PAGE.ENTER_VALID_VALUE' | i18nTranslate}}</span>
                </div>
              </div>


            </div>
            <div *ngIf="amountTypes.includes(hurdleRewardType)">

              <div style="width:10%;">
                <!-- <span
                  class="fontExo2">Amount</span> -->
                <div style="width: 100%;display: flex;align-items: center;">
                  <div class="formDiv2" id="amountOff"
                    [ngClass]="{active:submitClicked&&(amountOff==null)||(amountOff!=null&&(amountOff<1||amountOff==e))}"
                    style="width: 100%;">
                    <!-- [ngClass]="{'active' : (offerTemplate.controls.offerBarrier.controls[i].controls['amountOff'].errors ? offerTemplate.controls.offerBarrier.controls[i].controls['amountOff'].errors['min'] : false)
  || (offerTemplate.controls.offerBarrier.controls[i].controls['amountOff'].errors ? (offerTemplate.controls.offerBarrier.controls[i].controls['amountOff'].errors.required && offerTemplate.controls.offerBarrier.controls[i].controls['amountOff'].touched || submitClicked) : false)}"> -->
                    <span matSuffix [id]="'pvalue-'+i+'-'+j" style="font-size: 12px;margin: 0 8px;color: #3a3a3a;" *ngIf="hurdleRewardType == 'FIXED_AMOUNT_OFF'">
                      {{currencySymbol}}</span>
                    <input class=" formInput fontExo2" type="text" min=0 [(ngModel)]="amountOff" (keydown)="keypressNumber($event)">
                  </div>

                </div>
                <div class="fontExo2 qb-error-msg" *ngIf="amountOff!=null&&(amountOff<1||amountOff==e)">
                  <span>{{'OFFER_PAGE.ENTER_VALID_VALUE' | i18nTranslate}}</span>
                </div>
              </div>
              <small style="color:red" *ngIf="submitClicked&&(amountOff==null)">{{'OFFER_PAGE.FIELD_REQ' | i18nTranslate}}</small>
            </div>
          </div>



          <div style="width: 50%;" *ngIf="hurdleRewardType!='FREE_SHIPPING'&&hurdleRewardType!='BUY_X_GET_Y'">
            <div style="display: flex;width: 100%; gap: 10px;">
              <div class="label-title">{{'OFFER_PAGE.APPLIES_TO' | i18nTranslate}}<span class="qb-imp-color">*</span></div>
              <mat-slide-toggle (change)="toggleAIassistanceReward($event);" color="accent" 
                [disabled]="discountAndShippingType=='ORDER' || discountAndShippingType=='COLLECTION'"
                [checked]="isPersonalised">{{'OFFER_PAGE.AI_ASSISTANCE' | i18nTranslate}}
              </mat-slide-toggle>
            </div>
            <div style="width:100%;"
            *ngIf="rewardPersonalizationType === 'PERSONALIZED'&&hurdleRewardType!='FREE_SHIPPING'&&hurdleRewardType!='BUY_X_GET_Y'&&barrierType === 'PRODUCT_BASED'&&discountAndShippingType!='ORDER'">
            <div *ngIf="discountAndShippingType=='PRODUCT'">{{'OFFER_PAGE.AI_SUGGEST_RECOMMENDED_PRODCT' | i18nTranslate}}</div>
            <div *ngIf="discountAndShippingType=='COLLECTION'">{{'OFFER_PAGE.AI_SUGGEST_RECOMMENDED_CATEGORY' | i18nTranslate}}</div>
          </div>

            <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
              [(ngModel)]="discountAndShippingType">
              <mat-radio-button color="primary" class="example-radio-button" value="ORDER" (click)="toggleOffAiAssistance()" [disabled]="checkIfDisabled('ALLPRODUCTS')">
                {{'OFFER_PAGE.ALL_PRODUCTS' | i18nTranslate}}
              </mat-radio-button>
              <mat-radio-button color="primary" class="example-radio-button" value="COLLECTION" (click)="toggleOffAiAssistance()" [disabled]="checkIfDisabled('CATEGORY')">
                {{'OFFER_PAGE.SPECIFIC_CATEGORIES' | i18nTranslate}}
              </mat-radio-button>
              <mat-radio-button color="primary" class="example-radio-button" value="PRODUCT" [disabled]="checkIfDisabled('PRODUCT')">
                {{'OFFER_PAGE.SPECIFIC_PRODUCTS' | i18nTranslate}}
              </mat-radio-button>
            </mat-radio-group>
           


            <!-- <div style="width:100%;"
            *ngIf="rewardPersonalizationType === 'PERSONALIZED'&&hurdleRewardType!='FREE_SHIPPING'&&hurdleRewardType!='BUY_X_GET_Y'&&barrierType === 'PRODUCT_BASED'&&discountAndShippingType!='ORDER'">
            <div class="formDiv" [ngClass]="{active:submitClicked&&(rewardPropensity==null)}">
              <mat-select #rewardPropensityselect class="formInput fontExo2" [(ngModel)]="rewardPropensity"
                disableOptionCentering panelClass="myPanelClass">
                <mat-option value="LOW">Low
                </mat-option>
                <mat-option value="MEDIUM">
                  Medium
                </mat-option>
                <mat-option value="HIGH">High
                </mat-option>
              </mat-select>
              <div _ngcontent-c14="" class="keyboard-down-icon-container cursor-pointer"
                id="keyboard-down-icon-container" style="transform: rotate(0deg);"
                (click)="selectopenclose('rewardPropensityselect')">
                <mat-icon _ngcontent-c14=""
                  class="keyboard-down-icon mat-icon notranslate material-icons mat-icon-no-color" role="img"
                  aria-hidden="true">keyboard_arrow_down</mat-icon>
              </div>
            </div>
          </div> -->
            <small style="color:red" *ngIf="submitClicked&&(rewardPropensity==null)">{{'OFFER_PAGE.FIELD_REQ' |  i18nTranslate}}</small>
            <div style="width: 100%;display: flex; gap: 20px;
          margin-bottom: 12px;"
              *ngIf="discountAndShippingType!='ORDER'&&rewardPersonalizationType==='NON_PERSONALIZED'">
              <div class="formDiv2" style="width: 50%;">
                <input class=" formInput fontExo2" *ngIf="discountAndShippingType=='PRODUCT'"
                  [(ngModel)]="atdiscountAndPercentageOffProducts"
                  (keyup)="openItemDialogwithSearchData(atdiscountAndPercentageOffProducts,'REWARD',discountAndShippingType,hurdleRewardType)"
                  placeholder="Search by name (Minimum 3 characters)">
                <input class="formInput fontExo2" *ngIf="discountAndShippingType=='COLLECTION'"
                  [(ngModel)]="atdiscountAndPercentageOffCollections"
                  (keyup)="openItemDialogwithSearchData(atdiscountAndPercentageOffCollections,'REWARD',discountAndShippingType,hurdleRewardType)"
                  placeholder="Search by name (Minimum 3 characters)">
                <span class="material-icons-outlined icons-primary cursor-pointer"
                  *ngIf="(discountAndShippingType=='PRODUCT'&&atdiscountAndPercentageOffProducts.length==0)||(discountAndShippingType=='COLLECTION'&&atdiscountAndPercentageOffCollections.length==0)">
                  search
                </span>
                <span class="material-icons-outlined icons-primary cursor-pointer" style="font-size: 19px;"
                  *ngIf="discountAndShippingType=='PRODUCT'&&atdiscountAndPercentageOffProducts.length>0"
                  (click)="atdiscountAndPercentageOffProducts=''">
                  close
                </span>
                <span class="material-icons-outlined icons-primary cursor-pointer" style="font-size: 19px;"
                  *ngIf="discountAndShippingType=='COLLECTION'&&atdiscountAndPercentageOffCollections.length>0"
                  (click)="atdiscountAndPercentageOffCollections=''">
                  close
                </span>
              </div>
              <button class="browse-btn"
                (click)="browseReward(hurdleRewardType,discountAndShippingType)">{{'OFFER_PAGE.BROWSE' | i18nTranslate}}</button>
            </div>









































            <div *ngIf="discountAndShippingType=='PRODUCT'&&rewardPersonalizationType==='NON_PERSONALIZED'" class="ls-container">
              <div *ngFor="let product of selectedRewardProductList;let i=index"
                style="display: flex;width: 97%;justify-content: space-between;    margin-bottom: 5px;">
                <div><span>
                    <mat-icon style="font-size: 12px;">fiber_manual_record</mat-icon>
                  </span> <span *ngIf="product.variantName!=product.parentProductName">{{product.parentProductName}}
                    ({{product.variantName}})</span>
                  <span *ngIf="product.variantName===product.parentProductName">{{product.variantName}}</span>
                </div>
                <div><span class="material-icons remove-btn" style="cursor: pointer;"
                    (click)="removeEntry(i,'reward',1)">
                    cancel
                  </span></div>

              </div>
            </div>
            <div *ngIf="discountAndShippingType=='COLLECTION'&&rewardPersonalizationType==='NON_PERSONALIZED'"
              id="selectedRewardCollectionList" class="ls-container">
              <div *ngFor="let product of selectedRewardCollectionList;let i=index"
                style="display: flex;width: 97%;justify-content: space-between;    margin-bottom: 5px;">
                <div><span>
                    <mat-icon style="font-size:12px">fiber_manual_record</mat-icon>
                  </span> <span>{{product.collectionName}}</span></div>
                <div><span class="material-icons remove-btn" style="cursor: pointer;"
                    (click)="removeEntry(i,'reward',0)">
                    cancel
                  </span></div>

              </div>
            </div>
            <!-- AI Assistance -->
            <div>
              <div *ngIf="rewardPersonalizationType === 'PERSONALIZED'&&discountAndShippingType!='ORDER'"
                style="width:100%;margin-bottom: 10px">
                <div style="width:90%;">
                  <span class="label-subtitle">{{'OFFER_PAGE.DIFFICULTY_LEVEL' | i18nTranslate}}</span>
                  <div class="formDiv" [ngClass]="{active:submitClicked&&(rewardPropensity==null)}">
                    <mat-select #rewardPropensityselect class="formInput fontExo2" [(ngModel)]="rewardPropensity">
                      <mat-option value='LOW'>{{'OFFER_PAGE.LOW' | i18nTranslate}}
                      </mat-option>
                      <mat-option value='MEDIUM'>
                        {{'OFFER_PAGE.MEDIUM' | i18nTranslate}}</mat-option>
                      <mat-option value='HIGH'>
                        {{"OFFER_PAGE.HIGH" | i18nTranslate}}
                      </mat-option>
                    </mat-select>
                    <div _ngcontent-c14="" class="keyboard-down-icon-container cursor-pointer"
                      id="keyboard-down-icon-container" style="transform: rotate(0deg);"
                      (click)="selectopenclose('rewardPropensityselect')">
                      <mat-icon _ngcontent-c14=""
                        class="keyboard-down-icon mat-icon notranslate material-icons mat-icon-no-color" role="img"
                        aria-hidden="true">keyboard_arrow_down</mat-icon>
                    </div>
                  </div>
                </div>
                <small style="color:red" *ngIf="submitClicked&&(rewardPropensity==null)">{{'OFFER_PAGE.FIELD_REQ' | i18nTranslate}}</small>
              </div>

            </div>
            <div>
              <button *ngIf="discountAndShippingType != 'PRODUCT' && qid === 'null'" class="exclusion-browse-btn" (click)="browseProducts(discountAndShippingType, 'REWARD', rewardPersonalizationType)" [disabled]="(selectedRewardCollectionList.length == 0 && discountAndShippingType=='COLLECTION' && rewardPersonalizationType==='NON_PERSONALIZED')">{{'OFFER_PAGE.EXCLUDE_PRODUCTS' | i18nTranslate}}</button>
            </div>
            <div *ngIf="discountAndShippingType != 'PRODUCT' && qid === 'null'" class="ls-container">
              <div *ngFor="let product of selectedRewardExcludedProductList;let i=index"
                style="display: flex;width: 97%;justify-content: space-between;    margin-bottom: 5px;">
                <div><span>
                    <mat-icon style="font-size: 12px;">fiber_manual_record</mat-icon>
                  </span>
                  <span>{{product.productName}}</span>
                </div>
                <div><span class="material-icons remove-btn" style="cursor: pointer;"
                    (click)="removeEntry(i,'reward',1, 1)">
                    cancel
                  </span></div>

              </div>
            </div>
            <div>
              <button *ngIf="discountAndShippingType == 'ORDER' && qid === 'null'" class="exclusion-browse-btn" (click)="browseCategories('REWARD')">{{'OFFER_PAGE.EXCLUDE_CATEGORIES' | i18nTranslate}}</button>
            </div>
            <div *ngIf="discountAndShippingType == 'ORDER' && qid === 'null'" class="ls-container">
              <div *ngFor="let product of selectedRewardExcludedCategoryList;let i=index"
                style="display: flex;width: 100%;justify-content: space-between;    margin-bottom: 5px;">
                <div><span>
                    <mat-icon style="font-size: 12px;">fiber_manual_record</mat-icon>
                  </span>
                  <span>{{product.productCategory}}</span>
                </div>
                <div><span class="material-icons remove-btn" style="cursor: pointer;"
                    (click)="removeEntry(i,'reward',0, 1)">
                    cancel
                  </span></div>

              </div>
            </div>
          </div>

          <hr id="3">




          <div style="width: 50%;" *ngIf="hurdleRewardType!='BUY_X_GET_Y'">
            <div class="label-title">{{'OFFER_PAGE.MIN_REQ' | i18nTranslate}}<span class="qb-imp-color">*</span></div>

            <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
              [(ngModel)]="rewardMinReqs" id="minRequirement">
              <mat-radio-button color="primary" class="example-radio-button" value="NONE">
                {{'OFFER_PAGE.NONE' | i18nTranslate}}
              </mat-radio-button>
              <mat-radio-button color="primary" class="example-radio-button" value="MIN_ORDER_AMOUNT" [disabled]="checkIfDisabled('MIN_ORDER_AMOUNT')">
                {{'OFFER_PAGE.MIN_PURCHASE_AMT' | i18nTranslate}}
              </mat-radio-button>

              <mat-radio-button color="primary" class="example-radio-button" value="MIN_ORDER_QUANTITY" [disabled]="checkIfDisabled('MIN_ORDER_QUANTITY')">
                {{'OFFER_PAGE.MIN_QUANTITY_ITEMS' | i18nTranslate}}
              </mat-radio-button>      
            </mat-radio-group>
            <div style="width: 100%;" *ngIf="rewardMinReqs=='MIN_ORDER_AMOUNT'">
              <!-- <div class="label-title">Customer Buys</div> -->
              <span class="label-subtitle">{{'OFFER_PAGE.AMOUNT' | i18nTranslate}}</span>
              <div style="width:100%;">

                <div style="width:20%;">
                  <!-- <span
                    class="fontExo2">Amount</span> -->
                  <div style="width: 100%;display: flex;align-items: center;">
                    <div class="formDiv2"
                      [ngClass]="{active:submitClicked&&(rewardMinReqsAmount==null)||(rewardMinReqsAmount!=null&&(rewardMinReqsAmount<1||rewardMinReqsAmount==e))}"
                      style="width: 100%;">
                      <!-- [ngClass]="{'active' : (offerTemplate.controls.offerBarrier.controls[i].controls['amountOff'].errors ? offerTemplate.controls.offerBarrier.controls[i].controls['amountOff'].errors['min'] : false)
    || (offerTemplate.controls.offerBarrier.controls[i].controls['amountOff'].errors ? (offerTemplate.controls.offerBarrier.controls[i].controls['amountOff'].errors.required && offerTemplate.controls.offerBarrier.controls[i].controls['amountOff'].touched || submitClicked) : false)}"> -->
                      <span matSuffix [id]="'pvalue-'+i+'-'+j" style="font-size: 12px;margin: 0 8px;color: #3a3a3a;">
                        {{currencySymbol}}</span>
                      <input class=" formInput fontExo2" type="text" min=0 [(ngModel)]="rewardMinReqsAmount" id="scrollPreventInput" (keydown)="keypressNumber($event)">
                    </div>

                  </div>
                  <div class="fontExo2 qb-error-msg" *ngIf="rewardMinReqsAmount!=null&&(rewardMinReqsAmount<1||rewardMinReqsAmount==e)">
                    <span>{{'OFFER_PAGE.ENTER_VALID_VALUE' | i18nTranslate}}</span>
                  </div>
                </div>
                <small style="color:red" *ngIf="submitClicked&&(rewardMinReqsAmount==null)">{{'OFFER_PAGE.FIELD_REQ' | i18nTranslate}}</small>
              </div>

            </div>
            
            <div style="width: 100%;" *ngIf="rewardMinReqs=='MIN_ORDER_QUANTITY'">
              <!-- <div class="label-title">Customer Buys</div> -->
              <span class="label-subtitle">{{'OFFER_PAGE.QUANTITY' | i18nTranslate}}</span>
              <!-- <span class="label-subtitle" *ngIf="barrierType=='SPENT_BASED'">Amount</span> -->

              <div style="width:100%;">
                <div style="width:90%;">
                  <!-- <span class="fontExo2">Days to
redeem</span> -->
                  <div style="width: 20%;display: flex;align-items: center;">
                    <div class="formDiv2"
                      [ngClass]="{active:submitClicked&&(rewardMinReqsQuantity==null)||(rewardMinReqsQuantity!=null&&(rewardMinReqsQuantity<1||rewardMinReqsQuantity==e))}"
                      style="width: 100%;">
                      <input class="formInput fontExo2" min=1 max=6 type="text" [(ngModel)]="rewardMinReqsQuantity" id="scrollPreventInput" (keydown)="keypressNumber($event)">


                    </div>

                  </div>
                  <div class="fontExo2 qb-error-msg">

                    <span
                      *ngIf="rewardMinReqsQuantity!=null&&(rewardMinReqsQuantity<1||rewardMinReqsQuantity==e)">{{"OFFER_PAGE.ENTER_VALID_VALUE" | i18nTranslate}}</span>
                    <span *ngIf="(rewardMinReqsQuantity>100)">{{'OFFER_PAGE.MAX_VALUE_6' | i18nTranslate}}</span>

                  </div>
                </div>
                <small style="color:red" *ngIf="submitClicked&&(rewardMinReqsQuantity==null)">{{'OFFER_PAGE.FIELD_REQ' | i18nTranslate}}</small>
              </div>




            </div>
          </div>























          <!-- <hr *ngIf="hurdleRewardType=='BUY_X_GET_Y'" id="4"> -->
          <div style="width: 100%;" *ngIf="hurdleRewardType=='BUY_X_GET_Y'">

            <div style="width: 100%;">
              <div class="label-title">{{'OFFER_PAGE.CUSTOMER_BUYS' | i18nTranslate}}<span class="qb-imp-color">*</span></div>
              <!-- <div style="width: 100%;">
              <div class="label-title">Customer Buys</div>
              <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                [(ngModel)]="barrierType">
                <mat-radio-button color="primary" class="example-radio-button" value="PRODUCT_BASED">
                  Minimum quantity of items
                </mat-radio-button>
                <mat-radio-button color="primary" class="example-radio-button" value="SPENT_BASED">
                  Minimum purchase amount
                </mat-radio-button>

              </mat-radio-group>
            </div> -->





              <div style="width: 50%;">
                <!-- <div class="label-title">Customer Buys</div> -->
                <span class="label-subtitle" *ngIf="barrierType=='PRODUCT_BASED'">{{'OFFER_PAGE.QUANTITY' | i18nTranslate}}</span>
                <span class="label-subtitle" *ngIf="barrierType=='SPENT_BASED'">{{"OFFER_PAGE.AMOUNT" | i18nTranslate}}</span>

                <div style="width:100%;" *ngIf="barrierType=='PRODUCT_BASED'">
                  <div style="width:25%;">
                    <!-- <span class="fontExo2">Days to
      redeem</span> -->
                    <div style="width: 100%;display: flex;align-items: center;">
                      <div class="formDiv2"
                        [ngClass]="{active:submitClicked&&(hurdleProductCount==null)||(hurdleProductCount!=null&&(hurdleProductCount<1||hurdleProductCount==e))}"
                        style="width: 100%;">
                        <input class="formInput fontExo2" min=1 max=6 type="text" [(ngModel)]="hurdleProductCount" id="scrollPreventInput" (keydown)="keypressNumber($event)">


                      </div>

                    </div>
                    <div class="fontExo2 qb-error-msg">

                      <span *ngIf="hurdleProductCount!=null&&(hurdleProductCount<1||hurdleProductCount==e)">{{'OFFER_PAGE.ENTER_VALID_VALUE' | i18nTranslate}}</span>
                      <span *ngIf="(hurdleProductCount>100)">{{'OFFER_PAGE.MAX_VALUE_6' | i18nTranslate}}</span>

                    </div>
                  </div>
                  <small style="color:red" *ngIf="submitClicked&&(hurdleProductCount==null)">{{'OFFER_PAGE.FIELD_REQ' | i18nTranslate}}</small>
                </div>

                <div style="width:100%;" *ngIf="barrierType=='SPENT_BASED'">

                  <div style="width:90%;">
                    <!-- <span
                      class="fontExo2">Amount</span> -->
                    <div style="width: 20%;display: flex;align-items: center;">
                      <div class="formDiv2"
                        [ngClass]="{active:submitClicked&&(hurdleSpentAmount==null)||(hurdleSpentAmount!=null&&(hurdleSpentAmount<1||hurdleSpentAmount==e))}"
                        style="width: 100%;">
                        <!-- [ngClass]="{'active' : (offerTemplate.controls.offerBarrier.controls[i].controls['amountOff'].errors ? offerTemplate.controls.offerBarrier.controls[i].controls['amountOff'].errors['min'] : false)
      || (offerTemplate.controls.offerBarrier.controls[i].controls['amountOff'].errors ? (offerTemplate.controls.offerBarrier.controls[i].controls['amountOff'].errors.required && offerTemplate.controls.offerBarrier.controls[i].controls['amountOff'].touched || submitClicked) : false)}"> -->
                        <span matSuffix [id]="'pvalue-'+i+'-'+j" style="font-size: 12px;margin: 0 8px;color: #3a3a3a;">
                          {{currencySymbol}}</span>
                        <input class=" formInput fontExo2" type="text" min=0 [(ngModel)]="hurdleSpentAmount" id="scrollPreventInput" (keydown)="keypressNumber($event)">
                      </div>

                    </div>
                    <div class="fontExo2 qb-error-msg">
                      <span *ngIf="hurdleSpentAmount!=null&&(hurdleSpentAmount<1||hurdleSpentAmount==e)"> {{'OFFER_PAGE.ENTER_VALID_VALUE' | i18nTranslate}} </span>
                    </div>
                  </div>
                  <small style="color:red" *ngIf="submitClicked&&(hurdleSpentAmount==null)">{{'OFFER_PAGE.FIELD_REQ' | i18nTranslate}}</small>
                </div>


              </div>


              <div style="width: 50%;">
                <div style="display: flex;width: 100%;gap: 20px;">
                  
                  <div class="label-title">Applies To<span class="qb-imp-color">*</span></div>
                  <mat-slide-toggle (change)="toggleAIassistanceBarrier($event);" color="accent"
                    [checked]="barrierParamType !== 'PERSONALIZED' ? false : true">AI Assistance
                  </mat-slide-toggle>
                </div>
                <div style="width:100%;"
                *ngIf="barrierParamType === 'PERSONALIZED'&&hurdleRewardType==='BUY_X_GET_Y'">
                <div *ngIf="hurdleProductInnerType=='PRODUCT'">{{'OFFER_PAGE.AI_SUGGEST_RECOMMENDED_PRODCT' | i18nTranslate}}</div>
                <div *ngIf="hurdleProductInnerType=='CATEGORY'">{{'OFFER_PAGE.AI_SUGGEST_RECOMMENDED_CATEGORY' | i18nTranslate}}</div>
              </div>
                <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                  [(ngModel)]="hurdleProductInnerType">
                  <mat-radio-button color="primary" class="example-radio-button" value="CATEGORY">
                    {{'OFFER_PAGE.SPECIFIC_CATEGORIES' | i18nTranslate}}
                  </mat-radio-button>
                  <mat-radio-button color="primary" class="example-radio-button" value="PRODUCT">
                    {{'OFFER_PAGE.SPECIFIC_PRODUCTS' | i18nTranslate}}
                  </mat-radio-button>
                
                </mat-radio-group>

                <div style="width:100%;" *ngIf="barrierParamType === 'PERSONALIZED'&&hurdleRewardType==='BUY_X_GET_Y'">
                 
                  <div style="width: 90%;">
                    <span class="label-subtitle">{{'OFFER_PAGE.DIFFICULTY_LEVEL' | i18nTranslate}}</span>
                    <div class="formDiv" [ngClass]="{active:submitClicked&&(rewardPropensity==null)}">
                      <mat-select #productPropensityselect class="formInput fontExo2" [(ngModel)]="productPropensity"
                        disableOptionCentering panelClass="myPanelClass">
                        <mat-option value="LOW">{{'OFFER_PAGE.LOW' | i18nTranslate}}
                        </mat-option>
                        <mat-option value="MEDIUM">
                          {{'OFFER_PAGE.MEDIUM' | i18nTranslate}}
                        </mat-option>
                        <mat-option value="HIGH">{{'OFFER_PAGE.HIGH' | i18nTranslate}}
                        </mat-option>
                      </mat-select>
                      <div _ngcontent-c14="" class="keyboard-down-icon-container cursor-pointer"
                        id="keyboard-down-icon-container" style="transform: rotate(0deg);"
                        (click)="selectopenclose('productPropensityselect')">
                        <mat-icon _ngcontent-c14=""
                          class="keyboard-down-icon mat-icon notranslate material-icons mat-icon-no-color" role="img"
                          aria-hidden="true">keyboard_arrow_down</mat-icon>
                      </div>
                    </div>
                  </div>
                </div>
                <small style="color:red" *ngIf="submitClicked&&(rewardPropensity==null)">{{'OFFER_PAGE.FIELD_REQ' | i18nTranslate}}</small>

                <div style="width: 100%;display: flex;margin-bottom: 10px;justify-content: space-between;"
                  *ngIf="barrierParamType==='NON_PERSONALIZED'">
                  <div class="formDiv2" style="width: 85%;">
                    <input class=" formInput fontExo2" *ngIf="hurdleProductInnerType=='PRODUCT'"
                      [(ngModel)]="atBuyxGetYProducts"
                      (keyup)="openItemDialogwithSearchData(atBuyxGetYProducts,'BARRIER',hurdleProductInnerType)"
                      placeholder="Search by name (Minimum 3 characters)">
                    <input class=" formInput fontExo2" *ngIf="hurdleProductInnerType=='CATEGORY' "
                      [(ngModel)]="atBuyxGetYCollections"
                      (keyup)="openItemDialogwithSearchData(atBuyxGetYCollections,'BARRIER',hurdleProductInnerType)"
                      placeholder="Search by name (Minimum 3 characters)">
                    <span class="material-icons-outlined icons-primary cursor-pointer"
                      *ngIf="(hurdleProductInnerType=='PRODUCT'&&atBuyxGetYProducts.length==0)||(hurdleProductInnerType=='CATEGORY'&&atBuyxGetYCollections.length==0)">
                      search
                    </span>
                    <span class="material-icons-outlined icons-primary cursor-pointer" style="font-size: 19px;"
                      *ngIf="hurdleProductInnerType=='PRODUCT'&&atBuyxGetYProducts.length>0"
                      (click)="atBuyxGetYProducts=''">
                      close
                    </span>
                    <span class="material-icons-outlined icons-primary cursor-pointer" style="font-size: 19px;"
                      *ngIf="hurdleProductInnerType=='CATEGORY'&&atBuyxGetYCollections.length>0"
                      (click)="atBuyxGetYCollections=''">
                      close
                    </span>
                  </div>

                  <button class="browse-btn" (click)="browseBarrier(hurdleProductInnerType)">{{'OFFER_PAGE.BROWSE' | i18nTranslate}}</button>
                </div>




                <div *ngIf="hurdleProductInnerType=='PRODUCT'&&barrierParamType==='NON_PERSONALIZED'" class="ls-container">
                  <div *ngFor="let product of selectedBarrierProductList;let i=index"
                    style="display: flex;width: 97%;justify-content: space-between;    margin-bottom: 5px;">
                    <div><span>
                        <mat-icon style="font-size: 12px;">fiber_manual_record</mat-icon>
                      </span> <span *ngIf="product.variantName!=product.parentProductName">{{product.parentProductName}}
                        ({{product.variantName}})</span>
                      <span *ngIf="product.variantName===product.parentProductName">{{product.variantName}}</span>
                    </div>
                    <div><span class="material-icons remove-btn" style="cursor: pointer;"
                        (click)="removeEntry(i,'barrier',1, 0)">
                        cancel
                      </span></div>

                  </div>
                </div>
                <div *ngIf="hurdleProductInnerType=='CATEGORY'&&barrierParamType==='NON_PERSONALIZED'" class="ls-container">
                  <div *ngFor="let product of selectedBarrierCollectionList;let i=index"
                    style="display: flex;width: 97%;justify-content: space-between;    margin-bottom: 5px;">
                    <div><span>
                        <mat-icon style="font-size:12px">fiber_manual_record</mat-icon>
                      </span> <span>{{product.collectionName}}</span></div>
                    <div><span class="material-icons remove-btn" style="cursor: pointer;"
                        (click)="removeEntry(i,'barrier',0, 0)">
                        cancel
                      </span></div>

                  </div>
                </div>
                <div>
                  <button *ngIf="hurdleProductInnerType != 'PRODUCT' && qid === 'null'" class="exclusion-browse-btn" (click)="browseProducts(hurdleProductInnerType, 'BARRIER', barrierParamType)" [disabled] = "selectedBarrierCollectionList.length == 0 && hurdleProductInnerType=='CATEGORY' && barrierParamType==='NON_PERSONALIZED'">{{'OFFER_PAGE.EXCLUDE_PRODUCTS' | i18nTranslate}}</button>
                </div>
                <div *ngIf="hurdleProductInnerType != 'PRODUCT' && qid === 'null'" class="ls-container">
                  <div *ngFor="let product of selectedBarrierExcludedProductList;let i=index"
                    style="display: flex;width: 97%;justify-content: space-between;    margin-bottom: 5px;">
                    <div><span>
                        <mat-icon style="font-size: 12px;">fiber_manual_record</mat-icon>
                      </span>
                      <span>{{product.productName}}</span>
                    </div>
                    <div><span class="material-icons remove-btn" style="cursor: pointer;"
                        (click)="removeEntry(i,'barrier',1, 1)">
                        cancel
                      </span></div>

                  </div>
                </div>
              </div>
            </div>
            <hr id="5">

            <div style="width: 50%;">
              <div style="width: 100%;">
                <div class="label-title">{{'OFFER_PAGE.CUSTOMER_GETS' | i18nTranslate}}<span class="qb-imp-color">*</span></div>
                <span class="label-subtitle">{{'OFFER_PAGE.QUANTITY' | i18nTranslate}}</span>

                <div style="width:100%;">
                  <div style="width:20%;">
                    <!-- <span class="fontExo2">Days to
      redeem</span> -->
                    <div style="width: 100%;display: flex;align-items: center;">
                      <div class="formDiv2"
                        [ngClass]="{active:submitClicked&&(hurdleRewardProductCount==null)||(hurdleRewardProductCount!=null&&(hurdleRewardProductCount<1||hurdleRewardProductCount==e))}"
                        style="width: 100%;">
                        <input class="formInput fontExo2" min=1 max=6 type="text" id="scrollPreventInput"
                          [(ngModel)]="hurdleRewardProductCount" (keydown)="keypressNumber($event)">


                      </div>

                    </div>
                    <div class="fontExo2 qb-error-msg">

                      <span
                        *ngIf="hurdleRewardProductCount!=null&&(hurdleRewardProductCount<1||hurdleRewardProductCount==e)">{{'OFFER_PAGE.ENTER_VALID_VALUE' | i18nTranslate}}</span>
                      <span *ngIf="hurdleRewardProductCount>100">{{'OFFER_PAGE.MAX_VALUE_6' | i18nTranslate}}</span>

                    </div>
                  </div>
                  <small style="color:red" *ngIf="submitClicked&&(hurdleRewardProductCount==null)">{{'OFFER_PAGE.FIELD_REQ' | i18nTranslate}}</small>
                </div>

              </div>

              <!-- <div style="width: 100%;">
              <span class="label-subtitle">Amount</span>

              <div style="width:100%;">
                <div style="width:90%;">

                  <div style="width: 100%;display: flex;align-items: center;">
                    <div class="formDiv2"
                      [ngClass]="{active:submitClicked&&(hurdleRewardProductCount==null)||(hurdleRewardProductCount!=null&&(hurdleRewardProductCount<1||hurdleRewardProductCount==e))}"
                      style="width: 100%;">
                      <input class="formInput fontExo2" min=1 type="number" [(ngModel)]="hurdleRewardProductCount">


                    </div>

                  </div>
                  <div style="height:13px; font-size:12px; color:#ff5f31" class="fontExo2">

                    <span
                      *ngIf="hurdleRewardProductCount!=null&&(hurdleRewardProductCount<1||hurdleRewardProductCount==e)">Enter
                      Valid value</span>

                  </div>
                </div>
                <small style="color:red" *ngIf="submitClicked&&(hurdleRewardProductCount==null)">Field is
                  required</small>
              </div>

            </div> -->


              <div style="width: 100%;">
                <div class="label-title">{{'OFFER_PAGE.APPLIES_TO' | i18nTranslate}}<span class="qb-imp-color">*</span></div>
                <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                  [(ngModel)]="buyXGetYType">
                  <mat-radio-button color="primary" class="example-radio-button" value="COLLECTION">
                    Specific Categories
                  </mat-radio-button>
                  <div style="display: flex;width: 100%;justify-content: space-between;">
                    <mat-radio-button color="primary" class="example-radio-button" value="PRODUCT">
                      {{'OFFER_PAGE.SPECIFIC_PRODUCTS' | i18nTranslate}}
                    </mat-radio-button>

                    <mat-slide-toggle (change)="toggleAIassistanceReward($event);" color="accent"
                      *ngIf="hurdleRewardType!='BUY_X_GET_Y'"
                      [checked]="rewardPersonalizationType === 'NON_PERSONALIZED' ? false : true">{{'OFFER_PAGE.AI_ASSISTANCE' | i18nTranslate}}
                    </mat-slide-toggle>
                  </div>
                </mat-radio-group>
                <!-- <div style="width:100%;"
                *ngIf="rewardPersonalizationType === 'PERSONALIZED'&&hurdleRewardType=='BUY_X_GET_Y'">
                <div *ngIf="discountAndShippingType=='PRODUCT'">AI will suggest 1 recommended product</div>
                <div *ngIf="discountAndShippingType=='COLLECTION'">AI will suggest 1 recommended collection</div>
              </div> -->

                <!-- <div style="width:100%;"
                *ngIf="rewardPersonalizationType === 'PERSONALIZED'&&hurdleRewardType=='BUY_X_GET_Y'">
                <div class="formDiv" [ngClass]="{active:submitClicked&&(rewardPropensity==null)}">
                  <mat-select #rewardPropensityselect2 class="formInput fontExo2" [(ngModel)]="rewardPropensity"
                    disableOptionCentering panelClass="myPanelClass">
                    <mat-option value="LOW">Low
                    </mat-option>
                    <mat-option value="MEDIUM">
                      Medium
                    </mat-option>
                    <mat-option value="HIGH">High
                    </mat-option>
                  </mat-select>
                  <div _ngcontent-c14="" class="keyboard-down-icon-container cursor-pointer"
                    id="keyboard-down-icon-container" style="transform: rotate(0deg);"
                    (click)="selectopenclose('rewardPropensityselect2')">
                    <mat-icon _ngcontent-c14=""
                      class="keyboard-down-icon mat-icon notranslate material-icons mat-icon-no-color" role="img"
                      aria-hidden="true">keyboard_arrow_down</mat-icon>
                  </div>
                </div>
              </div> -->
                <!-- <small style="color:red" *ngIf="submitClicked&&(rewardPropensity==null)">Field is
                required</small> -->

                <div style="width: 100%;display: flex;margin-bottom: 10px;justify-content: space-between;"
                  *ngIf="rewardPersonalizationType==='NON_PERSONALIZED'">
                  <div class="formDiv2" style="width: 85%;">
                    <input class=" formInput fontExo2" *ngIf="buyXGetYType=='PRODUCT'"
                      [(ngModel)]="atdiscountAndPercentageOffProducts"
                      (keyup)="openItemDialogwithSearchData(atdiscountAndPercentageOffProducts,'REWARD',buyXGetYType,hurdleRewardType)"
                      placeholder="Search by name (Minimum 3 characters)">
                    <input class=" formInput fontExo2" *ngIf="buyXGetYType=='COLLECTION'"
                      [(ngModel)]="atdiscountAndPercentageOffCollections"
                      (keyup)="openItemDialogwithSearchData(atdiscountAndPercentageOffCollections,'REWARD',buyXGetYType,hurdleRewardType)"
                      placeholder="Search by name (Minimum 3 characters)">
                    <span class="material-icons-outlined icons-primary cursor-pointer"
                      *ngIf="(buyXGetYType=='PRODUCT'&&atdiscountAndPercentageOffProducts.length==0)||(buyXGetYType=='COLLECTION'&&atdiscountAndPercentageOffCollections.length==0)">
                      search
                    </span>
                    <span class="material-icons-outlined icons-primary cursor-pointer" style="font-size: 19px;"
                      *ngIf="buyXGetYType=='PRODUCT'&&atdiscountAndPercentageOffProducts.length>0"
                      (click)="atdiscountAndPercentageOffProducts=''">
                      close
                    </span>
                    <span class="material-icons-outlined icons-primary cursor-pointer" style="font-size: 19px;"
                      *ngIf="buyXGetYType=='COLLECTION'&&atdiscountAndPercentageOffCollections.length>0"
                      (click)="atdiscountAndPercentageOffCollections=''">
                      close
                    </span>
                  </div>

                  <button class="browse-btn" (click)="browseReward(hurdleRewardType,buyXGetYType)">Browse</button>
                </div>

                <div *ngIf="buyXGetYType=='PRODUCT'&&rewardPersonalizationType==='NON_PERSONALIZED'" class="ls-container">
                  <div *ngFor="let product of selectedRewardProductList;let i=index"
                    style="display: flex;width: 97%;justify-content: space-between;    margin-bottom: 5px;">
                    <div><span>
                        <mat-icon style="font-size: 12px;">fiber_manual_record</mat-icon>
                      </span> <span *ngIf="product.variantName!=product.parentProductName">{{product.parentProductName}}
                        ({{product.variantName}})</span>
                      <span *ngIf="product.variantName===product.parentProductName">{{product.variantName}}</span>
                    </div>
                    <div><span class="material-icons remove-btn" style="cursor: pointer;"
                        (click)="removeEntry(i,'reward',1, 0)">
                        cancel
                      </span></div>

                  </div>
                </div>

                <div *ngIf="buyXGetYType  =='COLLECTION'&&rewardPersonalizationType==='NON_PERSONALIZED'"
                  id="selectedRewardCollectionList" class="ls-container">
                  <div *ngFor="let product of selectedRewardCollectionList;let i=index"
                    style="display: flex;width: 97%;justify-content: space-between;    margin-bottom: 5px;">
                    <div><span>
                        <mat-icon style="font-size:12px">fiber_manual_record</mat-icon>
                      </span> <span>{{product.collectionName}}</span></div>
                    <div><span class="material-icons remove-btn" style="cursor: pointer;"
                        (click)="removeEntry(i,'reward',0, 0)">
                        cancel
                      </span></div>
                  </div>
                </div>
                <div>
                  <button *ngIf="buyXGetYType != 'PRODUCT' && qid === 'null'" class="exclusion-browse-btn" (click)="browseProducts(buyXGetYType, 'REWARD', rewardPersonalizationType)" [disabled]="selectedRewardCollectionList.length == 0 && buyXGetYType  =='COLLECTION' && rewardPersonalizationType==='NON_PERSONALIZED'">{{'OFFER_PAGE.EXCLUDE_PRODUCTS' | i18nTranslate}}</button>
                </div>

                <div *ngIf="buyXGetYType != 'PRODUCT' && qid === 'null'" class="ls-container">
                  <div *ngFor="let product of selectedRewardExcludedProductList;let i=index"
                    style="display: flex;width: 97%;justify-content: space-between;    margin-bottom: 5px;">
                    <div><span>
                        <mat-icon style="font-size: 12px;">fiber_manual_record</mat-icon>
                      </span>
                      <span>{{product.productName}}</span>
                    </div>
                    <div><span class="material-icons remove-btn" style="cursor: pointer;"
                        (click)="removeEntry(i,'reward',1, 1)">
                        cancel
                      </span></div>

                  </div>
                </div>
              </div>
              <div style="width: 100%;">
                <div class="label-title">{{'OFFER_PAGE.AT_DISCOUNTED_VALUE' | i18nTranslate}}</div>
                <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                  [(ngModel)]="atADiscountedValue">
                  <mat-radio-button color="primary" class="example-radio-button" value="PERCENTAGE_OFF">
                    {{'OFFER_PAGE.PERCENTAGE' | i18nTranslate}}
                  </mat-radio-button>
                  <mat-radio-button color="primary" class="example-radio-button" value="AMOUNT_OFF">
                    {{'OFFER_PAGE.AMOUNT' | i18nTranslate}}
                  </mat-radio-button>
                  <mat-radio-button color="primary" class="example-radio-button" value="FREE">
                    {{'OFFER_PAGE.FREE' | i18nTranslate}}
                  </mat-radio-button>

                </mat-radio-group>
              </div>

              <div *ngIf="(hurdleRewardType==='BUY_X_GET_Y'&&atADiscountedValue==='PERCENTAGE_OFF')">
                <div style="width:90%;">
                  <!-- <span
                  class="fontExo2">Percentage</span> -->
                  <div style="width: 15%;display: flex;align-items: center;">
                    <div class="formDiv2"
                      [ngClass]="{active:submitClicked&&(rewardpercentageOff==null)||(rewardpercentageOff!=null&&(rewardpercentageOff<1||rewardpercentageOff==e||rewardpercentageOff>100))}"
                      style="width: 100%;display: flex;">
                      <!-- [ngClass]="{'active' : (offerTemplate.controls.offerBarrier.controls[i].controls['percentageOff'].errors ? (offerTemplate.controls.offerBarrier.controls[i].controls['percentageOff'].errors['min'] || offerTemplate.controls.offerBarrier.controls[i].controls['percentageOff'].errors['max']) : false)
    || (offerTemplate.controls.offerBarrier.controls[i].controls['percentageOff'].errors ? (offerTemplate.controls.offerBarrier.controls[i].controls['percentageOff'].errors.required && offerTemplate.controls.offerBarrier.controls[i].controls['percentageOff'].touched || submitClicked) : false)}"> -->
                      <input class=" formInput fontExo2" type="text" min=0 max=100 maxlength="3"[(ngModel)]="rewardpercentageOff" id="scrollPreventInput" (keydown)="keypressNumber($event)">
                    </div>
                    <span matSuffix [id]="'pvalue-'+i+'-'+j" style="font-size: 12px;margin: 0 8px;color: #3a3a3a;">
                      %</span>

                  </div>
                  <div class="fontExo2 qb-error-msg">
                    <span
                      *ngIf="rewardpercentageOff!=null&&(rewardpercentageOff<1||rewardpercentageOff==e||rewardpercentageOff>100)">{{'OFFER_PAGE.ENTER_VALID_VALUE' | i18nTranslate}}</span>

                  </div>
                </div>


              </div>
              <div *ngIf="(hurdleRewardType==='BUY_X_GET_Y'&&atADiscountedValue==='AMOUNT_OFF')">
                <div style="width:90%;">
                  <!-- <span
                  class="fontExo2">Percentage</span> -->
                  <div style="width: 20%;display: flex;align-items: center;">
                    <div class="formDiv2"
                      [ngClass]="{active:submitClicked&&(rewardAmountOff==null)||(rewardAmountOff!=null&&(rewardAmountOff<1||rewardAmountOff==e))}"
                      style="width: 100%;display: flex;">
                      <!-- [ngClass]="{'active' : (offerTemplate.controls.offerBarrier.controls[i].controls['percentageOff'].errors ? (offerTemplate.controls.offerBarrier.controls[i].controls['percentageOff'].errors['min'] || offerTemplate.controls.offerBarrier.controls[i].controls['percentageOff'].errors['max']) : false)
    || (offerTemplate.controls.offerBarrier.controls[i].controls['percentageOff'].errors ? (offerTemplate.controls.offerBarrier.controls[i].controls['percentageOff'].errors.required && offerTemplate.controls.offerBarrier.controls[i].controls['percentageOff'].touched || submitClicked) : false)}"> -->
                      <span matSuffix [id]="'pvalue-'+i+'-'+j" style="font-size: 12px;margin: 0 8px;color: #3a3a3a;">
                        {{currencySymbol}}</span>
                      <input class=" formInput fontExo2" type="text" min=0 [(ngModel)]="rewardAmountOff" id="scrollPreventInput" (keydown)="keypressNumber($event)">
                    </div>

                  </div>
                  <div class="fontExo2 qb-error-msg">
                    <span
                      *ngIf="rewardAmountOff!=null&&(rewardAmountOff<1||rewardAmountOff==e)">{{'OFFER_PAGE.ENTER_VALID_VALUE' | i18nTranslate}}</span>

                  </div>
                </div>


              </div>

            </div>


          </div>


          <hr id="6">
          <div style="width: 100%;" id ='usageLimit' *ngIf="!((hurdleRewardType!='FREE_SHIPPING'&&hurdleRewardType!='BUY_X_GET_Y'&&rewardPersonalizationType === 'PERSONALIZED' && pos === 'shopify')||(hurdleRewardType==='BUY_X_GET_Y'&&barrierParamType === 'PERSONALIZED' && pos === 'shopify'))">
            <div class="label-title">{{'OFFER_PAGE.USAGE_LIMIT' | i18nTranslate}}</div>
            <div class="label-subtitle" *ngIf="!islimitNoOfTry">{{'OFFER_PAGE.USAGE_LIMIT_WARNING' | i18nTranslate}} </div>
            <div>
              <mat-checkbox class="example-margin" style="display: block;" [(ngModel)]="islimitNoOfTry" color="primary"
                *ngIf="!((hurdleRewardType!='FREE_SHIPPING'&&hurdleRewardType!='BUY_X_GET_Y'&&rewardPersonalizationType === 'PERSONALIZED' && pos === 'shopify')||(hurdleRewardType==='BUY_X_GET_Y'&&barrierParamType === 'PERSONALIZED' && pos === 'shopify'))">
                <span *ngIf="pos === 'shopify'">
                  {{'OFFER_PAGE.LIMIT_NUMBER_OF_TIMES_USED' | i18nTranslate}}
                </span>
                <span *ngIf="pos !== 'shopify'">
                  {{'OFFER_PAGE.LIMIT_NUMBER_OF_TIMES_USED_PER_CUSTOMER' | i18nTranslate}}
                </span>
              </mat-checkbox>

              <div style="width:100%; margin-top: 10px;" *ngIf="islimitNoOfTry">
                <div style="width:50%;">
                  <!-- <span class="fontExo2">Days to
      redeem</span> -->
                  <div style="width: 25%;display: flex;align-items: center;">
                    <div class="formDiv2"
                      [ngClass]="{active:submitClicked&&((noOfTry==null)||(noOfTry!=null&&(noOfTry<1||noOfTry==e)))}"
                      style="width: 100%;">
                      <input class="formInput fontExo2" min=1 type="number" id="scrollPreventInput" [(ngModel)]="noOfTry"  placeholder="Usage Limit" #inp5 maxlength="3" (keydown)="numberInputHandler($event,3)">
                      <span class="charText" style="color:#636466 ;"> {{inp5.value.length}} <span
                        class="charText">/3</span> </span>
                    </div>

                  </div>
                  <div class="fontExo2 qb-error-msg">

                    <span *ngIf="noOfTry!=null&&(noOfTry<1||noOfTry==e) && submitClicked">{{'OFFER_PAGE.ENTER_VALID_VALUE' | i18nTranslate}}</span>

                  </div>
                </div>
                <small style="color:red" *ngIf="submitClicked&&(noOfTry==null)">{{'OFFER_PAGE.ENTER_VALID_VALUE' | i18nTranslate}}</small>
              </div>
            </div>

            <mat-checkbox *ngIf="pos === 'shopify'" class="example-margin" color="primary" [(ngModel)]="islimitToOneUsePerCustomer">{{'OFFER_PAGE.LIMItT_ONE_USE_PER_CUSTOMER' | i18nTranslate}}
            </mat-checkbox>
          </div>

          <hr id="7">
          <div style="width: 100%;" class="qb-gap-component">
            <div>
              <div class="label-title">{{'OFFER_PAGE.REWARD_VALIDITY' | i18nTranslate}}<span class="qb-imp-color">*</span></div>
            </div>
            <div>
              <mat-slide-toggle class="qb-fs-limited-time" (change)="toggelLimitedTimeOffer($event);" color="accent" 
              [checked]="isLImitedTime">
              <span>
                Limited Time Offer (Offer available for a specific period of time only).
              </span>
            </mat-slide-toggle>
          </div>
          
          <div style="width:100%;" class="qb-gap-component" *ngIf="!isLImitedTime">
            <span *ngIf="!isLImitedTime" class="label-subtitle">{{'OFFER_PAGE.REWARD_VALID_FOR' | i18nTranslate}}</span>
              <div style="width:50%;">

                <div style="width: 20%;display: flex;align-items: center;">
                  <div class="formDiv2"
                    [ngClass]="{active:submitClicked&&(rewardTimeToComplete==null)||(rewardTimeToComplete!=null&&(rewardTimeToComplete<1||rewardTimeToComplete=='e')) && !isLImitedTime}"
                    style="width: 100%;">
                    <input class="formInput fontExo2" min=1 type="number" id="scrollPreventInput" maxlength="3" [(ngModel)]="rewardTimeToComplete" (keydown)="numberInputHandler($event,3)">
                  </div>
                  <span matSuffix [id]="'pvalue-'+i+'-'+j" style="font-size: 12px;margin: 0 8px;color: #3a3a3a;"
                    *ngIf="rewardTimeToComplete>1">
                    {{'OFFER_PAGE.DAYS' | i18nTranslate}}</span>
                  <span matSuffix [id]="'pvalue-'+i+'-'+j" style="font-size: 12px;margin: 0 8px;color: #3a3a3a;"
                    *ngIf="rewardTimeToComplete<2">
                    {{'OFFER_PAGE.DAY' | i18nTranslate}}</span>

                </div>
                <div class="fontExo2 qb-error-msg" *ngIf="rewardTimeToComplete!=null&&(rewardTimeToComplete<1||rewardTimeToComplete=='e')">

                  <span>{{'OFFER_PAGE.ENTER_VALID_VALUE' | i18nTranslate}}</span>

                </div>
              </div>
              <small style="color:red" *ngIf="submitClicked&&(rewardTimeToComplete==null)">{{'OFFER_PAGE.REWARD_VALIDITY_ERROR' | i18nTranslate}}</small>
            </div>
            <div *ngIf="isLImitedTime" class="qb-limited-period-container">
              <div class="qb-d-flex qb-gap-20">
                <div style="width: 25%">
                  <span class="fs08-rem">{{'SHARED_PAGE.START_DATE' | i18nTranslate}}</span><span style="color: red">*</span>
                  <div class="formDiv-date" [ngClass]="{active:startDate.value==null || checkIfBeforeCurrentDate(startDate.value)}">
                    <input style=" width: 0px; opacity: 0;"  [formControl]="startDate" matInput
                      [matDatepicker]="pickerOffer1" placeholder="Choose a date" [min]="startDateMinValue"
                      (dateChange)="startDateChange()" readonly />
                      <div style="margin-left: 4px;" class=" formInput fs08-rem">
                        {{startDate.value | date: getDateFormat("MMM/dd/yyyy") }}
                      </div>
                    <mat-datepicker-toggle matSuffix [for]="pickerOffer1">
                    </mat-datepicker-toggle>
                    <mat-datepicker #pickerOffer1></mat-datepicker>
                  </div>
                </div>
                <div style="width: 25%">
                  <span class="fs08-rem">{{'SHARED_PAGE.END_DATE' | i18nTranslate}}</span><span style="color: red">*</span>
                  <div class="formDiv-date">
                    <input style=" width: 0px; opacity: 0;" [formControl]="endDate"  matInput
                      [matDatepicker]="pickerOffer2" placeholder="Choose a date" [min]="endDateMinValue"
                      (dateChange)="endDateChange()" readonly />
                      <div style="margin-left: 4px;" class=" formInput fs08-rem">
                        {{endDate.value | date: getDateFormat("MMM/dd/yyyy") }}
                      </div>
                    <mat-datepicker-toggle [for]="pickerOffer2"></mat-datepicker-toggle>
                    <mat-datepicker #pickerOffer2></mat-datepicker>
                  </div>
                </div>
              </div>
              <span *ngIf="(validWidgets | widgetConfig: 89)">
                <hr id="1">
                <div style="width: 100%;">
                  <mat-radio-group aria-labelledby="example-radio-group-label" class="qb-radio-group-timetype" (change)="changeTimeType($event.value)"
                    [(ngModel)]="offerTimeType">
                    <mat-radio-button color="primary" class="example-radio-button" [value]="type.value" *ngFor="let type of typeOfOffer">
                      {{type.displayName}}
                    </mat-radio-button>
                  </mat-radio-group>
                </div>
              </span>
              <div
              class="qb-d-flex qb-gap-20">
              <div style="width: 25%">
                <span class="fs08-rem">{{'SHARED_PAGE.START_TIME' | i18nTranslate}}</span><span style="color: red">*</span>
                <div class="formDiv-date" [ngClass]="{active:startTime.value==null}" style=" justify-content: space-between;  position: relative;">
                  <input style=" opacity:0;position:absolute; cursor: pointer;" class="formInput fs08-rem" [formControl]="startTime"  
                  [ngxTimepicker]="pickerOffer1T"  

                  [max]="startMaxTime" [min]="startMinTime" [format]="serviceCountry === 'US' ? 12 : 24" readonly />
                  <div style="margin-left: 10px;">{{transformTo24HourFormat(startTime.value) | customTime}}</div>
                  <div>
                    <img 
                    style="margin-right: 10px; cursor: pointer;"
                    src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/icons/negotiator/time.svg"
                    alt=""
                    (closed)="startTimeChange()"
                  />
                  <ngx-material-timepicker #pickerOffer1T (closed)="startTimeChange()">
                  </ngx-material-timepicker>
                  </div>
                    </div>
              </div>
              <div style="width: 25%">
                <span class="fs08-rem">{{'SHARED_PAGE.END_TIME' | i18nTranslate}}</span><span style="color: red">*</span>
                <div class="formDiv-date" [ngClass]="{active:endTime.value==null}" style=" justify-content: space-between;  position: relative;">
                  <input style=" opacity:0;position:absolute; cursor: pointer;" class="formInput" [formControl]="endTime"  [ngxTimepicker]="pickerOffer2T" 
                   [min]="endMinTime" [format]="serviceCountry === 'US' ? 12 : 24" readonly />
                   <div style="margin-left: 10px;">{{transformTo24HourFormat(endTime.value) | customTime}}</div>
                   <div>
                     <img
                     style="margin-right: 10px; cursor: pointer;"
                     src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/icons/negotiator/time.svg"
                     alt=""
                     (closed)="endTimeChange()"
                   />
                   <ngx-material-timepicker #pickerOffer2T (closed)="endTimeChange()">
                   </ngx-material-timepicker>
                   </div>
                </div>
              </div>
            </div>

            <div style="margin-top: 10px" *ngIf="offerTimeType.toUpperCase() === 'RECCURRING'">
              <div class="qb-d-flex qb-week-class">
                <mat-slide-toggle color="accent" [(ngModel)]="everyDaySliderValue"></mat-slide-toggle>
                <span class="fs08-rem"> {{'CAMPAIGN_PAGE.EVERY_DAY_WEEK' | i18nTranslate}}</span>
              </div>
              <mat-chip-list *ngIf="!everyDaySliderValue" placeholder="Select a day" class="font-class fs08-rem"
                multiple="true">
                <mat-chip color="accent" #c="matChip" *ngFor="let day of weekDaysChips"
                  class="font-class fs08-rem cursor-pointer weekdays-chip" [value]="day.name"
                  (click)="toggleSelection(day)" [selected]="day.selected">
                  {{ day.name }}
                </mat-chip>
              </mat-chip-list>
            </div>

            </div>

          </div>

          <span *ngIf="(validWidgets | widgetConfig: 88)">
            <hr id="8">
            <div class="qb-gap-component">
              <div class="label-title">
                <span>Order Type</span><span class="qb-imp-color">*</span>
              </div>
              <div class="qb-flex qb-half-width">
                <div class="qb-form-div-schedule qb-half-width cursor-pointer" style="margin-right: 12px;"
                (click)="selectopenclose('orderTypeDropDown')" 
                [ngClass]="{'error':submitClicked && orderType.value.length == 0}">
                  <mat-select #orderTypeDropDown class="qb-font-exo2"
                    color="primary" placeholder="Select Order Type"
                    [formControl]="orderType" multiple disableOptionCentering
                    panelClass="myPanelClass">
                    <div class="qb-dropdown-wrapper">
                      <div class="qb-rectangle">
                        <div class="qb-rect">
                          <mat-icon class="qb-icon" matPrefix>search</mat-icon>
                          <input class="qb-no-border-input" type="text" [(ngModel)]="dropDownSearch"
                            placeholder=" Search by name " (keydown)="$event.stopPropagation()"/>
                        </div>
                      </div>
                      <div class="qb-dropdown-scroll-wrapper">
                        <div class="qb-scrollable-conatiner">
                          <div class="qb-mat-select-dropdowns">
                            <mat-option 
                              [value]="allOfferTypeObj.value" class="qb-font-exo2" (click)="selectOrderTypeDropDown(allOfferTypeObj.value)">
                              {{ allOfferTypeObj.displayName }}
                            </mat-option>
                            <span *ngFor="let type of typeOfOrderType | labelPipe
                            : dropDownSearch : 'displayName'" >
                              <mat-option
                                [value]="type.value" class="qb-font-exo2" *ngIf="type.applicableFor === discountOn || type.applicableFor === 'ALL'" (click)="selectOrderTypeDropDown(type.value)">
                                <span>
                                  {{ type.displayName }}
                                </span>
                              </mat-option>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </mat-select>
                </div>
              </div>
            </div>
            <small style="color:red" *ngIf="submitClicked && orderType.value.length == 0">{{'OFFER_PAGE.FIELD_REQ' | i18nTranslate}}</small>
          </span>


          <hr id="8">
          <div class="qb-gap-component">
            <div class="label-title">
              <span>Stores where offer can be redeemed</span><span class="qb-imp-color">*</span>
            </div>
            <div class="qb-flex qb-half-width">
              <div class="qb-form-div-schedule qb-half-width cursor-pointer" style="margin-right: 12px;"
              (click)="selectopenclose('redeemableAtStoresSelect')" 
              [ngClass]="{'error':submitClicked && redeemableAtStores.value.length == 0,'disabled':!isAdminPos}"
              [matTooltip]="!isAdminPos?'Editable only for offers created by Admin':''">
                <mat-select #redeemableAtStoresSelect class="qb-font-exo2"
                  color="primary" placeholder="Select Stores"
                  [formControl]="redeemableAtStores" multiple disableOptionCentering
                  panelClass="myPanelClass">
                  <div class="qb-dropdown-wrapper">
                    <div class="qb-rectangle">
                      <div class="qb-rect">
                        <mat-icon class="qb-icon" matPrefix>search</mat-icon>
                        <input class="qb-no-border-input" type="text" [(ngModel)]="dropDownSearch"
                          placeholder=" Search by name " (keydown)="$event.stopPropagation()"/>
                      </div>
                    </div>
                    <div class="qb-dropdown-scroll-wrapper">
                      <div class="qb-scrollable-conatiner">
                        <div class="qb-mat-select-dropdowns">
                          <mat-option 
                            [value]="allStoresObj.qid" class="qb-font-exo2" (click)="selectStoreInDropdown(allStoresObj.qid)">
                            {{ allStoresObj.storeName }}
                          </mat-option>
                          <mat-option *ngFor="let store of storeData | labelPipe
                            : dropDownSearch : 'storeName'" 
                            [value]="store.qid" class="qb-font-exo2" (click)="selectStoreInDropdown(store.qid)">
                            {{ store.storeName }}
                          </mat-option>
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-select>
              </div>
            </div>
          </div>
          <small style="color:red" *ngIf="submitClicked && redeemableAtStores.value.length == 0">{{'OFFER_PAGE.FIELD_REQ' | i18nTranslate}}</small>
          <span *ngIf="(validWidgets | widgetConfig: 95)">
            <hr id="9">
            <div style="width: 100%;" id ='customDescription'>
              <div class="label-title">Custom Description(Optional)</div>
              <div class="label-subtitle">  </div>
              <div>
                <div style="width:100%; margin-top: 10px;">
                  <div>
                    <div class="qb-d-flex qb-ai-center">
                      <textarea
                      class="bullet-textarea qb-w-100"
                      [(ngModel)]="customDescription"
                      (keydown)="handleKeyDown($event, 'description')"
                      (focus)="initializeBulletPoint('description')"
                      placeholder="Type here..."
                    ></textarea>                 
                    </div>
                  </div>
                  <small style="color:red" *ngIf="submitClicked&&(noOfTry==null)">{{'OFFER_PAGE.ENTER_VALID_VALUE' | i18nTranslate}}</small>
                </div>
              </div>
            </div>
          </span>
          <span *ngIf="(validWidgets | widgetConfig: 96)">
            <hr id="10">
            <div style="width: 100%;" id ='customTermsAndCondition'>
              <div class="label-title">Custom Terms and Condition(Optional)</div>
              <div class="label-subtitle">  </div>
              <div>
                <div style="width:100%; margin-top: 10px;">
                  <div>
                    <div class="qb-d-flex qb-ai-center">
                      <textarea
                      class="bullet-textarea qb-w-100"
                      [(ngModel)]="customTermsAndCondition"
                      (keydown)="handleKeyDown($event, 'terms')"
                      (focus)="initializeBulletPoint('terms')"
                      placeholder="Type here..."
                    ></textarea>                 
                    </div>
                  </div>
                  <small style="color:red" *ngIf="submitClicked&&(noOfTry==null)">{{'OFFER_PAGE.ENTER_VALID_VALUE' | i18nTranslate}}</small>
                </div>
              </div>
            </div>
          </span>


          <!-- <hr id="9">
          <div class="qb-gap-component">
            <div class="label-title">
              <span>Offer Budget</span><mat-checkbox class="custom-check" color="primary" [(ngModel)]="hasBudget" (change)="onHasBudgetChange()"></mat-checkbox>
            </div>
            <app-offer-budget [ngClass]="{'disabled-budget':!hasBudget}" #offerBudgetComp>
            </app-offer-budget>
          </div> -->
          








        </div>
      </div>
    </div>
    <div class="save-offer-popup">
      <div *ngIf="popUpMode" class="primary-button" appButtonDebounce [throttleTime]="600" data-button-name="Save Offer"
      [matTooltip]="'Save Offer'" (click)="this.submitOfferForm('saveTemplateAndChannel')">
        <span class="normText" style="color: #fff; margin-left: 3px;">Save Offer</span>
      </div>
    </div>
   
  </div>

  


</div>

<div id="schedule-overlay"></div>

<div style="  position: absolute;
          top: 0;
          right: 0;
          height: 100vh;
          width: 65%;
          z-index: -1;font-size: 13px;" id="configDiv">


  <app-offer-schedule-overlay style="width: 100%;height: 100%;" (messageEvent)="receiveMessage($event)"
    [offerId]="queryparamofferId" [offerReqBodyObj]="offerReqBodyObj" [action]="saveActionType"
    [BarrierRewardFull]="BarrierRewardFull" *ngIf='openScheduleOverlay' [popUpMode]="popUpMode" [isLImitedTime]="isLImitedTime" [discountOn] = "discountOn">
  </app-offer-schedule-overlay>
</div>