import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import "rxjs";

@Injectable()
export class Globals {
  constructor() {}
  public sidenavToggleStatus: BehaviorSubject<string> = new BehaviorSubject(
    null
  );
  public sidenavToggleButton: BehaviorSubject<string> = new BehaviorSubject(
    null
  );
  public redirectToPaymentPlan: boolean = false;
  public getplanIdValue: number = null;
  public sidenavButtonActive;
  public merchantDetailsCurrency: BehaviorSubject<any> = new BehaviorSubject(
    null
  );
  public merchantDetailsCountry: BehaviorSubject<any> = new BehaviorSubject(
    null
  );
  public avatarUploaded: BehaviorSubject<string> = new BehaviorSubject(null);

  public trialdaysRemaining: BehaviorSubject<any> = new BehaviorSubject(null);
  public isOnboarding: BehaviorSubject<any> = new BehaviorSubject(null);
  ngOnInit() {}
  setNewAvatarUploaded(url) {
    this.avatarUploaded.next(url);
  }

  passQueryValue(status: boolean, value: number) {
    this.redirectToPaymentPlan = status;
    this.getplanIdValue = value;
  }

  setMerchantCurrencyandCountry(currency: string, country: string) {
    this.merchantDetailsCurrency.next(currency);
    this.merchantDetailsCountry.next(country);
    localStorage.setItem("serviceCurrency", this.merchantDetailsCurrency.value);
    localStorage.setItem("serviceCountry", this.merchantDetailsCountry.value);
    sessionStorage.setItem(
      "serviceCurrency",
      this.merchantDetailsCurrency.value
    );
    sessionStorage.setItem("serviceCountry", this.merchantDetailsCountry.value);
  }

  setSidenavToggleStatus(data, button) {
    this.sidenavToggleStatus.next(data);
    this.sidenavToggleButton.next(button);
    this.sidenavButtonActive = button;
  }

  getSidenavToggleStatus(days) {}

  trialperiodBannerUpdate(days) {
    // this.trialdaysRemaining=(days)
    //passing the data as the next observable
    // if (days) {
    this.trialdaysRemaining.next(days);
    //sessionStorage.setItem('trialdaysRemaining', this.trialdaysRemaining.value);
    // }
  }
  setIsOnboarding(status) {
    this.isOnboarding.next(status);
  }

  getStartOfWeekByYearWeek(year: number, weekNumber: number): Date {
    // Get the first day of the year
    const firstDayOfYear = new Date(year, 0, 1); // January 1st
    const dayOfWeek = firstDayOfYear.getDay(); // Day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)

    // Adjust to the start of the first week (assuming weeks start on Monday)
    const daysToFirstMonday = dayOfWeek === 0 ? 1 : 8 - dayOfWeek; // 1 if Sunday, otherwise offset to next Monday
    const firstMonday = new Date(year, 0, daysToFirstMonday);

    // Calculate the start of the requested week
    const startOfWeekDate = new Date(firstMonday);
    startOfWeekDate.setDate(firstMonday.getDate() + (weekNumber - 1) * 7);

    return startOfWeekDate;
  }

  formatDateToCustomDateString(date): string {
    // Extract components
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const day = date.getDate(); // Day of the month
    const month = monthNames[date.getMonth()]; // Get abbreviated month name
    const year = date.getFullYear(); // Year

    // Combine into desired format
    return `${month} ${day} ${year}`;
  }

  convertArrayToDateString(dateArray: [number, number, number]): string {
    const [year, month, day] = dateArray;
    return `${year}-${month}-${day}`;
  }

  isDataSyncedWithin7Days(inputDate: string): boolean {
    // Parse the input date (assuming format "DD/MM/YYYY")
    const [year, month, day] = inputDate.split("-").map(Number);
    const givenDate = new Date(year, month - 1, day);

    // Get the current date
    const currentDate = new Date();

    // Calculate the difference in milliseconds
    const diffInMilliseconds = currentDate.getTime() - givenDate.getTime();

    // Convert milliseconds to days
    const diffInDays = diffInMilliseconds / (1000 * 60 * 60 * 24);

    // Check if the difference is within 7 days
    return diffInDays <= 7 && diffInDays >= 0;
  }
}
