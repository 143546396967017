import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { TokenStorageService } from 'src/app/core/auth/token-storage.service';
import { ApiCallService } from 'src/app/core/services/api-call-service';
import { SnackbarCollection } from 'src/app/core/services/snackbar.service';
import { WidgetConfigService } from 'src/app/core/services/widget-config.service';
import { MatPaginator } from '@angular/material/paginator';
import { getdateformatService } from 'src/app/core/services/get-date-format.service';

@Component({
  selector: 'app-review-instance',
  templateUrl: './review-instance.component.html',
  styleUrls: ['./review-instance.component.css']
})
export class ReviewInstanceComponent implements OnInit {
  @Input() platformFilter: string = 'None';
  constructor(
    private apiCall: ApiCallService,
    private _WidgetConfigService: WidgetConfigService,
    private tokenStorage: TokenStorageService,
    private snackBar: SnackbarCollection,
    private date_format: getdateformatService,
  ) { }

  reviews;
  pageNumber = 0;
  pageSize = 10;
  storeFilterList = [{
    storeName:'None',
    qid: null,
  }];
  selectedStore;
  isAdmin = false;
  validWidgets = null;
  reviewsBody=[];
  totalReviewslength: number;
  loading = true;
  selectedi = -1;
  rightMargin;
  graphDataLoading = true;
  dashboardData;
  isFiltering: boolean = false;
  @ViewChild('paginator') paginator: MatPaginator;

  ngOnInit(): void {
    this.selectedStore = this.storeFilterList[0];
    this.getWidgets();
    if(this.tokenStorage.getqId() == 'null'){
      this.isAdmin = true;
      this.apiCall.getReviewsActiveStore().subscribe(
        (response) => {
          this.storeFilterList = [{
            storeName:'None',
            qid: null,
          }];
          if(response['body']) {
            response['body'].forEach((storeData)=>{
              if(storeData.qid) {
                this.storeFilterList.push({
                  'qid':storeData.qid,
                  'storeName':storeData.storeName,
                });
              }
            });
            this.selectedStore = this.storeFilterList[0];
          }
        },
        (err) => {
          this.snackBar.openSnackBar("Error ", 2000);
        }
      );
    }
    this.fetchReviews();
  }

  ngOnChanges(): void {
      if(this.selectedStore?.qid!=null){
        this.getFilteredSurveyList();
      }
      else{
        this.isFiltering = true;
        this.fetchReviews();
      }
  }

  getStars(rating: number): number[] {
    const maxStars = 5;
    return Array(maxStars).fill(0).map((_, index) => index < rating ? 1 : 0);
  }

  fetchReviews(){
    this.loading = true;
    this.graphDataLoading = true;
    this.apiCall.getAllReviews(this.pageNumber, this.pageSize,this.platformFilter).subscribe(
      (response) => {
        this.reviews = response["body"];
        this.reviewsBody = this.reviews["reviewDetailsResponses"];
        this.totalReviewslength = this.reviews["totalElements"];
        this.SentimentAnalysisParticularReview(0);
        this.loading = false;
        let sheader = document.getElementById("tableHeads");

        let interval = setInterval(() => {
          let sideMenu = document.getElementById("side-menu-survey");
          let sideMenuParent = document.getElementById(
            "side-menu-survey-parent"
          );

          if (sideMenu) {
            clearInterval(interval);
            if (sideMenuParent.clientHeight < sideMenuParent.scrollHeight) {
              this.rightMargin = "14px"; //5 is the scroller width
                sheader.style.paddingRight = this.rightMargin;
            } else {
              sheader.style.paddingRight = "0px";
            }
          }
        });
      },
      (err) => {
        this.snackBar.openSnackBar("Error ", 2000);
        this.loading = false;
      }
    );
  }

  getDateFormat(type?){
    return this.date_format.getDateFormat(type);
  }

  async getWidgets() {
    this.validWidgets = await this._WidgetConfigService.getWidget();
  }

  getFilteredSurveyList() {
    this.loading = true;
    if(this.paginator) {
      this.paginator.pageIndex = 0; // Reset to the first page
      this.paginator.firstPage();
    }
    this.isFiltering = true;
    this.apiCall.getAllReviews(this.pageNumber, this.pageSize,this.platformFilter,this.selectedStore.qid).subscribe(
      (response) => {
        this.reviews = response["body"];
        this.reviewsBody = this.reviews["reviewDetailsResponses"];
        this.totalReviewslength = this.reviews["totalElements"];
        this.SentimentAnalysisParticularReview(0);
        this.loading = false;
        let sheader = document.getElementById("tableHeads");

        let interval = setInterval(() => {

          let sideMenu = document.getElementById("side-menu-survey");
          let sideMenuParent = document.getElementById(
            "side-menu-survey-parent"
          );

          if (sideMenu) {
            clearInterval(interval);
            if (sideMenuParent.clientHeight < sideMenuParent.scrollHeight) {
              this.rightMargin = "14px"; //5 is the scroller width
                sheader.style.paddingRight = this.rightMargin;
            } else {
              sheader.style.paddingRight = "0px";
            }
          }
        });
      },
      (err) => {
        this.snackBar.openSnackBar("Error ", 2000);
      }
    );
  }

  getNextData(pageEvent) {
    this.pageNumber = pageEvent.pageIndex;
    this.pageSize = pageEvent.pageSize;
    console.log(pageEvent);
    this.loading = true;
    this.reviewsBody = [];
    this.isFiltering=true;
    this.apiCall.getAllReviews(this.pageNumber, this.pageSize,this.platformFilter, this.selectedStore.qid).subscribe(
      (response) => {
        this.reviews = response["body"];
        this.reviewsBody = this.reviews["reviewDetailsResponses"];
        this.SentimentAnalysisParticularReview(0);
        this.selectedi = 0;
        this.totalReviewslength = this.reviews["totalElements"];
        this.loading = false;
        let sheader = document.getElementById("tableHeads");
        let interval = setInterval(() => {
          let sideMenu = document.getElementById("side-menu-survey");
          let sideMenuParent = document.getElementById(
            "side-menu-survey-parent"
          );

          console.log(sideMenu);
          if (sideMenu) {
            console.log(
              sideMenuParent.clientHeight,
              sideMenuParent.scrollHeight
            );
            clearInterval(interval);

            if (sideMenuParent.clientHeight < sideMenuParent.scrollHeight) {
              this.rightMargin = "14px"; 
                sheader.style.paddingRight = this.rightMargin;
              console.log("2");
            } else {
               sheader.style.paddingRight = "0px";
            }
          }
        });
      },
      (err) => {
        this.snackBar.openSnackBar("error", 2000);
        this.loading = false;
      }
    );
    this.loading = false;
  }

  SentimentAnalysisParticularReview(index) {
    if(index==this.selectedi && !this.isFiltering){
      return;
    }
    this.selectedi=index;
    let dashboardData;
    this.graphDataLoading = true;
    if (index != null && this.reviewsBody.length > 0) {
      this.apiCall
        .getIndividualReviewDashboard(
          this.reviewsBody[index]["reviewId"]
        )
        .subscribe(
          (response) => {
            if (response["message"] === "SUCCESS") {
              dashboardData = response["body"];
              this.dashboardData = dashboardData;
              this.graphDataLoading = false;
            } else {
              
            }
          },
          (error) => {
            this.snackBar.openSnackBar("Error fetching data", 2000);
            this.graphDataLoading = false;
          }
        );
    } else {
      this.dashboardData = null;
      this.graphDataLoading = false;
    }
    if (this.isFiltering) {
      this.isFiltering = false;
    }
  }


  }


