<div class="qb-w-100 qb-h-100">
  <div
    class="qb-offer-section-header qb-offer-section-header2 qb-top-container qb-padding-30"
  >
    <div>
      <app-breadcrumb></app-breadcrumb>
    </div>
    <div class="qb-offer-all-heading">
      <button
        class="primary-button qb-create-new-offer-button"
        (click)="createOffer()"
        data-button-name="Create New Offer"
      >
        <mat-icon class="qb-add-icon">add</mat-icon>
        {{ "OFFER_PAGE.CREATE_NEW_OFFER" | i18nTranslate }}
      </button>
      <button
        *ngIf="validWidgets | widgetConfig : 49"
        class="secondary-button qb-get-customer-offer"
        (click)="getCustomerOffer()"
        data-button-name="Get Customer Offer"
      >
        {{ "OFFER_PAGE.GET_CUSTOMER_OFFER" | i18nTranslate }}
      </button>
      <button
      *ngIf="(validWidgets | widgetConfig : 49) && qid === 'null'"
      class="secondary-button qb-get-customer-offer"
      (click)="browseProducts('ORDER', 'REWARD')"
      data-button-name="Get Customer Offer"
    >
      {{ "OFFER_PAGE.ADD_DEFAULT_EXCLUDION" | i18nTranslate }}
    </button>
    <button
      *ngIf="(validWidgets | widgetConfig : 49) && qid !== 'null'"
      class="secondary-button qb-get-customer-offer"
      (click)="showProducts('excludedProduct')"
      data-button-name="Get Customer Offer"
    >
    {{ "OFFER_PAGE.VIEW_DEFAULT_PRODUCT_LIST" | i18nTranslate }}
  </button>
    </div>
  </div>
  <div
    class="qb-main"
    *ngIf="validWidgets"
    id="viewconfigDiv"
    data-widget-name="OFFER LISTING"
    (scroll)="onScroll($event)"
  >
    <div
      class="qb-offer-section-header qb-offer-section-header2 qb-offer-list-heading-wrapper"
    >
      <div class="module-heading">Offer List</div>
      <div class="qb-offer-list-main-div">
        <div class="qb-form-div-search qb-form-search">
          <input
            #search
            class="qb-form-input"
            [(ngModel)]="filter.label"
            type="text"
            placeholder="Search by name"
            (input)="onKeySearch($event)"
          />
          <span
            class="material-icons-outlined icons-primary"
            *ngIf="filter.label.length == 0"
          >
            search
          </span>
          <span
            class="material-icons-outlined icons-primary cursor-pointer qb-close-icon"
            *ngIf="filter.label.length > 0"
            (click)="filter.label = ''; onKeySearch($event)"
          >
            close
          </span>
        </div>
        <div>
          <div
            class="filterButton button-hover-effect"
            (click)="openFilterDialog()"
            data-button-name="Filter"
          >
            <div style="display: flex">
              <span
                class="material-icons-outlined icons-primary"
                style="cursor: pointer"
                [matTooltipShowDelay]="200"
                [matTooltip]="'COMMON_PAGE.FILTERS' | i18nTranslate"
              >
                filter_alt
              </span>
            </div>
          </div>
        </div>
        <div *ngIf="selectedOfferListPageWise[page].length > 0">
          <div
            class="filterButton button-hover-effect"
            (click)="deleteSelectedOffer()"
            data-button-name="Delete"
          >
            <div style="display: flex">
              <span
                class="material-icons-outlined icons-primary"
                style="cursor: pointer"
                [matTooltipShowDelay]="200"
                [matTooltip]="'COMMON_PAGE.DELETE' | i18nTranslate"
              >
                delete
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="validWidgets | widgetConfig : 28"
      class="qb-widget-div qb-padding-header-padding"
    >
      <div>
        <div
          class="qb-offer-section-header qb-table-header"
          [style.padding-right.px]="rightMargin"
          id="tableHeads"
        >
          <div class="qb-column-heading-div-name">
            <mat-checkbox
              class="example-margin"
              [checked]="
                selectedOfferListPageWise[page].length === offerList.length &&
                offerList.length > 0
              "
              [indeterminate]="
                selectedOfferListPageWise[page].length > 0 &&
                selectedOfferListPageWise[page].length !== offerList.length
              "
              (change)="checkOfferList($event.checked, 'all', null)"
              color="primary"
            >
            </mat-checkbox>
            <span class="qb-offer-section-head-name">{{
              "OFFER_PAGE.OFFER_NAME" | i18nTranslate
            }}</span>
          </div>
          <div class="qb-column-heading-div qb-column-heading-channel">
            <span class="qb-offer-section-head qb-center">{{
              "OFFER_PAGE.CHANNEL" | i18nTranslate
            }}</span>
          </div>
          <div
            class="qb-column-heading-div qb-column-heading-segments-targeted"
          >
            <span class="qb-offer-section-head qb-word-wrapper">{{
              "OFFER_PAGE.SEGMENT_TIER_TARGET" | i18nTranslate
            }}</span>
          </div>
          <div class="qb-column-heading-status">
            <span class="qb-offer-section-head qb-center"
              >{{ "OFFER_PAGE.STATUS" | i18nTranslate }}
            </span>
          </div>
          <div class="qb-column-heading-status">
            <span class="qb-offer-section-head qb-center">Last Updated </span>
          </div>
          <div class="qb-column-heading-status">
            <span class="qb-offer-section-head qb-center">Actions</span>
          </div>
        </div>
      </div>
      <div class="qb-table-content" id="side-menu-offer-parent">
        <div
          class="qb-offer-list-div"
          *ngFor="let offer of offerList; let i = index"
          id="side-menu-offer"
        >
          <div
            *ngIf="
              mobileView &&
              (offer['isNoneBarrierDetected'] || offer['isBuyXGetYDetected'])
            "
            class="qb-mobile-view-offer-list"
          >
            <div class="qb-mobile-view-offer-details-div">
              <div
                class="qb-overflow"
                [ngClass]="{
                  'qb-disabled':
                    (offer['isNoneBarrierDetected'] ||
                      offer['isBuyXGetYDetected']) != 1
                }"
              >
                {{ offer["offerName"] }}
              </div>
              <div>
                <span
                  *ngIf="appSubscriptionPlan && offer['offerType'] === 'CUSTOM'"
                  class="qb-channel-dialog-content"
                  (click)="
                    offer['isNoneBarrierDetected'] ||
                    offer['isBuyXGetYDetected']
                      ? deleteOffer(offer['id'])
                      : null
                  "
                  data-widget-name="OFFER LISTING"
                  data-button-name="Delete Offer"
                  [attr.data-item-name]="
                    '(' + offer['id'] + ')' + offer['offerName']
                  "
                >
                  <span
                    class="material-icons-outlined icons-primary qb-delete-icon"
                    >delete</span
                  >
                </span>
              </div>
            </div>
            <div class="qb-channel-div">
              <div class="qb-flex">
                <div
                  class="material-icons-outlined icons-primary qb-channel-icon"
                  *ngIf="
                    offer['channel'] !== null && offer['channel'] === 'EMAIL'
                  "
                >
                  email
                </div>
                <div
                  class="material-icons-outlined icons-primary qb-channel-icon"
                  *ngIf="
                    offer['channel'] !== null &&
                    offer['channel'] === 'SMS' &&
                    (validScreens | screenConfig : 18)
                  "
                >
                  sms
                </div>
                <div
                  class="qb-offer-section-value qb-channel"
                  [ngClass]="{
                    disabled:
                      (offer['isNoneBarrierDetected'] ||
                        offer['isBuyXGetYDetected']) != 1
                  }"
                >
                  {{
                    offer["channel"]
                      ? offer["channel"].toLowerCase()
                      : ("No Channel" | titlecase)
                  }}
                </div>
              </div>
              <div class="qb-flex">
                <div
                  class="material-icons-outlined icons-primary qb-close-icon"
                >
                  person
                </div>
                <div>{{ offer["customerCount"] }}</div>
              </div>
            </div>
            <div class="qb-status-div">
              <span class="qb-status-text">{{
                "OFFER_PAGE.STATUS" | i18nTranslate
              }}</span>
              <div class="qb-status">
                <div
                  class="material-icons icons-primary qb-icon16-p qb-flag-icon"
                  [ngClass]="{
                    'qb-active':
                      offer['status'] === 'Sent' ||
                      (offer['status'] === 'Draft' &&
                        offer['channel'] !== null),
                    'qb-draft':
                      offer['status'] === 'Draft' && offer['channel'] === null,
                    'qb-scheduled': offer['status'] === 'Scheduled'
                  }"
                >
                  flag
                </div>
                <div
                  *ngIf="offer['status'] == 'Sent'"
                  class="qb-active qb-mr-r-25"
                  [ngClass]="{
                    'qb-disabled':
                      (offer['isNoneBarrierDetected'] ||
                        offer['isBuyXGetYDetected']) != 1
                  }"
                >
                  {{ "OFFER_PAGE.SENT" | i18nTranslate }}
                </div>
                <div
                  *ngIf="
                    offer['status'] == 'Draft' && offer['channel'] === null
                  "
                  class="qb-draft qb-mr-r-25"
                  [ngClass]="{
                    'qb-disabled':
                      (offer['isNoneBarrierDetected'] ||
                        offer['isBuyXGetYDetected']) != 1
                  }"
                >
                  {{ "OFFER_PAGE.DRAFT" | i18nTranslate }}
                </div>
                <div
                  *ngIf="offer['status'] == 'Draft' && offer['channel'] != null"
                  class="qb-draft qb-mr-r-25"
                  [ngClass]="{
                    'qb-disabled':
                      (offer['isNoneBarrierDetected'] ||
                        offer['isBuyXGetYDetected']) != 1
                  }"
                >
                  {{ "OFFER_PAGE.SAVED" | i18nTranslate }}
                </div>
                <div
                  *ngIf="offer['status'] == 'Scheduled'"
                  class="qb-scheduled qb-mr-r-25"
                  [ngClass]="{
                    'qb-disabled':
                      (offer['isNoneBarrierDetected'] ||
                        offer['isBuyXGetYDetected']) != 1
                  }"
                >
                  {{ "OFFER_PAGE.SCHEDULED" | i18nTranslate }}
                </div>
              </div>
            </div>
            <div>
              <span *ngIf="offer['status'] == 'Sent'" class="qb-offer-status">
                <span class="qb-status-sub-text">{{
                  "OFFER_PAGE.SENT_ON" | i18nTranslate
                }}</span>
                <span
                  class="qb-date-time"
                  [ngClass]="{
                    'qb-disabled':
                      (offer['isNoneBarrierDetected'] ||
                        offer['isBuyXGetYDetected']) != 1
                  }"
                  >{{
                    offer["scheduledDate"] | date : getDateFormat("MMM dd yyyy")
                  }}
                  | {{ offer["scheduledDate"] | customTime }}</span
                >
              </span>

              <span *ngIf="offer['status'] == 'Draft'" class="qb-offer-status">
                <span class="qb-status-sub-text">{{
                  "OFFER_PAGE.LAST_EDITED" | i18nTranslate
                }}</span>
                <span
                  class="qb-date-time"
                  [ngClass]="{
                    'qb-disabled':
                      (offer['isNoneBarrierDetected'] ||
                        offer['isBuyXGetYDetected']) != 1
                  }"
                >
                  {{
                    offer["lastModifiedDate"]
                      | date : getDateFormat("MMM dd yyyy")
                  }}
                  | {{ offer["lastModifiedDate"] | customTime }}
                </span>
              </span>
              <span
                *ngIf="offer['status'] == 'Scheduled'"
                class="qb-offer-status"
              >
                <span class="qb-status-sub-text">{{
                  "OFFER_PAGE.WILL_BE_SENT_ON" | i18nTranslate
                }}</span>
                <span
                  class="qb-date-time"
                  [ngClass]="{
                    'qb-disabled':
                      (offer['isNoneBarrierDetected'] ||
                        offer['isBuyXGetYDetected']) != 1
                  }"
                >
                  {{
                    offer["scheduledDate"] | date : getDateFormat("MMM dd yyyy")
                  }}
                  | {{ offer["scheduledDate"] | customTime }}
                </span>
              </span>
            </div>
          </div>
          <div
            class="qb-offer-section-body"
            *ngIf="!loading && !mobileView"
            id="a"
            [matTooltip]="
              !(offer['isNoneBarrierDetected'] || offer['isBuyXGetYDetected'])
                ? 'Barrier+Reward offers is disabled for this account'
                : null
            "
          >
            <div
              class="qb-offer-value qb-list-head-offer-name"
              [ngClass]="{
                'qb-no-click':
                  (offer['isNoneBarrierDetected'] ||
                    offer['isBuyXGetYDetected']) != 1
              }"
            >
              <div class="qb-d-flex qb-ai-center">
                <mat-checkbox
                  class="example-margin"
                  [checked]="checkIfPresent(offer['id'])"
                  (change)="
                    checkOfferList($event.checked, 'single', offer['id'])
                  "
                  color="primary"
                >
                </mat-checkbox>
              </div>
              <div>
                <span
                  class="qb-offer-section-offer-name"
                  [ngClass]="{
                    'qb-disabled':
                      (offer['isNoneBarrierDetected'] ||
                        offer['isBuyXGetYDetected']) != 1
                  }"
                  (click)="
                    previewOrEditOffer(
                      offer['status'],
                      offer['id'],
                      offer['isNoneBarrierDetected'],
                      offer['isBuyXGetYDetected'],
                      offer['adminCreated'],
                      offer?.createdBy?.qid
                    )
                  "
                  [matTooltip]="offer['offerName']"
                  matTooltipClass="ex-tooltip-red1"
                  data-button-name="Offer Name"
                  [attr.data-item-name]="
                    '(' + offer['id'] + ')' + offer['offerName']
                  "
                  >{{ offer["offerName"] | limitTo : 30 }}</span
                >
                <div
                  style="font-size: 10px"
                  class="mini-list-item"
                  [matTooltip]="
                  'Created by ' +  (offer?.createdBy?.userName 
                  ? offer.createdBy.userName + '\n' + (offer?.createdBy?.userEmail || '') 
                  : offer?.createdBy?.storeName || '')
                "
                matTooltipClass="custom-tooltip"
                >
                  <span class="material-symbols-outlined">person_edit</span>
                  {{
                    offer?.createdBy?.storeName
                      ? offer["createdBy"]["storeName"]
                      : ("-" | titlecase)
                  }}
                </div>
              </div>
            </div>
            <div
              class="qb-list-head-offer qb-offer-channel"
              [ngClass]="{
                'qb-no-click':
                  (offer['isNoneBarrierDetected'] ||
                    offer['isBuyXGetYDetected']) != 1
              }"
            >
              <span
                class="qb-offer-section-value"
                [ngClass]="{
                  'qb-disabled':
                    (offer['isNoneBarrierDetected'] ||
                      offer['isBuyXGetYDetected']) != 1
                }"
              >
                <div>
                  <span
                    [matTooltip]="getTooltipOffer('SMS')"
                    style="margin-right: 5px"
                    *ngIf="checkChannel('SMS', offer['channelList'])"
                  >
                    <img
                      src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/whatsapp/sms.svg"
                      width="15px"
                      height="15px"
                    />
                  </span>
                  <span
                    style="margin-right: 5px"
                    [matTooltip]="getTooltipOffer('Email')"
                    *ngIf="checkChannel('EMAIL', offer['channelList'])"
                  >
                    <img
                      src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/whatsapp/email.svg"
                      width="15px"
                      height="15px"
                    />
                  </span>
                  <span
                    [matTooltip]="getTooltipOffer('WhatsApp')"
                    style="margin-right: 5px"
                    *ngIf="checkChannel('WHATSAPP', offer['channelList'])"
                  >
                    <img
                      src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/whatsapp/whatsapp.svg"
                      width="15px"
                      height="15px"
                    />
                  </span>

                  <span
                    [matTooltip]="getTooltipOffer('Mobile')"
                    style="margin-right: 5px"
                    *ngIf="checkChannel('appOnly', offer['channelList'])"
                  >
                    <img
                      src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/icons/Mobile/phone_iphone_FILL0_wght400_GRAD0_opsz24.svg"
                      width="15px"
                      height="15px"
                    />
                  </span>

                  <span
                    [matTooltip]="getTooltipOffer('POS')"
                    style="margin-right: 5px"
                    *ngIf="checkChannel('POS', offer['channelList'])"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="15px"
                      viewBox="0 -960 960 960"
                      width="15px"
                      fill="#5f6368"
                    >
                      <path
                        d="M240-120v-80h200v-120H160q-33 0-56.5-23.5T80-400v-360q0-33 23.5-56.5T160-840h640q33 0 56.5 23.5T880-760v360q0 33-23.5 56.5T800-320H520v120h200v80H240Zm-80-280h640v-360H160v360Zm0 0v-360 360Z"
                      />
                    </svg>
                  </span>
                  <span
                    [matTooltip]="getTooltipOffer('Push Notification')"
                    style="margin-right: 5px"
                    *ngIf="
                      checkChannel('pushNotification', offer['channelList'])
                    "
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="20px"
                      viewBox="0 -960 960 960"
                      width="20px"
                      fill="#5f6368"
                    >
                      <path
                        d="M80-560q0-100 44.5-183.5T244-882l47 64q-60 44-95.5 111T160-560H80Zm720 0q0-80-35.5-147T669-818l47-64q75 55 119.5 138.5T880-560h-80ZM160-200v-80h80v-280q0-83 50-147.5T420-792v-28q0-25 17.5-42.5T480-880q25 0 42.5 17.5T540-820v28q80 20 130 84.5T720-560v280h80v80H160Zm320-300Zm0 420q-33 0-56.5-23.5T400-160h160q0 33-23.5 56.5T480-80ZM320-280h320v-280q0-66-47-113t-113-47q-66 0-113 47t-47 113v280Z"
                      />
                    </svg>
                  </span>

                  <span
                    *ngIf="
                      offer['channelList'] === null && offer['channel'] === null
                    "
                  >
                    -
                  </span>
                </div>
              </span>
            </div>
            <div
              class="qb-list-head-offer qb-segment-div"
              [ngClass]="{
                'qb-no-click':
                  (offer['isNoneBarrierDetected'] ||
                    offer['isBuyXGetYDetected']) != 1
              }"
            >
              <span
                class="qb-segment-value-div overflow-ellipsis-2"
                *ngIf="offer['segmentNames'] != null"
              >
                <span
                  class="qb-offer-section-value qb-no-padding"
                  [ngClass]="{
                    'qb-disabled':
                      (offer['isNoneBarrierDetected'] ||
                        offer['isBuyXGetYDetected']) != 1
                  }"
                  *ngFor="
                    let segment of offer['segmentNames'] | keyvalue;
                    last as islast
                  "
                  #segmentnamefield
                  >{{ segment.value }} <span *ngIf="!islast">,</span></span
                >
              </span>
              <mat-icon
                class="qb-double-arrow-icon"
                [matMenuTriggerFor]="menusegment"
                *ngIf="offer['segmentNames'] != null"
                >double_arrow
              </mat-icon>
              <mat-menu #menusegment="matMenu">
                <div class="qb-segment-value">
                  <span
                    *ngFor="
                      let segment of offer['segmentNames'] | keyvalue;
                      last as islastseg
                    "
                  >
                    {{ segment.value }}<span *ngIf="!islastseg">,</span></span
                  >
                </div>
              </mat-menu>
              <span
                *ngIf="
                  offer['segmentNames'] == null && offer['status'] == 'Draft'
                "
                class="qb-offer-section-value qb-no-padding"
                [ngClass]="{
                  'qb-disabled':
                    (offer['isNoneBarrierDetected'] ||
                      offer['isBuyXGetYDetected']) != 1
                }"
                >-</span
              >
              <span
                *ngIf="
                  (offer['isSegmented'] == false &&
                    offer['status'] != 'Draft' &&
                    offer['allCustomer']) ||
                  (offer['isSegmented'] == false &&
                    offer['status'] != 'Draft' &&
                    !offer['allCustomer'] &&
                    !offer['allLead'])
                "
                class="offerSectionValue qb-no-padding"
                [ngClass]="{
                  'qb-disabled':
                    (offer['isNoneBarrierDetected'] ||
                      offer['isBuyXGetYDetected']) != 1
                }"
                >{{ "OFFER_PAGE.ALL_CUSTOMERS" | i18nTranslate }}</span
              >
              <span
                *ngIf="offer['status'] != 'Draft' && offer['allLead']"
                class="offerSectionValue qb-no-padding"
                [ngClass]="{
                  'qb-disabled':
                    (offer['isNoneBarrierDetected'] ||
                      offer['isBuyXGetYDetected']) != 1
                }"
                >{{ "CAMPAIGN_PAGE.ALL_LEADS" | i18nTranslate }}</span
              >
            </div>
            <div
              class="qb-list-head-offer qb-list-head-offer-status"
              [ngClass]="{
                'no-click':
                  (offer['isNoneBarrierDetected'] ||
                    offer['isBuyXGetYDetected']) != 1
              }"
            >
              <div
                class="qb-flag-icon-div"
                [ngClass]="{
                  'qb-active':
                    offer['status'] === 'Sent' ||
                    checkIfSaved(
                      offer['status'],
                      offer['channelList'],
                      offer['isPersonalised'],
                      offer['channel']
                    ),
                  'qb-draft': checkIfDraft(
                    offer['status'],
                    offer['channelList'],
                    offer['isPersonalised'],
                    offer['channel']
                  ),
                  'qb-scheduled': offer['status'] === 'Scheduled'
                }"
              >
                <span
                  class="material-icons icons-primary qb-icon16-p"
                  style="display: none"
                  [ngClass]="{
                    'qb-active':
                      offer['status'] === 'Sent' ||
                      checkIfSaved(
                        offer['status'],
                        offer['channelList'],
                        offer['isPersonalised'],
                        offer['channel']
                      ),
                    'qb-draft': checkIfDraft(
                      offer['status'],
                      offer['channelList'],
                      offer['isPersonalised'],
                      offer['channel']
                    ),
                    'qb-scheduled': offer['status'] === 'Scheduled'
                  }"
                >
                  flag
                </span>
                <span
                  *ngIf="offer['status'] == 'Sent'"
                  class="qb-mr-r-25"
                  [ngClass]="{
                    'qb-disabled':
                      (offer['isNoneBarrierDetected'] ||
                        offer['isBuyXGetYDetected']) != 1
                  }"
                  >{{ "OFFER_PAGE.SENT" | i18nTranslate }}</span
                >
                <span
                  *ngIf="
                    checkIfDraft(
                      offer['status'],
                      offer['channelList'],
                      offer['isPersonalised'],
                      offer['channel']
                    )
                  "
                  class="qb-mr-r-25"
                  [ngClass]="{
                    'qb-disabled':
                      (offer['isNoneBarrierDetected'] ||
                        offer['isBuyXGetYDetected']) != 1
                  }"
                  >{{ "OFFER_PAGE.DRAFT" | i18nTranslate }}</span
                >
                <div
                  *ngIf="
                    checkIfSaved(
                      offer['status'],
                      offer['channelList'],
                      offer['isPersonalised'],
                      offer['channel']
                    )
                  "
                  class="qb-active qb-mr-r-25"
                  [ngClass]="{
                    'qb-disabled':
                      (offer['isNoneBarrierDetected'] ||
                        offer['isBuyXGetYDetected']) != 1
                  }"
                >
                  {{ "OFFER_PAGE.SAVED" | i18nTranslate }}
                </div>
                <span
                  *ngIf="offer['status'] == 'Scheduled'"
                  class="qb-scheduled qb-mr-r-25"
                  [ngClass]="{
                    'qb-disabled':
                      (offer['isNoneBarrierDetected'] ||
                        offer['isBuyXGetYDetected']) != 1
                  }"
                  >{{ "OFFER_PAGE.SCHEDULED" | i18nTranslate }}</span
                >
              </div>
            </div>
            <div class="qb-list-head-offer qb-list-last-updated">
              <span *ngIf="offer['status'] == 'Sent'" class="qb-column">
                <span class="qb-status-sub-text">{{
                  "OFFER_PAGE.SENT_ON" | i18nTranslate
                }}</span>
                <span
                  class="qb-sent-on-value"
                  [ngClass]="{
                    'qb-disabled':
                      (offer['isNoneBarrierDetected'] ||
                        offer['isBuyXGetYDetected']) != 1
                  }"
                >
                  {{
                    offer["scheduledDate"] | date : getDateFormat("MMM dd yyyy")
                  }}
                  | {{ offer["scheduledDate"] | customTime }}
                </span>
              </span>
              <span *ngIf="offer['status'] == 'Draft'" class="qb-column">
                <span class="qb-status-sub-text">{{
                  "OFFER_PAGE.LAST_EDITED" | i18nTranslate
                }}</span>
                <span
                  class="qb-sent-on-value"
                  [ngClass]="{
                    'qb-disabled':
                      (offer['isNoneBarrierDetected'] ||
                        offer['isBuyXGetYDetected']) != 1
                  }"
                >
                  {{
                    offer["lastModifiedDate"]
                      | date : getDateFormat("MMM dd yyyy")
                  }}
                  | {{ offer["lastModifiedDate"] | customTime }}
                </span>
              </span>
              <span *ngIf="offer['status'] == 'Scheduled'" class="qb-column">
                <span class="qb-status-sub-text">{{
                  "OFFER_PAGE.WILL_BE_SENT_ON" | i18nTranslate
                }}</span>
                <span
                  class="qb-sent-on-value"
                  [ngClass]="{
                    'qb-disabled':
                      (offer['isNoneBarrierDetected'] ||
                        offer['isBuyXGetYDetected']) != 1
                  }"
                >
                  {{
                    offer["scheduledDate"] | date : getDateFormat("MMM dd yyyy")
                  }}
                  | {{ offer["scheduledDate"] | customTime }}
                </span>
              </span>
            </div>
            <div
              class="qb-list-head-offer qb-more-icon-div"
              [ngClass]="{
                'qb-no-click':
                  (offer['isNoneBarrierDetected'] ||
                    offer['isBuyXGetYDetected']) != 1
              }"
            >
              <svg
                *ngIf="appSubscriptionPlan"
                [matTooltip]="'CAMPAIGN_PAGE.EDIT' | i18nTranslate"
                class="cursor-pointer"
                [ngClass]="{
                  'disabled-icon': offer['status'] != 'Draft'
                }"
                (click)="
                offer['status'] == 'Draft'
                  ? editOffer(
                      offer['id'],
                      offer['adminCreated'],
                      offer?.createdBy?.qid
                    )
                  : null
              "
                width="18"
                height="16"
                viewBox="0 0 18 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.5625 10.7812L13.5625 9.78125C13.6458 9.69792 13.7396 9.67708 13.8438 9.71875C13.9479 9.76042 14 9.84375 14 9.96875V14.5C14 14.9167 13.8542 15.2708 13.5625 15.5625C13.2708 15.8542 12.9167 16 12.5 16H1.5C1.08333 16 0.729167 15.8542 0.4375 15.5625C0.145833 15.2708 0 14.9167 0 14.5V3.5C0 3.08333 0.145833 2.72917 0.4375 2.4375C0.729167 2.14583 1.08333 2 1.5 2H10.0625C10.1667 2 10.2396 2.05208 10.2812 2.15625C10.3229 2.26042 10.3021 2.35417 10.2188 2.4375L9.21875 3.4375C9.17708 3.47917 9.125 3.5 9.0625 3.5H1.5V14.5H12.5V10.9375C12.5 10.875 12.5208 10.8229 12.5625 10.7812ZM17.4688 4.46875L9.25 12.6875L6.4375 13C6.02083 13.0417 5.66667 12.9167 5.375 12.625C5.08333 12.3333 4.95833 11.9792 5 11.5625L5.3125 8.75L13.5312 0.53125C13.8854 0.177083 14.3125 0 14.8125 0C15.3125 0 15.75 0.177083 16.125 0.53125L17.4688 1.875C17.8229 2.22917 18 2.66667 18 3.1875C18 3.6875 17.8229 4.11458 17.4688 4.46875ZM14.375 5.4375L12.5625 3.625L6.75 9.4375L6.53125 11.4688L8.5625 11.25L14.375 5.4375ZM16.4062 2.9375L15.0625 1.59375C14.8958 1.44792 14.7396 1.44792 14.5938 1.59375L13.625 2.5625L15.4375 4.375L16.4062 3.40625C16.5521 3.26042 16.5521 3.10417 16.4062 2.9375Z"
                  fill="#4379EF"
                />
              </svg>
              <!-- <svg
                *ngIf="appSubscriptionPlan && offer['status'] != 'Draft'"
                class="cursor-pointer"
                xmlns="http://www.w3.org/2000/svg"
                [matTooltip]="'OFFER_PAGE.RESCHEDULE' | i18nTranslate"
                height="20"
                viewBox="0 -960 960 960"
                width="20"
                fill="#4379EF"
                (click)="openReScheduleOffer(offer['id'], offer['channel'])"
              >
                <path
                  d="M200-80q-33 0-56.5-23.5T120-160v-560q0-33 23.5-56.5T200-800h40v-80h80v80h320v-80h80v80h40q33 0 56.5 23.5T840-720v240h-80v-80H200v400h280v80H200ZM760 0q-73 0-127.5-45.5T564-160h62q13 44 49.5 72T760-60q58 0 99-41t41-99q0-58-41-99t-99-41q-29 0-54 10.5T662-300h58v60H560v-160h60v57q27-26 63-41.5t77-15.5q83 0 141.5 58.5T960-200q0 83-58.5 141.5T760 0ZM200-640h560v-80H200v80Zm0 0v-80 80Z"
                />
              </svg> -->
              <svg
                *ngIf="
                  appSubscriptionPlan &&
                  (offer['isNoneBarrierDetected'] ||
                    offer['isBuyXGetYDetected'])
                "
                [matTooltip]="'CAMPAIGN_PAGE.CLONE' | i18nTranslate"
                class="cursor-pointer"
                (click)="
                  cloneOffer(
                    offer['id'],
                    offer['adminCreated'],
                    offer?.createdBy?.qid
                  )
                "
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.5 0C14.9167 0 15.2708 0.145833 15.5625 0.4375C15.8542 0.729167 16 1.08333 16 1.5V11.5C16 11.9167 15.8542 12.2708 15.5625 12.5625C15.2708 12.8542 14.9167 13 14.5 13H13V14.5C13 14.9167 12.8542 15.2708 12.5625 15.5625C12.2708 15.8542 11.9167 16 11.5 16H1.5C1.08333 16 0.729167 15.8542 0.4375 15.5625C0.145833 15.2708 0 14.9167 0 14.5V4.5C0 4.08333 0.145833 3.72917 0.4375 3.4375C0.729167 3.14583 1.08333 3 1.5 3H3V1.5C3 1.08333 3.14583 0.729167 3.4375 0.4375C3.72917 0.145833 4.08333 0 4.5 0H14.5ZM11.3125 14.5C11.4375 14.5 11.5 14.4375 11.5 14.3125V13H4.5C4.08333 13 3.72917 12.8542 3.4375 12.5625C3.14583 12.2708 3 11.9167 3 11.5V4.5H1.6875C1.5625 4.5 1.5 4.5625 1.5 4.6875V14.3125C1.5 14.4375 1.5625 14.5 1.6875 14.5H11.3125ZM14.3125 11.5C14.4375 11.5 14.5 11.4375 14.5 11.3125V1.6875C14.5 1.5625 14.4375 1.5 14.3125 1.5H4.6875C4.5625 1.5 4.5 1.5625 4.5 1.6875V11.3125C4.5 11.4375 4.5625 11.5 4.6875 11.5H14.3125Z"
                  fill="#4379EF"
                />
              </svg>
              <svg
                *ngIf="appSubscriptionPlan && offer['offerType'] === 'CUSTOM'"
                [matTooltip]="'CAMPAIGN_PAGE.DELETE' | i18nTranslate"
                class="cursor-pointer"
                (click)="
                  deleteOffer(
                    offer['id'],
                    offer?.createdBy?.qid,
                    offer?.createdBy?.storeName
                  )
                "
                width="14"
                height="16"
                viewBox="0 0 14 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.5 2.5C13.6458 2.5 13.7604 2.55208 13.8438 2.65625C13.9479 2.73958 14 2.85417 14 3V3.5C14 3.64583 13.9479 3.77083 13.8438 3.875C13.7604 3.95833 13.6458 4 13.5 4H13L12.3438 14.5938C12.3229 14.9896 12.1667 15.3229 11.875 15.5938C11.5833 15.8646 11.2396 16 10.8438 16H3.15625C2.76042 16 2.41667 15.8646 2.125 15.5938C1.83333 15.3229 1.67708 14.9896 1.65625 14.5938L1 4H0.5C0.354167 4 0.229167 3.95833 0.125 3.875C0.0416667 3.77083 0 3.64583 0 3.5V3C0 2.85417 0.0416667 2.73958 0.125 2.65625C0.229167 2.55208 0.354167 2.5 0.5 2.5H3.0625L4.125 0.71875C4.41667 0.239583 4.85417 0 5.4375 0H8.5625C9.14583 0 9.58333 0.239583 9.875 0.71875L10.9375 2.5H13.5ZM5.4375 1.5L4.8125 2.5H9.1875L8.5625 1.5H5.4375ZM10.8438 14.5L11.5 4H2.5L3.15625 14.5H10.8438Z"
                  fill="#4379EF"
                />
              </svg>
            </div>
          </div>
        </div>
        <div *ngIf="loading && !mobileView" class="qb-spinner">
          <mat-spinner diameter="22"></mat-spinner>
        </div>
        <div *ngIf="offerList.length == 0 && !loading">
          <div class="qb-no-data">
            <div class="qb-empty-customers-div">
              {{ "OFFER_PAGE.NO_DATA_AVAILABLE" | i18nTranslate }}
            </div>
          </div>
        </div>
      </div>
      <div class="qb-paginate-div">
        <mat-paginator
          #paginator
          [pageIndex]="0"
          [length]="totalOfferCount"
          [pageSize]="size"
          [pageSizeOptions]="[5, 10, 25, 100]"
          (page)="getNextData($event)"
        >
        </mat-paginator>
      </div>
    </div>
  </div>
</div>
<div id="overlay"></div>
<div class="qb-config-div" id="configDiv">
  <app-offer-dispatch-direct
    class="qb-config"
    (messageEvent2)="receiveMessage($event)"
    [offerId]="selectedOfferId"
    [channel]="selectedChannel"
    *ngIf="openReScheduleOverlay"
  >
  </app-offer-dispatch-direct>
</div>
