import { DatePipe, getCurrencySymbol } from "@angular/common";
import {
  Component,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { TokenStorageService } from "src/app/core/auth/token-storage.service";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { SnackbarCollection } from "src/app/core/services/snackbar.service";
import { WidgetConfigService } from "src/app/core/services/widget-config.service";
import { environment } from "src/environments/environment";
import {
  ContactUsDialogComponent,
  CollectionListDialogComponent,
  ProductListDialogComponent,
  OfferChannelAddConfirmationDialogComponent,
} from "src/app/shared/components/dialog-box";
import { OfferCreateChatGptDialogBoxComponent } from "src/app/shared/components/dialog-box/offer-create-chat-gpt-dialog-box/offer-create-chat-gpt-dialog-box.component";
import { TranslateDynamicText } from "src/app/shared/pipes/dynamic-translation.pipe";
import { FormControl, Validators } from "@angular/forms";
import { getdateformatService } from "src/app/core/services/get-date-format.service";
import { NotDeletedListDialogBoxComponent } from "src/app/shared/components/dialog-box";

@Component({
  selector: "app-offer-creation-simplified",
  templateUrl: "./offer-creation-simplified.component.html",
  styleUrls: ["./offer-creation-simplified.component.css"],
})
export class OfferCreationSimplifiedComponent implements OnInit {
  @Input() popUpMode: boolean = false;
  @Output() messageEvent = new EventEmitter<any>();
  @ViewChild("redeemableAtStoresSelect") redeemableAtStoresSelect: any;
  @ViewChild("offerBudgetComp") offerBudgetComp: any;
  submitClicked = false;
  offerTemplateName = "";
  offerDiscountCode = "";
  islimitNoOfTry = false;
  noOfTry = 1;
  customDescription = "";
  bulletCustomDescription = [];
  customTermsAndCondition = "";
  bulletCustomTermsAndCondition = [];
  islimitToOneUsePerCustomer = false;
  previewmode = false;
  adminMode = false;
  managerAdmin = false;
  validWidgets;
  saveAndScheduleButtonLoading = false;
  saveAndExitButtonLoading = false;
  noneBarrierCount = 0;
  buyXGetYCount = 0;
  productCategory = null;
  hurdleRewardProductCategory = null;
  message = "";
  //discountType="";
  hurdleProduct = null;
  hurdleRewardType = "PERCENTAGE_OFF";
  typeOfDiscountOn = [
    {
      displayName: "Cart",
      value: "CART",
    },
    {
      displayName: "Delivery Charge",
      value: "DELIVERYCHARGE",
    },
    {
      displayName: "Cart & Delivery",
      value: "CARTANDDELIVERY",
    },
  ];
  typeOfOffer= [
    {
      displayName: "Continous",
      value: "CONTINOUS",
    },
    {
      displayName: "Recurring",
      value: "RECCURRING",
    },
  ];
  allOfferTypeObj = {
    displayName: "Select All",
    value: "ALL",
  };
  typeOfOrderType = [
    {
      displayName: "Dine In",
      value: "DINEIN",
      applicableFor: "CART",
    },
    {
      displayName: "Takeaway",
      value: "TAKEAWAY",
      applicableFor: "CART",
    },
    {
      displayName: "Delivery",
      value: "DELIVERY",
      applicableFor: "ALL",
    },
    {
      displayName: "Drive-through",
      value: "DRIVETHRU",
      applicableFor: "CART",
    },
  ];
  orderType = new FormControl([]);
  offerTimeType = "CONTINOUS";
  discountOn = "CART";
  percentageOff;
  amountOff = null;
  currencySymbol;
  revCurrency;
  // hurdleProductInnerType = "ANY_PRODUCT";
  barrierType = "PRODUCT_BASED";
  barrierParamType = "NON_PERSONALIZED";
  rewardPersonalizationType = "NON_PERSONALIZED";
  rewardPropensity = "LOW";
  rewardMinReqs = "NONE";
  rewardMinReqsAmount = null;
  rewardMinReqsQuantity = null;
  rewardTimeToComplete = null;
  productCategoryType = null;
  fullproductListLength = null;
  fullproductCategoryListLength = null;
  productType;
  offerReady = false;
  readyToBuild = false;
  fieldsetDisabled = "false";
  apiProductList = [];
  masterOffer;
  queryparamofferId = null;
  showofferTemplateSkeleton = false;

  hurdleProductCount = null;
  hurdleProductVariant = null;
  hurdleProductCategory = null;
  rewardProductVariant = null;
  hurdleRewardProduct = null;
  hurdleRewardProductCount = null;
  amountOrPercentDiscount = null;
  discountAndShippingType = "ORDER";
  freeShippingType = "NONE";
  buyXGetYDiscount = null;
  freeShippingDiscount = null;
  product = null;
  barrierListList = null;
  rewardListList = null;
  freeShippingMinimumAmount = null;
  freeShippingMinimumOrderCount = null;
  productPropensity = "LOW";
  hurdleTimeToComplete = null;
  hurdleRewardToggle = false;
  hurdleSpentInnerType = "AMOUNT";
  rewardTemplate = null;
  rewardSavedId = null;
  rewardSMSSavedId = null;
  smsTemplateId = null;
  rewardsmsName = null;
  smsTemplateName = null;
  channel = null;
  communication = null;
  offerMaster;
  isBuyXGetYDetected = false;
  // loadingblock = null;
  oneTimeSaved: boolean = false;
  // responseLoading = null;
  oneTimeSavedId = null;
  dispatchRecAction = null;
  saveActionType = null;
  openScheduleOverlay = false;
  offerReqBodyObj;
  offerJson;
  offerMasterUpdate;
  importedData;
  BarrierRewardFull = null;
  noneBarrierDetected = true;
  hurdleProductInnerType = "CATEGORY";
  buyXGetYType = "COLLECTION";
  hurdleSpentAmount = null;
  atADiscountedValue = "PERCENTAGE_OFF";
  rewardpercentageOff = null;
  rewardAmountOff = null;
  atBuyxGetYProducts = "";
  atBuyxGetYCollections = "";
  atdiscountAndPercentageOffProducts = "";
  atdiscountAndPercentageOffCollections = "";
  hurdleAtAnyTime = true;
  hurdleEverydayOfTheWeek = true;
  adminCreated = {
    storeId: null,
    storeName: "ADMIN",
    qid: null,
    userName: this.tokenStorage.getUsernameFull(),
    userEmail: this.tokenStorage.getMerchantUserEmail()
  };
  storeDataJSON = {};
  @ViewChild("rewardPropensityselect") rewardPropensityselect: any;
  @ViewChild("rewardPropensityselect2") rewardPropensityselect2: any;
  @ViewChild("productPropensityselect") productPropensityselect: any;
  @ViewChild("orderTypeDropDown") orderTypeDropDown: any;
  selectedRewardProductList = [];
  selectedRewardCollectionList = [];
  selectedBarrierCollectionList = [];
  selectedBarrierProductList = [];
  selectedRewardExcludedProductList = [];
  selectedBarrierExcludedProductList = [];
  selectedRewardExcludedCategoryList = [];
  selectedBarrierExcludedCategoryList = [];
  globalExclusionList = [];
  timeout;
  previousData = "";
  sub: Subscription;
  iscouponcodeCheckLoading = false;
  couponCodeVerified = true;
  couponCodeMsg = "";
  couponcodeCheckInitialised = false;
  offerNameCheckInitialised = false;
  pos = "";
  shop;
  storeData = [];
  allowedAccount = [];
  email;
  isSpecialOffer = false;
  isGiveAway = false;
  isHiddenItemOffer = false;
  toApplyOnMultipleProduct = false;
  isPersonalised = false;
  isLImitedTime: boolean = false;
  anyTimeSliderValue: boolean = false;
  everyDaySliderValue: boolean = false;
  weekDaysChips: any[] = [
    { name: "SUN", selected: false },
    { name: "MON", selected: false },
    { name: "TUE", selected: false },
    { name: "WED", selected: false },
    { name: "THU", selected: false },
    { name: "FRI", selected: false },
    { name: "SAT", selected: false },
  ];
  arrayDynamic = [];
  startDateMinValue;
  endDateMinValue;
  startMaxTime;
  startMinTime;
  endMinTime;
  serviceCountry = sessionStorage.getItem("serviceCountry");
  qid = this.tokenStorage.getqId();
  startDate = new FormControl([Validators.required]);
  endDate = new FormControl([Validators.required]);

  startTime = new FormControl();
  endTime = new FormControl();
  redeemableAtStores = new FormControl([]);
  isAdminPos = false;
  previousStoreIdList = [];
  previousOrderTypeList = [];
  valueTypes = ['FIXED_AMOUNT_OFF', 'PERCENTAGE_OFF', 'PERCENTAGE_REWARD_COINS_GAIN', 'FIXED_REWARD_COINS_GAIN', 'BURN_LOYALTY_POINTS', 'FIXED_CASHBACK', 'PERCENTAGE_CASHBACK'];
  percentageOffTypes = ['PERCENTAGE_OFF', 'PERCENTAGE_REWARD_COINS_GAIN', 'PERCENTAGE_CASHBACK'];
  amountTypes = ['FIXED_AMOUNT_OFF', 'FIXED_REWARD_COINS_GAIN', 'BURN_LOYALTY_POINTS', 'FIXED_CASHBACK', "NONE"];
  hasBudget = false;
  budgetEntity = null;

  constructor(
    public dialog: MatDialog,
    private apiCall: ApiCallService,
    private snackBar: SnackbarCollection,
    private route: ActivatedRoute,
    private tokenStorage: TokenStorageService,
    private router: Router,
    private _WidgetConfigService: WidgetConfigService,
    private _i18nDynamicTranslate: TranslateDynamicText,
    private date_format: getdateformatService,
    private datePipe: DatePipe
  ) {
    window["screen-name"] = "OFFER CREATE";
  }
  async getWidgets() {
    this.validWidgets = await this._WidgetConfigService.getWidget();
  }
  async ngOnInit() {
    this.email = this.tokenStorage.getUserEmail();
    await this.setDefaultValues();
    this.allowedAccount = environment.demoAccounts;
    if (this.tokenStorage.getqId() !== "null") {
      this.getParticularStore();
      this.getParticularStoreJSON();
      this.isAdminPos = false;
      this.redeemableAtStores.disable();
    } else {
      this.isAdminPos = true;
      this.redeemableAtStores.enable();
    }
    this.getStores();
    this.selectOrderTypeDropDown("ALL");
    this.pos = this.tokenStorage.getMerchantOnlineStore();
    this.getWidgets();
    this.revCurrency = sessionStorage.getItem("serviceCurrency");
    this.currencySymbol = getCurrencySymbol(this.revCurrency, "narrow");
    this.shop = this.tokenStorage.getMerchantOnlineStore();
    this.queryparamofferId = this.route.snapshot.queryParams["id"]
      ? this.route.snapshot.queryParams["id"]
      : null;
    this.queryparamofferId = this.route.snapshot.paramMap.get("id")
      ? this.route.snapshot.paramMap.get("id")
      : this.queryparamofferId;
    if (this.route.snapshot.paramMap.get("id")) {
      this.getOfferDetails(this.route.snapshot.paramMap.get("id"));
    } else {
      this.setConstants("creation");
      this.getDefaultExclusion();
    } // Print the parameter to the console.
    this.removeScrollinImput();
  }

  setDefaultValues() {
    let currentDate = new Date();
    this.startDateMinValue = new Date(
      currentDate.toLocaleString("en-US", {
        timeZone: this.tokenStorage.getMerchantTimeZone(),
      })
    );
    let hh = this.startDateMinValue.getHours();
    let mm = this.startDateMinValue.getMinutes();
    // Increment hour and minute properly, handling overflow
    hh = (hh + 1) % 24; // Ensure hours wrap around correctly
    let mmIncremented = (mm + 1) % 60; // Increment minutes and wrap around

    // Format hours and minutes
    hh = hh < 10 ? "0" + hh : hh;
    mm = mm < 10 ? "0" + mm : mm;
    let endhh = hh;
    let endmm = mmIncremented < 10 ? "0" + mmIncremented : mmIncremented;

    // Adjust hour if minute overflowed
    if (mmIncremented === 0) {
      endhh = (parseInt(endhh) + 1) % 24;
      endhh = endhh < 10 ? "0" + endhh : endhh;
    }

    this.startTime.setValue(`${hh}:${mm}`);
    this.endTime.setValue(`${endhh}:${endmm}`);
    this.endDateMinValue = new Date(
      currentDate.toLocaleString("en-US", {
        timeZone: this.tokenStorage.getMerchantTimeZone(),
      })
    );
    this.endDateMinValue.setHours(0, 0, 0, 0);
    this.startMaxTime = "23:58";

    this.startMinTime = this.addMinutes(
      this.startDateMinValue.toTimeString(),
      60
    );
    // this.endMinTime = this.addMinutes(this.startMinTime, 1);
    let startDate = new Date(this.startDate.value);
    startDate.setHours(0);
    startDate.setMinutes(0);
    startDate.setSeconds(0);
    startDate.setMilliseconds(0);
    let endDate = new Date(this.endDate.value);
    endDate.setHours(0);
    endDate.setMinutes(0);
    endDate.setSeconds(0);
    endDate.setMilliseconds(0);
    if (
      startDate.getTime() === currentDate.getTime() &&
      endDate.getTime() === currentDate.getTime()
    ) {
      this.startMinTime = this.addMinutes(
        this.startDateMinValue.toTimeString(),
        60
      );
      this.endMinTime = this.addMinutes(this.startMinTime, 1);
    } else {
      this.endMinTime = "00:01";
    }
    this.startDate.setValue(this.startDateMinValue);
    this.endDateMinValue.setDate(currentDate.getDate());
    this.endDate.setValue(
      new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)
    );
  }

  setConstants(operation) {
    this.productCategoryType = [];
    this.productType = [];
    this.offerReady = false;
    this.apiCall.getConstantProducts().subscribe(
      (response) => {
        if (response["message"] === "success") {
          let result: any = response["body"];
          result = JSON.parse(result);

          // this.apiCall
          //   .getOfferManagementProductsList("", 1, 50)
          //   .subscribe((resultList) => {
          // this.apiProductList = JSON.parse(resultList["body"]);
          // this.fullproductListLength = this.apiProductList.length;
          for (let i = 0; i < result["productCategories"].length; i++) {
            if (
              this.productCategoryType.indexOf(
                result["productCategories"][i] === -1
              )
            ) {
              this.productCategoryType.push({
                collectionName:
                  result["productCategories"][i].productCategoryName,
                collectionId: result["productCategories"][i].productCategoryId,
                subCategory: result["productCategories"][i].subCategory,
              });
            }
          }

          this.fullproductCategoryListLength = this.productCategoryType.length;

          if (1 === 1) {
            if (operation === "updation") {
              if (this.masterOffer["id"] == this.queryparamofferId) {
                this.offerTemplateName = this.route.snapshot.paramMap.get(
                  "clone"
                )
                  ? this.setOfferNameAndCheck()
                  : this.masterOffer["offerName"];
              }
              this.addFilledForm(this.offerMasterUpdate);
            } else {
              this.offerTemplateName = this.masterOffer["offerName"];
            }
            this.readyToBuild = true;
            this.offerReady = true;
            this.showofferTemplateSkeleton = true;
          } else {
            //this.snackBar.openSnackBar("Error:No product or category", 2000);
            const dialogRef = this.dialog.open(ContactUsDialogComponent, {
              panelClass: "no-padding-dialog-popup",
              height: "auto",
              disableClose: true,
              width: "380px",
              data: {
                header: this._i18nDynamicTranslate.transform("Error", [
                  "POS_PAGE",
                ]),
                body: this._i18nDynamicTranslate.transform(
                  "No product or category present in the shop",
                  ["POS_PAGE"]
                ),
              },
            });
            dialogRef.afterClosed().subscribe((result) => {
              this.router.navigate(["app/offer/choose-offer"]);
              return;

              // this.disabled = false;
            });
          }
          // });
          // let result: any = response["body"];
          // for (let i = 0; i < result["productCategories"].length; i++) {
          //   this.productCategoryType.push({
          //     name: result["productCategories"][0].productCategoryName,
          //     id: result["productCategories"][0].productCategoryId,
          //   });
          // }
          // for (let i = 0; i < result["products"].length; i++) {
          //   this.productType.push({
          //     name: result["products"][0].productName,
          //     id: result["products"][0].productId,
          //   });
          // }
        } else {
          this.snackBar.openSnackBar(
            this._i18nDynamicTranslate.transform("Error loading", ["POS_PAGE"]),
            2000
          );
          this.offerReady = true;
          this.showofferTemplateSkeleton = false;
        }
      },
      (error) => {
        this.snackBar.openSnackBar(
          this._i18nDynamicTranslate.transform(error.error.message, [
            "POS_PAGE",
          ]),
          2000
        );
        this.offerReady = true;
        this.showofferTemplateSkeleton = false;
      }
    );
  }
  setOfferNameAndCheck() {
    this.offerTemplateName = this.addCopySuffix(this.masterOffer["offerName"]);
    this.onKeyOfferNameCheck(null);
    return this.offerTemplateName;
  }

  addCopySuffix(name: string): string {
    const maxLength = 100;
    const suffix = "_copy";
    const maxBaseNameLength = maxLength - suffix.length;
    if (name.length <= maxBaseNameLength) {
      return name + suffix;
    }
    const trimmedBaseName = name.slice(0, maxBaseNameLength).trim();
    return trimmedBaseName + suffix;
  }

  toggleAIassistanceBarrier(event) {
    event.checked == false
      ? (this.barrierParamType = "NON_PERSONALIZED")
      : (this.barrierParamType = "PERSONALIZED");
  }
  toggleAIassistanceReward(event) {
    if (event.checked) {
      this.rewardPersonalizationType = "PERSONALIZED";
      this.isPersonalised = true;
    } else {
      this.rewardPersonalizationType = "NON_PERSONALIZED";
      this.isPersonalised = false;
    }
    if (
      this.rewardPersonalizationType == "PERSONALIZED" &&
      (this.hurdleRewardType === "PERCENTAGE_OFF" ||
        this.hurdleRewardType === "FIXED_AMOUNT_OFF")

      //  && this.discountAndShippingType === "ORDER"
    ) {
      //  this.discountAndShippingType = null;
    }
  }

  toggleOffAiAssistance() {
    this.rewardPersonalizationType = "NON_PERSONALIZED";
    this.isPersonalised = false;
  }

  toggelLimitedTimeOffer(event) {
    if (event.checked) {
      this.communication = null;
      this.isLImitedTime = true;
    } else {
      this.isLImitedTime = false;
    }
  }

  selectopenclose(value) {
    switch (value) {
      case "rewardPropensityselect":
        this.rewardPropensityselect.open();
        break;
      case "rewardPropensityselect2":
        this.rewardPropensityselect2.open();
        break;
      case "productPropensityselect":
        this.productPropensityselect.open();
        break;
      case "redeemableAtStoresSelect":
        this.dropDownSearch = "";
        this.redeemableAtStoresSelect.open();
        break;
      case "orderTypeDropDown":
        this.dropDownSearch = "";
        this.orderTypeDropDown.open();
        break;
    }
  }

  hurdleRewardTypeRadioChange(value) {
    if(value === 'NONE'){
      this.amountOff = 0;
    }
    if (value === "BUY_X_GET_Y") {
      //  this.barrierParamType = "NONE";
      // this.barrierType = "NONE";
      this.rewardPersonalizationType = "NON_PERSONALIZED";
    } else {
      this.barrierParamType = "NON_PERSONALIZED";
      this.isPersonalised = false;

      this.barrierType = "PRODUCT_BASED";
    }
    if (value === "BURN_LOYALTY_POINTS") {
      this.discountAndShippingType = "COLLECTION";
    }
    this.selectedBarrierExcludedProductList = this.globalExclusionList[
      "excludedProductList"
    ]
      ? this.globalExclusionList["excludedProductList"]
      : [];
    this.selectedRewardExcludedProductList = this.globalExclusionList[
      "excludedProductList"
    ]
      ? this.globalExclusionList["excludedProductList"]
      : [];
    this.selectedRewardExcludedCategoryList = this.globalExclusionList[
      "excludedCategoryList"
    ]
      ? this.globalExclusionList["excludedCategoryList"]
      : [];
    this.selectedRewardCollectionList = [];
    this.selectedBarrierCollectionList = [];
    this.selectedBarrierProductList = [];
    this.selectedBarrierProductList = [];
  }

  browseBarrier(list, searchText?) {
    let name;
    let dataOption;
    if (list != "PRODUCT") {
      name = CollectionListDialogComponent;
      dataOption = {
        allCollection: this.productCategoryType,
        selectedCollectionList: this.selectedBarrierCollectionList,
        searchString: searchText ? searchText : null,
      };
    } else {
      name = ProductListDialogComponent;
      dataOption = {
        totalProducts: this.productType,
        selectedProductList: this.selectedBarrierProductList,
        section: "BARRIER",
        listType: this.hurdleProductInnerType,
        searchString: searchText ? searchText : null,
        pos: this.pos,
        selectedCategory: null,
        actionType: null,
        isHiddenItemOffer: this.isHiddenItemOffer
      };
    }
    const dialogRef = this.dialog.open(name, {
      panelClass: "o-item-dialog",
      height: "440px",
      width: "540px",
      data: dataOption,
      maxHeight: "80vh",
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
        this.hurdleProductInnerType == "PRODUCT"
          ? (this.selectedBarrierProductList = result.variantIdArray)
          : (this.selectedBarrierCollectionList = result.variantIdArray);

        // this.selectedRewardProductList = result.variantIdArray;
      }
      this.clearTempFilters();
      // this.productType=result;
      // this.disabled = false;
    });
  }
  browseReward(type, list, searchText?) {
    let name;
    let dataOption;
    if (list != "PRODUCT") {
      name = CollectionListDialogComponent;
      dataOption = {
        allCollection: this.productCategoryType,
        selectedCollectionList: this.selectedRewardCollectionList,
        searchString: searchText ? searchText : null,
      };
    } else {
      name = ProductListDialogComponent;
      dataOption = {
        totalProducts: this.productType,
        selectedProductList: this.selectedRewardProductList,
        section: "REWARD",
        listType: list,
        searchString: searchText ? searchText : null,
        selectedCategory: null,
        actionType: null,
        isHiddenItemOffer: this.isHiddenItemOffer
      };
    }
    const dialogRef = this.dialog.open(name, {
      panelClass: "o-item-dialog",
      height: "440px",
      width: "540px",
      data: dataOption,
      maxHeight: "80vh",
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
        list == "PRODUCT"
          ? (this.selectedRewardProductList = result.variantIdArray)
          : (this.selectedRewardCollectionList = result.variantIdArray);

        // this.selectedRewardProductList = result.variantIdArray;
      }
      // this.productType=result;
      // this.disabled = false;
      this.clearTempFilters();
    });
  }

  browseProducts(list, section, type) {
    let name = ProductListDialogComponent;
    let dataOption;
    if (list == "ORDER") {
      dataOption = {
        totalProducts: this.productType,
        selectedProductList:
          section == "REWARD"
            ? this.selectedRewardExcludedProductList
            : this.selectedBarrierExcludedProductList,
        section: section,
        listType: list,
        searchString: null,
        selectedCategory: null,
        actionType: "EXCLUSION",
      };
    } else {
      dataOption = {
        totalProducts: this.productType,
        selectedProductList:
          section == "REWARD"
            ? this.selectedRewardExcludedProductList
            : this.selectedBarrierExcludedProductList,
        section: section,
        listType: list,
        searchString: null,
        selectedCategory:
          type === "NON_PERSONALIZED"
            ? section == "REWARD"
              ? this.selectedRewardCollectionList
              : this.selectedBarrierCollectionList
            : null,
        actionType: "EXCLUSION",
      };
    }
    const dialogRef = this.dialog.open(name, {
      panelClass: "o-item-dialog",
      height: "440px",
      width: "540px",
      data: dataOption,
      maxHeight: "80vh",
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
        section == "REWARD"
          ? (this.selectedRewardExcludedProductList = result.variantIdArray)
          : (this.selectedBarrierExcludedProductList = result.variantIdArray);

        // this.selectedRewardProductList = result.variantIdArray;
      }
      // this.productType=result;
      // this.disabled = false;
      // this.clearTempFilters();
    });
  }

  browseCategories(section) {
    let name = CollectionListDialogComponent;
    let dataOption;
    dataOption = {
      allCollection: this.productCategoryType,
      selectedCollectionList:
        section == "REWARD"
          ? this.getCategoryFormat(this.selectedRewardExcludedCategoryList)
          : this.selectedBarrierExcludedCategoryList,
      searchString: null,
      actionType: "EXCLUSION",
    };
    const dialogRef = this.dialog.open(name, {
      panelClass: "o-item-dialog",
      height: "440px",
      width: "540px",
      data: dataOption,
      maxHeight: "80vh",
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
        section == "REWARD"
          ? (this.selectedRewardExcludedCategoryList =
              this.getInExclusionFormat(result.variantIdArray))
          : (this.selectedBarrierExcludedCategoryList =
              this.getInExclusionFormat(result.variantIdArray));
      }
    });
  }

  getInExclusionFormat(allData) {
    let selectedVariantObj = [];
    allData.forEach((element) => {
      let data = {};
      data["productId"] = null;
      data["productName"] = null;
      data["productCategoryId"] = element.collectionId;
      data["productCategory"] = element.collectionName;
      selectedVariantObj.push(data);
    });
    return selectedVariantObj;
  }

  getCategoryFormat(allData) {
    let selectedVariantObj = [];
    allData.forEach((element) => {
      let data = {};
      data["collectionId"] = element.productCategoryId;
      data["collectionName"] = element.productCategory;
      selectedVariantObj.push(data);
    });
    return selectedVariantObj;
  }

  removeEntry(index, type, isProduct, isExclusion?) {
    if (type == "reward") {
      if (isProduct) {
        if (isExclusion) {
          this.selectedRewardExcludedProductList.splice(index, 1);
        } else {
          this.selectedRewardProductList.splice(index, 1);
        }
      } else {
        if (isExclusion) {
          this.selectedRewardExcludedCategoryList.splice(index, 1);
        } else {
          this.selectedRewardCollectionList.splice(index, 1);
        }
      }
    } else {
      if (isProduct) {
        if (isExclusion) {
          this.selectedBarrierExcludedProductList.splice(index, 1);
        } else {
          this.selectedBarrierProductList.splice(index, 1);
        }
      } else {
        if (isExclusion) {
          this.selectedBarrierExcludedCategoryList.splice(index, 1);
        } else {
          this.selectedBarrierCollectionList.splice(index, 1);
        }
      }
    }
  }

  onHasBudgetChange() {
    if (!this.hasBudget) {
      this.offerBudgetComp.resetValues();
    }
  }

  submitOfferForm(action) {

    if(this.hasBudget) {
      if(!this.offerBudgetComp.isValuesValid()) {
        this.saveAndExitButtonLoading = false;
        this.saveAndScheduleButtonLoading = false;
        return true;
      }
    }

    this.submitClicked = true;

    this.noneBarrierCount = 0;
    this.buyXGetYCount = 0;

    // if (this.hurdleEverydayOfTheWeek) {
    //   this.hurdleCompletionDay = [];
    // } else {
    //   this.hurdleCompletionDay = [];
    //   for (let day of this.weekDaysChips) {
    //     if (day.selected) {
    //       this.hurdleCompletionDay.push(day.full);
    //     }
    //   }
    //   // this.hurdleCompletionDay = this.arrayDynamic;
    // }

    // if (action != "saveTemplate" && this.validationCheck()) {

    if (
      (this.startTime.value == null || this.endTime.value == null) &&
      this.isLImitedTime
    ) {
      this.snackBar.openSnackBar(
        this._i18nDynamicTranslate.transform("Please enter the time", [
          "POS_PAGE",
        ]),
        2000
      );
      this.saveAndExitButtonLoading = false;
      this.saveAndScheduleButtonLoading = false;
      return true;
    }
    // if(this.startTime.value <this.startMinTime || this.endTime.value < this.startMinTime ){
    //   this.snackBar.openSnackBar(
    //     this._i18nDynamicTranslate.transform(
    //       "Please enter a future time",
    //       ["POS_PAGE"]
    //     ),
    //     2000
    //   );
    //   this.saveAndExitButtonLoading = false;
    //   this.saveAndScheduleButtonLoading = false;
    //   return true;
    // }
    let startDate = new Date(this.startDate.value);
    startDate.setHours(
      +this.transformTo24HourFormat(this.startTime.value).split(":")[0]
    );
    startDate.setMinutes(
      +this.transformTo24HourFormat(this.startTime.value).split(":")[0]
    );
    startDate.setSeconds(0);
    startDate.setMilliseconds(0);
    let endDate = new Date(this.endDate.value);
    endDate.setHours(
      +this.transformTo24HourFormat(this.endTime.value).split(":")[0]
    );
    endDate.setMinutes(
      +this.transformTo24HourFormat(this.endTime.value).split(":")[0]
    );
    endDate.setSeconds(0);
    endDate.setMilliseconds(0);
    if (
      (startDate.getTime() < this.startDateMinValue.getTime() ||
        endDate.getTime() < this.startDateMinValue.getTime()) &&
      this.isLImitedTime
    ) {
      this.snackBar.openSnackBar(
        this._i18nDynamicTranslate.transform(
          "Please enter a future date/time",
          ["POS_PAGE"]
        ),
        2000
      );
      this.saveAndExitButtonLoading = false;
      this.saveAndScheduleButtonLoading = false;
      return true;
    }
    if (startDate.getTime() > endDate.getTime()) {
      this.snackBar.openSnackBar(
        this._i18nDynamicTranslate.transform(
          "Start time should be less than end time",
          ["POS_PAGE"]
        ),
        2000
      );
      this.saveAndExitButtonLoading = false;
      this.saveAndScheduleButtonLoading = false;
      return true;
    }
    if (this.offerTemplateName.length < 3) {
      this.saveAndExitButtonLoading = false;
      this.saveAndScheduleButtonLoading = false;
      return true;
    }
    var str = this.offerTemplateName;
    if (!str.replace(/\s/g, "").length || this.offerNameDuplicate) {
      this.snackBar.openSnackBar(
        this._i18nDynamicTranslate.transform(
          "Please enter valid offer template name",
          ["POS_PAGE"]
        ),
        2000
      );
      this.saveAndExitButtonLoading = false;
      this.saveAndScheduleButtonLoading = false;
      return true;
    }
    if (this.redeemableAtStores.value?.length == 0) {
      this.saveAndExitButtonLoading = false;
      this.saveAndScheduleButtonLoading = false;
      return true;
    }
    if (this.orderType.value?.length == 0) {
      this.saveAndExitButtonLoading = false;
      this.saveAndScheduleButtonLoading = false;
      return true;
    }
    if (
      this.rewardPersonalizationType === "NON_PERSONALIZED" ||
      (this.rewardPersonalizationType === "PERSONALIZED" &&
        this.shop !== "shopify")
    ) {
      var str2 = this.offerDiscountCode;
      if (!str2.replace(/\s/g, "").length || !this.couponCodeVerified) {
        this.snackBar.openSnackBar(
          this._i18nDynamicTranslate.transform(
            "Please enter valid discount code",
            ["POS_PAGE"]
          ),
          2000
        );
        this.saveAndExitButtonLoading = false;
        this.saveAndScheduleButtonLoading = false;
        return true;
      }
    }

    if (this.checkOfferValidation()) {
      this.saveAndExitButtonLoading = false;
      this.saveAndScheduleButtonLoading = false;
      return 0;
    } else {
    }
    this.productCategory = {
      productCategoryId: this.getProductId(
        this.hurdleRewardProductCategory,
        this.productCategory
      ),
      productCategoryName: this.hurdleRewardProductCategory,
      productCount: null,
    };

    let anyproduct = {
      productCount:
        this.hurdleProductCount != null ? this.hurdleProductCount : 1,
      anyproductSelected:
        this.hurdleProductInnerType == "ANY_PRODUCT" ? true : false,
    };

    let prerequisiteProduct = {
      gfId: this.hurdleProduct != null ? this.hurdleProduct["gfId"] : null,

      productName:
        this.hurdleProduct != null ? this.hurdleProduct["productName"] : null,
      productId:
        this.hurdleProduct != null ? this.hurdleProduct["productId"] : null,
      productCount:
        this.hurdleProductCount != null ? this.hurdleProductCount : 1,
    };

    let prerequisiteVariants = this.selectedBarrierProductList;
    // let prerequisiteVariants = {
    //   gfId:
    //     this.hurdleProductVariant != null
    //       ? this.hurdleProductVariant["gfId"]
    //       : null,
    //   variantName:
    //     this.hurdleProductVariant != null
    //       ? this.hurdleProduct["productName"] !=
    //         this.hurdleProductVariant["variantName"]
    //         ? this.hurdleProduct["productName"] +
    //           "(" +
    //           this.hurdleProductVariant["variantName"] +
    //           ")"
    //         : this.hurdleProductVariant["variantName"]
    //       : "",
    //   variantId:
    //     this.hurdleProductVariant != null
    //       ? this.hurdleProductVariant["variantId"]
    //       : null,
    //   parentProductGFId:
    //     this.hurdleProductVariant != null ? this.hurdleProduct["gfId"] : null,
    //   variantCount:
    //     this.hurdleProductCount != null ? this.hurdleProductCount : 1,
    // };
    let prerequisiteCollection = this.selectedBarrierCollectionList;
    // let prerequisiteCollection = {
    //   collectionId: this.getProductId(
    //     this.hurdleProductCategory,
    //     this.productCategory
    //   ),
    //   collectionName: this.hurdleProductCategory,
    // };
    let entitledVariantsection = this.selectedRewardProductList;
    // let entitledVariantsection = {
    //   gfId:
    //     this.rewardProductVariant != null
    //       ? this.rewardProductVariant["gfId"]
    //       : null,
    //   variantName: this.rewardProductVariant
    //     ? this.hurdleRewardProduct["productName"] !=
    //       this.rewardProductVariant["variantName"]
    //       ? this.hurdleRewardProduct["productName"] +
    //         "(" +
    //         this.rewardProductVariant["variantName"] +
    //         ")"
    //       : this.rewardProductVariant["variantName"]
    //     : null,
    //   variantId:
    //     this.rewardProductVariant != null
    //       ? this.rewardProductVariant["variantId"]
    //       : null,
    //   parentProductGFId: this.rewardProductVariant
    //     ? this.hurdleRewardProduct["gfId"]
    //     : null,
    //   variantCount:
    //     this.hurdleRewardProductCount != null
    //       ? this.hurdleRewardProductCount
    //       : 1,
    // };

    let entitledProduct = {
      gfId:
        this.hurdleRewardProduct != null
          ? this.hurdleRewardProduct["gfId"]
          : null,
      productId:
        this.hurdleRewardProduct != null
          ? this.hurdleRewardProduct["productId"]
          : null,
      productName:
        this.hurdleRewardProduct != null
          ? this.hurdleRewardProduct["productName"]
          : null,
      variant: null,
    };
    let entitledCollection = this.selectedRewardCollectionList;
    // let entitledCollection = {
    //   collectionId: this.getProductId(
    //     this.hurdleRewardProductCategory,
    //     this.productCategory
    //   ),
    //   collectionName: this.hurdleRewardProductCategory,
    // };

    if (
      this.valueTypes.includes(this.hurdleRewardType)
    ) {
      this.amountOrPercentDiscount = {
        entitledType: this.discountAndShippingType,
        entitledProducts:
          this.discountAndShippingType === "PRODUCT" &&
          this.rewardPersonalizationType === "NON_PERSONALIZED"
            ? this.getEntitledProduct()
            : null,
        entitledVariants:
          this.discountAndShippingType === "PRODUCT" &&
          this.rewardPersonalizationType === "NON_PERSONALIZED"
            ? entitledVariantsection
            : null,
        entitledCollection:
          this.discountAndShippingType === "COLLECTION" &&
          this.rewardPersonalizationType === "NON_PERSONALIZED"
            ? entitledCollection
            : null,
        discountValue:
          (this.percentageOffTypes.includes(this.hurdleRewardType))
            ? this.percentageOff
            : (this.amountTypes.includes(this.hurdleRewardType))
            ? this.amountOff
            : null,
        minPurchaseAmount:
          this.rewardMinReqs != "MIN_ORDER_AMOUNT"
            ? null
            : this.rewardMinReqsAmount,
        minPurchaseQuantity:
          this.rewardMinReqs != "MIN_ORDER_QUANTITY"
            ? null
            : this.rewardMinReqsQuantity,
        prerequisiteType:
          this.rewardMinReqs != "NONE" ? this.rewardMinReqs : null,
      };
    }

    if (this.hurdleRewardType === "BUY_X_GET_Y") {
      this.buyXGetYDiscount = {
        prerequisiteType:
          this.hurdleProductInnerType === "PRODUCT" ? "PRODUCT" : "COLLECTION",
        entitledType: this.buyXGetYType,
        prerequisiteProducts: null,
        // [
        //   "hurdleProductInnerType"
        // ] === "PRODUCT"
        //   ? [prerequisiteProduct]
        //   : null,
        prerequisiteVariants:
          this.hurdleProductInnerType === "PRODUCT"
            ? prerequisiteVariants
            : null,
        prerequisiteCollection:
          this.hurdleProductInnerType === "CATEGORY"
            ? prerequisiteCollection
            : null,
        entitledProducts:
          this.buyXGetYType === "PRODUCT" ? [entitledProduct] : null,
        entitledVariants:
          this.buyXGetYType === "PRODUCT" ? entitledVariantsection : null,
        entitledCollection:
          this.buyXGetYType === "COLLECTION" ? entitledCollection : null,
        prerequisiteQuantity:
          this.barrierType == "PRODUCT_BASED" ? this.hurdleProductCount : null,
        entitledQuantity: this.hurdleRewardProductCount,
        entitledItemDiscountPercent:
          this.getEntitledDiscountValue("PERCENTAGE"),
        entitledItemDiscountAmount:
          this.atADiscountedValue == "AMOUNT_OFF" ? this.rewardAmountOff : null,
        prerequisiteAmount:
          this.barrierType == "SPENT_BASED" ? this.hurdleSpentAmount : null,
        prerequisiteExcludedProducts:
          this.selectedBarrierExcludedProductList?.length > 0 &&
          this.hurdleProductInnerType != "PRODUCT"
            ? this.selectedBarrierExcludedProductList
            : null,
        entitledExcludedProducts:
          this.selectedRewardExcludedProductList?.length > 0 &&
          this.buyXGetYType != "PRODUCT"
            ? this.selectedRewardExcludedProductList
            : null,
      };
    }
    if (this.hurdleRewardType === "FREE_SHIPPING") {
      this.freeShippingDiscount = {
        entitledType:
          this.rewardMinReqs == "NONE" ? "ORDER" : this.rewardMinReqs,
        minOrderAmount:
          this.rewardMinReqs != "MIN_ORDER_AMOUNT"
            ? null
            : this.rewardMinReqsAmount,
        minOrderQuantity:
          this.rewardMinReqs != "MIN_ORDER_QUANTITY"
            ? null
            : this.rewardMinReqsQuantity,
      };
    }

    // this.barrierReward = {
    //   amountOrPercentageOffDiscount:
    //     this.hurdleRewardType === "PERCENTAGE_OFF" ||
    //     this.hurdleRewardType === "FIXED_AMOUNT_OFF"
    //       ? this.amountOrPercentDiscount
    //       : null,
    //   productPropensity:
    //     (this.hurdleRewardType === "PERCENTAGE_OFF" ||
    //       this.hurdleRewardType === "FIXED_AMOUNT_OFF") &&
    //     this.discountAndShippingType === "PRODUCT" &&
    //     this.rewardPersonalizationType === "PERSONALIZED"
    //       ? this.rewardPropensity
    //       : null,
    //   productCategoryPropensity:
    //     (this.hurdleRewardType === "PERCENTAGE_OFF" ||
    //       this.hurdleRewardType === "FIXED_AMOUNT_OFF") &&
    //     this.discountAndShippingType === "COLLECTION" &&
    //     this.rewardPersonalizationType === "PERSONALIZED"
    //       ? this.rewardPropensity
    //       : null,
    //   buyXGetYDiscount:
    //     this.hurdleRewardType === "BUY_X_GET_Y" ? this.buyXGetYDiscount : null,
    //   freeShippingDiscount:
    //     this.hurdleRewardType === "FREE_SHIPPING"
    //       ? this.freeShippingDiscount
    //       : null,
    //   // title: ["rewardCoupon"],
    //   index: 0,
    //   daysToRedeem: this.hurdleRewardType === "BUY_X_GET_Y"?this.hurdleTimeToComplete:this.rewardTimeToComplete,
    //   serviceProvider: "shopify",
    //   rewardType: this.hurdleRewardType,
    // };

    var vari = {
      gfId:
        this.hurdleProductVariant != null
          ? this.hurdleProductVariant["gfId"]
          : null,
      variantName:
        this.hurdleProductVariant != null
          ? this.hurdleProduct["productName"] !=
            this.hurdleProductVariant["variantName"]
            ? this.hurdleProduct["productName"] +
              "(" +
              this.hurdleProductVariant["variantName"] +
              ")"
            : this.hurdleProductVariant["variantName"]
          : "",
      variantId:
        this.hurdleProductVariant != null
          ? this.hurdleProductVariant["variantId"]
          : null,
      parentProductGFId:
        this.hurdleProductVariant != null ? this.hurdleProduct["gfId"] : null,
      variantCount:
        this.hurdleProductCount != null ? this.hurdleProductCount : 1,
    };
    this.product = {
      productName:
        this.hurdleProduct != null ? this.hurdleProduct["productName"] : null,
      productId:
        this.hurdleProduct != null ? this.hurdleProduct["productId"] : null,
      productCount:
        this.hurdleProductCount != null ? this.hurdleProductCount : 1,
    };
    this.productCategory = {
      productCategoryName: this.hurdleProductCategory,
      productCategoryId: this.getProductId(
        this.hurdleProductCategory,
        this.productCategory
      ),
      productCount:
        this.hurdleProductCount != null ? this.hurdleProductCount : 1,
    };

    // var starttimeDD = null;
    // var endTimeDD = null;

    // if (this.hourFrom.split(":")[2] == "00") {
    //   starttimeDD = this.hourFrom;
    // } else {
    //   starttimeDD = this.hourFrom + ":00";
    // }
    // if (this.hourTo.split(":")[2] == "00") {
    //   endTimeDD = this.hourTo;
    // } else {
    //   endTimeDD = this.hourTo + ":00";
    // }

    this.barrierListList = {
      barrierDates: {
        startDayCount: 0,
        endDayCount:
          this.hurdleRewardType === "BUY_X_GET_Y"
            ? this.rewardTimeToComplete
            : 0,
        daysOfTheWeek: this.hurdleRewardType === "BUY_X_GET_Y" ? [] : null,
        startTime: this.hurdleRewardType === "BUY_X_GET_Y" ? "00:00:00" : null,
        endTime: this.hurdleRewardType === "BUY_X_GET_Y" ? "23:59:00" : null,
      },
      spentBarrier:
        this.barrierType === "SPENT_BASED" &&
        this.barrierParamType === "NON_PERSONALIZED"
          ? this.hurdleSpentAmount
          : null,

      barrierType: this.hurdleRewardType === "BUY_X_GET_Y" ? "NONE" : "NONE",

      barrierParamType:
        this.hurdleRewardType === "BUY_X_GET_Y"
          ? this.barrierParamType
          : "NONE",
      spentType:
        this.barrierType === "SPENT_BASED" ? this.hurdleSpentInnerType : null,

      anyproduct:
        this.barrierType === "PRODUCT_BASED" &&
        this.hurdleProductInnerType === "ANY_PRODUCT"
          ? anyproduct
          : null,

      product:
        this.barrierType === "PRODUCT_BASED" &&
        this.hurdleProductInnerType === "PRODUCT"
          ? this.product
          : null,
      productVariant: null,
      productCategory: null,
      productPropensity:
        this.barrierParamType === "PERSONALIZED" &&
        this.hurdleProductInnerType === "PRODUCT"
          ? this.productPropensity
          : null,

      productCategoryPropensity:
        this.barrierParamType === "PERSONALIZED" &&
        this.hurdleProductInnerType === "CATEGORY"
          ? this.productPropensity
          : null,

      hurdleAtAnyTime: this.hurdleAtAnyTime,
      hurdleEverydayOfTheWeek: this.hurdleEverydayOfTheWeek,
    };

    this.rewardListList = {
      daysToRedeem:
        this.hurdleRewardType === "BUY_X_GET_Y"
          ? // ? this.hurdleTimeToComplete
            this.rewardTimeToComplete
          : this.rewardTimeToComplete,
      serviceProvider: this.tokenStorage.getMerchantOnlineStore(),
      index: 0,
      rewardType: this.hurdleRewardType == 'NONE' ? "FIXED_AMOUNT_OFF" : this.hurdleRewardType,
      // title: [
      //   "rewardCoupon"
      // ],
      amountOrPercentageOffDiscount:
       this.valueTypes.includes(this.hurdleRewardType)
          ? this.amountOrPercentDiscount
          : null,
      buyXGetYDiscount:
        this.hurdleRewardType === "BUY_X_GET_Y" ? this.buyXGetYDiscount : null,
      freeShippingDiscount:
        this.hurdleRewardType === "FREE_SHIPPING"
          ? this.freeShippingDiscount
          : null,
      // rewardPersonalizationType:
      // [
      //   "rewardPersonalizationType"
      // ] === "NON_PERSONALIZED"
      // ?"NON_PERSONALIZED"
      // :"PERSONALIZED",

      productPropensity:
        (this.hurdleRewardType === "PERCENTAGE_OFF" ||
          this.hurdleRewardType === "FIXED_AMOUNT_OFF") &&
        this.discountAndShippingType === "PRODUCT" &&
        this.rewardPersonalizationType === "PERSONALIZED"
          ? this.rewardPropensity
          : null,
      productCategoryPropensity:
        (this.hurdleRewardType === "PERCENTAGE_OFF" ||
          this.hurdleRewardType === "FIXED_AMOUNT_OFF") &&
        this.discountAndShippingType === "COLLECTION" &&
        this.rewardPersonalizationType === "PERSONALIZED"
          ? this.rewardPropensity
          : null,

      rewardTemplate: this.rewardTemplate,
      emailLayoutId: this.rewardSavedId,
      layoutId: this.rewardSMSSavedId,
      channel: this.channel,
      rewardLayoutName: this.rewardsmsName,
      title: this.offerDiscountCode.length > 2 ? this.offerDiscountCode : null,
      isOncePerCustomer: this.islimitToOneUsePerCustomer,
      usageLimit: this.islimitNoOfTry
        ? (this.hurdleRewardType != "FREE_SHIPPING" &&
            this.hurdleRewardType != "BUY_X_GET_Y" &&
            this.rewardPersonalizationType === "PERSONALIZED") ||
          (this.hurdleRewardType === "BUY_X_GET_Y" &&
            this.barrierParamType === "PERSONALIZED")
          ? 1
          : this.noOfTry
        : null,
      excludedProducts:
        this.selectedRewardExcludedProductList?.length > 0 &&
        this.discountAndShippingType !== "PRODUCT"
          ? this.selectedRewardExcludedProductList
          : null,
      excludedCategories:
        this.selectedRewardExcludedCategoryList?.length > 0 &&
        this.discountAndShippingType === "ORDER"
          ? this.selectedRewardExcludedCategoryList
          : null,
      communication:
        this.route.snapshot.paramMap.get("clone") && this.isLImitedTime
          ? null
          : this.communication,
      termsAndCondition: this.updateBulletArray(this.customTermsAndCondition),
      description: this.updateBulletArray(this.customDescription),
      discountScope : this.discountOn,
      orderType : this.orderType.value.filter(item => item !== 'ALL')
    };

    if (this.hurdleRewardToggle == false) {
      //   this.rewardListList = null;
    }
    if (this.barrierParamType == "NONE") {
      // this.barrierListList[0] = null;
    }
    this.BarrierRewardFull = {
      barrier: this.barrierListList,
      reward: this.rewardListList,
    };
    // this.selectedbarrier = this.BarrierRewardFull;

    if (this.barrierParamType === "NONE") {
      this.noneBarrierCount++;
    }
    if (this.hurdleRewardType === "BUY_X_GET_Y") {
      this.buyXGetYCount++;
    }
    if (1) {
      if (this.noneBarrierCount == 1) {
        this.noneBarrierDetected = true;
      } else {
        this.noneBarrierDetected = false;
        // this.noneBarrierCount=0;
      }

      if (this.buyXGetYCount == 1) {
        this.isBuyXGetYDetected = true;
      } else {
        this.isBuyXGetYDetected = false;
        // this.buyXGetYCount=0;
      }

      if (this.noneBarrierCount + this.buyXGetYCount == 0 + 1) {
        if (this.noneBarrierCount != 0) {
          this.noneBarrierDetected = true;
        }
        if (this.buyXGetYCount != 0) {
          this.isBuyXGetYDetected = true;
        }

        // this.noneBarrierCount=0;
        // this.isBuyXGetYDetected=true;
        // this.buyXGetYCount=0;
      }
    }

    this.addRule(action);
  }
  getEntitledProduct() {
    let data: any = [];
    let parentIds = [];
    this.selectedRewardProductList.forEach((varient) => {
      if (parentIds.indexOf(varient.parentProductGFId) === -1) {
        data.push({
          gfId: varient.parentProductGFId,
          productId: varient.parentProductGFId,
          productName: varient.parentProductName,
        });
        parentIds.push(varient.parentProductGFId);
      }
    });
    return data;
  }

  addRule(action) {
    //  this.addData()
    if (0) {
      this.snackBar.openSnackBar(
        this._i18nDynamicTranslate.transform("add barrier", ["POS_PAGE"]),
        2000
      );
    } else {
      let font =
        "<link href='https://fonts.googleapis.com/css2?family=Exo+2&display=swap' rel='stylesheet'>";
      if (false) {
      } else {
        let offerJson = {
          barrierRewards: [this.BarrierRewardFull],
          isNoneBarrierDetected: this.hurdleRewardType != "BUY_X_GET_Y",
          isBuyXGetYDetected: this.hurdleRewardType == "BUY_X_GET_Y",
          smsLayoutId: null,
          smsTemplateId: null,
          smsTemplateName: null,
          emailTemplateName: null,
          channel: null,
          emailLayoutId: null,
          emailLayoutTemplateId: null,
          emailLayoutName: null,
          emailLayoutSubject: null,
          emailTemplateSnapshotUrl: null,
          comCouponValidity: null,
          comDisplayValidity: null,
          // stores: this.storeData,
          adminCreated: this.tokenStorage.getqId() === "null" ? true : false,
          createdBy:
            this.tokenStorage.getqId() === "null"
              ? this.adminCreated
              : this.storeDataJSON,
        };
        if (this.shop == "posist") {
          offerJson["isSpecialOffer"] = this.isSpecialOffer;
          offerJson["toApplyOnMultipleProduct"] = this.toApplyOnMultipleProduct;
        }
        let redeemableAtStores = [];
        if (this.isAdminPos) {
          if (this.redeemableAtStores.value.some((id) => id == -1)) {
            //check if all stores selected
            redeemableAtStores = [];
          } else {
            this.redeemableAtStores.value.forEach((id) =>
              redeemableAtStores.push(parseInt(id))
            );
          }
        } else {
          redeemableAtStores.push(parseInt(this.storeDataJSON["storeId"]));
        }
        offerJson["redeemableAtStores"] = redeemableAtStores;
        let startDate = new Date(this.startDate.value);
        startDate.setHours(
          +this.transformTo24HourFormat(this.startTime.value).split(":")[0]
        );
        startDate.setMinutes(
          +this.transformTo24HourFormat(this.startTime.value).split(":")[1]
        );
        startDate.setSeconds(0);
        startDate.setMilliseconds(0);
        let endDate = new Date(this.endDate.value);
        endDate.setHours(
          +this.transformTo24HourFormat(this.endTime.value).split(":")[0]
        );
        endDate.setMinutes(
          +this.transformTo24HourFormat(this.endTime.value).split(":")[1]
        );
        endDate.setSeconds(0);
        endDate.setMilliseconds(0);
        let offertestNew = {
          offerJson: JSON.stringify(offerJson),
          channel: null,
          offerName: this.offerTemplateName,
          offerId: this.offerMasterUpdate ? this.queryparamofferId : null,
          isPersonalised: this.isPersonalised,
          isLimitedTime: this.isLImitedTime,
          // offerHtml: this.finalHtml,
          offerStartTime: this.isLImitedTime
            ? this.toUTC(
                this.datePipe.transform(startDate, "yyyy-MM-dd HH:mm:ss"),
                "UTC"
              )
            : null,
          offerEndTime: this.isLImitedTime
            ? this.toUTC(
                this.datePipe.transform(endDate, "yyyy-MM-dd HH:mm:ss"),
                "UTC"
              )
            : null,
          isGiveAway: this.isGiveAway,
          isHiddenItemOffer: this.isHiddenItemOffer,
          isContinuos: this.offerTimeType.toUpperCase() === 'CONTINOUS' ? true : false,
          limitedTimeOfferInfo : this.offerTimeType.toUpperCase() !== 'CONTINOUS' ? (!this.everyDaySliderValue ? this.arrayDynamic : []) : null
        };

        this.offerReqBodyObj = offertestNew;
        // if ("DISPATCH" == action) {
        //   this.loadingblock = 2;
        // } else {
        //   this.loadingblock = 1;
        // }
        if ("DISPATCH" == action) {
          this.saveAndScheduleButtonLoading = true;
          this.saveAndExitButtonLoading = false;
        } else {
          this.saveAndScheduleButtonLoading = false;
          this.saveAndExitButtonLoading = true;
        }

        if (
          (offertestNew.offerId == null && this.oneTimeSaved == false) ||
          (this.route.snapshot.paramMap.get("clone") &&
            this.oneTimeSaved == false) ||
          (!this.queryparamofferId && this.oneTimeSaved == false)
        ) {
          if(this.hasBudget) {
            let budgetEntityFromComp = this.offerBudgetComp.returnBudgetObj();
            budgetEntityFromComp['merchantId'] = parseInt(this.tokenStorage.getMerchantId());
            budgetEntityFromComp['module'] = 'OFFER';
            budgetEntityFromComp['moduleId'] = null;
            offertestNew['budgetEntity'] = budgetEntityFromComp;
          } 
          this.apiCall.createParticularOffer(offertestNew).subscribe(
            (response) => {
              this.saveAndScheduleButtonLoading = false;
              this.saveAndExitButtonLoading = false;

              if (response["message"] === "success") {
                this.oneTimeSaved = true;
                this.oneTimeSavedId = response["body"];
                this.queryparamofferId = response["body"];
                const queryParams: Params = {
                  id: response["body"],
                };
                this.snackBar.openSnackBar(
                  this._i18nDynamicTranslate.transform("Template saved", [
                    "POS_PAGE",
                  ]),
                  1000
                );
                if (response["body"] != null) {
                  if ("DISPATCH" == action) {
                    this.checkIfOfferInJourneyAndOpenScheduleOverlay(
                      this.queryparamofferId
                    );
                    this.saveActionType = "DISPATCH";
                  } else if ("saveTemplateAndChannel" == action) {
                    this.checkIfOfferInJourneyAndOpenScheduleOverlay(
                      this.queryparamofferId
                    );
                    this.saveActionType = "saveTemplateAndChannel";
                    // this.router.navigate([
                    //   "app/offer/shopify-offer/list-offer",
                    //   { id: response["body"] },
                    // ]);
                  } else {
                    this.snackBar.openSnackBar(
                      this._i18nDynamicTranslate.transform("Template saved", [
                        "POS_PAGE",
                      ]),
                      1000
                    );
                    this.router.navigate(["/app/offer/choose-offer"]);
                  }
                }
              }
            },
            (err) => {
              const dialogRef = this.dialog.open(ContactUsDialogComponent, {
                panelClass: "no-padding-dialog-popup",
                height: "auto",
                disableClose: true,
                width: "380px",
                data: {
                  header: "Error",
                  body: err.error.body,
                },
              });
              dialogRef.afterClosed().subscribe((result) => {
                this.saveAndScheduleButtonLoading = false;
                this.saveAndExitButtonLoading = false;
                return;

                // this.disabled = false;
              });

              //   this.snackBar.openSnackBar("error", 2000);
            }
          );
        } else {
          var idnumber;
          if (this.masterOffer) {
            idnumber = this.masterOffer["id"];
          } else {
            idnumber = this.oneTimeSavedId;
          }

          var k = this.masterOffer
            ? JSON.parse(this.masterOffer["offerjson"])
            : this.offerReqBodyObj;
          console.log("testdattdtta", k);

          let offerJson = {
            barrierRewards: [this.BarrierRewardFull],
            isNoneBarrierDetected: this.hurdleRewardType != "BUY_X_GET_Y",
            isBuyXGetYDetected: this.hurdleRewardType == "BUY_X_GET_Y",
            layoutId: k.layoutId,
            smsTemplateId: k.smsTemplateId,
            smsTemplateName: k.smsTemplateName,
            emailTemplateName: k.emailTemplateName,
            channel: k.channel,
            emailLayoutId: k.emailLayoutId,
            emailLayoutTemplateId: k.emailLayoutTemplateId,
            emailLayoutName: k.emailLayoutName,
            emailLayoutSubject: k.emailLayoutSubject,
            emailTemplateSnapshotUrl: k.emailTemplateSnapshotUrl,
            // stores: this.storeData,
            adminCreated: this.tokenStorage.getqId() === "null" ? true : false,
            createdBy:
              this.tokenStorage.getqId() === "null"
                ? this.adminCreated
                : this.storeDataJSON,
          };
          if (this.shop == "posist") {
            offerJson["isSpecialOffer"] = this.isSpecialOffer;
            offerJson["toApplyOnMultipleProduct"] =
              this.toApplyOnMultipleProduct;
          }
          let redeemableAtStores = [];
          if (this.isAdminPos) {
            if (this.redeemableAtStores.value.some((id) => id == -1)) {
              //check if all stores selected
              redeemableAtStores = [];
            } else {
              this.redeemableAtStores.value.forEach((id) =>
                redeemableAtStores.push(parseInt(id))
              );
            }
          } else {
            redeemableAtStores.push(parseInt(this.storeDataJSON["storeId"]));
          }
          offerJson["redeemableAtStores"] = redeemableAtStores;
          let offertestUpdate = {
            offerJson: JSON.stringify(offerJson),
            // channel: k.channel,
            offerName: this.offerTemplateName,
            offerId: this.offerMasterUpdate ? this.queryparamofferId : null,
            isPersonalised: this.isPersonalised,
            isLimitedTime: this.isLImitedTime,
            // offerHtml: this.finalHtml,
            offerStartTime: this.isLImitedTime
              ? this.toUTC(
                  this.datePipe.transform(startDate, "yyyy-MM-dd HH:mm:ss"),
                  "UTC"
                )
              : null,
            offerEndTime: this.isLImitedTime
              ? this.toUTC(
                  this.datePipe.transform(endDate, "yyyy-MM-dd HH:mm:ss"),
                  "UTC"
                )
              : null,
            isGiveAway: this.isGiveAway,
            isHiddenItemOffer: this.isHiddenItemOffer,
            isContinuos: this.offerTimeType.toUpperCase() === 'CONTINOUS' ? true : false,
            limitedTimeOfferInfo : this.offerTimeType.toUpperCase() !== 'CONTINOUS' ? (!this.everyDaySliderValue ? this.arrayDynamic : []) : null
          };

          if(this.hasBudget) {
            if(this.budgetEntity) {
              let budgetEntityFromComp = this.offerBudgetComp.returnBudgetObj();
              this.budgetEntity['budgetLimit'] = budgetEntityFromComp['budgetLimit'];
              this.budgetEntity['redemptionCount'] = budgetEntityFromComp['redemptionCount'];
              this.budgetEntity['resetFrequency'] = budgetEntityFromComp['resetFrequency'];
              offertestUpdate['budgetEntity'] = this.budgetEntity;
            } else {
              let budgetEntityFromComp = this.offerBudgetComp.returnBudgetObj();
              budgetEntityFromComp['merchantId'] = parseInt(this.tokenStorage.getMerchantId());
              budgetEntityFromComp['module'] = 'OFFER';
              budgetEntityFromComp['moduleId'] = this.offerMasterUpdate ? this.queryparamofferId : null;
              offertestUpdate['budgetEntity'] = budgetEntityFromComp;
            }
          } else {
            offertestUpdate['budgetEntity'] = null;
          }
          

          this.offerReqBodyObj = offertestUpdate;
          this.apiCall
            .updateParticularOfferDetails(
              this.queryparamofferId,
              offertestUpdate
            )
            .subscribe(
              (response) => {
                this.oneTimeSavedId = response["body"];
                this.queryparamofferId = response["body"];
                this.saveAndScheduleButtonLoading = false;
                this.saveAndExitButtonLoading = false;
                if (response["message"] === "success") {
                  console.log(
                    this._i18nDynamicTranslate.transform("Template saved", [
                      "POS_PAGE",
                    ])
                  );

                  this.snackBar.openSnackBar(
                    this._i18nDynamicTranslate.transform("Template saved", [
                      "POS_PAGE",
                    ]),
                    1000
                  );
                  const queryParams: Params = {
                    id: response["body"],
                  };
                  if ("DISPATCH" == action) {
                    this.checkIfOfferInJourneyAndOpenScheduleOverlay(
                      this.queryparamofferId
                    );
                    this.saveActionType = "DISPATCH";
                  } else if ("saveTemplateAndChannel" == action) {
                    this.checkIfOfferInJourneyAndOpenScheduleOverlay(
                      this.queryparamofferId
                    );
                    this.saveActionType = "saveTemplateAndChannel";
                    // this.router.navigate([
                    //   "app/offer/shopify-offer/list-offer",
                    //   { id: response["body"] },
                    // ]);
                  } else {
                    this.snackBar.openSnackBar(
                      this._i18nDynamicTranslate.transform("Template saved", [
                        "POS_PAGE",
                      ]),
                      1000
                    );
                    this.router.navigate(["/app/offer/choose-offer"]);
                  }
                }
              },
              (err) => {
                const dialogRef = this.dialog.open(ContactUsDialogComponent, {
                  height: "auto",
                  disableClose: true,
                  width: "380px",
                  data: {
                    header: "Error",
                    body: err.error.body,
                  },
                });
                dialogRef.afterClosed().subscribe((result) => {
                  this.saveAndScheduleButtonLoading = false;
                  this.saveAndExitButtonLoading = false;
                  return;
                });
              }
            );
        }
      }
    }
  }

  checkIfOfferInJourneyAndOpenScheduleOverlay(offerId) {
    this.snackBar.openSnackBar("Checking if offer is in any journey...", 0);
    this.apiCall.getJourneyNamesForOffer(offerId).subscribe(
      (response) => {
        this.snackBar.dismiss();
        if (response["body"].length > 0) {
          this.openScheduleOverlay = false;
          const dialogRefDeleted = this.dialog.open(
            NotDeletedListDialogBoxComponent,
            {
              panelClass: "customer-dialog-popup",
              width: "540px",
              disableClose: false,
              data: {
                heading: "Cant send this offer",
                type: "OFFERINJOURNEY",
                journeyList: response["body"],
              },
            }
          );
        } else {
          this.openScheduleOverlay = true;
          this.modifyConfig(true);
        }
      },
      (err) => {
        this.snackBar.dismiss();
        this.snackBar.openSnackBar("Failed to validate offer", 2000);
      }
    );
  }

  getProductId(product: string, type: string) {
    if (type === "product") {
      for (let i = 0; i < this.productType.length; i++) {
        if (product === this.productType[i]["name"]) {
          return this.productType[i]["id"];
        }
      }
    }
    if (type === this.productCategory) {
      for (let i = 0; i < this.productCategoryType.length; i++) {
        if (product === this.productCategoryType[i]["name"]) {
          return this.productCategoryType[i]["id"];
        }
      }
    }
    // if (type === "emailTemplate") {
    //   for (let i = 0; i < this.rewardTemplateList.length; i++) {
    //     if (product == this.rewardTemplateList[i]["layoutName"]) {

    //       return this.rewardTemplateList[i]["id"];
    //     }
    //   }
    // }
  }
  getMainProductJson(id) {
    if (id == null) {
      return "";
    }
    for (var i = 0; i < this.apiProductList.length; i++) {
      if (this.apiProductList[i]["productId"] == id) {
        return this.apiProductList[i];
      }
    }
    return "";
  }
  getvariantProductJson(id, parent) {
    if (id == null) {
      return "";
    }
    for (var i = 0; i < parent.variants.length; i++) {
      if (parent.variants[i]["variantId"] == id) {
        return parent.variants[i];
      }
    }
    return "";
  }
  validationCheck() {
    if (this.offerTemplateName.length == 0) {
      return true;
    }

    // if (!this.timeRangeSetValidation(this.hourFrom, this.hourTo)) {
    //   this.snackBar.openSnackBar("Please check the from and to timings", 2000);
    //   return true;
    // }
    // if (
    //   this.barrierParamType != "NONE" &&
    //   this.hurdleEverydayOfTheWeek == false
    // ) {
    //   if (this.hurdleCompletionDay.length === 0) {
    //     this.snackBar.openSnackBar(
    //       "Please check Every day of the week field ",
    //       2000
    //     );
    //
    //     return true;
    //   }
    // }

    if (
      this.barrierParamType === "NON_PERSONALIZED" &&
      this.barrierType === "PRODUCT_BASED"
    ) {
      if (this.hurdleProductInnerType == null) {
        // this.snackBar.openSnackBar(
        //   "Please check 'Customer needs to purchase' field",
        //   2000
        // );

        return true;
      }
    }

    if (
      this.hurdleRewardType == "BUY_X_GET_Y" &&
      this.barrierParamType === "NON_PERSONALIZED" &&
      this.barrierType === "SPENT_BASED"
    ) {
      if (
        this.hurdleSpentAmount == null ||
        this.hurdleSpentAmount < 1 ||
        this.hurdleSpentAmount == "e"
      ) {
        // this.snackBar.openSnackBar(
        //   "Please check 'Minimum amount that needs to be spent' field",
        //   2000
        // );

        return true;
      }
    }

    if (
      this.hurdleRewardType == "BUY_X_GET_Y" &&
      this.barrierParamType === "NON_PERSONALIZED" &&
      this.barrierType === "PRODUCT_BASED" &&
      this.hurdleProductInnerType === "CATEGORY" &&
      this.hurdleProductCategory == null
    ) {
      return true;
    }

    if (
      this.hurdleRewardType == "BUY_X_GET_Y" &&
      this.barrierParamType === "PERSONALIZED" &&
      this.barrierType === "PRODUCT_BASED" &&
      this.hurdleProductInnerType === null
    ) {
      return true;
    }

    if (
      this.hurdleRewardType == "BUY_X_GET_Y" &&
      this.barrierParamType === "PERSONALIZED" &&
      this.barrierType === "PRODUCT_BASED" &&
      this.hurdleProductInnerType != null &&
      this.productPropensity == null
    ) {
      return true;
    }

    if (
      this.hurdleRewardType == "BUY_X_GET_Y" &&
      this.barrierParamType === "NON_PERSONALIZED" &&
      this.barrierType === "PRODUCT_BASED" &&
      this.hurdleProductInnerType === "PRODUCT"
    ) {
      if (this.selectedBarrierProductList.length == 0) {
        this.snackBar.openSnackBar(
          this._i18nDynamicTranslate.transform("Please select the products", [
            "POS_PAGE",
          ]),
          2000
        );

        return true;
      }
    }
    if (
      this.hurdleRewardType == "BUY_X_GET_Y" &&
      this.barrierParamType === "NON_PERSONALIZED" &&
      this.barrierType === "SPENT_BASED"
    ) {
      if (
        this.hurdleSpentAmount == null ||
        this.hurdleSpentAmount == undefined ||
        this.hurdleSpentAmount == 0 ||
        this.hurdleSpentAmount < 1 ||
        this.hurdleSpentAmount == "e"
      ) {
        //  this.snackBar.openSnackBar("Please check the form field amount", 2000);

        return true;
      }
    }

    if (
      this.hurdleRewardType == "BUY_X_GET_Y" &&
      this.buyXGetYType == "PRODUCT" &&
      (this.selectedRewardProductList == null ||
        this.selectedRewardProductList.length < 1)
    ) {
      this.snackBar.openSnackBar(
        this._i18nDynamicTranslate.transform("Add atleast 1 product", [
          "POS_PAGE",
        ]),
        2000
      );
      return true;
    }
    if (
      this.hurdleRewardType == "BUY_X_GET_Y" &&
      this.buyXGetYType == "COLLECTION" &&
      (this.selectedRewardCollectionList == null ||
        this.selectedRewardCollectionList.length < 1)
    ) {
      this.snackBar.openSnackBar(
        this._i18nDynamicTranslate.transform("Add atleast 1 category", [
          "POS_PAGE",
        ]),
        2000
      );
      return true;
    }
    if (this.barrierParamType != "NONE") {
      // if (
      //   this.hurdleRewardType == "BUY_X_GET_Y" &&
      //   (this.hurdleTimeToComplete == null ||
      //     this.hurdleTimeToComplete == undefined ||
      //     this.hurdleTimeToComplete == 0 ||
      //     this.hurdleTimeToComplete == "e" ||
      //     this.hurdleTimeToComplete < 1)
      // ) {
      //
      //   return true;
      // }
    }

    if (
      this.barrierParamType == "NONE" &&
      this.rewardPersonalizationType == "PERSONALIZED" &&
      (this.hurdleRewardType === "PERCENTAGE_OFF" ||
        this.hurdleRewardType === "FIXED_AMOUNT_OFF") &&
      this.discountAndShippingType == null
    ) {
      return true;
    }

    if (this.hurdleRewardToggle) {
      if (
        (this.rewardTimeToComplete == null ||
          this.rewardTimeToComplete == undefined ||
          this.rewardTimeToComplete == 0 ||
          this.rewardTimeToComplete < 1 ||
          this.rewardTimeToComplete == "e") &&
        this.hurdleRewardType != "BUY_X_GET_Y" &&
        !this.isLImitedTime
      ) {
        this.snackBar.openSnackBar(
          this._i18nDynamicTranslate.transform(
            "Please check the 'Reward Validity' field",
            ["POS_PAGE"]
          ),
          2000
        );

        return true;
      }

      if (this.hurdleRewardType === "PERCENTAGE_OFF") {
        if (
          this.percentageOff == null ||
          this.percentageOff == undefined ||
          this.percentageOff == 0 ||
          this.percentageOff < 1 ||
          this.percentageOff == "e" ||
          this.percentageOff > 100
        ) {
          return true;
        }
      }

      if (this.hurdleRewardType === "BUY_X_GET_Y") {
        if (this.buyXGetYType == null) {
          // this.snackBar.openSnackBar(
          //   "Please check 'Type of free product' field",
          //   2000
          // );

          return true;
        }
        if (
          this.buyXGetYType == "COLLECTION" &&
          this.rewardPersonalizationType == "NON_PERSONALIZED" &&
          this.hurdleRewardProductCategory == null
        ) {
          // this.snackBar.openSnackBar(
          //   "Please check 'Type of free product' field",
          //   2000
          // );

          return true;
        }

        if (
          this.hurdleRewardProductCount == 0 ||
          this.hurdleRewardProductCount == "e" ||
          this.hurdleRewardProductCount == null ||
          this.hurdleRewardProductCount > 100
        ) {
          this.snackBar.openSnackBar(
            this._i18nDynamicTranslate.transform("Check the product count", [
              "POS_PAGE",
            ]),
            2000
          );

          return true;
        }
      }
      if (
        this.hurdleRewardType === "FIXED_AMOUNT_OFF" ||
        this.hurdleRewardType === "PERCENTAGE_OFF"
      ) {
        if (this.discountAndShippingType == null) {
          // this.snackBar.openSnackBar(
          //   "Please check 'Customers will get an amount off on' field",
          //   2000
          // );

          return true;
        }
        // if (
        //   this.discountAndShippingType == "COLLECTION" &&
        //   this.rewardPersonalizationType == "NON_PERSONALIZED" &&
        //   this.hurdleRewardProductCategory == null
        // ) {

        //

        //   return true;
        // }
        if (
          this.discountAndShippingType != null &&
          this.rewardPersonalizationType == "PERSONALIZED" &&
          this.rewardPropensity == null
        ) {
          // this.snackBar.openSnackBar(
          //   "Please check 'Customers will get an amount off on' field",
          //   2000
          // );

          return true;
        }

        // if(this.hurdleRewardProductCategory==null){
        //   this.snackBar.openSnackBar(
        //     "Please check the Category",
        //     2000
        //   );
        //

        //   return true;
        // }
      }
      if (this.hurdleRewardType === "PERCENTAGE_OFF") {
        if (this.discountAndShippingType == null) {
          // this.snackBar.openSnackBar(
          //   "Please check 'Customers will get a percentage off on' field",
          //   2000
          // );

          return true;
        }
      }

      if (
        this.hurdleRewardType === "BUY_X_GET_Y" &&
        this.buyXGetYType === "PRODUCT" &&
        this.barrierParamType != "NONE"
      ) {
        if (this.hurdleRewardProduct) {
          if (this.rewardProductVariant) {
          } else {
            //  this.snackBar.openSnackBar("Please check the Variant field", 2000);

            return true;
          }
        } else {
          //this.snackBar.openSnackBar("Please check the Product field", 2000);

          return true;
        }
      }
      // if (
      //   [
      //     "hurdleRewardType"
      //   ] === "PERCENTAGE_OFF" &&
      //   [
      //     "discountAndShippingType"
      //   ] === "PRODUCT"
      // ) {
      //   if (
      //     [
      //       "hurdleRewardProduct"
      //     ]
      //   ) {
      //     if (
      //       this.offerTemplate.controls.offerBarrier["controls"][0][
      //         "controls"
      //       ]this.rewardProductVariant
      //     ) {
      //     } else {
      //       this.snackBar.openSnackBar(
      //         "Please check the Variant field",
      //         2000
      //       );
      //       document
      //         .getElementById("reward-" + i)
      //         .scrollIntoView({ behavior: "smooth", block: "center" });
      //       return true;
      //     }
      //   } else {
      //     this.snackBar.openSnackBar("Please check the Product field", 2000);
      //     document
      //       .getElementById("reward-" + i)
      //       .scrollIntoView({ behavior: "smooth", block: "center" });

      //     return true;
      //   }
      // }
      if (
        this.rewardPersonalizationType === "NON_PERSONALIZED" &&
        this.discountAndShippingType === "PRODUCT" &&
        this.hurdleRewardType != "FREE_SHIPPING" &&
        this.hurdleRewardType != "BUY_X_GET_Y"
      ) {
        if (this.hurdleRewardProduct) {
          if (this.rewardProductVariant) {
          } else {
            // this.snackBar.openSnackBar("Please check the Variant field", 2000);

            return true;
          }
        } else {
          // this.snackBar.openSnackBar("Please check the Product field", 2000);

          return true;
        }
      }

      if (
        (this.hurdleRewardType === "PERCENTAGE_OFF" ||
          this.hurdleRewardType === "FIXED_AMOUNT_OFF") &&
        this.discountAndShippingType === "PRODUCT" &&
        this.barrierParamType != "NONE"
      ) {
        if (this.hurdleRewardProduct) {
          if (this.rewardProductVariant) {
          } else {
            //  this.snackBar.openSnackBar("Please check the Variant field", 2000);

            return true;
          }
        } else {
          // this.snackBar.openSnackBar("Please check the Product field", 2000);

          return true;
        }
      }

      if (this.hurdleRewardType === "FIXED_AMOUNT_OFF") {
        if (this.amountOff == null || this.amountOff == undefined) {
          // this.snackBar.openSnackBar(
          //   "Please check the form fields amount",
          //   2000
          // );

          return true;
        }
      }
      if (
        this.hurdleRewardType === "FREE_SHIPPING" &&
        this.freeShippingType === "MIN_ORDER_AMOUNT"
      ) {
        if (
          this.freeShippingMinimumAmount == null ||
          this.freeShippingMinimumAmount == undefined
        ) {
          // this.snackBar.openSnackBar(
          //   "Please check the form fields amount",
          //   2000
          // );

          return true;
        }
      }
      if (
        this.hurdleRewardType === "FREE_SHIPPING" &&
        this.freeShippingType === "MIN_ORDER_QUANTITY"
      ) {
        if (
          this.freeShippingMinimumOrderCount == null ||
          this.freeShippingMinimumOrderCount == undefined ||
          this.freeShippingMinimumOrderCount == 0 ||
          this.freeShippingMinimumOrderCount < 1 ||
          this.freeShippingMinimumOrderCount == "e"
        ) {
          return true;
        }
      }

      if (
        (this.hurdleRewardType === "PERCENTAGE_OFF" ||
          this.hurdleRewardType === "FIXED_AMOUNT_OFF") &&
        this.discountAndShippingType === "COLLLECTION" &&
        this.barrierParamType != "NONE"
      ) {
        if (
          this.rewardPersonalizationType === "NON_PERSONALIZED" &&
          this.hurdleRewardProductCategory == null
        ) {
          return true;
        }
        if (
          this.rewardPersonalizationType === "PERSONALIZED" &&
          this.rewardPropensity == null
        ) {
          //this.rewardExpand();

          return true;
        }
      }
    }
    // }
    // }
  }

  modifyConfig(status) {
    document.getElementById("viewconfigDiv").style.zIndex = "1";
    document.getElementById("schedule-overlay").style.zIndex = "2";
    document.getElementById("configDiv").style.zIndex = "2";
    document.getElementById("configDiv").style.display = "flex";
    this.on();
  }

  cancelAllSchedule() {
    this.off();
    document.getElementById("viewconfigDiv").style.zIndex = "2";
    document.getElementById("configDiv").style.zIndex = "-1";
    document.getElementById("configDiv").style.display = "none";
    document.getElementById("schedule-overlay").style.zIndex = "-1";
    document.getElementById("schedule-overlay").style.display = "none";
  }

  on() {
    // this.getParticularOfferDetail();
    // this.getTemplates();
    document.getElementById("schedule-overlay").style.display = "block";
  }

  off() {
    document.getElementById("schedule-overlay").style.display = "none";
  }

  saveandExitPressed() {
    const dialogRef = this.dialog.open(
      OfferChannelAddConfirmationDialogComponent,
      {
        panelClass: "no-padding-dialog-popup",
        height: "auto",
        width: "380px",
      }
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result == "nochannel") {
        this.submitOfferForm("saveTemplate");
      } else if (result == "addchannel") {
        this.submitOfferForm("saveTemplateAndChannel");
      } else {
      }
    });
  }
  closeOfferScreen() {
    this.router.navigate(["/app/offer/choose-offer"]);
  }
  receiveMessage($event) {
    this.message = $event;
    this.saveAndExitButtonLoading = false;
    this.saveAndScheduleButtonLoading = false;
    this.cancelAllSchedule();
    this.openScheduleOverlay = false;
    if (this.popUpMode) {
      this.messageEvent.emit(this.message);
    }
  }

  clickSave() {
    // if (this.checkOfferValidation()) {
    //   this.saveAndExitButtonLoading = false;
    //   this.saveAndScheduleButtonLoading=false;
    //   return 0;
    // } else {
    //   this.setUpjsonResponse();
    // }
  }

  checkOfferValidation() {
    if (
      this.offerDiscountCode.length < 3 &&
      this.offerDiscountCode.length > 0
    ) {
      this.snackBar.openSnackBar(
        this._i18nDynamicTranslate.transform(
          "Discount code should have atleast 3 characters",
          ["POS_PAGE"]
        ),
        2000
      );
      document
        .getElementById("discountCode")
        .scrollIntoView({ behavior: "smooth", block: "center" });
      return 1;
    }
    if (
      this.valueTypes.includes(this.hurdleRewardType)
    ) {
      if (
        (this.percentageOffTypes.includes(this.hurdleRewardType)) &&
        (this.percentageOff == null ||
          this.percentageOff == "" ||
          this.percentageOff == "e")
      ) {
        this.snackBar.openSnackBar(
          this._i18nDynamicTranslate.transform("Please enter Discount Value", [
            "POS_PAGE",
          ]),
          2000
        );
        document
          .getElementById("percentageOff")
          .scrollIntoView({ behavior: "smooth", block: "center" });
        return 1;
      }
      if (
        (this.hurdleRewardType == "PERCENTAGE_OFF" ||
          this.hurdleRewardType == "PERCENTAGE_REWARD_COINS_GAIN") &&
        (this.percentageOff == null ||
          this.percentageOff == "" ||
          this.percentageOff == "e" ||
          this.percentageOff > 100)
      ) {
        this.snackBar.openSnackBar(
          this._i18nDynamicTranslate.transform(
            "Please enter Valid Discount Value",
            ["POS_PAGE"]
          ),
          2000
        );
        document
          .getElementById("percentageOff")
          .scrollIntoView({ behavior: "smooth", block: "center" });
        return 1;
      }
      if (
        (this.amountTypes.includes(this.hurdleRewardType)) &&
        (this.amountOff == null ||
          this.amountOff == "" ||
          this.amountOff == "e")
      ) {
        document
          .getElementById("amountOff")
          .scrollIntoView({ behavior: "smooth", block: "center" });
        return 1;
      }

      if (
        this.discountAndShippingType == null ||
        this.discountAndShippingType == "" ||
        this.rewardMinReqs == null ||
        this.rewardMinReqs == ""
      ) {
        document
          .getElementById("minRequirement")
          .scrollIntoView({ behavior: "smooth", block: "center" });

        return 1;
      }
      if (
        this.rewardPersonalizationType === "NON_PERSONALIZED" &&
        this.discountAndShippingType == "COLLECTION" &&
        this.selectedRewardCollectionList.length == 0
      ) {
        this.snackBar.openSnackBar(
          this._i18nDynamicTranslate.transform("Add atleast 1 category", [
            "POS_PAGE",
          ]),
          2000
        );
        document
          .getElementById("selectedRewardCollectionList")
          .scrollIntoView({ behavior: "smooth", block: "center" });

        return 1;
      }

      if (
        this.rewardPersonalizationType === "NON_PERSONALIZED" &&
        this.discountAndShippingType == "PRODUCT" &&
        (this.selectedRewardProductList.length == 0 ||
          this.selectedRewardProductList == null)
      ) {
        this.snackBar.openSnackBar(
          this._i18nDynamicTranslate.transform("Add atleast 1 product", [
            "POS_PAGE",
          ]),
          2000
        );
        return 1;
      }
      if (
        this.rewardPersonalizationType === "PERSONALIZED" &&
        (this.rewardPropensity == null || this.rewardPropensity == "")
      ) {
        this.snackBar.openSnackBar(
          this._i18nDynamicTranslate.transform("Select Propensity", [
            "POS_PAGE",
          ]),
          2000
        );
        return 1;
      }
      if (
        this.rewardMinReqs == "MIN_ORDER_AMOUNT" &&
        (this.rewardMinReqsAmount == 0 ||
          this.rewardMinReqsAmount == null ||
          this.rewardMinReqsAmount == "")
      ) {
        this.snackBar.openSnackBar(
          this._i18nDynamicTranslate.transform("Add Amount", ["POS_PAGE"]),
          2000
        );
        return 1;
      }
      if (
        this.rewardMinReqs == "MIN_ORDER_QUANTITY" &&
        (this.rewardMinReqsQuantity == 0 ||
          this.rewardMinReqsQuantity == null ||
          this.rewardMinReqsQuantity == "" ||
          this.rewardMinReqsQuantity > 100)
      ) {
        this.snackBar.openSnackBar(
          this._i18nDynamicTranslate.transform("Check quantity", ["POS_PAGE"]),
          2000
        );
        return 1;
      }
    }

    if (this.hurdleRewardType == "FREE_SHIPPING") {
      if (
        this.rewardMinReqs == "MIN_ORDER_AMOUNT" &&
        (this.rewardMinReqsAmount == 0 ||
          this.rewardMinReqsAmount == null ||
          this.rewardMinReqsAmount == "")
      ) {
        this.snackBar.openSnackBar(
          this._i18nDynamicTranslate.transform("Add Amount", ["POS_PAGE"]),
          2000
        );
        return 1;
      }
      if (
        this.rewardMinReqs == "MIN_ORDER_QUANTITY" &&
        (this.rewardMinReqsQuantity == 0 ||
          this.rewardMinReqsQuantity == null ||
          this.rewardMinReqsQuantity == "")
      ) {
        this.snackBar.openSnackBar(
          this._i18nDynamicTranslate.transform("Add quantity", ["POS_PAGE"]),
          2000
        );
        return 1;
      }
    }
    if (this.hurdleRewardType == "BUY_X_GET_Y") {
      if (
        this.barrierType == "PRODUCT_BASED" &&
        (this.hurdleProductCount == "" ||
          this.hurdleProductCount == null ||
          this.hurdleProductCount == "e" ||
          this.hurdleProductCount > 100)
      ) {
        this.snackBar.openSnackBar(
          this._i18nDynamicTranslate.transform("Check product quantity", [
            "POS_PAGE",
          ]),
          2000
        );
        return 1;
      }
      if (
        this.barrierType == "SPENT_BASED" &&
        (this.hurdleSpentAmount == "" ||
          this.hurdleSpentAmount == null ||
          this.hurdleSpentAmount == "e")
      ) {
        this.snackBar.openSnackBar(
          this._i18nDynamicTranslate.transform("Add Amount", ["POS_PAGE"]),
          2000
        );
        return 1;
      }
      if (
        this.barrierParamType == "NON_PERSONALIZED" &&
        this.hurdleProductInnerType == "PRODUCT" &&
        (this.selectedBarrierProductList == null ||
          this.selectedBarrierProductList.length == 0)
      ) {
        this.snackBar.openSnackBar(
          this._i18nDynamicTranslate.transform("Add Product", ["POS_PAGE"]),
          2000
        );
        return 1;
      }
      if (
        this.barrierParamType == "NON_PERSONALIZED" &&
        this.hurdleProductInnerType == "CATEGORY" &&
        (this.selectedBarrierCollectionList == null ||
          this.selectedBarrierCollectionList.length == 0)
      ) {
        this.snackBar.openSnackBar(
          this._i18nDynamicTranslate.transform("Add Collection", ["POS_PAGE"]),
          2000
        );
        return 1;
      }
      if (
        this.barrierParamType == "PERSONALIZED" &&
        (this.productPropensity == null || this.productPropensity == "")
      ) {
        this.snackBar.openSnackBar(
          this._i18nDynamicTranslate.transform("Add difficulty level", [
            "POS_PAGE",
          ]),
          2000
        );
        return 1;
      }

      if (
        this.hurdleRewardType == "BUY_X_GET_Y" &&
        this.buyXGetYType == "PRODUCT" &&
        (this.selectedRewardProductList == null ||
          this.selectedRewardProductList.length < 1)
      ) {
        this.snackBar.openSnackBar(
          this._i18nDynamicTranslate.transform("Add atleast 1 product", [
            "POS_PAGE",
          ]),
          2000
        );
        return true;
      }
      if (
        this.hurdleRewardType == "BUY_X_GET_Y" &&
        this.buyXGetYType == "COLLECTION" &&
        (this.selectedRewardCollectionList == null ||
          this.selectedRewardCollectionList.length < 1)
      ) {
        this.snackBar.openSnackBar(
          this._i18nDynamicTranslate.transform("Add atleast 1 category", [
            "POS_PAGE",
          ]),
          2000
        );
        return true;
      }
      if (
        this.hurdleRewardProductCount == null ||
        this.hurdleRewardProductCount == "" ||
        this.hurdleRewardProductCount == "e"
      ) {
        this.snackBar.openSnackBar(
          this._i18nDynamicTranslate.transform("Add product quantity", [
            "POS_PAGE",
          ]),
          2000
        );
        return 1;
      }
      if (this.atADiscountedValue == null || this.atADiscountedValue == "") {
        return 1;
      }
    }

    if (
      this.hurdleRewardType == "BUY_X_GET_Y" &&
      this.atADiscountedValue == "PERCENTAGE_OFF" &&
      (this.rewardpercentageOff == null ||
        this.rewardpercentageOff == "" ||
        this.rewardpercentageOff == "e" ||
        this.rewardpercentageOff < 1 ||
        this.rewardpercentageOff > 100)
    ) {
      return 1;
    }

    if (
      this.hurdleRewardType == "BUY_X_GET_Y" &&
      this.atADiscountedValue == "AMOUNT_OFF" &&
      (this.rewardAmountOff == null ||
        this.rewardAmountOff == "" ||
        this.rewardAmountOff == "e" ||
        this.rewardAmountOff < 1)
    ) {
      return 1;
    }

    if (
      (this.rewardTimeToComplete == null ||
        this.rewardTimeToComplete == 0 ||
        this.rewardTimeToComplete == "" ||
        this.rewardTimeToComplete == "e") &&
      !this.isLImitedTime
    ) {
      this.snackBar.openSnackBar(
        this._i18nDynamicTranslate.transform(
          "Please check the 'Reward Validity' field",
          ["POS_PAGE"]
        ),
        2000
      );

      return 1;
    }
    if (this.islimitNoOfTry && (this.noOfTry === null || this.noOfTry < 1)) {
      document
        .getElementById("usageLimit")
        .scrollIntoView({ behavior: "smooth", block: "center" });
      return 1;
    }
    return 0;
  }

  setUpjsonResponse() {}

  getInTimeFormat(time) {
    let dateTime: any = new Date(time);

    // Get the time in hh:mm format
    var hours = dateTime.getHours();
    var minutes = dateTime.getMinutes();

    // Convert single digit hours and minutes to double digits
    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;

    let timeOp: any = hours + ":" + minutes;
    return timeOp;
  }

  getOfferDetails(id) {
    this.offerReady = false;
    this.apiCall.getParticularOfferDetails(id).subscribe(
      (response) => {
        if (response["message"] == "success") {
          this.masterOffer = JSON.parse(response["body"]);
          this.isPersonalised = this.masterOffer["isPersonalised"];
          this.isLImitedTime = this.masterOffer["isLimitedTime"];
          this.isGiveAway = this.masterOffer["isGiveAway"];
          this.isHiddenItemOffer = this.masterOffer["isHiddenItemOffer"];
          this.offerTimeType = this.masterOffer["isContinuos"] ? this.masterOffer["isContinuos"] ? 'CONTINOUS' : 'RECURRING' : 'CONTINOUS';
          this.everyDaySliderValue = !this.masterOffer["isContinuos"] ? (this.masterOffer['limitedTimeOfferInfo']?.length === 0 ? true : false) : false;
          // this.arrayDynamic = this.everyDaySliderValue ? [] : this.masterOffer['limitedTimeOfferInfo'];

          if (!this.everyDaySliderValue ) {
            this.arrayDynamic = this.masterOffer['limitedTimeOfferInfo'];
            this.weekDaysChips = this.weekDaysChips.map((x) => {
              x.selected = this.arrayDynamic?.includes(x.name);
              return x;
            });
          } else {
            this.arrayDynamic = [];
            this.weekDaysChips = this.weekDaysChips.map((x) => {
              x.selected = this.arrayDynamic?.includes(x.name);
              return x;
            });
          }

          if (this.isLImitedTime) {
            var startDateValue = this.toMerchantTimeZome(
              this.masterOffer["offerStartTime"]
            );
            var endDateValue = this.toMerchantTimeZome(
              this.masterOffer["offerEndTime"]
            );
            console.log(
              this.getInTimeFormat(this.masterOffer["offerStartTime"]),
              this.getInTimeFormat(this.masterOffer["offerEndTime"])
            );
            console.log(this.startMinTime);
            this.startTime.setValue(
              this.getInTimeFormat(this.masterOffer["offerStartTime"])
            );
            this.endTime.setValue(
              this.getInTimeFormat(this.masterOffer["offerEndTime"])
            );
            console.log(this.startTime, this.endTime);
            this.startDate.setValue(startDateValue);
            this.endDate.setValue(endDateValue);
          }

          if (this.route.snapshot.paramMap.get("clone")) {
            var k = JSON.parse(this.masterOffer["offerjson"]);
            (k.layoutId = null),
              (k.smsTemplateId = null),
              (k.smsTemplateName = null),
              (k.emailTemplateName = null),
              (k.channel = null),
              (k.emailLayoutId = null),
              (k.emailLayoutTemplateId = null),
              (k.emailLayoutName = null),
              (k.emailLayoutSubject = null),
              (k.emailTemplateSnapshotUrl = null),
              (k["barrierRewards"][0]["reward"]["emailLayoutId"] = null);
            k["barrierRewards"][0]["reward"]["layoutId"] = null;
            this.masterOffer["offerjson"] = JSON.stringify(k);
            this.masterOffer["offerjson"];
          }
          this.offerJson = JSON.parse(this.masterOffer["offerjson"])[
            "barrierRewards"
          ];
          //    this.setConstants("updation");
          this.offerMasterUpdate = JSON.parse(response["body"]);
        }

        // for (let i = 0; i < this.offerJson.length; i++) {
        if (
          true
          // this.masterOffer["id"] == this.route.snapshot.paramMap.get("id")
        ) {
          this.offerMasterUpdate = this.offerJson;
          this.importedData = true;
        }
        // }
        this.setConstants("updation");
        this.isSpecialOffer = JSON.parse(this.masterOffer["offerjson"])[
          "isSpecialOffer"
        ];
        this.redeemableAtStores.setValue(
          JSON.parse(this.masterOffer["offerjson"])["redeemableAtStores"]?.map(
            (id) => id.toString()
          )
        );
        console.log(this.redeemableAtStores.value);
        let createdBy = JSON.parse(this.masterOffer["offerjson"])["createdBy"];
        if (
          this.redeemableAtStores?.value?.length == 0 ||
          !this.redeemableAtStores.value
        ) {
          if (createdBy.qid == null) {
            this.redeemableAtStores.setValue([
              -1,
              ...this.storeData.map((store) => store.qid),
            ]);
          } else {
            this.redeemableAtStores.setValue([createdBy.qid.toString()]);
          }
        }
        this.previousStoreIdList = [...(this.redeemableAtStores.value || [])];
        this.toApplyOnMultipleProduct = JSON.parse(
          this.masterOffer["offerjson"]
        )["toApplyOnMultipleProduct"];
        if(this.masterOffer.budgetEntity) {
          this.hasBudget = true;
          this.budgetEntity = this.masterOffer.budgetEntity;
        }
      },
      (err) => {
        this.showofferTemplateSkeleton = false;
        this.offerReady = true;
        this.snackBar.openSnackBar(
          this._i18nDynamicTranslate.transform(err.error.body, ["POS_PAGE"]),
          2000
        );
      }
    );
  }

  isofferNameCheckLoading = false;
  offerNameDuplicate = false;
  checkOfferName() {
    if (this.sub) {
      this.sub.unsubscribe();
    }

    var str = this.offerTemplateName;
    if (!str.replace(/\s/g, "").length) {
      this.snackBar.openSnackBar(
        this._i18nDynamicTranslate.transform(
          "Please enter valid offer template name",
          ["POS_PAGE"]
        ),
        2000
      );
      return;
    }

    this.isofferNameCheckLoading = true;
    this.offerNameCheckInitialised = true;
    this.offerNameDuplicate = false;
    this.sub = this.apiCall
      .offerNameValidation(
        this.offerTemplateName,
        this.route.snapshot.paramMap.get("id")
          ? this.route.snapshot.paramMap.get("clone")
            ? null
            : this.route.snapshot.paramMap.get("id")
          : null
      )
      .subscribe((response) => {
        this.isofferNameCheckLoading = false;
        if (response["message"] == "success" && response["body"] == "true") {
          this.offerNameDuplicate = true;
        } else {
          this.offerNameDuplicate = false;
        }
      });
  }

  checkCouponcode() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
    this.iscouponcodeCheckLoading = true;
    this.couponcodeCheckInitialised = true;
    this.couponCodeVerified = false;
    this.sub = this.apiCall
      .couponCodeValidation(
        this.offerDiscountCode,
        this.route.snapshot.paramMap.get("id")
          ? this.route.snapshot.paramMap.get("clone")
            ? 0
            : this.route.snapshot.paramMap.get("id")
          : 0,
        0
      )
      .subscribe((response) => {
        this.iscouponcodeCheckLoading = false;
        if (response["message"] == "success" && response["body"] == "false") {
          // this.couponCodeValid=true;
          this.couponCodeVerified = true;
        } else {
          this.couponCodeVerified = false;
        }
      });
  }

  onKeyDiscountCodeCheck(event: any) {
    if (this.offerDiscountCode.length < 3) return 0;
    this.offerDiscountCode = this.offerDiscountCode.toUpperCase();
    clearTimeout(this.timeout);
    var $this = this;
    this.timeout = setTimeout(function () {
      // if (event.keyCode != 13) {
      $this.checkCouponcode();
      // }
    }, 600);
  }

  onKeyOfferNameCheck(event: any) {
    if (this.offerTemplateName.length < 3) {
      this.offerNameCheckInitialised = false;
      return;
    }
    clearTimeout(this.timeout);
    var $this = this;
    this.timeout = setTimeout(function () {
      // if (event.keyCode != 13) {
      $this.checkOfferName();
      // }
    }, 600);
  }

  openItemDialogwithSearchData(data, BorR, type, list) {
    clearTimeout(this.timeout);

    var $this = this;
    this.timeout = setTimeout(function () {
      if (data.length > 2 && data.length > $this.previousData.length) {
        if ("BARRIER" == BorR) {
          $this.browseBarrier(type, data);
        } else {
          $this.browseReward(list, type, data);
        }
      }
      $this.previousData = data || "";
    }, 300);
  }

  clearTempFilters() {
    this.atBuyxGetYProducts = "";
    this.atBuyxGetYCollections = "";
    this.atdiscountAndPercentageOffProducts = "";
    this.atdiscountAndPercentageOffCollections = "";
    this.previousData = "";
  }

  addFilledForm(offerMaster) {
    let barrierList = offerMaster["barrierList"];
    let barrierSet = offerMaster["barrier"];
    let rewardSet = offerMaster["reward"];
    this.communication = offerMaster[0]["reward"]
      ? offerMaster[0]["reward"]["communication"]
      : null;
    console.log(this.communication);
    // (this.offerTemplate.get("offerBarrier")).removeAt(0);
    // for (let i = 0; i < offerMaster.length; i++) {
    // const control = new FormGroup({

    this.barrierParamType =
      offerMaster[0]["reward"]["rewardType"] === "BUY_X_GET_Y"
        ? offerMaster[0]["barrier"]["barrierParamType"]
        : "NON_PERSONALIZED";
    this.hurdleAtAnyTime = offerMaster[0]["barrier"]
      ? offerMaster[0]["barrier"]["hurdleAtAnyTime"]
      : true;
    this.hurdleEverydayOfTheWeek = offerMaster[0]["barrier"]
      ? offerMaster[0]["barrier"]["hurdleEverydayOfTheWeek"]
      : true;

    (this.productPropensity =
      offerMaster[0]["barrier"]["barrierParamType"] === "PERSONALIZED"
        ? offerMaster[0]["barrier"]["productPropensity"]
          ? offerMaster[0]["barrier"]["productPropensity"]
          : offerMaster[0]["barrier"]["productCategoryPropensity"]
        : "LOW"),
      (this.barrierType =
        offerMaster[0]["barrier"]["barrierType"] == "NONE"
          ? "PRODUCT_BASED"
          : offerMaster[0]["barrier"]["barrierType"]),
      //  offerMaster[0]["barrier"]["barrierType"]),
      // (this.temphurdleSegmentFilter = ""),
      // (this.temphurdleVariantSegmentFilter = ""),
      // (this.temprewardVariantSegmentFilter = ""),
      // (this.temprewardSegmentFilter = ""),
      (this.hurdleProductInnerType =
        offerMaster[0]["reward"]["buyXGetYDiscount"] != null
          ? offerMaster[0]["reward"]["buyXGetYDiscount"]["prerequisiteType"] ==
            "PRODUCT"
            ? "PRODUCT"
            : "CATEGORY"
          : "CATEGORY"),
      // let a=    offerMaster[0]["barrier"]["barrierParamType"] === "PERSONALIZED"
      //       ? offerMaster[0]["barrier"]["productPropensity"]
      //         ? "PRODUCT"
      //         : "CATEGORY"
      //       : offerMaster[0]["barrier"]["product"]
      //       ? "PRODUCT"
      //       : offerMaster[0]["barrier"]["productCategory"]
      //       ? "CATEGORY"
      //       : offerMaster[0]["barrier"]["anyproduct"]
      //       ? "ANY_PRODUCT"
      //       : "CATEGORY");
      // (this.hurdleProduct =
      //   offerMaster[0]["barrier"]["product"] != null
      //     ? this.getMainProductJson(
      //         offerMaster[0]["barrier"]["product"]["productId"]
      //       )
      //     : null),
      // (this.hurdleProductVariant =
      //   offerMaster[0]["barrier"]["productVariant"] != null &&
      //   offerMaster[0]["barrier"]["product"] != null
      //     ? this.getvariantProductJson(
      //         offerMaster[0]["barrier"]["productVariant"]["variantId"],
      //         this.getMainProductJson(
      //           offerMaster[0]["barrier"]["product"]["productId"]
      //         )
      //       )
      //     : null),

      (this.hurdleProductCategory = offerMaster[0]["barrier"]["productCategory"]
        ? offerMaster[0]["barrier"]["productCategory"]["productCategoryName"]
        : null);
    // this.hurdleProductCount =
    //   offerMaster[0]["barrier"]["barrierType"] === "PRODUCT_BASED" ||
    //   offerMaster[0]["barrier"]["barrierType"] === "NONE"
    //     ? offerMaster[0]["barrier"]["product"]
    //       ? offerMaster[0]["barrier"]["product"]["productCount"]
    //       : offerMaster[0]["barrier"]["productCategory"]
    //       ? offerMaster[0]["barrier"]["productCategory"]["productCount"]
    //       : 1
    //     : 1;
    this.hurdleSpentInnerType =
      offerMaster[0]["barrier"]["barrierParamType"] === "NON_PERSONALIZED"
        ? offerMaster[0]["barrier"]["barrierType"] === "SPENT_BASED"
          ? offerMaster[0]["barrier"]["spentType"]
          : "AMOUNT"
        : "AMOUNT";

    // (this.hurdleSpentAmount =
    //   offerMaster[0]["barrier"]["barrierParamType"] === "NON_PERSONALIZED"
    //     ? offerMaster[0]["barrier"]["barrierType"] === "SPENT_BASED"
    //       ? offerMaster[0]["barrier"]["spentBarrier"]
    //       : null
    //     : null),
    this.hurdleRewardToggle = offerMaster[0]["reward"] ? true : false;
    // rewardPersonalizationType: offerMaster[0]["reward"]
    //   ? offerMaster[0]["barrier"]["productPropensity"] !== null ||
    //     offerMaster[0]["barrier"]["productCategoryPropensity"] !== null
    //     ? "PERSONALIZED")
    //     : "NON_PERSONALIZED")
    //   : "NON_PERSONALIZED"),
    this.rewardPersonalizationType = offerMaster[0]["reward"]
      ? offerMaster[0]["reward"]["productPropensity"] !== null ||
        offerMaster[0]["reward"]["productCategoryPropensity"] !== null
        ? "PERSONALIZED"
        : "NON_PERSONALIZED"
      : "NON_PERSONALIZED";

    this.rewardPropensity = offerMaster[0]["reward"]
      ? offerMaster[0]["reward"]["productPropensity"] !== null
        ? offerMaster[0]["reward"]["productPropensity"]
        : offerMaster[0]["reward"]["productCategoryPropensity"] !== null
        ? offerMaster[0]["reward"]["productCategoryPropensity"]
        : "LOW"
      : "LOW";

    if (this.route.snapshot.paramMap.get("clone")) {
    } else {
      this.offerDiscountCode = offerMaster[0]["reward"]["title"]
        ? offerMaster[0]["reward"]["title"]
        : "";
    }

    this.islimitToOneUsePerCustomer =
      offerMaster[0]["reward"]["isOncePerCustomer"];

    this.islimitNoOfTry =
      offerMaster[0]["reward"]["usageLimit"] != null ? true : false;

    this.noOfTry =
      offerMaster[0]["reward"]["usageLimit"] != null
        ? offerMaster[0]["reward"]["usageLimit"]
        : 1;

    this.hurdleRewardType = offerMaster[0]["reward"]
      ? offerMaster[0]["reward"]["rewardType"]
      : "BUY_X_GET_Y";
    this.buyXGetYType = offerMaster[0]["reward"]
      ? offerMaster[0]["reward"]["rewardType"] === "BUY_X_GET_Y"
        ? offerMaster[0]["reward"]["buyXGetYDiscount"]["entitledType"]
        : "PRODUCT"
      : "PRODUCT";
    this.discountAndShippingType = offerMaster[0]["reward"]
      ? (
        this.valueTypes.includes(offerMaster[0]["reward"]["rewardType"])
      )
        ? offerMaster[0]["reward"].amountOrPercentageOffDiscount?.entitledType
        : "PRODUCT"
      : "PRODUCT";
    this.percentageOff = offerMaster[0]["reward"]
      ? (this.percentageOffTypes.includes(offerMaster[0]["reward"]["rewardType"]))
        ? offerMaster[0]["reward"]["amountOrPercentageOffDiscount"][
            "discountValue"
          ]
        : null
      : null;
    this.amountOff = offerMaster[0]["reward"]
      ? (this.amountTypes.includes(offerMaster[0]["reward"]["rewardType"]))
        ? offerMaster[0]["reward"].amountOrPercentageOffDiscount?.discountValue
        : null
      : null;

    if(this.hurdleRewardType === 'FIXED_AMOUNT_OFF' && this.amountOff == null && this.isHiddenItemOffer){
      this.hurdleRewardType = 'NONE';
    }

    this.barrierType = offerMaster[0]["reward"]
      ? offerMaster[0]["reward"]["rewardType"] === "BUY_X_GET_Y"
        ? offerMaster[0]["reward"]["buyXGetYDiscount"]["prerequisiteAmount"]
          ? "SPENT_BASED"
          : "PRODUCT_BASED"
        : "PRODUCT_BASED"
      : "PRODUCT_BASED";
    this.hurdleSpentAmount = offerMaster[0]["reward"]
      ? offerMaster[0]["reward"]["rewardType"] === "BUY_X_GET_Y"
        ? offerMaster[0]["reward"]["buyXGetYDiscount"]["prerequisiteAmount"] !=
          null
          ? offerMaster[0]["reward"]["buyXGetYDiscount"]["prerequisiteAmount"]
          : null
        : null
      : null;
    this.hurdleProductCount = offerMaster[0]["reward"]
      ? offerMaster[0]["reward"]["rewardType"] === "BUY_X_GET_Y"
        ? offerMaster[0]["reward"]["buyXGetYDiscount"][
            "prerequisiteQuantity"
          ] != null
          ? offerMaster[0]["reward"]["buyXGetYDiscount"]["prerequisiteQuantity"]
          : null
        : null
      : null;

    // this.hurdleRewardProduct = offerMaster[0]["reward"]
    //   ? offerMaster[0]["reward"]["rewardType"] === "BUY_X_GET_Y"
    //     ? offerMaster[0]["reward"]["buyXGetYDiscount"]["entitledType"] ===
    //       "PRODUCT"
    //       ? this.getMainProductJson(
    //           offerMaster[0]["reward"]["buyXGetYDiscount"][
    //             "entitledProducts"
    //           ][0]["productId"]
    //         )
    //       : null
    //     : offerMaster[0]["reward"]["rewardType"] === "PERCENTAGE_OFF" &&
    //       !offerMaster[0]["reward"]["productPropensity"]
    //     ? offerMaster[0]["reward"]["amountOrPercentageOffDiscount"][
    //         "entitledType"
    //       ] === "PRODUCT"
    //       ? this.getMainProductJson(
    //           offerMaster[0]["reward"]["amountOrPercentageOffDiscount"][
    //             "entitledProducts"
    //           ][0]["productId"]
    //         )
    //       : null
    //     : offerMaster[0]["reward"]["rewardType"] === "FIXED_AMOUNT_OFF" &&
    //       !offerMaster[0]["reward"]["productPropensity"]
    //     ? offerMaster[0]["reward"]["amountOrPercentageOffDiscount"][
    //         "entitledType"
    //       ] === "PRODUCT"
    //       ? this.getMainProductJson(
    //           offerMaster[0]["reward"]["amountOrPercentageOffDiscount"][
    //             "entitledProducts"
    //           ][0]["productId"]
    //         )
    //       : null
    //     : null
    //   : null;

    let amountOrPercentageOffDiscountMinAmount = offerMaster[0]["reward"][
      "amountOrPercentageOffDiscount"
    ]
      ? offerMaster[0]["reward"]["amountOrPercentageOffDiscount"][
          "minPurchaseAmount"
        ]
        ? offerMaster[0]["reward"]["amountOrPercentageOffDiscount"][
            "minPurchaseAmount"
          ]
        : null
      : null;
    let amountOrPercentageOffDiscountMinOrder = offerMaster[0]["reward"][
      "amountOrPercentageOffDiscount"
    ]
      ? offerMaster[0]["reward"]["amountOrPercentageOffDiscount"][
          "minPurchaseQuantity"
        ]
        ? offerMaster[0]["reward"]["amountOrPercentageOffDiscount"][
            "minPurchaseQuantity"
          ]
        : null
      : null;
    let freeshippingMinOrder = offerMaster[0]["reward"]["freeShippingDiscount"]
      ? offerMaster[0]["reward"]["freeShippingDiscount"]["minOrderQuantity"]
        ? offerMaster[0]["reward"]["freeShippingDiscount"]["minOrderQuantity"]
        : null
      : null;
    let freeshippingMinAmount = offerMaster[0]["reward"]["freeShippingDiscount"]
      ? offerMaster[0]["reward"]["freeShippingDiscount"]["minOrderAmount"]
        ? offerMaster[0]["reward"]["freeShippingDiscount"]["minOrderAmount"]
        : null
      : null;

    this.rewardMinReqsAmount =
      amountOrPercentageOffDiscountMinAmount || freeshippingMinAmount;
    this.rewardMinReqsQuantity =
      amountOrPercentageOffDiscountMinOrder || freeshippingMinOrder;
    this.rewardMinReqs =
      amountOrPercentageOffDiscountMinAmount || freeshippingMinAmount
        ? "MIN_ORDER_AMOUNT"
        : amountOrPercentageOffDiscountMinOrder || freeshippingMinOrder
        ? "MIN_ORDER_QUANTITY"
        : "NONE";

    let buyxgetyBarrierProdList = offerMaster[0]["reward"]["buyXGetYDiscount"]
      ? offerMaster[0]["reward"]["buyXGetYDiscount"]["prerequisiteVariants"]
      : [];
    let buyxgetyBarrierExcludeProdList = offerMaster[0]["reward"][
      "buyXGetYDiscount"
    ]
      ? offerMaster[0]["reward"]["buyXGetYDiscount"][
          "prerequisiteExcludedProducts"
        ]
      : [];
    let buyxgetyBarrierCollList = offerMaster[0]["reward"]["buyXGetYDiscount"]
      ? offerMaster[0]["reward"]["buyXGetYDiscount"]["prerequisiteCollection"]
      : [];
    let buyxgetyRewardProdList = offerMaster[0]["reward"]["buyXGetYDiscount"]
      ? offerMaster[0]["reward"]["buyXGetYDiscount"]["entitledVariants"]
      : [];
    let buyxgetyRewardExcludeProdList = offerMaster[0]["reward"][
      "buyXGetYDiscount"
    ]
      ? offerMaster[0]["reward"]["buyXGetYDiscount"]["entitledExcludedProducts"]
      : [];
    let buyxgetyRewardCollList = offerMaster[0]["reward"]["buyXGetYDiscount"]
      ? offerMaster[0]["reward"]["buyXGetYDiscount"]["entitledCollection"]
      : [];
    let percentageoramountoffProdList = offerMaster[0]["reward"][
      "amountOrPercentageOffDiscount"
    ]
      ? offerMaster[0]["reward"]["amountOrPercentageOffDiscount"][
          "entitledVariants"
        ]
      : [];
    let percentageoramountoffExcludeProdList = offerMaster[0]["reward"][
      "excludedProducts"
    ]
      ? offerMaster[0]["reward"]["excludedProducts"]
      : [];
    let percentageoramountoffCollList = offerMaster[0]["reward"][
      "amountOrPercentageOffDiscount"
    ]
      ? offerMaster[0]["reward"]["amountOrPercentageOffDiscount"][
          "entitledCollection"
        ]
      : [];
    let percentageoramountoffExcludeCollList = offerMaster[0]["reward"][
      "excludedCategories"
    ]
      ? offerMaster[0]["reward"]["excludedCategories"]
      : [];

    let selrewardProdlist =
      offerMaster[0]["reward"]["amountOrPercentageOffDiscount"] != null
        ? percentageoramountoffProdList
        : buyxgetyRewardProdList || [];
    let selExcludeProductList =
      offerMaster[0]["reward"]["excludedProducts"] != null
        ? percentageoramountoffExcludeProdList
        : buyxgetyRewardExcludeProdList || [];

    let selExcludeCategoryList =
      offerMaster[0]["reward"]["excludedCategories"] != null
        ? percentageoramountoffExcludeCollList
        : buyxgetyRewardExcludeProdList || [];
    // percentageoramountoffProdList || buyxgetyRewardProdList;
    let selrewardCollList =
      offerMaster[0]["reward"]["amountOrPercentageOffDiscount"] != null
        ? percentageoramountoffCollList
        : buyxgetyRewardCollList || [];

    this.selectedBarrierProductList = buyxgetyBarrierProdList || [];
    this.selectedBarrierExcludedProductList =
      buyxgetyBarrierExcludeProdList || [];
    this.selectedBarrierCollectionList = buyxgetyBarrierCollList || [];
    this.selectedRewardProductList = selrewardProdlist || [];
    this.selectedRewardExcludedProductList = selExcludeProductList || [];
    this.selectedRewardExcludedCategoryList = selExcludeCategoryList || [];
    this.selectedRewardCollectionList = selrewardCollList || [];

    // this.selectedRewardProductList  = offerMaster[0]["reward"]
    //       ? offerMaster[0]["reward"]["rewardType"] === "BUY_X_GET_Y"
    //         ? offerMaster[0]["reward"]["buyXGetYDiscount"]["entitledType"] ===
    //           "PRODUCT"
    //           ?
    //               offerMaster[0]["reward"]["buyXGetYDiscount"]["entitledVariants"]
    //                 ? offerMaster[0]["reward"]["buyXGetYDiscount"][
    //                     "entitledVariants"
    //                   ]
    //                 : []
    //           : []
    //         : offerMaster[0]["reward"]["rewardType"] === "PERCENTAGE_OFF" &&
    //           !offerMaster[0]["reward"]["productPropensity"]
    //         ? offerMaster[0]["reward"]["amountOrPercentageOffDiscount"][
    //             "entitledType"
    //           ] === "PRODUCT"
    //           ?
    //               offerMaster[0]["reward"]["amountOrPercentageOffDiscount"][
    //                 "entitledVariants"
    //               ]
    //                 ? offerMaster[0]["reward"]["amountOrPercentageOffDiscount"][
    //                     "entitledVariants"
    //                   ]
    //                 : []

    //           : []
    //         : offerMaster[0]["reward"]["rewardType"] === "FIXED_AMOUNT_OFF" &&
    //           !offerMaster[0]["reward"]["productPropensity"]
    //         ? offerMaster[0]["reward"]["amountOrPercentageOffDiscount"][
    //             "entitledType"
    //           ] === "PRODUCT"
    //           ?
    //               offerMaster[0]["reward"]["amountOrPercentageOffDiscount"][
    //                 "entitledVariants"
    //               ]
    //                 ? offerMaster[0]["reward"]["amountOrPercentageOffDiscount"][
    //                     "entitledVariants"
    //                   ]
    //                 : []
    //           : []
    //         : []
    //       : [];
    // this.selectedBarrierCollectionList = offerMaster[0]["reward"]?offerMaster[0]["reward"]["buyXGetYDiscount"]?offerMaster[0]["reward"]["buyXGetYDiscount"]["entitledCollection"]?offerMaster[0]["reward"]["buyXGetYDiscount"]["entitledCollection"]:[]:[]:[];
    // this.selectedRewardCollectionList = offerMaster[0]["reward"]
    // ? offerMaster[0]["reward"]["rewardType"] === "BUY_X_GET_Y"
    //   ? offerMaster[0]["reward"]["buyXGetYDiscount"]["entitledType"] ===
    //     "COLLECTION"
    //     ?
    //         offerMaster[0]["reward"]["buyXGetYDiscount"]["entitledCollection"]
    //           ? offerMaster[0]["reward"]["buyXGetYDiscount"][
    //               "entitledVariants"
    //             ]
    //           : []
    //     : []
    //   : offerMaster[0]["reward"]["rewardType"] === "PERCENTAGE_OFF" &&
    //     !offerMaster[0]["reward"]["productPropensity"]
    //   ? offerMaster[0]["reward"]["amountOrPercentageOffDiscount"][
    //       "entitledType"
    //     ] === "PRODUCT"
    //     ?
    //         offerMaster[0]["reward"]["amountOrPercentageOffDiscount"][
    //           "entitledVariants"
    //         ]
    //           ? offerMaster[0]["reward"]["amountOrPercentageOffDiscount"][
    //               "entitledVariants"
    //             ]
    //           : []

    //     : []
    //   : offerMaster[0]["reward"]["rewardType"] === "FIXED_AMOUNT_OFF" &&
    //     !offerMaster[0]["reward"]["productPropensity"]
    //   ? offerMaster[0]["reward"]["amountOrPercentageOffDiscount"][
    //       "entitledType"
    //     ] === "PRODUCT"
    //     ?
    //         offerMaster[0]["reward"]["amountOrPercentageOffDiscount"][
    //           "entitledVariants"
    //         ]
    //           ? offerMaster[0]["reward"]["amountOrPercentageOffDiscount"][
    //               "entitledVariants"
    //             ]
    //           : []
    //     : []
    //   : []
    // : [];

    this.hurdleRewardProductCount = offerMaster[0]["reward"]
      ? offerMaster[0]["reward"]["rewardType"] === "BUY_X_GET_Y"
        ? offerMaster[0]["reward"]["buyXGetYDiscount"]["entitledQuantity"]
        : 1
      : 1;
    // this.percentageOff=offerMaster[0]["reward"]? offerMaster[0]["reward"]["rewardType"] === "BUY_X_GET_Y"?offerMaster[0]["reward"]["buyXGetYDiscount"][
    //   "entitledItemDiscountPercent"] :null:null;

    this.atADiscountedValue = this.getEntitledDiscountValue(
      "PREFILL",
      offerMaster
    );
    this.rewardpercentageOff = offerMaster[0]["reward"]
      ? offerMaster[0]["reward"]["rewardType"] === "BUY_X_GET_Y"
        ? offerMaster[0]["reward"]["buyXGetYDiscount"][
            "entitledItemDiscountPercent"
          ]
        : null
      : null;
    this.rewardAmountOff = offerMaster[0]["reward"]
      ? offerMaster[0]["reward"]["rewardType"] === "BUY_X_GET_Y"
        ? offerMaster[0]["reward"]["buyXGetYDiscount"][
            "entitledItemDiscountAmount"
          ]
        : null
      : null;
    (this.hurdleRewardProductCategory = offerMaster[0]["reward"]
      ? offerMaster[0]["reward"]["rewardType"] === "BUY_X_GET_Y"
        ? offerMaster[0]["reward"]["buyXGetYDiscount"]["entitledType"] ===
          "COLLECTION"
          ? offerMaster[0]["reward"]["buyXGetYDiscount"]["entitledCollection"]
          : null
        : offerMaster[0]["reward"]["rewardType"] === "PERCENTAGE_OFF" &&
          !offerMaster[0]["reward"]["productCategoryPropensity"]
        ? offerMaster[0]["reward"]["amountOrPercentageOffDiscount"][
            "entitledType"
          ] === "COLLECTION"
          ? offerMaster[0]["reward"]["amountOrPercentageOffDiscount"][
              "entitledCollection"
            ]
          : null
        : offerMaster[0]["reward"]["rewardType"] === "FIXED_AMOUNT_OFF" &&
          !offerMaster[0]["reward"]["productCategoryPropensity"]
        ? offerMaster[0]["reward"]["amountOrPercentageOffDiscount"]?.entitledType === "COLLECTION"
          ? offerMaster[0]["reward"]["amountOrPercentageOffDiscount"]?.entitledType
          : null
        : null
      : null),
      (this.freeShippingType = offerMaster[0]["reward"]
        ? offerMaster[0]["reward"]["rewardType"] === "FREE_SHIPPING"
          ? offerMaster[0]["reward"]["freeShippingDiscount"]?.entitledType
          : "ORDER"
        : "ORDER");
    this.freeShippingMinimumAmount = offerMaster[0]["reward"]
      ? offerMaster[0]["reward"]["rewardType"] === "FREE_SHIPPING"
        ? offerMaster[0]["reward"]["freeShippingDiscount"]["entitledType"] ===
          "ORDER"
          ? offerMaster[0]["reward"]["freeShippingDiscount"]["minOrderAmount"]
          : ""
        : ""
      : "";
    this.freeShippingMinimumOrderCount = offerMaster[0]["reward"]
      ? offerMaster[0]["reward"]["rewardType"] === "FREE_SHIPPING"
        ? offerMaster[0]["reward"]["freeShippingDiscount"]["entitledType"] ===
          "MIN_ORDER_QUANTITY"
          ? offerMaster[0]["reward"]["freeShippingDiscount"]["minOrderQuantity"]
          : ""
        : ""
      : "";
    this.hurdleTimeToComplete =
      offerMaster[0]["barrier"]["barrierDates"]["endDayCount"];
    this.rewardTimeToComplete = offerMaster[0]["reward"]
      ? offerMaster[0]["reward"]["daysToRedeem"]
      : null;

    this.rewardTemplate = offerMaster[0]["reward"]
      ? offerMaster[0]["reward"]["rewardType"] == "BUY_X_GET_Y"
        ? "BUY_X_GET_Y_REWARD_EMAIL"
        : offerMaster[0]["reward"]["rewardType"] == "PERCENTAGE_OFF"
        ? "PERCENTAGE_OFF_REWARD_EMAIL"
        : offerMaster[0]["reward"]["rewardType"] == "FIXED_AMOUNT_OFF"
        ? "FIXED_AMOUNT_OFF_REWARD_EMAIL"
        : offerMaster[0]["reward"]["rewardType"] == "FREE_SHIPPING"
        ? "FREE_SHIPPING_REWARD_EMAIL"
        : null
      : null;
    this.rewardSavedId = offerMaster[0]["reward"]
      ? offerMaster[0]["reward"]["emailLayoutId"]
      : null;
    this.channel = offerMaster[0]["reward"]
      ? offerMaster[0]["reward"]["channel"]
      : null;
    this.rewardsmsName = offerMaster[0]["reward"]
      ? offerMaster[0]["reward"]["rewardLayoutName"]
      : null;
    this.rewardSMSSavedId = offerMaster[0]["reward"]
      ? offerMaster[0]["reward"]["layoutId"]
      : null;
    this.customTermsAndCondition = this.convertToOriginalFormat(offerMaster[0]["reward"]['termsAndCondition']);
    this.customDescription = this.convertToOriginalFormat(offerMaster[0]["reward"]['description']);
    this.discountOn = offerMaster[0]["reward"]['discountScope'];
    this.orderType.setValue(offerMaster[0]["reward"]['orderType']);
    if(this.getApplicableCount(this.discountOn) === this.orderType?.value?.length){
      let val = this.orderType.value;
      val.push("ALL");
      this.orderType.setValue(val);
    }
    // this.hurdleCompletionDay =
    //   offerMaster[0]["barrier"]["barrierDates"]["daysOfTheWeek"] == null
    //     ? []
    //     : offerMaster[0]["barrier"]["barrierDates"]["daysOfTheWeek"];

    // for (let i = 0; i < this.hurdleCompletionDay.length; i++) {
    //   for (var j = 0; j <= this.weekDaysChips.length; j++) {
    //     if (this.hurdleCompletionDay[i] == this.weekDaysChips[j]["full"]) {
    //       this.weekDaysChips[j]["selected"] = true;
    //       break;
    //     }
    //   }
    // }

    // this.hourFrom =
    //   offerMaster[0]["barrier"]["hurdleAtAnyTime"] == true
    //     ? "00:00"
    //     : offerMaster[0]["barrier"]["barrierDates"]["startTime"];

    // this.hourTo =
    //   offerMaster[0]["barrier"]["hurdleAtAnyTime"] == true
    //     ? "23:59"
    //     : offerMaster[0]["barrier"]["barrierDates"]["endTime"];

    // });
    //(this.offerTemplate.get("offerBarrier")).push(control);

    // }

    this.readyToBuild = true;

    if (!this.route.snapshot.paramMap.get("clone")) {
      if (this.route.snapshot.paramMap.get("preview") == "true") {
        this.fieldsetDisabled = "true";
        this.previewmode = true;
      } else if (this.route.snapshot.paramMap.get("admin") == "true") {
        this.fieldsetDisabled = "true";
        if (this.tokenStorage.getqId() !== "null") this.adminMode = true;
      } else if (
        this.route.snapshot.paramMap.get("qid") !== this.tokenStorage.getqId()
      ) {
        this.fieldsetDisabled = "true";
        if (this.route.snapshot.paramMap.get("qid") !== "null")
          this.managerAdmin = true;
      }
    }
  }

  getVariantObj(obj) {
    this.apiCall.getVariantObjbyId(obj).subscribe((response) => {
      // this.variantObj=response;
    });
  }

  createOffer() {
    const dialogRef = this.dialog.open(OfferCreateChatGptDialogBoxComponent, {
      panelClass: "previewDialog",
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.editOffer(result);
      }
    });
  }
  async editOffer(idnumber) {
    // if (
    //   await this._SubscriptionPlanHandlerService.IsValidOperation(
    //     this.appSubscriptionPlan
    //   )
    //)
    {
      this.router.navigate(["app/offer/edit-offer", { id: idnumber }]);
    }
  }
  getStores() {
    this.apiCall.getStoreData().subscribe(
      (response) => {
        this.storeData = response["body"];
        // if(this.storeData.length != 0)
        //   // this.storeAvailability = true;
        // else
        //   // this.storeAvailability = false;
        // this.apiloading = false;
        if (!this.route.snapshot.paramMap.get("id")) {
          this.selectStoreInDropdown(-1);
        }
      },
      (error) => {
        // this.storeAvailability = false;
        this.storeData = [];
        // this.apiloading = false;
      }
    );
  }
  getParticularStore() {
    let data: any = {};
    this.apiCall.getSpecificStoreOffer().subscribe((response) => {
      console.log(response);
      data.storeId = response["body"]["storeMetaInfo"].storeId;
      data.storeName = response["body"]["storeMetaInfo"].storeName;
      data.qid = response["body"]["storeMetaInfo"].qid;
      this.storeData.push(data);
    });
  }
  getParticularStoreJSON() {
    let data: any = {};
    this.apiCall.getSpecificStoreOffer().subscribe((response) => {
      console.log(response);
      data.storeId = response["body"]["storeMetaInfo"].storeId;
      data.storeName = response["body"]["storeMetaInfo"].storeName;
      data.qid = response["body"]["storeMetaInfo"].qid;
      data.userName = this.tokenStorage.getUsernameFull();
      data.userEmail = this.tokenStorage.getMerchantUserEmail();
      this.storeDataJSON = data;
      if (!this.route.snapshot.paramMap.get("id")) {
        this.redeemableAtStores.setValue([data.storeId]);
      }
    });
  }
  getTooltipText() {
    if (this.previewmode) {
      return "Preview only, can’t edit";
    } else if (this.adminMode) {
      return "Created by admin, cant't edit";
    } else if (this.managerAdmin) {
      return "Created by another store manager, can't edit";
    } else {
      return "AI Assistance does not allow custom coupon codes";
    }
  }
  numberInputHandler(event: KeyboardEvent, maxLength) {
    const invalidCharacters = ["e", "-", "+", "."];
    const inputValue = (event.target as HTMLInputElement).value;
    if (
      (inputValue.length >= maxLength && event.key !== "Backspace") ||
      invalidCharacters.includes(event.key)
    ) {
      event.preventDefault();
    }
  }
  removeScrollinImput() {
    const inputElement = document.getElementById("scrollPreventInput");

    inputElement.addEventListener("wheel", (event) => {
      event.preventDefault(); // Prevent the default scrolling behavior
    });
  }
  keypressNumber(event) {
    if (
      [46, 8, 9, 27, 13].includes(event.keyCode) ||
      // Allow: Ctrl+A/Ctrl+C/Ctrl+V/Ctrl+X
      (event.keyCode === 65 &&
        (event.ctrlKey === true || event.metaKey === true)) ||
      (event.keyCode === 67 &&
        (event.ctrlKey === true || event.metaKey === true)) ||
      (event.keyCode === 86 &&
        (event.ctrlKey === true || event.metaKey === true)) ||
      (event.keyCode === 88 &&
        (event.ctrlKey === true || event.metaKey === true)) ||
      // Allow: home, end, left, right, down, up
      (event.keyCode >= 35 && event.keyCode <= 40)
    ) {
      // Let it happen, don't do anything
      return;
    }
    // Ensure that it's a number and stop the keypress if it's not
    if (
      (event.shiftKey || event.keyCode < 48 || event.keyCode > 57) &&
      (event.keyCode < 96 || event.keyCode > 105)
    ) {
      event.preventDefault();
    }
  }

  getDateFormat(type?) {
    // this.date_format.getDateFormat();
    return this.date_format.getDateFormat(type);
  }

  startDateChange() {
    let startDate = new Date(this.startDate.value);
    startDate.setHours(0);
    startDate.setMinutes(0);
    startDate.setSeconds(0);
    startDate.setMilliseconds(0);
    let endDate = new Date(this.endDate.value);
    endDate.setHours(0);
    endDate.setMinutes(0);
    endDate.setSeconds(0);
    endDate.setMilliseconds(0);
    if (startDate > endDate) {
      this.endDate.setValue(
        new Date(
          this.startDate.value.getFullYear(),
          this.startDate.value.getMonth(),
          this.startDate.value.getDate() + 1
        )
      );
    }
    let currentDate = new Date(this.startDateMinValue);
    currentDate.setHours(0);
    currentDate.setMinutes(0);
    currentDate.setSeconds(0);
    currentDate.setMilliseconds(0);
    if (
      startDate.getTime() === currentDate.getTime() &&
      endDate.getTime() === currentDate.getTime()
    ) {
      this.startMinTime = this.addMinutes(
        this.startDateMinValue.toTimeString(),
        60
      );
      this.endMinTime = this.addMinutes(this.startMinTime, 1);
    } else if (
      startDate.getTime() === currentDate.getTime() &&
      endDate.getTime() != currentDate.getTime()
    ) {
      this.startMinTime = this.addMinutes(
        this.startDateMinValue.toTimeString(),
        60
      );
      this.endMinTime = "00:00";
    } else {
      this.startMinTime = "00:00";
      this.endMinTime = "00:01";
    }
    // this.checkStartDateTime();
  }

  endDateChange() {
    let startDate = new Date(this.startDate.value);
    startDate.setHours(0);
    startDate.setMinutes(0);
    startDate.setSeconds(0);
    startDate.setMilliseconds(0);
    let endDate = new Date(this.endDate.value);
    endDate.setHours(0);
    endDate.setMinutes(0);
    endDate.setSeconds(0);
    endDate.setMilliseconds(0);
    if (endDate < startDate) {
      this.startDate.setValue(
        new Date(
          this.endDate.value.getFullYear(),
          this.endDate.value.getMonth(),
          this.endDate.value.getDate() - 1
        )
      );
    }
    let currentDate = new Date(this.startDateMinValue);
    currentDate.setHours(0);
    currentDate.setMinutes(0);
    currentDate.setSeconds(0);
    currentDate.setMilliseconds(0);
    if (endDate.getTime() === currentDate.getTime()) {
      this.endMinTime = this.addMinutes(this.startMinTime, 1);
    } else if (endDate.getTime() != currentDate.getTime()) {
      this.endMinTime = "00:00";
    }
    // this.checkStartDateTime();
  }

  checkIfBeforeCurrentDate(date) {
    const options = { timeZone: this.tokenStorage.getMerchantTimeZone() };
    const today = new Date();
    const todayInTimezone = today.toLocaleString("en-US", options);
    const todayInTimezoneDate = new Date(todayInTimezone);
    // Extract only the date part (year, month, and day)
    const year = todayInTimezoneDate.getFullYear();
    const month = todayInTimezoneDate.getMonth() + 1; // Month is zero-indexed, so add 1
    const day = todayInTimezoneDate.getDate();

    // Create a new Date object with only the date (time will be set to midnight)
    const todayWithoutTime = new Date(year, month - 1, day);
    // const todayWithoutTime = new Date(Date.UTC(year, month - 1, day));

    // Convert to specific timezone

    // If you need it as a Date object

    // Assuming you have the selected date in a variable called selectedDate
    const selectedDate = new Date(date); // Replace this with your selected date

    // Check if the selected date is before today's date
    if (selectedDate < todayWithoutTime) {
      return true;
    } else if (selectedDate > todayWithoutTime) {
      return false;
    } else {
      return false;
    }
  }
  startTimeChange() {
    let currentTime = new Date();
    let startTime = new Date(this.startDate.value);
    startTime.setHours(
      +this.transformTo24HourFormat(this.startTime.value).split(":")[0]
    );
    startTime.setMinutes(
      +this.transformTo24HourFormat(this.startTime.value).split(":")[1]
    );
    startTime.setSeconds(0);
    startTime.setMilliseconds(0);
    let endTime = new Date(this.endDate.value);
    endTime.setHours(
      +this.transformTo24HourFormat(this.endTime.value).split(":")[0]
    );
    endTime.setMinutes(
      +this.transformTo24HourFormat(this.endTime.value).split(":")[1]
    );
    endTime.setSeconds(0);
    endTime.setMilliseconds(0);
    if (endTime <= startTime) {
      let hour: any = +this.transformTo24HourFormat(this.startTime.value).split(
        ":"
      )[0];
      let minute: any = +this.transformTo24HourFormat(
        this.startTime.value
      ).split(":")[1];
      if (minute === 59) {
        minute = "00";
        hour += 1;
      } else {
        minute += 1;
      }
      if (hour < 10) {
        hour = "0" + hour;
      }
      this.endTime.setValue(
        this.transformTo12HourFormat(
          hour +
            ":" +
            (minute < 10 && minute > 0 ? "0" + minute : minute.toString())
        )
      );
      //this.endMinTime=this.endTime.value;
    }
  }

  endTimeChange() {
    let currentTime = new Date();
    let startTime = new Date(this.startDate.value);
    startTime.setHours(
      +this.transformTo24HourFormat(this.startTime.value).split(":")[0]
    );
    startTime.setMinutes(
      +this.transformTo24HourFormat(this.startTime.value).split(":")[1]
    );
    startTime.setSeconds(0);
    startTime.setMilliseconds(0);
    let endTime = new Date(this.endDate.value);
    endTime.setHours(
      +this.transformTo24HourFormat(this.endTime.value).split(":")[0]
    );
    endTime.setMinutes(
      +this.transformTo24HourFormat(this.endTime.value).split(":")[1]
    );
    endTime.setSeconds(0);
    endTime.setMilliseconds(0);
    if (endTime <= startTime) {
      let hour: any = +this.transformTo24HourFormat(this.endTime.value).split(
        ":"
      )[0];
      let minute: any = +this.transformTo24HourFormat(this.endTime.value).split(
        ":"
      )[1];
      if (minute === 0) {
        minute = "59";
        hour -= 1;
      } else {
        minute -= 1;
      }
      if (hour < 10) {
        hour = "0" + hour;
      }
      this.startTime.setValue(
        this.transformTo12HourFormat(
          hour +
            ":" +
            (minute < 10 && minute > 0 ? "0" + minute : minute.toString())
        )
      );
    }
  }

  transformTo24HourFormat(time12hr: string): string {
    if (sessionStorage.getItem("serviceCountry") === "US") {
      // Use the DatePipe to format the time in 12-hour format
      let formattedTime = time12hr;

      // Parse the formatted time to get the hour and minute
      let parts = formattedTime.split(":");
      let hour = parseInt(parts[0], 10);
      let minute = parseInt(parts[1], 10);

      // Check if it's PM, and if it is, add 12 to the hour
      if (time12hr.toLowerCase().includes("pm") && hour < 12) {
        hour += 12;
      } else if (time12hr.toLowerCase().includes("am") && hour == 12) {
        hour = 0;
      }

      // Format the hour and minute as two-digit strings
      let hourStr = hour.toString().padStart(2, "0");
      let minuteStr = minute.toString().padStart(2, "0");

      // Return the time in 24-hour format
      return `${hourStr}:${minuteStr}`;
    } else {
      return time12hr;
    }
  }
  transformTo12HourFormat(time24hr: string): string {
    // Split the time string into hours and minutes
    if (sessionStorage.getItem("serviceCountry") === "US") {
      const [hoursStr, minutesStr] = time24hr.split(":");
      const hours = parseInt(hoursStr, 10);
      const minutes = parseInt(minutesStr, 10);

      // Determine AM or PM based on hours
      const period = hours >= 12 ? "PM" : "AM";

      // Convert hours to 12-hour format
      const hours12 = hours > 12 ? hours - 12 : hours === 0 ? 12 : hours;

      // Format the time in 12-hour format
      const formattedTime = `${hours12}:${minutes
        .toString()
        .padStart(2, "0")} ${period}`;

      return formattedTime;
    } else {
      return time24hr;
    }
  }

  addMinutes(time, minsToAdd) {
    var piece = time.split(":");

    var mins = piece[0] * 60 + +piece[1] + +minsToAdd;

    return this.D(((mins % (24 * 60)) / 60) | 0) + ":" + this.D(mins % 60);
  }

  D(J) {
    return (J < 10 ? "0" : "") + J;
  }

  toUTC(time, timezone) {
    return this.datePipe.transform(time, "yyyy-MM-dd HH:mm:ss");
  }

  toMerchantTimeZome(utcDate) {
    return this.datePipe.transform(utcDate, "yyyy-MM-dd HH:mm:ss");
  }

  getEntitledDiscountValue(type, data?) {
    if (type === "PERCENTAGE") {
      if (this.atADiscountedValue == "PERCENTAGE_OFF") {
        return this.rewardpercentageOff;
      } else if (this.atADiscountedValue == "AMOUNT_OFF") {
        return null;
      } else {
        return 100;
      }
    }
    if (type === "PREFILL") {
      data[0]["reward"]
        ? data[0]["reward"]["rewardType"] === "BUY_X_GET_Y"
          ? data[0]["reward"]["buyXGetYDiscount"][
              "entitledItemDiscountAmount"
            ] !== null
            ? "AMOUNT_OFF"
            : "PERCENTAGE_OFF"
          : "PERCENTAGE_OFF"
        : "PERCENTAGE_OFF";
      if (
        data[0]["reward"] &&
        data[0]["reward"]["rewardType"] === "BUY_X_GET_Y" &&
        data[0]["reward"]["buyXGetYDiscount"]["entitledItemDiscountAmount"] !==
          null
      ) {
        return "AMOUNT_OFF";
      } else if (
        data[0]["reward"] &&
        data[0]["reward"]["rewardType"] === "BUY_X_GET_Y" &&
        data[0]["reward"]["buyXGetYDiscount"]["entitledItemDiscountPercent"] !==
          null &&
        data[0]["reward"]["buyXGetYDiscount"]["entitledItemDiscountPercent"] !==
          100
      ) {
        return "PERCENTAGE_OFF";
      } else if (
        data[0]["reward"] &&
        data[0]["reward"]["rewardType"] === "BUY_X_GET_Y" &&
        data[0]["reward"]["buyXGetYDiscount"]["entitledItemDiscountPercent"] !==
          null &&
        data[0]["reward"]["buyXGetYDiscount"]["entitledItemDiscountPercent"] !==
          100
      ) {
        return "FREE";
      } else {
        return "PERCENTAGE_OFF";
      }
    }
  }

  getDefaultExclusion() {
    this.apiCall.getDefaultProductExclusion().subscribe((response) => {
      console.log(response);
      this.globalExclusionList = response["body"];
      this.selectedBarrierExcludedProductList = [
        ...this.selectedBarrierExcludedProductList,
        ...this.globalExclusionList["excludedProductList"],
      ];
      this.selectedRewardExcludedProductList = [
        ...this.selectedRewardExcludedProductList,
        ...this.globalExclusionList["excludedProductList"],
      ];
      this.selectedRewardExcludedCategoryList = [
        ...this.selectedRewardExcludedCategoryList,
        ...this.globalExclusionList["excludedCategoryList"],
      ];

      console.log(this.selectedBarrierExcludedProductList);
    });
  }

  allStoresObj = {
    qid: -1, //FOR ALL STORES SELCTION
    storeName: "All stores",
  };
  dropDownSearch = "";
  selectStoreInDropdown(storeId) {
    let storeIdList = [...this.previousStoreIdList];

    if (storeId === -1) {
      if (!storeIdList.includes(-1)) {
        storeIdList = [-1, ...this.storeData.map((store) => store.qid)];
      } else {
        storeIdList = [];
      }
    } else {
      const index = storeIdList.indexOf(storeId);
      if (index === -1) {
        storeIdList.push(storeId);
      } else {
        storeIdList.splice(index, 1);
      }

      if (storeIdList.includes(-1) && storeIdList.length > 1) {
        storeIdList = storeIdList.filter((id) => id !== -1);
      }
      const allSelected = this.storeData.every((store) =>
        storeIdList.includes(store.qid)
      );
      if (allSelected && !storeIdList.includes(-1)) {
        storeIdList.push(-1);
      }
    }
    this.redeemableAtStores.setValue(storeIdList);
    this.previousStoreIdList = [...storeIdList];
  }

  changeDiscountOn(value){
    this.previousOrderTypeList = [];
    if(value !== 'CART'){
      this.discountAndShippingType = "ORDER";
    }
    this.selectOrderTypeDropDown("ALL");
  }

  selectOrderTypeDropDown(type) {
    let orderTypeList = [...this.previousOrderTypeList];
    if (type === "ALL") {
      if (!orderTypeList.includes("ALL")) {
        orderTypeList = [
          "ALL",
          ...this.typeOfOrderType.filter((data) => (data.applicableFor === this.discountOn || data.applicableFor === 'ALL')).map((data) => data.value)
        ];
      } else {
        orderTypeList = [];
      }
    } else {
      const index = orderTypeList.indexOf(type);
      if (index === -1) {
        orderTypeList.push(type);
      } else {
        orderTypeList.splice(index, 1);
      }
      if (orderTypeList.includes("ALL") && orderTypeList.length > 1) {
        orderTypeList = orderTypeList.filter((type) => type !== "ALL");
      }
      const filteredType = this.typeOfOrderType.filter(
        (type) => (type.applicableFor === this.discountOn || type.applicableFor === 'ALL')
      );
      const allSelected = filteredType.every((type) =>
        orderTypeList.includes(type.value)
      );

      if (allSelected && !orderTypeList.includes(-1)) {
        orderTypeList.push("ALL"); // Add "ALL" if all items are selected and "ALL" is not already in the list
      }
    }
    this.orderType.setValue(orderTypeList);
    this.previousOrderTypeList = [...orderTypeList];
  }

  toggleSelection(day: any) {
    day.selected = !day.selected;
    this.arrayDynamic = [];
    for (let day of this.weekDaysChips) {
      if (day.selected) {
        this.arrayDynamic.push(day.name);
      }
    }
  }

  changeTimeType(type){
    
  }

  handleKeyDown(event: KeyboardEvent, type): void {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent default "Enter" behavior

      const textarea = event.target as HTMLTextAreaElement;
      const start = textarea.selectionStart;
      const end = textarea.selectionEnd;

      // Insert a newline and bullet point
      const bulletPoint = '\u2022 ';
      if(type === 'description'){
        const newValue = 
          this.customDescription.substring(0, start) + // Content before cursor
          '\n' + bulletPoint +              // New line and bullet
          this.customDescription.substring(end);      // Content after cursor
  
        this.customDescription = newValue;
      }
      else if(type === 'terms'){
        const newValue = 
        this.customTermsAndCondition.substring(0, start) + // Content before cursor
        '\n' + bulletPoint +              // New line and bullet
        this.customTermsAndCondition.substring(end);      // Content after cursor

        this.customTermsAndCondition = newValue;
      }

      // this.updateBulletArray(type);

      // Move the cursor to the right of the new bullet point
      setTimeout(() => {
        textarea.setSelectionRange(start + bulletPoint.length + 1, start + bulletPoint.length + 1);
        this.scrollToCursor(textarea);
      });
    }
  }

  private scrollToCursor(textarea: HTMLTextAreaElement): void {
    const cursorPosition = textarea.selectionStart;
    const textBeforeCursor = textarea.value.substring(0, cursorPosition);
    const lines = textBeforeCursor.split('\n');

    // Calculate the height of the content above the cursor
    const lineHeight = parseInt(window.getComputedStyle(textarea).lineHeight || '20', 10);
    const scrollHeight = lines.length * lineHeight;

    // Scroll to the calculated height
    textarea.scrollTop = scrollHeight - textarea.clientHeight + lineHeight;
  }

  initializeBulletPoint(type): void {
    if(type === 'description'){
      if (!this.customDescription.trim()) {
        this.customDescription = '\u2022 '; // Add the first bullet point
      }
    }
    else{
      if (!this.customTermsAndCondition.trim()) {
        this.customTermsAndCondition = '\u2022 '; // Add the first bullet point
      }
    }
  }

  updateBulletArray(value): void {
    let reuslt =  value
      .split('\n') // Split by new lines
      .map(line => line.trim()) // Remove extra spaces
      .filter(line => line.startsWith('\u2022')) // Include only lines that start with a bullet
      .map(line => line.substring(2).trim()); // Remove the bullet and trim the text
    
    return reuslt;
  }

  convertToOriginalFormat(value) {
    // if (type === 'description') {
    //   this.customDescription = this.bulletCustomDescription
    //     .map(line => `• ${line}`) // Add bullet point before each line
    //     .join('\n'); // Join the lines with newline characters
    // } else {
    if(value){
      let result = value
        .map(line => `• ${line}`) // Add bullet point before each line
        .join('\n'); // Join the lines with newline characters
    //}
    return result;
    }
    return "";
  }

  onCheckboxChange(event, type){
    if(event.checked === true){
      switch(type){
        case "isHiddenItemOffer":
          this.isGiveAway = false;
          this.toApplyOnMultipleProduct = false;
          this.discountAndShippingType = "PRODUCT";
          if(this,this.isHiddenItemOffer){
            this.hurdleRewardType = "NONE";
            this.amountOff = 0;
          }
          break;
        case "isGiveAway":
          if(this.isGiveAway){
            this.hurdleRewardType = "PERCENTAGE_OFF";
            this.percentageOff = 100;
            this.discountAndShippingType = "COLLECTION";
          }
          this.isHiddenItemOffer = false;
          this.toApplyOnMultipleProduct = false;
          break;
        case "toApplyOnMultipleProduct":
          this.isGiveAway = false;
          this.isHiddenItemOffer = false;
          break;
      }
    }
  }

  getApplicableCount(input: string): number {
    return this.typeOfOrderType.filter(
      item => item.applicableFor === input || item.applicableFor === 'ALL'
    ).length;
  }

  checkIfDisabled(type: string, value?): boolean {
    if (type === 'ALLPRODUCTS') {
      return this.hurdleRewardType === 'BURN_LOYALTY_POINTS' || this.isHiddenItemOffer;
    } 

    if(type === 'MIN_ORDER_QUANTITY'){
      return this.isGiveAway || this.isHiddenItemOffer;;
    }
  
    if (type === 'CATEGORY') {
      return this.isHiddenItemOffer || this.discountOn !== 'CART';;
    }

    if(type === 'PRODUCT'){
      return this.discountOn !== 'CART';
    }

    if(type === 'DISCOUNT_ON'){
      return (value != 'CART' && (this.isGiveAway || this.isHiddenItemOffer));
    }
  
    return false;
  }
  
}
